import styled, { css } from 'styled-components';

const SectionTab = styled.li.attrs({
    'data-test-id': 'SectionTab'
})`
    display: block;
    padding: ${props => props.theme.spacing.md} 0;
    margin: 0 ${props => props.theme.spacing.xs};
    border-bottom: 1px solid transparent;
    color: ${props => props.theme.colors.darkGray};
    cursor: pointer;
    font-size: ${props => props.theme.fontSize.xs};
    font-family: ${props => props.theme.font.family.arial};

    ${props => props.selected && css`
        border-bottom-color: ${props => props.theme.colors.blue};
        color: ${props => props.theme.colors.blue};
        font-weight: ${props => props.theme.fontWeight.bold};
    `}

    ${props => props.large && css`
        border-bottom: 2px solid transparent;
        color: ${props => props.selected && props.theme.colors.black};
        border-bottom-color: ${props => props.selected && props.theme.colors.cerulean};

        &:first-child {
            margin-left: 0;
        }

        ${props => props.theme.media.portrait`
            font-size: ${props => props.theme.fontSize.md};
            font-weight: ${props => !props.selected && props.theme.fontWeight.text};
            font-family: ${props => props.theme.font.family.sanchez};
            margin: 0 ${props => props.theme.spacing.md};
            padding: ${props => props.theme.spacing.md} 0;
        `}
    `}
`;
SectionTab.displayName = 'SectionTab';

export default SectionTab;
