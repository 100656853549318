import _ from 'lodash';
import moment from 'moment-timezone';

import { createReducerFromMapping } from 'redux/utils/index.js';
import { loadPermissions } from 'redux/modules/profile/profile.js';
import { loadEquipments, loadSubEquipments } from 'redux/modules/customer/equipments.js';
import { loadFunctionalLocationsImages } from 'redux/modules/customer/partnerImage';
import { loadFunctionalLocationImages } from 'redux/modules/customer/partnerImage';
import { loadBuildingContainer } from 'redux/modules/containers/building.js';
import { isBusinessUnit, isBusinessEntity, isTenant, isSystem } from 'utils/Data/functionalLocations.js';
import { loadSensorDataTypes } from '../customer/sensorHierarchy';
import { myFLCustomViews } from 'redux/modules/customView/customView';
import {
    loadDocumentsByFunctionalLocation,
    loadAvailableSensors,
    loadFunctionalLocations,
    loadFunctionalLocationsByPath,
    loadFolders,
    loadFiles,
} from 'redux/modules';

const initialState = {
    // Make the default state loading so that the first load respects loading order via promise resolve
    loading: true,
    loadingChildren: true,
    loadingEquipment: true,
    loadingParents: true,
    loadingServiceOrders: true,
    error: false
};

export const FUNCTIONAL_LOCATION_CONTAINER_LOAD = 'CUSTOMER_PLATFORM/FunctionalLocationContainer/LOAD';
export const FUNCTIONAL_LOCATION_CONTAINER_LOAD_SUCCESS = 'CUSTOMER_PLATFORM/FunctionalLocationContainer/LOAD_SUCCESS';
export const FUNCTIONAL_LOCATION_CONTAINER_LOAD_FAIL = 'CUSTOMER_PLATFORM/FunctionalLocationContainer/LOAD_FAIL';

export const loadFunctionalLocationContainer = (functionalLocationId, startDate, endDate, features, partnerNumber) => {
    return async dispatch => {
        dispatch({ type: FUNCTIONAL_LOCATION_CONTAINER_LOAD });
        try {
            // Load functional location
            const functionalLocationResult = await dispatch(loadFunctionalLocations([functionalLocationId]));
            const functionalLocation = functionalLocationResult.result[0];
            const parents = _.without(functionalLocation.path, functionalLocation.functionalLocation);

            // Dispatch success immediately after Functional Location has loaded, this invokes render
            dispatch({ type: FUNCTIONAL_LOCATION_CONTAINER_LOAD_SUCCESS });

            // Load custom views for side navigation
            dispatch(myFLCustomViews(functionalLocationId));

            // Always load parent(s)
            const toLoad = [dispatch(loadFunctionalLocationsByPath(partnerNumber, parents)).then(({ result }) => {
                // Load images for BU and UN parents
                const loadImagesTo = _.filter(result, isBusinessUnit || isTenant).map(fl => fl.functionalLocation);
                if (loadImagesTo && loadImagesTo.length > 0) {
                    dispatch(loadFunctionalLocationsImages(loadImagesTo))
                        .then(() => dispatch(setFunctionalLocationParentsLoaded()));
                }

                // Always load images for Building and Tenant
                if (isBusinessUnit(functionalLocation) || isTenant(functionalLocation)) {
                    dispatch(loadFunctionalLocationImages(functionalLocationId))
                        .then(() => dispatch(setFunctionalLocationParentsLoaded()));
                }

                // If there are no images to load, just set the parents loaded
                if (!loadImagesTo || loadImagesTo.length === 0
                    && !isBusinessUnit(functionalLocation) && !isTenant(functionalLocation)) {
                    dispatch(setFunctionalLocationParentsLoaded());
                }
            })];

            // Load equipment, if enabled.
            if (features.equipment) {
                toLoad.concat([dispatch(loadEquipments(functionalLocation.functionalLocation)).then(({ result }) => {
                    dispatch(setFunctionalLocationEquipmentLoaded());
                    return Promise.all(result.map(y =>
                        dispatch(loadSubEquipments(functionalLocation.functionalLocation, y.equipmentNumber))
                    ));
                })]);
            }

            // Load child-FLs, if enabled.
            if (features.technicalTab) {
                toLoad.concat([
                    // Load permissions in in order to find out children
                    dispatch(loadPermissions(functionalLocation)).then(({ result }) => {
                        // Set children loaded to invoke rendering of the cards
                        dispatch(setFunctionalLocationChildrenLoaded());

                        // Load images for BU and UN children
                        const loadImagesTo = _.filter(result, isBusinessUnit || isTenant)
                            .map(fl => fl.functionalLocation);
                        loadImagesTo.length > 0 && dispatch(loadFunctionalLocationsImages(loadImagesTo));

                        // Load equipment for disciplines
                        return Promise.all(result
                            .map(fl => isSystem(fl) && dispatch(loadEquipments(fl.functionalLocation))));
                    })
                ]);
            }

            // Load documents for building or tenant, if enabled.
            if (features.files && (isBusinessUnit(functionalLocation) || isTenant(functionalLocation))) {
                toLoad.concat([
                    dispatch(loadFolders(functionalLocation)),
                    dispatch(loadFiles(functionalLocation))
                ]);
            }

            // Load external documents, if enabled.
            if (features.documents) {
                toLoad.concat([dispatch(loadDocumentsByFunctionalLocation(functionalLocation))]);
            }

            // load SensorDataTypes, if energy tab is enabled
            if (features.energyTab) {
                toLoad.concat([dispatch(loadSensorDataTypes())]);
            }

            // Load CCC
            if (features.conditions && !isBusinessUnit(functionalLocation) && !isBusinessEntity(functionalLocation)) {
                toLoad.concat([dispatch(loadAvailableSensors(functionalLocation))]);
            }

            Promise.all(toLoad);

            // Load building container (for buildings) if buildingManagement is enabled
            if (features.buildingManagement && (isBusinessUnit(functionalLocation) || isTenant(functionalLocation))) {
                dispatch(loadBuildingContainer(
                    partnerNumber,
                    functionalLocation,
                    moment.utc().subtract(6, 'days').startOf('day'),
                    moment.utc().startOf('hour'),
                    features));
            }
        } catch (error) {
            return dispatch({
                type: FUNCTIONAL_LOCATION_CONTAINER_LOAD_FAIL,
                error
            });
        }
    };
};

export const SET_FUNCTIONAL_LOCATION_CHILDREN_LOADED =
    'CUSTOMER_PLATFORM/FunctionalLocationContainer/SET_FUNCTIONAL_LOCATION_CHILDREN_LOADED';

export const setFunctionalLocationChildrenLoaded = () => ({
    type: SET_FUNCTIONAL_LOCATION_CHILDREN_LOADED,
    loadingChildren: false
});

export const SET_FUNCTIONAL_LOCATION_PARENTS_LOADED =
    'CUSTOMER_PLATFORM/FunctionalLocationContainer/SET_FUNCTIONAL_LOCATION_PARENTS_LOADED';

export const setFunctionalLocationParentsLoaded = () => ({
    type: SET_FUNCTIONAL_LOCATION_PARENTS_LOADED,
    loadingParents: false
});

export const SET_FUNCTIONAL_LOCATION_EQUIPMENT_LOADED =
    'CUSTOMER_PLATFORM/FunctionalLocationContainer/SET_FUNCTIONAL_LOCATION_EQUIPMENT_LOADED';

export const setFunctionalLocationEquipmentLoaded = () => ({
    type: SET_FUNCTIONAL_LOCATION_EQUIPMENT_LOADED,
    loadingEquipment: false
});

export default createReducerFromMapping({
    [FUNCTIONAL_LOCATION_CONTAINER_LOAD]: state => ({
        ...state,
        loading: true,
        loadingChildren: true,
        loadingParents: true,
        loadingEquipments: true,
        loadingServiceOrders: true
    }),
    [FUNCTIONAL_LOCATION_CONTAINER_LOAD_SUCCESS]: state => ({
        ...state,
        loading: false
    }),
    [FUNCTIONAL_LOCATION_CONTAINER_LOAD_FAIL]: (state, action) => ({
        ...state,
        loading: false,
        error: action.error
    }),
    [SET_FUNCTIONAL_LOCATION_CHILDREN_LOADED]: (state, { loadingChildren }) => ({
        ...state,
        loadingChildren
    }),
    [SET_FUNCTIONAL_LOCATION_PARENTS_LOADED]: (state, { loadingParents }) => ({
        ...state,
        loadingParents
    }),
    [SET_FUNCTIONAL_LOCATION_EQUIPMENT_LOADED]: (state, { loadingEquipment }) => ({
        ...state,
        loadingEquipment
    })
}, initialState);
