import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { rgba } from 'polished';
import { Icon, ICON_SIZES } from 'components/Icon/Icon';

const StyledLabel = styled.label`
    cursor: ${props => !props.disabled && 'pointer'};
    opacity: ${props => props.disabled && 0.6};
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.abbey};
    font-size: ${props => props.theme.font.size.xs};
    line-height: ${props => props.theme.font.lineHeight.md};
    white-space: nowrap;
    margin-top: 0;

    svg {
        fill: ${props => props.disabled && props.theme.colors.lightGray || !props.checked && props.theme.colors.rockBlue};
        margin: 0;
    }
`;
StyledLabel.displayName = 'StyledLabel';

const StyledInput = styled.span`
    position: relative;
    top: 0;
    left: 0;
    width: ${props => props.theme.spacing.lg};
    height: ${props => props.theme.spacing.lg};
    box-shadow: ${props => `inset 0 3px 3px 0 ${rgba(props.theme.colors.black, 0.10)}`};
    border: 1px solid ${props => props.disabled ? props.theme.colors.rockBlue : props.theme.colors.darkGray};
    border-radius: 50%;
    background: ${props => props.disabled ? props.theme.colors.lightGray : props.theme.colors.white};
`;
StyledInput.displayName = 'StyledInput';

const StyledLabelText = styled.p`
    padding-left: ${props => props.theme.spacing.xs};
`;

StyledLabelText.displayName = 'StyledLabelText';

const Container = styled.div`
    margin: ${props => props.theme.spacing.xxs} 0;
`;
Container.displayName = 'Container';

const HiddenInput = styled.input`
    right: 1000em;
    position: absolute;

    &:checked ~ ${StyledLabel} {
        ${StyledInput} {
            background: ${props => props.disabled ? props.theme.colors.lightGray : props.theme.colors.cerulean};
            border: ${props => props.disabled ? `1px solid ${props.theme.colors.rockBlue}` : 'none'};

            &:after {
                content: '';
                position: absolute;
                display: block;
                left: 8px;
                top: 5px;
                width: 8px;
                height: 11px;
                border: 0;
                border-bottom: 3px solid ${props => props.disabled ? props.theme.colors.rockBlue : props.theme.colors.white};
                border-right: 3px solid ${props => props.disabled ? props.theme.colors.rockBlue : props.theme.colors.white};
                border-radius: 20%;
                transform: rotate(40deg);
            }
        }
        svg {
            fill: ${props => props.disabled ? props.theme.colors.rockBlue : props.fill || props.theme.colors.cerulean};
        }
    }
`;
HiddenInput.displayName = 'HiddenInput';

const RadioButton = props =>
    <Container>
        <HiddenInput
            id={`${props.name}_${props.value}`}
            type="radio"
            name={props.name}
            value={props.value}
            checked={props.checked}
            fill={props.color}
            onChange={e => props.onChange(props.name, props.value)}
            disabled={ props.disabled }
        />
        <StyledLabel htmlFor={`${props.name}_${props.value}`} disabled={ props.disabled }>
            {props.type === 'icon'
                ? <Icon
                    name={props.icon}
                    size={ICON_SIZES.MEDIUM}
                    fill={props.theme.colors.rockBlue} />
                : <StyledInput disabled={ props.disabled } />
            }
            <StyledLabelText>{props.label}</StyledLabelText>
        </StyledLabel>
    </Container>;

RadioButton.defaultProps = {
    type: 'default'
};

RadioButton.propTypes = {
    type: PropTypes.oneOf(['default', 'icon']),
    name: PropTypes.string,
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    icon: PropTypes.string,
    color: PropTypes.string
};

export default withTheme(RadioButton);
