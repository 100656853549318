import React from 'react';
import DateColumn from './../ColumnTypes/DateColumn';
import TitleColumn from './../ColumnTypes/TitleColumn';
import ToggleDescriptionColumn from './../ColumnTypes/ToggleDescriptionColumn';
import StatusColumn from './../ColumnTypes/StatusColumn';
import EventsRow from './../EventsRow/EventsRow';
import EventsCell from './../EventsRow/EventsCell';

export const PlannedInspectionsRow = props => {
    const { t, rowData, onClick } = props;

    return (
        <EventsRow onClick={ () => onClick(rowData) }>
            <EventsCell grow hideOnMobile>
                <TitleColumn t={ t } data={ rowData.location } />
            </EventsCell>
            <EventsCell hideOnMobile>
                <DateColumn data={ rowData.date } />
            </EventsCell>
            <EventsCell hideOnDesktop onClick={ e => e.stopPropagation() }>
                <DateColumn data={ rowData.date } />
                <br />
                <ToggleDescriptionColumn t={ t } data={ rowData.location } />
            </EventsCell>
        </EventsRow>
    );
};

const InspectionsRow = props => {
    const { t, rowData, onClick } = props;

    return (
        <EventsRow onClick={ () => onClick(rowData) }>
            <EventsCell grow hideOnMobile>
                <TitleColumn t={ t } data={ rowData.location } />
            </EventsCell>
            <EventsCell hideOnMobile>
                <StatusColumn data={ rowData.status } t={ t } />
            </EventsCell>
            <EventsCell hideOnMobile>
                <DateColumn data={ rowData.date } />
            </EventsCell>
            <EventsCell hideOnDesktop onClick={ e => e.stopPropagation() }>
                <StatusColumn data={ rowData.status } t={ t } />
                <DateColumn data={ rowData.date } />
                <br />
                <ToggleDescriptionColumn t={ t } data={ rowData.location } />
            </EventsCell>
        </EventsRow>
    );
};

export default InspectionsRow;
