import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { keys, clone } from 'lodash';

import StatisticsBarChart from 'components/Charts/StatisticsBarChart';
import StatisticsHelp from 'containers/Application/ServiceOrderStatistics/StatisticsHelp/StatisticsHelp';
import StatisticsChartContainer
    from 'containers/Application/ServiceOrderStatistics/StatisticsChartContainer/StatisticsChartContainer';
import PrioritySelector from './PrioritySelector/PrioritySelector';

import { GroupedServiceOrders, PartnerMeta, StatisticsType } from 'containers/Application/ServiceOrderStatistics/types';
import * as utils from 'containers/Application/ServiceOrderStatistics/utils';
import { OrderType } from 'constants/serviceCalendar';
import { chartStatistics, referenceData, slas, helpTexts } from './data';

const OrderBreakdown = ({ t, theme, partnerMeta, yearControls, serviceOrders, breakdownSLAs, statisticsType }) => {
    const [selectedPriority, setSelectedPriority] = useState('all');

    const orders = serviceOrders[OrderType.ORDER];
    const statistics = chartStatistics(orders, breakdownSLAs, statisticsType);

    if (!statistics || statistics.all.sampleSize === 0) {
        return (
            <StatisticsChartContainer
                empty
                controls={ yearControls }
                t={ t }
            />
        );
    }

    const priorities = keys(statistics);
    const data = statistics[selectedPriority].monthlyHourAverages || [];
    const series = utils.getSimpleSeries(clone(data), t('Monthly average'));
    const reference = referenceData(partnerMeta, statisticsType, selectedPriority, theme, t);
    const slaData = slas(breakdownSLAs, statisticsType);
    const statsHelp = helpTexts(statisticsType, t);

    return (
        <React.Fragment>
            <PrioritySelector
                selectedPriority={ selectedPriority }
                setSelectedPriority={ setSelectedPriority }
                priorities={ priorities }
                statistics={ statistics }
                t={ t }
                slas={ slaData }
            />
            <StatisticsChartContainer
                count={ statistics[selectedPriority].sampleSize || 0 }
                countLabel={ t('Service Orders') }
                controls={ yearControls }
            >
                <StatisticsBarChart
                    t={ t }
                    type="column"
                    height={ utils.CHART_HEIGHT }
                    hideLegend={ false }
                    categories={ utils.getMonths(t) }
                    noZoom
                    plotBorderWidth="0"
                    legendAlign="left"
                    labelStyle={ utils.getLabelStyle(theme) }
                    series={ utils.merge(series, reference.series) }
                    colors={ utils.getReactionTimeColors(theme) }
                    yTitle={ t('Hours in Average') }
                    valueDecimals={ 0 }
                    backgroundColor="transparent"
                    plotLines={ reference.plotLines }
                />
            </StatisticsChartContainer>
            <StatisticsHelp
                heading={ statsHelp.heading }
                text={ statsHelp.text }
            />
        </React.Fragment>
    );
};

OrderBreakdown.propTypes = {
    t: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
    partnerMeta: PartnerMeta,
    yearControls: PropTypes.node.isRequired,
    serviceOrders: GroupedServiceOrders.isRequired,
    breakdownSLAs: PropTypes.object.isRequired,
    statisticsType: StatisticsType,
};

export default withTheme(OrderBreakdown);
