import React, { PureComponent } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import _ from 'lodash';
import styled, { withTheme, keyframes } from 'styled-components';
import { transparentize } from 'polished';
import Responsive from '../Responsive/Responsive';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import Loader from 'components/Loader/Loader.jsx';
import { tooltipFormatter } from './utils';
import { generateTresholds } from '../../utils/Data/performance';
import memoizeOne from 'memoize-one';

const fadeIn = keyframes`
    0%      { opacity: 0 }
    50%     { opacity: 0 }
    100%    { opacity: 1 }
`;

const StyledSensorChart = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition:
        top 280ms ease-out,
        width 280ms ease-in,
        height 280ms ease-out;

    .performance-chart {
        opacity: 1;
        animation-duration: 560ms;
        animation-iteration-count: 1;
        animation-name: ${fadeIn};
        animation-fill-mode: forwards;
    }
`;

const thresholdOptions = {
    marker: {
        enabled: false,
    },
    enableMouseTracking: false,
    showInLegend: false,
    states: {
        inactive: {
            opacity: 1
        }
    },
    zIndex: 2,
};

/**
 * Generate suitable series to represent limits. Plot bands and lines only support constant values, so the limits are
 * constructed from arearange and area series. Arearange is used to highlight the values in within the limits, while
 * area series are used to highlight red areas (limit -> ±infinity).
 */
const getThresholdSeries = memoizeOne((theme, thresholds) => {
    const series = [];
    const { infiniteMin, infiniteMax } = thresholds;
    if (infiniteMin && infiniteMax) {
        return series;
    }

    if (!infiniteMin && !infiniteMax) {
        series.push({
            ...thresholdOptions,
            type: 'arearange',
            lineWidth: 1,
            lineColor: theme.colors.emerald,
            dashStyle: 'dash',
            fillColor: transparentize(0.95, theme.colors.emerald),
            data: thresholds.within,
        });

        series.push({
            ...thresholdOptions,
            type: 'area',
            threshold: Number.NEGATIVE_INFINITY,
            fillColor: transparentize(0.95, theme.colors.radicalRed),
            lineWidth: 0,
            data: thresholds.below,
        });

        series.push({
            ...thresholdOptions,
            type: 'area',
            threshold: Number.POSITIVE_INFINITY,
            fillColor: transparentize(0.95, theme.colors.radicalRed),
            lineWidth: 0,
            data: thresholds.above,
        });
    }

    if (infiniteMin) {
        series.push({
            ...thresholdOptions,
            type: 'area',
            threshold: Number.NEGATIVE_INFINITY,
            fillColor: transparentize(0.95, theme.colors.emerald),
            lineWidth: 1,
            lineColor: theme.colors.emerald,
            dashStyle: 'dash',
            data: thresholds.above,
        });

        series.push({
            ...thresholdOptions,
            type: 'area',
            threshold: Number.POSITIVE_INFINITY,
            fillColor: transparentize(0.95, theme.colors.radicalRed),
            lineWidth: 1,
            lineColor: theme.colors.emerald,
            dashStyle: 'dash',
            data: thresholds.above,
        });
    }

    if (infiniteMax) {
        series.push({
            ...thresholdOptions,
            type: 'area',
            threshold: Number.POSITIVE_INFINITY,
            fillColor: transparentize(0.95, theme.colors.emerald),
            lineWidth: 0,
            data: thresholds.below,
        });

        series.push({
            ...thresholdOptions,
            type: 'area',
            threshold: Number.NEGATIVE_INFINITY,
            fillColor: transparentize(0.95, theme.colors.radicalRed),
            lineWidth: 0,
            data: thresholds.below,
        });
    }

    return series;
});

/**
 * Find extremes from the dataset.
 */
const analyzeSeries = memoizeOne(data => {
    const minTimestamp = _.minBy(data, 'timestamp');
    const maxTimestamp = _.maxBy(data, 'timestamp');
    const minValue = _.minBy(data, 'value');
    const maxValue = _.maxBy(data, 'value');
    return {
        minTimestamp: minTimestamp ? moment(minTimestamp.timestamp) : undefined,
        maxTimestamp: maxTimestamp ? moment(maxTimestamp.timestamp) : undefined,
        minValue: minValue ? minValue.value : undefined,
        maxValue: maxValue ? maxValue.value : undefined,
    };
});

class PerformanceChart extends PureComponent {

    renderChart() {

        const {
            width,
            height,
            dimensions,
            loading,
            histories,
            error,
            sensorNames,
            unit,
            sensorConfigurations,
            maxX,
            minXRange,
            theme,
            t,
            yAxisTitle,
            getThreshold,
            thresholdGranularity,
            yMin,
            yMax,
            precision,
            isBooleanType
        } = this.props;

        // Use 1.) explicit width and height, or 2.) Dimensions from Responsive, or 3.) Highcharts default.
        let chartWidth = width || ( dimensions ? dimensions.width : null );
        const chartHeight = height
            ? height
            : dimensions
                ? dimensions.height
                : null;

        if (chartWidth > window.innerWidth) {
            chartWidth = window.innerWidth;
        }

        const showLoader = loading || _.isNil(histories);
        const noData = histories.length === 0;
        const showError = noData && !_.isNil(error);

        const skeletonContent = showLoader && <Loader color='BLUE' size='LARGE' />
            || showError && error
            || noData && t('No data available')
            || undefined;

        if (skeletonContent !== undefined) {
            return (
                <SkeletonChart
                    width={chartWidth ? `${chartWidth}px` : null}
                    height={chartHeight ? `${chartHeight}px` : null}>
                    { skeletonContent }
                </SkeletonChart>
            );
        }

        // Take the first sensor name and unit for the series name since they are all the same.
        const sensorName = _.get(sensorNames, [histories[0].sensorName])
            || histories[0].sensorName;
        const sensorUnit = unit ||
            _.get(sensorConfigurations, [histories[0].sensorName, 'unit']) ||
            '';

        // Find extremes from the data set
        const stats = analyzeSeries(histories);
        const end = moment.utc(maxX ? maxX : stats.maxTimestamp).valueOf();
        const start = moment.utc(minXRange ?
            moment.min(moment(end).subtract(minXRange, 'ms'), stats.minTimestamp) :
            stats.minTimestamp
        ).valueOf();

        const thresholds = generateTresholds(start, end, getThreshold, thresholdGranularity);
        const series = [
            {
                name: sensorName,
                type: isBooleanType ? 'line' : 'spline',
                data: _.map(
                    histories,
                    point => [
                        moment.utc(point.timestamp).local().valueOf(),
                        point.avg === undefined ? point.value : point.avg
                    ]
                ),
                yAxis: 0,
                zIndex: 2,
                color: this.props.theme.colors.midnight,
                singleLine: true,
                unit: sensorUnit,
                step: isBooleanType ? 'left' : undefined
            },
            ...getThresholdSeries(theme, thresholds),
        ];

        const config = {
            chart: {
                backgroundColor: 'transparent',
                plotBackgroundColor: theme.colors.white,
                spacing: [8, 8, 16, 8],
                width: chartWidth,
                height: chartHeight,
                className: 'performance-chart',
                marginTop: 50,
                marginRight: 10,
                spacingLeft: 30,
                spacingBottom: 20
            },
            title: false,
            credits: false,
            tooltip: {
                split: true,
                shared: false,
                crosshairs: true,
                borderWidth: 0,
                padding: 0,
                backgroundColor: null,
                useHTML: true,
                formatter: function() {
                    return tooltipFormatter(
                        this.points,
                        this.x,
                        theme,
                        chartHeight - 50,
                        'no temperature',
                        undefined,
                        undefined,
                        precision
                    );
                }
            },
            plotOptions: {
                spline: {
                    lineColor: theme.colors.midnight,
                    lineWidth: 2,
                    turboThreshold: 400, // Make rendering large series (> 400) more efficient.
                    marker: {
                        enabled: false,
                        lineColor: theme.colors.midnight,
                        lineWidth: 2,
                        radius: 5,
                        fillColor: this.props.theme.colors.white,
                        symbol: 'circle',
                        states: {
                            hover: {
                                lineWidthPlus: 0,
                                radiusPlus: 0,
                                shadow: false,
                                animation: { duration: 0 }
                            }
                        }
                    },
                    states: {
                        hover: {
                            lineWidthPlus: 0,
                            halo: { size: 0 }
                        }
                    }
                }
            },
            legend: false,
            xAxis: {
                type: 'datetime',
                lineWidth: 0,
                lineColor: theme.colors.mystic,
                minorGridLineWidth: 0,
                gridLineWidth: 0,
                gridLineColor: theme.colors.mystic,
                tickLength: 5,
                labels: {
                    y: 20,
                    style: {
                        color: theme.colors.rockBlue,
                        fontSize: theme.font.size.xxxs,
                        textTransform: 'uppercase',
                        letterSpacing: '1px',
                        fontWeight: theme.font.weight.bold,
                    }
                },
                min: start,
                max: end,
            },
            yAxis: {
                min: _.isNumber(yMin) ? yMin : null,
                max: _.isNumber(yMax) ? yMax : null,
                lineWidth: 0,
                lineColor: theme.colors.mystic,
                labels: {
                    style: {
                        color: theme.colors.rockBlue,
                        fontSize: theme.font.size.xxxs,
                        textTransform: 'uppercase',
                        letterSpacing: '1px',
                        fontWeight: theme.font.weight.bold,
                    }
                },
                title: {
                    align: 'high',
                    offset: 0,
                    text: yAxisTitle || sensorUnit,
                    rotation: 0,
                    y: -20,
                    style: {
                        color: theme.colors.black,
                        fontWeight: theme.font.weight.bold,
                    }
                },
                minorGridLineWidth: 0,
                gridLineWidth: 1,
                gridLineColor: theme.colors.mystic,
            },
            series: series
        };

        return (
            <HighchartsReact
                highcharts={ Highcharts }
                options={ config }
                ref="PerformanceChart"
            />
        );
    }

    render() {
        return (
            <StyledSensorChart compact={ this.props.compact }>
                { this.renderChart() }
            </StyledSensorChart>
        );
    }
}

export default Responsive(withTheme(PerformanceChart));
