import React from 'react';
import Helmet from 'react-helmet';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import FadeTransition from 'components/FadeTransition/FadeTransition';
import AdminPage from './AdminPage';

import { default as AdminCreateUser } from './AdminCreateUser.jsx';
import { default as AdminEditUser } from './AdminEditUser.jsx';
import { default as AdminManageUsers } from './AdminManageUsers.jsx';
import { default as AdminEditBuilding } from './Building/AdminEditBuilding.jsx';
import { default as AdminEditPartner } from './Partner/AdminEditPartner.jsx';
import { default as AdminGeneralAnnouncements } from './AdminGeneralAnnouncements.jsx';
import { default as AdminTags } from './AdminTags.jsx';
import { default as AdminManageCustomViews } from './CustomViews/AdminManageCustomViews.jsx';
import { default as AdminTranslations } from './AdminTranslations.jsx';

export const Admin = ({ t, match }) =>
    <FadeTransition>
        <React.Fragment>
            <Helmet title={t('Admin')} />
            <Route exact path={match.url} render={() => <AdminPage match={match} />} />
            <Route exact path={`${match.url}/Users/Create`} component={AdminCreateUser} />
            <Route exact path={`${match.url}/Users/Edit/:id`} component={AdminEditUser} />
            <Route exact path={`${match.url}/Users`} component={AdminManageUsers} />
            <Route exact path={`${match.url}/Partners/Edit/:partnerNumber`} component={AdminEditPartner} />
            <Route exact path={`${match.url}/Announcements`} component={AdminGeneralAnnouncements} />
            <Route exact path={`${match.url}/Buildings/Edit/:functionalLocationId`} component={AdminEditBuilding} />
            <Route exact path={`${match.url}/Tags`} component={AdminTags} />
            <Route exact path={`${match.url}/CustomViews`} component={AdminManageCustomViews} />
            <Route exact path={`${match.url}/Translations`} component={AdminTranslations} />
        </React.Fragment>
    </FadeTransition>;

export const adminCommonPropType = {
    match: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

Admin.propTypes = adminCommonPropType;

export default translations(Admin);
