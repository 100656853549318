import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import translations from 'decorators/Translations/translations';
import { find, map, orderBy, keys, sortedUniq, cloneDeep, isArray, isEqual, filter, head, chain,
    get, startsWith, isNil, isEmpty, trimStart } from 'lodash';
import memoizeOne from 'memoize-one';

import SensorConfiguration from 'components/SensorConfiguration/SensorConfiguration';
import {
    SAVE_TYPES,
    SUBSENSOR_SAVE_TYPES,
    GRANULARITIES,
    SENSOR_APPEARANCES,
    PRESENCE_TYPES,
} from 'components/SensorConfiguration/utils';
import {
    upsertSensor,
    upsertHierarchiesForSensor,
    loadSensorHierarchies,
    upsertCoords,
    deleteCoords
} from 'redux/modules/customer/sensorHierarchy.js';
import { upsertAllSensorMeta, sensorBuildingsForMeta } from 'redux/modules/index.js';
import {
    getBuildingAreasFromHierarchy,
    getBuildingGroupsFromHierarchy,
    getSensorBuildingOrFloorParentFromHierarchy,
    getSensorFromHierarchy,
    findHierarchy,
    getAssignedGroupsFromHierarchy,
    createFeature,
} from 'utils/Data/sensorHierarchy';
import { createFloorFeatures } from './utils';

const isSigfox = memoizeOne((sensorTypeId, sensorTypes) => {
    const type = sensorTypes.find(sensorType => sensorType.id === sensorTypeId);
    return type && type.sensorTypeGroups.some(group => group.name === 'sigfox') || false;
});

const isPerformanceType = memoizeOne((sensorTypeId, sensorTypes) => {
    const type = sensorTypes.find(sensorType => sensorType.id === sensorTypeId);
    return type ? type.sensorTypeGroups.some(group => group.name === 'technical_performance') : false;
});

const getSensorTypeOptions = memoizeOne((sensorTypes, t) => sensorTypes.reduce((acc, type) => {
    if (type.sensorTypeGroups) {
        // Skip sensor types that should only be created by the system
        if (
            type.sensorTypeGroups.some(group => group.name === 'technical_performance') ||
            type.sensorTypeGroups.some(group => group.name === 'weather')
        ) {
            return acc;
        }

        if (type.sensorTypeGroups.some(group => group.name === 'sigfox')) {
            acc.sigfox.push({
                value: type.id,
                label: t(`use_case_${type.name}`),
                name: type.name,
            });

            return acc;
        }
    }

    acc.other.push({
        value: type.id,
        label: t('Sensor_' + type.name),
    });

    return acc;
}, { sigfox: [], other: [] }));

const sigfoxTypeOptionsOrder = ['sigfox_sensor', 'sigfox_outdoor', 'sigfox_cooler', 'sigfox_freezer', 'sigfox_storage'];
const sortSigfoxTypeOptions = memoizeOne(options => orderBy(options, option => {
    const order = sigfoxTypeOptionsOrder.indexOf(option.name);
    return order !== -1 ? order : Number.POSITIVE_INFINITY;
}));

class EditOrCreateSensor extends Component {
    state = {
        // Sensor coordinates
        coords: [],
        // Loading state
        loading: false,
        // Form model
        form: {
            // Default hierarchy id is default location (floor or building) for sensor
            defaultHierarchyId: null,
            // Area sensor belongs to
            area: null,
            // Groups sensor belongs to
            groups: [],
            // Where sensor is saved
            saveType: SAVE_TYPES.TO_BUILDING,
        },
        // Original areas set to sensor
        originalArea: null,
        // Original groups set to sensor
        originalGroups: [],
        // Subsensors
        subSensors: []
    };

    componentDidMount() {
        const {
            coords,
            editId,
            editParentId,
            hierarchy
        } = this.props;

        // If editing a sensor, load sensor data to form
        if (!this.isCreateMode(editId)) {
            const sensor = getSensorFromHierarchy(hierarchy, editId);
            const buildingId = !sensor.parentId && sensor.sensorHierarchyBuildingId;
            const subSensors = sensor.children;

            // Get floor or building where sensor is bound to
            const defaultHierarchy = getSensorBuildingOrFloorParentFromHierarchy(editId, hierarchy, buildingId);
            const defaultHierarchyId = defaultHierarchy ? defaultHierarchy.id : null;

            // Get saveType (sensor has coords or by parent)
            const saveType = this.getSaveType({
                hasCoords: coords && coords.length > 0,
                hierarchyParentType: defaultHierarchy ? defaultHierarchy.type : null,
                saveTypeId: sensor.saveTypeId,
            });

            // Get floor if sensor is bound to one
            let floor = (saveType === SAVE_TYPES.TO_COORDS || saveType === SAVE_TYPES.TO_FLOOR) && defaultHierarchyId;

            // Get floor from parent so that it is selected if user changes saveType
            if (saveType === SUBSENSOR_SAVE_TYPES.TO_PARENT_SENSOR) {
                const parentSensorHierarchy = getSensorBuildingOrFloorParentFromHierarchy(sensor.parentId, hierarchy);
                if (parentSensorHierarchy && parentSensorHierarchy.type === 'floor') {
                    floor = parentSensorHierarchy.id;
                }
            }

            // Get all hierarchy groups where sensor is, separate area and groups
            const hierarchyGroups = getAssignedGroupsFromHierarchy(hierarchy, editId);
            const assignedArea = find(hierarchyGroups, { type: 'area' });
            const assignedGroups = chain(hierarchyGroups)
                .filter(group => startsWith(group.type, 'group'))
                .map(group => group.id)
                .value();

            // Sigfox id from sensor meta
            const originalSigfoxMeta = find(sensor.sensorMeta, { 'metaKey': 'sigfox_id' });
            const sigfoxId = originalSigfoxMeta && originalSigfoxMeta.value;

            // Extract measuring points shown on blueprint from coordinates
            const coordsSubsensors = (sensor.coords || []).reduce((acc, coord) => {
                if (coord.subsensors) {
                    const options = coord.subsensors
                        .map(subsensor => subsensor.sensorId === sensor.id ?
                            sensor :
                            find(subSensors, { id: subsensor.sensorId })
                        )
                        .filter(subsensor => subsensor)
                        .map(subsensor => ({ value: subsensor.id, label: subsensor.name }));

                    return [...acc, ...options];
                }

                return acc;
            }, []);

            this.setState(prevState => ({
                form: {
                    ...prevState.form,
                    ...sensor,
                    defaultHierarchyId,
                    saveType,
                    area: assignedArea ? assignedArea.id : null,
                    groups: assignedGroups || [],
                    floor,
                    sigfoxId,
                    coordsSubsensors,
                },
                originalCoords: coords || null,
                originalFloor: floor || null,
                originalArea: assignedArea ? assignedArea.id : null,
                originalGroups: assignedGroups || [],
                originalSensorType: sensor.sensorType,
                originalHierarchyId: defaultHierarchy ? defaultHierarchy.id : null,
                originalSigfoxMeta,
                subSensors,
                coords: coords || [],
            }));
        } else {
            // Get parent hierarchy and save type
            const defaultHierarchy = findHierarchy(hierarchy, editParentId);
            const defaultHierarchyId = defaultHierarchy ? defaultHierarchy.id : null;
            const saveType = this.getSaveType({
                hasCoord: false,
                hierarchyParentType: defaultHierarchy ? defaultHierarchy.type : null
            });
            // Get floor if default parent is one
            const floor = (saveType === SAVE_TYPES.TO_COORDS || saveType === SAVE_TYPES.TO_FLOOR) && defaultHierarchyId;

            this.setState(prevState => ({
                form: {
                    ...prevState.form,
                    saveType,
                    defaultHierarchyId,
                    floor,
                    sensorTypeId: this.getSigfoxTypeId(),
                    name: 'Sigfox',
                    coordsSubsensors: [],
                }
            }));
        }
    }

    // Get save type for opened sensor
    getSaveType = ({ hasCoords, hierarchyParentType, sensorTypeId }) => {
        if (this.isPresenceTypeId(sensorTypeId)) {
            return this.isCoordsPresenceTypeId(sensorTypeId) ? SAVE_TYPES.TO_COORDS : SAVE_TYPES.TO_FLOOR;
        }
        switch (hierarchyParentType) {
        case 'building':
            return SAVE_TYPES.TO_BUILDING;
        case 'floor':
            return hasCoords ? SAVE_TYPES.TO_COORDS : SAVE_TYPES.TO_FLOOR;
        default:
            return SUBSENSOR_SAVE_TYPES.TO_PARENT_SENSOR;
        }
    };

    isCreateMode(mode) {
        return mode === 'add';
    }

    // Sensor save logic
    saveSensor = (coords, shouldDeleteCoords, sensorHierarchyId, originalParentId, sigfoxMeta) => {
        const { coordsId, upsertSensor } = this.props;
        const { form: { defaultHierarchyId }, form } = this.state;
        const model = cloneDeep(form);
        const sensorHierarchyBuildingId = get(this.props, 'hierarchy.id');
        const data = {
            id: model.id,
            name: model.name,
            sensorTypeId: model.sensorTypeId,
            sensorHierarchyBuildingId,
            functionalLocation: model.functionalLocation || null,
            granularity: model.granularity || null,
            default: model.default || null,
            equipmentNumber: model.equipmentNumber || null,
            sensorHierarchyId,
            type: model.type
        };

        this.setState({ loading: true });

        upsertSensor(data).then(({ result }) => {
            if (result) {
                const {
                    originalArea,
                    originalGroups,
                    form: {
                        area,
                        groups,
                        coordsSubsensors,
                    }
                } = this.state;
                const {
                    functionalLocation,
                    upsertCoords,
                    upsertHierarchiesForSensor,
                    deleteCoords,
                    upsertAllSensorMeta,
                } = this.props;

                const promises = [];
                const sensorId = result.id;

                if (sigfoxMeta) {
                    promises.push(upsertAllSensorMeta([{ ...sigfoxMeta, sensorId }]));
                }

                let newHierarchies = [];
                let shouldDeleteHierarchy = false;

                // If configuring this sensor affects any hierarchies, they need to be updated
                // Compare original hierarchyIds and groups and area to values in model
                const sortedGroups = sortedUniq(groups);
                const sortedOriginalGroups = sortedUniq(originalGroups);
                const groupsAreArrays = isArray(groups) && isArray(originalGroups);
                if (
                    originalParentId !== defaultHierarchyId ||
                    originalArea !== area ||
                    groupsAreArrays && !isEqual(sortedGroups, sortedOriginalGroups)
                ) {
                    // add groups first
                    if (sortedGroups && sortedGroups.length > 0) {
                        newHierarchies = newHierarchies.concat(sortedGroups);
                    }
                    // handle sensor that is bound to floor or building
                    if (defaultHierarchyId) {
                        newHierarchies.push(defaultHierarchyId);
                        if (area) {
                            newHierarchies.push(area);
                        }
                    } else if (model.parentId && !newHierarchies.length) {
                        // handle subsensors that don't have groups or are not bound to floor or building
                        // sensor is a subsensor, and it is saved back to "uncategorized"
                        // (upserting empty hierarchy array will cause the deletion of the old hierarchy bindings)
                        shouldDeleteHierarchy = true;
                    }
                }
                if (newHierarchies.length > 0 || shouldDeleteHierarchy) {
                    promises.push(upsertHierarchiesForSensor(sensorId, newHierarchies));
                }
                // Delete coordinates if flagged as deletable and exist
                if (shouldDeleteCoords && coordsId) {
                    promises.push(deleteCoords(coordsId, model.functionalLocation));
                } else if (coords && coords.length === 2) {
                    // Save coordinates if available
                    const coordsData = {
                        functionalLocation,
                        type: 'sensor',
                        sensorId,
                        point: {
                            x: coords[0],
                            y: coords[1]
                        },
                        subsensors: coordsSubsensors,
                    };
                    if (coordsId) {
                        coordsData.id = coordsId;
                    }
                    promises.push(upsertCoords(coordsData));
                }
                Promise.all(promises).then(() => {
                    this.afterSubmit();
                });
            }
        });
    };

    afterSubmit = () => {
        const { loadSensorHierarchies, functionalLocation, onSubmit, t } = this.props;

        loadSensorHierarchies(functionalLocation).then(() => {
            this.setState({ loading: false });

            if (typeof onSubmit === 'function') {
                const notification = {
                    type: 'success',
                    message: t('Sensor successfully saved')
                };
                onSubmit(null, notification);
            }
        });
    };

    handleSubmit = () => {
        const { form: { saveType, defaultHierarchyId, sigfoxId }, originalSigfoxMeta } = this.state;
        const { editId, hierarchy } = this.props;
        let coords, deleteCoords = false, sigfoxMeta;

        if (saveType === SAVE_TYPES.TO_COORDS) {
            coords = this.state.coords;
        } else {
            // If not saving to coordinates, set flag to delete coords
            deleteCoords = true;
        }

        // For new sensors, save sensor with defaultHierarchyId to prevent orphan sensors
        // Otherwise we leave this null and handle hierarchy binding later
        const sensorHierarchyId = this.isCreateMode(editId) ? defaultHierarchyId : null;

        const originalParent = getSensorBuildingOrFloorParentFromHierarchy(editId, hierarchy);
        const originalParentId = originalParent ? originalParent.id : null;

        // Prepare sensor meta row for sigfox sensor, trim starting zeroes
        if (!isNil(sigfoxId)) {
            sigfoxMeta = originalSigfoxMeta
                ? { ...originalSigfoxMeta, value: trimStart(sigfoxId, '0') }
                : { metaKey: 'sigfox_id', value: trimStart(sigfoxId, '0') };
        }

        this.saveSensor(coords, deleteCoords, sensorHierarchyId, originalParentId, sigfoxMeta);
    };

    setCoordinates = coords => {
        this.setState({
            coords: coords
        });
    };

    handleFormChange = (property, value) => {
        const values = isArray(value) ? map(value, 'value') : value;
        const { floors, sensorDataTypes } = this.props;

        this.setState(prevState => {
            const form = {
                ...prevState.form,
                [property]: values
            };
            let coords = [...prevState.coords];

            // Set defaultHierarchyId to building hierarchy id if user has selected building
            if (property === 'saveType') {
                if (value === SAVE_TYPES.TO_BUILDING) {
                    form.defaultHierarchyId = this.props.hierarchy.id;
                } else if (value === SUBSENSOR_SAVE_TYPES.TO_PARENT_SENSOR) {
                    // Set defaultHieararcyId to null if we have subsensor that we don't want to bind anywhere
                    form.defaultHierarchyId = null;
                } else {
                    // TO_COORDS or TO_FLOOR
                    // defaultHierarchyId to floor
                    form.defaultHierarchyId = prevState.form.floor;

                    // set first floor as the selected floor
                    if (!prevState.form.floor) {
                        const defaultFloor = head(orderBy(floors, ['order'], ['asc']));
                        form.floor = defaultFloor.id;
                        form.defaultHierarchyId = defaultFloor.id;
                    }
                }
            }
            // Reset areas on floor change, set defaultHierarchyId to floor
            // Reset coordinates on floor change
            if (property === 'floor' && prevState.form && prevState.form.floor !== value) {
                form.area = null;
                form.defaultHierarchyId = value;

                // Use original coordinates if available
                if (value === prevState.originalFloor) {
                    form.coords = [...this.props.coords];
                    coords = [...this.props.coords];
                } else {
                    // Otherwise reset coordinates
                    form.coords = [];
                    coords = [];
                }
            }

            // Change save type for presence sensors
            if (property === 'sensorTypeId' && this.isPresenceTypeId(value) && !isEmpty(floors)) {
                if (this.isCoordsPresenceTypeId(value)) {
                    form.saveType = SAVE_TYPES.TO_COORDS;
                } else {
                    form.saveType = SAVE_TYPES.TO_FLOOR;
                }
                // copy-pasted from above:

                // defaultHierarchyId to floor
                form.defaultHierarchyId = prevState.form.floor;

                // set first floor as the selected floor
                if (!prevState.form.floor) {
                    const defaultFloor = head(orderBy(floors, ['order'], ['asc']));
                    form.floor = defaultFloor.id;
                    form.defaultHierarchyId = defaultFloor.id;
                }
            }

            // Auto-generate name for sigfox sensors
            if (property === 'sensorTypeId' && this.isSigfoxTypeId(value) && isEmpty(form.name)) {
                form.name = 'Sigfox';
            }

            // Auto-generate device name
            if (property === 'sensorTypeId' && !form.parentId && isEmpty(form.name)) {
                form.name = (find(sensorDataTypes, { id: value }) || {}).name;
            }

            // change sensorTypeId to sigfox sensortype OR original sensorType
            if (property === 'isSigfox') {
                form.sensorTypeId = value
                    ? (find(sensorDataTypes, { name: 'sigfox_sensor' }) || {}).id
                    : this.state.originalSensorType
                        ? this.state.originalSensorType.id
                        : null;

                // auto-generate name
                if (value && isEmpty(form.name)) {
                    form.name = 'Sigfox';
                } else if (!value && form.name === 'Sigfox') {
                    form.name = '';
                }
            }

            if (property === 'sigfoxId') {
                this.checkSigfoxId(value);
            }

            return {
                form,
                coords
            };
        });
    };

    getAvailableAreasForFloor = floorId => {
        const { areas } = this.props;
        return filter(areas, { parentId: floorId });
    };

    getGroups = () => {
        return this.props.groups || [];
    };

    isPresenceTypeId = sensorTypeId =>
        startsWith((find(this.props.sensorDataTypes, { id: sensorTypeId }) || {}).name, 'presence');

    isSigfoxTypeId = sensorTypeId =>
        startsWith((find(this.props.sensorDataTypes, { id: sensorTypeId }) || {}).name, 'sigfox');

    getSigfoxTypeId = () =>
        (find(this.props.sensorDataTypes, type => startsWith(type.name, 'sigfox')) || {}).id;

    isCoordsPresenceTypeId = sensorTypeId => {
        const sensorTypeName = (find(this.props.sensorDataTypes, { id: sensorTypeId }) || {}).name;
        return sensorTypeName === PRESENCE_TYPES.seat || sensorTypeName === PRESENCE_TYPES.zone;
    };

    checkSigfoxId = async sigfoxId => {
        this.setState({ searchingSigfoxId: true });

        const searchMeta = { metaKey: 'sigfox_id', value: trimStart(sigfoxId, '0') };
        const response = await this.props.sensorBuildingsForMeta(searchMeta);
        let foundFls = null;

        if (!isEmpty(response.result)) {
            foundFls = response.result.join(', ');
        }

        this.setState({ searchingSigfoxId: false, sigfoxIdSearchResult: foundFls });
    };

    render() {
        const {
            t,
            editId,
            floors,
            onCancel,
            sensorDataTypes,
            latestValuesBySensorId,
            hierarchy,
        } = this.props;
        const {
            coords,
            form,
            subSensors,
            originalSensorType,
            loading,
            searchingSigfoxId,
            sigfoxIdSearchResult,
        } = this.state;

        let featureCollection, image, floorOptions, areaOptions, parentSaveType;

        const hasFloors = floors && floors.length > 0;

        // Create feature collection for openlayers
        if (form.saveType === SAVE_TYPES.TO_COORDS && form.floor) {
            const features = createFloorFeatures(
                find(floors, { id: form.floor }),
                latestValuesBySensorId,
                t,
                form.id || 'id'
            );

            // change sensor feature when coords change
            if (form.coords && form.coords.length > 0 && coords && coords.length > 0 && !isEqual(coords, form.coords)) {
                const sensorFeature = features && features.sensors && features.sensors.features
                    && find(
                        features.sensors.features,
                        feature => feature.properties && feature.properties.sensorId === form.id
                    );
                if (sensorFeature && sensorFeature.geometry) {
                    sensorFeature.geometry.coordinates = coords;
                }
            } else if (coords && coords.length > 0) {
                // add new feature when a sensor gets coords
                const newFeature = createFeature(coords, 'id', 'Point', '');
                features && features.sensors && features.sensors.features.push(newFeature);
            }

            featureCollection = [[features.sensors], [features.areas]];
        }

        // Get floor image for mapping to coordinates
        if (form.defaultHierarchyId && form.saveType === SAVE_TYPES.TO_COORDS) {
            const floor = find(floors, { id: form.defaultHierarchyId });
            if (floor) {
                image = find(floor.images, { type: 'floor' });
            }
        }

        // Flooroptions and areaoptions, if saving to floor
        if (form.saveType === SAVE_TYPES.TO_COORDS || form.saveType === SAVE_TYPES.TO_FLOOR) {
            areaOptions = map(
                this.getAvailableAreasForFloor(form.defaultHierarchyId),
                area => ({ value: area.id, label: `${area.id}: ${area.name}` })
            );

            // Get ordered floor options
            if (hasFloors) {
                floorOptions = map(
                    orderBy(floors, ['order'], ['asc']),
                    floor => ({ label: floor.shortName, value: floor.id })
                );
            }
        }

        // For subsensors, get area and floor options based on parent
        if (form.saveType === SUBSENSOR_SAVE_TYPES.TO_PARENT_SENSOR) {
            const parentSensorHierarchy = getSensorBuildingOrFloorParentFromHierarchy(form.parentId, hierarchy);
            parentSaveType = SAVE_TYPES.TO_BUILDING;
            if (parentSensorHierarchy && parentSensorHierarchy.type === 'floor') {
                parentSaveType = SAVE_TYPES.TO_FLOOR;
                const floor = parentSensorHierarchy.id;
                areaOptions = map(
                    this.getAvailableAreasForFloor(floor),
                    area => ({ value: area.id, label: `${area.id}: ${area.name}` })
                );
                if (hasFloors) {
                    floorOptions = map(
                        orderBy(floors, ['order'], ['asc']),
                        floor => ({ label: floor.shortName, value: floor.id })
                    );
                }
            }
        }

        const groupOptions = map(this.getGroups(), group => ({ value: group.id, label: `${group.id}: ${group.name}` }));
        const subsensorOptions = map(subSensors, sensor => ({ value: sensor.id, label: sensor.name }));

        const floorplanValueOptions = [
            isPerformanceType(form.sensorTypeId, sensorDataTypes) && { value: form.id, label: form.name },
            ...subsensorOptions,
        ].filter(option => option);

        const sensorPresenceTypeOptions = {
            seat: (find(sensorDataTypes, { name: PRESENCE_TYPES.seat }) || {}).id,
            zone: (find(sensorDataTypes, { name: PRESENCE_TYPES.zone }) || {}).id,
            area: (find(sensorDataTypes, { name: PRESENCE_TYPES.area }) || {}).id,
        };
        const showPresenceType = this.isPresenceTypeId(form.sensorTypeId);

        const showSigfoxId = isSigfox(form.sensorTypeId, sensorDataTypes);

        const sensorGranularityOptions = map(
            keys(GRANULARITIES),
            type => ({ value: get(GRANULARITIES[type], 'value'), label: t(get(GRANULARITIES[type], 'label')) })
        );

        const appearance = form.parentId
            ? SENSOR_APPEARANCES.measuringPoint
            : SENSOR_APPEARANCES.device;

        const sensorTypeOptions = getSensorTypeOptions(sensorDataTypes, t);
        return (
            <SensorConfiguration
                appearance={ appearance }
                t={t}
                isCreateMode={ this.isCreateMode(editId) }
                isSapEquipment={ originalSensorType && originalSensorType.name === 'sap-equipment' }
                onCancel={ onCancel }
                onSubmit={ this.handleSubmit }
                onFormChange={ this.handleFormChange }
                model={ form }
                loading={ loading }
                floorOptions={ floorOptions }
                groupOptions={ groupOptions }
                areaOptions={ areaOptions }
                sensorTypeOptions={ sensorTypeOptions.other }
                sensorPresenceTypeOptions={ sensorPresenceTypeOptions }
                floorplanValueOptions={ floorplanValueOptions }
                showPresenceType={ showPresenceType }
                showSigfoxId={ showSigfoxId }
                subsensorOptions={ subsensorOptions }
                granularityOptions={ sensorGranularityOptions }
                featureCollection={ featureCollection }
                image={ image }
                coordsEditMode={ coords && coords.length > 0 ? true : 'point' }
                setCoordinates={ this.setCoordinates }
                hasFloors={ hasFloors }
                searchingSigfoxId={ searchingSigfoxId }
                sigfoxIdSearchResult={ sigfoxIdSearchResult }
                parentSaveType={ parentSaveType }
                sigfoxTypeOptions={ sortSigfoxTypeOptions(sensorTypeOptions.sigfox) }
            />
        );
    }
}

EditOrCreateSensor.defaultProps = {
    feature: {},
    coordsId: null,
    coords: [],
    floors: [],
    sensorDataTypes: []
};

EditOrCreateSensor.propTypes = {
    // redux:
    /** List of all areas in the hierarchy */
    areas: PropTypes.array.isRequired,
    /** List of groups in the hierarchy */
    groups: PropTypes.array.isRequired,
    /** Current FL sensorHierarchy */
    hierarchy: PropTypes.object.isRequired,
    /** List of available sensor data types */
    sensorDataTypes: PropTypes.arrayOf(PropTypes.object),
    /** Redux dispatchers for load, upsert, and deletion */
    upsertSensor: PropTypes.func.isRequired,
    upsertHierarchiesForSensor: PropTypes.func.isRequired,
    loadSensorHierarchies: PropTypes.func.isRequired,
    upsertCoords: PropTypes.func.isRequired,
    deleteCoords: PropTypes.func.isRequired,
    sensorBuildingsForMeta: PropTypes.func.isRequired,
    /** Translation function */
    t: PropTypes.func.isRequired,
    /** Id of the sensor that is edited
     * if editId==='all', a new sensor is created
     **/
    editId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    /** Id of the currently selected floor in admin OR building for *new sensors* */
    editParentId: PropTypes.number,
    /** FL the sensor is on */
    functionalLocation: PropTypes.string.isRequired,
    /** Callback for submit */
    onSubmit: PropTypes.func.isRequired,
    /** Callback for cancellation */
    onCancel: PropTypes.func.isRequired,
    /** Openlayers feature for the sensor */
    feature: PropTypes.object,
    /** Sensor coordinates id */
    coordsId: PropTypes.number,
    /** Sensor coordinates */
    coords: PropTypes.arrayOf(PropTypes.number),
    /** List of all floors in the building */
    floors: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state, props) => {
    const functionalLocation = props.functionalLocation;
    const hierarchy = head(state.sensorHierarchy.buildingHierarchy[functionalLocation]);
    let areas = [];
    let groups = [];
    if (hierarchy && hierarchy.id) {
        areas = getBuildingAreasFromHierarchy(hierarchy);
        groups = getBuildingGroupsFromHierarchy(hierarchy);
    }
    return {
        areas,
        groups,
        hierarchy,
        sensorDataTypes: state.sensorHierarchy.sensorDataTypes,
        latestValuesBySensorId: state.values.latestValuesBySensorId,
    };
};

const mapDispatchToProps = dispatch => ({
    upsertSensor: data => dispatch(upsertSensor(data)),
    upsertHierarchiesForSensor: (sensorId, hierarchies) => dispatch(upsertHierarchiesForSensor(sensorId, hierarchies)),
    loadSensorHierarchies: functionalLocation => dispatch(loadSensorHierarchies(functionalLocation, true)),
    upsertCoords: data => dispatch(upsertCoords(data)),
    deleteCoords: (id, functionalLocation) => dispatch(deleteCoords(id, functionalLocation)),
    upsertAllSensorMeta: sensorMeta => dispatch(upsertAllSensorMeta(sensorMeta)),
    sensorBuildingsForMeta: meta => dispatch(sensorBuildingsForMeta(meta)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(EditOrCreateSensor));

