import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';


const List = styled.ul`
    margin-bottom: ${props => props.theme.spacing.lg};
`;
List.displayName = 'List';

const Classification = styled.li`
    margin-top: ${props => props.theme.spacing.md};
`;
Classification.displayName = 'Classification';

const ClassProp = styled.p`
    color: ${props => props.theme.colors.black};
    margin-top: ${props => props.theme.spacing.xxs};
`;
ClassProp.displayName = 'ClassProp';

const ClassKey = styled.span`
    font-weight: ${props => props.theme.font.weight.bold};
    text-transform: capitalize;
`;
ClassKey.displayName = 'ClassKey';

const KEYS = ['characteristic', 'value', 'functionalLocation', 'path'];


const ClassificationList = ({ classifications }) => {

    const propertyItem = (key, value) =>
        <ClassProp key={ key }><ClassKey>{ `${key}: `}</ClassKey>
            { Array.isArray(value) ? value.join(' ') : value }
        </ClassProp>;

    return (
        <List>
            { classifications.map((classification, index) =>
                <Classification key={ index }>
                    { KEYS.map(key => propertyItem(key, classification[key])) }
                </Classification>
            ) }
        </List>
    );
};

ClassificationList.propTypes = {
    classifications: PropTypes.array.isRequired,
};

export default ClassificationList;
