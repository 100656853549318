import React from 'react';
import styled from 'styled-components';
import TableCell from 'components/SortableTable/SortableTableCell';
import SkeletonText from './SkeletonText';

const TableBody = styled.tbody``;
const TableBodyRow = styled.tr``;

const SkeletonTableBody = props => {
    return (
        <TableBody>
            <TableBodyRow>
                { props.columns && props.columns.map(( column, index ) => {
                    if (index === 0) {
                        return (
                            <TableCell key={`${column}-${index}`}>
                                <SkeletonText style={{ marginLeft: 0 }} />
                            </TableCell>
                        );
                    }
                    return (
                        <TableCell key={`${column}-${index}`} style={{ verticalAlign: 'top' }}>
                            <SkeletonText style={{ marginLeft: 0 }} />
                        </TableCell>
                    );
                })}
            </TableBodyRow>
        </TableBody>
    );
};

export default SkeletonTableBody;
