import React, { Component } from 'react';
import translations from 'decorators/Translations/translations';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Hero from 'components/Hero/Hero';
import PartnerImage from './PartnerImage';
import StandardPage from 'components/StandardPage/StandardPage';
import { connect } from 'react-redux';
import { loadPartnerImages } from 'redux/modules/customer/partnerImage';
import {
    loadCustomers,
    loadPartnerMeta,
    setPartnerMeta,
    savePartnerMeta,
    showModal,
    deletePartnerMeta,
} from 'redux/modules';
import Tabs from 'components/Tabs/Tabs';
import Tab from 'components/Tabs/Tab';
import AdminGeneralAnnouncements from '../AdminGeneralAnnouncements';
import { ANNOUNCEMENT_TYPE } from 'components/Announcement/utils';
import { canEditPartner } from 'utils/Data/profileData';
import Header from 'containers/Application/Header/Header.jsx';
import { icons } from 'components/Icon/IconNames';
import AdminPartnerConfig from '../AdminPartnerConfig';

class AdminEditPartner extends Component {
    state = {
        partnerImage: null
    };

    componentDidMount() {
        const { partnerNumber } = this.props.match.params;
        this.props.loadCustomers([partnerNumber]);
        this.props.loadPartnerImages(partnerNumber)
            .then(() => this.setState({ partnerImage: this.getPartnerImage(this.props) }));
        this.props.loadPartnerMeta(partnerNumber);
    }

    componentDidUpdate(prevProps) {
        const partnerNumber = prevProps.match.params.partnerNumber;
        if (!_.isEqual(prevProps.partnerImages[partnerNumber], this.props.partnerImages[partnerNumber])) {
            this.setState({ partnerImage: this.getPartnerImage(this.props) });
        }
    }

    getPartnerImage = props => {
        const { partnerNumber } = props.match.params;
        let partnerImagePath = '';
        if (partnerNumber && props.partnerImages && props.partnerImages[partnerNumber]) {
            const partnerImages = props.partnerImages[partnerNumber];
            const partnerImage = _.findLast(partnerImages, image => image.type === 'partner');
            if (partnerImage && partnerImage.path) {
                partnerImagePath = partnerImage.path;
            }
        }
        return partnerImagePath;
    };

    renderImageSelector = () => {
        const { match: { params: { partnerNumber } }, fileupload: { uploading } } = this.props;
        return (
            <PartnerImage partnerNumber={ partnerNumber } uploading={ uploading } />
        );
    };

    render() {
        const {
            t,
            match: {
                params: {
                    partnerNumber
                }
            },
            customers,
            profile,
            partnerMeta,
            setPartnerMeta,
            savePartnerMeta,
            showModal,
            deletePartnerMeta,
        } = this.props;
        const partner = _.find(customers, customer => customer.partnerNumber === partnerNumber);
        const partnerName = partner && partner.name;

        if (!canEditPartner(profile.role)) {
            return null;
        }

        const links = [
            {
                title: partnerName || '...',
                icon: icons.PORTFOLIO,
                to: { pathname: `/${partnerNumber}/Overview` },
                contextLink: true
            },
            { title: 'Configuration', icon: icons.SETTINGS },
            { title: 'Customer Announcements', icon: icons.ADMIN_MANAGE_NOTIFICATIONS },
        ];

        return (
            <StandardPage withTabs>
                <Header t={ t } links={ links } />
                <Hero
                    title={ t('Edit customer') }
                    subTitle={ partnerName }
                    t={ t }
                    customImageElement={ this.renderImageSelector() }
                />
                <Tabs t={t}>
                    <Tab title="Customer Announcements" key="Customer Announcements">
                        <AdminGeneralAnnouncements
                            type={ANNOUNCEMENT_TYPE.PARTNER}
                            partnerNumber={partnerNumber}
                            isComponent
                        />
                    </Tab>
                    <Tab title="Configuration" key="Configuration">
                        <AdminPartnerConfig
                            partnerNumber={ partnerNumber }
                            meta={ partnerMeta[partnerNumber] }
                            setPartnerMeta={ setPartnerMeta }
                            savePartnerMeta={ savePartnerMeta }
                            showModal={ showModal }
                            deletePartnerMeta={ deletePartnerMeta }
                        />
                    </Tab>
                </Tabs>
            </StandardPage>
        );
    }
}

AdminEditPartner.propTypes = {
    t: PropTypes.func.isRequired,
    customers: PropTypes.object.isRequired,
    fileupload: PropTypes.object.isRequired,
    loadPartnerImages: PropTypes.func.isRequired,
    partnerImages: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    fileupload: state.fileupload,
    partnerImages: state.partnerImage.byPartner,
    customers: state.customer.customers,
    profile: state.profile.profile,
    partnerMeta: state.partnerMeta.meta,
});

const mapDispatchToProps = dispatch => ({
    loadPartnerImages: partnerNumber => dispatch(loadPartnerImages(partnerNumber, true)),
    loadCustomers: ids => dispatch(loadCustomers(ids)),
    loadPartnerMeta: partnerNumber => dispatch(loadPartnerMeta(partnerNumber)),
    setPartnerMeta: (partnerNumber, meta) => dispatch(setPartnerMeta(partnerNumber, meta)),
    savePartnerMeta: (partnerNumber, metas, deleted) => dispatch(savePartnerMeta(partnerNumber, metas, deleted)),
    showModal: (modalType, preConditions, onSubmitAction, passedProps) =>
        dispatch(showModal(modalType, preConditions, onSubmitAction, passedProps)),
    deletePartnerMeta: (partnerNumber, index) => dispatch(deletePartnerMeta(partnerNumber, index)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(AdminEditPartner));
