import React, { PureComponent } from 'react';
import styled, { withTheme } from 'styled-components';
import { rgba } from 'polished';
import PropTypes from 'prop-types';

import Svg from 'components/Svg/Svg';
import GeneralAnnouncement from './GeneralAnnouncement';
import { icons } from 'components/Icon/IconNames';
import { announcementShape, ANNOUNCEMENT_TYPE } from './utils';
import { toggleScroll } from 'utils/Modal/toggleScroll';

const Container = styled.div`
    position: fixed;
    z-index: ${props => props.theme.zIndex('announcements') + 1};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: auto;

    ${props => props.theme.media.landscape`
        top: auto;
        top: 64px;
        right: 65px;
        bottom: auto;
        left: auto;
    `}
`;
Container.displayName = 'Container';

const Box = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: ${props => props.theme.colors.white};

    ${props => props.theme.media.landscape`
        width: 400px;
        height: 500px;
        margin-bottom: 10px;
        box-shadow: 0 2px 20px ${rgba(0, 0, 0, 0.08)};
    `}
`;
Box.displayName = 'Box';

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${props => props.theme.colors.white};
    margin: 0;
    width: 100%;
    height: 40px;
    cursor: pointer;
`;
Header.displayName = 'Header';

const Title = styled.h2`
    margin-left: 1.5em;
    transform: translateY(0.1em);
    font-family: ${props => props.theme.font.arial};
    font-size: ${props => props.theme.font.size.sm};
`;
Title.displayName = 'Title';

const CloseIcon = styled(Svg)`
    margin-right: 1.5em;
    fill: ${props => props.theme.colors.rockBlue};
    font-size: 0.8em;
    cursor: pointer;
`;
CloseIcon.displayName = 'CloseIcon';

const Scrollable = styled.div`
    height: calc(100% - 40px);
    overflow-y: auto;
    border: 1px solid ${props => props.theme.colors.lightGray};
`;
Scrollable.displayName = 'Scrollable';

const SubHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 1em;
    margin: 0;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.colors.lightGray};
`;
SubHeader.displayName = 'SubHeader';

const Unread = styled.div`
    border-radius: 1em;
    padding: 0.6em 1em 0.75em;
    background-color: ${props => props.theme.colors.rockBlue};
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.font.size.xxxs};
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: ${props => props.theme.font.weight.bold};
`;
Unread.displayName = 'Unread';

const MarkRead = styled.span`
    margin: 0.4em 1em;
    color: ${props => props.theme.colors.rockBlue};
    font-size: ${props => props.theme.font.size.xxs};
    font-family: ${props => props.theme.font.family.arial};
`;
MarkRead.displayName = 'MarkRead';

const CheckBall = styled.div`
    position: relative;
    float: right;
    left: 1em;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 16px;
    height: 16px;
    padding: 2px;

    border-radius: 50%;
    background-color: ${props => props.theme.colors.cerulean};
    border: 2px solid ${props => props.theme.colors.cerulean};
`;
CheckBall.displayName = 'CheckBall';

const Icon = styled(({ small, ...props }) => <Svg { ...props } />)`
    font-size: ${props => props.small ? '0.7em' : '2em'};
    fill: ${props => props.fill}
`;
Icon.displayName = 'Icon';

const Content = styled.div`
    section:last-child {
        border-bottom: 0;
    }
`;
Content.displayName = 'Content';

const NoAnnouncements = styled.div`
    color: ${props => props.theme.colors.darkGray};
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.font.size.xs};
    line-height: ${props => props.theme.font.lineHeight.lg};
    text-align: center;
    margin: auto;
    margin-top: 30%;
    width: 70%;
`;
NoAnnouncements.displayName = 'NoAnnouncements';

const NoAnnouncementsTitle = styled.h3`
    color: ${props => props.theme.colors.black};
    font-family: ${props => props.theme.font.family.sanchez};
    font-size: ${props => props.theme.font.size.sm};
    font-weight: ${props => props.theme.font.weight.bold};
    padding: 1em;
`;
NoAnnouncementsTitle.displayName = 'NoAnnouncementsTitle';

const Triangular = styled.div`
    ${props => props.theme.media.landscape`
        position: absolute;
        top: -10px
        right: 20px;
        width: 5px;
        height: 5px;
        border-width: 0 10px 10px;
        border-style: solid;
        border-color: ${props => props.theme.colors.transparent};
        border-bottom-color: ${props => props.theme.colors.white};
    `}
`;
Triangular.displayName = 'Triangular';


export class AnnouncementBox extends PureComponent {

    componentDidMount() {
        window.innerWidth <= 900 && toggleScroll(true);
    }

    componentWillUnmount() {
        if (this.props.unreadAnnouncements.length > 0) {
            this.props.saveAcknowledgements(this.props.unreadAnnouncements.map(announcement => announcement.id));
        }
        toggleScroll(false);
    }

    render() {
        const { announcements, t, onClose, unreadAnnouncements, onReadMore, customers, theme } = this.props;
        const MARK_READ_IMPLEMENTED = false;
        const hasAnnouncements = announcements.length + unreadAnnouncements.length > 0;

        const renderAnnouncement = ( announcement, index, unread ) =>
            <GeneralAnnouncement
                announcement={ announcement }
                unread={ unread }
                partner={ customers[announcement.partnerNumber] }
                key={`FLAnnouncement-${index}`}
                theme={ theme }
                onClick={ () => onReadMore(announcement) }
                t={ t } />;

        return (
            <Container data-test-id="AnnouncementBox">
                <Triangular />
                <Box>
                    <Header onClick={ onClose }>
                        <Title>{ t('Announcements') }</Title>
                        <CloseIcon data-test-id="AnnouncementBoxCloseIcon" name="remove" />
                    </Header>
                    { hasAnnouncements &&
                        <Scrollable>
                            { unreadAnnouncements.length > 0 &&
                                <SubHeader>
                                    <Unread>
                                        { `${unreadAnnouncements.length} ${t('New')} ${t('Since your last login')}` }
                                    </Unread>
                                    { MARK_READ_IMPLEMENTED &&
                                        <MarkRead>
                                            { t('Mark all as read') }
                                            <CheckBall>
                                                <Icon
                                                    small
                                                    name={ icons.OK }
                                                    fill={ theme.colors.white } />
                                            </CheckBall>
                                        </MarkRead> }
                                </SubHeader> }
                            <Content>
                                { unreadAnnouncements.map( ( announcement, index ) =>
                                    renderAnnouncement(announcement, index, true) ) }
                                { announcements.map( ( announcement, index ) =>
                                    renderAnnouncement(announcement, index, false) ) }
                            </Content>
                        </Scrollable> }
                    { !hasAnnouncements &&
                        <NoAnnouncements>
                            <NoAnnouncementsTitle>
                                { t('No announcements available') }
                            </NoAnnouncementsTitle>
                            { t('A red circle will appear on the bell icon when new announcements are published.') }
                        </NoAnnouncements> }
                </Box>
            </Container>
        );
    }
}

AnnouncementBox.propTypes = {
    announcements: PropTypes.arrayOf(
        PropTypes.oneOfType([
            announcementShape(ANNOUNCEMENT_TYPE.PARTNER),
            announcementShape(ANNOUNCEMENT_TYPE.SYSTEM)
        ])
    ).isRequired,
    unreadAnnouncements: PropTypes.arrayOf(
        PropTypes.oneOfType([
            announcementShape(ANNOUNCEMENT_TYPE.PARTNER),
            announcementShape(ANNOUNCEMENT_TYPE.SYSTEM)
        ])
    ),
    t: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onReadMore: PropTypes.func.isRequired,
    customers: PropTypes.object.isRequired,
    saveAcknowledgements: PropTypes.func.isRequired
};

AnnouncementBox.defaultProps = {
    unreadAnnouncements: []
};


export default withTheme(AnnouncementBox);
