import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Menu = styled.button`
    position: relative;
    margin: 0 0.5em;
    background-color: transparent;
    border: 0;
    appearance: none;
    pointer-events: auto;
    cursor: pointer;
    align-self: stretch;
    width: 3em;
    font-size: ${props => props.theme.fontSize.text};
    flex-shrink: 0;
`;

const MenuLineStyle = styled.div`
    position: absolute;
    top: 50%;
    left: 0.5em;
    right: 0.5em;
    height: 2px;
    border-radius: 1px;
    background-color: ${props => props.menuOpen ? 'transparent' : props.theme.colors.white};
    margin-top: -2px;
    transition-property: background-color;
    transition-duration: ${props => props.menuOpen ? '140ms' : '0ms'};
    transition-delay: ${props => props.menuOpen ? '0ms' : '140ms'};
`;

const MenuTopLine = styled(MenuLineStyle)`
    left: 0.7em;
    right: 0.7em;
    background-color: ${props => props.menuOpen ? props.theme.colors.rockBlue : props.theme.colors.white};
    margin-top: ${props => !props.menuOpen && '-10px'};
    transition-property: margin-top, transform;
    transform: ${props => props.menuOpen ? 'rotate(45deg)' : 'rotate(0deg)'};
    transition-duration: 140ms, 140ms;
    transition-delay: ${props => props.menuOpen ? '0ms, 140ms' : '140ms, 0ms'};
`;

const MenuBottomLine = styled(MenuLineStyle)`
    left: 0.7em;
    right: 0.7em;
    background-color: ${props => props.menuOpen ? props.theme.colors.rockBlue : props.theme.colors.white};
    margin-top: ${props => !props.menuOpen && '6px'};
    transition-property: margin-top, transform;
    transform: ${props => props.menuOpen ? 'rotate(-45deg)' : 'rotate(0deg)'};
    transition-duration: 140ms, 140ms;
    transition-delay: ${props => props.menuOpen ? '0ms, 140ms' : '140ms, 0ms'};
`;

const MenuToggle = props => {
    const toggleMenu = () => {
        props.toggleMenu(!props.menuOpen);
    };

    return (
        <Menu onClick={ toggleMenu } data-test-id="MenuToggle">
            <MenuTopLine menuOpen={props.menuOpen}/>
            <MenuLineStyle menuOpen={props.menuOpen}/>
            <MenuBottomLine menuOpen={props.menuOpen}/>
        </Menu>
    );
};

MenuToggle.propTypes = {
    menuOpen: PropTypes.bool
};

MenuToggle.defaultProps = {
    menuOpen: false
};

MenuToggle.displayName = 'MenuToggle';

export default MenuToggle;
