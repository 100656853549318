import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { some, includes } from 'lodash';

import { isValidPartner } from 'utils/Data/partners';
import Header from 'containers/Application/Header/Header.jsx';
import Navigation from 'containers/Application/Navigation/Navigation.jsx';
import PartnerOverview from 'containers/Application/PartnerOverview/PartnerOverview.jsx';
import FunctionalLocation from 'containers/Application/FunctionalLocation/FunctionalLocation.jsx';
import ServiceCalendar from 'containers/Application/ServiceCalendar/ServiceCalendar.jsx';
import Equipment from 'containers/Application/Equipment/Equipment.jsx';
import ServiceOrder from 'containers/Application/ServiceOrder/ServiceOrder.jsx';
import ServiceRequest from 'containers/Application/ServiceRequest/ServiceRequest.jsx';
import EnergyOptimization from '../Energy/EnergyOptimization';
import Recycling from 'containers/Application/Recycling/Recycling';
import CustomViewPartnerModule from 'containers/Application/CustomViews/CustomViewPartnerModule';
import ScrollAware from 'components/ScrollAware/ScrollAware';
import PartnerDocumentModule from 'containers/Application/PartnerDocuments/PartnerDocumentModule';
import ContactModule from 'containers/Application/Modules/ContactModule/ContactModule';
import RemoteCenter from 'containers/Application/RemoteCenter/RemoteCenter';

const pathsWithHeader = [
    '/UserManual',
    '/AdminUserManual',
    '/DataMapping'
];

class Partner extends Component {
    render() {
        const { match, match: { params: { partnerNumber } }, location: { pathname } } = this.props;
        const showHeader = some(pathsWithHeader, path => includes(pathname, path));

        return (
            <div>
                { showHeader && <Header /> }
                <Navigation />
                <ScrollAware />
                <Route exact path={ `${match.path}/Overview` } component={ PartnerOverview } />
                <Route path={ `${match.path}/FunctionalLocation/:functionalLocationId` }
                    component={ FunctionalLocation } />
                <Route path="/FunctionalLocation/:functionalLocationId" component={ FunctionalLocation } />
                <Route path={ `${match.path}/ServiceCalendar` } component={ ServiceCalendar } />
                <Route exact path={ `${match.path}/ServiceRequest/` } component={ ServiceRequest } />
                <Route path="/ServiceCalendar" component={ ServiceCalendar } />
                <Route exact path="/ServiceRequest" component={ ServiceRequest } />
                <Route
                    exact
                    path={ `${match.path}/Equipment/:functionalLocationId/:equipmentNumber` }
                    component={ Equipment }
                />
                <Route
                    path={ `${match.path}/Equipment/:functionalLocationId/:equipmentNumber/ServiceCalendar` }
                    component={ Equipment }
                />
                <Route exact
                    path={ `${match.path}/Equipment/:functionalLocationId/:superordinate/:equipmentNumber` }
                    component={ Equipment }
                />
                <Route
                    path={ `${match.path}/Equipment/:functionalLocationId/:superordinate/:equipmentNumber/ServiceCalendar` }
                    component={ Equipment }
                />
                <Route exact path="/Equipment/:functionalLocationId/:equipmentNumber" component={ Equipment } />
                <Route exact path="/Equipment/:functionalLocationId/:superordinate/:equipmentNumber"
                    component={ Equipment } />
                <Route exact path={ `${match.path}/ServiceOrder/:functionalLocationId/:serviceOrderNumber/` }
                    component={ ServiceOrder } />
                <Route exact path="/ServiceOrder/:functionalLocationId/:serviceOrderNumber/"
                    component={ ServiceOrder } />
                <Route exact path={ `${match.path}/EnergyOptimization/` }
                    component={ EnergyOptimization } />
                <Route exact path={ `${match.path}/Recycling/` } component={ Recycling } />
                <Route exact path={ `${match.path}/Report/:customViewId` }
                    component={ CustomViewPartnerModule } />
                <Route path={ `${match.path}/Documents` } component={ PartnerDocumentModule } />
                { isValidPartner(partnerNumber) &&
                    <Route exact path={ `${match.path}` } component={ PartnerOverview } /> }
                <Route exact path={ `${match.path}/ContactCaverion` } component={ ContactModule } />
                <Route path={ `${match.path}/RemoteCenter` } component={ RemoteCenter } />
            </div>
        );
    }
}

export default withRouter(Partner);
