import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import SnackBar from 'components/SnackBar/SnackBar';
import Button from 'components/Button/Button';
import translations from 'decorators/Translations/translations';
import { withTheme } from 'styled-components';
import { hideGlobalNotification } from 'redux/modules/notification/notification';
import { resetErrorMessage } from 'redux/modules/errorMessage/errorMessage';
import PropTypes from 'prop-types';

class GlobalNotification extends PureComponent {
    hideNotification = () => {
        this.props.hideNotification();
        if (this.props.type === 'error') {
            this.props.resetErrorMessage();
        }
    };

    render() {
        const {
            message,
            visible,
            theme,
            type,
            t
        } = this.props;

        const buttonStyles = {
            error: {
                textColor: theme.colors.radicalRed,
            },
            success: {
                textColor: theme.colors.emerald,
            },
            default: {
                textColor: theme.colors.black,
            },
        };

        return (
            <SnackBar
                visible={ visible }
                variant={ type }
                primaryContent={ message }
                secondaryContent={
                    <Button
                        {...buttonStyles[type] || buttonStyles.default}
                        color={theme.colors.white}
                        onClick={ this.hideNotification }
                    >
                        { t('Close') }
                    </Button>
                }
            />
        );
    }
}

GlobalNotification.defaultProps = {
    type: 'error',
    message: '',
    visible: false
};

GlobalNotification.propTypes = {
    type: PropTypes.string,
    message: PropTypes.string,
    visible: PropTypes.bool
};

const mapStateToProps = state => ({
    type: state.notification.type,
    message: state.notification.message,
    visible: state.notification.visible
});

const mapDispatchToProps = dispatch => ({
    hideNotification: () => dispatch(hideGlobalNotification()),
    resetErrorMessage: () => dispatch(resetErrorMessage())
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withTheme(translations(GlobalNotification)));
