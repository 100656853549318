import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import translations from 'decorators/Translations/translations';
import withErrors from 'decorators/Errors/withErrors';
import queryString from 'query-string';

import { loadBuildingAdminContainer } from 'redux/modules/containers/buildingAdmin';
import { showModal } from 'redux/modules/modal/modal';
import { icons } from 'components/Icon/IconNames';
import { canEditBuilding } from 'utils/Data/profileData';
import { getEnabledTabs, getEnabledCustomViews } from 'utils/Data/features';

import Hero from 'components/Hero/Hero';
import StandardPage from 'components/StandardPage/StandardPage';
import Tabs from 'components/Tabs/Tabs';
import Tab from 'components/Tabs/Tab';
import BuildingImage from './BuildingImage/BuildingImage';
import SnackBar from 'components/SnackBar/SnackBar';
import BuildingSensors from './BuildingSensors/BuildingSensors';
import AdminAnnouncements from './BuildingAnnouncements/AdminAnnouncements';
import BuildingAdminTags from './BuildingTags/BuildingAdminTags';
import AdminBuildingConfiguration from './BuildingConfiguration/AdminBuildingConfiguration';
import Header from 'containers/Application/Header/Header.jsx';
import FadeTransition from 'components/FadeTransition/FadeTransition';
import LoadingBuildingSensors from './BuildingSensors/LoadingBuildingSensors';
import BuildingValueImporter from './BuildingValueImporter/BuildingValueImporter';

export const notificationTimeout = 2000;

export const TAB_TITLES = {
    'configuration': 'Configuration',
    'floors and sensors': 'Floors And Sensors',
    'news': 'News',
    'tagging tool': 'Tagging tool',
    'import values': 'Import values',
};
class AdminEditBuilding extends Component {
    state = {
        /** Hero image for building */
        buildingImage: '',
        /** Notification type */
        notificationType: '',
        /** Notification message */
        notificationMessage: '',
        /** Notification visibility */
        notificationVisible: false
    };

    // Load building data in component mount
    componentDidMount() {
        this.loadBuilding(this.props.match.params.functionalLocationId);
        this.timeout = null;
    }

    // Update building image and tree data in state when props change. Also handle generic error showing.
    componentDidUpdate(prevProps) {
        const {
            functionalLocationImages,
            errorMessage,
            showError
        } = prevProps;

        if (!_.isEqual(functionalLocationImages, this.props.functionalLocationImages)) {
            this.setState({ buildingImage: this.getBuildingImage(this.props) });
        }

        if (this.props.errorMessage && !_.isEqual(errorMessage, this.props.errorMessage)) {
            showError();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    // Building data loading logic and mapping to tree
    loadBuilding = functionalLocationId => {
        this.props.loadBuildingAdminContainer(functionalLocationId)
            .then(() => this.setState({ buildingImage: this.getBuildingImage(this.props) }));
    };

    // Show notification
    showNotification = notification => {
        this.setState({
            notificationType: notification.type,
            notificationMessage: notification.message,
            notificationVisible: true
        });

        this.timeout = setTimeout(() => this.setState({ notificationVisible: false }), notificationTimeout);
    };

    // Generate link back to building page
    generateBackLink = functionalLocation => {
        const { activePartner, profile: { features, customViews, partnerNumbers } } = this.props;
        if (!functionalLocation) {
            return { pathname: '' };
        }

        const functionalLocationId = functionalLocation.functionalLocation;
        const functionalLocationType = functionalLocation.type;
        const tab = _.head(getEnabledTabs(
            features,
            functionalLocationType,
            getEnabledCustomViews(customViews, { functionalLocation: functionalLocationId })
        ));

        let partnerNumber = activePartner;

        // Wrong active partner, probably due to direct link to admin
        if (!_.includes(functionalLocation.partnerNumberWithParents, activePartner)) {
            partnerNumber = _.head(_.intersection(functionalLocation.partnerNumberWithParents, partnerNumbers));
        }

        return {
            pathname: `/${partnerNumber || 'all'}/FunctionalLocation/${encodeURIComponent(functionalLocationId)}`,
            query: { tab }
        };
    };

    // Gets building image path
    getBuildingImage = props => {
        const { functionalLocationImages } = props;
        let buildingImagePath = '';

        if (functionalLocationImages) {
            const buildingImage = _.findLast(functionalLocationImages, image => image.type === 'building');

            if (buildingImage && buildingImage.path) {
                buildingImagePath = buildingImage.path;
            }
        }

        return buildingImagePath;
    };

    renderImageSelector = () => {
        const { match: { params: { functionalLocationId } }, fileupload: { uploading } } = this.props;
        return (
            <BuildingImage functionalLocation={ functionalLocationId } uploading={ uploading } />
        );
    };

    getTitleBySelectedTab = tab => TAB_TITLES[tab] || 'Configuration';

    render() {
        const {
            t,
            profile,
            functionalLocations,
            match: { params: { functionalLocationId } },
            loading,
            activePartner,
            location
        } = this.props;

        const {
            notificationMessage,
            notificationType,
            notificationVisible,
        } = this.state;

        if (!canEditBuilding(profile.role)) {
            return null;
        }

        let buildingName;
        const functionalLocation = functionalLocations[functionalLocationId];

        if (functionalLocation) {
            buildingName = functionalLocation.description;
        }

        const links = [
            {
                title: buildingName || '...',
                icon: icons.TO_BUILDING,
                to: this.generateBackLink(functionalLocation),
                contextLink: true
            },
            { title: 'Configuration', icon: icons.SETTINGS },
            { title: 'Floors And Sensors', icon: icons.TO_FLOOR },
            { title: 'Import values', icon: icons.IMPORT },
            { title: 'News', icon: icons.ADMIN_MANAGE_NOTIFICATIONS },
            { title: 'Tagging tool', icon: icons.ADMIN_TAGS }
        ];

        const selectedTab = queryString.parse(location.search).tab;

        return (
            <FadeTransition>
                <StandardPage withTabs>
                    <Header t={ t } links={ links } loading={ loading } />
                    <Hero
                        title={ t(this.getTitleBySelectedTab(selectedTab)) }
                        subTitle={ buildingName }
                        t={ t }
                        customImageElement={ this.renderImageSelector() }
                        loadingContext={ loading }
                    />
                    <Tabs t={ t }>
                        <Tab title="Configuration" key="Configuration">
                            <AdminBuildingConfiguration
                                functionalLocation={ functionalLocationId }
                                showNotification={ this.showNotification }
                                loading={ loading }
                            />
                        </Tab>
                        <Tab title="Floors And Sensors" key="Floors And Sensors">
                            {loading
                                ? <LoadingBuildingSensors />
                                : <BuildingSensors
                                    functionalLocation={ functionalLocationId }
                                    showNotification={ this.showNotification }
                                    activePartner={ activePartner }
                                />
                            }
                        </Tab>
                        <Tab title="News" key="News">
                            <AdminAnnouncements
                                functionalLocation={ functionalLocationId }
                                showNotification={ this.showNotification }
                                loading={ loading }
                            />
                        </Tab>
                        <Tab title="Tagging Tool" key="Tagging Tool">
                            <BuildingAdminTags
                                functionalLocation={ functionalLocationId }
                                loading={ loading }
                            />
                        </Tab>
                        <Tab title="Import values" key="Import values">
                            <BuildingValueImporter
                                showNotification={ this.showNotification }
                                loading={ loading }
                                functionalLocation={ functionalLocationId }
                            />
                        </Tab>
                    </Tabs>
                    <SnackBar variant={ notificationType } visible={ notificationVisible }>
                        { notificationMessage }
                    </SnackBar>
                </StandardPage>
            </FadeTransition>
        );
    }
}

AdminEditBuilding.defaultProps = {
    loading: false
};

AdminEditBuilding.propTypes = {
    /** Translations function */
    t: PropTypes.func.isRequired,
    /** Currently active partner */
    activePartner: PropTypes.string.isRequired,
    /** User profile */
    profile: PropTypes.object.isRequired,
    /** Functional locations data */
    functionalLocations: PropTypes.object.isRequired,
    /** Loading state */
    loading: PropTypes.bool,
    /** Fileupload state */
    fileupload: PropTypes.object,
    /** Globally catched error message */
    errorMessage: PropTypes.string,
    /** Global error notification show function (from withErrors) */
    showError: PropTypes.func.isRequired,
    /** Loads container initial data */
    loadBuildingAdminContainer: PropTypes.func.isRequired,
    /** Shows global modal */
    showModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
    activePartner: state.profile.activePartner,
    profile: state.profile.profile,
    fileupload: state.fileupload,
    functionalLocationImages: state.partnerImage.byFL[props.match.params.functionalLocationId],
    errorMessage: state.errorMessage,
    functionalLocations: state.functionalLocations.functionalLocations,
    loading: state.buildingAdminContainer.loading,
});

const mapDispatchToProps = dispatch => ({
    loadBuildingAdminContainer: id => dispatch(loadBuildingAdminContainer(id)),
    showModal: (modalType, preConditions, onSubmitAction) =>
        dispatch(showModal(modalType, preConditions, onSubmitAction, null)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withErrors(translations(AdminEditBuilding)));
