import styled from 'styled-components';

export const PermissionListItemGroups = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;

    ${props => props.theme.media.portrait`
        flex-direction: row;
    `}
`;
