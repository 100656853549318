import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { range } from 'lodash';
import memoizeOne from 'memoize-one';

import CalendarMonth from './CalendarMonth/CalendarMonth';

const getMonths = memoizeOne(year => range(12).map(month => moment(`${year}-${month + 1}-1`, 'YYYY-M-D')));

const getStatusesByDay = memoizeOne(orders => {
    const statuses = range(12).map(() => ({}));
    orders.forEach(order => {
        const { date } = order.meta.filterable;
        if (date) {
            const day = date.getDate();
            const month = date.getMonth();
            statuses[month][day] = statuses[month][day] || new Set();
            statuses[month][day].add(order.status);
        }
    });

    return statuses;
});

const CalendarMonths = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 85rem;
    margin: 0 auto;
`;

const CalendarView = ({
    loading,
    location,
    monthlyCounts,
    orders,
    partnerNumber,
    year,
    query,
}) => {
    return (
        <CalendarMonths>
            { getMonths(year).map((month, index) => {
                return <CalendarMonth
                    key={ month.valueOf() }
                    date={ month }
                    partnerNumber={ partnerNumber }
                    serviceOrderCount= { loading ? undefined : monthlyCounts[index] }
                    statusesByDay={ getStatusesByDay(orders) }
                    location={ location }
                    query={ query }
                />;
            }) }
        </CalendarMonths>
    );
};

CalendarView.propTypes = {
    loading: PropTypes.bool,
    location: PropTypes.object.isRequired,
    monthlyCounts: PropTypes.arrayOf(PropTypes.number).isRequired,
    orders: PropTypes.arrayOf(PropTypes.object).isRequired,
    partnerNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    year: PropTypes.number.isRequired,
    query: PropTypes.object.isRequired,
};

export default React.memo(CalendarView);
