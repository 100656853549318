import * as React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import { isBusinessEntity } from 'utils/Data/functionalLocations';
import PermissionListItem from './PermissionListItem';
import { FunctionalLocationIcon } from 'components/';
import { ICON_TYPES } from 'components/Icon/Icon';
import {
    PermissionListItemGroup,
    PermissionListItemIcon,
    PermissionListItemGroups,
    PermissionListSubheading,
    PermissionListItemsChips,
} from './components';

const FunctionalLocationHeader = styled.div`
    display: flex;
    align-items: center;
`;

const IconContainer = styled.div`
    position: relative;

    > div {
        margin-right: 0;
        margin-top: 0;

        ${props => props.theme.media.portrait`
            margin-right: ${props => props.theme.spacing.sm};
        `}
    }
`;

const Error = styled.div`
    color: ${props => props.theme.colors.radicalRed};
    font-size: ${props => props.theme.font.size.xxxs};
    font-weight: ${props => props.theme.fontWeight.bold};
    letter-spacing: 1px;
    padding-bottom: ${props => props.theme.spacing.xxs};
    text-transform: uppercase;
`;

const ErrorIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -3px;
    left: -3px;
    width: 1.5em;
    height: 1.5em;
    background-color: ${props => props.theme.colors.radicalRed};
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.font.size.xxxs};
    font-weight: ${props => props.theme.fontWeight.bold};
    border-radius: 50%;
    padding-top: 1px;
`;

const Address = styled.div`
    font-size: 0.875em;
    font-family: ${props => props.theme.font.family.arial};
    color: ${props => props.theme.colors.rockBlue};
`;

const FunctionalLocationDetails = styled(PermissionListItemGroup)`
    flex-direction: column;
    align-items: flex-start;

    & > * {
        margin-right: ${props => props.theme.spacing.xs};
        &:last-child {
            margin-top: ${props => props.theme.spacing.xs};
        }
    }

    ${props => props.theme.media.portrait`
        flex-direction: row;
        align-items: center;
        & > *:last-child {
            margin-top: 0;
        }
    `}
`;

const MobileIcon = styled.div`
    display: flex;
    width: 70px;
    justify-content: center;

    ${props => props.theme.media.portrait`
        display: none;
    `}
`;

const HideOnMobile = styled.div`
    display: none;

    ${props => props.theme.media.portrait`
        display: block;
    `}
`;

const renderAddress = functionalLocation => {
    if (isBusinessEntity(functionalLocation)) {
        return;
    }

    let addressString = '';
    const addressParts = [];

    if (functionalLocation.address) {
        addressParts.push(functionalLocation.address);
    }

    if (functionalLocation.city) {
        addressParts.push(functionalLocation.city);
    }

    if (addressParts.length) {
        addressString = addressParts.join(', ');
    }

    return <Address>{ addressString }</Address>;
};

const getIconType = type => type === 'SYSTEM' ?
    ICON_TYPES.SECONDARY :
    ICON_TYPES.DEFAULT;

const PermissionListItemFunctionalLocation = props => {
    const { functionalLocation, error, ...other } = props;
    return (
        <PermissionListItem {...other}>
            <HideOnMobile>
                <PermissionListItemIcon />
            </HideOnMobile>
            <MobileIcon>
                <IconContainer>
                    <FunctionalLocationIcon
                        functionalLocation={functionalLocation}
                        iconType={ getIconType(functionalLocation.type) }
                        size="SMALL_PLUS"
                    />
                    { error && <ErrorIcon>!</ErrorIcon> }
                </IconContainer>
            </MobileIcon>
            <PermissionListItemGroups>
                <PermissionListItemGroup>
                    <HideOnMobile>
                        <IconContainer>
                            <FunctionalLocationIcon
                                functionalLocation={functionalLocation}
                                iconType={ getIconType(functionalLocation.type) }
                                size="SMALL_PLUS"
                            />
                            { error && <ErrorIcon>!</ErrorIcon> }
                        </IconContainer>
                    </HideOnMobile>
                    <FunctionalLocationHeader>
                        <div>
                            { error && <Error>{ error }</Error>}
                            <PermissionListSubheading>
                                { functionalLocation.description || functionalLocation.functionalLocation }
                            </PermissionListSubheading>
                        </div>
                    </FunctionalLocationHeader>
                </PermissionListItemGroup>
                <FunctionalLocationDetails spread>
                    <PermissionListSubheading>{ functionalLocation.functionalLocation }</PermissionListSubheading>
                    {renderAddress(functionalLocation, )}
                </FunctionalLocationDetails>
                <PermissionListItemsChips {...props} />
            </PermissionListItemGroups>
        </PermissionListItem>
    );
};

PermissionListItemFunctionalLocation.propTypes = {
    functionalLocation: PropTypes.shape({
        description: PropTypes.string,
        functionalLocation: PropTypes.string,
        address: PropTypes.string,
        city: PropTypes.string,
    }),
};

export default PermissionListItemFunctionalLocation;
