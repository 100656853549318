import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Button, { BUTTON_MIN_WIDTH } from 'components/Button/Button';
import SimpleDropdown from 'components/SimpleDropdown/SimpleDropdown';
import SimpleDropdownItem from 'components/SimpleDropdown/SimpleDropdownItem';

const RelativeWrapper = styled.div`
    position: relative;
    display: inline-block;
`;
RelativeWrapper.displayName = 'RelativeWrapper';

const StyledSimpleDropdown = styled(SimpleDropdown)`
    ${props => props.theme.media.portrait`
        &::before, &::after {
            transform: translateX(50%);
            ${props => props.alignment === 'center'
    ? css`
            right: 50%;
        `
    : css`
            right: ${BUTTON_MIN_WIDTH / 2}px;
        `
            }
    `}
`;
StyledSimpleDropdown.displayName = 'StyledSimpleDropdown';

const SimpleDropdownWrapper = styled.div`
    z-index: 2;
    position: absolute;
    top: calc(50% + 16px);
    right: 0;
    overflow: visible;
    min-width: 100%;

    ${props => props.alignment === 'center'
    ? css`
        right: 50%;
        transform: translateX(50%);
    `
    : css`
        right: 0;
    `
    } }
`;
SimpleDropdownWrapper.displayName = 'SimpleDropdownWrapper';


const ButtonDropdown = ({ buttonLabel, items, alignment, autoWidth }) => {

    const [functionsMenuOpen, setFunctionsMenuOpen] = useState(false);

    const handleFunctionsButtonClick = () => setFunctionsMenuOpen(!functionsMenuOpen);

    const onBlur = e => {
        // Hide only if focusing outside the dropdown
        if (!e.relatedTarget || !e.relatedTarget.className.includes('customViewOption')) {
            setFunctionsMenuOpen(!functionsMenuOpen);
        }
    };

    return (
        <RelativeWrapper onBlur={ onBlur }>
            <Button autoWidth={ autoWidth } onClick={ handleFunctionsButtonClick }>
                { buttonLabel }
            </Button>
            { functionsMenuOpen &&
                <SimpleDropdownWrapper alignment={ alignment }>
                    <StyledSimpleDropdown alignment={ alignment }>
                        {items.map(item =>
                            <SimpleDropdownItem key={ item.label } className="customViewOption" onClick={ item.onClick }>
                                { item.label }
                            </SimpleDropdownItem>
                        )}
                    </StyledSimpleDropdown>
                </SimpleDropdownWrapper>
            }
        </RelativeWrapper>
    );
};

ButtonDropdown.propTypes = {
    buttonLabel: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, onClick: PropTypes.func })),
    alignment: PropTypes.string,
    autoWidth: PropTypes.bool
};

ButtonDropdown.defaultProps = {
    alignment: 'right'
};

export default ButtonDropdown;
