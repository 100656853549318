import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import Dialog from 'components/Dialog/Dialog';
import DialogFrame from 'components/Dialog/DialogFrame';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import SensorValues from 'containers/Application/SensorValues/SensorValues';
import EnergyBreakdownTab from './EnergyBreakdownTab';
import {
    getBreakdownTabs,
    getZoomedEnergyValues,
    ZOOM_TYPE,
    loadDailyChartValues,
    loadHourlyChartValues,
} from 'containers/Application/Modules/EnergyModule/EnergyModuleUtils';
import { energyBreakdownTypes, metaReferenceKeys } from 'utils/Data/values';
import { loadEnergyChartValues } from 'redux/modules/iot/values';
import ContextualHelp, { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';

const EMPTY_ARRAY = [];

const HelpPositioner = styled.div`
    margin-right: auto;
    margin-top: 1em;
`;

const AllMetersPositioner = styled.div`
    text-align: right;
`;

export class EnergyBreakdown extends Component {
    state = {
        zoom: {
            type: ZOOM_TYPE.year,
            selected: null,
        },
        loading: false,
        tabs: [],
    };

    componentDidMount() {
        this.initializeChart();
    }

    componentDidUpdate(prevProps, prevState) {
        const { zoom, loading } = this.state;
        const { energyChartValues, loadingEnergyChartValues, outdoorsTemperatureData } = this.props;
        /*
         * Update chart data if
         *  1) zoom has changed and no loading were required
         *  2) loadingEnergyChartValues (from redux) stopped and this component had the loading on
         *  3) Outdoors temp values have changed
         */
        const stoppedLoadingChartValues = prevProps.loadingEnergyChartValues && !loadingEnergyChartValues;
        if (!isEqual(zoom, prevState.zoom) && !loading || loading && stoppedLoadingChartValues
            || !isEqual(outdoorsTemperatureData, prevProps.outdoorsTemperatureData)) {
            if (zoom.type === ZOOM_TYPE.year) {
                this.initializeChart();
            } else {
                this.updateChart(
                    zoom.type === ZOOM_TYPE.date
                        ? energyChartValues.hourlySum
                        : energyChartValues.dailySum
                );
                this.removeLoading();
            }
        }
    }

    initializeChart = () => {
        const { tabs } = this.props;
        // Initialize state with props
        this.setState({ tabs, temperatureSeries: this.getTemperatureSeries() });
    };

    updateChart = energyValues => {
        const { zoom: { type, selected } } = this.state;
        const { theme, valuesByType, t, tabs } = this.props;

        if (!isEmpty(energyValues)) {
            const breakdownData = {};

            energyBreakdownTypes.forEach((breakType, index) => {
                if (energyValues[breakType] && valuesByType[breakType]) {
                    // Use unit from the initial zoom level as it doesn't change between zoom levels
                    const unit = tabs && tabs[index] && tabs[index].unit;
                    // preserve properties that won't change when zooming in
                    breakdownData[breakType] = {
                        ...valuesByType[breakType],
                        ...getZoomedEnergyValues(energyValues[breakType], theme, type, selected, unit),
                        unit,
                    };

                    const metaKey = metaReferenceKeys[breakType];
                    const referenceSeries = metaKey && this.getReferenceSeries(metaKey);
                    referenceSeries && breakdownData[breakType].series.push(referenceSeries);
                }
            });

            this.setState({ tabs: getBreakdownTabs(breakdownData, t), temperatureSeries: this.getTemperatureSeries() });
        } else {
            this.handleZoom(null, ZOOM_TYPE.year);
        }
    };

    handleZoom = (selected, type) => {
        const { loadEnergyChartValues, partnerNumber, energyChartValues } = this.props;
        let loading = true;

        if (type === ZOOM_TYPE.month && isEmpty(energyChartValues)) {
            loadDailyChartValues(loadEnergyChartValues, partnerNumber);
        } else if (type === ZOOM_TYPE.date) {
            loadHourlyChartValues(selected, loadEnergyChartValues, partnerNumber);
        } else {
            loading = false;
        }

        this.setState(oldState => ({
            zoom: {
                ...oldState.zoom,
                type,
                selected
            },
            loading
        }));
    };

    removeLoading = () => this.setState({ loading: false });

    getTemperatureSeries = () => this.props.outdoorsTemperatureData[this.state.zoom.type]
        && this.props.outdoorsTemperatureData[this.state.zoom.type][this.state.zoom.selected || 0]
        || EMPTY_ARRAY;

    getReferenceSeries = metaKey => this.props.referenceData[metaKey]
        && this.props.referenceData[metaKey][this.state.zoom.type]
        && this.props.referenceData[metaKey][this.state.zoom.type][this.state.zoom.selected || 0]
        || null;

    render() {
        const {
            t,
            functionalLocation,
            energySensors,
            handleTabChange,
            tabSelected,
            hideSensor,
            sensorId,
            sensorAlarmsById,
        } = this.props;
        const {
            zoom,
            loading,
            tabs,
            temperatureSeries,
        } = this.state;

        const currentTab = tabSelected != null && tabs[tabSelected];
        const options = tabs.map((tab, index) => ({ value: index, label: tab.heading }));

        return (
            <Section>
                <SectionHeader t={ t }>
                    <SectionTabSelector
                        t={ t }
                        options={ options }
                        model={{ tabSelected: tabSelected }}
                        property="tabSelected"
                        onTabChange={ (property, value) => handleTabChange(value) }
                        left
                        large
                    />
                    <HelpPositioner>
                        <ContextualHelp
                            t={ t }
                            title={ 'Energy breakdown' }
                            text={ `${CTXHELP_PREFIX} Energy breakdown` }
                            standalone
                            position="bottom"
                        />
                    </HelpPositioner>
                </SectionHeader>
                {!!currentTab ?
                    <EnergyBreakdownTab
                        {...currentTab}
                        t={t}
                        onZoom={ this.handleZoom }
                        zoom={ zoom }
                        loading={ loading }
                        temperatureSeries={ temperatureSeries }
                    />
                    : <div>{ t('No data found') }</div>
                }
                <AllMetersPositioner>
                    <Link to={ `${window.location.pathname}?tab=conditions` }>
                        {t('All Energy Meters')}  &rarr;
                    </Link>
                </AllMetersPositioner>
                { sensorId && <Dialog isActive onOverlayClick={ hideSensor }>
                    <DialogFrame onClose={ hideSensor } t={ t } smallVerticalPadding>
                        <SensorValues
                            sensorsIds={ [sensorId] }
                            buildingSensors={ energySensors }
                            functionalLocation={ functionalLocation }
                            sensorAlarmsById={ sensorAlarmsById }
                        />
                    </DialogFrame>
                </Dialog> }
            </Section>
        );
    }
}

EnergyBreakdown.defaultProps = {
    functionalLocation: null,
    partnerNumber: null,
    valuesByType: {},
    outdoorsTemperatureData: {},
    referenceData: {},
    energyChartValues: {},
    sensorId: null,
    tabSelected: 0,
};

EnergyBreakdown.propTypes = {
    t: PropTypes.func.isRequired,
    loadingEnergyChartValues: PropTypes.bool.isRequired,
    loadEnergyChartValues: PropTypes.func.isRequired,
    handleTabChange: PropTypes.func.isRequired,
    tabSelected: PropTypes.number.isRequired,
    handleMeterClick: PropTypes.func.isRequired,
    hideSensor: PropTypes.func.isRequired,
    sensorId: PropTypes.number,
    functionalLocation: PropTypes.object,
    partnerNumber: PropTypes.string,
    energySensors: PropTypes.array,
    tabs: PropTypes.arrayOf(PropTypes.object),
    valuesByType: PropTypes.object,
    outdoorsTemperatureData: PropTypes.object,
    referenceData: PropTypes.object,
    energyChartValues: PropTypes.object,
    sensorAlarmsById: PropTypes.object,
    theme: PropTypes.object
};

const mapStateToProps = (state, props) => ({
    energyChartValues: props.functionalLocation
        ? state.values.energyChartValues.byFl[props.functionalLocation.functionalLocation]
        : state.values.energyChartValues.byPartner[props.partnerNumber],
    loadingEnergyChartValues: state.values.loadingEnergyChartValues,
});

const mapDispatchToProps = dispatch => ({
    loadEnergyChartValues: (partnerNumber, betweens, aggregation) => {
        dispatch(loadEnergyChartValues(partnerNumber, betweens, aggregation));
    },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withTheme(connector(EnergyBreakdown));
