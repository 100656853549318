import React from 'react';
import SortableTable from 'components/SortableTable/SortableTable';
import TableCell from 'components/BuildingEvents/EventsRow/EventsCell';
import SkeletonText from 'components/Skeletons/SkeletonText';

const SkeletonBodyComponent = columns => () =>
    <tbody>
        <tr>
            {columns.map(col => col.title
                ? <TableCell hideOnMobile={ col.hideOnMobile } key={ `skeleton-${col.field}Cell` }>
                    <SkeletonText width="100%" />
                </TableCell>
                : null
            )}
        </tr>
    </tbody>
;

const ServiceOrderTable = props => {
    const { data, t, onClick, loading, columns, RowComponent } = props;

    return (
        <SortableTable
            columns={ columns }
            data={ data }
            onClick={ onClick }
            RowComponent={ RowComponent }
            SkeletonBodyComponent={ SkeletonBodyComponent(columns) }
            headerBackgroundColor="transparent"
            orderBy="createdDate"
            order="desc"
            t={ t }
            showControls
            loading={ loading }
            paginate
            borderColor="mystic"
            initialRows={ 20 }
            rowsPerFold={ 20 }
            pure
            scrollTop
            topStripe
            keyField="serviceOrderNumber"
        />
    );
};

export default ServiceOrderTable;
