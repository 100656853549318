import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { rgba } from 'polished';
import { isEmpty } from 'lodash';

import translations from 'decorators/Translations/translations';
import { loadAnnouncements } from 'redux/modules/announcement/announcement';
import { showModal } from 'redux/modules/modal/modal';
import { saveProfile } from 'redux/modules/index.js';
import { announcementShape, ANNOUNCEMENT_TYPE, getAnnouncements } from './utils';

import AnnouncementBox from './AnnouncementBox';
import { BellButton } from './BellButton';
import { MODALTYPE } from 'components/Modal/ModalTypes';
import { toggleAnnouncementMenu } from 'redux/modules/announcement/announcement';

const AnnouncementContainer = styled.div`
    position: relative;
    height: 100%;
    width: 0;

    ${props => props.theme.media.portrait`
        border: solid ${props => rgba(props.theme.colors.white, 0.20)};
        border-width: 0 1px;
        width: 60px;
    `}
`;

export class GeneralAnnouncements extends PureComponent {

    componentDidMount() {
        // Wait until the profile is loaded to ensure the session exists
        if (!isEmpty(this.props.profile)) {
            setTimeout(() => this.props.loadAnnouncements(), 500);
        }
    }

    componentDidUpdate(prevProps) {
        if (isEmpty(prevProps.profile) && !isEmpty(this.props.profile)) {
            setTimeout(() => this.props.loadAnnouncements(), 500);
        }
    }

    toggleBox = () => {
        this.props.toggleAnnouncementMenu();
    };

    toggleAnnouncementModal = announcement => {
        this.props.showModal(MODALTYPE.ANNOUNCEMENT_MODAL, null, null, { announcement: announcement });
    };

    saveAckAnnouncements = ids => {
        const newAcks = {};
        ids.forEach(id => { newAcks[id] = moment.utc().toISOString(); });
        this.props.saveProfile(this.props.profile.id, {
            ...this.props.profile,
            ackAnnouncements: { ...this.props.profile.ackAnnouncements, ...newAcks }
        });
    };

    render() {
        const {
            activePartner,
            generalAnnouncements,
            personalAnnouncements,
            t,
            profile,
            customers,
            announcementMenuOpen
        } = this.props;
        const { read, unread } = getAnnouncements(profile, generalAnnouncements, personalAnnouncements, activePartner);

        return <AnnouncementContainer data-test-id="GeneralAnnouncements">
            { announcementMenuOpen &&
                <AnnouncementBox
                    unreadAnnouncements={ unread }
                    announcements={ read }
                    customers={ customers }
                    t={ t }
                    onClose={ this.toggleBox }
                    onReadMore={ this.toggleAnnouncementModal }
                    saveAcknowledgements={ this.saveAckAnnouncements } />
            }
            <BellButton onClick={ this.toggleBox } unread={ unread.length } />
        </AnnouncementContainer>;
    }
}

GeneralAnnouncements.propTypes = {
    generalAnnouncements: PropTypes.arrayOf(
        PropTypes.oneOfType([
            announcementShape(ANNOUNCEMENT_TYPE.PARTNER),
            announcementShape(ANNOUNCEMENT_TYPE.SYSTEM)
        ])
    ).isRequired,
    personalAnnouncements: PropTypes.arrayOf(announcementShape(ANNOUNCEMENT_TYPE.PERSONAL)).isRequired,
    profile: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    activePartner: PropTypes.string.isRequired,
    announcementMenuOpen: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    activePartner: state.profile.activePartner,
    profile: state.profile.profile,
    customers: state.customer.customers,
    generalAnnouncements: state.announcement.general,
    personalAnnouncements: state.announcement.personal,
    announcementMenuOpen: state.announcement.menuOpen
});

const mapDispatchToProps = dispatch => ({
    loadAnnouncements: () => dispatch(loadAnnouncements()),
    showModal: (modalType, preConditions, onSubmitAction, passedProps) =>
        dispatch(showModal(modalType, preConditions, onSubmitAction, passedProps)),
    saveProfile: (id, data) => dispatch(saveProfile(id, data)),
    toggleAnnouncementMenu: () => dispatch(toggleAnnouncementMenu())
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(GeneralAnnouncements));
