import React, { Fragment, useMemo } from 'react';
import styled from 'styled-components';
import { map, isEmpty, orderBy, includes, head, last, toNumber, replace } from 'lodash';
import PropTypes from 'prop-types';

import Slider from 'components/Slider/Slider';
import { InputSelectDropdown, InputLabel } from 'components/index.js';

const FilterInput = styled.div`
    margin: ${props => props.theme.spacing.sm} 0;
`;
FilterInput.displayName = 'FilterInput';

const sliderInputs = ['grossArea', 'netArea'];

const sliderStep = maxValue => {
    const valueOrder = Math.floor(Math.log10(maxValue));
    const stepOrder = Math.max(0, valueOrder - 2);
    return Math.pow(10, stepOrder);
};

export const EnergyOptimizationFilterInputs = ({
    t,
    filter,
    selectedFilterValues,
    handleSelectDropdown
}) => {
    const filterOptions = useMemo(() =>
        filter && filter.reduce((accu, singleInput) => {
            accu[singleInput.key] = orderBy(singleInput.values, 'label');
            return accu;
        }, {}),
    [filter]);

    const sliderDomains = useMemo(() =>
        filter && filter.reduce((accu, singleInput) => {
            if (includes(sliderInputs, singleInput.key)) {
                const options = filterOptions[singleInput.key];
                const optionValues = map(options, option => toNumber(replace(option.value, ',', '.')))
                    .sort((a, b) => a - b);
                accu[singleInput.key] = [head(optionValues), last(optionValues)];
            }
            return accu;
        }, {}),
    [filter, filterOptions]);

    if (isEmpty(filter)) {
        return null;
    }

    return (
        <Fragment>
            {map(filter, singleInput => {
                const options = filterOptions[singleInput.key];
                const domain = sliderDomains[singleInput.key];

                // no need to render, if there is no options to choose from
                if (options.length < 2) {
                    return null;
                }

                return (
                    <FilterInput key={ singleInput.key }>
                        <InputLabel text={ t(singleInput.key) } />
                        {includes(sliderInputs, singleInput.key)
                            ? <Slider
                                step={ sliderStep(domain[1]) }
                                domain={ domain }
                                values={ selectedFilterValues[singleInput.key] || domain }
                                property={ singleInput.key }
                                onChange={ handleSelectDropdown }
                                showValueLabels
                                showTicks
                                showTickLabels
                                tickCount={ 5 }
                            />
                            : <InputSelectDropdown
                                t={ t }
                                multi
                                options={ options }
                                onChange={ handleSelectDropdown }
                                model={ selectedFilterValues }
                                property={ singleInput.key }
                                clearable={ false }
                            />
                        }
                    </FilterInput>
                );
            })}
        </Fragment>
    );
};

EnergyOptimizationFilterInputs.propTypes = {
    t: PropTypes.func.isRequired,
    filter: PropTypes.array.isRequired,
    selectedFilterValues: PropTypes.object.isRequired,
    handleSelectDropdown: PropTypes.func.isRequired
};

export default React.memo(EnergyOptimizationFilterInputs);
