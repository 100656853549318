import styled from 'styled-components';
import PropTypes from 'prop-types';

const getTabColors = ({ selected, selectedColor, theme }) => {
    if (selected) {
        if (selectedColor) {
            return {
                border: `1px solid ${selectedColor}`,
                backgroundColor: selectedColor
            };
        }
        return {
            border: `1px solid ${theme.colors.blue}`,
            backgroundColor: theme.colors.blue
        };
    }
    return {
        border: `1px solid ${theme.colors.rockBlue}`,
        backgroundColor: theme.colors.white
    };
};

const CircleTab = styled.div`
    ${props => getTabColors(props)};
    display: inline-block;
    width: auto;
    min-width: 3.25em;
    height: 3.25em;
    border-radius: 3.25em;
    margin-left: 1em;

    > a {
        width: 100%;
        height: 100%;
        padding: 0 1em;
        display: block;
        text-align: center;
        line-height: 3.25em;
        color: ${props => props.selected ? props.theme.colors.white : props.theme.colors.rockBlue};
    }
`;

CircleTab.defaultProps = {
    selected: false
};

CircleTab.propTypes = {
    selected: PropTypes.bool,
    selectedColor: PropTypes.string
};

export default CircleTab;
