import moment from 'moment';
import { range, entries, first, groupBy, map, reduce, filter, includes } from 'lodash';
import memoizeOne from 'memoize-one';
import { getUniqueEquipment } from 'utils/Data/serviceOrders';

import {
    OrderType,
    STATISTICS_PLANNED_VOLUMES_BY_BUILDING,
    STATISTICS_PLANNED_VOLUMES_BY_PROVIDER,
    STATISTICS_PLANNED_VOLUMES_BY_DISCIPLINE,
    STATISTICS_ORDER_VOLUMES_BY_DISCIPLINE,
    STATISTICS_ORDER_VOLUMES_BY_BUILDING,
    STATISTICS_ORDER_VOLUMES_BY_EQUIPMENT,
    OrderStatus,
    SyntheticOrderStatus,
    GROUP_UNKNOWN,
} from 'constants/serviceCalendar';

export const CHART_HEIGHT = 450;
const MAX_CATEGORIES = 10;

// Chart series
export const plannedStatuses = [
    SyntheticOrderStatus.COMPLETED_OTHER,
    OrderStatus.OVERDUE,
    OrderStatus.COMPLETED_LATE,
    OrderStatus.COMPLETED_EARLY,
    OrderStatus.COMPLETED_ON_TIME,
    SyntheticOrderStatus.UNFINISHED,
];

export const orderStatuses = [
    OrderStatus.POSTPONED,
    OrderStatus.COMPLETED,
    OrderStatus.PARTLY_COMPLETED,
    OrderStatus.IN_PROGRESS,
    OrderStatus.OPEN,
];

export const groupServiceOrdersByType = serviceOrders => ({
    [OrderType.ORDER]: [],
    [OrderType.PLANNED]: [],
    ...groupBy(serviceOrders, 'orderType'),
});

export const getServiceOrderStatus = order => {
    return order.detailedStatus;
};

export const calculateMonthlyStatistics = (orders, dateField, groupBy, secondaryDateField) => {
    const monthly = range(12).map(() => ({}));
    orders.forEach(order => {
        if (order[dateField] || secondaryDateField && order[secondaryDateField]) {
            const month = moment(order[dateField] || order[secondaryDateField]).month();
            const status = groupBy(order);
            monthly[month][status] = (monthly[month][status] || 0) + 1;
        }
    });

    return {
        monthly,
        year: monthly.reduce((acc, month) => {
            entries(month).forEach(([key, value]) => {
                acc[key] = (acc[key] || 0) + value;
            });
            return acc;
        }, {}),
    };
};

export const getSeriesFromMonthlyStatistics = (monthly, statuses, t) =>
    statuses.map(status => ({
        name: `${t(status)}${status === SyntheticOrderStatus.COMPLETED_OTHER ? ' *' : ''}`,
        data: monthly.map(month => month[status] || 0),
        _showTooltipForZeroValue: true,
    }));

export const getSimpleSeries = (data, name, unit) => [{
    name,
    data,
    _showTooltipForZeroValue: true,
    _unit: unit,
}];

export const getResponseSlaSeries = (percentData, sampleSizeData, t) => {
    const onTimeSeries = getSimpleSeries(
        map(percentData, percent => percent * 100),
        t('Technically completed on time'),
        '%'
    );

    // Late series data point is 1) 100% - onTime%, 2) 100% if onTime is 0% and over sla orders exist, 3) 0% otherwise
    const lateSeries = getSimpleSeries(
        map(percentData, (percent, index) => percent === 0
            ? sampleSizeData[index]
                ? 100
                : 0
            : (1 - percent) * 100),
        t('Technically completed late'),
        '%'
    );
    // order affects stacking order in chart
    return merge(lateSeries, onTimeSeries);
};

const groupByDiscipline = orders => groupBy(orders, order => order.meta.filterable.discipline);

const groupByBuilding = (orders, functionalLocations) => groupBy(orders, order => {
    const buildingId = order.path.find(functionalLocationId => {
        const functionalLocation = functionalLocations[functionalLocationId];
        return functionalLocation && functionalLocation.type === 'BU';
    });

    return buildingId ? buildingId : GROUP_UNKNOWN;
});

const groupByEquipment = orders => {
    const equipmentList = getUniqueEquipment(orders);
    return reduce(equipmentList, (accu, equipment) => {
        accu[equipment] = filter(orders, order => includes(order.equipmentNumber, equipment));
        return accu;
    }, {});
};

export const groupByFunctions = {
    [STATISTICS_PLANNED_VOLUMES_BY_BUILDING]: groupByBuilding,
    [STATISTICS_PLANNED_VOLUMES_BY_PROVIDER]: maintenances => groupBy(maintenances, maintenance => {
        const externalUser = first(maintenance.technicalResponsible);
        return externalUser ? externalUser.id : GROUP_UNKNOWN;
    }),
    [STATISTICS_PLANNED_VOLUMES_BY_DISCIPLINE]: groupByDiscipline,
    [STATISTICS_ORDER_VOLUMES_BY_DISCIPLINE]: groupByDiscipline,
    [STATISTICS_ORDER_VOLUMES_BY_BUILDING]: groupByBuilding,
    [STATISTICS_ORDER_VOLUMES_BY_EQUIPMENT]: groupByEquipment,
};

const aggregateGroup = (orders, statuses, getStatus) => orders.reduce((acc, order) => {
    const status = getStatus(order);
    acc[status] = (acc[status] || 0) + 1;
    return acc;
}, statuses.reduce((acc, status) => ({ ...acc, [status]: 0 }), {}));

export const getAggregateTotal = (aggregate, statuses) =>
    statuses.reduce((total, status) => total + (aggregate[status] || 0), 0);

export const aggregate = (orders, functionalLocations, groupByFn, statuses, getStatus) => {
    return entries(groupByFn(orders, functionalLocations))
        .filter(([category]) => category !== GROUP_UNKNOWN)
        .map(([category, values]) => [category, aggregateGroup(values, statuses, getStatus)])
        .sort((a, b) => getAggregateTotal(b[1], statuses) - getAggregateTotal(a[1], statuses));
};

export const getLabelStyle = memoizeOne(theme => ({
    color: theme.colors.darkGray,
    fontWeight: theme.font.weight.bold,
    fontFamily: theme.font.family.arial,
    letterSpacing: '1px',
    textTransform: 'uppercase',
}));

export const getPlannedColors = memoizeOne((theme, showCompleted = false) => {
    const colors = [
        theme.serviceOrder.completedColor,
        theme.serviceOrder.overdueColor,
        theme.serviceOrder.lateColor,
        theme.serviceOrder.inProgressColor,
        theme.colors.midnight,
        theme.serviceOrder.openColor,
    ];
    return showCompleted ? colors : colors.slice(1);
});

export const getOrderColors = memoizeOne(theme => [
    theme.serviceOrder.postponedColor,
    theme.serviceOrder.completedColor,
    theme.serviceOrder.arrivedColor,
    theme.serviceOrder.inProgressColor,
    theme.serviceOrder.openColor,
]);

export const getReactionTimeStackedColors = memoizeOne(theme => [
    theme.colors.radicalRed,
    theme.colors.midnight,
]);

export const getReactionTimeColors = memoizeOne(theme => [
    theme.colors.midnight,
]);

export const getXAxisOptions = memoizeOne(categoryCount => ({
    scrollbar: {
        enabled: categoryCount > MAX_CATEGORIES,
    },
    max: Math.min(MAX_CATEGORIES, categoryCount) - 1,
}));

export const getMonths = memoizeOne(t => moment.monthsShort().map(t));

export const getReference = memoizeOne((partnerMeta, key, theme, t, seriesName) => {
    const reference = partnerMeta &&
        !partnerMeta.loading &&
        partnerMeta.meta &&
        partnerMeta.meta.find(meta => meta.key === key);

    if (!reference) {
        return {
            value: null,
            plotLines: [],
            series: [],
        };
    }

    const value = Number.parseFloat(reference.value);
    return {
        value,
        plotLines: [{
            value,
            color: theme.colors.orange,
            dashStyle: 'ShortDot',
            width: 2,
            label: {
                useHTML: true,
                text: `<div>${seriesName || t('Reference')}</div>`,
                align: 'right',
                x: -11,
                y: -7,
                style: {
                    background: theme.colors.orange,
                    color: theme.colors.white,
                    padding: '1px 3px',
                    fontFamily: theme.font.family.arial,
                    fontSize: theme.font.size.xxxs,
                    textTransform: 'uppercase',
                },
            }
        }],
        series: [{
            name: seriesName || t('Reference'),
            type: 'line',
            color: theme.colors.orange,
        }]
    };
});

export const merge = memoizeOne((a, b) => [...a, ...b]);

const flFilterFields = ['functionalLocation', 'name', 'description', 'address', 'postalCode', 'city', 'country'];

export const filterByBuilding = (needle, functionalLocationId, functionalLocations) => {
    const functionalLocation = functionalLocations[functionalLocationId];
    if (!functionalLocation) {
        return false;
    }

    return flFilterFields.some(
        field => functionalLocation[field] && functionalLocation[field].toLowerCase().indexOf(needle) !== -1
    );
};


export const filterByEquipment = (needle, equipmentId, equipmentTexts) => {
    const equipmentText = equipmentTexts[equipmentId] || equipmentId;
    return equipmentText.toLowerCase().indexOf(needle) !== -1;
};
