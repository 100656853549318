import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Cell = styled.td`
    vertical-align: middle;
`;

const StyledCalendarDay = styled.div`
    display: flex;
    align-items: center;
    border-radius: 2px;
    overflow: hidden;
    margin: ${props => props.theme.spacing.xxs};
    text-align: center;
    position: relative;
    width: 2em;
    height: 2.3em;
    font-size: ${props => props.theme.font.size.xxs};
    font-family: ${props => props.theme.font.family.sanchez};
`;

const CalendarDay = ({ link, showLink, children, className }) => {
    if (showLink) {
        return (
            <Cell data-test-id="CalendarDay-link">
                <Link to={ link }>
                    <StyledCalendarDay>
                        { children }
                    </StyledCalendarDay>
                </Link>
            </Cell>
        );
    }
    return (
        <Cell className={ className }>
            <StyledCalendarDay>
                { children }
            </StyledCalendarDay>
        </Cell>
    );
};

CalendarDay.propTypes = {
    link: PropTypes.string,
    showLink: PropTypes.bool,
    children: PropTypes.node,
};

export default CalendarDay;
