import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import * as utils from './utils';
import StatusMeter from 'components/StatusMeter/StatusMeter';

const Selector = styled.div`
    display: flex;
    margin-bottom: ${props => props.theme.spacing.md};

    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none;

    section {
        flex: 0 1 auto;
    }
`;
Selector.displayName = 'Selector';

const PrioritySelector = ({
    selectedPriority,
    setSelectedPriority,
    priorities,
    statistics,
    theme,
    t,
    slas,
    isPercent
}) => {
    const valueCalculator = isPercent ? utils.prioritySlaValue : utils.priorityValue;
    const colorCalculator = isPercent ? utils.prioritySlaColor : utils.priorityColor;

    return (
        <Selector>
            <StatusMeter
                key={ 'all' }
                noChart={ !isPercent }
                onClick={ () => setSelectedPriority('all') }
                selected={ selectedPriority === 'all' }
                title={ t('All service orders') }
                subtitle={ t('Yearly average') }
                loading={ false }
                value={ utils.formatValue(valueCalculator(statistics.all), isPercent) }
                color={ theme.colors.midnight }
            />
            {priorities.map(priority => {
                if (priority === 'all') { return null; }

                const value = valueCalculator(statistics[priority]);
                const color = colorCalculator(
                    value,
                    theme,
                    utils.hasNoOrders(statistics[priority]),
                    slas[priority]
                );

                return <StatusMeter
                    key={ priority }
                    noChart={ !isPercent }
                    onClick={ () => setSelectedPriority(priority) }
                    selected={ priority === selectedPriority }
                    title={ `${t('Priority')} ${priority}` }
                    subtitle={ t('Yearly average') }
                    loading={ false }
                    value={ utils.formatValue(value, isPercent) }
                    color={ color }
                />;
            })}
        </Selector>
    );
};

PrioritySelector.defaultProps = {
    isPercent: false,
    slas: {},
};

PrioritySelector.propTypes = {
    selectedPriority: PropTypes.string.isRequired,
    setSelectedPriority: PropTypes.func.isRequired,
    priorities: PropTypes.arrayOf(PropTypes.string).isRequired,
    statistics: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    slas: PropTypes.object,
    isPercent: PropTypes.bool,
};

export default withTheme(PrioritySelector);
