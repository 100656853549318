import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { isValidPartner } from 'utils/Data/partners';
import { isEquipmentEnabled } from 'utils/Data/profileData';

const Container = styled.div`
    background-color: ${props => props.theme.colors.white};
    text-align: center;
    padding: 5em;
`;

const Title = styled.h4`
    padding-bottom: ${props => props.theme.spacing.md};
`;

const SapEquipment = ({ t, functionalLocation, partnerNumber, features, sensor }) => {
    const partnerPart = !isValidPartner(partnerNumber) ? '' : `/${partnerNumber}`;
    const params = `tab=conditions&fromTab=floors&from=${functionalLocation.functionalLocation}`;
    const link = `${partnerPart}/Equipment/${sensor.functionalLocation}/${sensor.equipmentNumber}?${params}`;

    return (
        <Container>
            <Title>{ `${t('SAP equipment')}: ${ sensor.name }` }</Title>
            { isEquipmentEnabled(features) &&
                <Link to={ link } title={ sensor.name }>
                    { t('Open Equipment Conditions') }
                </Link>
            }
            { !isEquipmentEnabled(features) && <div>{ t('No data found') }</div> }
        </Container>
    );
};

export default SapEquipment;
