import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { size, isEmpty } from 'lodash';

const Column = styled.div`
    white-space: nowrap;
`;
Column.displayName = 'Column';

const FunctionalLocations = styled.span`
    display: inline-block;
    width: 190px;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: ${props => props.theme.font.lineHeight.md};

    ${props => props.theme.media.portrait`
        width: auto;
    `}

    ${props => props.theme.media.landscape`
        font-weight: ${props => props.theme.font.weight.normal};
    `}
`;
FunctionalLocations.displayName = 'Users';

const FunctionalLocationsColumn = ({ t, data }) => {
    let text = '';
    if (!isEmpty(data)) {
        const length = size(data);
        if (length > 1) {
            text = `${length} ${t('functional locations')}`;
        }
        else if (length === 1) {
            text = data;
        }
    }

    return (
        <Column>
            <FunctionalLocations>{ text }</FunctionalLocations>
        </Column>
    );
};
FunctionalLocationsColumn.displayName = 'FunctionalLocationsColumn';

FunctionalLocationsColumn.propTypes = {
    data: PropTypes.array
};

export default FunctionalLocationsColumn;
