import styled from 'styled-components';

const SectionTabs = styled.ul`
    display: ${props => props.hideOnMobile ? 'none' : 'flex'};
    position: ${props => !props.left && 'absolute'};
    bottom: -1px;
    flex-flow: row nowrap;
    justify-content: ${props => props.left ? 'flex-start' : 'flex-end'};
    margin-bottom: ${props => props.left && 'calc(-1em - 2px)'};

    ${props => props.theme.media.landscape`
        display: flex;
        right: 0;
    `}
`;
SectionTabs.displayName = 'SectionTabs';

export default SectionTabs;
