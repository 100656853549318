import styled from 'styled-components';

const SimpleDropdownItem = styled.button`
    display: block;
    white-space: nowrap;
    background-color: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.font.size.xs};
    color: ${props => props.theme.colors.black};
    appearance: none;
    border: none;
    cursor: pointer;
    width: 100%;
    padding: ${props => props.theme.spacing.sm} ${props => props.theme.spacing.lg};

    &:focus {
        outline: none
    }

    &:hover {
        background-color: ${props => props.theme.colors.alabaster};
    }
`;
SimpleDropdownItem.displayName = 'SimpleDropdownItem';

export default SimpleDropdownItem;
