import React from 'react';
import PropTypes from 'prop-types';
import { isNil, find } from 'lodash';
import styled, { withTheme } from 'styled-components';
import moment from 'moment';

import Dialog from 'components/Dialog/Dialog';
import DialogFrame from 'components/Dialog/DialogFrame';
import Sensor from 'components/Sensor/Sensor';
import SensorHead from 'components/Sensor/SensorHead/SensorHead';
import SensorTools from 'components/Sensor/SensorTools/SensorTools';
import { ChartContainer } from 'components/Sensor/SensorBody/SensorBody';

import { performanceColors, getPerformanceStatus } from 'utils/Data/performance';
import PerformanceChart from 'components/Charts/PerformanceChart';
import IoTChart from 'components/Charts/IoTChart';
import { getPerformanceLimit } from 'utils/Data/performance';

const Charts = styled.div`
    background-color: ${props => props.theme.colors.white};
    padding: ${props => props.theme.sensorValues.padding.mobile};

    ${props => props.theme.media.portrait`
        padding: ${props => props.theme.sensorValues.padding.tablet};
    `}

    ${props => props.theme.media.desktop`
        padding: ${props => props.theme.sensorValues.padding.desktop};
    `}
`;
Charts.displayName = 'Charts';


export const PerformanceModal = props => {
    const {
        t,
        sensorId,
        equipmentSensors,
        latestValuesBySensorId,
        valuesBySensorId,
        loading,
        toggleModal,
        onSensorChange,
        sensorOptions,
        theme,
        parameterModel,
        onParameterChange,
        sensorAlarm,
        buildingMeta,
    } = props;

    const sensor = find(equipmentSensors, { id: sensorId }) || {};

    /** texts and stuff for modal */
    const sensorType = sensor.sensorType || {};
    const unit = sensorType.unit || '';
    const isPresence = sensorType.graphType === 'presence';

    const hasLatestValue = sensorId && latestValuesBySensorId[sensorId]
        && !isNil(latestValuesBySensorId[sensorId].value);
    const latestValue = hasLatestValue && latestValuesBySensorId[sensorId].value;
    const latestValueTime = hasLatestValue && moment(latestValuesBySensorId[sensorId].timestamp)
        .local().format('MMMM D, YYYY HH:mm');
    const fixedValue = hasLatestValue
        ? isPresence ? latestValue > 0 ? t('Occupied') : t('Available')
        : `${latestValue.toFixed(1)} ${unit}` : '';

    const isPerformanceSensor = sensor.name && (
        sensor.name.toLowerCase().indexOf('performance') !== -1
        || sensor.name.toLowerCase().indexOf('opi overall') !== -1
    );

    const status = hasLatestValue ? getPerformanceStatus(latestValue) : 'loading';
    const title = sensor.name ? t(sensor.name.replace(/_/g, ' ')) : '';
    /** texts and stuff for modal */

    let timespan, xMax;
    const { startDatetime, endDatetime } = parameterModel;
    if (startDatetime && endDatetime) {
        timespan = endDatetime.diff(startDatetime); // graph shows only the selected timespan
        xMax = endDatetime.local().valueOf(); // use xMax to control graph timespan
    }

    const getLimit = getPerformanceLimit(sensor, sensor.parent, buildingMeta, sensorAlarm);
    const getChart = () => {
        if (isPresence) {
            return <IoTChart
                t={ t }
                loading={ loading }
                histories={ valuesBySensorId && valuesBySensorId[sensorId] || [] }
                activeTimes={ null }
                alarms={ [] }
                minXRange={ timespan }
                yMax={ 1 }
                maxX={ xMax }
                yAxisLabelFormat=" "
                type="area"
                hideDecimals
                trueValue={ t('Occupied') }
                falseValue={ t('Available') }
            />;
        }
        return <PerformanceChart
            t={ t }
            loading={ loading }
            noZoom
            histories={ valuesBySensorId && valuesBySensorId[sensorId] || [] }
            minXRange={ timespan }
            maxX={ xMax }
            unit={ sensorType.unit }
            getThreshold={ getLimit }
        />;
    };

    return (
        <Dialog isActive animate onOverlayClick={ toggleModal }>
            <DialogFrame onClose={ toggleModal } t={ t } smallVerticalPadding>
                <Sensor>
                    <SensorHead
                        t={ t }
                        loading={ loading }
                        sensorTitle={ title }
                        sensorValue={ fixedValue }
                        sensorValueColor={ isPerformanceSensor ? performanceColors({ theme })[status] : undefined }
                        sensorOptions={ sensorOptions }
                        onSensorChange={ (property, value) => onSensorChange(value) }
                        noSubsensors={ true }
                        sensor={ sensor }
                        sensorValueTime={ latestValueTime }
                    />
                    <ChartContainer>{ getChart() }</ChartContainer>
                    <SensorTools
                        t={ t }
                        loading={ loading }
                        parameterModel={ parameterModel }
                        onParameterChange={ onParameterChange }
                    />
                </Sensor>
            </DialogFrame>
        </Dialog>
    );
};
PerformanceModal.displayName = 'PerformanceModal';

PerformanceModal.propTypes = {
    t: PropTypes.func.isRequired,
    sensorId: PropTypes.number.isRequired,
    equipmentSensors: PropTypes.array.isRequired,
    latestValuesBySensorId: PropTypes.object.isRequired,
    valuesBySensorId: PropTypes.object.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onSensorChange: PropTypes.func.isRequired,
    sensorOptions: PropTypes.array.isRequired,
    parameterModel: PropTypes.object.isRequired,
    onParameterChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    sensorAlarm: PropTypes.object,
    buildingMeta: PropTypes.array,
    theme: PropTypes.object
};

PerformanceModal.defaultProps = {
    loading: false,
};

export default withTheme(PerformanceModal);
