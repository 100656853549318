import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';

import { flIcons, disciplineIcons } from 'utils/Data/functionalLocations';
import { Icon } from 'components/Icon/Icon';

const getIconName = functionalLocation => {
    if (functionalLocation.type === 'SYSTEM') {
        const discipline = _.last(functionalLocation.functionalLocation.split('-'));
        return disciplineIcons[discipline] || disciplineIcons.EXT;
    }

    return flIcons[functionalLocation.type] || flIcons.UNKNOWN;
};

const FunctionalLocationIcon = ({ functionalLocation, ...other }) => {
    return (
        <Icon
            name={ getIconName(functionalLocation) }
            { ...other }
        />
    );
};

FunctionalLocationIcon.propTypes = {
    functionalLocation: PropTypes.shape({
        functionalLocation: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
};

export default FunctionalLocationIcon;
