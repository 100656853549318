import React from 'react';
import styled from 'styled-components';
import Svg from 'components/Svg/Svg';

import Loader from 'components/Loader/Loader.jsx';

const StyledStatusInfoTrend = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${props => props.theme.fontFamily.heading};
    text-align: center;
    height: 46px; /* Fix height to be same as the Gauge chart svg height */
    
    ${props => props.theme.media.portrait`
        margin: ${props => props.theme.spacing.xxs};
    `}
    
    ${props => props.theme.media.landscape`
        margin: ${props => props.theme.spacing.md};
    `}
`;

const Icon = styled(({ positive, up, noValue, isPerformance, ...props }) => <Svg {...props} />)`
    transform: ${props => !props.isPerformance && (props.up ? 'rotate(-45deg)' : 'rotate(45deg)')};
    width: 90%;
    height: 90%;
    fill: ${props =>
        props.noValue ? props.theme.colors.rockBlue :
        props.positive ? props.theme.colors.emerald :
        props.isPerformance ? props.theme.colors.orange : props.theme.colors.radicalRed};
`;

const StatusInfoTrend = props => {
    const { loading, positiveValue, positiveValueDirection, value, isPerformance } = props;
    const direction = positiveValue ? positiveValueDirection : positiveValueDirection === 'up' ? 'down' : 'up';

    if (loading) {
        return (
            <StyledStatusInfoTrend>
                <Loader color="GRAY"/>
            </StyledStatusInfoTrend>
        );
    }

    return (
        <StyledStatusInfoTrend>
            <Icon
                name={ isPerformance ? 'star' : 'slim-arrow' }
                positive={ positiveValue }
                up={ direction === 'up' }
                noValue={ !value }
                isPerformance={ isPerformance }
            />
        </StyledStatusInfoTrend>
    );
};

export default StatusInfoTrend;
