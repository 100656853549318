// From https://gist.github.com/gka/7469245
const charWidth = (w, c) => {
    if (c === 'W' || c === 'M') {
        w += 15;
    } else if (c === 'w' || c === 'm') {
        w += 12;
    } else if (c === 'I' || c === 'i' || c === 'l' || c === 't' || c === 'f') {
        w += 4;
    } else if (c === 'r') {
        w += 8;
    } else if (c === c.toUpperCase()) {
        w += 12;
    } else {
        w += 10;
    }

    return w;
};

export default charWidth;
