import React, { PureComponent } from 'react';

export default class Tab extends PureComponent {

    render() {
        const { style, children } = this.props;
        return (
            <div style={ style }>
                { children }
            </div>
        );
    }
}
