import React from 'react';
import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import SkeletonText from 'components/Skeletons/SkeletonText';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import SectionHeader from 'components/Section/SectionHeader';
import Section from 'components/Section/Section';
import OPICard from 'components/OPICard/OPICard';
import OPICards from 'components/OPICard/OPICards';


export const EnergyOptimizationSkeleton = () =>
    <React.Fragment>
        <OPICards smallMargin noSmallMarginMobile>
            <OPICard loading />
        </OPICards>
        <Section>
            <SectionHeader>
                <SkeletonText header />
            </SectionHeader>
            <Columns>
                <Column columnWidth={{
                    landscape: 12,
                    default: 12
                }}>
                    <SkeletonText height="200px" />
                    <SkeletonChart height="200px" />
                </Column>

            </Columns>
        </Section>
    </React.Fragment>;
