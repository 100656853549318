import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import _ from 'lodash';
import {
    showGlobalNotification,
    hideGlobalNotification
} from 'redux/modules/notification/notification';

const withErrors = WrappedComponent => {
    class WithErrorsComponent extends Component {
        // We want to detect errors that occur after Component is mounted so let's cache changes to state
        state = {
            errorMessage: null
        }

        componentDidUpdate(prevProps) {
            if (!_.isEqual(this.props.errorMessage, prevProps.errorMessage)) {
                this.setState({
                    errorMessage: this.props.errorMessage
                });
            }
        }

        showError = error => {
            // Allow custom error messages
            const message = error
                ? error
                : this.props.t('There was an error')
                    + (this.state.errorMessage && this.state.errorMessage.message
                        ? ': ' + this.state.errorMessage.message
                        : '');
            this.props.showGlobalNotification(message, 'error');
        };

        hideError = () => {
            this.props.hideGlobalNotification();
        }

        render() {
            return (
                <WrappedComponent
                    { ...this.props }
                    showError={ customError => { this.showError(customError); } }
                    hideError={ this.hideError }
                    errorMessage={ this.state.errorMessage }
                />
            );
        }
    }

    WithErrorsComponent.propTypes = {
        errorMessage: PropTypes.object
    };

    WithErrorsComponent.defaultProps = {
        errorMessage: null
    };

    const mapStateToProps = state => ({
        errorMessage: state.errorMessage
    });

    const mapDispatchToProps = dispatch => ({
        showGlobalNotification: (message, notificationType) => {
            dispatch(showGlobalNotification(message, notificationType));
        },
        hideGlobalNotification: () => {
            dispatch(hideGlobalNotification());
        }
    });

    return translations(connect(mapStateToProps, mapDispatchToProps)(WithErrorsComponent));
};

export default withErrors;
