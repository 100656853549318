import React, { PureComponent } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment-timezone';
import { filter, size } from 'lodash';
import styled, { withTheme, keyframes } from 'styled-components';
import Responsive from '../Responsive/Responsive';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import { tooltipFormatter } from './utils';

const fadeIn = keyframes`
    0%      { opacity: 0 }
    50%     { opacity: 0 }
    100%    { opacity: 1 }
`;

const StyledStatusViewChart = styled.div`
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    color: ${ props => props.theme.colors.darkGray };
    width: 100%;

    .status-view-chart {
        opacity: 1;
        animation-duration: 560ms;
        animation-iteration-count: 1;
        animation-name: ${fadeIn};
        animation-fill-mode: forwards;
    }

    ${props => props.theme.media.desktop`
        width: calc(100% - 420px);
        max-width: ${ props => props.maxWidth || 'none' }
    `};
`;

class StatusViewChart extends PureComponent {

    colors = {
        'cooling power': this.props.theme.colors.blue,
        'heating power': this.props.theme.colors.orange,
        'ventilation power': this.props.theme.colors.rockBlue
    };

    temperatureColor = this.props.theme.colors.radicalRed;

    renderChart() {
        const { theme } = this.props;

        // Use 1.) explicit width and height, or 2.) Dimensions from Responsive, or 3.) Highcharts default.
        let chartWidth = this.props.width || this.props.dimensions ? this.props.dimensions.width : null;
        const chartHeight = this.props.height ?
            this.props.height :
            this.props.dimensions ? this.props.dimensions.height : null;

        const mobile = window.innerWidth < this.props.theme.breakpoints.landscape;
        if (mobile || chartWidth > window.innerWidth) {
            chartWidth = this.props.dimensions ? this.props.dimensions.width : window.innerWidth - 32;
        }

        const series = filter(this.props.series.map( (sensorData, index) => {
            if (sensorData.length === 0) {
                return {};
            }
            // Take the first sensor name and unit for the series name since they are all the same.
            const name = sensorData[0].sensorName;

            const dataSeries = {
                name: this.props.t(name),
                type: 'spline',
                data: sensorData.map(p => [moment.utc(p.timestamp).local().valueOf(), p.avg]),
                yAxis: 0,
                zIndex: index + 1,
            };

            // If color matching the series key is found, use it. Otherwise, use Highcharts auto color.
            dataSeries.color = this.props.colors && this.props.colors[index];

            return dataSeries;
        }), size);

        const temperatureSeriesName = this.props.t(this.props.temperature && this.props.temperature[0] ?
            this.props.temperature[0].sensorName : '');

        if (Array.isArray(this.props.temperature) && this.props.temperature.length > 0) {
            series.push({
                name: temperatureSeriesName,
                type: 'line',
                color: this.temperatureColor,
                lineWidth: 1,
                dashStyle: 'Dash',
                data: this.props.temperature.map(p => [moment.utc(p.timestamp).local().valueOf(), p.avg]),
                yAxis: 1,
                zIndex: 0
            });
        }

        const skeletonContent = this.props.loading ? `${ this.props.t('Loading data') }...` :
            !series.length ? this.props.t('No data available') : undefined;

        if (skeletonContent !== undefined) {
            return (
                <SkeletonChart
                    width={chartWidth ? `${chartWidth}px` : null}
                    height={chartHeight ? `${chartHeight}px` : null}>
                    { skeletonContent }
                </SkeletonChart>
            );
        }

        const config = {
            chart: {
                alignTicks: false,
                zoomType: this.props.noZoom ? undefined : 'x',
                backgroundColor: 'transparent',
                plotBackgroundColor: this.props.theme.colors.white,
                spacing: [8, 8, 16, 8],
                width: chartWidth,
                height: chartHeight,
                className: 'status-view-chart',
                marginTop: mobile ? 20 : 50,
                spacingBottom: 20
            },
            legend: {
                align: 'left',
                verticalAlign: 'top',
                floating: true
            },
            title: false,
            credits: false,
            tooltip: {
                split: true,
                shared: false,
                crosshairs: true,
                borderWidth: 0,
                padding: 0,
                backgroundColor: null,
                useHTML: true,
                formatter: function() {
                    return tooltipFormatter(this.points, this.x, theme, chartHeight - 75, temperatureSeriesName);
                }
            },
            plotOptions: {
                line: {
                    marker: {
                        enabled: false,
                        lineColor: undefined,
                        lineWidth: 2,
                        radius: 5,
                        fillColor: this.props.theme.colors.white,
                        symbol: 'circle',
                        states: {
                            hover: {
                                lineWidthPlus: 0,
                                radiusPlus: 0,
                                shadow: false,
                                animation: { duration: 0 }
                            }
                        }
                    },
                    states: {
                        hover: {
                            lineWidthPlus: 0,
                            halo: { size: 0 }
                        }
                    }
                },
                spline: {
                    marker: {
                        enabled: false,
                        lineColor: undefined,
                        lineWidth: 2,
                        radius: 5,
                        fillColor: this.props.theme.colors.white,
                        symbol: 'circle',
                        states: {
                            hover: {
                                lineWidthPlus: 0,
                                radiusPlus: 0,
                                shadow: false,
                                animation: { duration: 0 }
                            }
                        }
                    },
                    states: {
                        hover: {
                            lineWidthPlus: 0,
                            halo: { size: 0 }
                        }
                    },
                    lineWidth: 2
                }
            },
            xAxis: {
                type: 'datetime',
                lineWidth: 0,
                lineColor: this.props.theme.colors.mystic,
                minorGridLineWidth: 0,
                gridLineWidth: 0,
                tickLength: 0,
                gridLineColor: this.props.theme.colors.mystic,
                crosshair: {
                    color: this.props.theme.colors.blue
                },
                labels: {
                    style: {
                        color: this.props.theme.colors.rockBlue,
                        fontWeight: this.props.theme.font.weight.bold,
                        textTransform: 'uppercase',
                        letterSpacing: '1px'
                    }
                },
                title: !mobile ? {
                    text: `${this.props.t('Date')}`,
                    style: {
                        color: this.props.theme.colors.black,
                        fontWeight: this.props.theme.font.weight.bold
                    },
                    margin: 10
                } : false
            },
            yAxis: [{
                min: -1,
                max: 101,
                startOnTick: false,
                endOnTick: false,
                lineWidth: 0,
                lineColor: this.props.theme.colors.mystic,
                labels: {
                    format: `{value} %`,
                    style: {
                        color: this.props.theme.colors.rockBlue,
                        fontWeight: this.props.theme.font.weight.bold,
                    }
                },
                title: !mobile ? {
                    text: !mobile && `${this.props.t('Power %')}`,
                    style: {
                        color: this.props.theme.colors.black,
                        fontWeight: this.props.theme.font.weight.bold
                    }
                } : false,
                minorGridLineWidth: 0,
                gridLineWidth: 1,
                gridLineColor: this.props.theme.colors.mystic,
            }],
            series: series
        };

        // Add temperature line, if available.
        if (Array.isArray(this.props.temperature) && this.props.temperature.length > 0) {
            config.yAxis[1] = {
                startOnTick: false,
                endOnTick: false,
                opposite: true,
                lineWidth: 0,
                tickLength: 0,
                tickWidth: 1,
                tickInterval: 1,
                minRange: 5,
                lineColor: this.props.theme.colors.rockBlue,
                labels: {
                    format: `{value} ${String.fromCharCode(176)}C`,
                    style: {
                        color: this.props.theme.colors.blue
                    }
                },
                title: !mobile ? {
                    text: `${this.props.t('Outdoors temperature')} (${String.fromCharCode(176)}C)`,
                    style: {
                        color: this.props.theme.colors.blue
                    }
                } : false,
                minorGridLineWidth: 0,
                gridLineWidth: 0,
            };
        }
        return (
            <HighchartsReact
                highcharts={ Highcharts }
                options={ config }
                ref="StatusViewChart"
            />
        );
    }

    render() {
        return (
            <StyledStatusViewChart maxWidth={ this.props.maxWidth }>
                { this.renderChart() }
            </StyledStatusViewChart>
        );
    }
}

export default Responsive(withTheme(StatusViewChart));
