import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { flow, filter, flatten, map as fpMap } from 'lodash/fp';
import { groupBy, forEach, cloneDeep, isEmpty, map, includes } from 'lodash';
import moment from 'moment-timezone';
import styled from 'styled-components';

import SortableTable from 'components/SortableTable/SortableTable';
import DocumentRow from './DocumentRow';
import TableCell from 'components/BuildingEvents/EventsRow/EventsCell';
import SkeletonText from 'components/Skeletons/SkeletonText';

import { findFromFolderTree, insertFolderProperties } from 'utils/Data/documents';
import { isCaverionAdminRole } from 'utils/Data/profileData';


const NoResult = styled.p`
    font-family: ${props => props.theme.fontFamily.text};
    font-size: ${props => props.theme.fontSize.xs};
    color: ${props => props.theme.colors.darkGray};
    margin: ${props => props.theme.spacing.md};
`;

// create function that checks user's right to edit/create file or folder and returns actions accordingly
const getActions = (profile, onEdit, onDelete) => createdBy => {
    if (isCaverionAdminRole(profile.role) || createdBy === profile.id) {
        return { onEdit, onDelete };
    }
};

const getColumns = (t, hasActions) => {
    const columns = [
        {
            title: t('Document'),
            field: 'name',
            sortable: true,
            hideOnMobile: true
        },
        {
            title: t('Uploaded/Updated'),
            field: 'date',
            sortable: true,
        },
        {
            title: t('Size'),
            field: 'size',
            sortable: true,
            hideOnMobile: true
        },
        {
            sortable: false,
            hideOnMobile: true
        },
        {
            sortable: false,
            hideOnMobile: true
        },
        {
            sortable: false,
        }
    ];

    return columns;
};

const SkeletonBodyComponent = () =>
    <tbody>
        <tr>
            <TableCell>
                <SkeletonText />
            </TableCell>
            <TableCell hideOnMobile>
                <SkeletonText />
            </TableCell>
            <TableCell hideOnMobile>
                <SkeletonText />
            </TableCell>
            <TableCell hideOnMobile>
                <SkeletonText />
            </TableCell>
        </tr>
    </tbody>
;

export const DocumentTable = props => {
    const {
        t,
        documents,
        folders,
        files,
        download,
        loading = false,
        minimalUI,
        onEdit,
        onDelete,
        onFolderEdit,
        onFolderDelete,
        onFolderFileUpload,
        profile,
        openAllFolders = false,
        openFolders,
    } = props;

    const getFileActions = profile && onEdit && onDelete && getActions(profile, onEdit, onDelete);
    const getFolderActions = profile && onFolderEdit && onFolderDelete
        && getActions(profile, onFolderEdit, onFolderDelete);

    const externalDocuments = documents && !documents.error && flow(
        filter(document => document.files && document.files.length),
        fpMap(document =>
            fpMap(file => ({ ...file, documentId: document.id, folderId: document.folderId }))(document.files)
        ),
        flatten,
        fpMap(document => ({
            ...document,
            date: {
                date: document.lastModified,
                value: moment(document.lastModified).local().valueOf(),
            }
        }))
    )(documents);

    const fileDocuments = map(
        files,
        file => ({
            ...file,
            documentId: file.id,
            actions: getFileActions && !file.externalType && getFileActions(file.createdBy),
            date: {
                date: file.modified,
                value: moment(file.modified).local().valueOf(),
            }
        })
    );

    let data;

    // Show only external documents or files and folders
    if (externalDocuments) {
        data = externalDocuments;
    } else {
        let clonedFolders = cloneDeep(folders);

        const getFolderProperties = folder => ({
            actions: getFolderActions && getFolderActions(folder.createdBy),
            onFolderFileUpload,
            isOpen: openAllFolders || includes(openFolders, folder.id),
            date: {
                date: folder.modified,
                value: moment(folder.modified).local().valueOf(),
            }
        });

        // add properties
        clonedFolders = insertFolderProperties(clonedFolders, getFolderProperties);

        const rootFolder = { children: clonedFolders };
        const groupedFiles = groupBy(fileDocuments, 'folderId');

        // push documents to their folders
        forEach(groupedFiles, (group, folderId) => {
            const folder = findFromFolderTree(+folderId, rootFolder) || rootFolder;
            folder.children = (folder.children || []).concat(group);
        });

        data = rootFolder.children;
    }

    return (
        <Fragment>
            <SortableTable
                data-test-id={ `DocumentTable-loading-${ loading }` }
                columns={ getColumns(t, getFileActions && getFolderActions) }
                loading={ loading }
                data={ data }
                onClick={ download }
                RowComponent={ DocumentRow }
                SkeletonBodyComponent={ SkeletonBodyComponent }
                headerBackgroundColor="transparent"
                borderColor={ minimalUI ? 'transparent' : 'porcelain' }
                orderBy="date"
                order="asc"
                t={ t }
            />
            { !loading && isEmpty(data) && <NoResult>{t('No results found')}</NoResult> }
        </Fragment>
    );
};

DocumentTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    documents: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        files: PropTypes.arrayOf(PropTypes.shape({
            created: PropTypes.string.isRequired,
            extension: PropTypes.string,
            id: PropTypes.number.isRequired,
            lastModified: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            size: PropTypes.number,
        })).isRequired,
    })),
    files: PropTypes.arrayOf(PropTypes.shape({
        adminOnly: PropTypes.bool,
        folderId: PropTypes.number,
        functionalLocation: PropTypes.string,
        id: PropTypes.number,
        mimeTypes: PropTypes.string,
        name: PropTypes.string,
        size: PropTypes.number,
    })),
    folders: PropTypes.array,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onEditFolder: PropTypes.func,
    onDeleteFolder: PropTypes.func,
    onFolderFileUpload: PropTypes.func,
    download: PropTypes.func,
    minimalUI: PropTypes.bool,
    openAllFolders: PropTypes.bool,
    openFolders: PropTypes.array,
};

export default DocumentTable;
