import { createReducerFromMapping } from 'redux/utils/index.js';
import { MasterData } from '@caverion/redux/api/actions';
import moment from 'moment';
import { isAdminRole } from 'utils/Data/profileData';

const initialState = {
    loading: false,
    loadingSensors: [],
    buildingHierarchy: {},
    sensors: {},
    floors: {},
    coords: {},
    images: {},
    areas: {},
    sensorDataTypes: [],
    loadingDataTypes: false,
    loadingDataTypesError: null,
    error: null,
    sensorSearchWord: '',
    searchError: null,
    searchResult: [],
    searchingSensors: false,
    sensorSearchResult: []
};


export const LOAD_TYPES = 'CUSTOMER_PLATFORM/Building/LOAD_TYPES';
export const LOAD_TYPES_SUCCESS = 'CUSTOMER_PLATFORM/Building/LOAD_TYPES_SUCCESS';
export const LOAD_TYPES_FAIL = 'CUSTOMER_PLATFORM/Building/LOAD_TYPES_FAIL';

export const loadSensorDataTypes = () => {
    return async dispatch => {
        dispatch({ type: LOAD_TYPES });
        try {
            const result = await dispatch(MasterData.sensorTypes(JSON.stringify({
                include: [{
                    relation: 'aggregations',
                    scope: {
                        where: {
                            active: true
                        },
                        fields: ['aggregation', 'frequency'],
                    },
                }, {
                    relation: 'latestValueAggregation'
                }, {
                    relation: 'sensorTypeGroups',
                }],
            })));
            return dispatch({
                type: LOAD_TYPES_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_TYPES_FAIL,
                error
            });
        }
    };
};
export const SET_SENSOR_SEARCH_WORD = 'CUSTOMER_PLATFORM/Building/SENSOR_SEARCH_WORD';

export const setSensorSearchWord = searchWord => {
    return {
        type: SET_SENSOR_SEARCH_WORD,
        searchWord
    };
};

export const loadBuildingLevelSensorHierarchiesByFL = functionalLocation => {
    const filter = {
        where: {
            functionalLocation,
            type: 'building'
        }
    };
    return async dispatch => {
        const result = await dispatch(MasterData.sensorHierarchies(filter));
        return result;
    };
};

export const LOAD = 'CUSTOMER_PLATFORM/Building/LOAD';
export const LOAD_SUCCESS = 'CUSTOMER_PLATFORM/Building/LOAD_SUCCESS';
export const LOAD_FAIL = 'CUSTOMER_PLATFORM/Building/LOAD_FAIL';

const coordsIncludes = [{ coords: 'subsensors' }];

const sensorIncludes = [
    coordsIncludes,
    'analytics',
    'sensorMeta',
    { sensorType: ['latestValueAggregation'] },
    {
        children: [
            coordsIncludes,
            'analytics',
            'sensorMeta',
            { parent: ['sensorType'] },
            { sensorType: ['latestValueAggregation'] },
        ]
    },
];
const hierarchyIncludes = [coordsIncludes, 'images', { sensors: sensorIncludes }];

export const loadSensorHierarchies = functionalLocation => {
    const filter = {
        where: {
            functionalLocation,
            type: 'building'
        },
        include: [
            {
                children: [
                    {
                        children: hierarchyIncludes
                    },
                    {
                        sensors: sensorIncludes
                    },
                    coordsIncludes,
                    'images'
                ]
            },
            {
                sensors: sensorIncludes
            },
            coordsIncludes,
            'images'
        ],
    };
    return async (dispatch, getState) => {
        // Invalidate cache for admin users
        // TODO: Proper cache invalidation
        const state = getState();
        const profile = state.profile && state.profile.profile;
        if (profile && isAdminRole(profile.role)) {
            filter.timestamp = moment.utc().toISOString();
        }
        dispatch({ type: LOAD, key: functionalLocation + '_sensorHierarchies' });
        try {
            const result = await dispatch(MasterData.sensorHierarchies(filter));
            return dispatch({
                type: LOAD_SUCCESS,
                key: functionalLocation + '_sensorHierarchies',
                functionalLocation,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_FAIL,
                key: functionalLocation + '_sensorHierarchies',
                error
            });
        }
    };
};

export const SEARCH_SENSORS = 'CUSTOMER_PLATFORM/Building/SEARCH_SENSORS';
export const SEARCH_SENSORS_SUCCESS = 'CUSTOMER_PLATFORM/Building/SEARCH_SENSORS_SUCCESS';
export const SEARCH_SENSORS_FAIL = 'CUSTOMER_PLATFORM/Building/SEARCH_SENSORS_FAIL';

export const searchSensors = filter => {

    return async (dispatch, getState) => {
        // Invalidate cache for admin users
        // TODO: Proper cache invalidation
        const state = getState();
        const profile = state.profile && state.profile.profile;
        if (profile && isAdminRole(profile.role)) {
            filter.timestamp = moment.utc().toISOString();
        }
        dispatch({ type: SEARCH_SENSORS, key: 'searchSensors' });
        try {
            const result = await dispatch(MasterData.searchSensors(filter));
            return dispatch({
                type: SEARCH_SENSORS_SUCCESS,
                key: 'searchSensors',
                result
            });
        } catch (error) {
            return dispatch({
                type: SEARCH_SENSORS_FAIL,
                key: 'searchSensors',
                error
            });
        }
    };
};

export const UPSERT_COORDS = 'CUSTOMER_PLATFORM/Building/UPSERT_COORDS';
export const UPSERT_COORDS_SUCCESS = 'CUSTOMER_PLATFORM/Building/UPSERT_COORDS_SUCCESS';
export const UPSERT_COORDS_FAIL = 'CUSTOMER_PLATFORM/Building/UPSERT_COORDS_FAIL';

export const upsertCoords = data => {
    return async dispatch => {
        dispatch({ type: UPSERT_COORDS });
        try {
            const result = await dispatch(MasterData.upsertCoords(data));
            return dispatch({
                type: UPSERT_COORDS_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: UPSERT_COORDS_FAIL,
                error
            });
        }
    };
};

export const UPSERT_SENSORHIERARCHY = 'CUSTOMER_PLATFORM/Building/UPSERT_SENSORHIERARCHY';
export const UPSERT_SENSORHIERARCHY_SUCCESS = 'CUSTOMER_PLATFORM/Building/UPSERT_SENSORHIERARCHY_SUCCESS';
export const UPSERT_SENSORHIERARCHY_FAIL = 'CUSTOMER_PLATFORM/Building/UPSERT_SENSORHIERARCHY_FAIL';

export const upsertSensorHierarchy = sensorHierarchy => {
    return async dispatch => {
        dispatch({ type: UPSERT_SENSORHIERARCHY });
        try {
            const result = await dispatch(MasterData.upsertSensorHierarchy(sensorHierarchy));
            return dispatch({
                type: UPSERT_SENSORHIERARCHY_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: UPSERT_SENSORHIERARCHY_FAIL,
                error
            });
        }
    };
};

export const DELETE_SENSORHIERARCHY = 'CUSTOMER_PLATFORM/Building/DELETE_SENSORHIERARCHY';
export const DELETE_SENSORHIERARCHY_SUCCESS = 'CUSTOMER_PLATFORM/Building/DELETE_SENSORHIERARCHY_SUCCESS';
export const DELETE_SENSORHIERARCHY_FAIL = 'CUSTOMER_PLATFORM/Building/DELETE_SENSORHIERARCHY_FAIL';

export const deleteSensorHierarchy = (id, functionalLocation) => {
    return async dispatch => {
        dispatch({ type: DELETE_SENSORHIERARCHY });
        try {
            const result = await dispatch(MasterData.deleteSensorHierarchy(id));
            return dispatch({
                type: DELETE_SENSORHIERARCHY_SUCCESS,
                id,
                functionalLocation,
                result
            });
        } catch (error) {
            return dispatch({
                type: DELETE_SENSORHIERARCHY_FAIL,
                error
            });
        }
    };
};

export const UPSERT_SENSOR = 'CUSTOMER_PLATFORM/Building/UPSERT_SENSOR';
export const UPSERT_SENSOR_SUCCESS = 'CUSTOMER_PLATFORM/Building/UPSERT_SENSOR_SUCCESS';
export const UPSERT_SENSOR_FAIL = 'CUSTOMER_PLATFORM/Building/UPSERT_SENSOR_FAIL';

export const upsertSensor = sensor => {
    return async dispatch => {
        dispatch({ type: UPSERT_SENSOR });
        try {
            const result = await dispatch(MasterData.upsertSensor(sensor));
            return dispatch({
                type: UPSERT_SENSOR_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: UPSERT_SENSOR_FAIL,
                error
            });
        }
    };
};

export const UPSERT_HIERARCHIES_FOR_SENSOR = 'CUSTOMER_PLATFORM/Building/UPSERT_HIERARCHIES_FOR_SENSOR';
export const UPSERT_HIERARCHIES_FOR_SENSOR_SUCCESS = 'CUSTOMER_PLATFORM/Building/UPSERT_HIERARCHIES_FOR_SENSOR_SUCCESS';
export const UPSERT_HIERARCHIES_FOR_SENSOR_FAIL = 'CUSTOMER_PLATFORM/Building/UPSERT_HIERARCHIES_FOR_SENSOR_FAIL';

export const upsertHierarchiesForSensor = (sensorId, hierarchy) => {
    return async dispatch => {
        dispatch({ type: UPSERT_HIERARCHIES_FOR_SENSOR });
        try {
            const result = await dispatch(MasterData.upsertHierarchiesForSensor(sensorId, hierarchy));
            return dispatch({
                type: UPSERT_HIERARCHIES_FOR_SENSOR_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: UPSERT_HIERARCHIES_FOR_SENSOR_FAIL,
                error
            });
        }
    };
};

export const DELETE_SENSOR = 'CUSTOMER_PLATFORM/Building/DELETE_SENSOR';
export const DELETE_SENSOR_SUCCESS = 'CUSTOMER_PLATFORM/Building/DELETE_SENSOR_SUCCESS';
export const DELETE_SENSOR_FAIL = 'CUSTOMER_PLATFORM/Building/DELETE_SENSOR_FAIL';

export const deleteSensor = (sensorId, functionalLocation) => {
    return async dispatch => {
        dispatch({ type: DELETE_SENSOR });
        try {
            const result = await dispatch(MasterData.deleteSensor(sensorId));
            return dispatch({
                type: DELETE_SENSOR_SUCCESS,
                id: sensorId,
                functionalLocation,
                result
            });
        } catch (error) {
            return dispatch({
                type: DELETE_SENSOR_FAIL,
                error
            });
        }
    };
};

export const DELETE_COORDS = 'CUSTOMER_PLATFORM/Building/DELETE_COORDS';
export const DELETE_COORDS_SUCCESS = 'CUSTOMER_PLATFORM/Building/DELETE_COORDS_SUCCESS';
export const DELETE_COORDS_FAIL = 'CUSTOMER_PLATFORM/Building/DELETE_COORDS_FAIL';

export const deleteCoords = (coordsId, functionalLocation) => {
    return async dispatch => {
        dispatch({ type: DELETE_COORDS });
        try {
            const result = await dispatch(MasterData.deleteCoords(coordsId));
            return dispatch({
                type: DELETE_COORDS_SUCCESS,
                id: coordsId,
                functionalLocation,
                result
            });
        } catch (error) {
            return dispatch({
                type: DELETE_COORDS_FAIL,
                error
            });
        }
    };
};

export default createReducerFromMapping({
    [LOAD]: (state, action) => ({
        ...state,
        loading: true
    }),
    [LOAD_SUCCESS]: (state, action) => {
        const buildingLevel = action.result[0];
        if (!buildingLevel) {
            return {
                ...state
            };
        }
        const buildingHierarchy = { ...state.buildingHierarchy, [action.functionalLocation]: action.result };

        return {
            ...state,
            buildingHierarchy,
            loading: false
        };
    },
    [LOAD_FAIL]: (state, action) => ({
        ...state,
        loading: false,
        error: action.error
    }),
    [UPSERT_COORDS_SUCCESS]: (state, action) => ({
        ...state
    }),
    [UPSERT_COORDS_FAIL]: (state, action) => ({
        ...state,
        error: action.error
    }),

    [DELETE_SENSORHIERARCHY_SUCCESS]: (state, action) => ({
        ...state
    }),
    [DELETE_SENSORHIERARCHY_FAIL]: (state, action) => ({
        ...state,
        error: action.error
    }),

    [DELETE_SENSOR_SUCCESS]: (state, action) => ({
        ...state
    }),
    [DELETE_SENSOR_FAIL]: (state, action) => ({
        ...state,
        error: action.error
    }),

    [DELETE_COORDS_SUCCESS]: (state, action) => ({
        ...state
    }),
    [DELETE_COORDS_FAIL]: (state, action) => ({
        ...state,
        error: action.error
    }),

    [UPSERT_SENSOR_SUCCESS]: (state, action) => ({
        ...state
    }),
    [UPSERT_SENSOR_FAIL]: (state, action) => ({
        ...state,
        error: action.error
    }),

    [UPSERT_HIERARCHIES_FOR_SENSOR_SUCCESS]: (state, action) => ({
        ...state
    }),
    [UPSERT_HIERARCHIES_FOR_SENSOR_FAIL]: (state, action) => ({
        ...state,
        error: action.error
    }),

    [UPSERT_SENSORHIERARCHY_SUCCESS]: (state, action) => ({
        ...state
    }),
    [UPSERT_SENSORHIERARCHY_FAIL]: (state, action) => ({
        ...state,
        error: action.error
    }),

    [LOAD_TYPES]: state => ({ ...state, loadingDataTypes: true }),
    [LOAD_TYPES_SUCCESS]: (state, action) => ({
        ...state,
        sensorDataTypes: action.result,
        loadingDataTypes: false
    }),
    [LOAD_TYPES_FAIL]: (state, action) => ({
        ...state,
        sensorDataTypes: [],
        loadingDataTypes: false,
        loadingDataTypesError: action.error
    }),
    [SET_SENSOR_SEARCH_WORD]: (state, action) => ({
        sensorSearchWord: action.searchWord
    }),
    [SEARCH_SENSORS]: state => ({
        ...state,
        searchingSensors: true,
        searchFail: null
    }),
    [SEARCH_SENSORS_SUCCESS]: (state, action) => ({
        ...state,
        searchingSensors: false,
        sensorSearchResult: action.result
    }),
    [SEARCH_SENSORS_FAIL]: (state, action) => ({
        ...state,
        searchingSensors: false,
        searchError: action.error
    })
}, initialState);

