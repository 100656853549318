import _ from 'lodash';
import { createReducerFromMapping } from 'redux/utils/index.js';

import { loadEquipment, loadSubEquipments, loadSubEquipment } from 'redux/modules/customer/equipments.js';
import { loadDocumentsByEquipment, loadFunctionalLocations, loadFunctionalLocationsByPath } from 'redux/modules';
import { loadSensorAlarms } from 'redux/modules/iot/sensorAlarms';
import { loadFunctionalLocationsImages } from 'redux/modules/customer/partnerImage';
import { isBusinessUnit, isTenant } from 'utils/Data/functionalLocations.js';

const initialState = {
    // Make the default state loading so that the first load respects loading order via promise resolve
    loading: true,
    loadingParents: true,
    error: false
};

export const EQUIPMENT_LOAD = 'CUSTOMER_PLATFORM/EquipmentContainerModule/LOAD';
export const EQUIPMENT_LOAD_SUCCESS = 'CUSTOMER_PLATFORM/EquipmentContainerModule/LOAD_SUCCESS';
export const EQUIPMENT_LOAD_FAIL = 'CUSTOMER_PLATFORM/EquipmentContainerModule/LOAD_FAIL';

export const loadEquipmentContainer = (
    functionalLocationId, equipmentNumber, features, superordinate, partnerNumber
) => {
    return async dispatch => {
        dispatch({ type: EQUIPMENT_LOAD });
        try {
            // Load parent FL
            const functionalLocationResult = await dispatch(loadFunctionalLocations([functionalLocationId]));
            const functionalLocation = functionalLocationResult.result[0];
            const flPath = _.without(functionalLocation.path, functionalLocation.functionalLocation);

            // Load the equipment
            const equipmentResult = await dispatch(superordinate ?
                loadEquipment(functionalLocation, equipmentNumber, superordinate) :
                loadSubEquipment(functionalLocation, equipmentNumber, superordinate));
            const equipment = equipmentResult.result[0];

            // Dispatch success to start rendering
            dispatch({ type: EQUIPMENT_LOAD_SUCCESS });

            // Load parent hierarchy and building image
            dispatch(loadFunctionalLocationsByPath(partnerNumber, flPath)).then(({ result }) => {
                // Load images for BU and UN parents
                const loadImagesTo = _.filter(result, isBusinessUnit || isTenant).map(fl => fl.functionalLocation);
                if (loadImagesTo && loadImagesTo.length > 0) {
                    dispatch(loadFunctionalLocationsImages(loadImagesTo))
                        .then(() => dispatch(setEquipmentParentsLoaded()));
                } else {
                    !superordinate && dispatch(setEquipmentParentsLoaded());
                }
            });

            // Load parent equipment if subequipment
            if (superordinate) {
                dispatch(loadEquipment(functionalLocation, superordinate)).then(() =>
                    dispatch(setEquipmentParentsLoaded())
                );
            }

            // Load external documents, if enabled
            if (features.documents) {
                dispatch(loadDocumentsByEquipment(functionalLocation, equipmentNumber));
            }

            // Load subequipment
            const subEquipmentResult = await dispatch(loadSubEquipments(
                functionalLocation.functionalLocation, equipment.equipmentNumber
            ));
            const subEquipment = subEquipmentResult.result;

            // Load subequipment for subequipment (but don't wait for them to load)
            subEquipment.forEach(item => {
                dispatch(loadSubEquipments(functionalLocation.functionalLocation, item.equipmentNumber));
            });

            features.conditions && dispatch(loadSensorAlarms());
        } catch (error) {
            return dispatch({
                type: EQUIPMENT_LOAD_FAIL,
                error
            });
        }
    };
};

export const SET_EQUIPMENT_PARENTS_LOADED =
    'CUSTOMER_PLATFORM/EquipmentContainerModule/SET_EQUIPMENT_PARENTS_LOADED';

export const setEquipmentParentsLoaded = () => {
    return {
        type: SET_EQUIPMENT_PARENTS_LOADED,
        loadingParents: false
    };
};

export default createReducerFromMapping({
    [EQUIPMENT_LOAD]: state => ({
        ...state,
        loading: true,
        loadingParents: true
    }),
    [EQUIPMENT_LOAD_SUCCESS]: state => ({
        ...state,
        loading: false
    }),
    [EQUIPMENT_LOAD_FAIL]: (state, action) => ({
        ...state,
        loading: false,
        error: action.error
    }),
    [SET_EQUIPMENT_PARENTS_LOADED]: (state, { loadingParents }) => ({
        ...state,
        loadingParents
    }),
}, initialState);
