
import moment from 'moment-timezone';

import { isAdminRole } from 'utils/Data/profileData';
import { isValidPartner } from 'utils/Data/partners';
import { icons } from 'components/Icon/IconNames';

export const isServiceRequestEnabled = features => features && features.sr || false;

export const getLink = (partnerNumber, link, extra = '') =>
    isValidPartner(partnerNumber) ? `/${partnerNumber}/${link}` : `${extra}/${link}`;

export const globalCalendarTo = activePartner => ({
    pathname: getLink(activePartner, 'ServiceCalendar'),
    search: '?year=' + moment().format('YYYY')
});

export const getNavigationItems = (activePartner, features, role, t) => [
    {
        dataTestId: 'NavigationLinkToOverview',
        linkTo: '/',
        iconName: icons.PORTFOLIO,
        text: t('Portfolio'),
        show: true
    },
    {
        dataTestId: 'NavigationLinkToMyProfile',
        linkTo: '/Profile',
        iconName: icons.ADMIN_MANAGE_USERS,
        text: t('My Profile'),
        show: true
    },
    {
        dataTestId: 'NavigationLinkToAdmin',
        linkTo: '/Admin',
        iconName: icons.ADMIN_PANEL,
        text: t('Admin Panel'),
        show: isAdminRole(role)
    },
    {
        dataTestId: 'NavigationLinkToNewServiceRequest',
        linkTo: getLink(activePartner, 'ServiceRequest'),
        iconName: icons.NEW_SERVICE_REQUEST,
        text: t('New Service Request'),
        show: isServiceRequestEnabled(features)
    },
    {
        dataTestId: 'NavigationLinkToUserManual',
        linkTo: '/UserManual',
        iconName: icons.DOCUMENTS,
        text: t('User Manual'),
        show: true
    },
    {
        dataTestId: 'NavigationLinkToAdminUserManual',
        linkTo: '/AdminUserManual',
        iconName: icons.ADMIN_USER_MANUAL,
        text: t('Admin User Manual'),
        show: isAdminRole(role)
    },
    {
        dataTestId: 'NavigationLinkToLogout',
        linkTo: '/Logout',
        iconName: icons.LOGOUT,
        text: t('Logout'),
        show: true
    },
];
