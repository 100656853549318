import styled, { withTheme } from 'styled-components';

const BuildingStatusView = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    ${ props => props.theme.media.desktop`
        flex-wrap: nowrap;
        justify-content: space-between;
    ` };
`;

BuildingStatusView.displayName = 'BuildingStatusView';

export default withTheme(BuildingStatusView);
