import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import ZoomableChart from 'components/Charts/ZoomableChart';
import ValuesBox from 'components/ValuesBox/ValuesBox';
import ValuesBoxComparisonValues from 'components/ValuesBox/ValuesBoxComparisonValues';
import ValuesBoxComparisonValue from 'components/ValuesBox/ValuesBoxComparisonValue';

export const EnergyBreakdownTab = ({
    unit,
    years,
    series,
    subHeading,
    categories,
    yearTotals,
    valuesBoxValue,
    valuesBoxLabel,
    t,
    onZoom,
    zoom,
    xTitle,
    loading,
    theme,
    temperatureSeries,
    maxZoomLevel,
}) => {
    const sortedYears = _.reverse(_.sortBy(years, ));
    return (
        <Columns>
            <Column columnWidth={{
                landscape: 9,
                default: 12
            }}>
                <ZoomableChart
                    categories={ categories }
                    series={ series }
                    temperatureSeries={ temperatureSeries }
                    t={ t }
                    maxZoomLevel={ maxZoomLevel }
                    onZoom={ onZoom }
                    zoom={ zoom }
                    xTitle={ xTitle }
                    loading={ loading }
                />
            </Column>
            <Column columnWidth={{
                landscape: 3,
                default: 12
            }}>
                { !!subHeading &&
                <ValuesBox
                    heading={subHeading}
                    value={`${Math.round(valuesBoxValue)} ${unit}`}
                    label={valuesBoxLabel}
                    positiveValueColor={theme.charts.colorsEnergy[0]}
                >
                    { sortedYears && sortedYears.length > 0 && yearTotals &&
                    <ValuesBoxComparisonValues>
                        { _.times(sortedYears.length < 2 ? sortedYears.length : 2, idx =>
                            !!sortedYears[idx] && !!yearTotals[sortedYears[idx]] &&
                                <ValuesBoxComparisonValue
                                    key={`value${idx}`}
                                    value={`${Math.round(yearTotals[sortedYears[idx]])} ${unit}`}
                                    label={sortedYears[idx] }
                                />
                        ) }
                    </ValuesBoxComparisonValues>
                    }
                </ValuesBox>
                }
            </Column>
        </Columns>
    );
};

EnergyBreakdownTab.defaultProps = {
    unit: '',
    years: [],
    series: [],
    categories: [],
    yearTotals: {},
    subHeading: '',
    valuesBoxLabel: '',
    valuesBoxValue: 0,
    onZoom: undefined,
    zoom: null,
    xTitle: '',
    loading: false,
    temperatureSeries: [],
    maxZoomLevel: undefined,
};

EnergyBreakdownTab.propTypes = {
    unit: PropTypes.string,
    years: PropTypes.array,
    series: PropTypes.array,
    categories: PropTypes.array,
    yearTotals: PropTypes.object,
    subHeading: PropTypes.string,
    valuesBoxLabel: PropTypes.string,
    valuesBoxValue: PropTypes.number,
    t: PropTypes.func.isRequired,
    onZoom: PropTypes.func,
    zoom: PropTypes.object,
    xTitle: PropTypes.string,
    loading: PropTypes.bool,
    temperatureSeries: PropTypes.array,
    maxZoomLevel: PropTypes.number,
};

export default withTheme(EnergyBreakdownTab);
