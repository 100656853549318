import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DialogFooter from 'components/Dialog/DialogFooter';
import Button from 'components/Button/Button';

const SingleButtonFooter = styled(DialogFooter)`
    justify-content: center;
`;

const ApiKeyModalFooter = ({ t, close, disabled, created }) => {
    if (created) {
        return (
            <SingleButtonFooter>
                <Button onClick={ close }>{ t('Close') }</Button>
            </SingleButtonFooter>
        );
    }

    return (
        <DialogFooter>
            <Button cancel onClick={ close }>{ t('Cancel') }</Button>
            <Button
                submit
                type="submit"
                disabled={ disabled }
            >
                { t('Generate') }
            </Button>
        </DialogFooter>
    );
};

ApiKeyModalFooter.propTypes = {
    t: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    created: PropTypes.bool.isRequired,
};

export default ApiKeyModalFooter;
