import React, { Fragment, useState } from 'react';

import AlarmsTable from 'components/BuildingEvents/Alarms/AlarmsTable';
import SectionHeader from 'components/Section/SectionHeader';
import Section from 'components/Section/Section';
import OPICard from 'components/OPICard/OPICard';
import OPICards from 'components/OPICard/OPICards';
import DialogModal from 'components/Dialog/DialogModal';
import EventSummary from 'components/EventSummary/EventSummary';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import Button from 'components/Button/Button';
import {
    FilterPillsContainer,
    HeaderInfo,
    overrideColumnWidth,
    NoDataAvailable
} from 'components/BuildingEvents/BuildingEvents';

const Alarms = props => {
    const {
        t,
        alarms,
        loading,
    } = props;

    const [showActions, setShowActions] = useState(false);

    const hasAlarms = alarms && alarms.alarms && alarms.alarms.length;

    const alarmValue = alarms && alarms.totals &&
        alarms.totals.acknowledged && Math.round(alarms.totals.acknowledged / alarms.totals.all * 100) || 0;
    const hasActions = alarms.totals && alarms.totals.withAction > 0;
    const averageActionTime = alarms.totals && !isNaN(alarms.totals.averageActionTime)
        && alarms.totals.averageActionTime || '-';
    const averageTimeToAction = alarms.totals && !isNaN(alarms.totals.averageTimeToAction)
        && alarms.totals.averageTimeToAction || '-';

    return (
        <Fragment>
            <FilterPillsContainer />
            <OPICards>
                <OPICard
                    t={ t }
                    loading={ loading }
                    title={ t('Alarms') }
                    value={ alarmValue }
                    subtitle={ `${alarms.totals ? alarms.totals.acknowledged : '-'}\u00A0${t('Handled')}\u00A0/\u00A0${alarms.totals ? alarms.totals.all : '-'}\u00A0${t('Total')}\n(${t('last 365 days')})` }
                    ctxHelp={`${CTXHELP_PREFIX} Alarms OPI`}
                    overrideColumnWidth={ overrideColumnWidth }
                />
                <OPICard
                    t={ t }
                    title={ t('Alarm handling time') }
                    subtitle={ t('Last 365 days average') }
                    valueInside={ averageActionTime }
                    valueInsideLabel={ t('min') }
                    valueInsideIsPositive={ !isNaN(averageActionTime) && averageActionTime < 60 }
                    noCircle
                    ctxHelp={ `${CTXHELP_PREFIX} Alarm handling time` }
                    loading={ loading }
                    overrideColumnWidth={ overrideColumnWidth }
                />
                <OPICard
                    t={ t }
                    title={ t('Time to action') }
                    subtitle={ t('Last 365 days average') }
                    valueInside={ averageTimeToAction }
                    valueInsideLabel={ t('min') }
                    valueInsideIsPositive={ !isNaN(averageTimeToAction) && averageTimeToAction < 180 }
                    noCircle
                    ctxHelp={ `${CTXHELP_PREFIX} Time to action` }
                    ctxHelpPosition="left"
                    loading={ loading }
                    overrideColumnWidth={ overrideColumnWidth }
                />
            </OPICards>
            <Section>
                <SectionHeader noBorder title={ t('Actions for Alarms') } t={ t }>
                    <HeaderInfo>{ t('Last 365 Days') }</HeaderInfo>
                    <Button
                        onClick={ () => setShowActions(true) }
                        disabled={ !hasActions }
                    >
                        { t('Action breakdown') }
                    </Button>
                </SectionHeader>
                { !hasAlarms && !loading ? <NoDataAvailable>
                    { t('No actions found for the last 365 days.') }
                </NoDataAvailable>
                : <AlarmsTable
                    loading={ loading }
                    t={ t }
                    data={ alarms.alarms }
                /> }
            </Section>
            { showActions && <DialogModal isActive onOverlayClick={ () => setShowActions(false) } t={ t }>
                <EventSummary
                    breakdownHeading={ t('Action breakdown') }
                    breakdownItems={ alarms.totals ? alarms.totals.byAction : {}}
                    t={ t }
                />
            </DialogModal> }
        </Fragment>
    );
};

export default Alarms;
