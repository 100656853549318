import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { transparentize } from 'polished';
import PropTypes from 'prop-types';

import Svg from 'components/Svg/Svg';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    padding: ${props => !props.large && `0 ${props.theme.spacing.sm}`};
`;
Container.displayName = 'Container';

const CircleContainer = styled.div`
    margin: ${props => props.large ? '0.5em 0 0 0' : '0.5em 1em 0 0' };
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${props => props.large && '7em'};
`;
CircleContainer.displayName = 'CircleContainer';

const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.large && '2.5em' };
    min-width: ${props => !props.large && '1em' };
    height: ${props => props.large ? '2.5em' : '1em' };
    padding: 1em;
    margin-bottom: 0.5em;
    color: ${props => props.selected && !props.disabled ? props.theme.colors.cerulean : props.theme.colors.rockBlue};
    font-family: ${props => props.theme.font.sanchez};
    font-weight: ${props => props.theme.fontWeight.bold};
    border: 2px solid ${props => props.selected && !props.disabled ? props.theme.colors.cerulean : props.theme.colors.rockBlue};
    border-radius: 4em;
    box-sizing: content-box;
    cursor: ${props => !props.disabled && 'pointer'};
    position: relative;
    box-shadow: ${props => props.selected && `0 2px 10px ${transparentize(0.75, props.theme.colors.black)}`};
`;
Circle.displayName = 'Circle';

const SelectedMarkContainer = styled.div`
    background-color: ${props => !props.disabled ? props.theme.colors.cerulean : props.theme.colors.rockBlue};
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    position: absolute;
    top: ${props => props.large ? 0 : '-5px'};
    right: ${props => props.large ? 0 : '-5px'};
    justify-content: center;
    align-items: center;
`;
SelectedMarkContainer.displayName = 'SelectedMarkContainer';

const SelectedIcon = styled(Svg)`
    fill: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fontSize.xxxs};
`;

const Icon = styled(Svg)`
    fill: ${props => props.selected ? props.theme.colors.cerulean : props.theme.colors.rockBlue};
    font-size: ${props => props.theme.fontSize.xl};
`;
Icon.displayName = 'Icon';

const Label = styled.p`
    color: ${props => props.selected ? props.theme.colors.cerulean : props.theme.colors.black};
    font-size: ${props => props.theme.fontSize.xs};
    font-weight: ${props => props.selected && props.theme.fontWeight.bold};
`;

export const InputCircleSelect = props => {
    const value = _.get(props.model, props.property);

    return (
        <Container id={ props.id }>
            { props.options.map(option => {
                const selected = value === option.value;
                return <CircleContainer
                    key={ option.value }
                    large={ option.icon }
                    onClick={ () => !props.disabled ? props.onChange(props.property, option.value) : undefined }
                >
                    <Circle selected={ selected } large={ option.icon } disabled={ props.disabled }>
                        { !option.icon && option.label }
                        { option.icon && <Icon name={ option.icon } selected={ selected } /> }
                        { selected && <SelectedMarkContainer large={ option.icon } disabled={ props.disabled }>
                            <SelectedIcon name="check" />
                        </SelectedMarkContainer> }
                    </Circle>
                    { option.icon && <Label selected={ selected }>{ option.label }</Label> }
                </CircleContainer>;
            })}
        </Container>
    );
};

InputCircleSelect.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.string.isRequired,
        icon: PropTypes.string
    })).isRequired,
    id: PropTypes.string,
    property: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    model: PropTypes.object,
    disabled: PropTypes.bool
};

InputCircleSelect.defaultProps = {
    id: undefined,
    property: '',
    value: '',
    model: {}
};

export default InputCircleSelect;
