import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import memoizeOne from 'memoize-one';
import { includes } from 'lodash';

import CalendarFilters from 'components/Calendar/CalendarFilters/CalendarFilters';
import translations from 'decorators/Translations/translations';
import CalendarMonthSelector from 'components/Calendar/CalendarMonthSelector';
import {
    loadAllPlannedMaintenances,
    loadPlannedMaintenances,
    loadFunctionalLocations,
    addLoading,
    removeLoading,
    setStatisticsView,
} from 'redux/modules';
import { getPlannedMaintenanceListVariants } from 'redux/modules/service/selectors/plannedMaintenance';
import * as utils from '../../utils';
import { getUniqueFunctionalLocations } from 'utils/Data/serviceOrders';
import { OrderType, ViewMode, CONFIGURATION_PROPERTY, DEFAULT_COLUMNS } from 'constants/serviceCalendar';
import CalendarBody from 'components/Calendar/CalendarBody';
import PlainSection from 'components/Section/PlainSection';
import CalendarMeters from 'components/Calendar/CalendarMeters/CalendarMeters';
import { findPartnerNumberForFL } from 'utils/Data/partners';

const getMonthlyCounts = memoizeOne(utils.getMonthlyCounts);

const COMPONENT = 'PlannedMaintenance';

export const PlannedMaintenance = ({
    activeFilter,
    addLoading,
    day,
    functionalLocations,
    history,
    loadAllPlannedMaintenances,
    loadFunctionalLocations,
    loading,
    loadingFLs,
    loadingKey,
    loadPlannedMaintenances,
    location,
    match,
    months,
    plannedMaintenances,
    query,
    removeLoading,
    t,
    year,
    setStatisticsView,
    listConfiguration,
    profile,
    equipmentTexts,
}) => {
    const { params: { functionalLocationId, equipmentNumber } } = match;

    const partnerNumber = !functionalLocationId || match.params.partnerNumber && match.params.partnerNumber !== 'all'
        ? match.params.partnerNumber : findPartnerNumberForFL(functionalLocations[functionalLocationId], profile);

    useEffect(() => {
        const start = moment.utc(year, 'YYYY').startOf('year');
        const end = moment.utc(start).endOf('year');

        (functionalLocationId ?
            loadPlannedMaintenances(partnerNumber, functionalLocationId, equipmentNumber, start, end, loadingKey) :
            loadAllPlannedMaintenances(start, end, partnerNumber, loadingKey)
        ).then(({ result }) => {
            addLoading(loadingKey, COMPONENT);
            loadFunctionalLocations(getUniqueFunctionalLocations(result))
                .then(() => removeLoading(loadingKey, COMPONENT))
                .catch(() => removeLoading(loadingKey, COMPONENT));
        });
    }, [partnerNumber, year]);

    const monthlyCounts = getMonthlyCounts(plannedMaintenances.filtered);
    const statusCounts = utils.getStatusCounts(plannedMaintenances.selected);

    const options = utils.getFilterOptions(
        plannedMaintenances.selected, plannedMaintenances.all, activeFilter, functionalLocations, t
    );

    return (
        <React.Fragment>
            <PlainSection>
                <CalendarFilters
                    t={ t }
                    options={ options }
                    activeFilter={ activeFilter }
                    history={ history }
                    location={ location }
                    loading={ loading }
                    loadingFLs={ loadingFLs }
                />
            </PlainSection>
            <PlainSection>
                <CalendarMonthSelector
                    year={ year }
                    months={ months }
                    day={ day }
                    counts={ monthlyCounts }
                    loading={ loading }
                    disableMonths={ query.mode === ViewMode.CALENDAR }
                />
            </PlainSection>
            <PlainSection>
                <CalendarMeters
                    t={ t }
                    loading={ loading }
                    statusCounts={ statusCounts }
                    responseView={ false }
                    showPlanned
                />
            </PlainSection>
            <CalendarBody
                activeFilter={ activeFilter }
                functionalLocations={ functionalLocations }
                loading={ loading }
                loadingFLs={ loadingFLs }
                monthlyCounts={ monthlyCounts }
                orders={ plannedMaintenances }
                orderType={ OrderType.PLANNED }
                partnerNumber={ partnerNumber }
                year={ year }
                query={ query }
                setStatisticsView={ setStatisticsView }
                showServiceOrder={ utils.showServiceOrder(location, query, history) }
                listConfiguration={ listConfiguration }
                equipmentTexts={ equipmentTexts }
            />
        </React.Fragment>
    );
};

PlannedMaintenance.propTypes = {
    t: PropTypes.func.isRequired,
    year: PropTypes.number.isRequired,
    months: PropTypes.arrayOf(PropTypes.number).isRequired,
    day: PropTypes.number,
    match: PropTypes.shape({
        params: PropTypes.shape({
            partnerNumber: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
        search: PropTypes.string.isRequired,
    }).isRequired,
    loadPlannedMaintenances: PropTypes.func.isRequired,
    loadAllPlannedMaintenances: PropTypes.func.isRequired,
    plannedMaintenances: PropTypes.shape({
        all: PropTypes.arrayOf(PropTypes.object).isRequired,
        filtered: PropTypes.arrayOf(PropTypes.object).isRequired,
        selected: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    loadingFLs: PropTypes.bool.isRequired,
    activeFilter: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    functionalLocations: PropTypes.objectOf(PropTypes.object).isRequired,
    query: PropTypes.object.isRequired,
    loadingKey: PropTypes.string.isRequired,
    setStatisticsView: PropTypes.func.isRequired,
};

const EMPTY_OBJECT = {};

const mapStateToProps = (state, ownProps) => {
    const { location, match } = ownProps;
    const query = utils.parseQuery(location.search);
    const year = Number.parseInt(query.year, 10);
    const months = utils.getSelectedMonths(query);
    const day = query.day ? Number.parseInt(query.day, 10) : undefined;
    const activeFilter = utils.getActiveFilter(query);
    const { partnerNumber, functionalLocationId, equipmentNumber } = match.params;
    const loadingKey = utils.getLoadingKey(year, partnerNumber, functionalLocationId, equipmentNumber);
    const loading = !!state.plannedMaintenance.loading[loadingKey];
    const loadingFLs = loading || includes(state.common.loading[COMPONENT], loadingKey);
    const listConfiguration = state.profile.profile[CONFIGURATION_PROPERTY[OrderType.PLANNED]]
        || DEFAULT_COLUMNS[OrderType.PLANNED];
    return {
        year,
        months,
        day,
        plannedMaintenances: getPlannedMaintenanceListVariants(state, {
            year,
            months,
            day,
            match,
            activeFilter,
            loadingFLs,
        }),
        loading,
        loadingFLs,
        activeFilter,
        functionalLocations: state.functionalLocations.functionalLocations,
        query,
        loadingKey,
        listConfiguration,
        profile: state.profile.profile,
        equipmentTexts: EMPTY_OBJECT,
    };
};

const mapDispatchToProps = {
    loadPlannedMaintenances,
    loadAllPlannedMaintenances,
    loadFunctionalLocations,
    addLoading,
    removeLoading,
    setStatisticsView,
};

export default connect(mapStateToProps, mapDispatchToProps)(translations(PlannedMaintenance));
