import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled, { withTheme } from 'styled-components';
import StatusColumn from '../ColumnTypes/StatusColumn';

const Content = styled.section`
    text-align: left;
    line-height: ${props => props.theme.font.lineHeight.lg};
`;
Content.displayName = 'Content';

const Header = styled.div`
    padding-bottom: ${props => props.theme.spacing.md};
    margin-bottom: ${props => props.theme.spacing.md};
    border-bottom: ${props => `1px solid ${props.theme.colors.lightGray}`};
`;
Header.displayName = 'Header';

const Title = styled.h2`
    font-size: ${props => props.theme.font.size.lg};
    font-weight: ${props => props.theme.font.weight.bold};
`;
Title.displayName = 'Title';

const Description = styled.p`
    color: ${props => props.theme.colors.black};
    font-size: ${props => props.theme.font.size.xs};
    font-family: ${props => props.theme.font.family.arial};
    margin: ${props => props.theme.spacing.md} 0;
    white-space: pre-line;
`;
Description.displayName = 'Description';

const InspectionsModalContent = ({ data, t }) => {
    if (!data || !data.location) {
        return null;
    }

    const { location, status, date } = data;
    const { description, value } = location;
    const formattedDate = date && date.date && moment(date.date).format('L') || '';

    return <Content>
        <Header>
            <Title>{ value }&nbsp;{ formattedDate }</Title>
        </Header>
        <StatusColumn data={ status } t={ t } />
        { description && <Description>{ description }</Description> }
    </Content>;
};

InspectionsModalContent.propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.shape({
        location: PropTypes.object,
        status: PropTypes.object,
        date: PropTypes.object
    })
};

export default withTheme(InspectionsModalContent);
