import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import Helmet from 'react-helmet';
import translations from 'decorators/Translations/translations';
import { connect } from 'react-redux';
import { includes, find, map } from 'lodash';
import styled from 'styled-components';

import Hero from 'components/Hero/Hero';
import StandardPage from 'components/StandardPage/StandardPage';
import Header from 'containers/Application/Header/Header';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage';
import { getPartnerNumbers } from 'utils/profile';
import SectionHeader from 'components/Section/SectionHeader';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import BuildingTable from './BuildingTable/BuildingTable';
import DocumentModule from 'containers/Application/Modules/DocumentModule/DocumentModule';
import { loadPartnerFiles, loadPartnerFolders } from 'redux/modules/index';

const Container = styled.div`
    max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});
    width: 100%;
    margin: 0 auto;
`;

const PartnerDocumentModule = props => {
    const {
        customers,
        match: { params: { partnerNumber }, path, url },
        location,
        history,
        profile,
        t,
        features: { portfolioDocuments },
        loadPartnerFiles,
        loadPartnerFolders
    } = props;

    useEffect(() => {
        if (portfolioDocuments && partnerNumber && partnerNumber !== 'all') {
            loadPartnerFiles(partnerNumber);
            loadPartnerFolders(partnerNumber);
        }
    }, [portfolioDocuments, partnerNumber, loadPartnerFiles, loadPartnerFolders]);

    if (!portfolioDocuments) {
        return null;
    }

    if (!partnerNumber || partnerNumber !== 'all' && !includes(getPartnerNumbers(profile), partnerNumber)) {
        return <ErrorPage type="partner" />;
    }

    const tabOptions = [
        { value: 'Portfolio', label: t('Portfolio') },
        { value: 'Buildings', label: t('Buildings') }
    ];

    const customerName = customers[partnerNumber] && customers[partnerNumber].name;

    const selectedTab = find(
        map(tabOptions, 'value'),
        tabValue => location.pathname.endsWith(tabValue)
    );

    return (
        <StandardPage withTabs>
            <Helmet title={ t('Documents') } />
            <Header
                t={ t }
                selected="documents"
                showPartnerSelect
            />
            <Hero
                title={ t('Documents') }
                subTitle={ customerName }
                t={ t }
                type="DOCUMENTS"
            />
            <Container>
                <SectionHeader t={ t }>
                    <SectionTabSelector
                        left
                        large
                        t={ t }
                        options={ tabOptions }
                        model={{ selectedTab }}
                        property="selectedTab"
                        onTabChange={ (property, value) => history.push(`${url}/${value}`) }
                    />
                </SectionHeader>
                <Switch>
                    <Route path={ `${path}/Buildings` } component={ BuildingTable } />
                    <Route
                        exact
                        path={ `${path}/Portfolio` }
                        render={ () => <DocumentModule partnerNumber={ partnerNumber } /> }
                    />
                </Switch>
            </Container>
        </StandardPage>
    );
};

const mapStateToProps = state => ({
    profile: state.profile.profile,
    customers: state.customer.customers,
    features: state.profile.profile.features,
});

const mapDispatchToProps = {
    loadPartnerFiles,
    loadPartnerFolders,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(translations(PartnerDocumentModule));
