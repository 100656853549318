import { createReducerFromMapping } from 'redux/utils/index.js';
import {
    loadSensorHierarchies,
    loadSensorDataTypes,
    upsertSensorHierarchy
} from 'redux/modules/customer/sensorHierarchy.js';
import { loadAnnouncementsByFL } from 'redux/modules/announcement/announcement';
import { loadFunctionalLocationImages } from 'redux/modules/customer/partnerImage';
import { loadBuildingMeta, loadBuildingClassifications } from 'redux/modules/buildingConfig/buildingMeta';
import { loadAnalytics } from 'redux/modules/iot/analytics';
import { loadFunctionalLocations } from 'redux/modules';

const initialState = {
    // Make the default state loading so that the first load respects loading order via promise resolve
    loading: true,
    error: false
};

export const BUILDING_ADMIN_CONTAINER_LOAD = 'CUSTOMER_PLATFORM/BuildingAdminContainer/LOAD';
export const BUILDING_ADMIN_CONTAINER_LOAD_SUCCESS = 'CUSTOMER_PLATFORM/BuildingAdminContainer/LOAD_SUCCESS';
export const BUILDING_ADMIN_CONTAINER_LOAD_FAIL = 'CUSTOMER_PLATFORM/BuildingAdminContainer/LOAD_FAIL';

export const loadBuildingAdminContainer = functionalLocationId => {
    return async (dispatch, getState) => {
        dispatch({ type: BUILDING_ADMIN_CONTAINER_LOAD });
        try {
            // Load functional location
            const functionalLocationResult = await dispatch(loadFunctionalLocations([functionalLocationId]));
            const functionalLocation = functionalLocationResult.result[0];

            // Load sensor hierarchies, if there isn't any, upsert one
            const sensorHierarchiesResult = await dispatch(
                loadSensorHierarchies(functionalLocation.functionalLocation)
            );
            const building = sensorHierarchiesResult.result[0];

            if (!building) {
                const partnerNumber = getState().profile.activePartner;
                const newHierarchy = {
                    type: 'building',
                    functionalLocation: functionalLocation.functionalLocation,
                    partnerNumber: partnerNumber !== 'all' && partnerNumber
                };
                await dispatch(upsertSensorHierarchy(newHierarchy));
                await dispatch(loadSensorHierarchies(functionalLocation.functionalLocation));
            }

            await Promise.all([
                dispatch(loadFunctionalLocationImages(functionalLocation.functionalLocation)),
                dispatch(loadSensorDataTypes()),
                dispatch(loadAnnouncementsByFL(functionalLocation.functionalLocation)),
                dispatch(loadBuildingMeta([functionalLocation.functionalLocation])),
                dispatch(loadAnalytics()),
                dispatch(loadBuildingClassifications(functionalLocation)),
            ]);

            return dispatch({ type: BUILDING_ADMIN_CONTAINER_LOAD_SUCCESS });
        } catch (error) {
            return dispatch({
                type: BUILDING_ADMIN_CONTAINER_LOAD_FAIL,
                error
            });
        }
    };
};

export default createReducerFromMapping({
    [BUILDING_ADMIN_CONTAINER_LOAD]: state => ({
        ...state,
        loading: true
    }),
    [BUILDING_ADMIN_CONTAINER_LOAD_SUCCESS]: state => ({
        ...state,
        loading: false
    }),
    [BUILDING_ADMIN_CONTAINER_LOAD_FAIL]: (state, action) => ({
        ...state,
        loading: false,
        error: action.error
    })
}, initialState);
