import { filter, map, includes, first, orderBy, capitalize, find, forEach, values, uniqBy,
    meanBy, isEmpty, round, compact } from 'lodash';
import moment from 'moment';
import { mapThresholdToStatusValue, getPerformanceLimit, areaUtilizationStatus } from 'utils/Data/performance';

const hourAgo = moment.utc().subtract(1, 'hour');

export const FLOOR_OPI = {
    airQuality: 'Indoor Air Quality',
    temperature: 'Temperature',
    cleaning: 'Cleaning',
    humidity: 'Humidity',
    co2: 'CO2',
    pm10: 'PM10',
    tvoc: 'TVOC',
    pm25: 'PM2.5',
    radon: 'Radon',
    pressureDifference: 'PressureDifference',
    presenceArea: 'PresenceArea',
    presenceSeat: 'PresenceSeat',
    presenceZone: 'PresenceZone',
    areaUtilizations: 'Area Utilizations'
};

const mapDataForOPI = ({ opi, floorSensors, floorAreas, sensorValues, buildingMeta, latestValues }) => {
    switch (opi) {
    case FLOOR_OPI.airQuality:
        return getAirQualityData(floorSensors, latestValues);
    case FLOOR_OPI.temperature:
        return getTemperatureData(floorSensors, sensorValues, buildingMeta);
    case FLOOR_OPI.co2:
        return getBasicOpiData(floorSensors, sensorValues, 'carbondioxide', buildingMeta);
    case FLOOR_OPI.humidity:
        return getBasicOpiData(floorSensors, sensorValues, 'humidity', buildingMeta);
    case FLOOR_OPI.pm10:
        return getBasicOpiData(floorSensors, sensorValues, 'particle/PM10', buildingMeta);
    case FLOOR_OPI.pm25:
        return getBasicOpiData(floorSensors, sensorValues, 'particle/PM2.5', buildingMeta);
    case FLOOR_OPI.tvoc:
        return getBasicOpiData(floorSensors, sensorValues, 'organic_gas', buildingMeta);
    case FLOOR_OPI.radon:
        return getBasicOpiData(floorSensors, sensorValues, 'radon', buildingMeta);
    case FLOOR_OPI.pressureDifference:
        return getBasicOpiData(floorSensors, sensorValues, 'pressure', buildingMeta);
    case FLOOR_OPI.presenceArea:
        return getPresenceData(floorSensors, sensorValues, 'presence_area', buildingMeta);
    case FLOOR_OPI.presenceSeat:
        return getPresenceData(floorSensors, sensorValues, 'presence_seat', buildingMeta);
    case FLOOR_OPI.presenceZone:
        return getPresenceData(floorSensors, sensorValues, 'presence_zone', buildingMeta);
    case FLOOR_OPI.areaUtilizations:
        return compact(map(floorAreas, area => getAreaUtilizationData(area, latestValues)));
    default:
        return {};
    }
};

export const getOPIData = ({ floorSensors, floorAreas, sensorValues, buildingMeta, latestValues }) => {
    const data = {};
    forEach(
        values(FLOOR_OPI),
        opi => {
            data[opi] = mapDataForOPI({ opi, floorSensors, floorAreas, sensorValues, buildingMeta, latestValues });
        }
    );
    return data;
};

export const getAirQualityData = (floorSensors, latestValues) => {
    const airQualitySensor = floorSensors.find(
        sensor =>sensor.sensorType && sensor.sensorType.name === 'technical_performance'
    );

    if (airQualitySensor) {
        const latest = latestValues && latestValues[airQualitySensor.id];
        if (latest) {
            return {
                value: Math.round(latest.value),
                latestTime: latest.timestamp,
                sensors: [airQualitySensor],
            };
        }
    }

    return { sensors: [] };
};

export const getTemperatureData = (floorSensors, sensorValues, buildingMeta) => {
    const { sensors, latestValue, latestTime } = getFloorDataForSensorTypes(
        ['indoor temperature', 'temperature'],
        null,
        floorSensors,
        sensorValues
    );

    let temperature, latestTemperatureSensor, valueIsInvalid, statusValue;

    if (latestValue) {
        const timestamp = moment.utc(latestValue.timestamp);
        temperature = latestValue.value;
        latestTemperatureSensor = find(
            sensors,
            { id: parseInt(latestValue.sensorId, 10) }
        );
        valueIsInvalid = timestamp.isSameOrBefore(hourAgo);

        const getLimit = getPerformanceLimit(latestTemperatureSensor, latestTemperatureSensor.parent, buildingMeta);
        if (getLimit) {
            const [min, max] = getLimit(timestamp.toDate());
            statusValue = valueIsInvalid ? 10 : temperature >= min && temperature <= max ? 100 : 10;
        }
    }

    return {
        latestSensor: latestTemperatureSensor,
        latestTime,
        value: temperature && Math.round(temperature),
        valueIsInvalid,
        statusValue,
        sensors: sensors
    };
};

const isBusinessDay = timestamp => {
    const weekday = new Date(timestamp).getDay();
    return weekday >= 1 && weekday <= 5;
};

const isBetweenOpeningHours = (timestamp, startHour, endHour) => {
    const hour = new Date(timestamp).getHours();
    return hour >= startHour && hour < endHour;
};

export const getPresenceData = (floorSensors, sensorValues, type, buildingMeta) => {
    const utilizationHours = (find(buildingMeta, { key: 'utilization_calculation_hours' })
        || { value: '09:00-15:00' }).value;
    const [start, end] = map(utilizationHours.split('-'), time => moment(time, 'HH:mm').hour());

    const filterValue = valueObject => valueObject.aggregation === 'hourlyUtilizationRate'
        && isBusinessDay(valueObject.timestamp)
        && isBetweenOpeningHours(valueObject.timestamp, start, end);

    const data = getFloorDataForSensorTypes(
        [type],
        filterValue,
        floorSensors,
        sensorValues
    );

    const value = isEmpty(data.values) ? null : round(100 * meanBy(data.values, 'value'));

    return {
        ...data,
        value,
    };
};

export const getAreaUtilizationData = (area, latestValues) => {
    const countSensor = area.sensors
        && find(area.sensors, sensor => sensor.sensorType.name === 'area_count');

    if (countSensor) {
        const latestValue = latestValues[countSensor.id];
        const latestTime = latestValue && moment.utc(latestValue.timestamp).local().fromNow();
        const capacity = countSensor.sensorMeta && find(countSensor.sensorMeta, { metaKey: 'capacity' });
        const value = latestValue && capacity && Math.min(round(latestValue.value / capacity.value * 100), 100);

        return {
            latestTime,
            value,
            sensors: [countSensor],
            title: area.name,
            status: areaUtilizationStatus(value)
        };
    }
};

export const getBasicOpiData = (floorSensors, sensorValues, type, buildingMeta) => {
    const data = getFloorDataForSensorTypes(
        [type],
        null,
        floorSensors,
        sensorValues
    );

    let value, statusValue, valueIsInvalid;

    if (data.latestValue) {
        const valueTime = moment.utc(data.latestValue.timestamp);
        value = data.latestValue && data.latestValue.value;
        valueIsInvalid = valueTime.isSameOrBefore(hourAgo);

        const latestSensorId = Number.parseInt(data.latestValue.sensorId, 10);
        const latestSensor = data.sensors.find(sensor => sensor.id === latestSensorId);
        if (latestSensor) {
            const getLimit = getPerformanceLimit(latestSensor, latestSensor.parent, buildingMeta);
            if (getLimit) {
                const limit = getLimit(valueTime.toDate());
                statusValue = mapThresholdToStatusValue(value, limit);
            }
        }
    }

    return { ...data, value, statusValue, valueIsInvalid };
};

export const getFloorDataForSensorTypes = (types, filterValue, floorSensors, sensorValues) => {
    const sensors = uniqBy(floorSensors.reduce((acc, sensor) => {
        if (sensor && sensor.sensorType) {
            if (!isEmpty(sensor.children)) {
                return [
                    ...acc,
                    ...sensor.children.filter(child => child.sensorType && includes(types, child.sensorType.name))
                ];
            }

            if (includes(types, sensor.sensorType.name)) {
                return [...acc, sensor];
            }
        }

        return acc;
    }, []), 'id');

    const sensorIds = map(sensors, 'id');
    const values = filter(
        sensorValues,
        valueObject => includes(sensorIds, parseInt(valueObject.sensorId, 10))
            && (!filterValue || filterValue(valueObject))
    );
    const latestValue = first(orderBy(values, 'timestamp', 'desc'));
    const latestTime = latestValue && capitalize(moment.utc(latestValue.timestamp).local().fromNow());

    return { sensors, sensorIds, values, latestValue, latestTime };
};
