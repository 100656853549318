import { createReducerFromMapping } from 'redux/utils/index.js';

import { loadPermissions } from 'redux/modules/profile/profile.js';
import { loadFunctionalLocationsImages } from 'redux/modules/customer/partnerImage';
import { loadPartnerImages } from 'redux/modules/customer/partnerImage';
import { loadPartnerMeta } from 'redux/modules/customer/partnerMeta';

const initialState = {
    loading: true,
    error: false
};

export const PARTNER_OVERVIEW_LOAD = 'CUSTOMER_PLATFORM/PartnerOverview/LOAD';
export const PARTNER_OVERVIEW_LOAD_SUCCESS = 'CUSTOMER_PLATFORM/PartnerOverview/LOAD_SUCCESS';
export const PARTNER_OVERVIEW_LOAD_FAIL = 'CUSTOMER_PLATFORM/PartnerOverview/LOAD_FAIL';

export const loadPartnerOverview = (partnerNumber, skipPartnerImage = false) => {
    return async dispatch => {
        dispatch({ type: PARTNER_OVERVIEW_LOAD });
        try {
            if (partnerNumber && partnerNumber !== 'all') {
                !skipPartnerImage && dispatch(loadPartnerImages(partnerNumber));
                dispatch(loadPartnerMeta(partnerNumber));
            }

            const result = await dispatch(loadPermissions());
            const permissions = result.result;
            permissions && dispatch(loadFunctionalLocationsImages(permissions.map(x => x.functionalLocation)));

            // Don't wait for the images to load
            return dispatch({ type: PARTNER_OVERVIEW_LOAD_SUCCESS });
        } catch (error) {
            return dispatch({
                type: PARTNER_OVERVIEW_LOAD_FAIL,
                error
            });
        }
    };
};

export default createReducerFromMapping({
    [PARTNER_OVERVIEW_LOAD]: state => ({
        ...state,
        loading: true
    }),
    [PARTNER_OVERVIEW_LOAD_SUCCESS]: state => ({
        ...state,
        loading: false
    }),
    [PARTNER_OVERVIEW_LOAD_FAIL]: (state, action) => ({
        ...state,
        loading: false,
        error: action.error
    })
}, initialState);
