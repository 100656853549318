import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Name = styled.div`
    word-break: break-word;
`;
Name.displayName = 'Name';

const NameColumn = ({ name, t }) => <Name>{ t(name.replace(/_/g, ' ')) }</Name>;


NameColumn.defaultProps = {
    name: ''
};

NameColumn.propTypes = {
    t: PropTypes.func.isRequired,
    name: PropTypes.string,
};

export default NameColumn;
