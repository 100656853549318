import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import translations from 'decorators/Translations/translations';
import { connect } from 'react-redux';
import { includes, find, isEmpty } from 'lodash';

import Hero from 'components/Hero/Hero';
import StandardPage from 'components/StandardPage/StandardPage';
import Header from 'containers/Application/Header/Header';
import styled from 'styled-components';
import PowerBIReport from 'containers/Application/PowerBI/PowerBIReport';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage';
import { getPartnerNumbers } from 'utils/profile';
import { myCustomerCustomViews } from 'redux/modules/customView/customView';
import { VIEW_TYPES } from 'containers/Application/Admin/CustomViews/utils';
import { NoData } from './CustomViewModule';
import FreeLayoutView from 'components/FreeLayoutView/FreeLayoutView';
import EmbeddedPageView from 'components/EmbeddedPageView/EmbeddedPageView';
import EmbeddedDocumentView from 'components/EmbeddedDocumentView/EmbeddedDocumentView';

const Divider = styled.div`
    height: ${props => props.theme.spacing.xl};
    width: 100%;
`;
Divider.displayName = 'Divider';

export class CustomViewPartnerModule extends React.Component {
    componentDidMount() {
        const { match: { params: { partnerNumber } }, customViewsByCustomer } = this.props;

        if (!customViewsByCustomer[partnerNumber]) {
            this.props.myCustomerCustomViews(partnerNumber);
        }
    }

    render() {
        const {
            t,
            match: { params: { partnerNumber, customViewId } },
            profile,
            customViewsByCustomer,
            loadingCustomViews,
        } = this.props;

        if (!partnerNumber || partnerNumber !== 'all' && !includes(getPartnerNumbers(profile), partnerNumber)) {
            return <ErrorPage type="partner" />;
        }

        const customViews = customViewsByCustomer[partnerNumber] || [];
        const customView = find(customViews, { id: customViewId }) || {};
        const NoDataRender = <NoData>{ t('No data available') }</NoData>;

        if (!loadingCustomViews && isEmpty(customView)) {
            return <ErrorPage type="customView" />;
        }

        const showHero = !includes([VIEW_TYPES.embedded, VIEW_TYPES.embeddedDocument], customView.viewType);

        return (
            <StandardPage withTabs>
                <Helmet title={ customView.viewName } />
                <Header
                    t={ t }
                    selected={ customViewId }
                    showPartnerSelect
                />
                { showHero && <Fragment>
                    <Hero
                        title={ customView.viewName }
                        subTitle={ customView.viewDesc }
                        t={ t }
                        type="CUSTOM_VIEW"
                    />
                    <Divider />
                </Fragment> }
                { !isEmpty(customView) && customView.viewType === VIEW_TYPES.powerBi &&
                    <PowerBIReport
                        customView={ customView }
                        isPorfolioLevel
                        noDataRender={ NoDataRender }
                    />
                }
                { !isEmpty(customView) && customView.viewType === VIEW_TYPES.freeLayout &&
                    <FreeLayoutView
                        customView={ customView }
                        noDataRender={ NoDataRender }
                    />
                }
                { !isEmpty(customView) && customView.viewType === VIEW_TYPES.embedded &&
                    <EmbeddedPageView
                        customView={ customView }
                        noDataRender={ NoDataRender }
                    />
                }
                { !isEmpty(customView) && customView.viewType === VIEW_TYPES.embeddedDocument &&
                    <EmbeddedDocumentView
                        customView={ customView }
                        noDataRender={ NoDataRender }
                        t={ t }
                    />
                }
            </StandardPage>
        );
    }
}

const mapStateToProps = state => ({
    customViewsByCustomer: state.customView.customViewsByCustomer,
    loadingCustomViews: state.customView.loading,
    profile: state.profile.profile,
});

const mapDispatchToProps = dispatch => ({
    myCustomerCustomViews: partnerNumber => dispatch(myCustomerCustomViews(partnerNumber))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(CustomViewPartnerModule));
