import React, { Fragment, useState, useCallback } from 'react';
import { get, isEmpty } from 'lodash';
import translations from 'decorators/Translations/translations';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { transparentize } from 'polished';

import InfoBox from 'components/InfoBox/InfoBox';
import FileUpload from 'components/FileUpload/FileUpload';
import InputLabel from 'components/Form/InputLabel';
import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';
import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import { getHumanreadableSize } from 'utils/Data/documents';
import { detailedTime } from 'utils/Date/dateFormatter';
import {
    csvColumns,
    validateParsedCsvValues,
    VALID_MIME_TYPES,
    readFileAsCsv,
    convertParsedCsvToSensorValueObjects
} from './utils';
import SensorSelector from './SensorSelector';
import AggregationSelector from './AggregationSelector';

const ImportForm = styled.form`
    margin-top: 1em;
`;
ImportForm.displayName = 'ImportForm';

const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 0.5em;
    > * {
        margin-right: 0.5em;
    }
    &:last-child {
        margin-right: auto;
    }
`;
Buttons.displayName = 'Buttons';

const FileInfoContainer = styled.div`
    display: flex;
    padding-top: 1em;
    padding-bottom: 1em;
`;
FileInfoContainer.displayName = 'FileInfoContainer';

const FileName = styled.div`
    font-size: ${props => props.theme.font.size.sm};
    font-weight: ${props => props.theme.font.weight.bold};
    color: ${props => props.theme.colors.black};
    word-break: break-all;
`;
FileName.displayName = 'FileName';

const FileInfo = styled.div`
    margin-left: 0.5em;
    font-size: ${props => props.theme.font.size.xs};
`;
FileInfo.displayName = 'FileInfo';

const FilePreviewContainer = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
`;
FilePreviewContainer.displayName = 'FilePreviewContainer';

const FilePreviewTableContainer = styled.div`
    flex: 1;
    font-size: ${props => props.theme.font.size.xxs};
    font-family: ${props => props.theme.input.font.family};
    line-height: ${props => props.theme.input.lineHeight.default};
    color: ${props => props.theme.colors.darkGray};
    background-color: ${props => props.theme.colors.alabaster};
    border: 1px solid ${props => props.theme.colors.lightGray};
    height: 100%;
    width: 100%;
    padding: 0.5em;
    box-shadow: none;
    overflow: auto;
    max-height: 260px;
`;
FilePreviewTableContainer.displayName = 'FilePreviewTableContainer';

const FilePreviewTable = styled.table`
    width: 100%;
    font-size: inherit;
    &, th, td {
        font-weight: normal;
        white-space: nowrap;
    }
    th, td {
        padding: 0 0.5em;
    }
`;
FilePreviewTable.displayName = 'FilePreviewTable';

const FilePreviewRow = styled.tr`
    background-color: ${props => props.invalid ? transparentize(0.9, props.theme.colors.radicalRed) : 'transparent'};
`;
FilePreviewRow.displayName = 'FilePreviewRow';

const FilePreviewCell = styled.td`
    background-color: ${props => props.invalid ? transparentize(0.8, props.theme.colors.radicalRed) : 'transparent'};
    color: ${props => props.invalid ? props.theme.colors.radicalRed : 'inherit'};
    text-align: ${props => props.align || 'left'};
    font-size: inherit;
`;
FilePreviewCell.displayName = 'FilePreviewCell';

const FilePreviewHeadingCell = styled.th`
    text-align: ${props => props.align || 'left'};
    text-transform: uppercase;
    font-size: ${props => props.theme.font.size.xxxs};
`;
FilePreviewHeadingCell.displayName = 'FilePreviewHeadingCell';

const UndefinedValue = styled.span`
    &::before {
        content: "n/a";
        text-transform: uppercase;
        font-size: ${props => props.theme.font.size.xxxs};
        color: ${props => props.invalid ? transparentize(0.5, props.theme.colors.radicalRed) : 'inherit'};
    }
`;

export const CsvFileImport = props => {
    const {
        t,
        sensors,
        sensorTypes,
        onSubmitValues,
        importingSensorValues
    } = props;
    const [file, setFile] = useState();
    const [sensorId, setSensorId] = React.useState(null);
    const [aggregation, setAggregation] = React.useState('raw');
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const [error, setError] = useState();
    const [loadingFile, setLoadingFile] = useState(false);

    const onFileUpload = async (acceptedUploadFiles = [], rejectedUploadFiles = []) => {
        setRejectedFiles(rejectedUploadFiles);
        if (acceptedUploadFiles.length) {
            setLoadingFile(true);
            try {
                const parsedFile = await readFileAsCsv(acceptedUploadFiles[0], t);
                setFile(parsedFile);
            } catch(error) {
                setError(error.message)
            }
            setLoadingFile(false);
        }
    };

    const onClearFile = () => {
        setFile();
        setSensorId(null);
        setAggregation('raw');
        setRejectedFiles([]);
        setError();
    };

    const validationErrors = React.useMemo(() => {
        if (file && file.data) {
            return validateParsedCsvValues(file.data, { sensorId, aggregation });
        }
        return null;
    }, [file, sensorId, aggregation]);

    const valid = file && !isEmpty(file.data) && !validationErrors;

    const onSubmit = useCallback(async event => {
        event.preventDefault();
        await onSubmitValues(
            convertParsedCsvToSensorValueObjects(file.data, { sensorId, aggregation })
        );
        onClearFile();
    }, [file, sensorId, aggregation, onSubmitValues]);

    return (
        <Fragment>
            <FileUpload
                upload={ onFileUpload }
                uploading={loadingFile}
                t={ t }
                header={ t('Click and select file or drag and drop') }
                noIcon
                allowedMimeTypes={['csv']}
                accept={VALID_MIME_TYPES}
                infoText={t('You can only select one file at a time')}
            />
            {error &&
                <InfoBox error>{error}</InfoBox>
            }
            {rejectedFiles.length > 0 &&
                <InfoBox error>
                    {t('Following files were rejected')}:
                    <ul>
                        {rejectedFiles.map(file =>
                            <li key={file.name}>{file.name} ({file.type})</li>
                        )}
                    </ul>
                </InfoBox>
            }
            {file &&
                <ImportForm onSubmit={onSubmit}>
                    <Columns>
                        <Column columnWidth={{ desktop: 6, landscape: 6, portrait: 12, default: 12 }}>
                            <FileInfoContainer>
                                <Icon
                                    name="documents"
                                    size="SMALL_PLUS"
                                    iconType={valid ? 'MODAL' : 'MODAL_WARNING'}
                                />
                                <FileInfo>
                                    <FileName>{file.name}</FileName>
                                    {detailedTime(file.lastModified)}<br />
                                    {getHumanreadableSize(file.size)}<br />
                                    {file.type}<br />
                                </FileInfo>
                            </FileInfoContainer>
                            <SensorSelector
                                sensorId={sensorId}
                                setSensorId={setSensorId}
                                setAggregation={setAggregation}
                                t={t}
                                sensors={sensors}
                                placeholder={t('From CSV file')}
                            />
                            <AggregationSelector
                                sensorId={sensorId}
                                aggregation={aggregation}
                                setAggregation={setAggregation}
                                t={t}
                                sensors={sensors}
                                sensorTypes={sensorTypes}
                                placeholder={t('From CSV file')}
                            />
                            {!valid &&
                                <InfoBox error>
                                    {t('File contains incomplete or invalid data.')}{' '}
                                    {sensorId
                                        ? t('Please fix the contents of your file or upload another file.')
                                        : t('You can either select missing sensor with the sensor field above or upload another file.') // eslint-disable-line max-len
                                    }
                                </InfoBox>
                            }
                            <Buttons>
                                <Button
                                    submit
                                    disabled={!valid || importingSensorValues}
                                    loading={importingSensorValues}
                                >
                                    {t('Upload file')}
                                </Button>
                                <Button
                                    cancel
                                    onClick={onClearFile}
                                    disabled={importingSensorValues}
                                >
                                    {t('Cancel')}
                                </Button>
                            </Buttons>
                        </Column>
                        <Column columnWidth={{ desktop: 6, landscape: 6, portrait: 12, default: 12 }}>
                            <FilePreviewContainer>
                                <InputLabel text={`${t('File preview')}`} />
                                <FilePreviewTableContainer>
                                    <FilePreviewTable>
                                        <thead>
                                            <tr>
                                                {csvColumns.map(column =>
                                                    <FilePreviewHeadingCell key={column.name} align={column.align}>
                                                        {t(column.title)}
                                                    </FilePreviewHeadingCell>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {file.data.map((row, rowIndex) =>
                                                <FilePreviewRow
                                                    key={rowIndex}
                                                    invalid={validationErrors && !isEmpty(validationErrors[rowIndex])}
                                                >
                                                    {csvColumns.map(column => {
                                                        const invalid = get(validationErrors, [rowIndex, column.name]);
                                                        return (
                                                            <FilePreviewCell
                                                                key={column.name}
                                                                invalid={invalid}
                                                                align={column.align}
                                                            >
                                                                {column.format(row[column.name], { sensorId, aggregation }) ?? <UndefinedValue invalid />}
                                                            </FilePreviewCell>
                                                        );
                                                    })}
                                                </FilePreviewRow>
                                            )}
                                        </tbody>
                                    </FilePreviewTable>
                                </FilePreviewTableContainer>
                            </FilePreviewContainer>
                        </Column>
                    </Columns>
                </ImportForm>
            }
        </Fragment>
    );
};

CsvFileImport.propTypes = {
    t: PropTypes.func.isRequired,
    sensors: PropTypes.arrayOf(PropTypes.object).isRequired,
    sensorTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSubmitValues: PropTypes.func.isRequired,
    importingSensorValues: PropTypes.bool
};

export default withTheme(translations(CsvFileImport));
