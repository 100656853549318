import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage';
import Header from 'containers/Application/Header/Header';
import Hero from 'components/Hero/Hero';
import StandardPage from 'components/StandardPage/StandardPage';
import translations from 'decorators/Translations/translations';
import { connect } from 'react-redux';
import { includes } from 'lodash';
import { getPartnerNumbers } from 'utils/profile';

export const Recycling = ({
    t,
    match: {
        params: { partnerNumber }
    },
    profile,
    customers
}) => {
    if (!partnerNumber || partnerNumber === 'all') {
        return <ErrorPage type='selectPartner' />;
    }
    if (!includes(getPartnerNumbers(profile), partnerNumber)) {
        return <ErrorPage type='partner' />;
    }
    const pageTitle = t('Recycling');
    const customerName = customers[partnerNumber] && customers[partnerNumber].name;
    return (
        <StandardPage withTabs>
            <Helmet title={pageTitle} />
            <Header t={t} selected={pageTitle.toLowerCase()} showPartnerSelect />
            <Hero title={pageTitle} subTitle={customerName} t={t} type='RECYCLING' />
            {/* TODO: implement content */}
        </StandardPage>
    );
};

Recycling.propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    customers: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    profile: state.profile.profile,
    customers: state.customer.customers
});

export default withRouter(connect(mapStateToProps)(translations(Recycling)));
