import Icon, { ICON_TYPES } from '../Icon/Icon';
import React from 'react';
import { icons } from '../Icon/IconNames';
import AnnouncementModalContent from 'components/Announcement/AnnouncementModalContent';
import InspectionsModalContent from 'components/BuildingEvents/Inspections/InspectionsModalContent';
import ObservationsModalContent from 'components/BuildingEvents/Observations/ObservationsModalContent';
import ModalWarning from './ModalWarning';

export const MODALTYPE = {
    CONFIRMATION_DELETE_FLOOR_MODAL: 'CONFIRMATION_DELETE_FLOOR',
    CONFIRMATION_DELETE_AREA_MODAL: 'CONFIRMATION_DELETE_AREA',
    CONFIRMATION_DELETE_GROUP_MODAL: 'CONFIRMATION_DELETE_GROUP',
    CONFIRMATION_DELETE_SENSOR_MODAL: 'CONFIRMATION_DELETE_SENSOR',
    CONFIRMATION_DELETE_SENSOR_LOCATION_MODAL: 'CONFIRMATION_DELETE_SENSOR_LOCATION',
    CONFIRMATION_DELETE_BUILDING_IMAGE_MODAL: 'CONFIRMATION_DELETE_BUILDING_IMAGE',
    CONFIRMATION_DELETE_PARTNER_IMAGE_MODAL: 'CONFIRMATION_DELETE_PARTNER_IMAGE',
    INFO_MODAL: 'INFO',
    CONFIRMATION_DEMO_MODAL: 'CONFIRMATION_DEMO',
    ANNOUNCEMENT_MODAL: 'ANNOUNCEMENT',
    CONFIRMATION_DELETE_ANNOUNCEMENT_MODAL: 'CONFIRMATION_DELETE_ANNOUNCEMENT',
    CONFIRMATION_DELETE_BUILDING_META: 'CONFIRMATION_DELETE_BUILDING_META',
    CONFIRMATION_DELETE_ANALYTICS_MODAL: 'CONFIRMATION_DELETE_ANALYTICS',
    CONFIRMATION_DELETE_USER: 'CONFIRMATION_DELETE_USER',
    CONFIRMATION_DELETE_PARTNER_META: 'CONFIRMATION_DELETE_PARTNER_META',
    INSPECTIONS_CONTENT: 'INSPECTIONS_CONTENT',
    OBSERVATIONS_TIMELINE: 'OBSERVATIONS_TIMELINE',
    CONFIRMATION_DELETE_CUSTOM_VIEW: 'CONFIRMATION_DELETE_CUSTOM_VIEW',
    CONFIRMATION_DELETE_SENSOR_ALARM: 'CONFIRMATION_DELETE_SENSOR_ALARM',
    CONFIRMATION_DELETE_DOCUMENT: 'CONFIRMATION_DELETE_DOCUMENT',
    CONFIRMATION_DELETE_FOLDER: 'CONFIRMATION_DELETE_FOLDER',
    CONFIRMATION_DELETE_API_KEY: 'CONFIRMATION_DELETE_API_KEY'
};

export const SUBMIT_BUTTON_TYPE = {
    WARNING: 'WARNING',
    CONFIRM: 'CONFIRM'
};

const defaultConfig = {
    getIcon: () => null,
    getHeader: t => 't(HeaderText)',
    getContent: props => null,
    buttons: {
        cancel: {
            text: 'Close'
        }
    },
    width: 600,
};

const defaultRemoveConfig = {
    getIcon: () => null,
    getHeader: t => t('Are you sure you want to remove this?'),
    getContent: warning => <ModalWarning>{ warning }</ModalWarning>,
    buttons: {
        submit: {
            text: 'Remove',
            type: SUBMIT_BUTTON_TYPE.WARNING
        },
        cancel: {
            text: 'Cancel'
        }
    },
    width: 500,
};

const defaultDeleteConfig = {
    ...defaultRemoveConfig,
    getHeader: t => t('Are you sure you want to delete this?'),
    buttons: {
        submit: {
            text: 'Delete',
            type: SUBMIT_BUTTON_TYPE.WARNING
        },
        cancel: {
            text: 'Cancel'
        }
    },
};

export default {
    CONFIRMATION_DELETE_FLOOR: Object.assign({}, defaultRemoveConfig),
    CONFIRMATION_DELETE_AREA: Object.assign({}, defaultRemoveConfig),
    CONFIRMATION_DELETE_GROUP: Object.assign({}, defaultRemoveConfig),
    CONFIRMATION_DELETE_SENSOR: Object.assign({}, defaultRemoveConfig),
    CONFIRMATION_DELETE_SENSOR_LOCATION: Object.assign({}, defaultRemoveConfig),
    CONFIRMATION_DELETE_BUILDING_IMAGE: Object.assign({}, defaultDeleteConfig),
    CONFIRMATION_DELETE_PARTNER_IMAGE: Object.assign({}, defaultDeleteConfig),
    INFO: Object.assign({}, defaultConfig),
    CONFIRMATION_DEMO: Object.assign({}, defaultConfig, {
        getIcon: () => <Icon iconType={ICON_TYPES.MODAL} name={icons.OK} />,
        getHeader: t => t('Demo Confirmation'),
        getContent: props => <p>{JSON.stringify(props)}</p>,
        buttons: {
            submit: {
                text: 'Save',
                type: SUBMIT_BUTTON_TYPE.CONFIRM
            },
            cancel: {
                text: 'Close'
            }
        }
    }),
    ANNOUNCEMENT: Object.assign({}, defaultConfig, {
        getHeader: () => null,
        getContent: props => <AnnouncementModalContent { ...props } />,
    }),
    CONFIRMATION_DELETE_ANNOUNCEMENT: Object.assign({}, defaultDeleteConfig),
    CONFIRMATION_DELETE_BUILDING_META: Object.assign({}, defaultDeleteConfig),
    CONFIRMATION_DELETE_PARTNER_META: Object.assign({}, defaultDeleteConfig),
    CONFIRMATION_DELETE_ANALYTICS: Object.assign({}, defaultDeleteConfig),
    CONFIRMATION_DELETE_USER: { ...defaultDeleteConfig },
    INSPECTIONS_CONTENT: Object.assign({}, defaultConfig, {
        getHeader: () => null,
        getContent: props => <InspectionsModalContent { ...props } />,
    }),
    OBSERVATIONS_TIMELINE: Object.assign({}, defaultConfig, {
        getHeader: () => null,
        getContent: props => <ObservationsModalContent { ...props } />,
    }),
    CONFIRMATION_DELETE_CUSTOM_VIEW: { ...defaultRemoveConfig },
    CONFIRMATION_DELETE_SENSOR_ALARM: { ...defaultRemoveConfig },
    CONFIRMATION_DELETE_DOCUMENT: { ...defaultDeleteConfig },
    CONFIRMATION_DELETE_FOLDER: { ...defaultDeleteConfig },
    CONFIRMATION_DELETE_API_KEY: { ...defaultDeleteConfig },
};
