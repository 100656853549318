import { OrderStatus } from 'constants/serviceCalendar';

export const systemStatus = {
    OPENED: 'I0001',
    RELEASED: 'I0002',
    TECHICALLY_COMPLETED: 'I0009',
    PARTLY_CONFIRMED: 'I0010',
    PRECALCULATED: 'I0016',
    // 'I0028',
    ADMINISTRATION_DONE: 'I0045',
    CLOSED: 'I0046',
    MANUALLY_REPORTED: 'I0052',
    LAST_INVOICE_SENT: 'I0097'
    // 'I0321',
    // 'I0414',
    // 'I0420',
    // 'I0485'
};

export const userStatus = {
    WORK_STARTED: 'E0003',
    WAITING_FOR_MATERIALS: 'E0004',
    IN_WORK: 'E0016',
    COMMENTED: 'E0017',
    FAST_TRACK_INVOICING: 'E0018',
    TECHNICALLY_COMPLETED: 'E0019',
    TEMPORARILY_FIXED: 'E0022'
};

export const serviceStatusSortOrder = [
    OrderStatus.PLANNED,
    OrderStatus.OPEN,
    OrderStatus.IN_PROGRESS,
    OrderStatus.PARTLY_COMPLETED,
    OrderStatus.COMPLETED,
    OrderStatus.DELAYED
];

export const serviceOperationSortOrder = [
    OrderStatus.RELEASED,
    OrderStatus.ARRIVED,
    OrderStatus.STARTED,
    OrderStatus.COMPLETED,
];
export const serviceStatusSorter = status => {
    return serviceStatusSortOrder.indexOf(status);
};

export const containsStatus = (statuses, status) => {
    return statuses && status && statuses.indexOf(status) > -1;
};

function serviceOrderStatusChecker(statuses) {
    return function checkStatus(status) {
        return containsStatus(statuses, status);
    };
}

export default serviceOrder => {
    const checkStatus = serviceOrderStatusChecker(serviceOrder.statuses);
    if (checkStatus(userStatus.TECHNICALLY_COMPLETED) || checkStatus(systemStatus.TECHICALLY_COMPLETED)) {
        return OrderStatus.COMPLETED;
    }
    if (checkStatus(userStatus.TEMPORARILY_FIXED)) {
        return OrderStatus.PARTLY_COMPLETED;
    }
    if (checkStatus(userStatus.WAITING_FOR_MATERIALS)) {
        return OrderStatus.DELAYED;
    }
    if (checkStatus(userStatus.WORK_STARTED) || checkStatus(userStatus.IN_WORK)) {
        return OrderStatus.IN_PROGRESS;
    }
    return OrderStatus.OPEN;
};
