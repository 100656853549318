import React from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import Select from 'react-select';

import 'react-select/dist/react-select.css';
import styled, { css } from 'styled-components';
import SkeletonPill from 'components/Skeletons/SkeletonPill';

const Block = styled.div`
    display: flex;
    ${props => props.labelPosition === 'left' && css`
        flex-direction: row;
        justify-content: center;
        align-items: center;
    `}
     ${props => props.labelPosition === 'top' && css`
        margin-right: ${props => props.noSpacing ? 0 : props.theme.spacing.md};
        padding-bottom: ${props => props.noSpacing ? 0 : props.theme.spacing.xs};
        flex-direction: column;
    `}
`;

const Content = styled.div`
    min-width: 14em;
`;

const Label = styled.label`
    flex-grow: 0;
    line-height: normal;
    display: block;
    margin: ${props => props.position === 'left' ? '0 1em 0 0' : '0.5em 0 1em 0'};
    color: ${props => props.theme.colors.darkGray};
    font-size: ${props => props.theme.font.size.xxxs};
    font-family: ${props => props.theme.font.family.arial};
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
`;

const StyledSelect = styled(Select)`
    &&& {
        &.is-open > .Select-control .Select-arrow {
            border-bottom-color: ${props => props.theme.input.font.color.default};
        }
        &.is-disabled > .Select-control {
            background: transparent;
            box-shadow: unset;
            &:hover, &:focus {
                background: transparent;
                box-shadow: unset;
            }
        }
        &.is-disabled .Select-value {
            opacity: 0.35;
        }

        &&& {
            .Select-input, .Select-placeholder, .Select-value-label {
                font-size: ${props => props.theme.input.font.mobileSize};
                font-family: ${props => props.theme.input.font.family};
                line-height: ${props => props.theme.input.lineHeight.default};

                ${props => props.theme.media.landscape`
                    font-size: ${props => props.theme.input.font.size};
                `}
            }
            .Select-value-label {
                color: ${props => props.theme.input.font.color.default};
            }
            .Select-placeholder {
                color: ${props => props.theme.input.font.color.placeholder};
                padding: ${props => props.theme.input.padding};
            }
            .Select-input {
                width: auto;
                height: auto;
                padding: 0;
                & > input {
                    height: auto;
                    padding: 0;
                    line-height: ${props => props.theme.input.lineHeight.default};
                }
            }
            .Select-arrow-zone {
                padding-right: 0;
                text-align: right;
                height: 1rem;
            }
            .Select-arrow {
                border-top-color: ${props => props.theme.input.font.color.default};
            }
        }
        .Select-control {
            width: 100%;
            height: auto;
            background-color: ${props => props.theme.input.background.default};
            padding: ${props => props.theme.input.padding};
            border: ${props => props.theme.input.border.static};
            border-radius: 0;
            box-shadow: ${props => props.theme.input.shadow.static};
            transition: ${props => props.theme.input.transition};
            &:hover, &:focus {
                box-shadow: ${props => props.theme.input.shadow.active};
                border: ${props => props.theme.input.border.active};
            }
            .Select-value {
                width: 100%;
                height: auto;
                padding: ${props => props.theme.input.padding};
                line-height: ${props => props.theme.input.lineHeight.default};
            }
        }
        .Select-menu-outer {
            z-index: 2;
            border-radius: 0;
            box-shadow: ${props => props.theme.input.shadow.active};
            border: ${props => props.theme.input.border.active};
            .Select-menu {
                .Select-option {
                    padding: ${props => props.theme.input.padding};
                    font-size: ${props => props.theme.input.font.mobileSize};
                    font-family: ${props => props.theme.input.font.family};
                    font-weight: ${props => props.theme.font.weight.normal};
                    line-height: ${props => props.theme.input.lineHeight.default};
                    color: ${props => props.theme.input.font.color.default};
                    font-style: ${props => props.disabled ? 'italic' : ''};
                    &:last-child {
                        border-radius: none;
                    }
                    &.is-selected {
                        font-weight: ${props => props.theme.font.weight.bold};
                        color: ${props => props.theme.input.font.color.default};
                    }

                    ${props => props.theme.media.landscape`
                        font-size: ${props => props.theme.input.font.size};
                    `}
                }
            }
        }
    }
`;

const SkeletonContainer = styled.div`
    height: 50px;
    display: flex;
    align-items: center;
`;

// Get the first line of text.
const renderText = text => {
    return text && text.split('\n')[0];
};

export const noFilter = 'NO_FILTER';

const FilterDropdown = ({
    options,
    handleChange,
    label,
    labelPosition,
    selectedFilter,
    sort,
    loading,
    disabled,
    showAllText,
    noFilterLast,
    className,
    noSpacing,
}) => {

    // Sort the options based on the sort
    const sortedOptions = sortBy(options.slice(), sort);
    if (noFilterLast) {
        sortedOptions.push({ value: noFilter, text: showAllText });
    } else {
        sortedOptions.unshift({ value: noFilter, text: showAllText });
    }

    const selectOptions = sortedOptions.map(option => ({
        label: renderText(option.text),
        value: option.value
    }));

    return (
        <Block labelPosition={ labelPosition } className={ className } noSpacing={ noSpacing }>
            { label && <Label id={ 'Filter' + label } position={ labelPosition }>{ label }</Label> }
            <Content>
                {loading ? <SkeletonContainer><SkeletonPill margin="0px" /></SkeletonContainer> :
                <StyledSelect
                    name="select-dropdown"
                    value={ selectedFilter }
                    onChange={ handleChange }
                    options={ selectOptions }
                    clearable={ false }
                    searchable={ false }
                    isLoading={ loading }
                    simpleValue
                    disabled={ disabled }
                /> }
            </Content>
        </Block>
    );
};
FilterDropdown.displayName = 'FilterDropdown';

FilterDropdown.defaultProps = {
    label: null,
    labelPosition: 'top',
    selectedFilter: noFilter,
    sort: null,
    loading: false,
    disabled: false,
    showAllText: 'Show all',
    noFilterLast: false,
    className: '',
    noSpacing: false,
};

FilterDropdown.propTypes = {
    options: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    labelPosition: PropTypes.string,
    selectedFilter: PropTypes.any,
    sort: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    showAllText: PropTypes.string,
    noFilterLast: PropTypes.bool,
    className: PropTypes.string,
    noSpacing: PropTypes.bool,
};

export default FilterDropdown;
