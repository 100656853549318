import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import StatisticsButton from 'components/Button/StatisticsButton';
import IconButton from 'components/Button/IconButton';

const StyledCalendarBodyHead = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: ${props => props.theme.spacing.sm};

    ${props => props.theme.media.portrait`
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 ${props => props.theme.spacing.sm};
    `}
`;

const StyledStatisticsButton = styled(StatisticsButton)`
    margin: 0 ${props => props.theme.spacing.md};
    justify-content: center;

    ${props => props.theme.media.portrait`
        margin: 0;
    `}
`;

const IconButtonContainer = styled.div`
    display: none;

    ${props => props.theme.media.landscape`
        display: block;
    `}
`;


const CalendarBodyHead = ({ t, showStatistics, setShowConfiguration, theme }) => {
    return (
        <StyledCalendarBodyHead>
            <StyledStatisticsButton onClick={ showStatistics }>
                { t('Show Statistics') }
            </StyledStatisticsButton>
            { typeof setShowConfiguration === 'function' &&
                <IconButtonContainer>
                    <IconButton
                        round
                        color={ theme.colors.midnight }
                        iconColor={ theme.colors.white }
                        iconName="admin-panel"
                        onClick={ () => setShowConfiguration(true) }
                    />
                </IconButtonContainer>
            }
        </StyledCalendarBodyHead>
    );
};

CalendarBodyHead.propTypes = {
    t: PropTypes.func.isRequired,
    showStatistics: PropTypes.func.isRequired,
    setShowConfiguration: PropTypes.func,
};

export default withTheme(CalendarBodyHead);
