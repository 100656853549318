import { groupBy, entries, fromPairs, keyBy } from 'lodash';

export const indexByDate = {
    getInitialState: () => ({
        byId: {},
        byYear: {},
    }),
    index: (index, items, idField, getYear) => {
        const ordersByYear = groupBy(items, getYear);
        const ordersByYearWithPrevious = fromPairs(
            entries(ordersByYear)
                .map(([year, ordersForYear]) => [
                    year,
                    keyBy(ordersForYear, idField)
                ])
        );

        return {
            ...index,
            byId: {
                ...index.byId,
                ...keyBy(items, idField)
            },
            byYear: ordersByYearWithPrevious,
        };
    },
};
