import { createReducerFromMapping } from 'redux/utils/index';
import cookies from 'utils/Cookies/Cookies.js';
import { Session } from '@caverion/redux/api/actions';

const initialState = {
    loading: false,
    error: null,
    isAuthenticated: false
};

export const AUTHENTICATE = 'CUSTOMER_PLATFORM/Session/AUTHENTICATE';
export const AUTHENTICATE_SUCCESS = 'CUSTOMER_PLATFORM/Session/AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_FAIL = 'CUSTOMER_PLATFORM/Session/AUTHENTICATE_FAIL';

export const authenticate = (welcomeUrl, authorizationUrl, code, state) => {
    return async dispatch => {
        dispatch({ type: AUTHENTICATE });
        try {
            const cnid = cookies.getItem('cnid');

            if (state !== cnid) {
                return dispatch({
                    type: AUTHENTICATE_FAIL,
                    error: new Error('Authorization state does not match with  cnid.')
                });
            }

            // Patch the session with authorization code and redirect URLs.
            const session = await dispatch(Session.patch(code, authorizationUrl, welcomeUrl));
            const redirectUrl = session.redirectUrl || window.location.origin;

            return dispatch({
                type: AUTHENTICATE_SUCCESS,
                redirectUrl
            });
        } catch (error) {
            return dispatch({
                type: AUTHENTICATE_FAIL,
                error
            });
        }
    };
};

export const UNSET = 'CUSTOMER_PLATFORM/Session/UNSET';
export const UNSET_SUCCESS = 'CUSTOMER_PLATFORM/Session/UNSET_SUCCESS';
export const UNSET_FAIL = 'CUSTOMER_PLATFORM/Session/UNSET_FAIL';

export const unset = redirectUrl => {
    return async dispatch => {
        dispatch({ type: UNSET });
        try {
            const cnid = cookies.getItem('cnid');

            if (!cnid) {
                setTimeout(function() {
                    window.location = redirectUrl || '/';
                }, 0);
                return;
            }

            // Delete session and remove cookie
            const session = await dispatch(Session.delete());
            cookies.removeItem('cnid');

            // Redirect to Azure for AD logout
            setTimeout(function() {
                window.location = session.logoutUrl;
            }, 0);
        } catch (error) {
            cookies.removeItem('cnid');
            setTimeout(function() {
                window.location = redirectUrl || '/';
            });
            return dispatch({
                type: UNSET_FAIL
            });
        }
    };
};

const authorizationReducer = state => ({
    ...state,
    loading: true
});

const authorizationSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    isAuthenticated: true
});

const authorizationFailReducer = (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
    isAuthenticated: false
});

export default createReducerFromMapping({
    [AUTHENTICATE]: authorizationReducer,
    [AUTHENTICATE_SUCCESS]: authorizationSuccessReducer,
    [AUTHENTICATE_FAIL]: authorizationFailReducer
}, initialState);
