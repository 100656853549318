import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment-timezone';

import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import CleaningTable from 'components/CleaningEvents/Cleanings/CleaningTable';
import CleaningChart from 'components/Charts/CleaningChart';
import InputLabel from 'components/Form/InputLabel';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';

import { mapCleaningData, cleaningDates } from 'utils/Data/cleaning.js';

const AreaSelector = styled.div`
    display: flex;
    align-items: center;
`;

const SelectContainer = styled.div`
    min-width: 10em;
    margin-left: ${props => props.theme.spacing.md};
`;

const CleaningModule = props => {
    const {
        t,
        cleaningValues,
        floors,
        loadingBuilding,
        loadingCleaning
    } = props;
    const loading = loadingBuilding || loadingCleaning;

    const [selectedArea, setSelectedArea] = useState(null);

    const cleaningData = _.compact(cleaningValues.map( value => {
        const sensorId = parseInt(value.sensorId, 10);
        const floor = _.find(floors, floor => _.some(floor.sensors, ['id', sensorId]));
        return mapCleaningData(t, value, floor);
    })) || [];

    const cleaningBySensor = _.groupBy(cleaningValues, 'sensorId');
    const cleaningChartData = Object.keys(cleaningBySensor).reduce((sensors, id) => {
        sensors[id] = cleaningDates().map(date =>
            _.filter(cleaningBySensor[id], value =>
                value.timestamp && date && moment.utc(value.timestamp).local().isSame(date, 'day') &&
                (_.isNil(value.value) || value.value === 100)).length);
        return sensors;
    }, {});

    const area = selectedArea || cleaningData.length > 0 && cleaningData[0].area.id;
    const options = _.uniqBy(cleaningData.map( item => ({ label: item.area.value, value: item.area.id })), 'value');

    return (
        <Fragment>
            <Section>
                <SectionHeader
                    t={ t }
                    title={ t('Cleaning') }
                    ctxHelp={ `${CTXHELP_PREFIX} Cleaning` }
                />
                <CleaningTable t={ t } data={ cleaningData } loading={ loading } />
            </Section>
            <Section>
                <SectionHeader
                    t={ t }
                    title={ t('Cleaning History') }
                    ctxHelp={ `${CTXHELP_PREFIX} Cleaning History` }
                >
                    <AreaSelector>
                        <InputLabel text={ t('Show history for') } />
                        <SelectContainer>
                            <InputSelectDropdown
                                property="area"
                                model={ { area } }
                                options={ options }
                                t={ t }
                                clearable={ false }
                                onChange={ (property, value) => setSelectedArea(value) }
                            />
                        </SelectContainer>
                    </AreaSelector>
                </SectionHeader>
                <CleaningChart
                    t={t}
                    categories={ cleaningDates().map(date => date.format('D. MMM')) }
                    data={ cleaningChartData[area] }
                    loading={ loading }
                    xTitle={ t('Last 7 Days') }
                />
            </Section>
        </Fragment>
    );
};

const mapStateToProps = state => ({
    loadingBuilding: state.buildingContainer.loading,
    loadingCleaning: state.values.loadingCleaning
});

const connector = connect(mapStateToProps);

export default connector(CleaningModule);
