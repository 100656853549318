import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled, { withTheme } from 'styled-components';
import { rgba } from 'polished';

import { Icon, ICON_SIZES } from 'components/Icon/Icon';
import { icons } from 'components/Icon/IconNames';
import { statusToColor, statusToIcon, announcementShape, ANNOUNCEMENT_TYPE } from './utils';
import Svg from 'components/Svg/Svg';

const StyledAnnouncement = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    padding: 0;
    margin: 0 0 0.5em 0;
    border: 1px solid ${props => props.theme.colors.lightGray};
    border-radius: 0.25em;
    box-shadow: 0 2px 20px ${rgba(0, 0, 0, 0.08)};
    ${props => props.theme.media.desktop`
        flex-direction: row;
        margin: 0.5em;
    `}
`;
StyledAnnouncement.displayName = 'StyledAnnouncement';

const Content = styled.section`
    display: flex;
    flex-direction: column;
    padding: 1.5em;
    width: calc(100% - 50px);
`;
Content.displayName = 'Content';

const Title = styled.h4`
    color: ${props => props.theme.colors.black};
    font-family: ${props => props.theme.font.family.sanchez};
    font-size: ${props => props.theme.font.size.sm};
    font-weight: ${props => props.theme.font.weight.bold};
    svg {
        position: relative;
        top: 0.5em;
        right: 0.5em;
    }
`;
Title.displayName = 'Title';

const Description = styled.p`
    margin: ${props => `${props.theme.font.size.xxs} 0 ${props.theme.font.size.sm} 0`};
    color: ${props => props.theme.colors.darkGray};
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.font.size.sm};
    line-height: ${props => props.theme.font.lineHeight.lg};
    text-align: left;
    white-space: pre-wrap;
    overflow: hidden;
`;
Description.displayName = 'Description';

const StyledDate = styled.p`
    color: ${props => props.theme.colors.rockBlue};
    font-size: ${props => props.theme.font.size.xs};
    font-family: ${props => props.theme.font.family.arial};
    font-style: italic;
    margin: 0;
    padding: 0.5em 0;
`;
StyledDate.displayName = 'StyledDate';

const IconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto 0.5em;
    padding: 0.5em 0 0 0;
    width: 100%;
    border-top: 1px solid ${props => props.theme.colors.lightGray};
    ${props => props.theme.media.desktop`
        flex-direction: column;
        width: 50px;
        margin: 0;
        padding: initial 0;
        border-top: 0;
        border-left: 1px solid ${props => props.theme.colors.lightGray};
    `};
`;
IconWrapper.displayName = 'IconWrapper';

const IconBall = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0.25em;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    background-color: ${props => props.color};
    &:hover {
        transform: scale(1.1);
        cursor: pointer;
    }
`;
IconBall.displayName = 'IconBall';

const CalendarIcon = styled(Svg)`
    position: relative;
    top: 2px;
    fill: ${props => props.theme.colors.rockBlue};
    margin-right: 0.5em;
`;
CalendarIcon.displayName = 'CalendarIcon';

const Information = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p, svg {
        margin-right: 8px
    }
`;
Information.displayName = 'Information';

const StatusText = styled.p`
    color: ${props => props.theme.colors.darkGray};
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.font.size.xs};
    text-transform: capitalize;
`;
StatusText.displayName = 'StatusText';


const AdminAnnouncement = props => {
    const { announcement, onDestroy, onEdit } = props;

    return (
        <StyledAnnouncement data-test-id="AdminAnnouncement">
            <Content>
                <Title data-test-id="AdminAnnouncement-title">{ announcement.title }</Title>
                <Description data-test-id="AdminAnnouncement-description">{ announcement.description }</Description>
                <Information>
                    <Icon
                        name={ statusToIcon[announcement.status] }
                        size={ ICON_SIZES.MEDIUM_MINUS }
                        fill={ statusToColor[announcement.status] } />
                    <StatusText>{ announcement.status }</StatusText>
                    <StyledDate data-test-id="AdminAnnouncement-StyledDate-startDate">
                        <CalendarIcon name="calendar-date" />
                        { moment.utc(announcement.startDate).local().format('DD.MM.YYYY') }
                    </StyledDate>
                    <StyledDate data-test-id="AdminAnnouncement-StyledDate-expirationDate">
                        <CalendarIcon name="calendar-expiry" />
                        { moment.utc(announcement.expirationDate).local().format('DD.MM.YYYY') }
                    </StyledDate>
                </Information>
            </Content>
            <IconWrapper>
                <IconBall
                    color={ props.theme.colors.radicalRed }
                    onClick={onDestroy}
                    data-test-id="AdminAnnouncement-IconBall-del">
                    <Icon
                        name={ icons.DEL }
                        size={ICON_SIZES.SMALL}
                        fill={ props.theme.colors.white } />
                </IconBall>
                <IconBall
                    color={ props.theme.colors.blue }
                    onClick={onEdit}
                    data-test-id="AdminAnnouncement-IconBall-pen">
                    <Icon
                        name={ icons.PEN }
                        size={ICON_SIZES.SMALL}
                        fill={ props.theme.colors.white } />
                </IconBall>
            </IconWrapper>
        </StyledAnnouncement>
    );
};

AdminAnnouncement.displayName = 'AdminAnnouncement';

AdminAnnouncement.propTypes = {
    announcement: PropTypes.oneOfType([
        announcementShape(ANNOUNCEMENT_TYPE.FL),
        announcementShape(ANNOUNCEMENT_TYPE.PARTNER),
        announcementShape(ANNOUNCEMENT_TYPE.SYSTEM)
    ]),
    onDestroy: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired
};

export default withTheme(AdminAnnouncement);
