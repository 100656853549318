import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import SortableTableRow from './SortableTableRow';
import SortableTableHead from './SortableTableHead';
import SortableTableBody from './SortableTableBody';
import SortableTableControls from './SortableTableControls';
import SkeletonTableBody from '../Skeletons/SkeletonTableBody';

export const StyledSortableTable = styled.table`
    width: 100%;
    color: ${props => props.theme.colors.black};
    font-family: ${props => props.theme.font.sanchez};
    &:not(:first-child) {
        border-top: 1px solid ${props => props.borderColor ? props.theme.colors[props.borderColor] : props.theme.colors.geyser};
    }
    border-top: ${props => props.topStripe && `1px solid ${props.theme.colors.geyser}`};
`;

const TableBodyRow = styled(SortableTableRow)`
    ${props => typeof props.onClick === 'function' && css`
        cursor: pointer;
    `}

    ${props => props.stripes && css`
        &:nth-child(even) {
            background-color: ${props.theme.colors.alabaster};
        }
    `}
`;

const toggleOrder = order => {
    return order === 'asc' ? 'desc' : 'asc';
};

const mobile = window.innerWidth < 900;

export const DEFAULT_INITIAL_ROWS = 10;
const DEFAULT_ROWS_PER_FOLD = 10;

export const getRowsVisible = initialRows => mobile ? initialRows / 2 : initialRows;

class SortableTable extends Component {
    state = {
        order: 'asc',
        orderBy: null,
        visibleRows: DEFAULT_INITIAL_ROWS,
        rowsPerFold: DEFAULT_ROWS_PER_FOLD
    };

    top = React.createRef();

    componentDidMount() {
        this.initializeState();
    }

    // Check if SortableTable data has changed.
    shouldComponentUpdate(nextProps, nextState) {
        const {
            columns,
            data,
            RowComponent,
            cellPadding,
            stripes,
            headerBackgroundColor,
            borderColor,
            removeSidePadding,
            initialRows,
            loading,
            location: {
                search,
            },
            pure,
        } = this.props;

        const equals = pure ? (a, b) => a === b : _.isEqual;
        return !equals(columns, nextProps.columns)
            || !equals(data, nextProps.data)
            || !equals(RowComponent, nextProps.RowComponent)
            || !equals(cellPadding, nextProps.cellPadding)
            || !equals(stripes, nextProps.stripes)
            || !equals(headerBackgroundColor, nextProps.headerBackgroundColor)
            || !equals(borderColor, nextProps.borderColor)
            || !equals(removeSidePadding, nextProps.removeSidePadding)
            || !equals(initialRows, nextProps.initialRows)
            || !equals(loading, nextProps.loading)
            || !equals(this.state, nextState)
            || search !== nextProps.location.search;
    }

    componentDidUpdate(prevProps, prevState) {
        // Reset pagination if ordering changes
        if (this.state.order !== prevState.order
            || this.state.orderBy !== prevState.orderBy
            || this.state.visibleRows !== prevState.visibleRows
            || this.state.rowsPerFold !== prevState.rowsPerFold
            || this.state.showAll !== prevState.showAll
        ) {
            const { history, location } = this.props;
            const search = queryString.parse(location.search);
            if (search.page && search.page > 1) {
                history.push({
                    pathname: location.pathname,
                    search: queryString.stringify({
                        ...search,
                        page: 1,
                    }),
                });
            }
        }
    }

    initializeState = () => {
        const { orderBy, columns, order, rowsPerFold = DEFAULT_ROWS_PER_FOLD, initialRows = DEFAULT_INITIAL_ROWS }
        = this.props;
        const rowsVisible = getRowsVisible(initialRows);

        // Set orderBy from props if defined
        if (orderBy) {
            this.setState({
                orderBy: orderBy,
                order: order,
                visibleRows: rowsVisible,
                rowsPerFold,
                showAll: false,
            });
        } else if (columns && columns.length > 0) {
            // Else set orderBy from first column
            this.setState({
                orderBy: _.first(columns).field,
                order: order,
                visibleRows: rowsVisible,
                rowsPerFold,
                showAll: false,
            });
        }
    };

    changeOrder = orderBy => {
        const order = this.state.orderBy === orderBy ? toggleOrder(this.state.order) : 'asc';
        this.setState({ orderBy, order });
    };

    setVisibleRows = rows => {
        this.setState({ visibleRows: isNaN(rows) ? undefined : rows });
    };

    handlePageChange = () => {
        if (this.props.scrollTop && this.top.current) {
            const rect = this.top.current.getBoundingClientRect();
            window.requestAnimationFrame(() => {
                window.scrollBy({
                    behavior: 'smooth',
                    top: rect.top - 160,
                    left: 0,
                });
            });
        }
    };

    handleShowAllToggle = () => {
        this.setState(oldState => ({ showAll: !oldState.showAll }), this.handlePageChange);
    }

    render() {
        const {
            columns,
            data,
            onClick,
            RowComponent,
            SkeletonBodyComponent,
            cellPadding,
            stripes,
            headerBackgroundColor,
            borderColor,
            removeSidePadding,
            t,
            showControls,
            loading,
            removeHeadOnMobile,
            initialRows = DEFAULT_INITIAL_ROWS,
            paginate,
            location: {
                search,
            },
            topStripe,
            keyField,
            removeHead,
            TableComponent,
        } = this.props;

        const { order, orderBy, showAll } = this.state;

        // Use custom row component from props if available
        const TableRowComponent = RowComponent != null ? RowComponent : TableBodyRow;
        const SkeletonTableBodyComponent = SkeletonBodyComponent != null ? SkeletonBodyComponent : SkeletonTableBody;

        const rowsInitiallyVisible = mobile ? initialRows / 2 : initialRows;
        const page = parseInt(queryString.parse(search).page, 10) || 1;
        return (
            <Fragment>
                <div ref={ this.top } />
                <div data-test-id={ this.props['data-test-id'] }>
                    <TableComponent borderColor={ borderColor } topStripe={ topStripe }>
                        { !removeHead && <SortableTableHead
                            columns={ columns }
                            cellPadding={ cellPadding }
                            stripes={ stripes }
                            headerBackgroundColor={ headerBackgroundColor }
                            removeSidePadding={ removeSidePadding }
                            order={ order }
                            orderBy={ orderBy }
                            changeOrder={ this.changeOrder }
                            hideOnMobile={ removeHeadOnMobile }
                        /> }
                        { loading ? <SkeletonTableBodyComponent columns={ columns } /> :
                        <SortableTableBody
                            columns={ columns }
                            data={ data }
                            onClick={ onClick }
                            TableRowComponent={ TableRowComponent }
                            cellPadding={ cellPadding }
                            stripes={ stripes }
                            order={ order }
                            orderBy={ orderBy }
                            t={ t }
                            visibleRows={ showControls && !showAll ? this.state.visibleRows : undefined }
                            paginate={ paginate }
                            page={ page }
                            keyField={ keyField }
                        /> }
                    </TableComponent>
                    { !loading && showControls && data && data.length > rowsInitiallyVisible &&
                            <SortableTableControls
                                visibleRows={ showAll ? undefined : this.state.visibleRows }
                                totalRows={ data.length }
                                setVisibleRows={ this.setVisibleRows }
                                rowsPerFold={ this.state.rowsPerFold }
                                initialRows={ rowsInitiallyVisible }
                                t={ t }
                                paginate={ paginate }
                                page={ page }
                                onPageChange={ this.handlePageChange }
                                showAll={ showAll }
                                toggleShowAll={ this.handleShowAllToggle }
                            />
                    }
                </div>
            </Fragment>
        );
    }
}

SortableTable.defaultProps = {
    order: 'asc',
    stripes: true,
    removeSidePadding: false,
    paginate: false,
    removeHead: false,
    TableComponent: StyledSortableTable,
};

export default withRouter(SortableTable);
