import moment from 'moment-timezone';
import { transparentize } from 'polished';
import { isNil, forEach } from 'lodash';

export const tooltipFormatter =
(points, x, theme, top, temperatureSeriesName, trueValue, falseValue, precision = 2) => {
    const dateFormatter = points[0].series.userOptions.tooltipDateFormatter;
    const hideTime = points[0].series.userOptions.hideTime;
    const timestamp = dateFormatter ?
        dateFormatter(x) :
        hideTime ? moment(x).format('Do MMM') : moment(x).format('Do MMM HH:mm');
    const showTrueFalse = trueValue !== undefined && falseValue !== undefined;
    const trueFalseValue = showTrueFalse ? points[0].y > 0 ? trueValue : falseValue : '';
    const plotX = points[0].point.plotX;
    const chartWidth = points[0].series.chart.chartWidth;
    const plotWidth = points[0].series.chart.plotWidth;
    const distanceFromRight = chartWidth - plotX;
    const offset = plotWidth - plotX;
    const right = distanceFromRight > 120 ? 'calc(50% - 12px)' : `${offset - 5}px`;

    const headerStyle = `
        color: ${theme.colors.black};
        background-color: ${theme.colors.white};
        font-size: ${theme.font.size.xs};
        padding: 0.5em 1em;
        position: relative;
        top: -${showTrueFalse ? top + 8 : top}px;
        right: 5px;
        border: 1px solid ${theme.colors.lightGray};
        box-shadow: 0 2px 10px ${transparentize(0.9, theme.colors.black)};
        border-radius: 4px;
        padding: 0.5em 1em;
        text-align: center;
    `;
    const border = `
        position: absolute;
        bottom: -10px;
        right: ${right};
        border-width: 10px 8px 0;
        border-style: solid;
        border-color: ${theme.colors.lightGray} transparent;
        display: block;
        width: 0;
    `;
    const borderTriangle = `
        position: absolute;
        bottom: -9px;
        right: ${right};
        border-width: 10px 8px 0;
        border-style: solid;
        border-color: #fff transparent;
        display: block;
        width: 0;
    `;
    const header = `
        <div style="${headerStyle}">
            <span style="${border}"></span>
            ${trueFalseValue}${showTrueFalse ? '<br>' : ''}
            ${timestamp}
            <span style="${borderTriangle}"></span>
        </div>
    `;

    return [header].concat(
        points.map(point => {
            if (point.interpolated || point.series.userOptions.tooltip === false || showTrueFalse) {
                return null;
            }

            const showName = !point.series.userOptions.singleLine;
            const tempSeries = point.series.name === temperatureSeriesName;
            const value = !isNil(point.y)
                ? tempSeries
                    ? point.y.toFixed(0)
                    : point.y.toFixed(precision)
                : '';
            const unit = !isNil(point.series.userOptions.unit) ? point.series.userOptions.unit :
                tempSeries ? `${String.fromCharCode(176)}C` : '%';

            const itemStyle = `
                text-align: left;
                background-color: ${point.color};
                color: ${theme.colors.white};
                padding: 0.5em 1em;
                width: ${showName ? '150px' : 'auto'};
            `;
            const nameStyle = `
                max-width: 140px;
                overflow: hidden;
                text-overflow: ellipsis;
            `;

            return `
                <div style="${itemStyle}">
                    <div style="${nameStyle}">${showName ? point.series.name : ''}</div>
                    <div style="font-size: 1.25em;">${value + ' ' + unit}</div>
                </div>
            `;
        })
    );
};

/**
 * Build zones that show data as dotted lines when value has been null for over 8 hours
 */
export const buildZones = data => {
    const zones = [];
    let lastNotNullValueTime;
    forEach(data, (current, i) => {
        // If we are on the first item, don't do anything
        if (i === 0) {
            return;
        }
        const currentValue = !!current && current[1];
        const previous = data[i - 1];
        const previousValue = !!previous && previous[1];
        // Show as solid until first null value is detected
        if (previousValue !== null && currentValue === null) {
            lastNotNullValueTime = previous[0];
            zones.push({
                dashStyle: 'solid',
                value: lastNotNullValueTime
            });
        } else if (previousValue === null && currentValue !== null) {
            // From the last not null value moment until next value,
            // we show dotted line if value has been null for over 8 hours
            const currentDate = moment(current[0]);
            if (!lastNotNullValueTime || currentDate.diff(lastNotNullValueTime, 'hours') > 8) {
                zones.push({
                    dashStyle: 'dot',
                    value: current[0]
                });
            } else if (zones.length > 0) {
                // Remove unnecessary dashStyle: solid if downtime is less than 8 hours
                zones.splice(-1, 1);
            }
        }
    });
    return zones;
};
