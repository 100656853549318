import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    StyledInput
} from './InputText';
import {
    InputLabel,
    Button
} from 'components';
import _ from 'lodash';

import Svg from 'components/Svg/Svg';
import InputBooleanCheckbox from 'components/Form/InputBooleanCheckbox';

const Inputs = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    margin: ${props => props.theme.spacing.md} 0;
`;
Inputs.displayName = 'Inputs';

const InputColumn = styled.div`
    flex: 1;
    margin-right: ${props => props.theme.spacing.sm};
`;
InputColumn.displayName = 'InputColumn';

const CheckBoxColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: ${props => props.theme.spacing.sm};
    width: 120px;
`;
CheckBoxColumn.displayName = 'CheckBoxColumn';

const CheckBoxContainer = styled.div`
    display: flex;
    align-items: center;
    height: 3em;
`;
CheckBoxContainer.displayName = 'CheckBoxContainer';

const ButtonColumn = styled.div`
    align-self: flex-end;
    line-height: 3em;
`;
ButtonColumn.displayName = 'ButtonColumn';

const RemoveIcon = styled(Svg)`
    fill: ${props => props.theme.colors.darkGray};
    font-size: ${props => props.theme.font.size.xxs};
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer' };
`;
RemoveIcon.displayName = 'RemoveIcon';

const AddRow = styled.div`
    margin-top: ${props => props.theme.spacing.md};
`;
AddRow.displayName = 'AddRow';


const MultiInputRow = ({
    t,
    model,
    addLabel,
    removeLabel,
    handleAdd,
    handleRemove,
    onPropertyChange,
    baseProperty,
    subProperties,
    subPropertyTitles,
    filterProperty,
    filterKeys,
    checkboxes,
    disabledSubProperties,
    disableRemove,
    isDisabled,
    options,
    optionsKey,
    ...other
}) => {
    return <div {...other}>
        {model && baseProperty && model[baseProperty] && model[baseProperty].length > 0
            && model[baseProperty].map((row, index) => {

                // Skip filtered properties
                if (filterProperty && _.includes(filterKeys, row[filterProperty])) {
                    return null;
                }
                const rowDisabled = isDisabled(row, index);

                return <Inputs key={index}>
                    { subProperties.map(propKey => {
                        const id = `row${index}_${propKey}`;
                        const label = subPropertyTitles ? subPropertyTitles[propKey] : propKey;
                        const prop = _.isArray(baseProperty) ? _.first(baseProperty) : baseProperty;
                        const isCheckbox = _.includes(checkboxes, propKey);
                        const propertyDisabled = rowDisabled || _.includes(disabledSubProperties, propKey);
                        const firstRow = index === 0;

                        if (isCheckbox) {
                            return <CheckBoxColumn key={ id + '_col' }>
                                { firstRow && <InputLabel text={ label } id={ id } /> }
                                <CheckBoxContainer>
                                    <InputBooleanCheckbox
                                        disabled={ propertyDisabled }
                                        id={ id }
                                        value={ row[propKey] }
                                        onChange={ (property, value) =>
                                            onPropertyChange(prop + '[' + index + '].' + propKey, value) }
                                    />
                                </CheckBoxContainer>
                            </CheckBoxColumn>;
                        }

                        return <InputColumn key={ id + '_col' }>
                            { firstRow && <InputLabel text={ label } id={ id } /> }
                            <StyledInput
                                id={ id }
                                type="text"
                                simple
                                value={ row[propKey] || '' }
                                onChange={ e =>
                                    onPropertyChange(prop + '[' + index + '].' + propKey, e.target.value) }
                                disabled={ propertyDisabled }
                                options={ options && options[propKey] }
                                list={ `options_${id}` }
                            />
                            {/**
                                Options are suggestions for possible values
                                The implementation is for key-value pairs so that "value"-field uses optionsKey-field to get possible value options
                            */
                                options && options[propKey] &&
                                <datalist id={ `options_${id}` }>
                                    { _.map(
                                        propKey === optionsKey ? options[propKey] : options[propKey][row[optionsKey]],
                                        optionValue => <option value={optionValue} key={optionValue} />
                                    )}
                                </datalist>
                            }
                        </InputColumn>;
                    })}
                    <ButtonColumn>
                        <RemoveIcon
                            disabled={ disableRemove || rowDisabled }
                            name="remove"
                            onClick={ !disableRemove && !rowDisabled ? e => handleRemove(e, index) : undefined }
                        />
                    </ButtonColumn>
                </Inputs>;
            })}
        <AddRow>
            <Button onClick={ handleAdd }>{ addLabel || t('Add row') }</Button>
        </AddRow>
    </div>;
};

MultiInputRow.propTypes = {
    model: PropTypes.object.isRequired,
    handleAdd: PropTypes.func.isRequired,
    handleRemove: PropTypes.func.isRequired,
    onPropertyChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    filterProperty: PropTypes.string,
    filterKeys: PropTypes.arrayOf(PropTypes.string),
    checkboxes: PropTypes.arrayOf(PropTypes.string),
    disabledSubProperties: PropTypes.arrayOf(PropTypes.string),
    disableRemove: PropTypes.bool,
    isDisabled: PropTypes.func,
    options: PropTypes.object,
    optionsKey: PropTypes.string,
};

MultiInputRow.defaultProps = {
    filterProperty: '',
    filterKeys: [],
    checkboxes: [],
    disabledSubProperties: [],
    disableRemove: false,
    isDisabled: () => { return false; },
};

export default MultiInputRow;
