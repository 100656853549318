import { join, compact, includes, isArray, fromPairs, map, filter, startsWith, isNil, last } from 'lodash';
import moment from 'moment-timezone';
import memoizeOne from 'memoize-one';

import {
    FILTERABLE_FIELDS,
    OrderStatus,
    SyntheticOrderStatus,
    ExternalType,
    DEFAULT_PRIORITY,
    SLA_META_KEY_REACTION_TIME,
    SLA_META_KEY_DOWNTIME,
    SLA_META_KEY_LEAD_TIME,
    GROUP_UNKNOWN,
    BreakdownFields,
} from 'constants/serviceCalendar';
import { selectAuthorizedPartnerNumber } from 'utils/profile';

// Get an array of unique reference functional locations for the given array of service orders
export const getUniqueFunctionalLocations = serviceOrders => {
    if (!serviceOrders) {
        return [];
    }

    const functionalLocations = new Set();
    serviceOrders.forEach(order => order.path.forEach(fl => functionalLocations.add(fl)));
    return Array.from(functionalLocations);
};

// Get an array of unique reference equipment for the given array of service orders
export const getUniqueEquipment = serviceOrders => {
    if (!serviceOrders) {
        return [];
    }

    const equipment = new Set();
    filter(serviceOrders, so => so.maintenanceActivityType !== 'Y02')
        .forEach(order => order.equipmentNumber.forEach(fl => equipment.add(fl)));
    return Array.from(equipment);
};

export const filterServiceOrders = (serviceOrders, activeFilter, excluded = null) => {
    // Quick return if no filter is set
    if (Object.keys(activeFilter).length === 0) {
        return serviceOrders;
    }

    return serviceOrders.filter(serviceOrder =>
        FILTERABLE_FIELDS.every(field => {
            if (
                activeFilter[field] &&
                excluded !== field &&
                (activeFilter[field] !== serviceOrder.meta.filterable[field] && (
                    !isArray(activeFilter[field]) || !includes(activeFilter[field], serviceOrder.meta.filterable[field])
                ))
            ) {
                return false;
            }

            return true;
        })
    );
};

const getText = text => text && text.split('\n')[0];

const unfinishedStatuses = {
    [OrderStatus.OPEN]: true,
    [OrderStatus.IN_PROGRESS]: true,
    [OrderStatus.PARTLY_COMPLETED]: true,
    [OrderStatus.POSTPONED]: true,
    [OrderStatus.PLANNED]: true,
    [OrderStatus.DELAYED]: true,
    [OrderStatus.RELEASED]: true,
    [OrderStatus.STARTED]: true,
    [OrderStatus.ARRIVED]: true,
};

export const getPlannedMaintenanceStatus = order => {
    if (order.externalType && order.externalType !== ExternalType.SAP) {
        if (order.detailedStatus in unfinishedStatuses) {
            return SyntheticOrderStatus.UNFINISHED;
        }

        return order.detailedStatus;
    }

    // Custom handling for SAP planned maintenance
    let maintenanceWindowStart;
    let maintenanceWindowEnd;
    if (order.plannedDate) {
        maintenanceWindowStart = moment(order.plannedDate);
        maintenanceWindowEnd = moment(maintenanceWindowStart).add(30, 'days');
    }

    if (order.status in unfinishedStatuses) {
        if (maintenanceWindowEnd && maintenanceWindowEnd.isBefore(moment())) {
            return OrderStatus.OVERDUE;
        }
        return SyntheticOrderStatus.UNFINISHED;
    }

    if (order.status === 'Completed') {
        if (!maintenanceWindowStart) {
            return SyntheticOrderStatus.COMPLETED_OTHER;
        }
        const completedDate = moment(order.modifiedDate);
        if (completedDate.isBefore(maintenanceWindowStart)) {
            return OrderStatus.COMPLETED_EARLY;
        } else if (completedDate.isAfter(maintenanceWindowEnd)) {
            return OrderStatus.COMPLETED_LATE;
        }
        return OrderStatus.COMPLETED_ON_TIME;
    }

    return order.detailedStatus;
};

const formatResponseTime = responseTime => {
    // make sortable table sort nil values alike and hide negative values
    if (isNil(responseTime) || responseTime < 0) {
        return null;
    }
    // convert seconds to hours
    return responseTime / 60 / 60;
};

const getDiscipline = functionalLocation =>
    functionalLocation && functionalLocation.type === 'SYSTEM' ?
        `DISCIPLINE_${last(functionalLocation.functionalLocation.split('-')).toUpperCase()}` :
        GROUP_UNKNOWN;

export const formatOrderForCalendar = (
    order,
    functionalLocations,
    profile,
    addSyntheticStatus = false,
    loadingFL = false,
    SLAs,
) => {
    const functionalLocation = functionalLocations[order.functionalLocation];
    let address;
    let location;
    let noPermissions = false;
    if (functionalLocation) {
        address = [functionalLocation.country, functionalLocation.city, functionalLocation.address]
            .filter(part => part)
            .join(', ');

        const addressArray = [];
        addressArray.push(getText(functionalLocation.description));
        addressArray.push(functionalLocation.name);
        location = join(compact(addressArray), ', ');
    } else {
        noPermissions = true;
        location = order.functionalLocation;
    }

    const priority = order.priority || DEFAULT_PRIORITY;

    const date = new Date(order.plannedDate || order.createdDate);

    const reactionTime = formatResponseTime(order[BreakdownFields.REACTION_TIME]);
    const downtime = formatResponseTime(order[BreakdownFields.DOWNTIME]);
    const leadTime = formatResponseTime(order[BreakdownFields.LEAD_TIME]);

    return {
        ...order,
        reactionTime,
        downtime,
        leadTime,
        location,
        meta: {
            noPermissions,
            loadingFL,
            syntheticStatus: addSyntheticStatus ? getPlannedMaintenanceStatus(order) : undefined,
            authorizedPartnerNumber: selectAuthorizedPartnerNumber(order.partnerNumberWithParents, profile, order.path),
            filterable: {
                type: order.maintenanceActivityType,
                source: order.externalType,
                location: order.functionalLocation,
                address: address,
                status: order.status,
                priority: order.priority,
                date,
                discipline: getDiscipline(functionalLocation),
            },
            overSLA: {
                reactionTime: SLAs && reactionTime > SLAs.reactionTime[priority],
                downtime: SLAs && downtime > SLAs.downtime[priority],
                leadTime: SLAs && leadTime > SLAs.leadTime[priority],
            },
        },
    };
};

export const filterByMonths = (orders, months) => {
    const monthMap = months.reduce((acc, month) => ({ ...acc, [month]: true }), {});
    return orders.filter(order => order.meta.filterable.date && order.meta.filterable.date.getMonth() + 1 in monthMap);
};

export const filterByDay = (orders, month, day) => {
    return orders.filter(order =>
        order.meta.filterable.date &&
        order.meta.filterable.date.getMonth() + 1 === month && order.meta.filterable.date.getDate() === day
    );
};

/**
 * Get SLAs from partner meta.
 * returns something like { reactionTime: { 1: 100, 2: 200 }, downtime: { 1: 110, 2: 220 }, leadTime: { 1: 110, 2: 220 } }
 */
export const getResponseSLAs = memoizeOne((partnerMeta, partnerNumber) => {
    if (partnerMeta && partnerMeta[partnerNumber] && partnerMeta[partnerNumber].meta) {
        return {
            reactionTime: fromPairs(
                map(
                    filter(partnerMeta[partnerNumber].meta, meta => startsWith(meta.key, SLA_META_KEY_REACTION_TIME)),
                    meta => [meta.key.slice(SLA_META_KEY_REACTION_TIME.length), +meta.value]
                )
            ),
            downtime: fromPairs(
                map(
                    filter(partnerMeta[partnerNumber].meta, meta => startsWith(meta.key, SLA_META_KEY_DOWNTIME)),
                    meta => [meta.key.slice(SLA_META_KEY_DOWNTIME.length), +meta.value]
                )
            ),
            leadTime: fromPairs(
                map(
                    filter(partnerMeta[partnerNumber].meta, meta => startsWith(meta.key, SLA_META_KEY_LEAD_TIME)),
                    meta => [meta.key.slice(SLA_META_KEY_LEAD_TIME.length), +meta.value]
                )
            )
        };
    }
    return null;
});
