export const sendError = (error, info) => {
    let parts;
    if (error.stack) {
        const component = error.stack.slice(error.stack.indexOf('(') + 1, error.stack.indexOf(')'));
        parts = component.split(':');
    }

    const data = {
        message: `${error.name} ${error.message}`,
        source: info,
        lineNumber: error.lineNumber || error.line || parts && parts[parts.length - 2],
        colNumber: error.columnNumber || error.column || parts && parts[parts.length - 1],
        error,
        windowLocation: window.location,
        browser: navigator.userAgent
    };
    const http = new XMLHttpRequest();
    http.open('POST', '/error/message', true);
    http.setRequestHeader('Content-type', 'application/json;charset=UTF-8');
    http.send(JSON.stringify(data));

    http.onReadyStateChange = function() {
        if (http.readyState === 4 && http.status === 200) {
            console.log('sent');
        }
        else {
            console.log('readyState is', http.readyState);
            console.log('status is', http.status);
        }
    };
};
