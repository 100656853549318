import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';

const StyledValuesBoxComparisonValue = styled.div`
    padding: ${props => props.theme.spacing.sm};
    flex: 1;
    text-align: center;
    &:not(:first-child) {
        border-left: ${({ theme }) => `${theme.spacing.border} solid ${theme.colors.lightGray}`};
    }
`;
StyledValuesBoxComparisonValue.displayName = 'StyledValuesBoxComparisonValue';

const Value = styled.div`
    font-size: ${props => props.theme.fontSize.xxs};
    line-height: 1rem;
`;
Value.displayName = 'Value';

const Label = styled.div`
    font-size: ${props => props.theme.fontSize.xxxs};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.rockBlue};
    line-height: 1rem;
`;
Label.displayName = 'Label';

const ValuesBoxComparisonValue = ({
    value,
    label,
    ...others
}) =>
    <StyledValuesBoxComparisonValue {...others}>
        { value && <Value>{ value }</Value> }
        { label && <Label>{ label }</Label> }
    </StyledValuesBoxComparisonValue>;

ValuesBoxComparisonValue.defaultProps = {
    value: '',
    label: ''
};

ValuesBoxComparisonValue.propTypes = {
    /** Value with unit */
    value: PropTypes.string,
    /** Value label */
    label: PropTypes.string
};

export default ValuesBoxComparisonValue;
