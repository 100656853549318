import { icons } from 'components/Icon/IconNames';
import themes from 'styles/themes';
import moment from 'moment';
import { sortBy, reject, filter, includes, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { getPartnerNumbers } from 'utils/profile';

export const statuses = ['info', 'warning', 'update', 'success', 'release notes'];

export const DESCRIPTION_MAX_LENGTH = 80;
export const FL_DESCRIPTION_MAX_LENGTH = 150;

export const statusToIcon = {
    'warning': icons.ANNOUNCEMENT_WARNING,
    'info': icons.ANNOUNCEMENT_INFO,
    'update': icons.ANNOUNCEMENT_RELEASE_NOTES,
    'release notes': icons.ANNOUNCEMENT_RELEASE_NOTES,
    'success': icons.ANNOUNCEMENT_SUCCESS
};

export const statusToColor = {
    'warning': themes.customerPlatform.colors.orange,
    'info': themes.customerPlatform.colors.cerulean,
    'update': themes.customerPlatform.colors.cerulean,
    'release notes': themes.customerPlatform.colors.cerulean,
    'success': themes.customerPlatform.colors.emerald
};

export const activeAnnouncements = announcements => announcements.filter(announcement =>
    moment.utc(announcement.startDate).startOf('day').isSameOrBefore(moment.utc())
    && moment.utc(announcement.expirationDay).isAfter(moment.utc().startOf('day')));

export const sortAnnouncements = announcements => sortBy( announcements, announcement =>
    -1 * moment.utc(announcement.startDate).valueOf() );

export const toPublishedAndScheduled = announcements => {
    const published = [];
    const scheduled = [];

    announcements.forEach( announcement => {
        if ( moment.utc().isSameOrAfter(moment.utc(announcement.startDate).startOf('day')) ) {
            published.push(announcement);
        } else {
            scheduled.push(announcement);
        }
    });

    return {
        published: published,
        scheduled: scheduled
    };
};

export const ANNOUNCEMENT_TYPE = {
    FL: 'FL',
    PARTNER: 'PARTNER',
    SYSTEM: 'SYSTEM',
    PERSONAL: 'PERSONAL'
};

export const typeToProperty = {
    'FL': { functionalLocation: PropTypes.string.isRequired },
    'PARTNER': { partnerNumber: PropTypes.string.isRequired },
    'SYSTEM': {}
};

export const announcementShape = () => PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    expirationDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    status: PropTypes.oneOf(statuses).isRequired,
    ...typeToProperty[ANNOUNCEMENT_TYPE.type]
});

export const filterSystemLevelAnnouncements = announcements => reject(announcements, 'partnerNumber');

export const filterPartnerLevelAnnouncementsByPartnerNumber = (announcements, partnerNumber) =>
    filter(announcements, announcement => announcement.partnerNumber === partnerNumber);

export const toISOFormat = date => moment.isMoment(date) ? date.toISOString() : date;

export const getStartDate = date => {
    if (moment.utc().isSame(date, 'day')) {
        return moment.utc().toISOString();
    }
    return toISOFormat(date);
};

const filterByActivePartner = (announcements, activePartner, partnerNumbers) => {
    if (activePartner === 'all') {
        return announcements.filter(announcement =>
            !announcement.partnerNumber || includes(partnerNumbers, announcement.partnerNumber));
    }
    return announcements.filter(announcement =>
        !announcement.partnerNumber || announcement.partnerNumber === activePartner);
};

export const getAnnouncements = (profile, generalAnnouncements = [], personalAnnouncements = [], activePartner) => {
    const allAnnouncements = [
        ...generalAnnouncements,
        ...personalAnnouncements
    ];
    let unread = [];
    const read = [];
    const sortedActiveAnnouncements = sortAnnouncements(
        activeAnnouncements(filterByActivePartner(allAnnouncements, activePartner, getPartnerNumbers(profile))));
    if (profile.ackAnnouncements) {
        const ackIds = Object.keys(profile.ackAnnouncements);
        sortedActiveAnnouncements.forEach(announcement => {
            if (ackIds.indexOf(announcement.id) === -1) {
                unread.push(announcement);
            } else {
                read.push(announcement);
            }
        });
    } else {
        unread = sortedActiveAnnouncements;
    }
    return { read, unread };
};
