import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { without, concat } from 'lodash';

import Button from 'components/Button/Button';
import InputLabel from 'components/Form/InputLabel';
import InputWithButton from 'components/Form/InputWithButton';
import Svg from 'components/Svg/Svg';
import DialogModal from 'components/Dialog/DialogModal';
import DialogFooter from 'components/Dialog/DialogFooter';

const StyledHeader = styled.header`
    margin-bottom: ${props => props.theme.spacing.md};
`;
StyledHeader.displayName = 'StyledHeader';

const StyledFormContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
`;
StyledFormContainer.displayName = 'StyledFormContainer';

const FormRow = styled.div`
    width: ${props => props.fullRow ? '100%' : '75%'};
    padding: ${props => props.theme.spacing.md} 0;
`;
FormRow.displayName = 'FormRow';

const EmailRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${props => props.theme.spacing.md} 0;
    border-top: 1px solid ${props => props.theme.colors.lightGray};

    &:last-child {
        border-bottom: 1px solid ${props => props.theme.colors.lightGray};
    }
`;
EmailRow.displayName = 'EmailRow';

const RemoveIcon = styled(Svg)`
    fill: ${props => props.theme.colors.darkGray};
    font-size: ${props => props.theme.font.size.xxs};
    margin: 0 ${props => props.theme.spacing.xs};
    cursor: pointer;
`;
RemoveIcon.displayName = 'RemoveIcon';

const SensorAlarmEmailsForm = props => {
    const {
        t,
        emails,
        onSubmit,
        onClose,
        onChange,
    } = props;

    const [initialValue] = useState(emails);

    return (
        <DialogModal isActive onOverlayClick={ () => onClose(initialValue) } t={ t } footer={
            <DialogFooter>
                <Button cancel data-test-id="CloseButton" onClick={ () => onClose(initialValue) }>
                    { t('Close') }
                </Button>
                <Button submit data-test-id="EmailSubmitButton" onClick={ onSubmit }>{t('Set')}</Button>
            </DialogFooter>
        }>
            <h4>{ t('Alarm Mailing List') }</h4>
            <FormRow data-test-id="NewRecipientRow">
                <InputLabel text={ t('New Recipient') } />
                <InputWithButton
                    placeholder={ t('Email address') }
                    buttonText={ t('Add') }
                    onSubmit={ added => onChange(concat(emails, [added])) }
                />
            </FormRow>
            <FormRow data-test-id="RecipientsRow" fullRow>
                <InputLabel text={t('Recipients')} />
                { emails.map(email =>
                    <EmailRow data-test-id="EmailRow" key={ email }>
                        { email }
                        <RemoveIcon
                            data-test-id="RemoveIcon"
                            name="remove"
                            onClick={() => onChange(without(emails, email))}
                        />
                    </EmailRow>)
                }
            </FormRow>
        </DialogModal>
    );
};

SensorAlarmEmailsForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    emails: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SensorAlarmEmailsForm;
