import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import translations from 'decorators/Translations/translations';

import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import MultiInputRow from 'components/Form/MultiInputRow';
import DialogModal from 'components/Dialog/DialogModal';
import DialogFooter from 'components/Dialog/DialogFooter';

import { loadSensorHierarchies } from 'redux/modules/customer/sensorHierarchy.js';
import { upsertAllSensorMeta, deleteSensorMeta, sensorMetaForSensor } from 'redux/modules/index.js';
import { getBuildingSensorsFromHierarchy } from 'utils/Data/sensorHierarchy';

const Content = styled.div`
    display: flex;
    flex-direction: column;
`;
Content.displayName = 'Content';

const Header = styled.h3`
    margin-bottom: ${props => props.theme.spacing.md};
    font-weight: ${props => props.theme.font.weight.bold};
`;
Header.displayName = 'Header';

const SubHeader = styled.div`
    width: 100%;
    padding: 0.5em 0;
    border-bottom: 1px solid ${props => props.theme.colors.mystic};
    color: ${props => props.theme.colors.rockBlue};
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.font.size.xxs};
    font-weight: ${props => props.theme.font.weight.bold};
    letter-spacing: 1px;
    text-transform: uppercase;
`;
SubHeader.displayName = 'SubHeader';

const NoDataText = styled.p`
    font-size: ${props => props.theme.font.size.md};
    color: ${props => props.theme.colors.radicalRed};
`;
NoDataText.displayName = 'NoDataText';


const metaProperty = 'sensorMeta';
const metaKey = 'metaKey';
const metaValue = 'value';
const minMetaKey = 'h:min';
const maxMetaKey = 'h:max';

export class EditSensorMeta extends Component {
    state = {
        error: '',
        loading: false,
        model: {},
        sensor: null,
        removed: []
    };

    async componentDidMount() {
        const sensor = _.find(this.props.sensors, { id: this.props.editId });
        if (sensor) {
            this.setState({ sensor });
            if (!sensor.sensorMeta) { // load meta if needed
                await this.props.loadMetaForSensor(sensor.id);
            }
            const meta = _.cloneDeep(!sensor.sensorMeta ? this.props.meta[sensor.id] : sensor.sensorMeta);

            // Remove min and max (to move them as first) + create them if they are missing
            const minMax = _.remove(meta, item => item[metaKey] === minMetaKey || item[metaKey] === maxMetaKey);
            if (!_.find(minMax, { [metaKey]: minMetaKey })) {
                minMax.push({ [metaKey]: minMetaKey });
            }
            if (!_.find(minMax, { [metaKey]: maxMetaKey })) {
                minMax.push({ [metaKey]: maxMetaKey });
            }

            this.setState({ model: { [metaProperty]: _.concat(_.orderBy(minMax, metaKey, 'desc'), meta) } });
        }
    }

    handleMultiInputChange = (property, value)=> {
        const model = _.cloneDeep(this.state.model);
        const newModel = _.setWith(model, property, value, Object);
        this.setState({ model: { [metaProperty]: newModel[metaProperty] } });
    };

    handleMetaAdd = e => {
        e.preventDefault();
        const model = _.cloneDeep(this.state.model);

        // Add new item to metadata array
        if (!_.isArray(model[metaProperty])) {
            model[metaProperty] = [];
        }
        model[metaProperty].push({ [metaKey]: '', [metaValue]: '' });

        this.setState({ model });
    };

    handleMetaRemove = (e, index) => {
        e.preventDefault();
        const model = _.cloneDeep(this.state.model);

        // Remove item at index (pullAt mutates the model) and concat state.removed
        const removed = _.pullAt(model[metaProperty], index).concat(this.state.removed);

        this.setState({ model: { [metaProperty]: model[metaProperty] }, removed });
    };

    handleSubmit = () => {
        const model = _.cloneDeep(this.state.model); // sensorMeta to upsert
        const removed = _.cloneDeep(this.state.removed); // sensorMeta to destroy

        // Remove empty metadata items:
        let meta = [];
        meta = _(model[metaProperty]).cloneDeep().filter(item => {
            if (item && !_.isEmpty(item[metaKey]) || !_.isEmpty(item[metaValue])) {
                return true;
            }
            return false;
        });
        // Add sensorId to all meta:
        meta = meta.map(metaItem => ({ ...metaItem, sensorId: this.state.sensor.id }));

        this.setState({ loading: true });
        Promise.all([meta.length > 0 && this.props.upsertAllSensorMeta(meta)]
            .concat(removed.map(metaItem => this.props.deleteSensorMeta(metaItem.id))))
            .then(() => {
                this.props.loadSensorHierarchies(this.props.functionalLocation)
                    .then(() => {
                        this.setState({ loading: false });
                        if (typeof this.props.onSubmit === 'function') {
                            const notification = {
                                type: 'success',
                                message: this.props.t('Sensor meta successfully updated')
                            };
                            this.props.onSubmit(null, notification);
                        }
                    });
            });
    };

    isSystemMeta = metaRow => {
        return metaRow && metaRow.type === 'system';
    };

    render() {
        const {
            t,
            onClose,
        } = this.props;
        const {
            sensor,
            model,
            loading
        } = this.state;

        if (!sensor || !sensor.id) {
            return <NoDataText>{ t('No data for sensor') }</NoDataText>;
        }

        return (
            <DialogModal isActive t={ t } onOverlayClick={ onClose } footer={
                <DialogFooter>
                    <Button cancel onClick={ onClose }>{ t('Close') }</Button>
                    <Button submit onClick={ this.handleSubmit }>
                        { loading ? <Loader color='WHITE' size='SMALL' /> : t('Save') }
                    </Button>
                </DialogFooter>
            }>
                <Content>
                    <Header>{ t('Set Sensor Meta') }</Header>
                    <SubHeader>{ t('Selected sensor')}: { sensor.name }</SubHeader>
                    <MultiInputRow
                        t={ t }
                        model={ model }
                        baseProperty={metaProperty}
                        filterProperty={ metaKey }
                        subProperties={ [metaKey, metaValue] }
                        subPropertyTitles={ { [metaKey]: t('Key'), [metaValue]: t('Value') } }
                        onPropertyChange={ this.handleMultiInputChange }
                        handleAdd={ this.handleMetaAdd }
                        handleRemove={ this.handleMetaRemove }
                        isDisabled={ this.isSystemMeta }
                    />
                </Content>
            </DialogModal>
        );
    }

}

EditSensorMeta.propTypes = {
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    sensors: PropTypes.array.isRequired,
    editId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    upsertAllSensorMeta: PropTypes.func.isRequired,
    loadSensorHierarchies: PropTypes.func.isRequired,
    deleteSensorMeta: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
    const functionalLocation = props.functionalLocation;
    const hierarchy = state.sensorHierarchy.buildingHierarchy[functionalLocation];
    let sensors = [];
    if (hierarchy && hierarchy.length > 0) {
        sensors = getBuildingSensorsFromHierarchy(_.head(hierarchy));
    }
    return {
        sensors: sensors,
        meta: state.sensorMeta.sensorMeta,
    };
};

const mapDispatchToProps = dispatch => ({
    upsertAllSensorMeta: data => dispatch(upsertAllSensorMeta(data)),
    loadSensorHierarchies: functionalLocation =>
        dispatch(loadSensorHierarchies(functionalLocation, true)),
    deleteSensorMeta: id => dispatch(deleteSensorMeta(id)),
    loadMetaForSensor: id => dispatch(sensorMetaForSensor(id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(withTheme(EditSensorMeta)));
