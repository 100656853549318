import React, { PureComponent } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import _ from 'lodash';
import moment from 'moment';
import styled, { withTheme, css, keyframes } from 'styled-components';
import { transparentize } from 'polished';
import Responsive from '../Responsive/Responsive';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import Loader from 'components/Loader/Loader.jsx';
import { tooltipFormatter, buildZones } from './utils';
import { CELSIUS } from 'utils/Data/values';

highchartsMore(Highcharts);

const delayFadeIn = keyframes`
    0%      { opacity: 0 }
    50%     { opacity: 0 }
    100%    { opacity: 1 }
`;

const fadeIn = keyframes`
    0%      { opacity: 0 }
    100%    { opacity: 1 }
`;

const StyledIoTChart = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: ${ props => props.theme.colors.darkGray };
    transition:
        top 280ms ease-out,
        width 280ms ease-in,
        height 280ms ease-out;

    ${props => props.compact && css`
        width: 18em;
        height: 12em;
    `}

    .iot-chart {
        opacity: 1;
        animation-duration: ${ props => props.delayFadeIn ? '560ms' : '280ms'};
        animation-iteration-count: 1;
        animation-name: ${ props => props.delayFadeIn ? delayFadeIn : fadeIn };
        animation-fill-mode: forwards;
    }
`;

const intersectionPoint = (l1Pt1, l1Pt2, l2Pt1, l2Pt2) => {
    // compute A B and C for the first line: AX + BY = C
    const A1 = l1Pt2.y - l1Pt1.y;
    const B1 = l1Pt1.x - l1Pt2.x;
    const C1 = A1 * l1Pt1.x + B1 * l1Pt1.y;

    // compute A B and C for the second line
    const A2 = l2Pt2.y - l2Pt1.y;
    const B2 = l2Pt1.x - l2Pt2.x;
    const C2 = A2 * l2Pt1.x + B2 * l2Pt1.y;

    const delta = A1 * B2 - A2 * B1;
    if (delta === 0) {
        return null;
    }

    return {
        x: (B2 * C1 - B1 * C2) / delta,
        y: (A1 * C2 - A2 * C1) / delta
    };
};

// From http://jsfiddle.net/2me4Z/11/
const createRangeSeries = (data1, data2, when, xAxisValues, theme) => {
    const rangeData = [];

    let lastWasGreater;
    data1.forEach((value, index) => {
        let value2 = data2[index];
        if (!value2) {
            return;
        }

        if (value2 > value) {
            if (lastWasGreater) { // lines crossed we need to add the intersection point!

                const previousValue2 = data2[index - 1];
                const point = intersectionPoint({ x: xAxisValues[index], y: value },
                    { x: xAxisValues[index - 1], y: data1[index - 1] },
                    { x: xAxisValues[index], y: value2 },
                    { x: xAxisValues[index - 1], y: previousValue2 });
                rangeData.push([point.x, point.y, point.y]);
            }
            if (when === 'GREATER_ONLY' ) { value2 = value; }
            lastWasGreater = false;
        } else if (value2 < value) {
            if (typeof lastWasGreater !== 'undefined' && !lastWasGreater) { // lines crossed we need to add the intersection point!
                const previousValue2 = data2[index - 1];
                const point = intersectionPoint({ x: xAxisValues[index], y: value },
                    { x: xAxisValues[index - 1], y: data1[index - 1] },
                    { x: xAxisValues[index], y: value2 },
                    { x: xAxisValues[index - 1], y: previousValue2 });
                point && rangeData.push([point.x, point.y, point.y]);
            }

            if (when === 'LOWER_ONLY' ) { value2 = value; }
            lastWasGreater = true;
        }
        rangeData.push([xAxisValues[index], value, value2]);
    });

    let fillColor = {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
            [0, transparentize(0.75, theme.colors.radicalRed)],
            [1, transparentize(0.95, theme.colors.radicalRed)],
        ]
    };

    if (when === 'LOWER_ONLY') {
        fillColor = {
            linearGradient: { x1: 0, y1: 1, x2: 0, y2: 0 },
            stops: [
                [0, transparentize(0.85, theme.colors.radicalRed)],
                [1, transparentize(0.95, theme.colors.radicalRed)],
            ]
        };
    }

    return {
        name: null,
        type: 'arearange',
        data: rangeData,
        lineWidth: 0,
        fillColor,
        tooltip: false,
        zIndex: 1,
        marker: {
            enabled: false,
            states: {
                hover: {
                    enabled: false
                }
            }
        },
        states: {
            inactive: {
                opacity: 1
            }
        },
        enableMouseTracking: false,
        showInLegend: false
    };
};


class IoTChart extends PureComponent {

    renderChart() {
        const { theme, trueValue, falseValue, maxLabel, minLabel, t } = this.props;
        // Use 1.) explicit width and height, or 2.) Dimensions from Responsive, or 3.) Highcharts default.

        let chartWidth = this.props.width ?
            this.props.width :
            this.props.dimensions ? this.props.dimensions.width : null;
        const chartHeight = this.props.height ?
            this.props.height :
            this.props.dimensions ? this.props.dimensions.height : null;

        if (chartWidth > window.innerWidth) {
            chartWidth = window.innerWidth;
        }

        const showLoader = this.props.loading || _.isNil(this.props.histories) || _.isNil(this.props.alarms);
        const noData = this.props.histories.length === 0;
        const showError = noData && !_.isNil(this.props.error);

        const skeletonContent = showLoader && <Loader color='BLUE' size='LARGE' />
            || showError && this.props.error
            || noData && this.props.t('No data available')
            || undefined;

        if (skeletonContent !== undefined) {
            return (
                <SkeletonChart
                    width={chartWidth ? `${chartWidth}px` : null}
                    height={chartHeight ? `${chartHeight}px` : null}>
                    { skeletonContent }
                </SkeletonChart>
            );
        }

        let minValue = _.min(this.props.histories.map(x => x.avg));
        let maxValue = _.max(this.props.histories.map(x => x.avg));

        // Relax min and max if they are equal.
        if (minValue === maxValue) {
            --minValue;
            ++maxValue;
        }

        const showAlerts = this.props.alarms;

        const offTimes = Array.isArray(this.props.activeTimes) ?
            this.props.activeTimes.map(band =>
                ({ ...band, color: transparentize(0.80, this.props.theme.colors.darkGray), zIndex: 1 })) :
            false;

        // Take the first sensor name and unit for the series name since they are all the same.
        const sensorName = _.get(this.props.sensorNames, [this.props.histories[0].sensorName])
            || this.props.histories[0].sensorName;
        const unit = this.props.unit ||
            _.get(this.props.sensorConfigurations, [this.props.histories[0].sensorName, 'unit']) ||
            '';
        const hideTime = this.props.histories[0].aggregation === 'dailyAverage';

        const seriesData = this.props.histories.map(point => [
            moment.utc(point.timestamp).seconds(0).local().valueOf(),
            point.avg === undefined ? point.value : point.avg
        ]);

        const series = [{
            zones: buildZones(seriesData),
            zoneAxis: 'x',
            connectNulls: true,
            name: sensorName,
            type: this.props.type || 'line',
            data: seriesData,
            yAxis: 0,
            zIndex: 2,
            color: this.props.theme.colors.midnight,
            unit,
            singleLine: true,
            hideTime,
            tooltipDateFormatter: this.props.tooltipDateFormatter
        }];

        const minMaxLine = {
            color: theme.colors.radicalRed,
            type: 'line',
            dashStyle: 'Dash',
            lineWidth: 1,
            zIndex: 2,
            marker: {
                enabled: false,
                states: {
                    hover: {
                        enabled: false
                    }
                }
            },
            tooltip: false
        };

        const dataLabels = {
            enabled: true,
            useHTML: true,
            padding: 0
        };
        const labelStyle = `
            color: ${theme.colors.white};
            background-color: ${theme.colors.radicalRed};
            font-size: ${theme.font.size.xxxs};
            padding: 0 5px;
        `;

        const xAxisValues = this.props.histories.map(p => moment.utc(p.timestamp).seconds(0).local().valueOf());
        if (this.props.maxHistories) {
            const historiesWithValues = _.filter(this.props.maxHistories, point => !_.isNil(point.value));
            const lineData = historiesWithValues.map((point, index) => {
                if (index === historiesWithValues.length - 1) {
                    return {
                        x: moment.utc(point.timestamp).seconds(0).local().valueOf(),
                        y: point.value,
                        dataLabels: {
                            ...dataLabels,
                            y: -5,
                            formatter: function () {
                                return `<span style="${labelStyle}">${maxLabel || t('MAX')}</span>`;
                            }
                        }
                    };
                }
                return [moment.utc(point.timestamp).seconds(0).local().valueOf(), point.value];
            });
            const maxLine = { ...minMaxLine, data: lineData };

            const WHEN = 'GREATER_ONLY';
            const data = this.props.histories.map(p => p.avg || p.value);

            const maxData = this.props.maxHistories.map(p => p.value);
            const maxArea = createRangeSeries(data, maxData, WHEN, xAxisValues, theme);
            series.push(maxArea);
            series.push(maxLine);
        }

        if (this.props.minHistories) {
            const historiesWithValues = _.filter(this.props.minHistories, point => !_.isNil(point.value));
            const lineData = historiesWithValues.map((point, index) => {
                if (index === historiesWithValues.length - 1) {
                    return {
                        x: moment.utc(point.timestamp).local().valueOf(),
                        y: point.value,
                        dataLabels: {
                            ...dataLabels,
                            y: 20,
                            formatter: function () {
                                return `<span style="${labelStyle}">${minLabel || t('MIN')}</span>`;
                            }
                        }
                    };
                }
                return [moment.utc(point.timestamp).local().valueOf(), point.value];
            });

            const minLine = { ...minMaxLine, data: lineData };
            const WHEN = 'LOWER_ONLY';
            const data = this.props.histories.map(p => p.avg || p.value);

            const minData = this.props.minHistories.map(p => p.value);
            const minArea = createRangeSeries(data, minData, WHEN, xAxisValues, theme);
            series.push(minArea);
            series.push(minLine);
        }

        if (showAlerts) {
            const alertPlot = {
                type: 'scatter',
                data: this.props.alarms,
                zIndex: 3,
                marker: {
                    fillColor: 'red',
                    radius: this.props.compact ? 2 : 4
                }
            };
            series.push(alertPlot);
        }

        const config = {
            chart: {
                zoomType: this.props.compact || this.props.noZoom ? undefined : 'x', // Allow zooming of x-axis.
                panning: !this.props.compact, // Allow panning on x-axis.
                panKey: 'meta', // Use the command key on Mac and Windows key on Windows for panning charts.
                backgroundColor: 'transparent',
                plotBackgroundColor: this.props.theme.colors.white,
                spacing: this.props.compact ? false : [8, 8, 16, 8],
                width: chartWidth,
                height: chartHeight,
                className: 'iot-chart',
                marginTop: this.props.compact ? 20 : 60,
                marginRight: 10,
                spacingLeft: 20,
                spacingBottom: 20
            },
            title: false,
            credits: false,
            tooltip: !this.props.compact ? {
                split: true,
                shared: false,
                crosshairs: true,
                borderWidth: 0,
                padding: 0,
                backgroundColor: null,
                useHTML: true,
                formatter: function() {
                    return tooltipFormatter(
                        this.points,
                        this.x,
                        theme,
                        chartHeight - 50,
                        'no temperature',
                        trueValue,
                        falseValue
                    );
                }
            } : false,
            plotOptions: {
                line: {
                    lineWidth: this.props.compact ? 1 : 2,
                    marker: {
                        enabled: false,
                        lineColor: undefined,
                        lineWidth: 2,
                        radius: 5,
                        fillColor: this.props.theme.colors.white,
                        symbol: 'circle',
                        states: {
                            hover: {
                                lineWidthPlus: 0,
                                radiusPlus: 0,
                                shadow: false,
                                animation: { duration: 0 }
                            }
                        }
                    },
                    states: {
                        hover: {
                            lineWidthPlus: 0,
                            halo: { size: 0 }
                        },
                        inactive: {
                            opacity: 1
                        }
                    }
                },
                spline: {
                    lineWidth: this.props.compact ? 1 : 2,
                    marker: {
                        enabled: false,
                        lineColor: undefined,
                        lineWidth: 2,
                        radius: 5,
                        fillColor: this.props.theme.colors.white,
                        symbol: 'circle',
                        states: {
                            hover: {
                                lineWidthPlus: 0,
                                radiusPlus: 0,
                                shadow: false,
                                animation: { duration: 0 }
                            }
                        }
                    },
                    states: {
                        hover: {
                            lineWidthPlus: 0,
                            halo: { size: 0 }
                        },
                        inactive: {
                            opacity: 1
                        }
                    }
                },
                area: {
                    color: this.props.theme.colors.transparent,
                    fillColor: this.props.theme.colors.midnight,
                    step: 'left',
                    connectNulls: true,
                    trackByArea: true,
                    marker: {
                        enabled: false,
                    }
                }
            },
            legend: false,
            xAxis: {
                type: 'datetime',
                lineWidth: this.props.compact ? 1 : 0,
                lineColor: this.props.theme.colors.mystic,
                minorGridLineWidth: 0,
                gridLineWidth: 0,
                tickLength: 0,
                gridLineColor: !this.props.compact ? this.props.theme.colors.mystic : 'transparent',
                labels: {
                    style: {
                        color: this.props.theme.colors.darkGray,
                        fontWeight: this.props.theme.font.weight.bold,
                        textTransform: 'uppercase',
                        letterSpacing: '1px'
                    },
                    format: this.props.xAxisLabelFormat
                },
                plotBands: this.props.compact ? false : offTimes,
                minRange: this.props.minXRange,
                max: this.props.maxX
            },
            yAxis: {
                min: this.props.yMin,
                max: this.props.yMax,
                lineWidth: this.props.compact ? 1 : 0,
                lineColor: this.props.theme.colors.mystic,
                labels: !this.props.compact ? {
                    format: this.props.yAxisLabelFormat || (unit === CELSIUS ? `{value:.1f}` : `{value:.2f}`),
                    style: {
                        color: this.props.theme.colors.darkGray,
                        fontWeight: this.props.theme.font.weight.bold,
                    },
                    enabled: this.props.type !== 'area',
                } : {
                    format: this.props.yAxisLabelFormat || `{value:.2f}`,
                    formatter: function() {
                        if (this.isFirst || this.isLast) {
                            return this.axis.defaultLabelFormatter.call(this);
                        }
                    },
                    style: {
                        color: this.props.theme.colors.blue
                    },
                    enabled: this.props.type !== 'area',
                },
                title: !this.props.compact ? {
                    align: this.props.yAxisVerticalTitle ? 'middle' : 'high',
                    offset: !this.props.yAxisVerticalTitle ? 0 : undefined,
                    text: this.props.yAxisTitle || unit,
                    rotation: this.props.yAxisVerticalTitle ? 270 : 0,
                    y: this.props.yAxisVerticalTitle ? 0 : -20,
                    style: {
                        color: this.props.theme.colors.black,
                        fontWeight: this.props.theme.font.weight.bold
                    },
                } : false,
                minorGridLineWidth: 0,
                gridLineWidth: 1,
                gridLineColor: !this.props.compact ? this.props.theme.colors.mystic : 'transparent',
                allowDecimals: !this.props.hideDecimals,
                tickInterval: unit === CELSIUS ? 1 : undefined,
                minRange: unit === CELSIUS ? 5 : undefined,
            },
            series: series
        };

        return (
            <HighchartsReact
                highcharts={ Highcharts }
                options={ config }
                ref="IoTChart"
            />
        );
    }

    render() {
        return (
            <StyledIoTChart
                data-test-id={`StyledIoTChart-compact-${this.props.compact}`}
                compact={ this.props.compact }
                delayFadeIn={ this.props.delayFadeIn }>
                { this.renderChart() }
            </StyledIoTChart>
        );
    }
}

export default Responsive(withTheme(IoTChart));
