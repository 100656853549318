import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import queryString from 'query-string';
import * as JsSearch from 'js-search';
import translations from 'decorators/Translations/translations';
import styled, { withTheme } from 'styled-components';

import StandardPage from 'components/StandardPage/StandardPage.jsx';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import Header from 'containers/Application/Header/Header.jsx';
import KPIModule from './KPIModule/KPIModule';

import { InputSearch } from 'components/index.js';
import Hero from 'components/Hero/Hero';
import { loadPartnerOverview } from 'redux/modules/containers/partner-overview.js';
import SortableFLTable from 'components/SortableFunctionalLocationTable/SortableFLTable';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage';
import { getPartnerNumbers } from 'utils/profile';
import FadeTransition from 'components/FadeTransition/FadeTransition';
import { getPortfolioLinks, getPortfolioImage } from 'utils/Data/partners';
import {
    addCustomerAdminLink,
    addNewServiceRequestCustomerLink,
    addContactCaverionCustomerLink,
} from 'utils/Data/functionalLocations';
import { FEATURE_TO_TAB } from 'utils/Data/features';
import { showContactCaverion } from 'containers/Application/Modules/ContactModule/utils';
import { createSelector } from 'reselect';
import { performanceColors, getPerformanceStatus } from 'utils/Data/performance';
import { performanceSourceLabels } from 'constants/performance';

const getSortedFunctionalLocations = (
    functionalLocations,
    topLevelPermissions,
    profile,
    partnerNumber,
    defaultFeature,
    performanceByFL,
) => {
    const partnerPermissions = profile.partnerPermissions || [];
    const topLevelFunctionalLocations = _.values(functionalLocations).filter(functionalLocation => {
        // If user has partner permissions, show building level FLs and tenants with direct partner.
        if (functionalLocation.partnerNumberWithParents.some(partner => _.includes(partnerPermissions, partner))) {
            return functionalLocation.type === 'BU'
                || _.includes(functionalLocation.partnerNumber, partnerNumber) && functionalLocation.type === 'UN';
        }

        // Otherwise show FLs the user has direct permissions to
        return _.includes(topLevelPermissions, functionalLocation.functionalLocation);
    });

    let tab = FEATURE_TO_TAB[defaultFeature];
    if (_.isArray(tab)) {
        tab = tab[0];
    }

    return topLevelFunctionalLocations
        // Filter out functional locations that are not related to selected partner
        .filter(functionalLocation => partnerNumber === 'all' ||
            _.includes(functionalLocation.partnerNumberWithParents, partnerNumber))
        .map(functionalLocation => ({
            functionalLocation: functionalLocation.functionalLocation,
            description: functionalLocation.description,
            name: functionalLocation.name,
            key: functionalLocation.key,
            type: functionalLocation.type,
            address: `${functionalLocation.address || ''} ${functionalLocation.city || ''}`,
            tab: functionalLocation.type === 'BU' || functionalLocation.type === 'UN' ? tab : undefined,
            performance: performanceByFL ?
                _.get(performanceByFL, functionalLocation.functionalLocation, null) :
                undefined
        }));
};

const Content = styled.div`
    ${props => props.theme.media.landscape`
        margin: ${props => props.theme.spacing.lg} 0;
    `};
`;

const InputSearchContainer = styled.div`
    width: 100%;
    ${props => props.theme.media.landscape`
        width: auto;
        min-width: 18rem;
    `}

    & > div {
        width: 100%;
    }
`;

class PartnerOverview extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired
    };

    state = {
        searchResults: null,
        partnerImage: ''
    };

    componentDidUpdate(prevProps) {
        const { match: { params: { partnerNumber } }, history } = this.props;
        if (partnerNumber !== prevProps.match.params.partnerNumber) {
            this.props.loadPartnerOverview(partnerNumber);
            this.setState({ searchResults: null }, () => {
                history.push(`/${partnerNumber}/Overview`);
            });
            this.flSearch = this.initSearch();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state);
    }

    componentDidMount() {
        const {
            match: {
                params: {
                    partnerNumber
                }
            }
        } = this.props;
        this.props.loadPartnerOverview(partnerNumber);
        this.flSearch = this.initSearch();
    }

    initSearch = () => {
        const flSearch = new JsSearch.Search('functionalLocation');
        flSearch.addIndex('functionalLocation');
        flSearch.addIndex('name');
        flSearch.addIndex('description');
        flSearch.addIndex('address');
        return flSearch;
    };

    render() {
        const {
            t, loading, loadingImage, error,
            match: { params: { partnerNumber } },
            profile: { topLevelPermissions, profile },
            features,
            customers,
            history,
            functionalLocations,
            location,
            customViewsByCustomer,
            disabledCustomViews,
            featureTeasers,
            defaultFeature,
            theme,
            partnerMeta,
            performance,
        } = this.props;

        if (error || !partnerNumber
            || partnerNumber !== 'all' && !_.includes(getPartnerNumbers(profile), partnerNumber)) {
            return <ErrorPage type="partner" />;
        }

        let sortedFunctionalLocations = getSortedFunctionalLocations(
            functionalLocations, topLevelPermissions, profile, partnerNumber, defaultFeature, performance
        );

        if (this.flSearch) {
            this.flSearch.addDocuments(sortedFunctionalLocations);
        }
        sortedFunctionalLocations = this.state.searchResults || sortedFunctionalLocations;

        const partnerImage = getPortfolioImage(this.props.partnerImages, partnerNumber) || `${window.location.origin}/clientnet_background.jpg`;
        const page = parseInt(queryString.parse(this.props.location.search).page, 10) || 1;
        const partner = _.find(customers, customer => customer.partnerNumber === partnerNumber);

        const showContactCaverionLink = showContactCaverion(partnerMeta[partnerNumber]
            && partnerMeta[partnerNumber].meta, profile);

        const links = getPortfolioLinks(partnerNumber, features, location.search,
            customViewsByCustomer[partnerNumber], disabledCustomViews, featureTeasers);

        let linksMobile = _.clone(links);
        linksMobile = addCustomerAdminLink(linksMobile, partnerNumber, profile);
        linksMobile = addNewServiceRequestCustomerLink(linksMobile, partnerNumber, features);
        linksMobile = addContactCaverionCustomerLink(linksMobile, partnerNumber, showContactCaverionLink);

        // Show loading if partner is selected but not yet loaded (or images are not loaded yet)
        const loadingContext = loading || loadingImage || partnerNumber && partnerNumber !== 'all' && !partner;
        return (
            <FadeTransition>
                <StandardPage key="overview" disableScrollToTop>
                    <Helmet title={t('Overview')} />
                    <Header t={ t } selected="overview" showPartnerSelect links={ links } linksMobile={ linksMobile } />
                    <Hero
                        title={ partner && partner.name || t('All Customers') }
                        t={ t }
                        heroImage={ partnerImage }
                        isOverview
                        profile={ profile }
                        partnerNumber={ partnerNumber }
                        loadingContext={ loadingContext }
                        type="OVERVIEW"
                        showNewServiceRequest={ features && features.sr }
                        showContactCaverion={ showContactCaverionLink }
                    />
                    { partnerNumber !== 'all' &&
                        <KPIModule
                            t={ t }
                            theme={ theme }
                            partnerNumber={ partnerNumber }
                            history={ history }
                        />
                    }
                    <Content>
                        <Section>
                            <SectionHeader noBorder title={ t('Portfolio') } t={ t }>
                                <InputSearchContainer>
                                    <InputSearch
                                        id="fl-search-input"
                                        onChange={ value => {
                                            const result = this.flSearch.search(value);
                                            this.setState({ searchResults: result });

                                            // Reset possible paging after search
                                            history.push(`/${partnerNumber}/Overview`);
                                        } }
                                        placeholder={ t('Filter by Name or Address') }
                                        onClear={() => this.setState({ searchResults: null })}
                                    />
                                </InputSearchContainer>
                            </SectionHeader>
                            <SortableFLTable
                                loading={ loading }
                                t={ t }
                                sortedFunctionalLocations={ sortedFunctionalLocations }
                                partnerNumber={ partnerNumber }
                                page={ page }
                                showPerformance={ !!profile.buildingPerformanceSource }
                            />
                        </Section>
                    </Content>
                </StandardPage>
            </FadeTransition>
        );
    }
}

const getAirQualityPerformance = createSelector(
    state => state.values.airQualityKPI,
    (_, theme) => theme,
    (kpi, theme) => {
        const values = _.get(kpi, 'data.moving7dAverage', {});
        return _.mapValues(values, value => typeof value === 'number' && {
            value,
            formattedValue: value.toFixed(1),
            unit: '%',
            color: performanceColors({ theme })[getPerformanceStatus(value, true)],
            infotip: performanceSourceLabels.indoorAirQuality,
        });
    }
);

const getEnergyRatingPerformance = createSelector(
    state => state.values.energyRating,
    (_, theme) => theme,
    (energyRating, theme) => _.mapValues(energyRating.data || {}, value => value.latest && {
        value: value.latest,
        formattedValue: value.latest.toFixed(1),
        unit: 'KWh',
        color: theme.colors.rockBlue,
        infotip: performanceSourceLabels.energy,
    })
);

const getEnergyConsumptionPerformance = createSelector(
    state => state.values.consumptionKPIs.electricity_main,
    state => state.values.consumptionKPIs.district_heating,
    (_, theme) => theme,
    (electricity, districtHeating, theme) => {
        if (
            !electricity || electricity.loading || electricity.error ||
            !districtHeating || districtHeating.loading || districtHeating.error
        ) {
            return {};
        }

        const sums = _.mergeWith({}, electricity.data.byFL, districtHeating.data.byFL, (lhs, rhs) => (lhs || 0) + rhs);
        return _.mapValues(sums, value => ({
            value,
            formattedValue: (value / 1000).toFixed(0),
            unit: 'MWh',
            color: theme.colors.rockBlue,
            infotip: performanceSourceLabels.energyConsumption,
        }));
    }
);

const getAlarmPerformance = createSelector(
    (state, props) => state.alarm.performance[props.match.params.partnerNumber],
    (_, props) => props.theme,
    (performance, theme) => {
        if (!performance || !performance.data) {
            return {};
        }

        return _.fromPairs(
            _.entries(performance.data)
                .filter(([, value]) => !_.isNil(value) && !_.isNil(value.performance))
                .map(([fl, value]) => [fl, {
                    value: value.performance,
                    formattedValue: value.performance.toFixed(0),
                    unit: '%',
                    color: performanceColors({ theme })[getPerformanceStatus(value.performance)],
                    infotip: performanceSourceLabels.alarms,
                }])
        );
    }
);

const getObservationPerformance = createSelector(
    (state, props) => state.notice.observationPerformance[props.match.params.partnerNumber],
    (_, props) => props.theme,
    (performance, theme) => {
        if (!performance || !performance.data) {
            return {};
        }

        return _.fromPairs(
            _.entries(performance.data)
                .filter(([, value]) => !_.isNil(value))
                .map(([fl, value]) => [fl, {
                    value,
                    formattedValue: value.toFixed(0),
                    unit: '%',
                    color: performanceColors({ theme })[getPerformanceStatus(value)],
                    infotip: performanceSourceLabels.observations,
                }])
        );
    }
);

const getPerformanceValues = (state, props) => {
    const profile = state.profile.profile;
    const { theme } = props;

    let data;
    switch (profile.buildingPerformanceSource) {
    case 'indoorAirQuality':
        data = getAirQualityPerformance(state, theme);
        break;
    case 'energy':
        data = getEnergyRatingPerformance(state, theme);
        break;
    case 'energyConsumption':
        data = getEnergyConsumptionPerformance(state, theme);
        break;
    case 'alarms':
        data = getAlarmPerformance(state, props);
        break;
    case 'observations':
        data = getObservationPerformance(state, props);
        break;
    default:
        break;
    }

    return !_.isEmpty(data) ? data : undefined;
};

const mapStateToProps = (state, props) => ({
    customViewsByCustomer: state.customView.customViewsByCustomer,
    loading: state.partnerOverview.loading,
    loadingImage: state.partnerImage.loading,
    error: state.partnerOverview.error,
    profile: state.profile,
    features: state.profile.profile.syntheticFeatures,
    featureTeasers: state.profile.profile.featureTeasers,
    defaultFeature: state.profile.profile.defaultFeature,
    customers: state.customer.customers,
    partnerImages: state.partnerImage.byPartner,
    functionalLocations: state.functionalLocations.functionalLocations,
    disabledCustomViews: state.profile.profile.disabledCustomViews || [],
    partnerMeta: state.partnerMeta.meta,
    performance: getPerformanceValues(state, props),
});

const mapDispatchToProps = dispatch => ({
    loadPartnerOverview: partnerNumber => dispatch(loadPartnerOverview(partnerNumber)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withTheme(withRouter(connector(translations(PartnerOverview))));
