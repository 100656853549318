import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';

const StyledDateColumn = styled.div`
    color: ${props => props.theme.colors.darkGray};
    font-size: ${props => props.theme.fontSize.xxs};
    font-weight: ${props => props.theme.fontWeight.normal};
`;
StyledDateColumn.displayName = 'StyledDateColumn';

const DateColumn = ({ data, isPlannedDate }) => {
    if (!data || !data.date) {
        return null;
    }
    const date = moment.utc(data.date);
    if (!isPlannedDate) {
        date.local();
    }

    const eventDate = date.format('L');
    const eventTime = date.format('LT');

    return (
        <StyledDateColumn>
            <span>{ eventDate }</span>{!isPlannedDate && <span>, {eventTime}</span>}
        </StyledDateColumn>
    );
};

DateColumn.defaultProps = {
    isPlannedDate: false
};

DateColumn.propTypes = {
    data: PropTypes.object.isRequired,
    isPlannedDate: PropTypes.bool
};

export default DateColumn;
