import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import QueryLink from 'components/QueryLink/QueryLink.jsx';
import Svg from 'components/Svg/Svg.jsx';
import _ from 'lodash';

const Tooltip = styled.div`
    position: fixed;
    display: ${props => props.display};
    left: ${props => props.extended ? '150px' : '75px'};
    top: ${props => props.offsetTop + 'px'};
    background-color: ${props => props.theme.colors.white};
    box-shadow: ${props => `0 2px 5px ${rgba(props.theme.colors.black, 0.10)}`};
    border-radius: 2px;
    color: ${props => props.theme.colors.black};
    padding: ${props => props.theme.spacing.xs};
    font-size: ${props => props.theme.fontSize.xxs};
    line-height: ${props => props.theme.fontSize.xs};
    max-width: 350px;
    white-space: pre-line;

    &::before {
        content: '';
        position: absolute;
        margin-left: -0.6em;
        z-index: 2;
        line-height: 0;
        border-left: 0.5em solid transparent;
        border-bottom: 0.5em solid transparent;
        border-top: 0.5em solid transparent;
        border-right: ${props => `0.5em solid ${rgba(props.theme.colors.black, 0.04)}`};
        left: -0.45em;
        top: calc(50% - 0.5em);
    }

    &::after {
        content: '';
        position: absolute;
        margin-left: -0.5em;
        z-index: 2;
        line-height: 0;
        border-left: 0.5em solid transparent;
        border-bottom: 0.5em solid transparent;
        border-top: 0.5em solid transparent;
        border-right: 0.5em solid ${props => props.theme.colors.white};
        left: -0.45em;
        top: calc(50% - 0.5em);
    }
`;

const Bold = styled.span`
    font-weight: ${props => props.theme.font.weight.bold};
`;

const IconContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    min-width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color: ${props => props.selected && rgba(props.theme.colors.cerulean, 0.10)};
`;

const Label = styled.span`
    position: relative;
    font-size: ${props => props.theme.fontSize.xxs};

    ${props => props.contextLink && css`
        border-bottom: 1px solid ${props => props.theme.colors.alabaster};
        margin-bottom: ${props => props.theme.spacing.md};
        margin-top: -${props => props.theme.spacing.md};
    `}

    ${props => props.theme.media.landscape`
        font-size: ${props => props.theme.fontSize.xs};
    `}

    &:hover ${IconContainer} {
        background-color: ${props => !props.disabled && rgba(props.theme.colors.cerulean, 0.10)};
    }
`;

const linkStyles = css`
    color: ${props => props.disabled ? props.theme.colors.rockBlue : props.theme.colors.black};
    font-weight: ${props => props.selected && props.theme.font.weight.bold};
    padding: ${props => `${props.theme.spacing.xxs} ${props.theme.spacing.md}`};
    cursor: ${props => props.disabled || props.selected ? 'default' : 'pointer'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
`;

const Link = styled(QueryLink)`
    ${linkStyles}
`;
Link.displayName = 'Link';

const ExternalLink = styled.a`
    ${linkStyles}
`;
ExternalLink.displayName = 'ExternalLink';

const Icon = styled(({ small, ...props }) => <Svg {...props} />)`
    font-size: ${props => props.small ? props.theme.fontSize.md : props.theme.fontSize.xl};
    fill: ${props => props.disabled ? props.theme.colors.rockBlue : props.theme.colors.midnight};
`;

Icon.displayName = 'Icon';

const Text = styled.span`
    opacity: ${props => props.extended ? 1 : 0};
    transition: opacity 0.3s;
    margin: 0 ${props => props.theme.spacing.sm};
    text-overflow: ellipsis;
    max-width: 80%;
    overflow: hidden;
    line-height: normal;
`;

Text.displayName = 'Text';

const Arrow = styled.span`
    opacity: ${props => props.extended ? 1 : 0};
    transition: opacity 0.3s;
`;

const featureTeaserTexts = {
    'Energy Optimization': 'allows users to view summed up Energy Rating and compare energy efficiency between ' +
        'buildings through benchmarking. Contact your Caverion contact person to activate the feature.',
    'Documents': 'provides users easy access to portfolio and building level documents. Users can manage folders ' +
        'and easily upload new documents. Contact your Caverion contact person to activate the feature.',
    'Conditions': 'shows a complete performance list of sensor and device groups attached to building or equipment. ' +
        'Users may set email alarms when defined thresholds are exceeded. Contact your Caverion contact person to ' +
        'activate the feature.',
    'Energy': 'view gives detailed information about building’s energy consumption and efficiency. Contact your ' +
        'Caverion contact person to activate the feature.',
    'Control Room': 'consists of three views: Alarms, Observations and Inspections. Contact your Caverion contact ' +
        'person to activate the feature.',
    'Building': 'gives an overview of operational performance of the building. Contact your Caverion contact person ' +
        'to activate the feature.',
    'Floors': 'gives an easy-to-use display for sensor values per floor, area or room on top of a floor plan image. ' +
        'Contact your Caverion contact person to activate the feature.',
    'Cleaning': 'lets user monitor when a building, floor or area has been cleaned during the last 7 days. Contact ' +
        'your Caverion contact person to activate the feature.',
    'Technical': 'displays a complete architecture tree of a building and it’s disciplines and equipments. Contact ' +
        'your Caverion contact person to activate the feature.',
    'Equipment': 'shows all equipments attached to the current Location, Discipline or Equipment. Contact your ' +
        'Caverion contact person to activate the feature.',
    'External Documents': 'lists all M-files documents attached to the current Location. Contact your Caverion ' +
        'contact person to activate the feature.',
    'News': 'is a platform for creating and managing news or announcements for a building. Contact your Caverion ' +
        'contact person to activate the feature.'
};

const tooltipInitialStyles = { display: 'none' };

const NaviItem = props => {
    const {
        t,
        id,
        title,
        icon,
        disabled,
        count,
        loading,
        to,
        selected,
        extended,
        smallIcon,
        contextLink,
        teaser,
        external
    } = props;

    const [toolTipStyles, setTooltipStyles] = useState(tooltipInitialStyles);
    let iconRef = null;

    /**
     * Gets icon bounding client rect
     */
    const getIconPosition = () => {
        if (iconRef) {
            const element = ReactDOM.findDOMNode(iconRef);
            if (element) {
                return element.getBoundingClientRect();
            }
        }
        return null;
    };

    /**
     * Sets infotip positioning styles to state
     */
    const setInfoTipPosition = bounds => {
        setTooltipStyles({
            top: bounds.top + (teaser ? -12 : 8),
            display: 'block'
        });
    };

    const handleInfoTipMouseOver = () => {
        const bounds = getIconPosition();
        if (bounds) {
            setInfoTipPosition(bounds);
        }
    };

    const LinkComponent = external ? ExternalLink : Link;
    const key = id ? id : title.toLowerCase();

    return (
        <Label
            data-test-id={`${ title }${ loading ? '-loading' : '' }${ disabled ? '-disabled' : '' }`}
            key={ `${key}-label` }
            selected={ selected }
            disabled={ disabled }
            extended={ extended }
            contextLink={ contextLink }
        >
            <LinkComponent
                selected={ selected }
                disabled={ disabled }
                to={ to }
                href={ external ? to.pathname : undefined }
                target={ external ? '_blank' : undefined }
                rel={ external ? 'noopener noreferrer' : undefined }
                onClick={ e => disabled && e.preventDefault() }
                onMouseOver={ () => handleInfoTipMouseOver() }
                onMouseOut={ () => setTooltipStyles(tooltipInitialStyles) }
            >
                <IconContainer
                    selected={ selected }
                    ref={ el => { iconRef = el; } }
                >
                    <Icon name={ icon } selected={ selected } disabled={ disabled } small={ smallIcon }/>
                    { !extended && !teaser &&
                        <Tooltip display={ toolTipStyles.display } offsetTop={ toolTipStyles.top }>
                            { t(title) }
                        </Tooltip> }
                    { teaser &&
                        <Tooltip
                            extended={ extended }
                            display={ toolTipStyles.display }
                            offsetTop={ toolTipStyles.top }
                        >
                            <Bold>{ t(title) } </Bold>
                            { t(featureTeaserTexts[title]) }
                        </Tooltip> }
                </IconContainer>
                <Text extended={ extended }>
                    { `${t(title)}${loading ? ' (...)' : _.isNumber(count) ? ` (${count})` : ''}` }
                </Text>
                { selected && <Arrow extended={ extended }>&#8594;</Arrow> }
            </LinkComponent>
        </Label>
    );
};

NaviItem.propTypes = {
    t: PropTypes.func.isRequired,
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    to: PropTypes.object.isRequired,
    extended: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    count: PropTypes.number,
    loading: PropTypes.bool,
    smallIcon: PropTypes.bool,
    contextLink: PropTypes.bool,
    teaser: PropTypes.bool,
    external: PropTypes.bool
};

NaviItem.defaultProps = {
    disabled: false,
    selected: false,
    loading: false,
    smallIcon: false,
    contextLink: false,
    teaser: false,
    external: false
};

export default React.memo(NaviItem);
