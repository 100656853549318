import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MenuToggle from 'components/MenuToggle/MenuToggle';
import { toggleScroll } from 'utils/Modal/toggleScroll';
import FeedbackForm from './FeedbackForm';
import InfoBox from 'components/InfoBox/InfoBox';


const FeedbackPanelContainer = styled.div`
    position: fixed;
    pointer-events: auto;
    background: ${props => props.theme.colors.white};
    width: ${props => props.active ? '100%' : 0};
    ${props => props.theme.media.portrait`
        width: ${props => props.active ? '400px' : 0};
    `};
    box-shadow: ${props => props.theme.shadows.opiCard};
    transition: width ${props => props.theme.navigation.transition};
    top: 0;
    right: 0;
    bottom: 0;
    z-index: ${props => props.theme.zIndex('feedback')};
`;
FeedbackPanelContainer.displayName = 'FeedbackPanelContainer';

const MenuToggleContainer = styled.div`
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    font-size: ${props => props.theme.font.size.lg};
    height: ${props => props.theme.navigation.height};
    background-color: ${props => props.theme.colors.midnight};
    align-items: center;
`;
MenuToggleContainer.displayName = 'MenuToggleContainer';

const FeedbackContent = styled.div`
    padding: ${props => props.theme.spacing.md} ${props => props.theme.spacing.xl} ${props => props.theme.spacing.xl};
    height: 100%;
    overflow-y: auto;
    opacity: ${props => props.active ? 1 : 0};
    transition: ${props => props.active ? 'opacity 0.3s' : 'none'};
    transition-delay: ${props => props.active && '0.3s'};
`;
FeedbackContent.displayName = 'FeedbackContent';

const FeedbackHeading = styled.div`
    font-size: ${props => props.theme.font.size.lg};
    font-family: ${props => props.theme.font.family.sanchez};
    line-height: ${props => props.theme.font.lineHeight.lg};
`;
FeedbackHeading.displayName = 'FeedbackHeading';

const FeedbackHeadingExtra = styled.div`
    font-size: ${props => props.theme.font.size.xs};
    color: ${props => props.theme.colors.darkGray};
`;
FeedbackHeadingExtra.displayName = 'FeedbackHeadingExtra';

const FeedbackPanel = props => {
    const {
        t,
        active,
        onClose,
        onSubmit,
        error,
        sending,
        sent
    } = props;

    const [model, setModel] = useState({});

    const handleFormChange = useCallback((property, value) => {
        setModel({ ...model, [property]: value });
    }, [model]);

    const handleFormSubmit = useCallback(() => onSubmit(model), [model]);

    if (window && window.innerWidth < 600) {
        toggleScroll(active);
    }

    return (
        <FeedbackPanelContainer active={ active }>
            <MenuToggleContainer>
                <MenuToggle toggleMenu={ onClose } menuOpen={ active } />
            </MenuToggleContainer>
            <FeedbackContent active={ active }>
                <FeedbackHeading>{ t('We appreciate your feedback') }</FeedbackHeading>
                <FeedbackHeadingExtra>{t('All fields are optional')}</FeedbackHeadingExtra>
                <hr />
                <FeedbackForm
                    t={ t }
                    model={ model }
                    onChange={ handleFormChange }
                    onSubmit={ handleFormSubmit }
                    sending={ sending }
                    sent={ sent }
                />
                { error && <InfoBox error><b>{ t(error) }</b></InfoBox> }
            </FeedbackContent>
        </FeedbackPanelContainer>
    );
};

FeedbackPanel.propTypes = {
    t: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    active: PropTypes.bool,
    error: PropTypes.string,
    sending: PropTypes.bool,
    sent: PropTypes.bool
};

export default FeedbackPanel;
