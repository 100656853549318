import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { some, includes } from 'lodash';

import translations from 'decorators/Translations/translations';

import styled from 'styled-components';
import Svg from 'components/Svg/Svg';
import {
    setTOSStatus
} from 'redux/modules/index.js';

const StyledFooter = styled.footer`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    padding: 2em 1em;
    background-color: ${props => props.theme.colors.white};
    transition: margin-left ${props => props.theme.navigation.transition};
    border-top: 1px solid ${props => props.theme.colors.lightGray};

    ${props => props.theme.media.landscape`
        margin-left: ${props =>
            props.noMargin ? 0 :
            props.naviExtended ? props.theme.navigation.sideTabletWidth : props.theme.navigation.sideSmallWidth};
    `};

    ${props => props.theme.media.desktop`
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin-left: ${props =>
            props.noMargin ? 0 :
            props.naviExtended ? props.theme.navigation.sideWidth : props.theme.navigation.sideSmallWidth};
    `}
`;

const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    order: 2;
    margin-top: 1em;
    ${props => props.theme.media.desktop`
        margin-top: auto;
        flex-direction: row;
        width: 33%;
    `}
`;

const LinksContainer = styled.div`
    text-align: center;
    ${props => props.theme.media.desktop`
        width: 33%;
    `}
`;

const TermsAndConditions = styled.div`
    color: ${props => props.theme.colors.blue};
    font-size: 0.875em;
    cursor: pointer;
    display: inline;
    margin-left: 2em;

    &:first-child {
        margin-left: 0;
    }
`;

const PrivacyLink = TermsAndConditions.withComponent('a');

const Copyright = styled.span`
    color: ${props => props.theme.colors.rockBlue};
    font-size: 0.875em;
    margin-left: 0;
    margin-top: 1em;
    ${props => props.theme.media.desktop`
        margin-left: 2em;
        margin-top: 0em;
    `}
`;


class Footer extends Component {

    openTOS = () => this.props.setTOSStatus(true);

    shouldNotShowFooter = pathname =>
        pathname && some(['UserManual', 'AdminUserManual'], path => includes(pathname, path));

    render() {
        const { t, pathname, naviExtended, noMargin } = this.props;

        if (this.shouldNotShowFooter(pathname)) {
            return null;
        }

        return (
            <StyledFooter className="footer" naviExtended={ naviExtended } noMargin={ noMargin }>
                <LogoContainer>
                    <Link to="/" className="footer-logo">
                        <Svg
                            name="caverion_logo"
                            height={37}
                            width={140}
                            alt="Caverion Logo"
                            style={{ fill: '#002F6C' }}
                        />
                    </Link>
                    <Copyright>{ `©${moment().year()} Caverion Corporation` }</Copyright>
                </LogoContainer>
                <LinksContainer>
                    { t('tos_text') !== 'tos_text' &&
                        <TermsAndConditions onClick={ this.openTOS }>{ t('Terms and Conditions') }</TermsAndConditions>
                    }
                    { t('privacy_link') !== 'privacy_link' &&
                        <PrivacyLink
                            className="footer-link"
                            href={ t('privacy_link') }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            { t('Privacy statement') }
                        </PrivacyLink>
                    }
                </LinksContainer>
            </StyledFooter>
        );
    }
}

const mapStateToProps = state => ({
    naviExtended: state.navigation.sideNaviExtended
});

const mapDispatchToProps = dispatch => ({
    setTOSStatus: open => dispatch(setTOSStatus(open))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(Footer));
