import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Svg from 'components/Svg/Svg';

const StyledFeedbackButton = styled.span`
    height: 100%;
    width: 100%;
    display: none;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 5px;
    pointer-events: auto;
    cursor: pointer;

    &:hover {
        > * {
            transform: scale(1.15);
            transition: all ${props => props.theme.motion.quick}
                ${props => props.theme.motion.easing};
        }
    }

    ${props => props.theme.media.portrait`
        display: flex;
    `}
`;
StyledFeedbackButton.displayName = 'StyledFeedbackButton';

const Icon = styled(Svg)`
    fill: ${props => props.theme.colors.white};
    font-size: 25px;
`;
Icon.displayName = 'Icon';

const FeedbackButton = ({ onClick }) =>
    <StyledFeedbackButton onClick={onClick} data-test-id='FeedbackButton'>
        <Icon name="smiley-good" />
    </StyledFeedbackButton>;

FeedbackButton.displayName = 'FeedbackButton';

FeedbackButton.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default FeedbackButton;
