import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import underscored from 'underscore.string/underscored';
import { serviceStatusSortOrder } from 'utils/Data/resolveServiceOrderStatus';

const Status = styled.div`
    background-color: ${props => props.isOpen && props.theme.serviceOrder.openColor};
    display: flex;
    align-items: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const StatusIndicator = styled.span`
    ${props => props.open && 'display: none'};
    width: 100%;
    box-sizing: content-box;
    height: 3px;
    border-top: 1px solid ${props => props.theme.colors.white};
    background-color: ${props =>
        props.completed && props.theme.serviceOrder.completedColor
        || props.partly_completed && props.theme.serviceOrder.partlyCompletedColor
        || props.in_progress && props.theme.serviceOrder.inProgressColor
        || props.postponed && props.theme.serviceOrder.postponedColor
        || props.planned && props.theme.serviceOrder.plannedColor
    };
`;

const emptyArray = [];

const CalendarStatus = ({ statuses, isOpen }) => {
    const existingStatuses = statuses ? _.intersection(serviceStatusSortOrder, Array.from(statuses)) : emptyArray;
    return (
        <Status isOpen={ isOpen } className="calendar-status">
            { existingStatuses.map(status =>
                <StatusIndicator
                    { ... { [underscored(status)]: true } }
                    key={ underscored(status) }
                />)
            }
        </Status>
    );
};

CalendarStatus.propTypes = {
    isOpen: PropTypes.bool,
    statuses: PropTypes.instanceOf(Set),
};

export default CalendarStatus;
