import styled from 'styled-components';

const FLAnnouncements = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    ${props => props.theme.media.landscape`
        flex-direction: row;
        justify-content: flex-start;
        overflow: visible;
    `}
`;


export default FLAnnouncements;
