import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Type = styled.span``;
Type.displayName = 'Type';

const TypeColumn = ({ data }) => {
    return (
        <Type>
            { data }
        </Type>
    );
};
TypeColumn.displayName = 'TypeColumn';

TypeColumn.defaultProps = {
    data: '',
};

TypeColumn.propTypes = {
    data: PropTypes.string,
};

export default TypeColumn;
