import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import ContactInfo from './ContactInfo';
import ContactForm from './ContactForm';
import InfoBox from 'components/InfoBox/InfoBox';
import SkeletonText from 'components/Skeletons/SkeletonText';
import { categoryOptions } from './utils';


const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;
Container.displayName = 'Container';

const ContactInfos = styled.div`
    margin-top: ${props => props.theme.spacing.md};
`;
ContactInfos.displayName = 'ContactInfos';


const Contact = props => {
    const { t, contacts, onSubmit, partnerNumber, sending, sent, error, loadingContacts } = props;
    const [model, setModel] = useState({});

    const handleFormChange = useCallback((property, value) => {
        setModel({ ...model, [property]: value });
    }, [model]);

    const handleFormSubmit = useCallback(() => {
        const message = {
            ...model,
            partnerNumber,
            title: `${t('Contact')}: ${model.category}`
        };
        onSubmit(message);
    }, [model, onSubmit, partnerNumber, t]);

    return (
        <Section>
            <SectionHeader noBorder title={ t('Contact Caverion') } />
            <Columns>
                <Column columnWidth={{ desktop: 8, landscape: 8, portrait: 12, default: 12 }}>
                    <ContactForm
                        t={ t }
                        model={ model }
                        onSubmit={ handleFormSubmit }
                        onFormChange={ handleFormChange }
                        categoryOptions={ categoryOptions(t) }
                        sending={ sending }
                        sent={ sent }
                    />
                    { error && <InfoBox error><b>{ t(error) }</b></InfoBox> }
                </Column>
                <Column columnWidth={{ desktop: 4, landscape: 4, portrait: 12, default: 12 }}>
                    <ContactInfos>
                        { loadingContacts
                            ? <SkeletonText header />
                            : contacts.map(info => <ContactInfo key={ info.header } t={ t } info={ info } />) }
                    </ContactInfos>
                </Column>
            </Columns>
        </Section>
    );
};

Contact.propTypes = {
    t: PropTypes.func.isRequired,
    contacts: PropTypes.array.isRequired,
    loadingContacts: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    partnerNumber: PropTypes.string.isRequired,
    sending: PropTypes.bool.isRequired,
    sent: PropTypes.bool.isRequired,
    error: PropTypes.string,
};

export default Contact;
