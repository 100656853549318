import { createReducerFromMapping } from 'redux/utils/index.js';

const initialState = {
    loading: false,
    splash: false
};

export const SET_GENERAL_LOADING = 'CUSTOMER_PLATFORM/General/SET_GENERAL_LOADING';
export const setGeneralLoading = status => ({ type: SET_GENERAL_LOADING, status });

export const SET_SPLASH_LOADING = 'CUSTOMER_PLATFORM/General/SET_SPLASH_LOADING';
export const setSplashLoading = status => ({ type: SET_SPLASH_LOADING, status });

export default createReducerFromMapping({
    [SET_GENERAL_LOADING]: (state, action) => ({ ...state, loading: action.status }),
    [SET_SPLASH_LOADING]: (state, action) => ({ ...state, splash: action.status }),
}, initialState);
