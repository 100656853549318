import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import translations from 'decorators/Translations/translations';
import styled from 'styled-components';

import StandardPage from 'components/StandardPage/StandardPage';
import Header from 'containers/Application/Header/Header.jsx';
import Contact from 'components/Contact/Contact';

import { icons } from 'components/Icon/IconNames';
import { contactPersonInfo, contactSalesInfo, showContactCaverion } from './utils';
import { createContactMessage, loadPartnerMeta } from 'redux/modules';
import { isValidPartner } from 'utils/Data/partners';


const links = [
    { title: 'Back to Overview', icon: icons.ARROW_LEFT, to: { pathname: '/' }, smallIcon: true },
    { title: 'Contact Caverion', icon: icons.CONTACT }
];

const Empty = styled.p`
    width: 100%;
    text-align: center;
    margin-top: ${props => props.theme.spacing.xl};
`;


const ContactModule = props => {
    const {
        t,
        match: { params: { partnerNumber, functionalLocationId } },
        createContactMessage,
        loadPartnerMeta,
        partnerMeta,
        profile,
        sending,
        error,
    } = props;

    const partnerValid = isValidPartner(partnerNumber);
    useEffect(() => {
        // partnerMeta is usually already loaded in overview or building level
        partnerValid && !partnerMeta[partnerNumber] && loadPartnerMeta(partnerNumber);
    }, [partnerValid, partnerNumber, partnerMeta, loadPartnerMeta]);

    const [sent, setSent] = useState(false);
    const loadingContacts = !partnerMeta[partnerNumber] || partnerMeta[partnerNumber].loading;

    const contacts = useMemo(() => partnerValid && [
        contactPersonInfo(partnerMeta[partnerNumber] && partnerMeta[partnerNumber].meta),
        contactSalesInfo(profile, t)
    ], [partnerValid, partnerNumber, partnerMeta, profile, t]);

    const handleSubmit = useCallback(contactMessage =>
        createContactMessage(contactMessage).then(() => setSent(true)), [createContactMessage]);

    if (!partnerValid) {
        return null;
    }

    const content =
        showContactCaverion(partnerMeta[partnerNumber] && partnerMeta[partnerNumber].meta, profile)
            ? <Contact
                onSubmit={ handleSubmit }
                t={ t }
                partnerNumber={ partnerNumber }
                contacts={ contacts }
                sending={ sending }
                sent={ sent && !error }
                error={ error }
                loadingContacts={ loadingContacts }
            />
            : <Empty>{ t('No contact information') }</Empty>;

    if (functionalLocationId) {
        return content;
    }

    return (
        <StandardPage>
            <Helmet title={ t('Caverion Contact') } />
            <Header t={ t } showPartnerSelect links={ links } selected="contact caverion" />
            { content }
        </StandardPage>
    );
};


const mapStateToProps = state => ({
    functionalLocations: state.functionalLocations.functionalLocations,
    partnerMeta: state.partnerMeta.meta,
    profile: state.profile.profile,
    sending: state.contact.sending,
    error: state.contact.error,
});

const mapDispatchToProps = {
    createContactMessage,
    loadPartnerMeta,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(withRouter(ContactModule)));
