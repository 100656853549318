import queryString from 'query-string';
import { createReducerFromMapping } from 'redux/utils/index.js';
import { loadMe } from 'redux/modules/profile/profile.js';
import { setLanguage } from 'redux/modules/localization/localization.js';
import { Session, Profile } from '@caverion/redux/api/actions';
import cookies from 'utils/Cookies/Cookies.js';
import { fixLanguage } from 'utils/Data/language';
import { getWelcomeUrl } from 'utils/Data/language';
import { isExtenalADUser } from 'utils/profile';

const initialState = {
    // Make the default state loading so that the first load respects loading order via promise resolve
    loading: true,
    error: false
};

export const APPLICATION_LOAD = 'CUSTOMER_PLATFORM/Application/LOAD';
export const APPLICATION_LOAD_SUCCESS = 'CUSTOMER_PLATFORM/Application/LOAD_SUCCESS';
export const APPLICATION_LOAD_FAIL = 'CUSTOMER_PLATFORM/Application/LOAD_FAIL';

export const loadApplication = pathname => {
    return async (dispatch, getState) => {
        dispatch({ type: APPLICATION_LOAD });
        const { router: { location: { search } } } = getState();
        const username = queryString.parse(search).username || cookies.getItem('username');

        try {
            if (pathname === '/login') {
                const redirectUrl = cookies.getItem('redirectTo') || '/';

                // Create session and redirect to Azure for login
                const session = await dispatch(Session.create(`${window.location.origin}/Authorize`, { redirectUrl }));
                let loginUrl = session.loginUrl;
                if (username && !isExtenalADUser(username)) {
                    loginUrl = `${loginUrl}&login_hint=${username}&domain_hint=consumers`;
                }
                setTimeout(function () {
                    window.location = loginUrl;
                }, 0);

                cookies.removeItem('redirectTo');
                return;
            }

            // If no session cookie, redirect to welcome page
            const cnid = cookies.getItem('cnid');
            if (!cnid || cnid === 'null') {
                let welcomeUrl = getWelcomeUrl();
                if (username && !isExtenalADUser(username)) {
                    welcomeUrl = `${welcomeUrl}?username=${username}`;
                }

                setTimeout(function () {
                    window.location = welcomeUrl;
                }, 0);
                return;
            }

            const result = await dispatch(loadMe());

            if (result.error) {
                return dispatch({
                    type: APPLICATION_LOAD_FAIL,
                    error: result.error
                });
            }

            const profile = result && result.result && result.result.profile;
            let language = profile.language || 'en';
            language = fixLanguage(language);
            await dispatch(setLanguage(language));
            cookies.setItem('username', profile.username, Infinity, '/');

            return dispatch({
                type: APPLICATION_LOAD_SUCCESS
            });
        } catch (error) {
            return dispatch({
                type: APPLICATION_LOAD_FAIL,
                error
            });
        }
    };
};

export const ping = () => async dispatch => {
    try {
        await dispatch(Profile.ping());
    } catch (err) {
        //
    }
};

export default createReducerFromMapping({
    [APPLICATION_LOAD]: state => ({
        ...state,
        loading: true
    }),
    [APPLICATION_LOAD_SUCCESS]: state => ({
        ...state,
        loading: false
    }),
    [APPLICATION_LOAD_FAIL]: (state, action) => ({
        ...state,
        loading: false,
        error: action.error
    })
}, initialState);
