import React from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';

const StyledCircleTabs = styled.div`
    overflow-x: auto;
    ${props => props.stickyTabs && css`
        position: ${props => props.fixed ? 'fixed' : 'absolute'};
    `}
    top: ${props => props.fixed ? '64px' : '448px'};
    left: 0;
    right: 0;
    background-color: ${props => props.theme.colors.white};
    z-index: ${props => props.fixed && props.theme.zIndex('circleTabs')};
    padding: 1em;
    box-shadow: 0 0.125rem 1rem ${props => rgba(props.theme.colors.black, 0.15)};
    white-space: nowrap;
    text-align: ${props => props.align || 'center'};
    font-family: ${props => props.theme.font.sanchez};

    &::-webkit-scrollbar {
        display: none;
    }
`;

const Label = styled.div`
    display: inline-block;
    color: ${props => props.theme.colors.blue};
    font-weight: ${props => props.theme.fontWeight.bold};
`;

const CircleTabs = props => {
    const { label, children, ...other } = props;

    return (
        <StyledCircleTabs {...other}>
            { label && <Label>{ label }</Label> }
            { children }
        </StyledCircleTabs>
    );
};

export default CircleTabs;
