import { createReducerFromMapping } from 'redux/utils/index.js';

const initialState = {
    show: false,
    modalType: '',
    errorMessage: '',
    preConditions: null,
    onSubmitAction: null,
    passedProps: {}
};

export const SHOW_MODAL = 'CUSTOMER_PLATFORM/Modal/SHOW_MODAL';
export const CLOSE_MODAL = 'CUSTOMER_PLATFORM/Modal/CLOSE_MODAL';
export const MODAL_SHOW_ERROR_MESSAGE = 'CUSTOMER_PLATFORM/Modal/MODAL_SHOW_ERROR_MESSAGE';

/**
 *
 * @param {*} modalType
 * @param {*} preConditions
 * @param {*} onSubmitAction
 * @param {*} passedProps
 */
export const showModal = (modalType, preConditions, onSubmitAction, passedProps) => (
    { type: SHOW_MODAL, show: true, modalType, preConditions, onSubmitAction, passedProps }
);
export const closeModal = () => ({ type: CLOSE_MODAL });
export const showErrorMessage = errorMessage => ({ type: MODAL_SHOW_ERROR_MESSAGE, errorMessage });

export default createReducerFromMapping({
    [SHOW_MODAL]: (state, action) => ({
        ...state,
        show: action.show,
        modalType: action.modalType,
        preConditions: action.preConditions,
        onSubmitAction: action.onSubmitAction,
        passedProps: action.passedProps
    }),
    [CLOSE_MODAL]: (state, action) => ({
        ...initialState
    }),
    [MODAL_SHOW_ERROR_MESSAGE]: (state, action) => ({
        ...state,
        errorMessage: action.errorMessage
    })
}, initialState);
