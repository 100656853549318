import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { some, includes } from 'lodash';
import { devToolsEnhancer } from 'redux-devtools-extension/logOnlyInProduction';
import { default as createMiddleware } from '@caverion/redux/middleware';
import cookies from 'utils/Cookies/Cookies.js';
import createRootReducer from './modules/reducer.js';

import { sessionUnset } from 'redux/modules/index.js';
import { getWelcomeUrl } from 'utils/Data/language';

const SettingsProvider = {
    getApiUrl: () => process.env.REACT_APP_API_URL,
    getApiSubscriptionKey: () => process.env.REACT_APP_API_SUBSCRIPTION_KEY
};

const SessionIdProvider = {
    get: () => Promise.resolve(cookies.getItem('cnid')),
    set: id => Promise.resolve(cookies.setItem('cnid', id, Infinity, '/'))
};

const CacheProvider = {
    getTTL: () => 5 * 60 // 5 minutes
};

const doNotRedirect = ['Authorize', 'Logout'];

const authInterceptor = () => ({ dispatch, getState }) => next => action => {
    if (action.statusCode === 401) {
        const { router: { location } } = getState();
        if (!some(doNotRedirect, path => includes(location.pathname, path))) {
            cookies.setItem('redirectTo', `${location.pathname}${location.search}`, Infinity, '/');
        }
        dispatch(sessionUnset(getWelcomeUrl()));
    } else {
        return next(action);
    }
};

export default (history, initialState) => {
    const createStoreWithMiddleware = applyMiddleware(
        createMiddleware(SettingsProvider, SessionIdProvider, CacheProvider),
        authInterceptor(),
        routerMiddleware(history)
    )(createStore);
    return createStoreWithMiddleware(createRootReducer(history), initialState, devToolsEnhancer());
};
