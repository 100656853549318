import React from 'react';
import styled, { css } from 'styled-components';

const Row = styled.div`
    font-size: 0.937em;
    width: 100%;
    margin: 0.5em 0;

    & > * {
        width: 100%;
    }

    & > label {
        line-height: 2.5em;
        display: inline-block;
    }

    & > button {
        width: auto; /* width: initial does not work in IE */
    }

    ${props => props.theme.media.portrait`
        margin-top: 1em;
        margin-bottom: 1em;

        ${props => !props.fullRow && css`
            width: 47%;

            &:nth-of-type(odd) {
                margin-right: 2.5%;
            }

            &:nth-of-type(even) {
                margin-left: 2.5%;
            }
        `}
    `}
`;
Row.displayName = 'Row';

function propagateDownwards(required, children) {
    if (children === undefined) {return [];}

    return React.Children.map(children, x => {
        if (typeof x === 'string') {
            return x;
        }

        const props = {};

        if (x.props !== undefined && x.props.id !== undefined) {
            props.required = required;
        }

        const childs = propagateDownwards(required, x.props && x.props.children);

        return React.cloneElement(x, props, childs);
    });
}

export const InputRow = ({ required, children, className, fullRow, ...other }) => {
    const childs = propagateDownwards(!!required, children);

    return (
        <Row
            className={ `inline-row ${className ? className : ''}` }
            fullRow={ fullRow }
            {...other}
        >
            { childs }
        </Row>
    );
};
InputRow.displayName = 'InputRow';

export default InputRow;
