import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import _ from 'lodash';

import rootReducer from '@caverion/redux/api/reducer';

import env from './service/env.js';
import title from './title/title.js';
import history from './iot/history.js';
import alarm from './iot/alarm.js';
import sensors from './iot/sensors.js';
import profile from './profile/profile.js';
import values from './iot/values.js';
import notice from './iot/notice.js';
import analytics from './iot/analytics.js';
import utilizationRate from './iot/utilizationRate';

import document from './document/document.js';
import folder from './document/folder.js';
import file from './document/file.js';
import navigation from './navigation/navigation.js';
import plannedMaintenance from './service/plannedMaintenance';
import serviceOrders from './service/serviceOrders.js';
import serviceRequests from './service/serviceRequests.js';
import localization from './localization/localization.js';
import session from './session/session.js';
import common from './common/common.js';

import application from './containers/application.js';
import partnerOverview from './containers/partner-overview.js';
import functionalLocationContainer from './containers/functional-location.js';
import buildingContainer from './containers/building.js';
import buildingAdminContainer from 'redux/modules/containers/buildingAdmin';
import equipmentContainer from './containers/equipment.js';
import energy from './iot/energy.js';
import KPIContainer from './containers/kpi.js';
import KPIEnergyContainer from './containers/kpi-energy.js';
import equipmentsModule from './containers/equipments-module.js';
import fileupload from './common/fileupload';
import CCCEnergyContainer from './containers/ccc-energy.js';
import modal from './modal/modal.js';
import announcement from './announcement/announcement.js';
import feedback from './feedback/feedback.js';
import buildingMeta from './buildingConfig/buildingMeta.js';
import sensorAlarms from './iot/sensorAlarms.js';
import { RESET_ERROR_MESSAGE } from './errorMessage/errorMessage.js';
import notification from './notification/notification.js';
import userManual from './userManual/userManual.js';
import device from './iot/device.js';
import powerbi from './powerbi/powerbi.js';
import customView from './customView/customView.js';

import customer from './customer/customer';
import equipments from './customer/equipments';
import functionalLocations from './customer/functionalLocations';
import partnerImage from './customer/partnerImage';
import sensorHierarchy from './customer/sensorHierarchy';
import sensorMeta from './customer/sensorMeta';
import partnerMeta from './customer/partnerMeta';
import tag from './tag/tag';
import generalLoading from './general/loading';
import exportSensorData from './iot/exportSensorData';
import contact from './contact/contact';

const errorMessage = (state = null, action) => {
    const { type, error } = action;

    if (type === RESET_ERROR_MESSAGE) {
        return null;
    } else if (error) {
        const appErrorId = _.uniqueId('errorId_');
        if (error.error) {
            return {
                ...error.error,
                errorSource: type,
                appErrorId
            };
        }
        return {
            ...error,
            errorSource: type,
            appErrorId
        };
    }

    return state;
};

const createRootReducer = routerHistory => combineReducers({
    rootReducer,
    router: connectRouter(routerHistory),
    env,
    title,
    alarm,
    sensors,
    iotHistory: history,
    profile,
    customer,
    document,
    folder,
    file,
    navigation,
    plannedMaintenance,
    serviceOrders,
    serviceRequests,
    functionalLocations,
    equipments,
    localization,
    session,
    common,
    energy,
    analytics,

    application,
    partnerOverview,
    functionalLocationContainer,
    CCCEnergyContainer,
    buildingContainer,
    equipmentContainer,
    KPIContainer,
    KPIEnergyContainer,
    equipmentsModule,
    sensorHierarchy,
    values,
    notice,
    fileupload,
    modal,
    partnerImage,
    announcement,
    feedback,
    errorMessage,
    notification,
    buildingAdminContainer,
    userManual,
    buildingMeta,
    sensorAlarms,
    sensorMeta,
    device,
    powerbi,
    customView,
    partnerMeta,
    tag,
    generalLoading,
    exportSensorData,
    contact,
    utilizationRate,
});

export default createRootReducer;
