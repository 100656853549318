import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import QueryLink from 'components/QueryLink/QueryLink.jsx';
import Svg from 'components/Svg/Svg.jsx';

const Label = styled.span`
    position: relative;
    font-size: ${props => props.theme.fontSize.xxs};
    font-weight: ${props => props.theme.font.weight.bold};
    min-width: 94px;
    flex: 1 0 0;
`;

const linkStyles = css`
    color: ${props => props.disabled ? props.theme.colors.lightGray : props.theme.colors.black};
    width: 100%;
    height: 100%;
    cursor: ${props => props.disabled || props.selected ? 'default' : 'pointer'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    flex-direction: column;

    ${props => props.theme.media.landscape`
        padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.md};
    `}
`;

const Link = styled(QueryLink)`
    ${linkStyles}
`;
Link.displayName = 'Link';

const ExternalLink = styled.a`
    ${linkStyles}
`;
ExternalLink.displayName = 'ExternalLink';

const Icon = styled(({ small, ...props }) => <Svg {...props} />)`
    font-size: ${props => props.small ? props.theme.fontSize.md : props.theme.fontSize.xxl};
    margin: ${props => props.theme.spacing.sm};
    height: 32px;
    fill: ${props =>
        props.disabled ? props.theme.colors.rockBlue :
        props.selected ? props.theme.colors.orange : props.theme.colors.midnight};
`;

Icon.displayName = 'Icon';

const Text = styled.span`
    margin: 0 ${props => props.theme.spacing.sm};
    text-overflow: ellipsis;
    max-width: 80%;
    overflow: hidden;
    line-height: normal;
`;

Text.displayName = 'Text';

const MobileNaviItem = props => {
    const { t, id, title, icon, disabled, loading, to, selected, smallIcon, external } = props;
    const LinkComponent = external ? ExternalLink : Link;
    const key = id ? id : title.toLowerCase();

    return (
        <Label
            data-test-id={`${ title }${ loading ? '-loading' : '' }${ disabled ? '-disabled' : '' }`}
            key={ `${key}-label` }
            selected={ selected }
            disabled={ disabled }
        >
            <LinkComponent
                selected={ selected }
                disabled={ disabled }
                to={ to }
                href={ external ? to.pathname : undefined }
                target={ external ? '_blank' : undefined }
                rel={ external ? 'noopener noreferrer' : undefined }
                onClick={ e => disabled && e.preventDefault() }
            >
                <Icon name={ icon } selected={ selected } disabled={ disabled } small={ smallIcon }/>
                <Text>{ t(title) }</Text>
            </LinkComponent>
        </Label>
    );
};

MobileNaviItem.propTypes = {
    t: PropTypes.func.isRequired,
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    to: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    loading: PropTypes.bool,
    smallIcon: PropTypes.bool,
    external: PropTypes.bool
};

MobileNaviItem.defaultProps = {
    disabled: false,
    selected: false,
    loading: false,
    smallIcon: false,
    external: false
};

export default React.memo(MobileNaviItem);
