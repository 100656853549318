import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import styled, { withTheme } from 'styled-components';
import memoizeOne from 'memoize-one';
import moment from 'moment-timezone';

import Section from 'components/Section/Section';
import { searchUsername, resendVerification } from 'redux/modules/index';
import { Icon } from 'components/Icon/Icon';
import { isExtenalADUser } from 'utils/profile';
import {
    InputRow,
    InputLabel,
    InputText,
    Infotip,
    InputSelectDropdown,
    Button
} from 'components/index.js';
import InputDate from 'components/Form/InputDate';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import SensorAlarmEmailsForm from './SensorAlarmEmailsForm';

const languageOptions = memoizeOne(t => [
    { value: 'en', label: t('English') },
    { value: 'fi', label: t('Finnish') },
    { value: 'sv', label: t('Swedish') },
    { value: 'nb', label: t('Norwegian') },
    { value: 'da', label: t('Danish') },
    { value: 'lt', label: t('Lithuanian') },
    { value: 'de', label: t('German') },
    { value: 'ru', label: t('Russian') }
]);

export const divisionOptions = memoizeOne(t => [
    { value: 'fi', label: t('Finland') },
    { value: 'se', label: t('Sweden') },
    { value: 'no', label: t('Norway') },
    { value: 'dk', label: t('Denmark') },
    { value: 'lt', label: t('Lithuania') },
    { value: 'de', label: t('Germany') },
    { value: 'ru', label: t('Russia') },
    { value: 'at', label: t('Austria') },
    { value: 'development', label: t('Development') }
]);

export const roleOptions = (t, role) => [
    { value: 'user', label: t('Normal user') },
    { value: 'customer-admin', label: t('Customer admin') },
    { value: 'caverion-user', label: t('Caverion user') },
    { value: 'caverion-admin', label: t('Caverion admin') }
]
    // For customer-admin, filter out global Caverion roles.
    .filter(x => role !== 'customer-admin' || x.value !== 'caverion-admin' && x.value !== 'caverion-user');

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Title = styled.h2`
    padding: ${props => props.theme.spacing.md} 0;
    font-size: ${props => props.theme.font.size.lg};
`;
Title.displayName = 'Title';

const FieldErrorInfoTip = styled(Infotip)`
    display: block;
`;

const NonVerified = styled.div`
    padding: 0.5em;
`;

const ResendVerificationLink = styled.span`
    cursor: pointer;
    color: ${props => props.theme.colors.cerulean};
`;

const Success = styled.span`
    color: ${props => props.theme.colors.emerald};
`;

const Failure = styled.span`
    color: ${props => props.theme.colors.radicalRed};
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
`;

const DeleteButton = styled.button`
    position: relative;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    font-size: ${props => props.theme.font.size.xs};
    font-weight: ${props => props.theme.font.weight.bold};
    color: ${props => props.theme.input.font.color.default};
    font-family: ${props => props.theme.font.family.arial};
    padding-left: 0;
    cursor: pointer;
    margin-left: auto;
    height: 0;

    ${props => props.theme.media.portrait`
        top: -44px;
    `};

    svg {
        margin-right: ${props => props.theme.spacing.sm};
    }
`;
DeleteButton.displayName = 'DeleteButton';

export const Settings = props => {
    const {
        theme,
        t,
        model,
        isAdmin,
        isCaverionAdmin,
        newProfile,
        onChange,
        searchUsername,
        usernameExists,
        resentVerification,
        resendVerification,
        profile,
        handleDeleteClick
    } = props;

    const [sensorAlarmsDialogOpen, setSensorAlarmsDialogOpen] = useState(false);

    const checkExistingUsername = model => {
        const username = model.username;
        if (!newProfile || !username) {
            return;
        }
        searchUsername(username);
    };

    const setAlarmEmailsAndClose = value => {
        onChange('ccAlarms', value);
        setSensorAlarmsDialogOpen(false);
    };

    const renderResendVerification = isCaverionAdmin => {
        if (!isCaverionAdmin) {
            return <NonVerified>-</NonVerified>;
        }

        let message = null;
        if (_.isEmpty(Object.keys(resentVerification)) || resentVerification[model.id] === undefined) {
            message = <ResendVerificationLink onClick={() => resendVerification(model.id)}>
                { t('Resend verification email') }
            </ResendVerificationLink>;
        }
        else if (resentVerification[model.id]) {
            message = <Success>{ t('Resent verification email!') }</Success>;
        }
        else if (resentVerification[model.id] === false) {
            message = <Failure>{ t('Sending verification email failed!') }</Failure>;
        }

        return <NonVerified>- ({ message })</NonVerified>;
    };

    const hasExternalADEmail = model && isExtenalADUser(model.email);
    const allowEmailEdit = newProfile || !hasExternalADEmail;
    const allowDelete = model && model.id && (isCaverionAdmin || model.id === profile.id);
    const invalidUsername = newProfile && model && model.username && usernameExists[model.username] || false;

    const verified = model && model.verified ?
        <InputRow>
            <InputLabel id="verified" text={ t('Verified on') } />
            <InputText
                id="verified"
                value={ moment.utc(model.verified).format('YYYY-MM-DD hh:mm:ss:SSS z') }
                readonly
            />
        </InputRow>
        : model && model.id && <InputRow>
            <InputLabel id="verified" text={ t('Verified on') } />
            { renderResendVerification(isCaverionAdmin) }
        </InputRow>
        || null;

    return <Fragment>
        <Section>
            <Title>{ t('Profile Information')}</Title>
            { allowDelete &&
                <DeleteButton onClick={ handleDeleteClick } type="button">
                    <Icon
                        name="trash-can"
                        fill={ theme.colors.radicalRed }
                        size="SMALL"
                    />
                    { t('Delete Account') }
                </DeleteButton>
            }
            <Container>
                <InputRow required>
                    <InputLabel id="firstName" text={ t('First name') } />
                    <InputText
                        model={ model }
                        onChange={ onChange }
                        id="firstName"
                        property="firstName"
                        placeholder={ t('User\'s first name') }
                        readonly={ !newProfile }
                        onBlur={ () => checkExistingUsername(model) }
                    />
                </InputRow>
                <InputRow required>
                    <InputLabel id="lastName" text={ t('Last name') } />
                    <InputText
                        model={ model }
                        onChange={ onChange }
                        id="lastName"
                        property="lastName"
                        placeholder={ t('User\'s last name') }
                        readonly={ !newProfile }
                        onBlur={ () => checkExistingUsername(model) }
                    />
                </InputRow>
                <InputRow required>
                    <InputLabel id="email" text={ t('Email') } />
                    <InputText
                        model={ model }
                        onChange={ onChange }
                        id="email"
                        property="email"
                        type="email"
                        placeholder={ t('User\'s private email address') }
                        readonly={ !isAdmin || !allowEmailEdit }
                        onBlur={ () => checkExistingUsername(model) }
                    />
                </InputRow>
                <InputRow>
                    <InputLabel id="username" text={ t('Username') } />
                    { invalidUsername ?
                        <FieldErrorInfoTip
                            text={ hasExternalADEmail ?
                                t('User already exists') :
                                [t('Username already exists.'), t('Please enter a different first or last name.')] }
                            alwaysVisible
                        >
                            <InputText
                                model={ model }
                                id="username"
                                property="username"
                                readonly
                                placeholder={ t('User\'s login name gets generated here') }
                                invalid
                            />
                        </FieldErrorInfoTip> :
                        <InputText
                            model={ model }
                            id="username"
                            property="username"
                            readonly
                            placeholder={ t('User\'s login name gets generated here') }
                        />
                    }
                </InputRow>
                { verified }
                <InputRow>
                    <InputLabel id="role" text={ t('Role') } />
                    <InputSelectDropdown
                        model={ model }
                        onChange={ onChange }
                        property="role"
                        options={ roleOptions(t, profile.role) }
                        t={ t }
                        disabled={ !isAdmin }
                        clearable={ false }
                    />
                </InputRow>
                <InputRow>
                    <InputLabel id="division" text={ t('Division') } />
                    <InputSelectDropdown
                        model={ model }
                        onChange={ onChange }
                        property="division"
                        options={ divisionOptions(t) }
                        t={ t }
                        disabled={ !isAdmin }
                        clearable={ false }
                        required
                    />
                </InputRow>
            </Container>
        </Section>
        <Section>
            <Title>{ t('Settings')}</Title>
            <Container>
                <InputRow>
                    <InputLabel id="language" text={ t('Language') } />
                    <InputSelectDropdown
                        model={ model }
                        onChange={ onChange }
                        property="language"
                        options={ languageOptions(t) }
                        t={ t }
                        clearable={ false }
                    />
                </InputRow>
                { isCaverionAdmin &&
                    <Fragment>
                        <InputRow>
                            <InputLabel id="giosgId" text={ t('Giosg id') } />
                            <InputText
                                model={ model }
                                onChange={ onChange }
                                id="giosgId"
                                property="giosgId"
                                placeholder={ t('Giosg chat id') }
                            />
                        </InputRow>
                        <InputRow>
                            <InputLabel id="serviceOrderStartDate" text={ t('Show service orders created after') } />
                            <InputDate
                                model={ model }
                                onChange={ onChange }
                                id="serviceOrderStartDate"
                                property="serviceOrderStartDate"
                                clearable
                            />
                        </InputRow>
                        <InputRow>
                            <InputLabel id="documentStartDate" text={ t('Show documents created after') } />
                            <InputDate
                                model={ model }
                                onChange={ onChange }
                                id="documentStartDate"
                                property="documentStartDate"
                                clearable
                            />
                        </InputRow>
                    </Fragment>
                }
                <InputRow>
                    <InputLabel
                        id="ccAlarms"
                        text={ t('Alarm Mailing List') }
                        ctxHelp={ `${CTXHELP_PREFIX} Alarm mailing list` }
                        t={ t }
                    />
                    <ButtonContainer>
                        <Button onClick={() => setSensorAlarmsDialogOpen(true)}>
                            { `${t('Recipients')} (${model && model.ccAlarms ? model.ccAlarms.length : 0})` }
                        </Button>
                    </ButtonContainer>
                </InputRow>
                { sensorAlarmsDialogOpen &&
                    <SensorAlarmEmailsForm
                        t={ t }
                        emails={ model && model.ccAlarms || [] }
                        onChange={ value => onChange('ccAlarms', value) }
                        onSubmit={ () => setSensorAlarmsDialogOpen(false) }
                        onClose={ value => setAlarmEmailsAndClose(value) }
                    />
                }
            </Container>
        </Section>
    </Fragment>;
};

Settings.propTypes = {
    t: PropTypes.func.isRequired,
    model: PropTypes.object,
    isAdmin: PropTypes.bool.isRequired,
    isCaverionAdmin: PropTypes.bool.isRequired,
    newProfile: PropTypes.bool,
    searchUsername: PropTypes.func.isRequired,
    resendVerification: PropTypes.func.isRequired,
    resentVerification: PropTypes.object,
    profile: PropTypes.object.isRequired,
    handleDeleteClick: PropTypes.func.isRequired
};

Settings.defaultProps = {
    newProfile: false,
    model: {},
    resentVerification: {}
};

const mapStateToProps = state => ({
    resentVerification: state.profile.resentVerification,
    profile: state.profile.profile
});

const mapDispatchToProps = dispatch => ({
    searchUsername: username => dispatch(searchUsername(username)),
    resendVerification: id => dispatch(resendVerification(id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withTheme(Settings));
