import React, { Fragment, PureComponent } from 'react';
import { isEmpty, includes, filter, map, find, some, clone, isEqual, forEach } from 'lodash';
import memoizeOne from 'memoize-one';

import BuildingAutomationModule from 'components/Modules/BuildingAutomationModule/BuildingAutomationModule';
import CleaningModule from 'components/Modules/CleaningModule/CleaningModule';
import AnnouncementsModule from 'components/Modules/AnnouncementsModule/AnnouncementsModule';
import ControlRoomModule from 'components/Modules/ControlRoomModule/ControlRoomModule';
import FloorplanModule from '../Modules/FloorplanModule/FloorplanModule.jsx';
import MaintenanceModule from 'containers/Application/Modules/MaintenanceModule/MaintenanceModule.jsx';
import FunctionalLocationsModule from 'components/Modules/FunctionalLocationsModule/FunctionalLocationsModule.jsx';
import ConditionListModule from 'components/Modules/ConditionListModule/ConditionListModule.jsx';
import ExternalDocumentModule from 'containers/Application/Modules/ExternalDocumentModule/ExternalDocumentModule.jsx';
import DocumentModule from 'containers/Application/Modules/DocumentModule/DocumentModule.jsx';
import EquipmentModule from 'containers/Application/Modules/EquipmentModule/EquipmentModule.jsx';
import CustomViewModule from 'containers/Application/CustomViews/CustomViewModule';
import Hero from 'components/Hero/Hero.jsx';
import EnergyModule from 'containers/Application/Modules/EnergyModule/EnergyModule';
import Header from 'containers/Application/Header/Header.jsx';
import ServiceRequest from 'containers/Application/ServiceRequest/ServiceRequest';
import { Tabs, Tab } from 'components/index.js';
import ContactModule from 'containers/Application/Modules/ContactModule/ContactModule';

import {
    addServiceOrderLink,
    addConditionsLink,
    addTechnicalLink,
    addEquipmentLink,
    addDocumentsLink,
    addExternalDocumentsLink,
    addBuildingAutomationLink,
    addFloorsLink,
    addEnergyLink,
    addRecyclingLink,
    addControlRoomLink,
    addCleaningLink,
    addAnnouncementsLink,
    addCustomViewLinks,
    addNewServiceRequestLink,
    addBuildingAdminLink,
    addContactCaverionLink
} from 'utils/Data/functionalLocations';
import {
    isServiceOrdersEnabled,
    isTechnicalTabEnabled,
    isEquipmentEnabled,
    isConditionsEnabled
} from 'utils/Data/profileData';
import { activeAnnouncements } from 'components/Announcement/utils';
import { utilizationTypes, isEnergySensor, isRecyclingSensor } from 'utils/Data/values';
import { showContactCaverion } from 'containers/Application/Modules/ContactModule/utils';

const heroMetaKeys = ['BuildingType', 'YearOfConstruction'];

const getSensorCategories = memoizeOne(buildingHierarchy =>
    buildingHierarchy && buildingHierarchy.length ?
        buildingHierarchy[0].children.filter(child => !!child.sensors) :
        []
);

const isEnergySensorsFound = (buildingSensors, { sensorDataTypes }) => {
    const energySensorTypes = filter(sensorDataTypes, sensorType => isEnergySensor(sensorType.name));
    return !!find(buildingSensors, sensor => includes(map(energySensorTypes, 'id'), sensor.sensorTypeId));
};

const isRecyclingSensorsFound = (buildingSensors, { sensorDataTypes }) => {
    const recyclingRensorTypeIds = sensorDataTypes
        .filter(sensorType => isRecyclingSensor(sensorType.name))
        .map(sensorType => sensorType.id);
    return buildingSensors.some(buildingSensor => recyclingRensorTypeIds.includes(buildingSensor.sensorTypeId));
};

class BusinessUnit extends PureComponent {

    memoizedLinks = memoizeOne(links => links, isEqual);
    memoizedLinksMobile = memoizeOne(links => links, isEqual);
    memoizedContext = memoizeOne((heroContext, loadingParent, functionalLocation) =>
        Object.assign({}, heroContext, { loadingContext: loadingParent, functionalLocation }), isEqual);

    getHeroMeta = memoizeOne((meta, t) => {
        if (meta) {
            const heroMeta = filter(meta, item => includes(heroMetaKeys, item.key))
                .map(item => ({ key: t(item.key), value: t(item.value) }));
            const areaMeta = find(meta, { key: 'grossArea' });
            areaMeta && heroMeta.push({ key: t(areaMeta.key), value: `${areaMeta.value} m²` });
            return heroMeta;
        }
    });

    render() {
        const {
            t, loadingParent,
            loadingEquipment,
            features,
            featureTeasers,
            functionalLocation,
            equipments,
            subEquipments,
            partnerNumber,
            parents,
            floors,
            sensorValues,
            valuesBySensorId,
            latestValuesBySensorId,
            cleaningValues,
            buildingConditions,
            buildingSensors,
            buildingHierarchy,
            observations,
            inspections,
            alarms,
            documentCount,
            loadingDocuments,
            equipmentCount,
            totalFunctionalLocations,
            loadingFunctionalLocations,
            profile,
            buildingImage,
            announcements,
            sensorHierarchy,
            page,
            loadingChildren,
            childFunctionalLocations,
            meta,
            sla,
            match: { url },
            customViews,
            sensorAlarmsById,
            partnerMeta,
        } = this.props;

        const showBuildingManagement = floors && floors.length > 0;

        const sensorCategories = getSensorCategories(buildingHierarchy);
        const disableEnergyTab = !isEnergySensorsFound(buildingSensors, sensorHierarchy);

        // TODO: Enable when recycling sensor names are added
        // const disableRecyclingTab = !isRecyclingSensorsFound(buildingSensors, sensorHierarchy);
        const disableRecyclingTab = false;

        const hasCleaningSensors = some(buildingSensors, sensor =>
            sensor.sensorType && sensor.sensorType.name === 'cleaning');
        const activeAnnouncementsList = activeAnnouncements(announcements);
        const hasUtilizationSensors = some(buildingSensors,
            sensor => sensor.sensorType && utilizationTypes.indexOf(sensor.sensorType.name) !== -1);
        const hasS2Sensors = some(buildingSensors, sensor => sensor.sensorType && sensor.sensorType.name === 's2');
        const buildingAutomationEnabled = hasUtilizationSensors || hasS2Sensors;

        const hasAlarms = alarms && alarms.totals && alarms.totals.all > 0;
        const controlRoomDisabled = !observations.length && !sla && !hasAlarms && isEmpty(inspections);

        const showContactCaverionLink = showContactCaverion(partnerMeta[partnerNumber]
            && partnerMeta[partnerNumber].meta, profile);

        let links = [];
        links = addServiceOrderLink(links, url, features);
        links = addConditionsLink(links, url, features, isEmpty(sensorCategories), featureTeasers);
        links = addEnergyLink(links, url, features, disableEnergyTab, featureTeasers);
        links = addRecyclingLink(links, url, features, disableRecyclingTab);
        links = addControlRoomLink(links, url, features, controlRoomDisabled, featureTeasers);
        links = addBuildingAutomationLink(links, url, features, !buildingAutomationEnabled, featureTeasers);
        links = addFloorsLink(links, url, features, !showBuildingManagement, featureTeasers);
        links = addCleaningLink(links, url, features, !hasCleaningSensors, featureTeasers);
        links = addTechnicalLink(links, url, features, functionalLocation,
            totalFunctionalLocations, loadingFunctionalLocations, featureTeasers);
        links = addEquipmentLink(links, url, features, equipmentCount, loadingEquipment, featureTeasers);
        links = addDocumentsLink(links, url, features, featureTeasers);
        links = addExternalDocumentsLink(links, url, features, documentCount, loadingDocuments, featureTeasers);
        links = addAnnouncementsLink(links, url, features,
            activeAnnouncementsList && activeAnnouncementsList.length || 0, featureTeasers);
        links = addCustomViewLinks(links, url, customViews);

        let linksMobile = clone(links);
        linksMobile = addNewServiceRequestLink(linksMobile, url, features);
        linksMobile = addBuildingAdminLink(linksMobile, functionalLocation, profile);
        linksMobile = addContactCaverionLink(linksMobile, url, showContactCaverionLink);

        const heroContext = this.props.getHeroContext(parents, partnerNumber, features, profile.customViews);
        const context = Object.assign({}, heroContext, { loadingContext: loadingParent, functionalLocation });

        const tabs = [];

        if (features && features.files) {
            tabs.push(
                <Tab title="Documents" key="Documents">
                    <DocumentModule functionalLocation={ functionalLocation } />
                </Tab>
            );
        }

        if (features && features.documents) {
            tabs.push(
                <Tab title="External Documents" key="External Documents">
                    <ExternalDocumentModule functionalLocation={ functionalLocation } />
                </Tab>
            );
        }

        if (features && features.sr) {
            tabs.push(
                <Tab title="New Service Request" key="New Service Request">
                    <ServiceRequest />
                </Tab>
            );
        }

        if (showContactCaverionLink) {
            tabs.push(
                <Tab title="Contact Caverion" key="Contact Caverion">
                    <ContactModule />
                </Tab>
            );
        }

        if (isServiceOrdersEnabled(features)) {
            tabs.push(
                <Tab title="Service Orders" key="Service Orders">
                    <MaintenanceModule functionalLocation={ functionalLocation } partnerNumber={ partnerNumber } />
                </Tab>
            );
        }

        if (isConditionsEnabled(features)) {
            tabs.push(
                <Tab title="Conditions" key="Conditions" disabled={ isEmpty(sensorCategories)}>
                    <ConditionListModule
                        t={ t }
                        sensorCategories={ sensorCategories }
                        valuesBySensorId={ valuesBySensorId }
                        latestValuesBySensorId={ latestValuesBySensorId }
                        functionalLocation={ functionalLocation }
                        buildingMeta={ meta }
                        sensorAlarmsById={ sensorAlarmsById }
                    />
                </Tab>
            );
        }

        if (features && features.controlRoomTab) {
            tabs.push(
                <Tab title="Control Room" key="Control Room">
                    <ControlRoomModule
                        t={ t }
                        observations={ observations }
                        inspections={ inspections }
                        alarms={ alarms }
                        sla={ sla }
                    />
                </Tab>
            );
        }

        if (features && features.buildingAutomationTab) {
            tabs.push(
                <Tab title="Building" key="Building">
                    <BuildingAutomationModule
                        t={ t }
                        functionalLocation={ functionalLocation }
                        sensorValues={ sensorValues }
                        buildingConditions={ buildingConditions }
                        buildingSensors={ buildingSensors }
                        loading={ loadingParent }
                        floors={ floors }
                        buildingMeta={ meta }
                    />
                </Tab>
            );
        }

        if (features && features.floorsTab && showBuildingManagement) {
            tabs.push(
                <Tab title="Floors" key="Floors">
                    <FloorplanModule
                        t={ t }
                        functionalLocation={ functionalLocation }
                        floors={ floors }
                        sensors={ buildingSensors }
                        sensorValues={ sensorValues }
                        latestValuesBySensorId={ latestValuesBySensorId }
                        loading={ loadingParent }
                        partnerNumber={ partnerNumber }
                        buildingMeta={ meta }
                        sensorAlarmsById={ sensorAlarmsById }
                    />
                </Tab>
            );
        }

        if (features && features.cleaningTab) {
            tabs.push(
                <Tab title="Cleaning" key="Cleaning">
                    <CleaningModule
                        t={ t }
                        cleaningValues={ cleaningValues }
                        floors={ floors }
                    />
                </Tab>
            );
        }

        if (features && features.energyTab) {
            tabs.push(
                <Tab title="Energy" key="Energy">
                    <EnergyModule
                        functionalLocation={ functionalLocation }
                        buildingSensors={ buildingSensors }
                        buildingConditions={ buildingConditions }
                        sensorTypes={ sensorHierarchy && sensorHierarchy.sensorDataTypes }
                        sensorAlarmsById={ sensorAlarmsById }
                    />
                </Tab>
            );
        }

        if (isTechnicalTabEnabled(features)) {
            tabs.push(
                <Tab title="Technical" key="Technical">
                    <FunctionalLocationsModule
                        t={ t }
                        loadingChildren={ loadingChildren }
                        childFunctionalLocations={ childFunctionalLocations }
                        partnerNumber={ partnerNumber }
                        page={ page }
                    />
                </Tab>
            );
        }

        if (equipments && isEquipmentEnabled(features)) {
            tabs.push(
                <Tab title="Equipment" key="Equipment">
                    <EquipmentModule
                        equipments={ equipments[functionalLocation.functionalLocation] }
                        subEquipments={ subEquipments }
                        loadingEquipment={ loadingEquipment }
                    />
                </Tab>
            );
        }
        if (features && features.announcementsTab) {
            tabs.push(
                <Tab title="News" key="News">
                    <AnnouncementsModule
                        t={ t }
                        announcements={ activeAnnouncementsList }
                        profile={ profile }
                        functionalLocationId={ functionalLocation.functionalLocation }
                    />
                </Tab>
            );
        }

        forEach(customViews, customView => {
            tabs.push(
                <Tab key={ customView.id } title={ customView.viewName }>
                    <CustomViewModule customView={ customView } />
                </Tab>
            );
        });

        return (
            <Fragment>
                <Header
                    t={ t }
                    context={ this.memoizedContext(context, loadingParent, functionalLocation) }
                    links={ this.memoizedLinks(links) }
                    linksMobile={ this.memoizedLinksMobile(linksMobile) }
                />
                <Hero
                    functionalLocation={ functionalLocation }
                    title={ functionalLocation.description }
                    type={ functionalLocation.type }
                    loadingContext={ loadingParent }
                    t={ t }
                    showAddress
                    showNewServiceRequest={ features && features.sr }
                    showContactCaverion={ showContactCaverionLink }
                    partnerNumber={ partnerNumber }
                    heroImage={ buildingImage }
                    profile={ profile }
                    isBuilding
                    meta={ this.getHeroMeta(meta, t) }
                />
                <Tabs t={ t } useKeysToSelectTab>
                    { tabs }
                </Tabs>
            </Fragment>
        );
    }
}

export default BusinessUnit;
