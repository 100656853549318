import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { includes } from 'lodash';
import queryString from 'query-string';

import FilterDropdown, { noFilter } from 'components/FilterDropdown/FilterDropdown';
import { FILTERABLE_FIELDS } from 'constants/serviceCalendar';
import SideFilter from 'components/SideFilter/SideFilter';
import FilterCheckboxes from './FilterCheckboxes';
import CalendarFilterRow from './CalendarFilterRow';


const SideFilterContent = styled.div`
    display: flex;
    flex-direction: column;
`;
SideFilterContent.displayName = 'SideFilterContent';

const SideFilterItem = styled.div`
    width: 100%;
    margin-bottom: ${props => props.theme.spacing.md};
`;
SideFilterItem.displayName = 'SideFilterItem';

const NoData = styled.p`
    color: ${props => props.theme.input.font.color.default};
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.font.size.xs};
`;
NoData.displayName = 'NoData';

const sort = ['text', 'value'];

const labels = {
    type: 'Service Order Type',
    source: 'Source',
    location: 'Location',
    address: 'Address',
    status: 'Status',
    priority: 'Priority',
};

const dropDownFilters = ['location', 'address', 'discipline'];

const getFilterUri = (location, filter, value) => {
    const query = queryString.parse(location.search);
    if (value === noFilter) {
        delete query[filter];
    }
    else {
        // Clear address filter when functional location filter is set
        if (filter === 'functionalLocation') {
            delete query.address;
        }
        query[filter] = value;
    }

    // Reset pager when filters change
    if (query.page) {
        query.page = 1;
    }

    return `${location.pathname}?${queryString.stringify(query)}`;
};

export const CalendarFilters = ({ t, options, activeFilter, history, location, loading, loadingFLs }) => {
    const query = queryString.parse(location.search);
    const showFiltering = query.showFiltering === 'true';

    const setFilter = (filter, value) => history.push(getFilterUri(location, filter, value));

    const toggleShowFiltering = () => setFilter('showFiltering', !showFiltering);

    return (
        <Fragment>
            <CalendarFilterRow
                t={ t }
                activeFilter={ activeFilter }
                setFilter={ setFilter }
                loading={ loading }
                filterOptions={ options }
            />
            <SideFilter
                t={ t }
                useSideToggle={ false }
                toggleFilterClick={ toggleShowFiltering }
                isOpen={ showFiltering }
                filtersOn={ !!activeFilter }
            >
                <SideFilterContent>
                    { FILTERABLE_FIELDS
                        .filter(field => loading || options[field] && options[field].length > 0)
                        .map(field =>
                            <SideFilterItem key={ field }>
                                { includes(dropDownFilters, field)
                                    ? <FilterDropdown
                                        label={ t(labels[field] || field) }
                                        options={ options[field] }
                                        selectedFilter={ activeFilter[field] || noFilter }
                                        loading={ loading || loadingFLs && field === 'location' }
                                        sort={ sort }
                                        handleChange={ value => setFilter(field, value) }
                                        showAllText={ options[field].length === 1
                                            ? t(options[field][0].text)
                                            : t('Show all')
                                        }
                                        noSpacing
                                        disabled={ options[field].length === 1 }
                                    />
                                    : <FilterCheckboxes
                                        label={ t(labels[field] || field) }
                                        field={ field }
                                        options={ options[field] }
                                        selectedFilter={ activeFilter[field] }
                                        sort={ sort }
                                        setFilter={ setFilter }
                                        loading={ loading }
                                    />
                                }
                            </SideFilterItem>
                        )
                    }

                    { !loading && FILTERABLE_FIELDS.reduce((accu, field) => accu + options[field].length, 0) === 0 &&
                        <NoData>{ t('No data available') }</NoData>
                    }
                </SideFilterContent>
            </SideFilter>
        </Fragment>
    );
};

CalendarFilters.propTypes = {
    t: PropTypes.func.isRequired,
    options: PropTypes.shape(FILTERABLE_FIELDS.reduce((acc, field) => ({
        ...acc,
        [field]: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        })).isRequired,
    }), {})).isRequired,
    activeFilter: PropTypes.shape(FILTERABLE_FIELDS.reduce((acc, field) => ({
        ...acc,
        [field]: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }), {})),
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    loadingFLs: PropTypes.bool.isRequired,
};

export default React.memo(CalendarFilters);
