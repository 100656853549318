import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import EventsRow from 'components/BuildingEvents/EventsRow/EventsRow';

const StyledLink = styled(Link)`
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    padding: ${props => `${props.theme.spacing.md} ${props.theme.spacing.xs}`};
    display: block;
    vertical-align: middle;

    ${props => props.theme.media.landscape`
        padding: ${props => props.theme.spacing.md};
    `}
`;


const UserSearchResultRow = ({ rowData }) => {
    const link = `/Admin/Users/Edit/${rowData.id}`;
    const created = rowData.created ? moment(rowData.created).local().format('DD.MM.YYYY') : '-';
    const lastLogin = rowData.lastLogin ? moment(rowData.lastLogin).local().format('DD.MM.YYYY HH:mm') : '-';

    return (
        <EventsRow link>
            <td>
                <StyledLink to={ link }>{ rowData.fullName }</StyledLink>
            </td>
            <td>
                <StyledLink to={ link }>{ rowData.username }</StyledLink>
            </td>
            <td>
                <StyledLink to={ link }>{ rowData.email }</StyledLink>
            </td>
            <td>
                <StyledLink to={ link }>{ created }</StyledLink>
            </td>
            <td>
                <StyledLink to={ link }>{ lastLogin }</StyledLink>
            </td>
        </EventsRow>
    );
};

UserSearchResultRow.propTypes = {
    rowData: PropTypes.shape({
        fullName: PropTypes.string,
        username: PropTypes.string,
        email: PropTypes.string,
        lastLogin: PropTypes.string,
    }).isRequired,
};

export default React.memo(UserSearchResultRow);
