import { round, isNil } from 'lodash';
import { performanceColors, getPerformanceStatus } from 'utils/Data/performance';

export const priorityValue = stats => {
    if (stats) {
        const value = stats.sampleSize === 0
            ? 0
            : stats.totalHours / stats.sampleSize;
        return round(value);
    }
    return '-';
};

export const priorityColor = (value, theme, noOrders, sla) =>
    value === '-' || isNil(sla) || noOrders
        ? theme.colors.midnight
        : value <= sla
            ? theme.colors.emerald
            : theme.colors.radicalRed;

export const prioritySlaValue = stats => {
    if (stats) {
        const value = stats.slaSampleSize === 0
            ? 0
            : stats.slaCount / stats.slaSampleSize;
        return round(value * 100);
    }
    return '-';
};

export const prioritySlaColor = (value, theme, noOrders) =>
    value === '-' || noOrders
        ? theme.colors.midnight
        : performanceColors({ theme })[getPerformanceStatus(value)];


export const formatValue = (value, isPercent) => isPercent ? value : `${value} ${value !== '-' ? 'h' : ''}`;

export const hasNoOrders = stats => stats.sampleSize === 0;
