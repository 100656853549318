import React from 'react';
import PropTypes from 'prop-types';
import { map, compact, mean, isEmpty, pickBy, includes } from 'lodash';

import OPICards from 'components/OPICard/OPICards';
import OPICard from 'components/OPICard/OPICard';
import { getOpiCards } from '../Modules/EnergyModule/EnergyModuleUtils';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';

export const EnergyOptimizationOpiCards = ({
    totalValues,
    valuesByType,
    t,
    energyRatingValuesByFL,
    filterableFLs,
    hasFilters
}) => {
    const calculateEnergyRatingValuesByFL = ({ averages }, filterableFLs) => {
        const { previous, latest } = averages || {};

        // filter is not on, use already calculated values
        if (isEmpty(filterableFLs)) {
            return Object.assign({}, {
                previous,
                latest,
                unit: 'kWh/m²'
            });
        }
        // filter is on, pick matching FLs and calculates avg for those
        const filteredFLs = pickBy(energyRatingValuesByFL, (fl, key) => includes(filterableFLs, key));
        if (!isEmpty(filteredFLs) ) {
            return Object.assign({}, {
                latest: mean(compact(map(filteredFLs, ({ latest }) => latest))),
                previous: mean(compact(map(filteredFLs, ({ previous }) => previous))),
                unit: 'kWh/m²'
            });
        }
        return {};
    };

    const calculatedEnergyRatingValuesByFL = calculateEnergyRatingValuesByFL(energyRatingValuesByFL, filterableFLs);
    const opiCards = getOpiCards(totalValues, valuesByType, calculatedEnergyRatingValuesByFL, t);

    return (
        opiCards &&
        opiCards.length > 0 &&
            <OPICards noPaddingTop={ hasFilters } smallMargin={ !hasFilters } noSmallMarginMobile>
                { opiCards.map((card, idx) =>
                    <OPICard
                        key={`card${idx}`}
                        {...card}
                        noCircle
                        t={t}
                        ctxHelp={ `${CTXHELP_PREFIX} Energy ${card.ctxHelpType} OPI` }
                        ctxHelpPosition={(idx + 1) % 3 === 0 || (idx + 1) % 4 === 0 ? 'left' : 'top'}
                    />
                ) }
            </OPICards>
    );
};

EnergyOptimizationOpiCards.defaultProps = {
    totalValues: {},
    valuesByType: {}
};

EnergyOptimizationOpiCards.propTypes = {
    energyRatingValuesByFL: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    totalValues: PropTypes.object,
    valuesByType: PropTypes.object,
    hasFilters: PropTypes.bool.isRequired
};
