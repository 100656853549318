import Prismic from 'prismic-javascript';
import scrollToElement from 'scroll-to-element';
import { each } from 'lodash';

// use dependencies wrapper coz of units
// to easily mock 3rd party exported function
export const dependencies = { scrollToElement };

export const SLICE_TYPE = {
    CONTENT_SECTION: 'sectionWithContent',
    SECTION_SUBJECT: 'sectionSubject'
};

export const CUSTOM_TYPE = {
    ADMIN_USER_MANUAL: 'adminusermanual',
    GENERAL_USER_MANUAL: 'usermanual'
};

export const getDocumentByCustomType = customType =>
    Prismic.api(process.env.REACT_APP_PRISMIC_ENDPOINT, { accessToken: process.env.REACT_APP_PRISMIC_TOKEN })
        .then(api => api.query(Prismic.Predicates.at('document.type', customType), { lang: 'en-us' }));

export const getSectionTitleText = (section, trimmed = true) => {
    let text = '';
    if (section.primary
        && section.primary.contentTitle
        && section.primary.contentTitle.length > 0
        && section.primary.contentTitle[0].text) {
        text = trimmed
            ? section.primary.contentTitle[0].text.replace(/[^A-Z0-9]/ig, '')
            : section.primary.contentTitle[0].text;
    }
    return text;
};

export const getSectionSubjectText = (section, trimmed = true) => {
    let text = '';
    if (section.primary
        && section.primary.contentSubject
        && section.primary.contentSubject.length > 0
        && section.primary.contentSubject[0].text) {
        text = trimmed
            ? section.primary.contentSubject[0].text.replace(/[^A-Z0-9]/ig, '')
            : section.primary.contentSubject[0].text;
    }
    return text;
};


export const scrollTo = id => {
    const isMobile = window.innerWidth < 900;
    const offset = isMobile ? -200 : -84;
    dependencies.scrollToElement(`#${id}`, { offset, duration: 1000 });
};

export const getSectionOptions = data => {
    const options = [];
    if (data && data.body) {
        each(data.body, singleSectionContent => {
            switch (singleSectionContent.slice_type) {
            case SLICE_TYPE.SECTION_SUBJECT: {
                options.push({
                    label: getSectionSubjectText(singleSectionContent, false),
                    value: getSectionSubjectText(singleSectionContent),
                    disabled: true }); // use react-select disabled flag as a workaround to show group headings in mobile
                break;
            }
            case SLICE_TYPE.CONTENT_SECTION: {
                options.push({
                    label: getSectionTitleText(singleSectionContent, false),
                    value: getSectionTitleText(singleSectionContent) });
                break;
            }
            default:
                break;
            }
        });
    }
    return options;
};

export const generateCopiedUrl = title => `${window.location.origin}${window.location.pathname}?title=${title}`;
