import * as React from 'react';
import * as PropTypes from 'prop-types';
import styled, { withTheme, css } from 'styled-components';
import { transparentize } from 'polished';

import Svg from 'components/Svg/Svg';
import translations from 'decorators/Translations/translations';

const getBackgroundColor = props => {
    if (props.disabled) {
        return props.theme.colors.alabaster;
    }
    else if (props.added) {
        return transparentize(0.9, props.theme.colors.emerald);
    }
    else if (props.removed) {
        return transparentize(0.9, props.theme.colors.radicalRed);
    }

    return 'transparent';
};

const PermissionListItemRoot = styled.div`
    display: flex;
    align-items: center;
    padding: ${props => props.theme.spacing.xs} 0;
    border-bottom: 1px solid ${props => props.theme.colors.lightGray};
    color: ${props => props.theme.colors.black};
    fill: ${props => props.theme.colors.blue};
    background-color: ${getBackgroundColor};
    cursor: ${props => props.clickable === false ? 'default' : 'pointer'};
    min-height: 56px;
`;


const Toggle = styled.div`
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const ToggleIcon = styled(({ hideAddIcon, ...props }) => <Svg { ...props } />)`
    margin: ${props => props.name === 'plus' ? '0 0.9em' : '0 15px'};
    margin-right: 0;
    fill: ${props => props.name === 'plus' ? props.theme.colors.blue : props.theme.colors.darkGray};
    font-size: ${props => props.name === 'plus' ? '16px' : '12px'};

    ${props => props.name === 'plus' && props.hideAddIcon && css`
        display: none;
    `}
`;

const PermissionListItem = ({ t, children, added, included, removed, add, remove, ...other }) => {
    const hideAddIcon = added || included || removed;
    return (
        <PermissionListItemRoot {...other} added={added} removed={removed}>
            {children}
            <Toggle
                onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    return add ? add() : remove();
                } }
            >
                <ToggleIcon
                    hideAddIcon={ hideAddIcon }
                    name={ add ? 'plus' : 'remove' }
                    alt={ add ? t('Add Functional Location') : t('Delete Functional Location') }
                />
            </Toggle>
        </PermissionListItemRoot>
    );
};

PermissionListItem.propTypes = {
    t: PropTypes.func.isRequired,
    added: PropTypes.bool,
    removed: PropTypes.bool,
    disabled: PropTypes.bool,
    add: PropTypes.func,
    remove: PropTypes.func,
    children: PropTypes.any,
    included: PropTypes.bool
};

export default translations(withTheme(PermissionListItem));
