import React from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { Icon, ICON_TYPES } from 'components/Icon/Icon';
import { icons } from 'components/Icon/IconNames';
import Loader from 'components/Loader/Loader.jsx';

const DropZoneContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: ${props => props.height || '200px'};
    border: 1px dotted ${props => props.theme.colors.cerulean};
    text-align: center;
    padding: ${props => props.theme.spacing.md} ${props => props.theme.spacing.xl};
    background: ${props => transparentize(0.85, props.theme.colors.cerulean)};
    cursor: pointer;
`;

const DropZoneHeaderText = styled.h3`
    color: ${props => props.theme.colors.cerulean};
    font-size: ${props => props.theme.font.size.xs};
    font-family: ${props => props.theme.font.family.arial};
    font-weight: ${props => props.theme.font.weight.bold};
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: ${props => props.theme.spacing.sm};
`;

const DropZoneDescription = styled.p`
    color: ${props => props.theme.colors.cerulean};
    font-size: ${props => props.theme.font.size.xs};
    line-height: ${props => props.theme.font.lineHeight.md};
`;

const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5em;
    height: 5em;
    margin-top: 0.5em
`;

// Using dropzone, styling props from here: https://react-dropzone.netlify.com/
const FileUpload = props => {
    const {
        upload,
        t,
        uploading,
        height,
        allowedMimeTypes = ['svg', 'png', 'jpg'],
        maxFileSize,
        noIcon,
        header = t('Dropzone Header'),
        noMimeTypes = false,
        multiple = false,
        infoText,
        accept
    } = props;

    let firstItems = allowedMimeTypes;
    let lastItem;
    if (allowedMimeTypes && allowedMimeTypes.length > 1) {
        firstItems = allowedMimeTypes.slice(0, allowedMimeTypes.length - 1);
        lastItem = allowedMimeTypes[allowedMimeTypes.length - 1];
    }

    return <Dropzone onDrop={upload} style={{}} multiple={multiple} accept={accept}>
        <DropZoneContainer height={height}>
            { uploading && <LoaderWrapper><Loader size="LARGE" /></LoaderWrapper> }
            { !uploading && !noIcon && <Icon name={icons.IMAGES} iconType={ICON_TYPES.DROPZONE}/> }

            <DropZoneHeaderText>{ header }</DropZoneHeaderText>

            { !noMimeTypes && firstItems && firstItems.length &&
                <DropZoneDescription>
                    { lastItem
                        ? t('Allowed file types are {0} and {1}', firstItems.join(', '), lastItem)
                        : t('Allowed file type is {0}', firstItems[0]) }
                </DropZoneDescription>
            }
            { infoText &&
                <DropZoneDescription>
                    { infoText }
                </DropZoneDescription>
            }
            { maxFileSize &&
                <DropZoneDescription>
                    { `${t('Maximum file size')}: ${maxFileSize}` }
                </DropZoneDescription>
            }
        </DropZoneContainer>
    </Dropzone>;
};

export default FileUpload;
