import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Svg from 'components/Svg/Svg';

const Content = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 38em;
    max-width: 38em;

    ${props => props.theme.media.portrait`
        width: 48em;
        max-width: 48em;
        padding-bottom: ${props => props.theme.section.spacing};
    `}
`;
Content.displayName = 'Content';

const Icon = styled(Svg)`
    fill: ${props => props.theme.colors.rockBlue};
    font-size: 80px;
    margin: ${props => props.theme.spacing.xxl};
`;
Icon.displayName = 'Icon';

const Title = styled.h1`
    color: ${props => props.theme.colors.rockBlue};
    text-align: center;
    white-space: normal;
    width: inherit;
`;
Title.displayName = 'Title';

const Instructions = styled.p`
    font-weight: ${props => props.theme.font.weight.normal};
    font-size: ${props => props.theme.font.size.sm};
    padding: ${props => props.theme.section.spacing};
    text-align: center;
    line-height: ${props => props.theme.lineHeight.text};
`;
Instructions.displayName = 'Instructions';

const Button = styled.a`
    cursor: pointer;
    display: inline-block;
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.font.size.xxxs};
    text-transform: uppercase;
    letter-spacing: 1px;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.cerulean};
    border-radius: 2em;
    padding: ${props => props.theme.spacing.md} ${props => props.theme.spacing.xxl};
`;
Button.displayName = 'Button';

const ErrorPageContent = ({ icon, title, instructions, buttonText, buttonLink }) => {
    return (
        <Content>
            <Icon name={ icon } />
            <Title>{ title }</Title>
            <Instructions>{ instructions }</Instructions>
            { buttonLink && <Button href={ buttonLink }>{ buttonText }</Button> }
        </Content>
    );
};

ErrorPageContent.defaultProps = {
    icon: null,
    title: null,
    instructions: null,
    buttonLink: null,
    buttonText: null,
};

ErrorPageContent.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    instructions: PropTypes.string,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string,
};

export default ErrorPageContent;
