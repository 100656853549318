import moment from 'moment';
import { createReducerFromMapping } from 'redux/utils';
import { MasterData } from '@caverion/redux/api/actions';
import { indexByDate } from '../../utils/indexing';

const initialState = {
    kpiCounts: {
        loading: false,
        serviceOrders: {
            completed: 0,
            total: 0,
        },
        plannedMaintenances: {
            completed: 0,
            total: 0,
        },
    },
    index: indexByDate.getInitialState(),
    objects: {},
    operations: {},
    loading: {},
    search: {},
};

export const LOAD = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD';
export const LOAD_SUCCESS = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_SUCCESS';
export const LOAD_FAIL = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_FAIL';

export const SEARCH_SET_TEXT = 'CUSTOMER_PLATFORM/ServiceOrders/SEARCH_SET_TEXT';
export const SEARCH = 'CUSTOMER_PLATFORM/ServiceOrders/SEARCH';
export const SEARCH_SUCCESS = 'CUSTOMER_PLATFORM/ServiceOrders/SEARCH_SUCCESS';
export const SEARCH_FAIL = 'CUSTOMER_PLATFORM/ServiceOrders/SEARCH_FAIL';

export const LOAD_OBJECTS = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_OBJECTS';
export const LOAD_OBJECTS_SUCCESS = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_OBJECTS_SUCCESS';
export const LOAD_OBJECTS_FAIL = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_OBJECTS_FAIL';

export const LOAD_OPERATIONS = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_OPERATIONS';
export const LOAD_OPERATIONS_SUCCESS = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_OPERATIONS_SUCCESS';
export const LOAD_OPERATIONS_FAIL = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_OPERATIONS_FAIL';

export const LOAD_COUNTS = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_COUNTS';
export const LOAD_COUNTS_SUCCESS = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_COUNTS_SUCCESS';
export const LOAD_COUNTS_FAIL = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_COUNTS_FAIL';


const getDateRangeWhere = (startDate, endDate) => ({
    createdDate: {
        between: [startDate.toISOString(), endDate.toISOString()],
    },
});

const verifyDates = (start, end) => {
    if (!start.isValid() || !end.isValid()) {
        throw new Error('Invalid start or end date: Must be a valid moment instance');
    }

    const daysBetween = end.diff(start, 'day');
    if (daysBetween < 0) {
        throw new Error(`Days between cannot be less than zero for ${start.format()} and ${end.format()}`);
    }
};

export const loadServiceOrder = (serviceOrderNumber, partnerNumber, functionalLocation, key) => async dispatch => {
    const filter = JSON.stringify({
        where: {
            serviceOrderNumber,
            path: {
                'any x': {
                    x: functionalLocation,
                },
            },
        },
    });

    dispatch({ type: LOAD, key });
    try {
        const result = await dispatch(MasterData.serviceOrders(filter, partnerNumber));
        return dispatch({
            type: LOAD_SUCCESS,
            key,
            result,
        });
    } catch (error) {
        return dispatch({
            type: LOAD_FAIL,
            key,
            error,
        });
    }
};

export const loadServiceOrders = (partnerNumber, functionalLocation, equipmentNumber, startDate, endDate, key) => {
    verifyDates(startDate, endDate);
    const filter = JSON.stringify({
        where: {
            ...getDateRangeWhere(startDate, endDate),
            path: {
                'any x': {
                    x: functionalLocation,
                },
            },
            ...equipmentNumber && {
                equipmentNumber: {
                    'any x': {
                        x: equipmentNumber,
                    },
                },
            },
        }
    });

    return async dispatch => {
        dispatch({ type: LOAD, key });
        try {
            const result = await dispatch(MasterData.serviceOrders(filter, partnerNumber));
            return dispatch({
                type: LOAD_SUCCESS,
                key,
                result,
            });
        } catch (error) {
            return dispatch({
                key,
                functionalLocation: functionalLocation.functionalLocation,
                type: LOAD_FAIL,
                error
            });
        }
    };
};

export const loadAllServiceOrders = (startDate, endDate, partnerNumber, key) => async dispatch => {
    verifyDates(startDate, endDate);
    dispatch({ type: LOAD, key });
    try {
        const result = await dispatch(MasterData.serviceOrdersForUser(
            startDate.toISOString(),
            endDate.toISOString(),
            partnerNumber !== 'all' ? partnerNumber : undefined,
        ));

        return dispatch({
            type: LOAD_SUCCESS,
            key,
            result,
        });
    } catch (error) {
        return dispatch({
            type: LOAD_FAIL,
            key,
            error,
        });
    }
};

export const setServiceOrderSearchText = (section, text) => ({
    type: SEARCH_SET_TEXT,
    section,
    text,
});

export const searchServiceOrdersAndPlanned = (section, text, partnerNumber, functionalLocation) => async dispatch => {
    dispatch({ type: SEARCH, section });
    try {
        const result = await dispatch(MasterData.serviceOrdersSearch(text, partnerNumber, functionalLocation, 1000));
        return dispatch({
            type: SEARCH_SUCCESS,
            section,
            result,
        });
    } catch (error) {
        return dispatch({
            type: SEARCH_FAIL,
            section,
            error,
        });
    }
};

export const loadCounts = (startDate, endDate, functionalLocation, partnerNumber) => {
    verifyDates(startDate, endDate);
    return async dispatch => {
        dispatch({ type: LOAD_COUNTS });
        try {
            const result = await dispatch(MasterData.serviceOrdersCountsForUser(
                startDate.toISOString(),
                endDate.toISOString(),
                partnerNumber !== 'all' ? partnerNumber : undefined,
                functionalLocation ? functionalLocation.functionalLocation : undefined
            ));
            return dispatch({
                type: LOAD_COUNTS_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_COUNTS_FAIL,
                error
            });
        }
    };
};

export const loadOperations = (orderId, functionalLocation) => {
    const filter = {
        where: {
            serviceOrderNumber: orderId,
            path: { 'any x': { x: functionalLocation } }
        }
    };

    return async dispatch => {
        dispatch({ type: LOAD_OPERATIONS });
        try {
            const result = await dispatch(MasterData.serviceOrderOperations(JSON.stringify(filter)));
            return dispatch({
                orderId,
                type: LOAD_OPERATIONS_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                orderId,
                type: LOAD_OPERATIONS_FAIL,
                error
            });
        }
    };
};

export const loadObjects = (serviceOrder, key) => {
    const orderId = serviceOrder.serviceOrderNumber;
    const filter = {
        where: {
            serviceOrderNumber: orderId,
            path: { 'any x': { x: serviceOrder.functionalLocation } },
        },
    };

    return async dispatch => {
        dispatch({ type: LOAD_OBJECTS, key });
        try {
            const result = await dispatch(MasterData.serviceOrderObjects(filter));
            return dispatch({
                type: LOAD_OBJECTS_SUCCESS,
                key,
                orderId,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_OBJECTS_FAIL,
                key,
                orderId,
                error
            });
        }
    };
};

const getOrderYear = order => moment(order.createdDate).year();


export default createReducerFromMapping({
    [LOAD]: (state, action) => ({
        ...state,
        loading: {
            ...state.loading,
            [action.key]: true,
        },
    }),
    [LOAD_SUCCESS]: (state, action) => ({
        ...state,
        index: indexByDate.index(state.index, action.result, 'serviceOrderNumber', getOrderYear),
        loading: {
            ...state.loading,
            [action.key]: false,
        },
    }),
    [LOAD_FAIL]: (state, action) => ({
        ...state,
        loading: {
            ...state.loading,
            [action.key]: false,
        },
    }),

    [SEARCH_SET_TEXT]: (state, action) => ({
        ...state,
        search: {
            ...state.search,
            [action.section]: {
                ...state.search[action.section],
                text: action.text,
            },
        },
    }),
    [SEARCH]: (state, action) => ({
        ...state,
        search: {
            ...state.search,
            [action.section]: {
                ...state.search[action.section],
                loading: true,
                items: [],
                total: 0,
                error: undefined,
            },
        },
    }),
    [SEARCH_SUCCESS]: (state, action) => ({
        ...state,
        index: indexByDate.index(state.index, action.result, 'serviceOrderNumber', getOrderYear),
        search: {
            ...state.search,
            [action.section]: {
                ...state.search[action.section],
                loading: false,
                items: action.result.items,
                total: action.result.total,
            },
        },
    }),
    [SEARCH_FAIL]: (state, action) => ({
        ...state,
        search: {
            ...state.search,
            [action.section]: {
                ...state.search[action.section],
                loading: false,
                items: [],
                total: 0,
                error: action.error,
            },
        },
    }),

    [LOAD_OBJECTS]: (state, action) => ({
        ...state,
        loading: {
            ...state.loading,
            [action.key]: true,
        },
    }),
    [LOAD_OBJECTS_SUCCESS]: (state, action) => ({
        ...state,
        objects: {
            ...state.objects,
            [action.orderId]: {
                items: action.result,
            },
        },
        loading: {
            ...state.loading,
            [action.key]: false,
        },
    }),
    [LOAD_OBJECTS_FAIL]: (state, action) => ({
        ...state,
        objects: {
            ...state.objects,
            [action.orderId]: {
                error: action.error,
            },
        },
        loading: {
            ...state.loading,
            [action.key]: false,
        },
    }),

    [LOAD_OPERATIONS]: (state, action) => ({
        ...state,
        operations: {
            ...state.operations,
            [action.orderId]: {
                loading: true,
            },
        },
    }),
    [LOAD_OPERATIONS_SUCCESS]: (state, action) => ({
        ...state,
        operations: {
            ...state.operations,
            [action.orderId]: {
                items: action.result,
                loading: false,
            },
        },
    }),
    [LOAD_OPERATIONS_FAIL]: (state, action) => ({
        ...state,
        operations: {
            ...state.operations,
            [action.orderId]: {
                error: action.error,
                loading: false,
            },
        },
    }),

    [LOAD_COUNTS]: state => ({
        ...state,
        kpiCounts: {
            ...initialState.kpiCounts,
            loading: true,
        },
    }),
    [LOAD_COUNTS_SUCCESS]: (state, action) => ({
        ...state,
        kpiCounts: {
            ...action.result,
            loading: false,
        },
    }),
    [LOAD_COUNTS_FAIL]: (state, action) => ({
        ...state,
        kpiCounts: {
            ...state.kpiCounts,
            error: action.error,
            loading: false,
        },
    }),

}, initialState);
