import { createReducerFromMapping } from 'redux/utils/index.js';
import { MasterData } from '@caverion/redux/api/actions';


const initialState = {
    sensorMeta: {},
    sensorsForMeta: [],
    error: {},
};


export const UPSERT_ALL_SENSOR_META = 'CUSTOMER_PLATFORM/Building/DELETE_SENSOR_META';
export const UPSERT_ALL_SENSOR_META_SUCCESS = 'CUSTOMER_PLATFORM/Building/DELETE_SENSOR_META_SUCCESS';
export const UPSERT_ALL_SENSOR_META_FAIL = 'CUSTOMER_PLATFORM/Building/DELETE_SENSOR_META_FAIL';

export const upsertAllSensorMeta = sensorMetaArray => {
    return async dispatch => {
        dispatch({ type: UPSERT_ALL_SENSOR_META });
        try {
            const result = await dispatch(MasterData.upsertAllSensorMeta(sensorMetaArray));
            return dispatch({
                type: UPSERT_ALL_SENSOR_META_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: UPSERT_ALL_SENSOR_META_FAIL,
                error
            });
        }
    };
};


export const DELETE_SENSOR_META = 'CUSTOMER_PLATFORM/Building/DELETE_SENSOR_META';
export const DELETE_SENSOR_META_SUCCESS = 'CUSTOMER_PLATFORM/Building/DELETE_SENSOR_META_SUCCESS';
export const DELETE_SENSOR_META_FAIL = 'CUSTOMER_PLATFORM/Building/DELETE_SENSOR_META_FAIL';

export const deleteSensorMeta = id => {
    return async dispatch => {
        dispatch({ type: DELETE_SENSOR_META });
        try {
            const result = await dispatch(MasterData.deleteSensorMeta(id));
            return dispatch({
                type: DELETE_SENSOR_META_SUCCESS,
                id,
                result
            });
        } catch (error) {
            return dispatch({
                type: DELETE_SENSOR_META_FAIL,
                error
            });
        }
    };
};


export const SENSOR_META_FOR_SENSOR = 'CUSTOMER_PLATFORM/Building/SENSOR_META_FOR_SENSOR';
export const SENSOR_META_FOR_SENSOR_SUCCESS = 'CUSTOMER_PLATFORM/Building/SENSOR_META_FOR_SENSOR_SUCCESS';
export const SENSOR_META_FOR_SENSOR_FAIL = 'CUSTOMER_PLATFORM/Building/SENSOR_META_FOR_SENSOR_FAIL';

export const sensorMetaForSensor = (id, path) => {
    return async dispatch => {
        dispatch({ type: SENSOR_META_FOR_SENSOR });
        try {
            const result = await dispatch(MasterData.sensorMetaForSensor(id));
            return dispatch({
                type: SENSOR_META_FOR_SENSOR_SUCCESS,
                id,
                result
            });
        } catch (error) {
            return dispatch({
                type: SENSOR_META_FOR_SENSOR_FAIL,
                error
            });
        }
    };
};


export const SENSOR_BUILDINGS_FOR_META = 'CUSTOMER_PLATFORM/Building/SENSOR_BUILDINGS_FOR_META';
export const SENSOR_BUILDINGS_FOR_META_SUCCESS = 'CUSTOMER_PLATFORM/Building/SENSOR_BUILDINGS_FOR_META_SUCCESS';
export const SENSOR_BUILDINGS_FOR_META_FAIL = 'CUSTOMER_PLATFORM/Building/SENSOR_BUILDINGS_FOR_META_FAIL';

export const sensorBuildingsForMeta = meta => {
    return async dispatch => {
        dispatch({ type: SENSOR_BUILDINGS_FOR_META });
        try {
            const result = await dispatch(MasterData.sensorBuildingsForSensorMeta(meta));
            return dispatch({
                type: SENSOR_BUILDINGS_FOR_META_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: SENSOR_BUILDINGS_FOR_META_FAIL,
                error
            });
        }
    };
};


export default createReducerFromMapping({
    [UPSERT_ALL_SENSOR_META_SUCCESS]: (state, action) => ({
        ...state
    }),
    [UPSERT_ALL_SENSOR_META_FAIL]: (state, action) => ({
        ...state,
        error: action.error
    }),

    [DELETE_SENSOR_META_SUCCESS]: (state, action) => ({
        ...state
    }),
    [DELETE_SENSOR_META_FAIL]: (state, action) => ({
        ...state,
        error: action.error
    }),

    [SENSOR_META_FOR_SENSOR_SUCCESS]: (state, action) => ({
        ...state,
        sensorMeta: { ...state.sensorMeta, [action.id]: action.result }
    }),
    [SENSOR_META_FOR_SENSOR_FAIL]: (state, action) => ({
        ...state,
        error: action.error
    }),

    [SENSOR_BUILDINGS_FOR_META_SUCCESS]: (state, action) => ({
        ...state,
        sensorsForMeta: action.result
    }),
    [SENSOR_BUILDINGS_FOR_META_FAIL]: (state, action) => ({
        ...state,
        error: action.error
    }),

}, initialState);
