import React from 'react';
import styled from 'styled-components';

const statusColors = props => ({
    open: props.theme.serviceOrder.openColor,
    completed: props.theme.serviceOrder.completedColor,
    'in_progress': props.theme.serviceOrder.inProgressColor,
    'partly_completed': props.theme.serviceOrder.partlyCompletedColor,
    postponed: props.theme.serviceOrder.postponedColor,
    planned: props.theme.serviceOrder.plannedColor
});

const StyledStatusTextColumn = styled.div`
    color: ${props => props.theme.colors.black};
    font-size: ${props => props.largeText && props.theme.fontSize.subHeading};

    &:before {
        content: '';
        border-left: 3px solid ${props => statusColors(props)[props.status] || 'transparent'};
        position: absolute;
        top: 5px;
        bottom: 5px;
    }
`;

const Text = styled.span`
    display: inline-block;
    padding-left: ${props => props.paddingLeft && props.theme.spacing.md};
    word-break: break-word;
`;

const StatusText = styled.span`
    text-transform: uppercase;
    color: ${props => props.theme.colors.black};
    font-size: ${props => props.theme.font.size.xxxs};
    font-weight: ${props => props.theme.fontWeight.bold};
    padding-left: ${props => props.theme.spacing.sm};
`;

const getText = text => text && text.split('\n')[0];
const getStatus = (status, t) => status && t(status).slice(0, 4);

const StatusTextColumn = props => {
    const { text, status, humanReadableStatus } = props.data;
    return (
        <StyledStatusTextColumn status={ props.showStatusColor && status }>
            { text && <Text paddingLeft={ props.showStatusColor }>{ getText(text) }</Text> }
            { !text && <StatusText>{ getStatus(humanReadableStatus, props.t) }</StatusText> }
        </StyledStatusTextColumn>
    );
};

export default StatusTextColumn;
