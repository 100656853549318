export const variants = {
    HEADER: 'header',
    THUMBNAIL: 'thumbnail'
};

export const getOriginalImageUrl = url => {
    return url;
};

export const getHeaderImageUrl = url => {
    return getVariant(url, variants.HEADER);
};

export const getThumbnailImageUrl = url => {
    return getVariant(url, variants.THUMBNAIL);
};

// Get variant url from original url by adding _${variant} before filename extension
const getVariant = (url, variant) => {
    if (!variant) {
        return url;
    }
    const extension = getImageExtensionFromUrl(url);
    const pathWithoutExtension = removeExtensionFromUrl(url);
    return `${pathWithoutExtension}_${variant}.${extension}`;
};

// Get image extension
const getImageExtensionFromUrl = url => {
    return url.substring(url.lastIndexOf('.') + 1);
};

// Remove extension
const removeExtensionFromUrl = url => {
    return url.substring(0, url.lastIndexOf('.'));
};
