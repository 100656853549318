import React, { Component } from 'react';
import styled from 'styled-components';
import translations from 'decorators/Translations/translations';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { initializeNiagaraStation } from 'redux/modules/iot/device';
import {
    Button,
    InputRow,
    InputLabel,
    InputText,
    InputForm,
} from 'components/index.js';
import DialogModal from 'components/Dialog/DialogModal';
import DialogFooter from 'components/Dialog/DialogFooter';

const InputActions = styled.div`
    display: none;
    width: 100%;
    margin: ${props => props.theme.spacing.md};
    text-align: right;

    ${props => props.theme.media.landscape`
        display: block;
    `};
`;
InputActions.displayName = 'InputActions';

export class InitializeNiagaraStation extends Component {
    state = {
        model: {
            deviceId: '',
            deviceKey: '',
            deviceHost: ''
        }
    }

    componentDidMount() {
        const {
            functionalLocation
        } = this.props;
        this.setState(state => ({
            model: {
                ...state.model,
                functionalLocation
            }
        }));
    }

    setProperty = (property, value) => {
        this.setState(state => ({
            model: {
                ...state.model,
                [property]: value
            }
        }));
    }

    handleSubmit = () => {
        const {
            initializeNiagaraStation,
            onSubmit
        } = this.props;
        initializeNiagaraStation(this.state.model).then(result => {
            onSubmit(result);
        });
    };

    render() {
        const {
            t,
            saving,
            onClose
        } = this.props;
        const {
            model
        } = this.state;

        return <DialogModal isActive onOverlayClick={ onClose } t={ t } footer={
            <DialogFooter>
                <Button cancel onClick={ onClose }>{ t('Close') }</Button>
                <Button submit loading={ saving } onClick={ this.handleSubmit }>{ t('Send') }</Button>
            </DialogFooter>
        }>
            <h4 data-test-id="InitializeNiagaraStation-formTitle">{ t('Initialize Niagara station') }</h4>
            <InputForm
                id={ this.props.id }
                model={ model }
                onPropertyChange={ this.setProperty }
                onSubmit={ this.handleSubmit }
            >
                <InputRow required>
                    <InputLabel id="deviceId" text={ t('Device id') } />
                    <InputText
                        id="deviceId"
                        property="deviceId"
                        placeholder={ t('Device id') }
                    />
                </InputRow>
                <InputRow required>
                    <InputLabel id="deviceKey" text={ t('Device key') } />
                    <InputText
                        id="deviceKey"
                        property="deviceKey"
                        placeholder={ t('Device key') }
                    />
                </InputRow>
                <InputRow required>
                    <InputLabel id="deviceHost" text={ t('Device host') } />
                    <InputText
                        id="deviceHost"
                        property="deviceHost"
                        placeholder={ t('Device host') }
                    />
                </InputRow>
            </InputForm>
        </DialogModal>;
    }
}

InitializeNiagaraStation.propTypes = {
    functionalLocation: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        saving: state.device.initializingStation
    };
};

const mapDispatchToProps = dispatch => ({
    initializeNiagaraStation: data => dispatch(initializeNiagaraStation(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(InitializeNiagaraStation));
