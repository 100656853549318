import React, { Fragment } from 'react';
import { clone, some, forEach } from 'lodash';

import MaintenanceModule from 'containers/Application/Modules/MaintenanceModule/MaintenanceModule.jsx';
import FunctionalLocationsModule from 'components/Modules/FunctionalLocationsModule/FunctionalLocationsModule.jsx';
import ExternalDocumentModule from 'containers/Application/Modules/ExternalDocumentModule/ExternalDocumentModule.jsx';
import EquipmentModule from 'containers/Application/Modules/EquipmentModule/EquipmentModule.jsx';
import Hero from 'components/Hero/Hero.jsx';
import Header from 'containers/Application/Header/Header.jsx';
import ServiceRequest from 'containers/Application/ServiceRequest/ServiceRequest';
import { Tabs, Tab } from 'components/index.js';
import { isTechnicalObject } from 'utils/Data/functionalLocations.js';
import {
    addServiceOrderLink,
    addCCCLink,
    addTechnicalLink,
    addEquipmentLink,
    addExternalDocumentsLink,
    addCustomViewLinks,
    addNewServiceRequestLink,
    isSystem
} from 'utils/Data/functionalLocations';
import CustomViewModule from 'containers/Application/CustomViews/CustomViewModule';

import { isServiceOrdersEnabled, isTechnicalTabEnabled, isEquipmentEnabled } from 'utils/Data/profileData';

const GenericFunctionalLocation = props => {
    const {
        functionalLocation,
        features,
        featureTeasers,
        equipments,
        parents,
        getHeroContext,
        loadingParent,
        loadingEquipment,
        t,
        partnerNumber,
        documentCount,
        loadingDocuments,
        equipmentCount,
        totalFunctionalLocations,
        loadingFunctionalLocations,
        noIoTData,
        buildingImage,
        loadingChildren,
        childFunctionalLocations,
        page,
        match: { url },
        customViews,
        profile,
    } = props;

    const type = functionalLocation.type || 'UNKNOWN';

    let links = [];
    links = addServiceOrderLink(links, url, features);
    links = addCCCLink(links, url, features, functionalLocation, noIoTData);
    links = addTechnicalLink(links, url, features, functionalLocation,
        totalFunctionalLocations, loadingFunctionalLocations, featureTeasers);
    links = addEquipmentLink(links, url, features, equipmentCount, loadingEquipment, featureTeasers);
    links = addExternalDocumentsLink(links, url, features, documentCount, loadingDocuments, featureTeasers);
    links = addCustomViewLinks(links, url, customViews);

    let linksMobile = clone(links);
    linksMobile = addNewServiceRequestLink(linksMobile, url, features);

    const heroContext = getHeroContext(parents, partnerNumber, features, profile.customViews);
    const context = Object.assign({}, heroContext, { loadingContext: loadingParent, functionalLocation });
    const showEquipment = some(childFunctionalLocations, isSystem);

    const tabs = [];

    if (features && features.documents) {
        tabs.push(
            <Tab
                title="External Documents"
                key="External Documents"
                count={ documentCount }
                loading={ loadingDocuments }
                disabled={ !(documentCount > 0) }>
                <ExternalDocumentModule functionalLocation={ functionalLocation } />
            </Tab>
        );
    }

    if (features && features.sr) {
        tabs.push(
            <Tab title="New Service Request" key="New Service Request">
                <ServiceRequest />
            </Tab>
        );
    }

    forEach(customViews, customView => {
        tabs.push(
            <Tab key={customView.id} title={customView.viewName}>
                <CustomViewModule customView={customView}/>
            </Tab>
        );
    });

    if (isServiceOrdersEnabled(features)) {
        tabs.push(
            <Tab title="Service Orders" key="Service Orders">
                <MaintenanceModule functionalLocation={ functionalLocation } partnerNumber={ partnerNumber } />
            </Tab>
        );
    }

    if (isTechnicalTabEnabled(features)) {
        tabs.push(
            <Tab title={ isTechnicalObject(functionalLocation) ? 'Systems' : 'Technical' } key={ isTechnicalObject(functionalLocation) ? 'Systems' : 'Technical' }>
                <FunctionalLocationsModule
                    t={ t }
                    loadingChildren={ loadingChildren }
                    childFunctionalLocations={ childFunctionalLocations }
                    partnerNumber={ partnerNumber }
                    page={ page }
                    hasEquipment={ showEquipment }
                    title={ isTechnicalObject(functionalLocation) && t('Systems') }
                    equipments={ equipments }
                />
            </Tab>
        );
    }

    if (equipments && isEquipmentEnabled(features)) {
        tabs.push(
            <Tab title="Equipment" key="Equipment">
                <EquipmentModule
                    equipments={ equipments }
                    loadingEquipment={ loadingEquipment }
                />
            </Tab>
        );
    }

    return (
        <Fragment>
            <Header context={ context } t={ t } links={ links } linksMobile={ linksMobile } />
            <Hero
                title={ functionalLocation.description }
                functionalLocation={ functionalLocation }
                type={ type }
                loadingContext={ loadingParent }
                t={ t }
                showAddress
                showNewServiceRequest={ features && features.sr }
                partnerNumber={ partnerNumber }
                heroImage={ buildingImage }
            />
            <Tabs t={ t } useKeysToSelectTab>
                { tabs }
            </Tabs>
        </Fragment>
    );
};

export default GenericFunctionalLocation;
