import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TableIcon from './TableIcon';
import { Icon, ICON_SIZES } from 'components/Icon/Icon';

const Column = styled.div`
    white-space: nowrap;
    display: flex;
    align-items: center;
`;
Column.displayName = 'Column';

const Name = styled.span`
    display: inline-block;
    width: 190px; // width is defined so that "text-overflow: ellipsis;" would work
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    font-size: ${props => props.theme.font.size.sm};
    font-weight: ${props => props.theme.font.weight.bold};
    line-height: ${props => props.theme.font.lineHeight.md};

    ${props => props.theme.media.portrait`
        width: auto;
        white-space: normal;
    `}

    ${props => props.theme.media.landscape`
        width: 150px;
        white-space: nowrap;
        font-weight: ${props => props.theme.font.weight.normal};
    `}

    ${props => props.theme.media.desktop`
        width: auto;
        white-space: normal;
    `}
`;
Name.displayName = 'Name';

const IconContainer = styled.span`
    margin-right: ${props => props.theme.spacing.md};
    font-size: ${props => props.theme.font.size.md};

    div {
        width: 2em;
        height: 2em;
        margin: 0;
        vertical-align: middle;
    }

    svg {
        margin: 0;
    }
`;
IconContainer.displayName = 'IconContainer';

const IndentContainer = styled.div`
    margin-right: ${props => props.theme.spacing.xs};
    width: 40px;
    display: flex;
    justify-content: center;
`;

const NameColumn = ({ data, onIconClick, depth, theme }) => {
    return (
        <Column>
            {!!depth &&
                <IndentContainer>
                    <Icon
                        name="indent"
                        size={ ICON_SIZES.SMALL_MINUS }
                        fill={ theme.colors.rockBlue }
                    />
                </IndentContainer>
            }
            <TableIcon
                name={ data && data.icon && data.icon.iconName }
                iconType={ data && data.icon && data.icon.iconType }
                size={ data && data.icon && data.icon.iconSize }
                fill={ data && data.icon && data.icon.iconFill }
                onIconClick={ onIconClick }
            />
            <Name>{ data.name }</Name>
        </Column>
    );
};
NameColumn.displayName = 'NameColumn';

NameColumn.defaultProps = {
    onIconClick: undefined,
};

NameColumn.propTypes = {
    theme: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    onIconClick: PropTypes.func,
    depth: PropTypes.number
};

export default NameColumn;
