import React from 'react';
import _ from 'lodash';
import InputText from './InputText';

export const InputPassword = props => {
    const value = _.get(props.model, props.property) || '';

    return (
        <InputText type="password"
            id={ props.id }
            value={ value }
            onChange={ props.onChange }
            placeholder={ props.placeholder }
            readonly={ props.readonly }
            required={ props.required }
            property={ props.property }
        />
    );
};

export default InputPassword;
