import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadPartnerImages } from 'redux/modules/customer/partnerImage';
import ImageUpload from 'containers/Application/ImageUpload/ImageUpload';
import { MODALTYPE } from 'components/Modal/ModalTypes';

const PartnerImage = ({
    uploading,
    loadPartnerImages,
    partnerNumber,
    partnerImages,
    loadingImage }) => {

    const onUploadSuccess = () => loadPartnerImages(partnerNumber);

    const onRemoveSuccess = () => loadPartnerImages(partnerNumber);

    return (
        <ImageUpload
            partnerNumber={ partnerNumber }
            imageType="partner"
            images={ partnerImages[partnerNumber] }
            onUploadSuccess={ onUploadSuccess }
            onRemoveSuccess={ onRemoveSuccess }
            uploading={ uploading || loadingImage }
            removeModalType={ MODALTYPE.CONFIRMATION_DELETE_PARTNER_IMAGE_MODAL }
        />
    );
};

PartnerImage.defaultProps = {
    uploading: false,
    partnerImages: {}
};

PartnerImage.propTypes = {
    partnerNumber: PropTypes.string.isRequired,
    loadPartnerImages: PropTypes.func.isRequired,
    uploading: PropTypes.bool,
    partnerImages: PropTypes.object
};

const mapStateToProps = state => ({
    partnerImages: state.partnerImage.byPartner,
    loadingImage: state.partnerImage.loading
});

const mapDispatchToProps = dispatch => ({
    loadPartnerImages: partnerNumber => dispatch(loadPartnerImages(partnerNumber, true)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PartnerImage);
