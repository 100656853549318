import React from 'react';
import styled from 'styled-components';

const StyledTitleColumn = styled.div`
    max-width: 800px;

    ${props => props.theme.media.landscape`
        padding-left: ${props => props.theme.spacing.md};
    `}
`;

const Title = styled.h3`
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.fontSize.xs};
    font-weight: ${props => props.theme.fontWeight.bold};
`;

const TitleColumn = props => {
    const { value } = props.data;

    return (
        <StyledTitleColumn>
            { value && <Title>{ value }</Title> }
        </StyledTitleColumn>
    );
};

export default TitleColumn;
