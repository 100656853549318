import React from 'react';

import translations from 'decorators/Translations/translations.js';

import Section from 'components/Section/Section';
import SortableFLTable from 'components/SortableFunctionalLocationTable/SortableFLTable';
import SectionHeader from 'components/Section/SectionHeader';
import { isBusinessEntity } from 'utils/Data/functionalLocations';


const FunctionalLocationsModule = props => {
    const {
        t,
        loadingChildren = true,
        childFunctionalLocations,
        partnerNumber,
        page,
        equipments,
        hasEquipment,
        title
    } = props;

    const getEquipmentCount = functionalLocationId => {
        if (!hasEquipment) {
            return undefined;
        }
        return equipments && equipments[functionalLocationId] ?
            equipments[functionalLocationId].length : '...';
    };

    const mappedFls = childFunctionalLocations
        .map(functionalLocation => ({
            functionalLocation: functionalLocation.functionalLocation,
            description: functionalLocation.description,
            type: functionalLocation.type,
            address: !isBusinessEntity(functionalLocation) && functionalLocation.address && functionalLocation.city
                && `${functionalLocation.address} ${functionalLocation.city}` || '',
            equipmentCount: getEquipmentCount(functionalLocation.functionalLocation)
        }));

    return (
        <Section>
            <SectionHeader noBorder title={ title || t('Technical') } t={ t } />
            <SortableFLTable
                loading={ loadingChildren }
                t={ t }
                sortedFunctionalLocations={ mappedFls }
                partnerNumber={ partnerNumber }
                page={ page }
                hasEquipment={ hasEquipment }
            />
        </Section>
    );
};

export default translations(FunctionalLocationsModule);
