import React, { Component, Fragment } from 'react';
import styled, { withTheme } from 'styled-components';
import translations from 'decorators/Translations/translations';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import Button from 'components/Button/Button';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import SnackBar from 'components/SnackBar/SnackBar';
import MultiInputRow from 'components/Form/MultiInputRow';
import ClassificationList from 'components/BuildingConfig/ClassificationList';
import Accordion from 'components/Accordion/Accordion';
import AccordionItem from 'components/Accordion/AccordionItem';
import Svg from 'components/Svg/Svg';
import InitializeNiagaraStation from './InitializeNiagaraStation';
import InitializeNiagaraStationToken from './InitializeNiagaraStationToken';
import SkeletonText from 'components/Skeletons/SkeletonText';

import { showModal } from 'redux/modules/modal/modal';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { deleteBuildingMeta, updateBuildingMeta } from 'redux/modules/buildingConfig/buildingMeta';
import { MODALTYPE } from 'components/Modal/ModalTypes';
import { notificationTimeout } from '../AdminEditBuilding';
import { BUILDING_META_KEYS } from 'constants/meta';


const ModalButtonWrapper = styled.div`
    margin: ${props => props.theme.spacing.md} 0 ${props => props.theme.spacing.xl};
`;
ModalButtonWrapper.displayName = 'ModalButtonWrapper';

const AccordionTitle = styled.h4`
    display: flex;
    flex-wrap: nowrap;
    &:hover {
        color: ${props => props.theme.colors.cerulean};
    }
`;
AccordionTitle.displayName = 'AccordionTitle';

const Arrow = styled(Svg)`
    vertical-align: text-bottom;
    margin-left: 5px;
`;
Arrow.displayName = 'Arrow';

const ClassificationsAccordion = styled(Accordion)`
    .accordion--closed {
        .show { display: block; }
        .hide { display: none; }
    }

    .accordion--open {
        .show { display: none; }
        .hide { display: block; }
    }
`;
ClassificationsAccordion.displayName = 'ClassificationsAccordion';

const metaKey = 'key';
const metaValue = 'value';
const metaFilterable = 'filterable';


export class AdminBuildingConfiguration extends Component {
    state = {
        meta: [],
        loading: false,
        error: null,
        edited: false,
        saving: false,
        saved: false
    };

    componentDidMount() {
        const { meta, functionalLocation } = this.props;

        if (meta[functionalLocation]) {
            this.setState({ meta: meta[functionalLocation] });
        }
    }

    componentDidUpdate(prevProps) {
        const { meta, functionalLocation } = this.props;
        const oldMeta = prevProps.meta;

        if (meta[functionalLocation] && meta[functionalLocation] !== oldMeta[functionalLocation]) {
            this.setState({ meta: meta[functionalLocation] });
        }
    }

    handleSubmit = () => {
        const { functionalLocation, updateBuildingMeta } = this.props;

        this.setState({ saving: true });
        updateBuildingMeta(this.state.meta, functionalLocation)
            .then(() => {
                this.setState({ saving: false, saved: true }, () => {
                    setTimeout(() => {
                        this.setState({ saved: false, edited: false });
                    }, notificationTimeout);
                });
            })
            .catch(error => this.setState({ error, saving: false, saved: false }));
    };

    deleteBuildingMeta = (id, functionalLocation) => {
        const { t, deleteBuildingMeta, showNotification } = this.props;
        return deleteBuildingMeta(id, functionalLocation)
            .then(() => {
                showNotification({ type: 'success', message: t('Meta data deleted successfully') });
                return Promise.resolve();
            })
            .catch(() => Promise.reject(new Error('Delete Meta Data Failed')));
    };

    handleMetaDeletion = id => {
        const { functionalLocation } = this.props;
        this.props.showModal(MODALTYPE.CONFIRMATION_DELETE_BUILDING_META, null, () =>
            this.deleteBuildingMeta(id, functionalLocation), null);
    };

    handleMetaAdd = e => {
        e.preventDefault();
        const { functionalLocation, partnerNumber } = this.props;
        const meta = _.cloneDeep(this.state.meta);

        meta.push({
            [metaKey]: '',
            [metaValue]: '',
            filterable: false,
            functionalLocation,
            partnerNumber: partnerNumber && partnerNumber !== 'all' ? partnerNumber : null
        });
        this.setState({ meta, edited: true });
    };

    handleMetaRemove = (e, index) => {
        e.preventDefault();
        const meta = _.cloneDeep(this.state.meta);
        const metaItem = meta[index];

        // Saved item, do actual delete
        if (metaItem.id) {
            this.handleMetaDeletion(metaItem.id);
            return;
        }

        // New item, just remove so it won't be saved
        _.pullAt(meta, index);
        this.setState({ meta, edited: this.hasEditedMeta() });
    };

    handleMetaChange = (property, value) => {
        const meta = _.cloneDeep(this.state.meta);

        const newMeta = _.setWith({ meta }, property, value, Object);
        this.setState({ meta: newMeta.meta, edited: true });
    };

    handleInitializeNiagaraSubmit = result => {
        const { t, showNotification } = this.props;
        let initializeNiagaraStationToken = '';
        if (result && result.result && result.result.token) {
            initializeNiagaraStationToken = result.result.token;
        }
        this.setState({
            initializeNiagaraStationToken,
            initializeNiagaraStationDialogOpen: false
        });
        showNotification({ type: 'success', message: t('Niagara station initialized') });
    };

    hasEditedMeta = () => {
        const originalMeta = this.props.meta[this.props.functionalLocation];
        return _.isEqual(originalMeta, this.state.meta);
    };

    createMetaOptions = () => {
        return {
            [metaKey]: _.keys(BUILDING_META_KEYS),
            [metaValue]: BUILDING_META_KEYS
        };
    }

    render() {
        const {
            t,
            classifications,
            functionalLocation,
            loading,
        } = this.props;
        const {
            meta,
            initializeNiagaraStationDialogOpen,
            initializeNiagaraStationToken,
            edited,
            saving,
            saved,
        } = this.state;

        const accordionTitle = flKey =>
            <AccordionTitle>
                { flKey }
                <Arrow className="show" name="fa-caret-down" />
                <Arrow className="hide" name="fa-caret-up"/>
            </AccordionTitle>;

        return (
            <Fragment>
                <Section>
                    <SectionHeader title={ t('Configuration') } t={ t } />
                    {loading
                        ? <SkeletonText />
                        : <Fragment>
                            <h4>{ t('Initialize Niagara station') }</h4>
                            <ModalButtonWrapper>
                                <Button
                                    onClick={() => this.setState({ initializeNiagaraStationDialogOpen: true })}
                                >
                                    { t('Initialize Niagara station') }
                                </Button>
                            </ModalButtonWrapper>
                        </Fragment>
                    }
                    { initializeNiagaraStationDialogOpen &&
                        <InitializeNiagaraStation
                            functionalLocation={ functionalLocation }
                            onSubmit={ this.handleInitializeNiagaraSubmit }
                            onClose={ () => this.setState({ initializeNiagaraStationDialogOpen: false }) }
                        />
                    }
                    { initializeNiagaraStationToken &&
                        <InitializeNiagaraStationToken
                            t={ t }
                            onClose={ () => this.setState({ initializeNiagaraStationToken: '' }) }
                            initializeNiagaraStationToken={ initializeNiagaraStationToken }
                        />
                    }
                </Section>
                <Section>
                    <SectionHeader
                        title={ t('Building Meta Data') }
                        t={ t }
                        ctxHelp={ `${CTXHELP_PREFIX} Building Meta Data` }
                    />
                    {loading
                        ? <SkeletonText />
                        : <MultiInputRow
                            t={ t }
                            model={ { meta } }
                            baseProperty="meta"
                            subProperties={ [metaKey, metaValue, metaFilterable] }
                            subPropertyTitles={ { [metaKey]: t('Key'), [metaValue]: t('Value'),
                                [metaFilterable]: t('Filterable') } }
                            checkboxes={ [metaFilterable] }
                            onPropertyChange={ this.handleMetaChange }
                            handleAdd={ this.handleMetaAdd }
                            handleRemove={ this.handleMetaRemove }
                            options={ this.createMetaOptions() }
                            optionsKey={ metaKey }
                        />
                    }
                </Section>
                <Section>
                    <SectionHeader
                        title={ t('Building Classifications') }
                        t={ t }
                        ctxHelp={ `${CTXHELP_PREFIX} Building Classifications` }
                    />
                    {loading
                        ? <SkeletonText />
                        : <ClassificationsAccordion>
                            { Object.keys(classifications).map(flKey =>
                                <AccordionItem titleComponent={ accordionTitle(flKey) } key={ flKey }>
                                    <ClassificationList classifications={ classifications[flKey] } />
                                </AccordionItem>
                            ) }
                        </ClassificationsAccordion>
                    }
                </Section>
                <SnackBar
                    visible={ edited }
                    variant="confirmation"
                    hideDelay={ 1000 }
                    primaryContent={ <div>
                        { !saving && !saved && t('Save your changes') }
                        { saving && `${t('Saving')}...` }
                        { saved && t('Meta data successfully saved') }
                    </div>}
                    secondaryContent={ <Button
                        submit
                        style={{ width: '200px' }}
                        onClick={ this.handleSubmit }
                        loading={saving}
                    >
                        { !saving && !saved ? t('Save') : t('Saved') }
                    </Button> }
                />
            </Fragment>
        );
    }
}

AdminBuildingConfiguration.defaultProps = {
    meta: {},
    classifications: {},
    loading: false,
};

AdminBuildingConfiguration.propTypes = {
    t: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
    meta: PropTypes.object,
    functionalLocation: PropTypes.string.isRequired,
    partnerNumber: PropTypes.string.isRequired,
    classifications: PropTypes.object,
    showNotification: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => ({
    meta: state.buildingMeta.meta,
    classifications: state.buildingMeta.classifications[ownProps.functionalLocation],
    partnerNumber: state.profile.activePartner
});

const mapDispatchToProps = dispatch => ({
    updateBuildingMeta: (meta, functionalLocation) => dispatch(updateBuildingMeta(meta, functionalLocation)),
    deleteBuildingMeta: (id, functionalLocation) => dispatch(deleteBuildingMeta(id, functionalLocation)),
    showModal: (modalType, preConditions, onSubmitAction, passedProps) =>
        dispatch(showModal(modalType, preConditions, onSubmitAction, passedProps))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withTheme(translations(AdminBuildingConfiguration)));
