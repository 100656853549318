import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';

import translations from 'decorators/Translations/translations';
import Section from 'components/Section/Section';
import SortableFLTable from 'components/SortableFunctionalLocationTable/SortableFLTable';
import SectionHeader from 'components/Section/SectionHeader';


class EquipmentModule extends Component {
    render() {
        const {
            t,
            loadingEquipment,
            equipments,
            subEquipments,
            location: { search },
            title
        } = this.props;

        const page = parseInt(queryString.parse(search).page, 10) || 1;
        const sortedEquipment = equipments && equipments.length > 0 &&
            _.sortBy(equipments, ['text', 'equipmentNumber']) || [];

        const mappedEquipment = sortedEquipment
            .map(equipment => ({
                functionalLocation: equipment.functionalLocation,
                equipmentNumber: equipment.equipmentNumber,
                description: equipment.text || equipment.equipmentNumber,
                type: 'EQUIPMENT',
                equipmentCount: subEquipments && subEquipments[equipment.equipmentNumber] ?
                    subEquipments[equipment.equipmentNumber].length : '...'
            }));

        return (
            <Section>
                <SectionHeader noBorder title={ title || t('Equipment') } t={ t } />
                <SortableFLTable
                    loading={ loadingEquipment }
                    t={ t }
                    sortedFunctionalLocations={ mappedEquipment }
                    page={ page }
                    hasEquipment
                    isEquipment
                    hideAddress
                />
            </Section>
        );
    }
}

export default withRouter(translations(EquipmentModule));
