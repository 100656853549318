import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { placeholder } from 'polished';
import PropTypes from 'prop-types';
import Svg from 'components/Svg/Svg';

const getBorderColor = (props, focus) => {
    if (props.invalid) {
        return 'error';
    } else if (props.success) {
        return 'ok';
    }

    return focus ? 'active' : 'static';
};

export const StyledInput = styled.input`
    width: 100%;
    height: auto;
    background-color: ${props => props.theme.input.background.default};
    padding: ${props => props.theme.input.padding};
    padding-left: ${props => props.hasIcon && 'calc(1.5rem + 24px)'};
    border: ${props => props.theme.input.border[getBorderColor(props)]};
    border-radius: 0;
    box-shadow: ${props => props.simple ? 'none' : props.invalid ? props.theme.input.shadow.errorStatic : props.theme.input.shadow.static};
    color: ${props => props.theme.input.font.color.default};
    font-size: ${props => props.theme.input.font.mobileSize};
    font-family: ${props => props.theme.input.font.family};
    line-height: ${props => props.theme.input.lineHeight.default};
    transition: ${props => props.theme.input.transition};

    &:hover, &:focus {
        box-shadow: ${props => props.simple ? 'none' : props.invalid ? props.theme.input.shadow.errorActive : props.theme.input.shadow.active};
        border: ${props => props.theme.input.border[getBorderColor(props, true)]};
    }

    &:read-only, &:read-only:hover, &:read-only:focus, &:disabled {
        opacity: ${props => props.success ? 1 : 0.6};
        box-shadow: ${props => props.invalid ? props.theme.input.shadow.errorStatic : 'none'};
    }
    &:disabled {
        color: ${props => props.theme.input.font.color.disabled};
        background-color: ${props => props.theme.input.background.disabled};
    }
    ${props => placeholder({ 'color': props.theme.input.font.color.placeholder, 'overflow': 'visible', opacity: 1 }) }

    ${props => props.theme.media.landscape`
        font-size: ${props => props.theme.input.font.size};
    `}
`;
StyledInput.displayName = 'StyledInput';

const Lock = styled(Svg)`
    position: absolute;
    right: 0.875rem;
    top: 0.8rem;
    fill: ${props => props.theme.colors.lightGray};
    font-size: ${props => props.theme.font.size.lg};
`;
Lock.displayName = 'Lock';

const Icon = styled(Svg)`
    position: absolute;
    left: 0.875rem;
    top: 0.8rem;
    fill: ${props => props.theme.colors.lightGray};
    font-size: ${props => props.theme.font.size.lg};
`;
Icon.displayName = 'Icon';

const Container = styled.div`
    position: relative;
`;
Container.displayName = 'Container';


export const InputText = props => {
    // Use value if it is explicitly provided in the props. Otherwise, get the property from the model.
    const value = props.value || props.value === 0 ? props.value : _.get(props.model, props.property) || '';

    return (
        <Container>
            { props.icon && <Icon name={ props.icon } /> }
            <StyledInput
                id={props.id}
                data-test-id={ props['data-test-id'] }
                value={value}
                onChange={e => props.onChange(props.property, e.target.value)}
                onBlur={props.onBlur}
                placeholder={props.placeholder}
                readOnly={props.readonly}
                type={props.type}
                required={props.required}
                invalid={props.invalid}
                disabled={props.disabled}
                simple={props.simple}
                hasIcon={ !!props.icon }
                success={ props.success }
                { ...props.inputProps }
            />
            { props.readonly && <Lock name="fa-lock" /> }
        </Container>
    );
};
InputText.displayName = 'InputText';

InputText.propTypes = {
    id: PropTypes.string,
    property: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    model: PropTypes.object,
    'data-test-id': PropTypes.string,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    readonly: PropTypes.bool,
    type: PropTypes.string,
    required: PropTypes.bool,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool,
    simple: PropTypes.bool,
    icon: PropTypes.string,
    inputProps: PropTypes.object,
    success: PropTypes.bool,
};

InputText.defaultProps = {
    id: undefined,
    property: '',
    value: '',
    model: {},
    dataTestId: undefined,
    placeholder: '',
    onBlur: undefined,
    type: undefined,
    readonly: false,
    required: false,
    invalid: false,
    disabled: false,
    simple: false,
    success: false,
};

export default InputText;
