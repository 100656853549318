import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import themes from 'styles/themes';
import Svg from 'components/Svg/Svg';

const StyledButton = styled.button.attrs(props => ({
    'data-test-id': props.dataTestId
}))`
    display: flex;
    align-items: center;
    background-color: ${props => props.color};
    color: ${props => props.textColor};
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.hasText ? props.theme.font.size.xxxs : props.theme.font.size.sm};
    font-weight: ${props => props.theme.font.weight.bold};
    ${props => props.hasText ? css`
        height: 3.2em;
        min-width: 3.2em;
    ` : css`
        align-items: center;
        justify-content: center;
    `}
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: ${props => props.round ? '50%' : '6px'};
    padding: ${props => props.hasText ? '0 3em' : '0.5em'};
    border: none;
    appearance: none;
    &:focus {
        outline: none;
    }
    opacity: ${props => props.disabled ? 0.6 : 1};
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    svg {
        ${props => props.hasText && 'margin-right: 0.5em;'}
    }
`;
StyledButton.displayName = 'StyledButton';

const IconButton = ({
    color, textColor, onClick, type, disabled, value, iconName, text, iconColor, dataTestId, stroke, ...other
}) =>
    <StyledButton
        dataTestId={ dataTestId }
        color={ color }
        textColor={ textColor }
        onClick={ onClick }
        type={ type }
        disabled={ disabled }
        value={ value }
        hasText={ !!text }
        { ...other }
    >
        { iconName && <Svg name={ iconName } fill={ iconColor } stroke={ stroke } /> }
        { text }
    </StyledButton>;

IconButton.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    iconName: PropTypes.string.isRequired,
    iconColor: PropTypes.string,
    textColor: PropTypes.string,
    round: PropTypes.bool,
    type: PropTypes.string,
    dataTestId: PropTypes.string,
    stroke: PropTypes.string
};

IconButton.defaultProps = {
    text: null,
    disabled: false,
    type: 'button',
    value: undefined,
    color: themes.customerPlatform.colors.midnight,
    textColor: themes.customerPlatform.colors.white,
    iconColor: themes.customerPlatform.colors.white,
    dataTestId: null
};

export default IconButton;
