import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AnnouncementButton } from './AnnouncementButton';

export const Unread = styled.div`
    position: absolute;
    top: -5px;
    right: -5px;
    width: 17px;
    height: 17px;
    line-height: 17px;
    text-align: center;
    display: ${props => props.hidden ? 'none' : 'block'};

    background: ${props => props.theme.colors.radicalRed};
    border-radius: 50%;
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.font.size.xxxs};
`;
Unread.displayName = 'Unread';

export const BellButton = ({ onClick, unread }) =>
    <AnnouncementButton onClick={ onClick } icon="bell">
        <Unread hidden={ unread < 1 }>{ unread }</Unread>
    </AnnouncementButton>;

BellButton.displayName = 'BellButton';

BellButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    unread: PropTypes.number.isRequired
};
