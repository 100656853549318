import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Section from 'components/Section/Section';
import ReactMarkdown from 'react-markdown/with-html';

import sanitizeHtml from 'sanitize-html';
import { isEmpty } from 'lodash';
import { customViewShape } from 'components/CustomView/utils';

/* Some basic styling for html content */
const StyledFreelayout = styled.div`
    p, h1, h2, h3, h4, h5, h6, ul, ol {
        margin-bottom: 1rem;
    }
`;

const FreeLayoutView = ({
    customView,
    noDataRender
}) => {
    if (!customView || isEmpty(customView.content)) {
        return noDataRender;
    }

    const cleanedContent = customView && customView.content ? sanitizeHtml(
        customView.content,
        {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat(['h1', 'h2', 'img']),
            allowedAttributes: {
                a: ['href', 'name', 'target'],
                img: ['src'],
                iframe: ['src', 'height', 'width'],
                '*': ['style']
            },
        }
    ) : '';

    return (
        <Section>
            <StyledFreelayout>
                <ReactMarkdown
                    source={ cleanedContent }
                    escapeHtml={ false }
                />
            </StyledFreelayout>
        </Section>
    );
};

FreeLayoutView.defaultProps = {
    customView: {},
    noDataRender: undefined
};

FreeLayoutView.propTypes = {
    customView: PropTypes.oneOfType([
        customViewShape()]),
    noDataRender: PropTypes.node
};

export default FreeLayoutView;
