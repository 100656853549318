import moment from 'moment-timezone';

export default date => {
    const
        weeks = [],
        firstDayOfMonth = date.startOf('month'),
        startDayOfWeek = firstDayOfMonth.isoWeekday(),
        lastDayOfMonth = moment(firstDayOfMonth).endOf('month'),
        startWeek = firstDayOfMonth.isoWeek(),
        weeksInYear = firstDayOfMonth.isoWeeksInYear();

    let weekObject = {
        number: startWeek,
        days: []
    };

    for (let j = 1; j < startDayOfWeek; j++) {
        // var previousDay = moment(firstDayOfMonth).subtract(startDayOfWeek - j, 'days');
        weekObject.days.push({
            hidden: true
        });
    }

    weekObject.days.push(firstDayOfMonth);

    for (let i = firstDayOfMonth.date(); i < lastDayOfMonth.date(); i++) {
        const
            shiftedDay = moment(firstDayOfMonth).add(i, 'days'),
            weekNumber = shiftedDay.isoWeek();

        if (weekNumber !== weekObject.number) {
            weeks.push(weekObject);
            weekObject = {
                number: weekNumber,
                days: [shiftedDay]
            };
        }
        else if (weekNumber === weeksInYear && shiftedDay.isoWeekday() === 7) {
            weekObject.days.push(shiftedDay);
            weeks.push(weekObject);
            weekObject = {
                number: 1,
                days: []
            };
        }
        else {
            weekObject.days.push(shiftedDay);
        }

        if (shiftedDay.date() === lastDayOfMonth.date() && weekObject.days.length > 0) {
            weeks.push(weekObject);
        }

    }

    return weeks;

};
