import PropTypes from 'prop-types';

export const customViewShape = () => PropTypes.shape({
    viewName: PropTypes.string.isRequired,
    viewType: PropTypes.string.isRequired,
    viewDesc: PropTypes.string,
    id: PropTypes.string,
    customers: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]),
    public: PropTypes.bool,
    portfolio: PropTypes.bool,
    functionalLocations: PropTypes.array,
    powerbiAppId: PropTypes.string,
    powerbiWorkspaceId: PropTypes.string,
    powerbiReportId: PropTypes.string,
    partnerCategory: PropTypes.string,
    content: PropTypes.string,
    height: PropTypes.number,
    offsetTop: PropTypes.number
});
