import { createSelector, createStructuredSelector } from 'reselect';
import { values, includes } from 'lodash';

import {
    filterServiceOrders,
    formatOrderForCalendar,
    filterByDay,
    filterByMonths,
    getResponseSLAs
} from 'utils/Data/serviceOrders';
import {
    getPartnerNumber,
    getFunctionalLocations,
    getLoadingFLs,
    getActiveFilter,
    getMonths,
    getDay,
    getFunctionalLocation,
    getProfile,
    getPartnerMeta,
} from './common';

export const getServiceOrders = (state, { year }) => state.serviceOrders.index.byYear[year];

export const getServiceOrderList = createSelector(
    [getServiceOrders],
    serviceOrders => values(serviceOrders).filter(order => order.maintenanceActivityType !== 'Y02')
);

/**
 * Get SLAs from partner meta
 */
export const getPartnerMetaSLAs = createSelector(
    [getPartnerMeta, getPartnerNumber],
    (partnerMeta, partnerNumber) => getResponseSLAs(partnerMeta, partnerNumber)
);

/**
 * Get service orders for a year for a partner (or all partners)
 * Expects the following props: year, match.params.partnerNumber
 */
export const getServiceOrdersForPartner = createSelector(
    [getServiceOrderList, getPartnerNumber, getFunctionalLocation],
    (serviceOrders, partnerNumber, functionalLocationId) => {
        if (functionalLocationId) {
            return serviceOrders.filter(order => includes(order.path, functionalLocationId));
        }

        return partnerNumber === 'all' ?
            serviceOrders :
            serviceOrders.filter(order => includes(order.partnerNumberWithParents, partnerNumber));
    }
);

/**
 * Get service orders for a year for a partner (or all partners) with FLs
 * Expects the following props: year, match.params['partnerNumber' | 'functionalLocationId'], loadingFLs
 */
export const getServiceOrdersForPartnerWithFLs = createSelector(
    [getServiceOrdersForPartner, getFunctionalLocations, getLoadingFLs, getProfile, getPartnerMetaSLAs],
    (serviceOrders, functionalLocations, loadingFLs, profile, SLAs) =>
        serviceOrders.map(serviceOrder =>
            formatOrderForCalendar(
                serviceOrder,
                functionalLocations,
                profile,
                false,
                loadingFLs,
                SLAs
            )
        )
);

/**
 * Get filtered service orders for a year for a partner (or all partners) with FLs
 * Expects the following props: year, match.params['partnerNumber' | 'functionalLocationId'], activeFilter, loadingFLs
 */
export const getFilteredServiceOrders = createSelector(
    [getServiceOrdersForPartnerWithFLs, getActiveFilter],
    (serviceOrders, activeFilter) => filterServiceOrders(serviceOrders, activeFilter)
);

/**
 * Get filtered service orders for a year / months for a partner (or all partners) with FLs
 * Expects the following props: year, months, match.params['partnerNumber' | 'functionalLocationId'], activeFilter,
 * loadingFLs
 */
export const getFilteredServiceOrdersForDates = createSelector(
    [getFilteredServiceOrders, getMonths, getDay],
    (serviceOrders, months, day) => {
        if (day) {
            return filterByDay(serviceOrders, months[0], day);
        }

        return months.length ?
            filterByMonths(serviceOrders, months) :
            serviceOrders;
    }
);

/**
 * Get all service order list variants for service calendar
 * Expects the following props: year, months, match.params['partnerNumber' | 'functionalLocationId'], activeFilter,
 * loadingFLs
 */
export const getServiceOrderListVariants = createStructuredSelector({
    all: getServiceOrdersForPartnerWithFLs,
    filtered: getFilteredServiceOrders,
    selected: getFilteredServiceOrdersForDates,
});
