import React, { Component, Fragment } from 'react';
import { find, get, filter, flatMap } from 'lodash';

import Dialog from 'components/Dialog/Dialog';
import DialogFrame from 'components/Dialog/DialogFrame';
import FloorTabs from 'components/FloorTabs/FloorTabs.jsx';
import SensorValues from 'containers/Application/SensorValues/SensorValues';
import SapEquipment from './SapEquipment/SapEquipment';
import FloorTooltip from './FloorTab/FloorTooltip';
import FloorplanSkeleton from './FloorplanSkeleton';

import { getOpeningHours } from 'utils/Data/performance';
import { floorTabsContent, replaceMultisensorIdWithSubsensorsIds } from './FloorplanModuleUtils';

const renderTooltip = (t, opiData) => <FloorTooltip t={ t } opiData={ opiData } />;

class FloorplanModule extends Component {
    state = {
        firstRender: true,
        dialogOpen: false,
        dialogTitle: null,
        dialogType: null
    }
    timeout = null;

    componentDidMount() {
        this.timeout = setTimeout(() => this.setState({ firstRender: false }), 400);
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    toggleDialog = floor => (title, type, sensorsIds, includeMainSensor = false) => {
        if (!sensorsIds || sensorsIds.length === 0) {
            this.setState({ dialogOpen: false });
            return;
        }

        const newIds = replaceMultisensorIdWithSubsensorsIds(this.props.sensors, sensorsIds, includeMainSensor);

        if (!newIds || newIds.length === 0) {
            this.setState({ dialogOpen: false });
            return;
        }

        const sensor = find(this.props.sensors, { id: sensorsIds[0] });
        const defaultSensorId = sensor.default;

        const isTechnicalPerformance = get(sensor, 'sensorType.name') === 'technical_performance';
        const openingHours = isTechnicalPerformance ? getOpeningHours(floor, this.props.buildingMeta) : undefined;

        const isAreaUtilizationRate = get(sensor, 'sensorType.name') === 'area_count';

        this.setState(oldState => ({
            dialogOpen: !oldState.dialogOpen,
            dialogTitle: title && !isAreaUtilizationRate ? title : null,
            dialogType: type,
            sensorsIds: newIds,
            defaultSensorId,
            sensor,
            category: floor,
            openingHours,
        }));
    };

    render() {
        const {
            t,
            floors,
            sensorValues,
            latestValuesBySensorId,
            cleaningValues,
            loading,
            sensors,
            functionalLocation,
            buildingMeta,
            sensorAlarmsById,
            partnerNumber,
            features
        } = this.props;
        const {
            dialogOpen,
            sensorsIds,
            defaultSensorId,
            dialogType,
            category,
            dialogTitle,
            openingHours,
            sensor,
            firstRender
        } = this.state;

        if (firstRender || loading) {
            return <FloorplanSkeleton />;
        }

        const isSapEquipment = dialogType === 'sap-equipment';
        const isPerformance = dialogType === 'technical_performance';
        const isUtilizationRate = dialogType === 'utilization_rate';

        const areas = filter(flatMap(floors, 'children'), { type: 'area' });

        return (
            <Fragment>
                { dialogOpen &&
                    <Dialog isActive={ true } onOverlayClick={ this.toggleDialog() }>
                        <DialogFrame onClose={ this.toggleDialog() } t={ t } smallVerticalPadding>
                            { !isSapEquipment && <SensorValues
                                sensorsIds={ sensorsIds }
                                buildingSensors={ sensors }
                                functionalLocation={ functionalLocation }
                                defaultSensorId={ defaultSensorId }
                                isAirQuality={ isPerformance }
                                isUtilizationRate={ isUtilizationRate }
                                showCombinationGroup={ isUtilizationRate }
                                buildingMeta={ buildingMeta }
                                category={ category }
                                sensorAlarmsById={ sensorAlarmsById }
                                title={ dialogTitle }
                                areas={ areas }
                                openingHours={ openingHours }
                            /> }
                            { isSapEquipment && <SapEquipment
                                t={ t }
                                functionalLocation={ functionalLocation }
                                partnerNumber={ partnerNumber }
                                features={ features }
                                sensor={ sensor }
                            /> }
                        </DialogFrame>
                    </Dialog>
                }
                <FloorTabs
                    t={ t }
                    loading={ loading }
                    content={ floorTabsContent(
                        t,
                        loading,
                        floors,
                        sensorValues,
                        latestValuesBySensorId,
                        cleaningValues,
                        buildingMeta,
                        sensorAlarmsById,
                        renderTooltip,
                        this.toggleDialog
                    ) }
                />
            </Fragment>
        );
    }
}

export default FloorplanModule;
