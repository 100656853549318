import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';

const Container = styled.li`
    display: flex;
    margin: ${props => props.theme.spacing.xs} 0;
`;
Container.displayName = 'Container';

const TableTitle = styled.span`
    color: ${props => props.invalid ? props.theme.colors.radicalRed : props.theme.colors.darkGray};
    font-size: ${props => props.theme.font.size.xxs};
    font-weight: ${props => props.theme.font.weight.normal};
    font-family: ${props => props.theme.font.arial};
    letter-spacing: 0.3px;
    width: 100px;
    min-width: 100px;
    margin-right: ${props => props.theme.spacing.sm};
    line-height: ${props => props.theme.font.size.sm};


    ${props => props.theme.media.portrait`
        width: 140px;
        min-width: 140px;
    `}
`;
TableTitle.displayName = 'TableTitle';

const TableValue = styled.div`
    color: ${props => props.theme.colors.black};
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.font.size.xs};
`;
TableValue.displayName = 'TableValue';

const Detail = ({ label, value, isDate, isPlannedDate }) => {
    if (!value) {
        return null;
    }
    let formatted;
    if (isDate) {
        formatted = moment.utc(value).local().format('LL, HH:mm');
    }
    else if (isPlannedDate) {
        formatted = moment.utc(value).format('LL');
    }
    else {
        formatted = value;
    }

    return (
        <Container>
            <TableTitle>{ label }</TableTitle>
            <TableValue>{ formatted }</TableValue>
        </Container>
    );
};

Detail.propTypes = {
    label: PropTypes.node.isRequired,
    value: PropTypes.node,
    isDate: PropTypes.bool,
    isPlannedDate: PropTypes.bool,
};

export default Detail;
