import themes from 'styles/themes';
import { rgba } from 'polished';
import { find, isNil, get, head, round } from 'lodash';
import moment from 'moment';
import {
    performanceColors,
    getPerformanceStatus,
    getPerformanceLimit,
    mapThresholdToStatusValue,
    areaUtilizationStatus
} from 'utils/Data/performance';
import { humanizeSensorTypeName } from 'utils/Data/sensorType';
import { getLatestValueForType } from 'utils/Data/values';

export const getIconFeature = (sensorTypeName, sensorId, latestValuesBySensorId, editId) => {
    // If in edit mode and sensorId is not of the editable sensor
    if (editId && sensorId !== editId) {
        return 'sensorDisabled';
    }

    switch (sensorTypeName) {
    case 'presence' || 'occupancy': {
        const value = latestValuesBySensorId[sensorId] && latestValuesBySensorId[sensorId].value;
        return value !== undefined && value === 0 ? 'presenceAvailable' : 'presenceUnavailable';
    }
    case 'presence_seat': {
        const value = latestValuesBySensorId[sensorId] && latestValuesBySensorId[sensorId].value;
        return value !== undefined && value === 0 ? 'seatAvailable' : 'seatUnavailable';
    }
    case 'presence_zone': {
        const value = latestValuesBySensorId[sensorId] && latestValuesBySensorId[sensorId].value;
        return value !== undefined && value === 0 ? 'zoneAvailable' : 'zoneUnavailable';
    }
    case 'lorawan-multisensor':
        return 'device';
    case 'sap-equipment':
        return 'equipment';
    case 'area':
        return null;
    case undefined:
        return 'default';
    default: // other sensorTypes have the same icon
        return 'measuringPoint';
    }
};

export const getAreaPresenceFeature = (area, latestValuesBySensorId) => {
    const theme = themes.customerPlatform;
    const colors = theme.blueprint.area.colors;
    let color = colors.default;
    let sensorId, titleWithUtilizationRate;

    if (area && area.color) {
        color = area.color;
    } else if (area && area.sensors) {
        const presenceSensor = find(
            area.sensors, sensor => sensor.sensorType && sensor.sensorType.graphType === 'presence'
        );
        const countSensor = find(
            area.sensors, sensor => sensor.sensorType && sensor.sensorType.name === 'area_count'
        );

        if (presenceSensor) {
            const value = latestValuesBySensorId[presenceSensor.id] && latestValuesBySensorId[presenceSensor.id].value;
            color = isNil(value) || value > 0 ? colors.occupied : colors.available;
            sensorId = presenceSensor.id;
        }

        if (countSensor) {
            const latestValue = latestValuesBySensorId[countSensor.id];
            const capacity = find(countSensor.sensorMeta, { metaKey: 'capacity' });
            const value = latestValue && capacity && Math.min(round(latestValue.value / capacity.value * 100), 100);
            sensorId = countSensor.id;

            if (!isNil(value)) {
                titleWithUtilizationRate = `${area.name} / ${value}%`;
                color = performanceColors({ theme })[areaUtilizationStatus(value)];
            }
        }
    }

    return {
        fillColor: rgba(color, 0.2),
        strokeColor: rgba(color, 0.3),
        sensorId,
        ...titleWithUtilizationRate && { title: titleWithUtilizationRate }
    };
};

export const getInfoValueColor = (sensor, value, timestamp, buildingMeta, isPerformance, sensorAlarmsById) => {
    const theme = themes.customerPlatform;
    let color = theme.colors.black;

    if (sensor && sensor.sensorType && sensor.sensorType.name && !isNil(value)) {
        const alarm = sensorAlarmsById && sensorAlarmsById[sensor.id];
        const getLimit = getPerformanceLimit(sensor, sensor.parent, buildingMeta, alarm);
        if (getLimit) {
            const threshold = getLimit(moment(timestamp).toDate());
            const mappedValue = isPerformance ? value : mapThresholdToStatusValue(value, threshold);
            const status = getPerformanceStatus(mappedValue, isPerformance);
            color = performanceColors({ theme })[status];
        }
    }
    return color;
};

/**
 * Select measuring points shown on the blueprint
 *
 * Uses the first available:
 * 1. sensor.coords.subsensors
 * 2. sensor.default
 * 3. first(sensor.children)
 * 4. sensor
 */
export const getInfotipMeasuringPoints = sensor => {
    let measuringPoints = [];

    const { children, coords } = sensor;
    if (children && children.length) {
        const sensors = [sensor, ...sensor.children];
        const subsensors = (coords || []).reduce((acc, coord) => coord.subsensors ? [...acc, ...coord.subsensors] : acc, []);
        if (subsensors.length) {
            measuringPoints = subsensors
                .sort((a, b) => a.ordinal - b.ordinal)
                .map(subsensor => find(sensors, { id: subsensor.sensorId }));
        } else {
            measuringPoints = [find(sensors, { id: sensor.default }) || head(sensor.children)];
        }
    }

    const valid = measuringPoints.filter(measuringPoint => measuringPoint);
    return valid.length ? valid : [sensor];
};

export const getInfotipContent = (featureSensor, latestValuesBySensorId, t, buildingMeta, sensorAlarmsById) => {
    if (!featureSensor || !featureSensor.id) { return; }

    let content = '';
    let rows = 0;
    const measuringPoints = getInfotipMeasuringPoints(featureSensor);
    if (!measuringPoints.length) {
        return undefined;
    }

    measuringPoints.forEach(measuringPoint => {
        const sensorTypeName = get(measuringPoint, ['sensorType', 'name']);
        const isPerformance = sensorTypeName && sensorTypeName.startsWith('technical_performance');
        if (sensorTypeName) {
            content += `<p class="hideOnMobile capitalize">${humanizeSensorTypeName(t, sensorTypeName)}</p>`;
            rows++;
        }

        const latestValue = latestValuesBySensorId[measuringPoint.id];
        if (latestValue) {
            const value = getLatestValueForType(latestValue, measuringPoint.sensorType, [], measuringPoint.sensorMeta, t);
            const color = getInfoValueColor(
                measuringPoint, latestValue.value, latestValue.timestamp, buildingMeta, isPerformance, sensorAlarmsById
            );

            content += `<p style="color:${color};">${value}</p>`;
            rows++;
        } else {
            content += `<p>...</p>`;
            rows++;
        }
    });

    return { content, rows };
};
