import React from 'react';
import PropTypes from 'prop-types';
import { throttle, isNil } from 'lodash';
import StatusMeter from 'components/StatusMeter/StatusMeter';
import PerformanceNarrowItem from './PerformanceNarrowItem';

const PerformanceItem = props => {
    const {
        property,
        performance,
        value,
        onClick,
        noRounding,
        ...other
    } = props;

    const roundedValue = !performance || props.noRounding
        ? performance
        : Math.round(performance);

    const hasOnClick = typeof onClick === 'function';
    const onCardClick = React.useCallback(() => onClick(property, value), [onClick]);

    const [isNarrow, setIsNarrow] = React.useState(window.innerHeight < 600);
    const throttledSetNarrow = React.useCallback(throttle(() => setIsNarrow(window.innerHeight < 600), 100), []);

    React.useEffect(() => {
        window.addEventListener('resize', throttledSetNarrow);

        return () => {
            window.removeEventListener('resize', throttledSetNarrow);
        };
    }, []);

    if (isNarrow) {
        return (
            <PerformanceNarrowItem
                value={ roundedValue }
                onClick={ hasOnClick ? onCardClick : undefined }
                {...other}
            />
        );
    }

    return <StatusMeter value={ roundedValue } onClick={ hasOnClick ? onCardClick : undefined } {...other} />;
};
PerformanceItem.displayName = 'PerformanceItem';

PerformanceItem.defaultProps = {
    title: '',
    subtitle: undefined,
    loading: false,
    color: undefined,
    selected: false,
    onClick: undefined,
    property: undefined,
    value: undefined,
    noRounding: false,
};

PerformanceItem.propTypes = {
    value: PropTypes.any,
    property: PropTypes.string,
    performance: PropTypes.number,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    loading: PropTypes.bool,
    color: PropTypes.string,
    noRounding: PropTypes.bool,
};

export default PerformanceItem;
