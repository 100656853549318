import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { rgba } from 'polished';
import { range, sum, xor, omit } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { withRouter, Link } from 'react-router-dom';

import translations from 'decorators/Translations/translations';
import LinkArrow, { direction, size } from 'components/LinkArrow/LinkArrow.jsx';

const MonthSelector = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: -${props => props.theme.spacing.sm};
    margin-left: -${props => props.theme.spacing.sm};

    ${props => props.theme.media.portrait`
        margin-top: -${props => props.theme.spacing.md};
        margin-left: -${props => props.theme.spacing.md};
    `}
`;

const BoxBase = styled.div`
    padding: ${props => `${props.theme.section.verticalSpacing} ${!props.noPadding ? props.theme.spacing.md : 0}`};
    background-color: ${props => props.theme.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    border: ${props => props.theme.input.border.static};
    border-bottom: 2px solid ${props => props.active ? props.theme.colors.cerulean : props.theme.input.border.static};
    min-height: 66px;

    ${props => props.theme.media.portrait`
        border: none;
        border-bottom: 2px solid ${props => props.active ? props.theme.colors.cerulean : 'transparent'};
        box-shadow: ${props => `0 2px 5px ${rgba(props.theme.colors.black, 0.10)}`};
    `}
`;

const Box = styled(BoxBase)`
    width: calc(100% / 4 - ${props => props.theme.spacing.sm} - 0.1px);
    margin-top: ${props => props.theme.spacing.sm};
    margin-left: ${props => props.theme.spacing.sm};

    ${props => props.theme.media.portrait`
        width: calc(100% / 7 - ${props => props.theme.spacing.md} - 0.1px);
        margin-top: ${props => props.theme.spacing.md};
        margin-left: ${props => props.theme.spacing.md};
    `}

    ${props => props.theme.media.desktop`
        width: calc(100% / 14 - ${props => props.theme.spacing.md} - 0.1px);
    `}
`;

const Year = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};

    ${props => props.theme.media.portrait`
        width: calc(100% / 7 * 2 - ${props => props.theme.spacing.md});
    `}

    ${props => props.theme.media.desktop`
        width: calc(100% / 14 * 2 - ${props => props.theme.spacing.md});
    `}
`;

const YearValue = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Value = styled.div`
    color: ${props => !props.children || props.disabled ? props.theme.colors.darkGray : props.theme.colors.black}
    font-size: ${props => props.theme.font.size.sm};

    ${props => props.active && `
        color: ${props.theme.colors.cerulean};
    `}

    ${props => props.theme.media.desktop`
        font-size: ${props => props.theme.font.size.xs};
    `}

    ${props => props.theme.media.bigDesktop`
        font-size: ${props => props.theme.font.size.sm};
    `}
`;

const Label = styled.div`
    font-size: ${props => props.theme.font.size.xxs};
    text-transform: uppercase;
    color: ${props => props.theme.colors.darkGray};
    margin-top: ${props => props.theme.spacing.xxs};
    text-align: center;

    ${props => props.active && `
        color: ${props.theme.colors.cerulean};
    `}

    ${props => props.theme.media.desktop`
        font-size: ${props => props.theme.font.size.xxxs};
    `}

    ${props => props.theme.media.bigDesktop`
        font-size: ${props => props.theme.font.size.xxs};
    `}
`;

const StyledLinkArrow = styled(LinkArrow)`
    display: block;
    align-self: stretch;
    outline: none;

    &:hover {
        background: ${props => props.theme.colors.lightGray};
    }
`;

const DaySelector = styled(BoxBase)`
    flex-direction: row;
    justify-content: space-between;
    cursor: default;
    width: 100%;
    margin: 0;
`;

const DayLink = styled(Link)`
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.font.size.xxxs};
    font-weight: ${props => props.theme.font.weight.bold};
    color: ${props => props.theme.colors.cerulean};
    text-transform: uppercase;

    ${props => props.theme.media.portrait`
        font-size: ${props => props.theme.font.size.xxs};
    `}
`;

const DayHeading = styled.h4`
    color: ${props => props.theme.colors.black};
    margin-bottom: -0.2em;
    font-size: ${props => props.theme.font.size.sm};

    ${props => props.theme.media.portrait`
        font-size: ${props => props.theme.font.size.md};
    `}
`;

const YearLink = styled(Link)`
    color: ${props => props.theme.colors.cerulean};
`;

const LOADING_INDICATOR = '···';

const CalendarMonthSelector = ({ t, year, months, day, counts, theme, location, history, loading, disableMonths }) => {
    const monthNames = moment.localeData().monthsShort();
    const { pathname, search } = location;
    const query = queryString.parse(search);

    const getYearLink = (year, reset) => {
        const nextQuery = queryString.stringify({
            ...omit(query, reset ? ['months', 'day'] : []),
            year,
            page: 1,
        });

        return `${pathname}?${nextQuery}`;
    };

    const getDayLink = date => {
        const nextQuery = queryString.stringify({
            ...query,
            year: date.year(),
            months: date.month() + 1,
            day: date.date(),
        });

        return `${pathname}?${nextQuery}`;
    };

    const toggleMonth = month => {
        const nextQuery = queryString.stringify({
            ...query,
            months: xor(months, [month]),
            page: 1,
        });

        history.push(`${pathname}?${nextQuery}`);
    };

    const clearMonths = () => {
        const nextQuery = queryString.stringify(omit(query, 'months'));
        history.push(`${pathname}?${nextQuery}`);
    };

    if (day) {
        const date = moment(`${year}-${months[0]}-${day}`);
        return (
            <DaySelector>
                <DayLink to={ getDayLink(moment(date).subtract(1, 'day')) }>
                    ← { t('Previous') }
                </DayLink>
                <DayHeading>
                    {date.format('MMMM Do')}, <YearLink to={ getYearLink(year, true) }>{ year }</YearLink>
                </DayHeading>
                <DayLink to={ getDayLink(moment(date).add(1, 'day')) }>
                    { t('Next') } →
                </DayLink>
            </DaySelector>
        );
    }

    return (
        <MonthSelector>
            <Year active={ !months.length } disabled={ disableMonths }>
                <StyledLinkArrow
                    to={ getYearLink(year - 1) }
                    direction={ direction.LEFT }
                    size={ size.TINY }
                    fill={ theme.colors.darkGray }
                    data-test-id="PrevYearLink"
                />
                <YearValue onClick={ clearMonths }>
                    <Value>{ loading ? LOADING_INDICATOR : sum(counts) }</Value>
                    <Label>{ t('Year') } { year }</Label>
                </YearValue>
                <StyledLinkArrow
                    to={ getYearLink(year + 1) }
                    direction={ direction.RIGHT }
                    size={ size.TINY }
                    fill={ theme.colors.darkGray }
                    data-test-id="NextYearLink"
                />
            </Year>
            { range(0, 12).map(index => {
                const active = months.includes(index + 1);
                return (
                    <Box
                        key={ index }
                        onClick={ disableMonths ? undefined : () => toggleMonth(index + 1) }
                        active={ active }
                        disabled={ disableMonths }
                    >
                        <Value active={ active } disabled={ disableMonths }>
                            { loading ? LOADING_INDICATOR : counts[index] }
                        </Value>
                        <Label active={ active } disabled={ disableMonths }>
                            { monthNames[index] }
                        </Label>
                    </Box>
                );
            }) }
        </MonthSelector>
    );
};

CalendarMonthSelector.propTypes = {
    year: PropTypes.number.isRequired,
    months: PropTypes.arrayOf(PropTypes.number).isRequired,
    day: PropTypes.number,
    counts: PropTypes.arrayOf(PropTypes.number).isRequired,
    theme: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    disableMonths: PropTypes.bool,
};

export default withRouter(withTheme(translations(CalendarMonthSelector)));
