import React from 'react';
import styled, { css } from 'styled-components';
import Svg from 'components/Svg/Svg';
import SortableTableRow from './SortableTableRow';
import SortableTableCell from './SortableTableCell';

const TableHead = styled.thead`
    display: ${props => props.hideOnMobile && 'none'};

    ${props => props.theme.media.landscape`
        display: table-header-group;
    `}
`;

const TableHeadRow = styled(SortableTableRow)`
    ${props => props.stripes && !props.backgroundColor && css`
        background-color: ${props.theme.colors.alabaster};
    `}
    ${props => props.backgroundColor && css`
        background-color: ${props.theme.colors[props.backgroundColor]};
    `}
`;

export const TableHeadCell = styled(SortableTableCell)`
    font-size: ${props => props.theme.font.size.xxxs};
    font-weight: ${props => props.theme.font.weight.bold};
    font-family: ${props => props.theme.font.family.arial};
    text-transform: uppercase;
    letter-spacing: 1px;
    color: ${props => props.selected ? props.theme.colors.midnight : props.theme.colors.darkGray};
    fill: ${props => props.selected ? props.theme.colors.midnight : props.theme.colors.darkGray};
    white-space: nowrap;
    vertical-align: bottom;
    display: ${props => props.hideOnMobile && 'none'};
    width: ${props => props.cellWidthMobile ? props.cellWidthMobile : 'auto'};

    ${props => props.sortable && css`
        cursor: pointer;
    `}

    ${props => props.theme.media.portrait`
        width: ${props => props.cellWidth ? props.cellWidth : props.cellWidthMobile || 'auto'};
    `}

    ${props => props.theme.media.landscape`
        display: ${props => props.hideOnDesktop ? 'none' : 'table-cell'};
    `}
`;

const Title = styled.span`
    display: inline-block;
    white-space: pre;
    text-align: center;
`;

const Icon = styled(Svg)`
    font-size: 10px;
    vertical-align: middle;
    margin-left: 5px;
`;

const SortableTableHead = ({
    changeOrder,
    columns,
    cellPadding,
    stripes,
    headerBackgroundColor,
    removeSidePadding,
    hideOnMobile,
    order,
    orderBy }) => {
    return (
        <TableHead hideOnMobile={hideOnMobile}>
            <TableHeadRow
                stripes={ stripes }
                backgroundColor={ headerBackgroundColor }
            >
                {columns && columns.length > 0 &&
                    columns.map((col, index) => {
                        const arrowIcon = orderBy === col.field
                            && order === 'desc' ? 'ion-arrow-down' : 'ion-arrow-up';
                        const CellComponent = col.component || TableHeadCell;
                        return (
                            <CellComponent
                                selected={ orderBy === col.field }
                                onClick={col.sortable ? () => changeOrder(col.field) : undefined}
                                key={ `${index} ${col.field}` }
                                padding={ cellPadding }
                                sortable={ col.sortable }
                                align={ col.align }
                                cellWidth={ col.width }
                                cellWidthMobile={ col.mobileWidth }
                                removeSidePadding={ removeSidePadding }
                                hideOnMobile={ col.hideOnMobile }
                                hideOnDesktop={ col.hideOnDesktop }
                            >
                                <Title>{ col.title }</Title> {col.sortable && <Icon name={ arrowIcon } key={ `${index} ${col.field}` } />}
                            </CellComponent>
                        );
                    })
                }
            </TableHeadRow>
        </TableHead>
    );
};

export default SortableTableHead;
