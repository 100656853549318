import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { rgba } from 'polished';
import { throttle, values } from 'lodash';
import IoTChart from 'components/Charts/IoTChart';
import AirQualityChart from 'components/Charts/AirQualityChart';
import CleaningChart from 'components/Charts/CleaningChart';
import BarChart from 'components/Charts/BarChart';
import PerformanceChart from 'components/Charts/PerformanceChart';
import StatisticsBarChart from 'components/Charts/StatisticsBarChart';
import { STATISTICS_TYPES } from 'containers/Application/SensorValues/SensorValuesUtils';
import { MAX_HEIGHT } from '../Sensor';
import {
    getLabelFormatter,
    getTooltipHeaderFormatter,
    getTickInterval,
    getPerformanceChartConfig
} from './utils';

const calcHeight = smallHeight => {
    const modalHeight = Math.min(window.innerHeight - 50, MAX_HEIGHT);
    return Math.max(200, modalHeight - (smallHeight ? 325 : 200));
};

export const ChartContainer = styled.div`
    display: flex;
    overflow: hidden;
    height: ${props => props.height || calcHeight(props.smallHeight)}px;
    background-color: ${props => props.theme.colors.white};
    padding: ${props => props.theme.sensorValues.padding.mobile};

    ${props => props.theme.media.portrait`
        padding: ${props => props.theme.spacing.sm} ${props =>
        props.theme.sensorValues.padding.tablet};
    `}

    ${props => props.theme.media.desktop`
        padding: ${props => props.theme.spacing.sm} ${props =>
        props.theme.sensorValues.padding.desktop};
    `}
`;
ChartContainer.displayName = 'ChartContainer';

const StyledNoDataInfo = styled.p`
    width: 100%;
    display: flex;
    justify-content: center;
`;
StyledNoDataInfo.displayName = 'StyledNoDataInfo';

export const LatestValueTime = styled.div`
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.font.size.xxs};
    color: ${props => props.theme.colors.darkGray};
    text-align: right;
    padding: ${props => props.theme.spacing.xs};
    line-height: 1;
    height: ${props => `calc(${props.theme.font.size.xxs} + 2 * ${props.theme.spacing.xs})`};
`;
LatestValueTime.displayName = 'LatestValueTime';

export const LatestValueLabel = styled.span`
    font-weight: ${props => props.theme.font.weight.bold};
`;
LatestValueLabel.displayName = 'LatestValueLabel';

const errorTexts = [
    'No data available',
    'Try data tools for more data',
    'You can only select three days time range',
    'You can choose sensor from dropdown above'
];

const EMPTY_ARRAY = [];

const SensorBody = props => {
    const {
        sensorData,
        sensorType,
        sensorMeta,
        hasSensorOptions,
        parameterModel,
        loading,
        t,
        aggregation,
        smallHeight,
        theme,
        utilizationHours,
        statistics,
        getThreshold,
        height,
        openingHours,
        buildingMeta,
        timezone,
        functionalLocation
    } = props;

    const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);

    const throttledSetHeight = throttle(() => setWindowHeight(window.innerHeight), 100);

    React.useEffect(() => {
        window.addEventListener('resize', throttledSetHeight);

        return () => {
            window.removeEventListener('resize', throttledSetHeight);
        };
    }, []);

    const errorText = [
        t(errorTexts[0]),
        t(errorTexts[1]),
        hasSensorOptions ? t(errorTexts[3]) : ''
    ].join('\n');

    const start = parameterModel && parameterModel.startDatetime;
    const end = parameterModel && parameterModel.endDatetime;

    if (!end || !start) {
        return null;
    }

    const timespan = end.diff(start); // graph shows only the selected timespan
    const maxX = end.local().valueOf(); // use maxX to control graph timespan

    const sharedProps = {
        loading,
        t,
        error: errorText,
        noZoom: true
    };

    const tickInterval = getTickInterval(timespan);
    const hideTime =
        aggregation === 'monthlySum' ||
        aggregation === 'dailySum' ||
        aggregation === 'energyRating';

    // define colors for utilization hours
    const utilizationZones = color => {
        if (statistics === STATISTICS_TYPES.perHour) {
            const [startHour, endHour] = utilizationHours;
            return {
                zones: [
                    { value: startHour, color: rgba(color, 0.3) },
                    { value: endHour, color },
                    { color: rgba(color, 0.3) }
                ],
                zoneAxis: 'x'
            };
        }
        return { zones: [{ color }], zoneAxis: 'x' };
    };

    const utilizationSeries = () => {
        const series = [];

        if (sensorData.AVERAGE) {
            series.push({
                data: sensorData.AVERAGE,
                name: t('Average'),
                color: theme.colors.midnight,
                _unit: '%',
                _showTooltipForZeroValue: true,
                ...utilizationZones(theme.colors.midnight)
            });
        }

        if (sensorData.PEAK_DAY) {
            series.push({
                data: sensorData.PEAK_DAY,
                name: t('Peak Day'),
                color: theme.colors.orange,
                _unit: '%',
                ...utilizationZones(theme.colors.orange)
            });
        }

        if (sensorData.PEAK_HOUR) {
            series.push({
                data: sensorData.PEAK_HOUR,
                name: t('Peak Hour'),
                color: theme.colors.amethyst,
                _unit: '%',
                ...utilizationZones(theme.colors.amethyst)
            });
        }

        return series;
    };

    let chart = null;
    if (sensorType) {
        switch (sensorType.graphType) {
            case 'air_quality':
            case 'technical_performance':
                chart = (
                    <AirQualityChart
                        {...sharedProps}
                        histories={sensorData}
                        minThreshold={90}
                        startDate={start}
                        endDate={end}
                        minXRange={timespan}
                        maxX={maxX}
                        unit={sensorType.unit}
                        aggregation={aggregation}
                        openingHours={openingHours}
                        buildingMeta={buildingMeta}
                        timezone={timezone}
                        functionalLocation={functionalLocation}
                    />
                );
                break;
            case 'iot':
            case 'performance':
                chart = <PerformanceChart
                    {...sharedProps}
                    {...getPerformanceChartConfig({
                        sensorTypeName: sensorType.name,
                        sensorTypeUnit: sensorType.unit,
                        sensorData,
                        sensorMeta,
                        timespan,
                        maxX,
                        getThreshold
                    })}
                />;
                break;
            case 'cleaning':
                chart = <CleaningChart {...sharedProps} data={sensorData} />;
                break;
            case 'presence':
                if (aggregation === 'raw') {
                    chart = (
                        <IoTChart
                            {...sharedProps}
                            histories={sensorData}
                            activeTimes={null}
                            alarms={EMPTY_ARRAY}
                            minXRange={timespan}
                            maxX={maxX}
                            yMax={1}
                            type="area"
                            hideDecimals
                            trueValue={t('Occupied')}
                            falseValue={t('Available')}
                        />
                    );
                } else if (statistics === STATISTICS_TYPES.perSensor) {
                    chart = (
                        <StatisticsBarChart
                            {...sharedProps}
                            type="bar"
                            hideLegend
                            simpleTooltip
                            series={[
                                {
                                    data: sensorData,
                                    name: '',
                                    _unit: '%',
                                    _showTooltipForZeroValue: true
                                }
                            ]}
                            xAxis={{
                                type: 'category',
                                ...(sensorData &&
                                    sensorData.length > 10 && {
                                        scrollbar: { enabled: true },
                                        max: Math.min(sensorData.length, 10) - 1
                                    })
                            }}
                            yTickInterval={25}
                            yMax={100}
                            colors={[theme.colors.midnight]}
                            plotBorderWidth="0"
                        />
                    );
                } else {
                    chart = (
                        <BarChart
                            {...sharedProps}
                            legendAlign="left"
                            series={utilizationSeries()}
                            xAxisType="category"
                            yTickInterval={25}
                            yMax={100}
                            yTitle={t('Utilization Percentage')}
                            noRounding
                        />
                    );
                }
                break;
            case 'bar':
                chart = (
                    <BarChart
                        {...sharedProps}
                        hideLegend
                        series={[
                            {
                                data: sensorData,
                                _unit: sensorType.unit,
                                _showTooltipForZeroValue: true
                            }
                        ]}
                        tickInterval={tickInterval}
                        hideTime={hideTime}
                        labelFormatter={getLabelFormatter(tickInterval)}
                        getTooltipHeader={getTooltipHeaderFormatter(aggregation)}
                        minXRange={timespan}
                        maxX={maxX}
                    />
                );
                break;
            default:
                chart = (
                    <IoTChart
                        {...sharedProps}
                        histories={sensorData}
                        activeTimes={null}
                        alarms={EMPTY_ARRAY}
                        minXRange={timespan}
                        maxX={maxX}
                        unit={sensorType.unit}
                    />
                );
                break;
        }
    }

    return (
        <ChartContainer smallHeight={smallHeight} windowHeight={windowHeight} height={height}>
            {!loading && !sensorData ? (
                <StyledNoDataInfo>{t('No data available.')}</StyledNoDataInfo>
            ) : (
                chart
            )}
        </ChartContainer>
    );
};

export default withTheme(SensorBody);

SensorBody.defaultProps = {
    loading: false,
    hasSensorOptions: false,
    maxData: undefined,
    minData: undefined,
    smallHeight: false
};

SensorBody.propTypes = {
    // required:
    sensorData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    sensorType: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    parameterModel: PropTypes.object.isRequired,
    // optional:
    loading: PropTypes.bool,
    hasSensorOptions: PropTypes.bool,
    aggregation: PropTypes.string,
    sensorMeta: PropTypes.arrayOf(PropTypes.object),
    smallHeight: PropTypes.bool,
    statistics: PropTypes.oneOf(values(STATISTICS_TYPES)),
    getThreshold: PropTypes.func,
    openingHours: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    buildingMeta: PropTypes.array,
    functionalLocation: PropTypes.string
};
