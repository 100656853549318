import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import translations from 'decorators/Translations/translations';
import { isCustomerAdminRole } from 'utils/Data/profileData';
import StandardPage from 'components/StandardPage/StandardPage';
import Header from 'containers/Application/Header/Header';
import { icons } from 'components/Icon/IconNames';
import FadeTransition from 'components/FadeTransition/FadeTransition';

import {
    setNewProfileProperty,
    createProfile,
    showGlobalNotification,
} from 'redux/modules';

import { default as UserForm } from './UserForm/UserForm.jsx';

class AdminCreateUser extends React.Component {
    state = { saving: false };

    createProfile = newProfile => {
        this.setState({ saving: true });

        const { t, createProfile, history, showGlobalNotification } = this.props;
        createProfile(newProfile).then(result => {
            this.setState({ saving: false });
            if (!result.error) {
                history.push('/Admin');
                showGlobalNotification(t('User has been created.'), 'success');
            }
        });
    };

    render() {
        const { t, profile, newProfile } = this.props;
        const isCustomerAdmin = profile && isCustomerAdminRole(profile.role);

        // Inherit feature flags and service order start date from customer admin to users created by them
        if (isCustomerAdmin) {
            newProfile.serviceOrderStartDate = profile && profile.serviceOrderStartDate;
            newProfile.documentStartDate = profile && profile.documentStartDate;
            newProfile.features = profile && profile.features;
        }

        const links = [
            { title: 'Back to admin homepage', icon: icons.ARROW_LEFT, to: { pathname: '/Admin' }, smallIcon: true },
            { title: 'Create new user', icon: icons.ADMIN_ADD_USER }
        ];

        return (
            <FadeTransition>
                <StandardPage>
                    <Helmet title={ t('Create user') } />
                    <Header t={ t } links={ links } selected="create new user" />
                    <UserForm id="createUserForm"
                        model={ newProfile }
                        setProperty={ this.props.setNewProfileProperty }
                        submit={profile => this.createProfile(profile)}
                        t={ t }
                        newProfile
                        saving={ this.state.saving }
                    />
                </StandardPage>
            </FadeTransition>
        );
    }
}

function mapStateToProps(state) {
    return {
        profile: state.profile.profile,
        newProfile: state.profile.newProfile
    };
}

const mapDispatchToProps = dispatch => ({
    setNewProfileProperty: (property, value) => dispatch(setNewProfileProperty(property, value)),
    createProfile: data => dispatch(createProfile(data)),
    showGlobalNotification: (message, type) => dispatch(showGlobalNotification(message, type)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(AdminCreateUser));
