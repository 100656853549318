import _ from 'lodash';
import { flow, difference, entries, filter, includes, map, fromPairs } from 'lodash/fp';

export const getPartnerNumbers = profile => _.uniq([
    ..._.keys(profile.permissions || {}),
    ...profile.partnerPermissions || [],
]);

export const hasPartnerPermission = (profile, partnerNumber) => {
    if (!profile || !profile.partnerPermissions || !partnerNumber) {
        return false;
    }

    return _.includes(profile.partnerPermissions, partnerNumber);
};

export const filterPathByPermissions = (path, partnerNumber, profile) => {
    // Partner permission always grants access to entire path
    if (hasPartnerPermission(profile, partnerNumber)) {
        return path;
    }

    const permissions = profile.permissions && profile.permissions[partnerNumber];
    if (!permissions) {
        return [];
    }

    // Remove functional locations from path until it only contains authorized functional locations
    let authorizedPath = path;
    while (authorizedPath.length) {
        if (_.includes(permissions, authorizedPath[0])) {
            break;
        }

        authorizedPath = authorizedPath.slice(1);
    }

    return authorizedPath;
};

export const getAllPermissionsFromModel = model => {
    const permissions = _.mergeWith(
        {},
        model.permissions,
        model.inactivePermissions,
        model.addedFunctionalLocations,
        (a, b) => {
            if (Array.isArray(a)) {
                return _.uniq(a.concat(b));
            }
        }
    );

    return _.pickBy(permissions, functionalLocations => functionalLocations && functionalLocations.length);
};

export const deriveEditedPermissions = model => {
    const {
        partnerPermissions = [],
        deletedFunctionalLocations = {},
        addedPartnerPermissions = [],
        deletedPartnerPermissions = [],
    } = model;

    const updatedPartnerPermissions = _.difference(
        _.union(partnerPermissions, addedPartnerPermissions),
        deletedPartnerPermissions
    );

    const allPermissions = getAllPermissionsFromModel(model);
    const updatedPermissions = flow(
        entries,
        filter(([partnerNumber]) => !includes(partnerNumber)(updatedPartnerPermissions)),
        map(([partnerNumber, functionalLocations]) => [
            partnerNumber,
            difference(functionalLocations)(deletedFunctionalLocations[partnerNumber]),
        ]),
        filter(([, functionalLocations]) => functionalLocations.length),
        fromPairs
    )(allPermissions);

    return {
        permissions: updatedPermissions,
        partnerPermissions: updatedPartnerPermissions,
    };
};

export const getEnabledTeasers = (features, featureTeasers) => ({
    featureTeasers: _.filter(featureTeasers, teaser => !features[teaser])
});

export const selectAuthorizedPartnerNumber = (partnerNumbers, profile, path) => {
    if (!partnerNumbers) {
        return undefined;
    }

    const partnerPermissions = profile.partnerPermissions || [];
    const permissions = profile.permissions || {};
    return partnerNumbers.find(partnerNumber =>
        _.includes(partnerPermissions, partnerNumber) ||
        partnerNumber in permissions && path.some(fl => _.includes(permissions[partnerNumber], fl))
    );
};

const externalADs = ['@caverion.com', '@rauma.fi'];
export const isExtenalADUser = email => {
    return email && _.some(externalADs, ad =>_.endsWith(email, ad));
};
