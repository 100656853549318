import _ from 'lodash';
import { getAreaPresenceFeature, getIconFeature, getInfotipContent } from './blueprint';

export const getEnabledSensors = sensors => sensors
    .filter(sensor => !sensor.disabled)
    .map(sensor => sensor.children && sensor.children.length ? {
        ...sensor,
        children: getEnabledSensors(sensor.children),
    } : sensor);


export const getBuildingSensorsFromHierarchy = buildingHierarchy => {
    if (!buildingHierarchy) {
        return [];
    }
    const categories = buildingHierarchy.children || [];
    /*
        Iterate tree or building -hierarchy,with children hierarchies related to that building. Sensors can be on any level.
        Currently is agreed that no more than one level of subhierarchies is allowed.
    */
    let sensorsResult = [];
    if (buildingHierarchy.sensors) {
        sensorsResult = sensorsResult.concat(buildingHierarchy.sensors);
    }
    categories.forEach(child => {
        if (child && child.sensors) {
            sensorsResult = sensorsResult.concat(child.sensors);
        }
    });
    // Get sensors that are subsensors
    sensorsResult.forEach(sensor => {
        if (sensor && sensor.children && sensor.children.length > 0) {
            sensorsResult = sensorsResult.concat(sensor.children);
        }
    });
    sensorsResult = _.uniqBy(sensorsResult, 'id');
    return getEnabledSensors(sensorsResult);
};

export const getSensorFromHierarchy = (buildingHierarchy, sensorId) => {
    if (!buildingHierarchy) {
        return;
    }

    // Search tree recursively to find node with given id
    const searchNode = node => {
        if (node.id === sensorId) {
            return node;
        }

        if (node.sensors) {
            // Search from sensors and their children
            const sensors = node.sensors.reduce((sensors, sensor) => {
                sensors.push(sensor);
                sensors = sensor.children ? sensors.concat(sensor.children) : sensors;
                return sensors;
            }, []);

            const sensor = sensors.find(sensor => sensor.id === sensorId);
            if (sensor) {
                return sensor;
            }
        }

        if (node.children) {
            for (const index in node.children) {
                const sensor = searchNode(node.children[index]);
                if (sensor) {
                    return sensor;
                }
            }
        }
    };

    return searchNode(buildingHierarchy);
};

const getHierarchies = parentHierarchy => {
    const categories = parentHierarchy.children || [];
    return [parentHierarchy].concat(categories);
};

export const findHierarchy = (buildingHierarchy, hierarchyId) => {
    return _.find(getHierarchies(buildingHierarchy), { id: hierarchyId });
};

export const getBuildingFloorsFromHierarchy = buildingHierarchy => {
    return _.sortBy(_.filter(getHierarchies(buildingHierarchy), { type: 'floor' }), ['order']);
};

export const getBuildingGroupsFromHierarchy = buildingHierarchy => {
    return _.filter(getHierarchies(buildingHierarchy), hierarchy => {
        return hierarchy.type === 'group' || hierarchy.type === 'group_energy';
    });
};

export const getBuildingAreasFromHierarchy = buildingHierarchy => {
    let areas = [];
    const hierarchies = getHierarchies(buildingHierarchy);
    if (hierarchies && hierarchies.length > 0) {
        hierarchies.forEach(child => {
            if (child && child.children && child.children.length > 0) {
                areas = areas.concat(_.filter(child.children, { type: 'area' }));
            }
        });
    }
    return areas;
};

export const getBuildingCoordsFromHierarchy = buildingHierarchy => {
    let areas = [];
    buildingHierarchy.children.forEach(child => {
        if (child && child.children && child.children.length > 0) {
            areas = areas.concat(_.filter(child.children, { type: 'area' }));
        }
    });
    const sensorsResult = getBuildingSensorsFromHierarchy(buildingHierarchy);

    // Combine coords of both sensors and areas in to same array.
    const mappedCoords =
        _.concat(
            _.flatten(_.compact(_.map(areas, 'coords'))),
            _.flatten(_.compact(_.map(sensorsResult, 'coords')))
        );

    return mappedCoords;
};

export const getFloorFeatures = (
    floor, latestValuesBySensorId, t, showInfotips, editId, buildingMeta, sensorAlarmsById
) => {
    const floorImage = _.find(floor.images, { type: 'floor' });
    const floorImagePath = floorImage ? floorImage.path : null;
    const floorAreas = _.filter(floor.children, { type: 'area' });
    const floorSensors = !!floor.sensors ? getEnabledSensors(floor.sensors) : [];
    // Use compact to remove empty objects from array
    const areaFeatures = _.compact(floorAreas.map( area => {
        const areaCoords = _.find(area.coords, { type: 'area' });
        if (!areaCoords || !_.first(areaCoords.area)) {
            return null;
        }
        return createFeature(
            [_.first(areaCoords.area).map(coord => [coord.x, coord.y])],
            area,
            'area',
            area.name,
            latestValuesBySensorId,
            t,
            false,
            editId,
            buildingMeta,
            sensorAlarmsById,
        );
    }));

    const sensorFeatures = _.compact(floorSensors.map( sensor => {
        const sensorCoords = _.find(sensor.coords, { type: 'sensor' });
        if (!sensorCoords || !sensorCoords.point) {
            return null;
        }
        return createFeature(
            [sensorCoords.point.x, sensorCoords.point.y],
            sensor,
            sensor.sensorType && sensor.sensorType.name,
            sensor.name,
            latestValuesBySensorId,
            t,
            showInfotips,
            editId,
            buildingMeta,
        );
    }));

    const floorIcons = _.uniq(sensorFeatures.map(feature => feature.properties && feature.properties.icon));

    return {
        floorAreas,
        floorSensors,
        areaFeatures,
        sensorFeatures,
        floorImage,
        floorImagePath,
        floorIcons
    };
};

const notPresence = object => !(object && object.sensorType && object.sensorType.graphType === 'presence');

// Create OpenLayers feature from area or measuring point
export const createFeature = (
    coords, object, type, name, latestValuesBySensorId, t, showInfotips, editId, buildingMeta, sensorAlarmsById
) => ({
    type: 'Feature',
    geometry: {
        type: type === 'area' ? 'Polygon' : 'Point',
        coordinates: coords,
    },
    properties: {
        title: notPresence(object) && name,
        areaId: type === 'area' && object.id,
        sensorId: type !== 'area' && object.id,
        type,
        icon: type !== 'area' && getIconFeature(type, object.id, latestValuesBySensorId, editId),
        ...type === 'area' && getAreaPresenceFeature(object, latestValuesBySensorId),
        infotip: type !== 'area' && notPresence(object) && showInfotips
            && getInfotipContent(object, latestValuesBySensorId, t, buildingMeta, sensorAlarmsById),
        editable: object.id === editId
    }
});

export const getSensorBuildingOrFloorParentFromHierarchy = (sensorId, hierarchy, buildingId) => {
    const hierarchies = getHierarchies(hierarchy);
    let parent = null;
    if (hierarchies && hierarchies.length > 0) {
        hierarchies.forEach(child => {
            if (child &&
                (child.type === 'floor' || child.type === 'building') &&
                child.sensors &&
                child.sensors.length > 0
            ) {
                const sensor = _.find(child.sensors, { id: sensorId });
                if (sensor && sensor.id) {
                    parent = child;
                }
            }
        });
    }
    // Default to building if building ID given
    if (!parent && buildingId) {
        parent = _.find(hierarchies, { id: buildingId });
    }
    return parent;
};

export const getAssignedGroupsFromHierarchy = (hierarchy, sensorId) => {
    let sensorHierarchies = [];
    if (!hierarchy) {
        return [];
    }
    if (hierarchy.sensors && hierarchy.sensors.length > 0) {
        if (_.some(hierarchy.sensors, { id: sensorId })) {
            sensorHierarchies.push({
                id: hierarchy.id,
                type: hierarchy.type
            });
        }
    }
    if (hierarchy.children && hierarchy.children.length > 0) {
        hierarchy.children.forEach(child => {
            sensorHierarchies = sensorHierarchies.concat(getAssignedGroupsFromHierarchy(child, sensorId));
        });
    }
    return sensorHierarchies;
};
