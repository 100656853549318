import {
    aggregate,
    groupByFunctions,
    getServiceOrderStatus,
    orderStatuses,
    filterByBuilding,
    filterByEquipment,
} from '../../utils';
import {
    STATISTICS_ORDER_VOLUMES_BY_DISCIPLINE,
    STATISTICS_ORDER_VOLUMES_BY_BUILDING,
    STATISTICS_ORDER_VOLUMES_BY_EQUIPMENT,
} from 'constants/serviceCalendar';

export const aggregateOrders = (orders, statisticsType, functionalLocations) => aggregate(
    orders,
    functionalLocations,
    groupByFunctions[statisticsType],
    orderStatuses,
    getServiceOrderStatus
);

export const filterAggregates = (aggregates, filter, statisticsType, items, t) => {
    const needle = filter.trim().toLowerCase();
    switch (statisticsType) {
    case STATISTICS_ORDER_VOLUMES_BY_BUILDING:
        return aggregates.filter(([key]) => filterByBuilding(needle, key, items));
    case STATISTICS_ORDER_VOLUMES_BY_EQUIPMENT:
        return aggregates.filter(([key]) => filterByEquipment(needle, key, items));
    default:
        return aggregates.filter(([key]) => {
            const label = t(key);
            return label && label.toLowerCase().indexOf(needle) !== -1;
        });
    }
};

export const getChartData = (t, aggregates, statisticsType, items) => {
    const categories = aggregates.map(([key]) => {
        switch (statisticsType) {
        case STATISTICS_ORDER_VOLUMES_BY_DISCIPLINE:
            return t(key);
        case STATISTICS_ORDER_VOLUMES_BY_BUILDING: {
            const functionalLocation = items[key];
            return functionalLocation && (functionalLocation.description || functionalLocation.name) || key;
        }
        case STATISTICS_ORDER_VOLUMES_BY_EQUIPMENT: {
            return items[key] || key;
        }
        default:
            return key;
        }
    });

    const data = aggregates.map(group => group[1]);
    const series = orderStatuses.map(status => ({
        name: t(status),
        data: data.map(categoryData => categoryData[status]),
        _showTooltipForZeroValue: true,
    }));

    return {
        categories,
        series,
    };
};
