import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { ThemeProvider } from 'styled-components';
import { isNil } from 'lodash';

import { sessionAuthenticate, setSplashLoading } from 'redux/modules/index.js';

import { getWelcomeUrl } from 'utils/Data/language';

import ErrorPage from 'containers/Application/ErrorPage/ErrorPage.jsx';
import themes from '../../../styles/themes';

class AcceptAuthorization extends Component {

    componentDidMount() {
        const query = queryString.parse(this.props.location.search);

        if (!isNil(query.code) && !isNil(query.state)) {
            this.props.sessionAuthenticate(
                getWelcomeUrl(),
                `${window.location.origin}/Authorize`,
                query.code,
                query.state);
        }
        this.props.setSplashLoading(true);
    }

    render() {
        const { isAuthenticated, sessionAuthorizationIsInProgress, history } = this.props;
        const redirectUrl = this.props.redirectUrl || '/';

        // Show general loading indicator in index.js when the authorization is in progress.
        if (sessionAuthorizationIsInProgress) {
            return null;
        }

        // Show error page if we cannot authenticate the session.
        if (!isAuthenticated) {
            return (
                <ThemeProvider theme={ themes.customerPlatform }>
                    <ErrorPage type="authentication" />
                </ThemeProvider>
            );
        }

        history.push(redirectUrl);
        return null;
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.session.isAuthenticated,
        sessionAuthorizationIsInProgress: state.session.loading,
        error: state.session.error,
        redirectUrl: state.session.redirectUrl
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sessionAuthenticate: (welcomeUrl, authorizationUrl, code, state) =>
            dispatch(sessionAuthenticate(welcomeUrl, authorizationUrl, code, state)),
        setSplashLoading: loading =>
            dispatch(setSplashLoading(loading))
    };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withRouter(AcceptAuthorization));
