import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import Accordion from 'components/Accordion/Accordion';
import AccordionItem from 'components/Accordion/AccordionItem';
import FunctionalLocationList from 'containers/Application/FunctionalLocationList/FunctionalLocationList';
import PermissionListItemPortfolio from '../PermissionListItem/PermissionListItemPortfolio';
import PermissionListItemFunctionalLocation from '../PermissionListItem/PermissionListItemFunctionalLocation';
import PermissionListItemPartner from '../PermissionListItem/PermissionListItemPartner';
import { getAllPermissionsFromModel } from 'utils/profile';
import { sortFunctionalLocations } from '../utils';

const deletedFlData = fl => ({
    functionalLocation: fl,
    type: 'UNKNOWN'
});

const DisabledAccordionItem = styled(AccordionItem)`
    a {
        cursor: default;
    }
`;

class Permissions extends Component {
    render() {
        const {
            t,
            partnerPermissions,
            addedFunctionalLocations,
            deletedFunctionalLocations,
            addedPartnerPermissions,
            deletedPartnerPermissions,
            permissions,
            inactivePermissions,
            functionalLocations,
            customers,
            portfolioCounts,
            deleteFunctionalLocation,
            deletePartnerFunctionalLocations,
            deletePartnerPermissions,
        } = this.props;

        // List items for customer portfolios
        const allPartnerPermissions = _.uniq([...partnerPermissions, ...addedPartnerPermissions]);
        const portfolios = allPartnerPermissions
            .map(partnerNumber => customers[partnerNumber])
            .filter(partner => partner)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(partner =>
                <DisabledAccordionItem
                    key={ `portfolio-${partner.partnerNumber}` }
                    titleComponent={
                        <PermissionListItemPortfolio
                            t={ t }
                            customer={ partner }
                            functionalLocationCount={ portfolioCounts[partner.partnerNumber] }
                            remove={ deletePartnerPermissions(partner.partnerNumber) }
                            removed={ _.includes(deletedPartnerPermissions, partner.partnerNumber) }
                        />
                    }
                />
            );

        // List items for regular permissions
        const allPermissions =
            getAllPermissionsFromModel({ permissions, inactivePermissions, addedFunctionalLocations });
        const functionalLocationPermissions = _.entries(allPermissions)
            .filter(([partnerNumber]) => customers[partnerNumber] && !_.includes(allPartnerPermissions, partnerNumber))
            .map(([partnerNumber, functionalLocations]) =>
                [customers[partnerNumber], _.uniq(functionalLocations || [])])
            .sort(([partnerA], [partnerB]) => partnerA.name.localeCompare(partnerB.name))
            .map(([partner, functionalLocationIds]) => {
                const partnerNumber = partner.partnerNumber;
                const items = functionalLocationIds
                    .map(functionalLocation =>
                        functionalLocations[functionalLocation] || deletedFlData(functionalLocation))
                    .sort(sortFunctionalLocations)
                    .map(functionalLocation => {
                        const error = !_.includes(functionalLocation.partnerNumberWithParents, partnerNumber) &&
                            `${t('This location is no longer linked to the partner')} ${partnerNumber}`;

                        const added = addedFunctionalLocations[partnerNumber] &&
                            _.includes(addedFunctionalLocations[partnerNumber], functionalLocation.functionalLocation);

                        const deleted = deletedFunctionalLocations[partnerNumber] &&
                            _.includes(
                                deletedFunctionalLocations[partnerNumber],
                                functionalLocation.functionalLocation
                            );

                        return {
                            functionalLocation,
                            error,
                            added,
                            deleted,
                        };
                    });

                return (
                    <AccordionItem
                        key={ `permissions-${partnerNumber}` }
                        titleComponent={
                            <PermissionListItemPartner
                                t={ t }
                                customer={ partner }
                                functionalLocationCount={ items.length }
                                remove={ deletePartnerFunctionalLocations(partnerNumber) }
                                removed={ items.every(item => item.deleted) }
                            />
                        }
                    >
                        <FunctionalLocationList>
                            { items.map(item =>
                                <PermissionListItemFunctionalLocation
                                    key={ item.functionalLocation.functionalLocation }
                                    t={ t }
                                    functionalLocation={ item.functionalLocation }
                                    remove={
                                        deleteFunctionalLocation(
                                            item.functionalLocation.functionalLocation,
                                            partnerNumber
                                        )
                                    }
                                    error={ item.error }
                                    added={ item.added }
                                    removed={ item.deleted }
                                />
                            ) }
                        </FunctionalLocationList>
                    </AccordionItem>
                );
            });

        return (
            <Accordion>
                { [
                    ...portfolios,
                    ...functionalLocationPermissions,
                ] }
            </Accordion>
        );
    }
}

Permissions.propTypes = {
    t: PropTypes.func.isRequired,
    partnerPermissions: PropTypes.array.isRequired,
    addedFunctionalLocations: PropTypes.object.isRequired,
    deletedFunctionalLocations: PropTypes.object.isRequired,
    addedPartnerPermissions: PropTypes.array.isRequired,
    deletedPartnerPermissions: PropTypes.array.isRequired,
    permissions: PropTypes.objectOf(PropTypes.array).isRequired,
    inactivePermissions: PropTypes.object.isRequired,
    functionalLocations: PropTypes.objectOf(PropTypes.object),
    customers: PropTypes.objectOf(PropTypes.object),
    portfolioCounts: PropTypes.objectOf(PropTypes.number).isRequired,
    deleteFunctionalLocation: PropTypes.func.isRequired,
    deletePartnerFunctionalLocations: PropTypes.func.isRequired,
    deletePartnerPermissions: PropTypes.func.isRequired,
};

export default Permissions;
