import { createReducerFromMapping } from 'redux/utils/index.js';
import { MasterData } from '@caverion/redux/api/actions';

const initialState = {
    meta: {},
};

export const LOAD_PARTNER_META = 'CUSTOMER_PLATFORM/PartnerMeta/LOAD_PARTNER_META';
export const LOAD_PARTNER_META_SUCCESS = 'CUSTOMER_PLATFORM/PartnerMeta/LOAD_PARTNER_META_SUCCESS';
export const LOAD_PARTNER_META_FAIL = 'CUSTOMER_PLATFORM/PartnerMeta/LOAD_PARTNER_META_FAIL';

export const loadPartnerMeta = partnerNumber => async dispatch => {
    dispatch({
        type: LOAD_PARTNER_META,
        partnerNumber,
    });

    try {
        const meta = await dispatch(MasterData.getPartnerMeta(JSON.stringify({
            where: {
                partnerNumber,
            },
        })));

        return dispatch({
            type: LOAD_PARTNER_META_SUCCESS,
            partnerNumber,
            meta,
        });
    } catch (error) {
        return dispatch({
            type: LOAD_PARTNER_META_FAIL,
            partnerNumber,
            error,
        });
    }
};

export const SET_PARTNER_META = 'CUSTOMER_PLATFORM/PartnerMeta/SET_PARTNER_META';
export const setPartnerMeta = (partnerNumber, changes) => ({
    type: SET_PARTNER_META,
    partnerNumber,
    changes,
});

export const SAVE_PARTNER_META = 'CUSTOMER_PLATFORM/PartnerMeta/SAVE_PARTNER_META';
export const SAVE_PARTNER_META_SUCCESS = 'CUSTOMER_PLATFORM/PartnerMeta/SAVE_PARTNER_META_SUCCESS';
export const SAVE_PARTNER_META_FAIL = 'CUSTOMER_PLATFORM/PartnerMeta/SAVE_PARTNER_META_FAIL';

export const savePartnerMeta = (partnerNumber, partnerMetaArray) => async dispatch => {
    dispatch({
        type: SAVE_PARTNER_META,
        partnerNumber,
    });

    try {
        const meta = await dispatch(MasterData.upsertAllPartnerMeta(partnerMetaArray));
        return dispatch({
            type: SAVE_PARTNER_META_SUCCESS,
            partnerNumber,
            meta,
        });
    } catch (error) {
        return dispatch({
            type: SAVE_PARTNER_META_FAIL,
            partnerNumber,
            error,
        });
    }
};

export const DELETE_PARTNER_META = 'CUSTOMER_PLATFORM/PartnerMeta/DELETE_PARTNER_META';
export const DELETE_PARTNER_META_SUCCESS = 'CUSTOMER_PLATFORM/PartnerMeta/DELETE_PARTNER_META_SUCCESS';
export const DELETE_PARTNER_META_FAIL = 'CUSTOMER_PLATFORM/PartnerMeta/DELETE_PARTNER_META_FAIL';

export const deletePartnerMeta = (partnerNumber, index) => async (dispatch, getState) => {
    dispatch({
        type: DELETE_PARTNER_META,
        partnerNumber,
        index,
    });

    const { partnerMeta: { meta } } = getState();
    const item = meta[partnerNumber] && meta[partnerNumber].meta[index];
    try {
        if (item.id) {
            await dispatch(MasterData.deletePartnerMeta(item.id));
        }

        return dispatch({
            type: DELETE_PARTNER_META_SUCCESS,
            partnerNumber,
            index,
        });
    } catch (error) {
        return dispatch({
            type: DELETE_PARTNER_META_FAIL,
            partnerNumber,
            index,
            error,
        });
    }
};

export default createReducerFromMapping({
    [LOAD_PARTNER_META]: (state, action) => ({
        ...state,
        meta: {
            ...state.meta,
            [action.partnerNumber]: {
                loading: true,
                error: undefined,
            },
        },
    }),
    [LOAD_PARTNER_META_SUCCESS]: (state, action) => ({
        ...state,
        meta: {
            ...state.meta,
            [action.partnerNumber]: {
                loading: false,
                original: action.meta,
                meta: action.meta,
            },
        },
    }),
    [LOAD_PARTNER_META_FAIL]: (state, action) => ({
        ...state,
        meta: {
            ...state.meta,
            [action.partnerNumber]: {
                loading: false,
                error: action.error,
            },
        },
    }),
    [SET_PARTNER_META]: (state, action) => ({
        ...state,
        meta: {
            ...state.meta,
            [action.partnerNumber]: {
                ...state.meta[action.partnerNumber],
                ...action.changes,
            },
        },
    }),
    [SAVE_PARTNER_META]: (state, action) => ({
        ...state,
        meta: {
            ...state.meta,
            [action.partnerNumber]: {
                ...state.meta[action.partnerNumber],
                saving: true,
                saved: false,
                error: undefined,
            },
        },
    }),
    [SAVE_PARTNER_META_SUCCESS]: (state, action) => ({
        ...state,
        meta: {
            ...state.meta,
            [action.partnerNumber]: {
                ...state.meta[action.partnerNumber],
                saving: false,
                saved: true,
                original: action.meta,
                meta: action.meta,
            },
        },
    }),
    [SAVE_PARTNER_META_FAIL]: (state, action) => ({
        ...state,
        meta: {
            ...state.meta,
            [action.partnerNumber]: {
                ...state.meta[action.partnerNumber],
                saving: false,
                saved: true,
                error: action.error,
            },
        },
    }),
    [DELETE_PARTNER_META_SUCCESS]: (state, action) => {
        const item = state.meta[action.partnerNumber].meta[action.index];
        return {
            ...state,
            meta: {
                ...state.meta,
                [action.partnerNumber]: {
                    ...state.meta[action.partnerNumber],
                    original: state.meta[action.partnerNumber].original.filter(meta => !item.id || meta.id !== item.id),
                    meta: [
                        ...state.meta[action.partnerNumber].meta.slice(0, action.index),
                        ...state.meta[action.partnerNumber].meta.slice(action.index + 1),
                    ],
                },
            },
        };
    }
}, initialState);
