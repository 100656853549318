import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import translations from 'decorators/Translations/translations';
import PropTypes from 'prop-types';
import { map, uniqBy, flatten, sortBy, values, startsWith, isArray } from 'lodash';
import { VIEW_TYPES, getFormDefaults } from './utils';

import CustomViewForm from 'components/CustomView/CustomViewForm';

import { searchProfiles, searchFunctionalLocationsByPartners, uploadCustomViewFile } from 'redux/modules';
import {
    SEARCH_TYPE_PARTNER_NUMBER,
    SEARCH_TYPE_PARTNER_PERMISSION,
    SEARCH_TYPE_FUNCTIONAL_LOCATION
} from 'redux/modules/profile/profile';
import { updateCustomView } from 'redux/modules/customView/customView';
import { MAX_SIZE } from 'components/Documents/DocumentManager/DocumentManager';

export const CUSTOM_VIEW_FORM_MODE = Object.freeze({
    CREATE: 'CREATE',
    EDIT: 'EDIT',
    DUPLICATE: 'DUPLICATE'
});

const StyledErrorMessage = styled.p`
    color: ${props => props.theme.colors.radicalRed};
`;
StyledErrorMessage.displayName = 'StyledErrorMessage';

const ErrorMessage = ({ t, message }) => <StyledErrorMessage>{ t(message) }</StyledErrorMessage>;

export class EditOrCreateCustomView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profileSearchResult: [],
            loading: false,
            model: !!props.newViewType
                ? getFormDefaults(props.newViewType)
                : null,
            file: null,
            uploading: false
        };
    }

    componentDidMount() {
        const { formMode, customView } = this.props;

        if (formMode === CUSTOM_VIEW_FORM_MODE.EDIT || formMode === CUSTOM_VIEW_FORM_MODE.DUPLICATE) {
            const customers = customView.customers[0];

            // load user options for customers or functional locations
            customView.portfolio
                ? this.searchProfileOptions(customView.customers, SEARCH_TYPE_PARTNER_NUMBER)
                : this.searchProfileOptions(customView.functionalLocations, SEARCH_TYPE_FUNCTIONAL_LOCATION, customers);

            // load buildings for customers
            !customView.portfolio && this.searchFLOptions(customView.customers);

            /**
             * Multi-inputs work with options, so customers, users, and functionalLocations options are
             * generated and put to model
            */

            const users = customView.users
                .map(user => ({
                    label: user.username,
                    value: user
                }));

            const functionalLocations = customView.functionalLocations
                .map(fl => ({
                    label: fl,
                    value: fl
                }));

            this.setState({
                model: {
                    ...customView,
                    customers,
                    users,
                    functionalLocations,
                },
            });
        }
    }

    handleSubmit = async () => {
        const { model, file } = this.state;
        const { updateCustomView, onSubmit, uploadFile } = this.props;

        const newCustomView = Object.assign({}, model);
        newCustomView.viewType = model.viewType || VIEW_TYPES.powerBi;
        newCustomView.customers = [model.customers];

        // multi inputs handle options rather than values, so they need to be mapped
        newCustomView.users = model.public
            ? []
            : map(model.users, 'value');
        newCustomView.functionalLocations = model.portfolio
            ? []
            : map(model.functionalLocations, 'value');

        this.setState({ loading: true });

        const result = await updateCustomView(newCustomView);

        // Handle file upload
        let uploadResponse = {};
        if (file && !result.error && result.result) {
            this.setState({ uploading: true });
            uploadResponse = await uploadFile({ ...file, customViewId: result.result.id });
        }

        this.setState({ loading: false, uploading: false });
        onSubmit(!!result.error || !!uploadResponse.error);
    };

    handleFormChange = (property, value) => {
        this.updateOptionsOnFormChange(property, value);

        this.setState({
            model: {
                ...this.state.model,
                [property]: value
            },
        });
    };

    updateOptionsOnFormChange = (property, value) => {
        // User options depend on selected customers and functional locations
        // Building options depend on selected customer
        const model = this.state.model;
        const prop = isArray(property) ? property[0] : property;
        switch (prop) {
        case 'portfolio':
            value
                ? this.searchProfileOptions([model.customers], SEARCH_TYPE_PARTNER_NUMBER)
                : this.searchProfileOptions(
                    map(model.functionalLocations, 'value'),
                    SEARCH_TYPE_FUNCTIONAL_LOCATION,
                    model.customers
                );
            break;
        case 'customers':
            model.portfolio && this.searchProfileOptions([value], SEARCH_TYPE_PARTNER_NUMBER);
            this.searchFLOptions([value]);
            break;
        case 'functionalLocations':
            this.searchProfileOptions(map(value, 'value'), SEARCH_TYPE_FUNCTIONAL_LOCATION, model.customers);
            break;
        default:
            break;
        }
    };

    searchProfileOptions = async (searchWords, searchType, partnerNumber) => {
        if (Array.isArray(searchWords) && searchWords.length > 0) {
            const promises = map(searchWords, word => this.props.searchProfiles(word, searchType));

            // users may only have portfolio-level permissions to a functional location
            if (partnerNumber) {
                promises.push(this.props.searchProfiles(partnerNumber, SEARCH_TYPE_PARTNER_PERMISSION));
            }

            const result = await Promise.all(promises);
            const profileSearchResult = uniqBy(flatten(result), 'username');
            this.setState({ profileSearchResult });
        }
        else {
            this.setState({ profileSearchResult: [] });
        }
    };

    searchFLOptions = searchWords => {
        this.props.searchFLs(searchWords, this.props.flTypes);
    };

    handleFileUpload = files => {
        const file = files[0];
        const filename = file.name;
        const mimeType = file.type;
        const size = file.size;
        const wrongMimeType = mimeType && !startsWith(mimeType, 'image') && mimeType !== 'application/pdf';

        if (size > MAX_SIZE || !mimeType || wrongMimeType) {
            this.setState({ error: 'File is too big or the type is unknown!' });
        }
        else {
            const reader = new FileReader();
            reader.onload = event => {
                if (event.target.result) {
                    let fileString = event.target.result;
                    if (fileString.indexOf(',') !== -1) {
                        fileString = fileString.split(',')[1];
                    }
                    const data = {
                        file: fileString,
                        filename,
                        mimeType,
                        size
                    };

                    this.setState({ file: data });
                }
            };

            reader.readAsDataURL(file);
            this.handleFormChange('content', file.name);
        }
    };

    handleFileRemoval = () => {
        this.setState({ file: null });
        this.handleFormChange('content', null);
    };

    render() {
        const {
            t,
            formMode,
            partnerSearch,
            onClose,
            functionalLocations
        } = this.props;
        const {
            profileSearchResult,
            model,
            error,
            loading,
            uploading
        } = this.state;

        if (!model) {
            return null;
        }

        const isNew = formMode === CUSTOM_VIEW_FORM_MODE.CREATE || formMode === CUSTOM_VIEW_FORM_MODE.DUPLICATE;

        const profileOptions = sortBy(
            map(
                profileSearchResult,
                profile => ({
                    label: profile.username,
                    value: profile
                })
            ), 'label');

        const flOptions = sortBy(
            map(
                functionalLocations,
                fl => ({
                    label: ` ${fl.name} (${fl.functionalLocation})`,
                    value: fl.functionalLocation
                })
            ), 'label');

        const customerOptions = sortBy(partnerSearch.options || [], 'label');

        return (
            <CustomViewForm
                t={ t }
                customView={ model }
                addNew={ isNew }
                onChange={ this.handleFormChange }
                onSubmit={ this.handleSubmit }
                customerOptions={ customerOptions }
                usersOptions={ profileOptions }
                flOptions={ flOptions }
                onUserSearch={ this.searchProfileOptions }
                onClose={ onClose }
                errorMessage={ error && <ErrorMessage t={ t } message={ error } /> }
                loading={ loading }
                handleFileUpload={ this.handleFileUpload }
                handleFileRemoval={ this.handleFileRemoval }
                uploading={ uploading }
            />
        );
    }
}

EditOrCreateCustomView.defaultProps = {
    formMode: CUSTOM_VIEW_FORM_MODE.CREATE
};

EditOrCreateCustomView.propTypes = {
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    formMode: PropTypes.string.isRequired,
    customView: PropTypes.object,
    newViewType: PropTypes.oneOf([...values(VIEW_TYPES), '']),
};

const mapStateToProps = state => ({
    partnerSearch: state.customer.partnerSearch,
    functionalLocations: state.customer.functionalLocationSearchResults,
    flTypes: state.customer.typesToSearch,
});

const mapDispatchToProps = dispatch => ({
    updateCustomView: customView => dispatch(updateCustomView(customView)),
    searchProfiles: (searchWord, searchType) => dispatch(searchProfiles(searchWord, searchType)),
    searchFLs: (partners, typesToSearch) => dispatch(searchFunctionalLocationsByPartners(partners, typesToSearch)),
    uploadFile: file => dispatch(uploadCustomViewFile(file))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(EditOrCreateCustomView));
