import { createReducerFromMapping } from 'redux/utils/index.js';
import { loadCounts } from 'redux/modules/service/serviceOrders.js';
import {
    loadPartnerAlarmCounts, loadHandlingTimeKPI, loadTimeToActionKPI, loadAlarmPerformance,
} from 'redux/modules/iot/alarm';
import { loadObservationKpi, loadInspectionKpi, loadObservationPerformance } from 'redux/modules/iot/notice';
import getServiceOrderStartDate from 'utils/Date/serviceOrderStartDate.js';
import moment from 'moment-timezone';
import { isEmpty, map, includes, intersection } from 'lodash';

import { loadEnergyRatingKPI, loadConsumptionKPI, loadAirQualityKPI } from '../iot/values';
import { getWhereFromPermissions } from '../../utils/partnerFilter';
import { loadPermissions } from 'redux/modules/profile/profile';

export const LOAD_KPI = 'CUSTOMER_PLATFORM/KPIContainer/LOAD_KPI';
export const LOAD_KPI_SUCCESS = 'CUSTOMER_PLATFORM/KPIContainer/LOAD_KPI_SUCCESS';
export const LOAD_KPI_FAIL = 'CUSTOMER_PLATFORM/KPIContainer/LOAD_KPI_FAIL';

const initialState = {
    loading: true,
    error: false
};

const kpiYearStart = moment.utc().startOf('year');
const kpiYearEnd = moment.utc().endOf('year');
const now = moment.utc().endOf('hour');
const yearAgo = moment.utc().subtract(365, 'days').startOf('day');

export const loadKPIContainer = (partnerNumber, serviceOrderStartDate, profileKpis, buildingPerformanceSource) => {
    const kpiServiceOrderStart = getServiceOrderStartDate(serviceOrderStartDate, kpiYearStart, kpiYearEnd);

    return async (dispatch, getState) => {
        // Fetch based on partner number if user has partner wide permissions, otherwise specify functional locations
        const { profile } = getState().profile;
        const baseWhere = getWhereFromPermissions(profile, partnerNumber, {
            buildPartnerNumberFilter: partnerNumber => ({
                partnerNumber: {
                    'any x': {
                        x: partnerNumber,
                    },
                },
            }),
        });

        dispatch({ type: LOAD_KPI });
        try {
            if (includes(profileKpis, 'energy') || buildingPerformanceSource === 'energy') {
                dispatch(loadEnergyRatingKPI(partnerNumber));
            }

            !isEmpty(intersection(profileKpis, ['plannedMaintenance', 'serviceOrders']))
                && dispatch(loadCounts(kpiServiceOrderStart, kpiYearEnd, undefined, partnerNumber));
            includes(profileKpis, 'alarms')
                && dispatch(loadPartnerAlarmCounts(partnerNumber, baseWhere, yearAgo, now));
            includes(profileKpis, 'alarmHandlingTime') && dispatch(loadHandlingTimeKPI(partnerNumber));
            includes(profileKpis, 'alarmTimeToAction') && dispatch(loadTimeToActionKPI(partnerNumber));
            includes(profileKpis, 'observations')
                && dispatch(loadObservationKpi(partnerNumber, baseWhere, yearAgo, now));
            includes(profileKpis, 'inspections')
                && dispatch(loadInspectionKpi(partnerNumber, baseWhere, kpiYearStart, now));

            if (includes(profileKpis, 'indoorAirQuality') || buildingPerformanceSource === 'indoorAirQuality') {
                dispatch(loadAirQualityKPI(partnerNumber));
            }

            const energyConsumption = includes(profileKpis, 'energyConsumption') ||
                buildingPerformanceSource === 'energyConsumption';

            if (energyConsumption || includes(profileKpis, 'electricity')) {
                dispatch(loadConsumptionKPI(partnerNumber, 'electricity_main'));
            }

            if (energyConsumption || includes(profileKpis, 'districtHeating')) {
                dispatch(loadConsumptionKPI(partnerNumber, 'district_heating'));
            }

            if (energyConsumption || includes(profileKpis, 'water')) {
                dispatch(loadConsumptionKPI(partnerNumber, 'water_consumption'));
            }

            if (buildingPerformanceSource === 'alarms') {
                dispatch(loadAlarmPerformance(partnerNumber));
            }

            if (buildingPerformanceSource === 'observations') {
                dispatch(loadObservationPerformance(partnerNumber));
            }

            let permissions = profile.permissions[partnerNumber];
            if (!permissions) {
                const result = await dispatch(loadPermissions());
                if (!!result && !isEmpty(result.result)) {
                    permissions = map(result.result, fl => fl.functionalLocation);
                }
            }

            dispatch({ type: LOAD_KPI_SUCCESS });
        } catch (error) {
            return dispatch({
                type: LOAD_KPI_FAIL,
                error
            });
        }
    };
};

export default createReducerFromMapping({
    [LOAD_KPI]: state => ({
        ...state,
        loading: true
    }),
    [LOAD_KPI_SUCCESS]: state => ({
        ...state,
        loading: false
    }),
    [LOAD_KPI_FAIL]: (state, action) => ({
        ...state,
        loading: false,
        error: action.error
    })
}, initialState);
