import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import InputLabel from 'components/Form/InputLabel';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import RadioButton from 'components/Form/RadioButton';
import InputText from 'components/Form/InputText';
import InfoBox from 'components/InfoBox/InfoBox';

const Container = styled.div`
    max-width: 700px;
`;

const Group = styled.div`
    &:not(:last-child) {
        margin-bottom: ${props => props.theme.spacing.md};
    }
`;
Group.displayName = 'Group';

const Heading = styled.div`
    color: ${props => props.theme.colors.black};
    font-weight: ${props => props.theme.fontWeight.bold};
`;
Heading.displayName = 'Heading';

const Name = styled.span`
    font-weight: ${props => props.theme.fontWeight.text};
`;

const Thresholds = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${props => props.theme.media.landscape`
        flex-direction: row;
    `}
`;
Thresholds.displayName = 'Thresholds';

const Threshold = styled.div`
    width: 100%;

    &:first-child {
        margin-right: ${props => props.theme.spacing.md};
    }
`;

const RadioButtonTitle = styled.div`
    font-weight: ${props => props.theme.fontWeight.bold};
    font-size: ${props => props.theme.fontSize.note};
    color: ${props => props.theme.colors.black};
    padding: ${props => props.theme.spacing.xs} 0;
`;
RadioButtonTitle.displayName = 'RadioButtonTitle';

const SensorAlarmForm = ({
    t,
    sensor,
    onPropertyChange,
    sensorName,
    isPresence
}) => {
    const onChangeNumber = (property, value) => {
        const numberValue = parseInt(value, 10);
        onPropertyChange(property, numberValue);
    };

    return <Container>
        <Group>
            <Heading>{`${t('Measuring Point')}: `}<Name>{sensorName}</Name></Heading>
        </Group>
        <Group>
            { !isPresence && <Thresholds>
                <Threshold>
                    <InputLabel text={t('Min threshold')} id="min"/>
                    <InputText
                        value={sensor && sensor.min}
                        property="min"
                        id="min"
                        type="number"
                        onChange={(property, value) => onChangeNumber(property, value)}
                    />
                </Threshold>
                <Threshold>
                    <InputLabel text={t('Max threshold')} id="max"/>
                    <InputText
                        value={sensor && sensor.max}
                        property="max"
                        id="max"
                        type="number"
                        onChange={(property, value) => onChangeNumber(property, value)}
                    />
                </Threshold>
            </Thresholds> }
            { isPresence && <Fragment>
                <RadioButtonTitle>{t('Presence alarms')}</RadioButtonTitle>
                <RadioButtonGroup
                    name="occupiedAlarm"
                    value={sensor && sensor.occupiedAlarm}
                    onChange={onPropertyChange}
                >
                    <RadioButton label={t('When occupied')} value="occupied" />
                    <RadioButton label={t('When available')} value="available" />
                </RadioButtonGroup>
            </Fragment> }
        </Group>
        <Group>
            <RadioButtonTitle>{t('Send Alarms')}</RadioButtonTitle>
            <RadioButtonGroup
                value={sensor && sensor.weekdays}
                onChange={onPropertyChange}
                name="weekdays"
            >
                <RadioButton
                    label={t('During weekdays (Monday to Friday)')}
                    value={true}
                />
                <RadioButton
                    label={t('Whole week')}
                    value={false}
                />
            </RadioButtonGroup>
        </Group>
        <InfoBox>
            {`${t('Alarm will be send into')} `}
            <b>{`${t('your profile\'s email account')} `}</b>
            {t('once when the threshold is being crossed. ' +
                'You will only receive one alarm within the time the sensor is over the threshold.')}
        </InfoBox>
    </Container>;
};

SensorAlarmForm.propTypes = {
    t: PropTypes.func.isRequired,
    sensor: PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        min: PropTypes.number,
        max: PropTypes.number,
        weekdays: PropTypes.bool
    }).isRequired,
    onPropertyChange: PropTypes.func.isRequired,
    sensorName: PropTypes.string.isRequired,
    isPresence: PropTypes.bool
};

SensorAlarmForm.defaultProps = {
    isPresence: false
};

export default SensorAlarmForm;
