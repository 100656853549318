import React from 'react';
import styled, { css } from 'styled-components';
import { stripUnit } from 'polished';
import PropTypes from 'prop-types';

const getColumnWidth = props => {
    const { columnWidth, theme } = props;
    if (!isNaN(columnWidth)) {
        return css`
            width: ${theme.getColumnWidth(columnWidth)};
        `;
    } else if (typeof columnWidth === 'object') {
        return css`
            ${columnWidth.default && css`
                width: ${theme.getColumnWidth(columnWidth.default)};
            `}
            ${Object.keys(theme.breakpoints)
            .map(key => {
                if (columnWidth[key]) {
                    return css`
                        ${theme.media[key]`
                            width: ${theme.getColumnWidth(columnWidth[key])};
                        `}
                    `;
                }
                return null;
            })}
        `;
    }
    return css`
        width: ${theme.getColumnWidth(1)};
    `;
};

const StyledColumn = styled.div`
    margin-left: ${props => stripUnit(props.theme.grid.gutter) / 2 + 'rem'};
    margin-right: ${props => stripUnit(props.theme.grid.gutter) / 2 + 'rem'};
    ${props => getColumnWidth(props)}
`;
StyledColumn.displayName = 'StyledColumn';

const Column = props => <StyledColumn {...props} />;

Column.propTypes = {
    columnWidth: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object
    ])
};

Column.defaultProps = {
    columnWidth: 1
};

export default Column;
