import React from 'react';
import PropTypes from 'prop-types';
import PerformanceItem from 'components/Sensor/PerformanceSelector/PerformanceItem';
import { Container } from 'components/Sensor/PerformanceSelector/PerformanceSelector';
import { Items } from 'components/Sensor/PerformanceSelector/PerformanceItems';


const PresenceKeys = ({ t, color, loading, utilizationRate, unusedHours }) => {

    return (
        <Container>
            <Items>
                <PerformanceItem
                    title={ t('Utilization Rate') }
                    color={ color }
                    loading={ loading }
                    performance={ utilizationRate }
                />
                <PerformanceItem
                    noChart
                    nextToChart
                    title={ t('Hours Unused') }
                    loading={ loading }
                    performance={ unusedHours }
                    color={ color }
                />
            </Items>
        </Container>
    );
};

PresenceKeys.propTypes = {
    t: PropTypes.func.isRequired,
    color: PropTypes.string,
    loading: PropTypes.bool,
    utilizationRate: PropTypes.number,
    unusedHours: PropTypes.number,
};

export default PresenceKeys;
