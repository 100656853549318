import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { intersection } from 'lodash';
import Helmet from 'react-helmet';
import { StandardPage } from 'components/index.js';
import translations from 'decorators/Translations/translations';
import { getPartnerNumbers } from 'utils/profile';
import { isValidPartner } from 'utils/Data/partners';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import Header from 'containers/Application/Header/Header.jsx';

import { icons } from 'components/Icon/IconNames';
import { default as ServiceRequestForm } from './ServiceRequestForm.jsx';

import {
    setNewServiceRequestProperty,
    createServiceRequest,
    loadFunctionalLocations,
    loadPartnerMeta,
} from 'redux/modules';

class ServiceRequest extends Component {
    state = { saving: false, saved: false };

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            this.loadData();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { loadFunctionalLocation, loadPartnerMeta, location: { search },
            match: { params: { partnerNumber } } } = this.props;

        const query = queryString.parse(search),
            functionalLocationKey = query.functionalLocation;

        functionalLocationKey && loadFunctionalLocation(functionalLocationKey);

        if (partnerNumber && partnerNumber !== 'all') {
            loadPartnerMeta(partnerNumber);
        }
    };

    createServiceRequest = newServiceRequest => {
        const { profile, functionalLocations } = this.props;

        this.setState({ saving: true });

        // If we are in '/all' -path, find a partnerNumber from FL that is also in user's profile
        // (there should always be at least one)
        if (!isValidPartner(newServiceRequest.partnerNumber)) {
            const profilePartnerNumbers = getPartnerNumbers(profile);
            const functionalLocation = functionalLocations[newServiceRequest.functionalLocation];
            const commonPartnerNumbers = functionalLocation &&
                intersection(profilePartnerNumbers, functionalLocation.partnerNumberWithParents);
            if (!commonPartnerNumbers.length) {
                this.setState({ saving: false });
                return;
            }
            newServiceRequest.partnerNumber = commonPartnerNumbers[0];
        }

        this.props.createServiceRequest(newServiceRequest).then(result => {
            !result.error && this.setState({ saving: false, saved: true });
            result.error && this.setState({ saving: false });
        });

        setTimeout(() => {
            this.setState({ saved: false });
        }, 4000);
    };

    render() {
        const {
            t,
            newServiceRequest,
            functionalLocations,
            match: { params: { partnerNumber, functionalLocationId } },
            features,
        } = this.props;

        if (features && !features.sr) {
            return null;
        }

        const functionalLocation = functionalLocations[functionalLocationId];

        const links = [
            { title: 'Back to Overview', icon: icons.ARROW_LEFT, to: { pathname: '/' }, smallIcon: true },
            { title: 'Service Request', icon: icons.NEW_SERVICE_REQUEST }
        ];

        const content =
            <Section>
                <SectionHeader noBorder title={ t('Service Request') } t={ t } />
                <ServiceRequestForm id="serviceRequestForm"
                    model={ newServiceRequest }
                    setProperty={ this.props.setNewServiceRequestProperty }
                    submit={ () => this.createServiceRequest(newServiceRequest) }
                    t={ t }
                    functionalLocation={ functionalLocation }
                    partnerNumber={ partnerNumber }
                    saving={ this.state.saving }
                    saved={ this.state.saved }
                />
            </Section>
        ;

        if (functionalLocationId) {
            return content;
        }

        return (
            <StandardPage>
                <Helmet title={ t('Service Request') } />
                <Header t={ t } showPartnerSelect links={ links } selected="service request" />
                { content }
            </StandardPage>
        );
    }
}

const mapStateToProps = state => ({
    features: state.profile.profile.syntheticFeatures,
    newServiceRequest: state.serviceRequests.new,
    functionalLocations: state.functionalLocations.functionalLocations
});

const mapDispatchToProps = {
    setNewServiceRequestProperty,
    createServiceRequest,
    loadFunctionalLocation: functionalLocationId => loadFunctionalLocations([functionalLocationId]),
    loadPartnerMeta,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(withRouter(ServiceRequest)));
