import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Highcharts from 'highcharts';
import HighchartsBorderRadius from 'highcharts-border-radius';

import themes from '../../styles/themes';

import ErrorPage from './ErrorPage/ErrorPage.jsx';
import ToS from './ToS/ToS.jsx';

import PartnerRedirect from 'containers/PartnerRedirect/PartnerRedirect.jsx';
import Partner from 'containers/Application/Partner/Partner.jsx';
import Admin from 'containers/Application/Admin/Admin.jsx';
import Profile from 'containers/Application/Profile/Profile.jsx';
import LogoutSession from 'containers/Application/Authorization/LogoutSession.jsx';
import NoProfileFound from 'containers/Application/User/NoProfileFound.jsx';

import { loadApplication, ping } from 'redux/modules/containers/application.js';
import { hot } from 'react-hot-loader';
import { isAdminRole } from 'utils/Data/profileData';
import Modal from 'components/Modal/Modal';
import GlobalNotification from 'containers/Application/GlobalNotification/GlobalNotification';
import UserManual from 'components/UserManual/UserManual';
import SensorMetaDataMapping from './SensorMetaDataMapping/SensorMetaDataMapping';
import AdminUserManual from 'components/UserManual/AdminUserManual';
import Giosg from 'components/Giosg/Giosg.jsx';
import { createDataLayerEvents } from 'utils/Analytics/analytics.js';
import { sendError } from 'utils/Error/error.js';
import { setGeneralLoading, setSplashLoading } from 'redux/modules';
import { isNil } from 'lodash';

HighchartsBorderRadius(Highcharts);

class Application extends Component {
    constructor() {
        Highcharts.setOptions({
            chart: {
                style: {
                    fontFamily: 'Arial, sans-serif'
                }
            }
        });
        super();
        this.state = {
            showError: false,
        };
        this.pageTitle = '';
    }

    componentDidMount() {
        const { location: { pathname }, ping, history } = this.props;
        this.props.loadApplication(pathname);
        this.pingTimer = setInterval(ping, 60 * 1000);
        if (pathname !== '/login') {
            this.props.setGeneralLoading(false);
            this.props.setSplashLoading(true);
        }
        else {
            this.props.setSplashLoading(false);
            this.props.setGeneralLoading(true);
        }
        // Listen to history changes and create pageview events
        history.listen((location, action) => {
            // Don't create page view if redirecting
            if (action !== 'REPLACE') {
                this.createPageviewEvent();
            }
        });
    }

    componentWillUnmount() {
        clearInterval(this.pingTimer);
    }

    componentDidCatch(error, errorInfo) {
        sendError(error, errorInfo);
        this.setState({ showError: true });
    }

    componentDidUpdate(prevProps) {
        const { loading, activePartner } = this.props;
        if (loading === false) {
            this.props.setGeneralLoading(false);
            this.props.setSplashLoading(false);
        }
        // Create first page view after activePartner is set
        if (!isNil(activePartner) && isNil(prevProps.activePartner)) {
            this.createPageviewEvent();
        }
    }

    createPageviewEvent(partnerNumber) {
        const self = this;
        // Hacky way to wait for pagetitle
        setTimeout(() => {
            const { activePartner } = self.props;
            const dataLayerData = {
                partnerNumber: activePartner
            };
            // Create pageview events
            createDataLayerEvents({
                title: this.pageTitle,
                ...dataLayerData
            });
        }, 500);
    }

    handlePageView = newState => {
        if (newState && newState.title && newState.title !== this.pageTitle) {
            this.pageTitle = newState.title;
        }
    };

    render() {
        const { error, session, loading, location: { pathname }, profile: { role } } = this.props;

        // Show general loading indicator in index.js when the authorization is in progress.
        if (loading) {
            return null;
        }

        // Show error page if there is an error in loading session.
        if (session.error || this.state.showError) {
            return (
                <ThemeProvider theme={ themes.customerPlatform }>
                    <Fragment>
                        <ErrorPage />
                    </Fragment>
                </ThemeProvider>
            );
        }

        // Show different error page if there is an error loading profile data (except if it's logout route).
        if (error && pathname !== '/Logout') {
            return <NoProfileFound />;
        }

        return (
            <ThemeProvider theme={ themes.customerPlatform }>
                <Fragment>
                    <Helmet
                        titleTemplate="%s - Caverion SmartView"
                        onChangeClientState={ this.handlePageView }
                    />
                    <Modal />
                    <ToS />
                    <Route path="/UserManual" component={ UserManual } />
                    <Route path="/AdminUserManual" component={ AdminUserManual } />
                    <Route exact path="/" component={ PartnerRedirect } />
                    <Route exact path="/index.html" component={ PartnerRedirect } />
                    <Route exact path="/login" component={ PartnerRedirect } />
                    <Route exact path="/Overview" component={ PartnerRedirect } />
                    <Route path="/:partnerNumber" component={ Partner } />
                    <Route path="/Admin" component={ isAdminRole(role) ? Admin : PartnerRedirect } />
                    <Route exact path="/Profile" component={ Profile } />
                    <Route exact path="/DataMapping" component={ SensorMetaDataMapping } />
                    <Route exact path="/Logout" component={ LogoutSession } />
                    <GlobalNotification />
                    { role && <Giosg /> }
                </Fragment>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = state => ({
    firstLoad: state.application.firstLoad,
    loading: state.application.loading,
    session: state.session,
    error: state.application.error,
    profile: state.profile.profile,
    activePartner: state.profile.activePartner,
});

const mapDispatchToProps = {
    loadApplication,
    ping,
    setGeneralLoading,
    setSplashLoading
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default hot(module)(withRouter(connector(Application)));
