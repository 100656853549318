import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { Link } from 'react-router-dom';

import { NameColumn } from './SortableFLTableNameColumn';
import { AddressColumn } from './SortableFLTableAddressColumn';
import { EquipmentColumn } from './SortableFLTableEquipmentColumn';
import { IconColumn } from './SortableFLTableIconColumn';
import ImageColumn from './SortableFLTableImageColumn';

import { getFunctionalLocationUrl, getEquipmentUrl } from 'utils/Data/functionalLocations';
import ValueColumn from '../Conditions/ConditionTable/ValueColumn';

const FlRow = styled.tr`
    border-bottom: ${props => props.theme.spacing.border} solid ${props => props.theme.colors.alabaster};
    font-family: ${props => props.theme.fontFamily.text};
    font-size: ${props => props.theme.fontSize.sm};
    a { color: ${props => props.theme.colors.black} };

    &:hover {
        cursor: pointer;
        background: ${props => props.theme.colors.alabaster};
        span {
            transform: scale(1.1);
            transition: ${props => props.theme.motion.easing} ${props => props.theme.motion.quick};
        }
    }
`;

FlRow.displayName = 'FlRow';

const FlCell = styled.td`
    position: relative;
    padding: ${props => props.theme.spacing.xs} 0 ${props => props.theme.spacing.xs} ${props => props.theme.spacing.md};
    display: ${props => props.hideOnMobile && 'none'};
    vertical-align: middle;
    width: ${props => props.width};
    ${props => props.theme.media.landscape`
        display: table-cell;
    `}
`;

FlCell.displayName = 'FlCell';

export const StyledIcon = styled.span`
    margin-bottom: ${props => props.marginBottom && props.theme.spacing.xs};
    margin-right: ${props => props.marginRight && props.theme.spacing.md};
    > div {
        margin-top: 0;

        &:hover {
            cursor: pointer;
        }
    }
`;

StyledIcon.displayName = 'StyledIcon';

const StyledLink = styled(Link)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
`;
StyledLink.displayName = 'StyledLink';


export const SortableFLTableRow = ({
    rowData,
    rowData: {
        address, description, functionalLocation, type, equipmentCount, equipmentNumber, tab, count, performance
    },
    features,
    match: { params },
    t,
    customViews,
    theme,
}) => {
    const partnerNumber = params.partnerNumber;
    const superordinate = params.equipmentNumber;
    const link = equipmentNumber ?
        getEquipmentUrl(partnerNumber, functionalLocation, equipmentNumber, superordinate) :
        getFunctionalLocationUrl(rowData, features, partnerNumber, customViews, tab);

    return <FlRow data-test-id="FLTableRow">
        <FlCell width="48px">
            <StyledLink to={link} />
            <ImageColumn
                functionalLocation={rowData}
                type={type}
            />
        </FlCell>
        <FlCell>
            <StyledLink to={link} />
            <NameColumn
                functionalLocation={functionalLocation}
                description={description}
                type={type}
                count={ count }
            />
        </FlCell>
        {!isNil(address) && <FlCell hideOnMobile>
            <StyledLink to={link} />
            <AddressColumn address={address}/>
        </FlCell>}
        {!isNil(equipmentCount) && <FlCell hideOnMobile>
            <StyledLink to={link} />
            <EquipmentColumn equipmentCount={equipmentCount} t={t} type={type}/>
        </FlCell>}
        { typeof performance !== 'undefined' &&
            <FlCell hideOnMobile width="10px">
                { performance !== null &&
                    <React.Fragment>
                        <StyledLink to={ link } />
                        <ValueColumn t={ t } data={{ ...performance, value: performance.formattedValue }} />
                    </React.Fragment>
                }
            </FlCell>
        }
        <FlCell hideOnMobile>
            <StyledLink to={link} />
            <IconColumn/>
        </FlCell>
    </FlRow>;
};

SortableFLTableRow.defaultProps = {
    rowData: {},
    features: {}
};

SortableFLTableRow.propTypes = {
    t: PropTypes.func.isRequired,
    rowData: PropTypes.shape({
        address: PropTypes.string,
        description: PropTypes.string.isRequired,
        functionalLocation: PropTypes.string,
        type: PropTypes.string.isRequired,
        equipmentCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        equipmentNumber: PropTypes.string
    }),
    features: PropTypes.object
};

const mapStateToProps = state => ({
    features: state.profile.profile.syntheticFeatures,
    customViews: state.profile.profile.customViews,
});
const connector = connect(mapStateToProps, null);

export default withRouter(connector(SortableFLTableRow));
