import React from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import { FunctionalLocationIcon } from 'components/';
import { ICON_TYPES, ICON_SIZES, getIconContainerSizeByType } from 'components/Icon/Icon';
import { StyledIcon } from './SortableFLTableRow';
import { getBuildingImage } from './SortableFLTableUtil';

const BuildingImage = styled.div`
    width: ${({ size }) => getIconContainerSizeByType(size)};
    height: ${({ size }) => getIconContainerSizeByType(size)};
    background-position: center center;
    background-size: cover;
    border-radius: 50%;
    background-image: url(${props => props.src});
`;

const getIconType = type =>
    type === 'SYSTEM' ? ICON_TYPES.SECONDARY :
    type === 'EQUIPMENT' ? ICON_TYPES.EQUIPMENT :
    ICON_TYPES.DEFAULT;

export const ImageColumn = ({ functionalLocation, flImages, type, theme }) => {
    const buildingImage = getBuildingImage(functionalLocation.functionalLocation, flImages);
    const size = ICON_SIZES.SMALL_PLUS;
    return <StyledIcon>
        {!!buildingImage
            ? <BuildingImage src={buildingImage} size={ size } />
            : <FunctionalLocationIcon
                functionalLocation={functionalLocation}
                iconType={ getIconType(type) }
                size={ size }
                fill={ type === 'EQUIPMENT' ? theme.colors.midnight : theme.colors.white}
            />}
    </StyledIcon>;
};

ImageColumn.defaultProps = {
    flImages: {}
};

ImageColumn.propTypes = {
    functionalLocation: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    flImages: PropTypes.object
};

const mapStateToProps = state => ({ flImages: state.partnerImage.byFL });
export default connect(mapStateToProps, null)(withTheme(ImageColumn));

