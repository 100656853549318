import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

import { performanceColors, getPerformanceStatus } from 'utils/Data/performance.js';

const Performance = styled.div`
    color: ${props => performanceColors(props)[props.status]};
    white-space: nowrap;
    margin-left: ${props => props.theme.spacing.sm};
`;
Performance.displayName = 'Performance';

const PerformanceOverall = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: ${props => props.theme.spacing.sm};
    cursor: pointer;
    font-size: ${props => props.theme.fontSize.heading};
    font-weight: ${props => props.theme.font.weight.bold};
    font-family: ${props => props.theme.font.family.sanchez};
    color: ${props => props.theme.colors.black};

    ${props => props.theme.media.landscape`
        justify-content: space-between;
    `}
`;
PerformanceOverall.displayName = 'PerformanceOverall';

const OPI = 'OPI_Overall';

const OpiOverall = ({ overallSensor, overallPerformanceValue, onClick, t, loading }) => {
    if (!t || !loading && (!overallSensor || isNil(overallPerformanceValue))) {
        return null;
    }

    const title = t(`${OPI.replace('_', ' ')} Performance`);
    const status = loading ? 'loading' : getPerformanceStatus(overallPerformanceValue);
    const value = loading ? '...' : `${ overallPerformanceValue.toFixed(1) } %`;
    const onPerfClick = loading ? undefined : () => onClick(overallSensor.id);

    return (
        <PerformanceOverall key={ OPI } onClick={ onPerfClick }>
            { title }
            <Performance status={ status }>{ value }</Performance>
        </PerformanceOverall>
    );
};
OpiOverall.displayName = 'OpiOverall';

OpiOverall.defaultProps = {
    overallPerformanceValue: null,
    overallSensor: null,
    loading: false,
};

OpiOverall.propTypes = {
    overallSensor: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    overallPerformanceValue: PropTypes.number,
    loading: PropTypes.bool,
};

export default OpiOverall;
