import styled from 'styled-components';

export const PermissionListItemHeading = styled.h4`
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.font.size.sm};

    ${props => props.theme.media.portrait`
        font-size: ${props => props.theme.font.size.md};
    `}
`;
