import moment from 'moment';
import { meanBy, isNil, round, filter, find, maxBy } from 'lodash';

export const CELSIUS = `${String.fromCharCode(176)}C`;

export const electricityMainSensorNames = ['electricity_main', 'energy/hour'];
export const electricitySubSensorNames = ['electricity_sub'];
export const electricitySensorNames = [
    ...electricityMainSensorNames
];
export const districtHeatingSensorNames = ['district_heating'];
export const waterConsumptionSensorNames = ['water_consumption'];
export const energyRatingSensorNames = ['energy/m2', 'energy_rating'];
export const energySensorNames = [
    ...electricityMainSensorNames,
    ...electricitySubSensorNames,
    ...districtHeatingSensorNames,
    ...waterConsumptionSensorNames,
    ...energyRatingSensorNames
];
export const recyclingSensorNames = [
    // TODO: Add recycling sensor names
];
export const energyConsumptionSensorNames = [
    ...electricityMainSensorNames,
    ...electricitySubSensorNames,
    ...districtHeatingSensorNames,
    ...waterConsumptionSensorNames
];
export const isEnergySensor = type => {
    return energySensorNames.indexOf(type) !== -1;
};
export const isRecyclingSensor = type => {
    return recyclingSensorNames.indexOf(type) !== -1;
};
export const isElectricitySensor = type => {
    return electricitySensorNames.indexOf(type) !== -1;
};
export const isElectricityMainSensor = type => {
    return electricityMainSensorNames.indexOf(type) !== -1;
};
export const isElectricitySubSensor = type => {
    return electricitySubSensorNames.indexOf(type) !== -1;
};
export const isDistrictHeatingSensor = type => {
    return districtHeatingSensorNames.indexOf(type) !== -1;
};
export const isWaterConsumptionSensor = type => {
    return waterConsumptionSensorNames.indexOf(type) !== -1;
};
export const isEnergyRatingSensor = type => {
    return energyRatingSensorNames.indexOf(type) !== -1;
};
export const isEnergyConsumptionSensor = type => {
    return energyConsumptionSensorNames.indexOf(type) !== -1;
};
export const getUnitBySensorType = type => {
    if (isWaterConsumptionSensor(type)) {
        return 'm³';
    } else if (isElectricityMainSensor(type) || isElectricitySubSensor(type) || isDistrictHeatingSensor(type)) {
        return 'kWh';
    } else if (isEnergyRatingSensor(type)) {
        return 'kWh/m²';
    }
    return '';
};
// Configuration for energy breakdown ui tabs
export const energyBreakdownTypes = [
    'electricity',
    'districtHeating',
    'waterConsumption'
];
// Configuration for total energy consumption
export const totalEnergyConsumptionTypes = [
    'electricity',
    'districtHeating'
];
// Map energy type to reference key
export const metaReferenceKeys = {
    districtHeating: 'district_heating_reference',
    electricity: 'electricity_reference',
    energy: 'energy_reference'
};

export const getLatestSensorValueProperties = (latestValue, sensorType, sensorData, sensorMeta, theme, t) => ({
    value: getLatestValueForType(latestValue, sensorType, sensorData, sensorMeta, t),
    time: getLatestValueTimeForType(latestValue, sensorType),
    color: getLatestValueColorForType(latestValue, sensorType, theme)
});

export const getLatestValueForType = (latestValue, sensorType, sensorData, sensorMeta, t) => {
    if (sensorType && isEnergySensor(sensorType.name)) {
        return;
    }
    if (!latestValue || isNil(latestValue.value)) {
        return 'N/A';
    }

    const unit = sensorType && sensorType.unit || '';

    if (sensorType) {
        switch (sensorType.graphType) {
        case 'presence':
            return latestValue.value > 0 ? t('Occupied') : t('Available');
        case 'cleaning':
            return moment.utc(latestValue.timestamp).local().format('D. MMMM');
        case 's2':
            return `${Math.round(meanBy(sensorData, 'avg')) || ''} ${unit}`;
        default:
            break;
        }

        if (sensorType.name === 'area_count') {
            const capacity = sensorMeta && find(sensorMeta, { metaKey: 'capacity' });
            const usage = capacity && round(latestValue.value / capacity.value * 100);
            if (!capacity || !isFinite(usage)) {
                return 'N/A';
            }
            return `${usage} %`;
        }
    }

    return `${round(latestValue.value, 1)} ${unit}`;
};

export const getLatestValueTimeForType = (latestValue, sensorType) => {
    if (sensorType && sensorType.name !== 'energy_rating'
        && latestValue && !isNil(latestValue.value)) {
        return moment.utc(latestValue.timestamp).local().format('MMMM D, YYYY HH:mm');
    }
};

export const getLatestValueColorForType = (latestValue, sensorType, theme) => {
    if (sensorType && sensorType.name === 'energy_rating') {
        return;
    }
    if (!latestValue || isNil(latestValue.value)) {
        return theme.colors.black;
    }

    if (sensorType) {
        switch (sensorType.graphType) {
        case 'presence':
            return latestValue.value > 0
                ? theme.colors.radicalRed
                : theme.colors.emerald;
        case 's2':
            return latestValue.value && latestValue.value > 90
                ? theme.colors.emerald
                : theme.colors.black;
        default:
            break;
        }
    }

    return theme.colors.black;
};

// Types for the building status sensors
export const HEATING_TYPE = 'heating utilization',
    COOLING_TYPE = 'cooling utilization',
    OUTDOOR_TYPE = 'outdoor temperature';

export const utilizationTypes = [HEATING_TYPE, COOLING_TYPE, OUTDOOR_TYPE];

export const filterBusinessDayValues = values => {
    const isMondayToFriday = timestamp => {
        const weekday = new Date(timestamp).getDay();
        return weekday >= 1 && weekday <= 5;
    };

    return filter(values, value => isMondayToFriday(value.timestamp));
};

export const getSensorMetaValue = (metaKey, sensorMeta = []) => {
    return metaKey && find(sensorMeta, { metaKey })?.value;
}

export const getMaximumSensorValue = sensorData => {
    return maxBy(sensorData, 'value')?.value;
}
