import React from 'react';
import { some, isEmpty } from 'lodash';
import styled from 'styled-components';
import SortableTable from 'components/SortableTable/SortableTable';
import CleaningRow from './CleaningRow';
import TableCell from 'components/BuildingEvents/EventsRow/EventsCell';
import SkeletonText from 'components/Skeletons/SkeletonText';

const Note = styled.div`
    color: ${props => props.theme.colors.rockBlue};
    font-size: ${props => props.theme.fontSize.xs};
    padding-top: ${props => props.theme.spacing.md};
`;

const Asterisk = styled.span`
    font-size: ${props => props.theme.fontSize.md};
    vertical-align: middle;
`;

const SkeletonBodyComponent = () =>
    <tbody>
        <tr>
            <TableCell>
                <SkeletonText width="40%" style={{ marginLeft: '1em' }} />
            </TableCell>
            <TableCell>
                <SkeletonText />
            </TableCell>
        </tr>
    </tbody>
;

const CleaningTable = props => {
    const { data, t, onClick, loading } = props;
    const hasBuildingSensors = some(data, item => isEmpty(item.area.value));

    return <div>
        <SortableTable
            columns={[
                {
                    title: t('Area/Floor'),
                    field: 'area',
                    sortable: true
                },
                {
                    title: t('Info'),
                    field: 'value',
                    sortable: false
                },
                {
                    title: t('Date'),
                    field: 'date',
                    sortable: true,
                    align: 'right'
                }
            ]}
            data={ data }
            onClick={ onClick }
            RowComponent={ CleaningRow }
            SkeletonBodyComponent={ SkeletonBodyComponent }
            headerBackgroundColor="transparent"
            borderColor="porcelain"
            orderBy="date"
            order="desc"
            t = { t }
            showControls
            loading={ loading }
        />
        { hasBuildingSensors && <Note><Asterisk>&#42;</Asterisk> { t('Area is undefined') }</Note> }
    </div>;
};

export default CleaningTable;
