import React, { PureComponent } from 'react';
import styled, { withTheme } from 'styled-components';
import translations from 'decorators/Translations/translations';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import Button from 'components/Button/Button';
import { showModal } from 'redux/modules/modal/modal';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import AdminAnnouncementColumns from 'components/Announcement/AdminAnnouncementColumns';
import AnnouncementForm from 'components/Announcement/AnnouncementForm';
import SkeletonPill from 'components/Skeletons/SkeletonPill';

import { statuses, getStartDate, toISOFormat } from 'components/Announcement/utils';
import { updateFLAnnouncement } from 'redux/modules/announcement/announcement';
import { deleteAnnouncementByFL } from 'redux/modules/announcement/announcement';
import { MODALTYPE } from 'components/Modal/ModalTypes';

const AddNew = styled(Button) `
    min-width: 200px;
    height: 38px;
    font-size: ${props => props.theme.font.size.xs};
    font-family: ${props => props.theme.font.family.sanchez};
`;
AddNew.displayName = 'AddNew';

const StyledErrorMessage = styled.h4`
    display: flex;
    justify-content: center;
    color: red;
    margin: 12px;
    position: relative;
    width: 100%;
`;

const ErrorMessage = ({ t }) => <StyledErrorMessage>{t('Save Failed')}</StyledErrorMessage>;

export class AdminAnnouncemets extends PureComponent {
    state = {
        dialogOpen: false,
        addNew: false,
        loading: false,
        error: null,
    }

    handleSubmit = () => {
        const { t, showNotification } = this.props;
        const model = Object.assign({}, this.state.model);

        model.startDate = getStartDate(model.startDate);
        model.expirationDate = toISOFormat(model.expirationDate);
        model.created = this.state.addNew ? moment.utc().toISOString() : model.created;
        model.modified = moment.utc().toISOString();

        this.setState({ loading: true });
        this.props.updateFLAnnouncement(model)
            .then(() => {
                this.setState({ loading: false }, () => {
                    this.closeForm();
                    const message = this.state.addNew ?
                        t('Announcement created successfully') :
                        t('Announcement updated successfully');
                    const notification = { type: 'success', message };
                    showNotification(notification);
                });
            })
            .catch(error => this.setState({ error, loading: false }));
    };

    openNewForm = () => {
        this.setState({
            dialogOpen: true,
            model: {
                title: '',
                description: '',
                created: {},
                startDate: moment.utc(),
                expirationDate: moment.utc().add(1, 'week'),
                status: statuses[0],
                functionalLocation: this.props.functionalLocation
            },
            addNew: true
        });
    };

    openEditForm = announcement => this.setState({
        dialogOpen: true,
        model: {
            ...announcement,
            startDate: moment.utc(announcement.startDate),
            expirationDate: moment.utc(announcement.expirationDate),
        },
        addNew: false
    });

    closeForm = () => {
        this.setState({ dialogOpen: false, addNew: false });
    };

    deleteAnnouncement = (id, functionalLocation) => {
        return this.props.deleteAnnouncementByFL(id, functionalLocation)
            .then(() => {
                const notification = {
                    type: 'success',
                    message: this.props.t('Announcement deleted successfully')
                };
                this.props.showNotification(notification);
                return Promise.resolve();
            })
            .catch(error => Promise.reject(new Error('Delete Announcement Failed')));
    };

    handleDeletion = ({ id, functionalLocation, title }) => {
        this.props.showModal(
            MODALTYPE.CONFIRMATION_DELETE_ANNOUNCEMENT_MODAL,
            null,
            () => this.deleteAnnouncement(id, functionalLocation),
            `${this.props.t('Announcement')} ${this.props.t('{0} will be deleted', title)}`
        );
    }

    handleFormChange = (property, value) => {
        this.setState({
            model: {
                ...this.state.model,
                [property]: value
            },
        });
    }

    functionalLocationAnnoucementExists = (announcements, functionalLocation) =>
        functionalLocation && announcements[functionalLocation]
        && announcements[functionalLocation].length > 0;

    render() {
        const { announcements, theme, t, functionalLocation, loading } = this.props;
        const flAnnouncements = announcements[functionalLocation] || [];

        return (
            <Section>
                <SectionHeader title={t('News')} t={ t }>
                    {loading
                        ? <SkeletonPill width="200px" />
                        : <AddNew onClick={this.openNewForm} color={theme.colors.emerald}>{t('Add New')}</AddNew>
                    }
                </SectionHeader>
                {this.state.dialogOpen &&
                    <AnnouncementForm
                        t={t}
                        announcement={this.state.model}
                        addNew={this.state.addNew}
                        onChange={this.handleFormChange}
                        onSubmit={this.handleSubmit}
                        onClose={this.closeForm}
                        errorMessage={this.state.error && <ErrorMessage t={t} />}
                        loading={this.state.loading} />
                }
                <AdminAnnouncementColumns
                    t={t}
                    announcements={ flAnnouncements }
                    onEdit={ this.openEditForm }
                    onDestroy={ this.handleDeletion }
                    loading={ loading }
                />
            </Section>
        );
    }
}

AdminAnnouncemets.defaultProps = {
    announcements: {},
    loading: false,
};

AdminAnnouncemets.propTypes = {
    t: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
    announcements: PropTypes.object,
    functionalLocation: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

const mapStateToProps = state => ({
    announcements: state.announcement.byFL
});

const mapDispatchToProps = dispatch => ({
    updateFLAnnouncement: announcement => dispatch(updateFLAnnouncement(announcement)),
    deleteAnnouncementByFL: (id, functionalLocation) => dispatch(deleteAnnouncementByFL(id, functionalLocation)),
    showModal: (modalType, preConditions, onSubmitAction, passedProps) =>
        dispatch(showModal(modalType, preConditions, onSubmitAction, passedProps)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withTheme(translations(AdminAnnouncemets)));
