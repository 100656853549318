import React from 'react';
import styled from 'styled-components';
import Svg from 'components/Svg/Svg';

const StyledFilterPill = styled.a`
    display: flex;
    align-items: center;
    padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.sm};
    margin: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.xs} 0 0;
    background-color: ${props => props.selected ? props.theme.colors.blue : props.theme.colors.rockBlue};
    border-radius: 0.25em;
    cursor: ${ props => props.onClick ? 'pointer' : 'default'};
`;

const Icon = styled(Svg)`
    fill: ${props => props.theme.colors.white};
    margin-right: ${props => props.theme.spacing.xs};
`;

const Label = styled.span`
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fontSize.xs};
    line-height: 16px;
`;

const FilterPill = props => {
    const { icon, children, onClick, ...other } = props;

    const hasOnClick = onClick ? { onClick: () => onClick(!props.selected) } : {};
    return (
        <StyledFilterPill {...other} { ...hasOnClick}>
            { icon && <Icon name={ icon } /> }
            <Label>{ children }</Label>
        </StyledFilterPill>
    );
};

export default FilterPill;
