import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: ${props => props.theme.spacing.sm} 0 0;
`;

const Button = styled.button`
    background: none;
    border: none;
    color: ${props => props.theme.colors.black};
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.font.size.xxxs};
    font-weight: ${props => props.theme.font.weight.bold};
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: ${props => props.left ? 'left' : 'right'};
    cursor: pointer;
`;

const Title = styled.h4`
    color: ${props => props.theme.colors.rockBlue};
    font-family: ${props => props.theme.font.family.sanchez};
    font-size: ${props => props.theme.font.size.sm};
    font-weight: ${props => props.theme.font.weight.bold};
    cursor: pointer;
    &:hover {
        color: ${props => props.theme.colors.black};
    }
`;

const StatisticsFooter = ({ t, year, setYear }) =>
    <Footer>
        <Button onClick={() => setYear(year - 1) }>← { t('Previous') }</Button>
        <Title>{ year }</Title>
        <Button onClick={() => setYear(year + 1)}>{ t('Next') } →</Button>
    </Footer>;

StatisticsFooter.propTypes = {
    t: PropTypes.func.isRequired,
    year: PropTypes.number.isRequired,
    setYear: PropTypes.func.isRequired,
};

export default StatisticsFooter;
