import { createReducerFromMapping } from 'redux/utils/index.js';
import _ from 'lodash';
import moment from 'moment';
import { IoT } from '@caverion/redux/api/actions';

const initialState = {
    byFL: {},
    observationsByPartner: {},
    inspectionsByPartner: {},
    slaByFL: {},
    loading: false,
    loadingSLA: false,
    observationKPI: {
        loading: false
    },
    inspectionKPI: {
        loading: false
    },
    observationPerformance: {},
    monthlyInspections: {}
};

export const LOAD_FOR_FL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_FOR_FL';
export const LOAD_FOR_FL_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_FOR_FL_SUCCESS';
export const LOAD_FOR_FL_FAIL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_FOR_FL_FAIL';

export const loadFunctionalLocationNotices = (functionalLocation, start, end) => {
    const filter = {
        where: {
            path: {
                'any x': {
                    x: functionalLocation.functionalLocation,
                }
            },
            or: [
                {
                    timestamp: null
                },
                {
                    timestamp: {
                        between: [start.format('YYYY-MM-DDTHH:mm:ss.SSSZ'), end.format('YYYY-MM-DDTHH:mm:ss.SSSZ')]
                    }
                }
            ]
        }
    };

    return async dispatch => {
        dispatch({ type: LOAD_FOR_FL });
        try {
            const result = await dispatch(IoT.notices(JSON.stringify(filter)));

            return dispatch({
                type: LOAD_FOR_FL_SUCCESS,
                functionalLocation: functionalLocation.functionalLocation,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_FOR_FL_FAIL,
                error
            });
        }
    };
};

export const LOAD_OBSERVATIONS_FOR_PARTNER = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATIONS_FOR_PARTNER';
export const LOAD_OBSERVATIONS_FOR_PARTNER_SUCCESS =
    'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATIONS_FOR_PARTNER_SUCCESS';
export const LOAD_OBSERVATIONS_FOR_PARTNER_FAIL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATIONS_FOR_PARTNER_FAIL';

export const loadPartnerObservations = (partnerNumber, baseWhere, start, end) => {
    const filter = {
        where: {
            ...baseWhere,
            type: 'observation',
            or: [
                {
                    timestamp: null
                },
                {
                    timestamp: {
                        between: [
                            start.toISOString(),
                            end.toISOString()
                        ]
                    }
                }
            ]
        }
    };

    const year = end.year();

    return async dispatch => {
        dispatch({ type: LOAD_OBSERVATIONS_FOR_PARTNER, year });
        try {
            const result = await dispatch(IoT.notices(JSON.stringify(filter)));

            return dispatch({
                type: LOAD_OBSERVATIONS_FOR_PARTNER_SUCCESS,
                partnerNumber,
                result,
                year
            });
        } catch (error) {
            return dispatch({
                type: LOAD_OBSERVATIONS_FOR_PARTNER_FAIL,
                error,
                year
            });
        }
    };
};

export const LOAD_INSPECTIONS_FOR_PARTNER = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_INSPECTIONS_FOR_PARTNER';
export const LOAD_INSPECTIONS_FOR_PARTNER_SUCCESS =
    'CUSTOMER_PLATFORM/IoT_Notice/LOAD_INSPECTIONS_FOR_PARTNER_SUCCESS';
export const LOAD_INSPECTIONS_FOR_PARTNER_FAIL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_INSPECTIONS_FOR_PARTNER_FAIL';

export const loadPartnerInspections = (partnerNumber, baseWhere, start, end) => {
    const slaFilter = {
        where: {
            ...baseWhere,
            timestamp: {
                between: [
                    start.toISOString(),
                    end.toISOString()
                ]
            }
        }
    };
    const filter = {
        where: {
            ...slaFilter.where,
            type: { neq: 'observation' }
        }
    };

    return async dispatch => {
        dispatch({ type: LOAD_INSPECTIONS_FOR_PARTNER });
        try {
            const result = await dispatch(IoT.notices(JSON.stringify(filter)));
            const slaResult = await dispatch(IoT.findSLAs(slaFilter));

            return dispatch({
                type: LOAD_INSPECTIONS_FOR_PARTNER_SUCCESS,
                partnerNumber,
                result,
                slaResult: slaResult
            });
        } catch (error) {
            return dispatch({
                type: LOAD_INSPECTIONS_FOR_PARTNER_FAIL,
                error
            });
        }
    };
};

export const LOAD_MONTHLY_INSPECTIONS = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_MONTHLY_INSPECTIONS';
export const LOAD_MONTHLY_INSPECTIONS_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_MONTHLY_INSPECTIONS_SUCCESS';
export const LOAD_MONTHLY_INSPECTIONS_FAIL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_MONTHLY_INSPECTIONS_FAIL';

export const loadMonthlyInspections = (partnerNumber, baseWhere, start, end) => {
    const filter = {
        where: {
            ...baseWhere,
            timestamp: {
                between: [
                    start.toISOString(),
                    end.toISOString()
                ]
            }
        }
    };

    return async dispatch => {
        dispatch({ type: LOAD_MONTHLY_INSPECTIONS });
        try {
            const result = await dispatch(IoT.inspectionCountsByMonth(filter));

            return dispatch({
                type: LOAD_MONTHLY_INSPECTIONS_SUCCESS,
                partnerNumber,
                result,
                year: start.format('YYYY')
            });
        } catch (error) {
            return dispatch({
                type: LOAD_MONTHLY_INSPECTIONS_FAIL,
                error
            });
        }
    };
};

export const LOAD_SLA_FOR_FL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_SLA_FOR_FL';
export const LOAD_SLA_FOR_FL_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_SLA_FOR_FL_SUCCESS';
export const LOAD_SLA_FOR_FL_FAIL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_SLA_FOR_FL_FAIL';

export const loadFunctionalLocationSla = functionalLocation => {
    const filter = {
        where: {
            path: {
                'any x': {
                    x: functionalLocation.functionalLocation,
                }
            }
        }
    };

    return async dispatch => {
        dispatch({ type: LOAD_SLA_FOR_FL });
        try {
            const result = await dispatch(IoT.slas(JSON.stringify(filter)));

            return dispatch({
                type: LOAD_SLA_FOR_FL_SUCCESS,
                functionalLocation: functionalLocation.functionalLocation,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_SLA_FOR_FL_FAIL,
                error
            });
        }
    };
};

export const LOAD_OBSERVATION_KPI = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATION_KPI';
export const LOAD_OBSERVATION_KPI_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATION_KPI_SUCCESS';
export const LOAD_OBSERVATION_KPI_FAIL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATION_KPI_FAIL';

export const loadObservationKpi = (partnerNumber, baseWhere, start, end) => {
    const filter = {
        where: {
            ...baseWhere,
            timestamp: {
                between: [
                    start.toISOString(),
                    end.toISOString()
                ]
            }
        }
    };

    return async dispatch => {
        dispatch({ type: LOAD_OBSERVATION_KPI });
        try {
            const result = await dispatch(IoT.observationKpi(filter));

            return dispatch({
                type: LOAD_OBSERVATION_KPI_SUCCESS,
                partnerNumber,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_OBSERVATION_KPI_FAIL,
                error
            });
        }
    };
};

export const LOAD_INSPECTION_KPI = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_INSPECTION_KPI';
export const LOAD_INSPECTION_KPI_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_INSPECTION_KPI_SUCCESS';
export const LOAD_INSPECTION_KPI_FAIL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_INSPECTION_KPI_FAIL';

export const loadInspectionKpi = (partnerNumber, baseWhere, start, end) => {
    const filter = {
        where: {
            ...baseWhere,
            timestamp: {
                between: [
                    start.toISOString(),
                    end.toISOString()
                ]
            }
        }
    };

    return async dispatch => {
        dispatch({ type: LOAD_INSPECTION_KPI });
        try {
            const result = await dispatch(IoT.inspectionKpi(filter));

            return dispatch({
                type: LOAD_INSPECTION_KPI_SUCCESS,
                partnerNumber,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_INSPECTION_KPI_FAIL,
                error
            });
        }
    };
};

export const LOAD_OBSERVATION_PERFORMANCE = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATION_PERFORMANCE';
export const LOAD_OBSERVATION_PERFORMANCE_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATION_PERFORMANCE_SUCCESS';
export const LOAD_OBSERVATION_PERFORMANCE_FAIL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATION_PERFORMANCE_FAIL';

export const loadObservationPerformance = partnerNumber => async dispatch => {
    dispatch({ type: LOAD_OBSERVATION_PERFORMANCE, partnerNumber });
    try {
        const result = await dispatch(IoT.observationPerformance(partnerNumber));
        dispatch({
            type: LOAD_OBSERVATION_PERFORMANCE_SUCCESS,
            partnerNumber,
            result,
        });
    } catch (error) {
        return dispatch({
            type: LOAD_OBSERVATION_PERFORMANCE_FAIL,
            partnerNumber,
            error,
        });
    }
};

export default createReducerFromMapping({
    [LOAD_FOR_FL]: state => ({
        ...state,
        loading: true
    }),

    [LOAD_SLA_FOR_FL]: state => ({
        ...state,
        loadingSLA: true
    }),

    [LOAD_FOR_FL_SUCCESS]: (state, action) => ({
        ...state,
        loading: false,
        byFL: (data => {
            data[action.functionalLocation] = {
                observations: _.filter(action.result, { type: 'observation' }).map( mapToSortableData ),
                inspections: _.filter(action.result, notice => ['extended', 'limited', 'nightly', 'adhoc']
                    .indexOf(notice.type) !== -1).map( mapToSortableData )
            };

            return data;
        })(_.cloneDeep(state.byFL))
    }),

    [LOAD_SLA_FOR_FL_SUCCESS]: (state, action) => ({
        ...state,
        loadingSLA: false,
        slaByFL: Object.assign({}, state.slaByFL, { [action.functionalLocation]: action.result })
    }),

    [LOAD_SLA_FOR_FL_FAIL]: (state, action) => ({
        ...state,
        loadingSLA: false,
        error: action.error
    }),

    [LOAD_FOR_FL_FAIL]: (state, action) => ({
        ...state,
        loading: false,
        error: action.error
    }),

    [LOAD_OBSERVATION_KPI]: state => ({
        ...state,
        observationKPI: {
            ...state.observationKPI,
            loading: true
        }
    }),

    [LOAD_OBSERVATION_KPI_SUCCESS]: (state, action) => ({
        ...state,
        observationKPI: {
            ...state.observationKPI,
            [action.partnerNumber]: action.result,
            loading: false
        }
    }),

    [LOAD_OBSERVATION_KPI_FAIL]: (state, action) => ({
        ...state,
        observationKPI: {
            ...state.observationKPI,
            loading: false
        },
        error: action.error
    }),

    [LOAD_INSPECTION_KPI]: state => ({
        ...state,
        inspectionKPI: {
            ...state.inspectionKPI,
            loading: true
        }
    }),

    [LOAD_INSPECTION_KPI_SUCCESS]: (state, action) => ({
        ...state,
        inspectionKPI: {
            ...state.inspectionKPI,
            [action.partnerNumber]: action.result,
            loading: false
        }
    }),

    [LOAD_INSPECTION_KPI_FAIL]: (state, action) => ({
        ...state,
        inspectionKPI: {
            ...state.inspectionKPI,
            loading: false
        },
        error: action.error
    }),

    [LOAD_OBSERVATION_PERFORMANCE]: (state, action) => ({
        ...state,
        observationPerformance: {
            ...state.observationPerformance,
            [action.partnerNumber]: {
                loading: true,
            },
        },
    }),
    [LOAD_OBSERVATION_PERFORMANCE_SUCCESS]: (state, action) => ({
        ...state,
        observationPerformance: {
            ...state.observationPerformance,
            [action.partnerNumber]: {
                loading: false,
                data: action.result,
            },
        },
    }),
    [LOAD_OBSERVATION_PERFORMANCE_FAIL]: (state, action) => ({
        ...state,
        observationPerformance: {
            ...state.observationPerformance,
            [action.partnerNumber]: {
                loading: false,
                error: action.error,
            },
        },
    }),

    [LOAD_OBSERVATIONS_FOR_PARTNER]: (state, action) => ({
        ...state,
        observationsByPartner: {
            ...state.observationsByPartner,
            [action.partnerNumber]: {
                ...state.observationsByPartner[action.partnerNumber],
                [action.year]: { loading: true }
            }
        }
    }),

    [LOAD_OBSERVATIONS_FOR_PARTNER_SUCCESS]: (state, action) => ({
        ...state,
        observationsByPartner: {
            ...state.observationsByPartner,
            [action.partnerNumber]: {
                ...state.observationsByPartner[action.partnerNumber],
                current: action.result,
                [action.year]: {
                    data: _.filter(action.result, observation =>
                        moment.utc(observation.timestamp).year() === action.year),
                    loading: false
                }
            }
        }
    }),

    [LOAD_OBSERVATIONS_FOR_PARTNER_FAIL]: (state, action) => ({
        ...state,
        observationsByPartner: {
            ...state.observationsByPartner,
            [action.partnerNumber]: {
                ...state.observationsByPartner[action.partnerNumber],
                [action.year]: { loading: false, error: action.error }
            }
        },
        error: action.error
    }),

    [LOAD_INSPECTIONS_FOR_PARTNER]: (state, action) => ({
        ...state,
        inspectionsByPartner: {
            ...state.inspectionsByPartner,
            [action.partnerNumber]: { loading: true }
        }
    }),

    [LOAD_INSPECTIONS_FOR_PARTNER_SUCCESS]: (state, action) => ({
        ...state,
        inspectionsByPartner: {
            ...state.inspectionsByPartner,
            [action.partnerNumber]: { loading: false, data: action.result, slaData: action.slaResult }
        }
    }),

    [LOAD_INSPECTIONS_FOR_PARTNER_FAIL]: (state, action) => ({
        ...state,
        inspectionsByPartner: {
            ...state.inspectionsByPartner,
            [action.partnerNumber]: { loading: false, error: action.error }
        },
        error: action.error
    }),

    [LOAD_MONTHLY_INSPECTIONS]: (state, action) => ({
        ...state,
        monthlyInspections: {
            ...state.monthlyInspections,
            [action.partnerNumber]: { loading: true }
        }
    }),

    [LOAD_MONTHLY_INSPECTIONS_SUCCESS]: (state, action) => ({
        ...state,
        monthlyInspections: {
            ...state.monthlyInspections,
            [action.partnerNumber]: { loading: false, [action.year]: action.result }
        }
    }),

    [LOAD_MONTHLY_INSPECTIONS_FAIL]: (state, action) => ({
        ...state,
        monthlyInspections: {
            ...state.monthlyInspections,
            [action.partnerNumber]: { loading: false, error: action.error }
        },
        error: action.error
    }),
}, initialState);

// Mapping used to make it work with sortable table -component
const mapToSortableData = row => {
    const consumptions = [];
    if (row.effectHeat) {
        consumptions.push({
            title: 'heating',
            value: row.effectHeatValue,
            unit: row.effectHeatingValueUnit
        });
    }
    if (row.effectWater) {
        consumptions.push({
            title: 'water',
            value: row.effectWaterValue,
            unit: row.effectWaterValueUnit
        });
    }
    if (row.effectElectricity) {
        consumptions.push({
            title: 'electricity',
            value: row.effectElectricityValue,
            unit: row.effectElectricityValueUnit
        });
    }
    return {
        ...row,
        location: {
            value: row.device,
            description: row.description,
            consumptions
        },
        date: {
            date: moment.utc(row.timestamp).local(),
            value: moment.utc(row.timestamp).local()
        },
        status: {
            value: statusMapper(row.status),
            status: row.status,
            title: row.status
        },
        system: {
            value: row.deviceGroup || 'other',
            icon: row.deviceGroup ? row.deviceGroup.charAt(0).toUpperCase() + row.deviceGroup.slice(1) : 'Other'
        },
    };
};

const statusMapper = status => {
    switch (status) {
    case 'observed':
        return 1;
    case 'offered':
        return 1;
    case 'processed':
        return 2;
    case 'started':
        return 2;
    case 'completed':
        return 3;
    default:
        return 1;
    }
};
