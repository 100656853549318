import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

const Container = styled.div`
    white-space: pre-line;
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.font.size.sm};
    color: ${props => props.theme.colors.black};

    p, ul, ol {
        margin: ${props => props.theme.spacing.xs} 0;
    }

    ul {
        list-style: inside disc;
    }

    ol {
        list-style: inside decimal;
    }
`;
Container.displayName = 'Container';

export const MarkdownContent = ({ content, className }) =>
    <Container className={ className }>
        <ReactMarkdown source={ content } linkTarget="_blank" />
    </Container>;

MarkdownContent.displayName = 'MarkdownContent';

MarkdownContent.propTypes = {
    content: PropTypes.string.isRequired,
    className: PropTypes.string
};

export default MarkdownContent;
