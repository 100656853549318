import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { each, cloneDeep, setWith, omit, map, omitBy, isEmpty } from 'lodash';

import StandardPage from 'components/StandardPage/StandardPage';
import translations from 'decorators/Translations/translations';
import { SensorHierarchyForm } from './SensorHierarchyForm';
import { SensorForm } from './SensorForm';
import {
    loadSensorDataTypes,
    upsertSensorHierarchy,
    loadBuildingLevelSensorHierarchiesByFL
} from 'redux/modules/customer/sensorHierarchy';
import { ErrorText } from 'components/index.js';
import { upsertSensor } from 'redux/modules/customer/sensorHierarchy';
import { upsertAllSensorMeta } from 'redux/modules/customer/sensorMeta';
import PartnerRedirect from '../../PartnerRedirect/PartnerRedirect';
import InputRow from 'components/Form/InputRow';

export const metaKey = 'metaKey';
export const metaValue = 'value';

const messageStyles = {
    textAlign: 'center',
    marginTop: '1rem'
};

export const InputRowButton = styled(InputRow)`
    display: flex;
    align-items: flex-end;
`;

const MessageContainer = styled.div`
    width: 800px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: auto;
`;
const Message = ({ messageObj: { message, isError } }) => {
    if (isError) {
        return <ErrorText style={messageStyles} >{message}</ErrorText>;
    }
    return <p style={messageStyles}>{message}</p>;
};

export const FormContainer = styled.div`
    display: flex;
    flex-flow: column wrap;
    width: 800px;
    margin: auto;

    h3 {
        margin-top: 2rem;
    }

    button {
        margin-left: auto;
    }
`;

class SensorMetaDataMapping extends Component {

    constructor(props) {
        super(props);
        this.state = {
            messageList: [],
            sensorHierarchy: {},
            sensor: { meta: [], addMeta: false },
            submitSensorLoadingStatus: false,
            submitSensorHierarchyLoadingStatus: false
        };
        this.sensorTypeOptions = [];
        this.hierarchyTypeOptions = [
            { label: 'Building', value: 'building' },
            { label: 'Group', value: 'group' },
            { label: 'Area', value: 'area' },
            { label: 'Floor', value: 'floor' },
        ];
    }

    addMessage = (message, isError = false) => {
        const { messageList } = this.state;
        messageList.push({
            isError,
            message
        });
        this.setState({ messageList: [].concat(...this.state.messageList) });
    };

    handleSensorHierarchyFormChange = (property, value) => {
        this.setState({
            sensorHierarchy: {
                ...this.state.sensorHierarchy,
                [property]: value
            }
        });
    };

    handleSubmitSensorHierarchy = () => {
        const { sensorHierarchy } = this.state;
        const { loadBuildingLevelSensorHierarchiesByFL, upsertSensorHierarchy } = this.props;
        this.setState({ submitSensorHierarchyLoadingStatus: true }, async () => {
            const buildingLevelSensorHierarchy = sensorHierarchy.functionalLocation &&
                await loadBuildingLevelSensorHierarchiesByFL(sensorHierarchy.functionalLocation).then(res => res);
            if (buildingLevelSensorHierarchy
                && buildingLevelSensorHierarchy.length > 0
                && sensorHierarchy.type === 'building') {
                this.addMessage(`Building level sensorhierarchy already exists with id: ${buildingLevelSensorHierarchy[0].id}, cannot be saved!`, true);
                this.setState({ addedSensorHierarchyId: null });
            }
            else {
                const finalizedSensorHierarchy = Object.assign({}, omitBy(sensorHierarchy, isEmpty));
                upsertSensorHierarchy(finalizedSensorHierarchy)
                    .then(({ result }) => {
                        this.addMessage(`SensorHierachy saved! with id: ${result.id}`);
                        this.handleSensorFormChange(['sensorHierarchyId'], result.id);
                        this.handleSensorFormChange(['functionalLocation'], result.functionalLocation);
                    })
                    .catch(err => this.addMessage(err.message, true));
            }
            this.setState({ submitSensorHierarchyLoadingStatus: false });
        });
    };

    handleSensorFormChange = (property, value) => {
        this.setState({
            sensor: {
                ...this.state.sensor,
                [property]: value
            }
        });
    };

    handleSubmitSensor = () => {
        const { upsertSensor, upsertAllSensorMeta } = this.props;
        const { sensor: { addMeta, meta } } = this.state;
        const addSensorMeta = addMeta && meta && meta.length > 0;
        let sensor = Object.assign({}, this.state.sensor);
        sensor = omit(sensor, ['addMeta', 'meta']);

        this.setState({ submitSensorLoadingStatus: true }, async () =>{
            const sensorId = await upsertSensor(sensor)
                .then(({ result }) => {
                    this.addMessage(`Sensor saved with id: ${result.id}`);
                    return result.id;
                })
                .catch(err => this.addMessage(err.message, true));

            if (addSensorMeta && sensorId) {
                each(meta, sm => { sm.sensorId = sensorId; });
                upsertAllSensorMeta(meta)
                    .then(({ result }) => {
                        const ids = map(result, sm => sm.id);
                        this.addMessage(`sensorMeta ids:(${ids.join()}) added for sensor id:(${sensorId})`);
                    })
                    .catch(err => this.addMessage(err.message, true));
            }
            this.setState({ submitSensorLoadingStatus: false });
        });
    };

    handleMetaAdd = e => {
        e.preventDefault();
        const meta = cloneDeep(this.state.sensor.meta);

        meta.push({
            [metaKey]: '',
            [metaValue]: '',
        });
        this.setState({
            sensor: {
                ...this.state.sensor,
                meta
            }
        });
    };

    handleMetaChange = (property, value) => {
        const meta = cloneDeep(this.state.sensor.meta);

        const newMeta = setWith({ meta }, property, value, Object);
        this.setState({
            sensor: {
                ...this.state.sensor,
                meta: newMeta.meta
            }
        });
    };

    handleMetaRemove = (e, index) => {
        e.preventDefault();
        const meta = cloneDeep(this.state.sensor.meta);
        const metaItem = meta[index];

        if (metaItem) {
            meta.splice(index, 1);
        }

        this.setState({
            sensor: {
                ...this.state.sensor,
                meta
            }
        });
    };

    constructSensorTypeOptions = () => {
        const { sensorTypes } = this.props;
        each(sensorTypes, sensorType => this.sensorTypeOptions.push({ label: sensorType.name, value: sensorType.id }));
    }

    loadData = async () => {
        await this.props.loadSensorTypes();
        this.constructSensorTypeOptions();
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        const {
            sensorHierarchy,
            sensor,
            messageList,
            submitSensorHierarchyLoadingStatus,
            submitSensorLoadingStatus
        } = this.state;
        const { t, profile } = this.props;
        if (profile.role !== 'caverion-admin') {
            return <PartnerRedirect />;
        }
        return (
            <StandardPage noMargin>
                <SensorHierarchyForm
                    model={sensorHierarchy}
                    t={t}
                    handleSubmit={this.handleSubmitSensorHierarchy}
                    handleOnChange={this.handleSensorHierarchyFormChange}
                    hierarchyTypeOptions={this.hierarchyTypeOptions}
                    loading={submitSensorHierarchyLoadingStatus}
                />
                <SensorForm
                    model={sensor}
                    t={t}
                    handleSubmit={this.handleSubmitSensor}
                    handleOnChange={this.handleSensorFormChange}
                    sensorTypeOptions={this.sensorTypeOptions}
                    addMeta={sensor.addMeta}
                    handleSensorMetaAdd={this.handleMetaAdd}
                    handleMetaChange={this.handleMetaChange}
                    handleMetaRemove={this.handleMetaRemove}
                    loading={submitSensorLoadingStatus}
                />
                { messageList && messageList.length > 0 &&
                    <MessageContainer >
                        {map(messageList, (message, index) => <Message key={index} messageObj={message} />)}
                    </MessageContainer> }
            </StandardPage>
        );
    }
}

const mapStateToProps = state => ({
    sensorTypes: state.sensorHierarchy.sensorDataTypes,
    buildingLevelSensorHierarchies: state.sensorHierarchy.buildingLevelSensorHierarchies,
    profile: state.profile.profile
});

const mapDispatchToProps = dispatch => ({
    loadSensorTypes: () => dispatch(loadSensorDataTypes()),
    loadBuildingLevelSensorHierarchiesByFL: functionalLocation =>
        dispatch(loadBuildingLevelSensorHierarchiesByFL(functionalLocation)),
    upsertSensorHierarchy: data => dispatch(upsertSensorHierarchy(data)),
    upsertSensor: sensor => dispatch(upsertSensor(sensor)),
    upsertAllSensorMeta: sensorMeta => dispatch(upsertAllSensorMeta(sensorMeta))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(SensorMetaDataMapping));
