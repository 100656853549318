import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { isEqual, setWith, cloneDeep, keys, reject } from 'lodash';
import memoizeOne from 'memoize-one';
import styled, { withTheme } from 'styled-components';

import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import translations from 'decorators/Translations/translations';
import MultiInputRow from 'components/Form/MultiInputRow';
import SnackBar from 'components/SnackBar/SnackBar';
import Button from 'components/Button/Button';
import { MODALTYPE } from 'components/Modal/ModalTypes';
import Loader from 'components/Loader/Loader';
import {
    setPartnerMeta,
    savePartnerMeta,
    showModal,
    deletePartnerMeta,
} from 'redux/modules';
import FadeTransition from 'components/FadeTransition/FadeTransition';
import { PARTNER_META_KEYS } from 'constants/meta';

const KEY_FIELD = 'key';
const VALUE_FIELD = 'value';

const isChanged = memoizeOne((original, meta) => !isEqual(original, meta));

const LoadingIndicator = styled.div`
    display: flex;
    height: 250px;
    justify-content: center;
    align-items: center;
`;

class AdminPartnerConfig extends React.Component {
    handleMetaChange = (property, value) => {
        const { meta: { meta }, partnerNumber, setPartnerMeta } = this.props;
        const nextMeta = setWith({ meta: cloneDeep(meta) }, property, value, Object).meta;
        setPartnerMeta(partnerNumber, { meta: nextMeta });
    };

    handleMetaAdd = () => {
        const { meta, partnerNumber, setPartnerMeta } = this.props;
        setPartnerMeta(partnerNumber, {
            meta: [
                ...meta.meta,
                {
                    partnerNumber,
                    [KEY_FIELD]: '',
                    [VALUE_FIELD]: '',
                }
            ],
        });
    };

    handleMetaRemove = (event, index) => {
        this.props.showModal(MODALTYPE.CONFIRMATION_DELETE_PARTNER_META, null, () => this.deleteMeta(index), null);
    };

    deleteMeta = async index => {
        this.props.deletePartnerMeta(this.props.partnerNumber, index);
    };

    trimMeta = meta => reject(meta, { [KEY_FIELD]: '' });

    handleSubmit = async () => {
        const { meta, partnerNumber, savePartnerMeta } = this.props;
        savePartnerMeta(partnerNumber, this.trimMeta(meta.meta));
    };

    handleDismissError = () => {
        const { partnerNumber, setPartnerMeta } = this.props;
        setPartnerMeta(partnerNumber, {
            error: undefined,
            saved: false,
        });
    }

    createMetaOptions = () => {
        return {
            [KEY_FIELD]: keys(PARTNER_META_KEYS),
            [VALUE_FIELD]: PARTNER_META_KEYS
        };
    }

    render() {
        const { t, meta, theme } = this.props;
        if (!meta) {
            return null;
        }

        const changed = isChanged(meta.original, meta.meta);
        return (
            <FadeTransition>
                <React.Fragment>
                    <Helmet title={ t('Configuration') } />
                    <Section>
                        <SectionHeader
                            t={ t }
                            title={ t('Configuration') }
                        />
                        {meta.loading ?
                            <LoadingIndicator><Loader /></LoadingIndicator> :
                            <MultiInputRow
                                t={ t }
                                model={ meta }
                                baseProperty="meta"
                                subProperties={ [KEY_FIELD, VALUE_FIELD] }
                                subPropertyTitles={ {
                                    [KEY_FIELD]: t('Key'),
                                    [VALUE_FIELD]: t('Value'),
                                } }
                                onPropertyChange={ this.handleMetaChange }
                                handleAdd={ this.handleMetaAdd }
                                handleRemove={ this.handleMetaRemove }
                                options={ this.createMetaOptions() }
                                optionsKey={ KEY_FIELD }
                            />
                        }
                    </Section>
                    <SnackBar
                        visible={ changed }
                        variant="confirmation"
                        hideDelay={ 1000 }
                        primaryContent={ <div>
                            { !meta.saving && !meta.saved && t('Save your changes') }
                            { meta.saving && `${t('Saving')}...` }
                            { meta.saved && !meta.error && t('Meta data successfully saved') }
                        </div>}
                        secondaryContent={
                            <Button
                                submit
                                onClick={ this.handleSubmit }
                                loading={ meta.saving }
                            >
                                { !meta.saving && !meta.saved ? t('Save') : t('Saved') }
                            </Button>
                        }
                    />
                    <SnackBar
                        variant="error"
                        visible={ !!meta.error }
                        secondaryContent={
                            <Button
                                color={theme.button.colors.clear}
                                textColor={theme.colors.midnight}
                                onClick={this.handleDismissError}
                            >
                                OK
                            </Button>
                        }
                    >
                        { t('Failed to save configuration.') }
                    </SnackBar>
                </React.Fragment>
            </FadeTransition>
        );
    }
}

AdminPartnerConfig.propTypes = {
    t: PropTypes.func.isRequired,
    partnerNumber: PropTypes.string.isRequired,
    meta: PropTypes.shape({
        loading: PropTypes.bool,
        saving: PropTypes.bool,
        saved: PropTypes.bool,
        meta: PropTypes.arrayOf(PropTypes.object),
    }),
    setPartnerMeta: PropTypes.func.isRequired,
    savePartnerMeta: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    deletePartnerMeta: PropTypes.func.isRequired,
    theme: PropTypes.object
};

const mapDispatchToProps = {
    setPartnerMeta,
    savePartnerMeta,
    showModal,
    deletePartnerMeta,
};

export default withTheme(translations(connect(undefined, mapDispatchToProps)(AdminPartnerConfig)));
