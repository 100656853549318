import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import translations from 'decorators/Translations/translations';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { MenuToggle } from 'components/index.js';
import { MENU_CLOSED, setMenuStatus } from 'redux/modules/index.js';
import { toggleAnnouncementMenu } from 'redux/modules/announcement/announcement';
import { toggleFeedbackPanel } from 'redux/modules/feedback/feedback';
import { NavigationLinks } from './NavigationLinks';
import { AnnouncementLink } from './AnnouncementLink';
import { getNavigationItems } from './NavigationItems';
import { LoggedInUser } from './LoggedInUser';
import { StyledOverlay } from 'components/ContextualHelp/ContextualHelp';
import { ANNOUNCEMENT_TYPE, announcementShape, getAnnouncements } from 'components/Announcement/utils';
import { FeedbackLink } from './FeedbackLink';

const StyledNavigation = styled.nav`
    font-family: ${props => props.theme.font.family.arial};
    background-color: ${props => props.theme.colors.white};
    position: fixed;
    z-index: ${props => props.theme.zIndex('menu')};
    width: 24em;
    max-width: 100%;
    top: ${props => props.theme.navigation.height};
    left: auto;
    right: 0;
    bottom: 0;
    height: auto;
    border-left: 1px solid ${props => props.theme.colors.lightGray};
    transform: translateX(${props => props.open ? '0' : '100%'});
    text-align: left;
    transition: transform 200ms ease-in, top 0.1s ease-in;
    overflow-y: auto;
    overflow-x: hidden;
`;

StyledNavigation.displayName = 'StyledNavigation';

const NaviBlue = styled.div`
    display: ${props => props.open ? 'flex' : 'none'};
    justify-content: flex-end;
    align-items: center;
    z-index: ${props => props.theme.zIndex('menu')};
    position: fixed;
    top: 0;
    right: 0;
    background-color: ${props => props.theme.colors.midnight};
    height: ${props => props.theme.navigation.height};
    max-width: 24em;
    width: ${props => props.open ? 'calc(100%)' : '0'};
    ${props => props.theme.media.portrait`
        width: ${props => props.open ? '24em' : '0'};
    `};
    transition: width 150ms ease-in, height 0.5s ease-in-out;
`;

NaviBlue.displayName = 'NaviBlue';

const StyledBackgroundOverlay = styled(StyledOverlay)`
    z-index: ${props => props.theme.zIndex('header') + 1};
`;

StyledBackgroundOverlay.displayName = 'StyledBackgroundOverlay';

export class Navigation extends PureComponent {

    static propTypes = {
        menuOpen: PropTypes.bool.isRequired,
        t: PropTypes.func.isRequired,
        profile: PropTypes.shape({
            features: PropTypes.object.isRequired,
            role: PropTypes.string.isRequired,
            username: PropTypes.string.isRequired
        }).isRequired,
        activePartner: PropTypes.string.isRequired,
        setMenuStatus: PropTypes.func.isRequired,
        toggleAnnouncementMenu: PropTypes.func.isRequired,
        generalAnnouncements: PropTypes.arrayOf(
            PropTypes.oneOfType([
                announcementShape(ANNOUNCEMENT_TYPE.PARTNER),
                announcementShape(ANNOUNCEMENT_TYPE.SYSTEM)
            ])
        ).isRequired
    };

    render() {
        const {
            menuOpen,
            t,
            profile,
            profile: { features, role, username },
            generalAnnouncements,
            personalAnnouncements,
            activePartner,
            setMenuStatus,
            toggleAnnouncementMenu,
            toggleFeedbackPanel
        } = this.props;

        const { unread } = getAnnouncements(profile, generalAnnouncements, personalAnnouncements, activePartner);
        const closeMenu = () => setMenuStatus(MENU_CLOSED);
        const naviItems = getNavigationItems(activePartner, features, role, t);
        const announcementClick = () => {
            closeMenu();
            toggleAnnouncementMenu();
        };
        const feedbackClick = () => {
            closeMenu();
            toggleFeedbackPanel();
        };

        return (
            <div>
                <StyledBackgroundOverlay active={ menuOpen } onClick={ closeMenu } />
                <NaviBlue open={ menuOpen }>
                    <MenuToggle toggleMenu={ setMenuStatus } menuOpen={ menuOpen }/>
                </NaviBlue>
                <StyledNavigation data-test-id='MenuBar' open={ menuOpen }>
                    <AnnouncementLink onClick={ announcementClick } t={ t } unread={ unread.length } />
                    <FeedbackLink onClick={ feedbackClick } t={ t } />
                    <NavigationLinks items={ naviItems } onClick={ closeMenu }/>
                    <LoggedInUser username={ username }/>
                </StyledNavigation>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    menuOpen: state.navigation.menuOpen,
    profile: state.profile.profile,
    activePartner: state.profile.activePartner,
    generalAnnouncements: state.announcement.general,
    personalAnnouncements: state.announcement.personal
});

const mapDispatchToProps = {
    setMenuStatus,
    toggleAnnouncementMenu,
    toggleFeedbackPanel
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(translations(Navigation)));
