import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { Switch, Route, Redirect } from 'react-router';
import queryString from 'query-string';
import moment from 'moment';
import { omit, find, map, includes } from 'lodash';
import memoizeOne from 'memoize-one';
import { connect } from 'react-redux';

import StandardPage from 'components/StandardPage/StandardPage';
import SectionHeader from 'components/Section/SectionHeader';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import Header from 'containers/Application/Header/Header';
import translations from 'decorators/Translations/translations';
import ServiceOrders from './tabs/ServiceOrders/ServiceOrders';
import PlannedMaintenance from './tabs/PlannedMaintenance/PlannedMaintenance';
import Search from './tabs/Search/Search';
import * as utils from './utils';
import { getPartnerNumbers } from 'utils/profile';
import { ViewMode } from 'constants/serviceCalendar';
import ViewModeButton from 'components/Button/ViewModeButton';
import PlainSection from 'components/Section/PlainSection';
import ServiceOrder from 'containers/Application/ServiceOrder/ServiceOrder';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage';
import { loadPartnerMeta } from 'redux/modules/index.js';

const StyledSectionHeader = styled(SectionHeader)`
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 0;

    ${props => props.theme.media.portrait`
        align-items: flex-end;
    `}

    ${props => props.theme.media.landscape`
        flex-direction: row;
        align-items: center;
        padding-bottom: ${props => props.theme.spacing.md};
    `}
`;

const Content = styled.div`
    width: 100%;
    max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});
    margin: 0 auto;
`;

const ViewModeButtons = styled.div`
    display: flex;
    margin: ${props => props.theme.spacing.sm} 0;

    ${props => props.theme.media.portrait`
        margin-top: ${props => props.renderContainer ? 0 : props.theme.spacing.sm};
    `}

    ${props => props.theme.media.landscape`
        margin-top: 0;
        margin-bottom: -${props => props.theme.spacing.sm};
    `}
`;

const hideServiceOrder = memoizeOne((location, query, history) => () => {
    const { pathname } = location;
    const nextQuery = omit(query, ['orderId', 'orderType', 'functionalLocationId', 'partnerNumber']);
    history.push(`${pathname}?${queryString.stringify(nextQuery)}`);
});

export const ServiceCalendar = ({ t, match, history, location, renderContainer, profile, loadPartnerMeta,
    partnerMeta, ...otherProps }) => {
    const { path, url, params } = match;

    const partnerNumber = otherProps.partnerNumber || params.partnerNumber;

    useEffect(() => {
        if (partnerNumber && partnerNumber !== 'all') {
            loadPartnerMeta(partnerNumber);
        }
    }, [partnerNumber]);

    const query = utils.parseQuery(location.search);
    const functionalLocationId = query.functionalLocationId;

    if (!query.year) {
        const nextQuery = queryString.stringify({
            ...query,
            year: moment().local().year(),
        });

        history.replace(`${location.pathname}?${nextQuery}`);
        return null;
    }

    const tabOptions = [
        { value: 'ServiceOrders', label: t('Service Orders') },
        { value: 'PlannedMaintenance', label: t('Planned Maintenance') },
        { value: 'Search', label: t('Search') },
    ];

    const selectedTab = find(
        map(tabOptions, 'value'),
        tabValue => location.pathname.endsWith(tabValue)
    );

    const handleTabClick = tab => {
        const nextQuery = queryString.stringify({ ...omit(query, ['months', 'day', 'page']) });
        history.push(`${url}/${tab}?${nextQuery}`);
    };

    const setViewMode = mode => {
        const nextQuery = queryString.stringify({
            ...omit(query, ['months', 'day']),
            mode,
        });

        history.push(`${location.pathname}?${nextQuery}`);
    };

    const toggleShowFiltering = () =>
        history.push(`${location.pathname}?${queryString.stringify({ ...query, showFiltering: query.showFiltering !== 'true' })}`);

    const content = <Content>
        <PlainSection>
            <StyledSectionHeader t={ t }>
                <SectionTabSelector
                    left
                    large
                    noMobilePadding
                    t={ t }
                    options={ tabOptions }
                    model={{ selectedTab }}
                    property="selectedTab"
                    onTabChange={ (property, value) => handleTabClick(value) }
                />
                <ViewModeButtons renderContainer={ renderContainer }>
                    { selectedTab !== tabOptions[2].value &&
                        <ViewModeButton
                            isFilter
                            data-test-id="FilterLink"
                            iconName="control-room"
                            onClick={ toggleShowFiltering }
                            active={ query.showFiltering === 'true' }
                            text={ t('Show filters') }
                        />
                    }
                    <ViewModeButton
                        data-test-id="CalendarLink"
                        iconName="calendar"
                        onClick={ () => setViewMode(ViewMode.CALENDAR) }
                        active={ query.mode === ViewMode.CALENDAR }
                    />
                    <ViewModeButton
                        data-test-id="CalendarListLink"
                        iconName="calendar-list"
                        onClick={ () => setViewMode(ViewMode.LIST) }
                        active={ query.mode !== ViewMode.CALENDAR }
                    />
                </ViewModeButtons>
            </StyledSectionHeader>
        </PlainSection>
        <Switch>
            <Route path={ `${path}/ServiceOrders` } component={ ServiceOrders } />
            <Route path={ `${path}/PlannedMaintenance` } component={ PlannedMaintenance } />
            <Route path={ `${path}/Search` } component={ Search } />
            <Redirect to={ `${url}/ServiceOrders${location.search}` } />
        </Switch>
        { query.orderId && query.orderType &&
            <ServiceOrder
                orderId={ query.orderId }
                orderType={ query.orderType }
                functionalLocationId={ functionalLocationId }
                partnerNumber={ query.partnerNumber }
                hide={ hideServiceOrder(location, query, history) }
            />
        }
    </Content>;

    if (!partnerNumber || partnerNumber !== 'all' && !includes(getPartnerNumbers(profile), partnerNumber)) {
        return <ErrorPage type="partner" embed={ !renderContainer } />;
    }

    if (functionalLocationId && partnerNumber === 'all') {
        return <ErrorPage type="selectPartner" embed={ !renderContainer } />;
    }

    if (!renderContainer) {
        return content;
    }

    return (
        <StandardPage withTabs noMargin={false} scrollToTopOnMount>
            <Helmet title={ t('Service Calendar') } />
            <Header t={ t } selected="service calendar" showPartnerSelect />
            { content }
        </StandardPage>
    );
};

ServiceCalendar.propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    renderContainer: PropTypes.bool,
};

ServiceCalendar.defaultProps = {
    renderContainer: true,
};

const mapStateToProps = state => ({
    profile: state.profile.profile,
    partnerMeta: state.partnerMeta.meta,
});

const mapDispatchToProps = {
    loadPartnerMeta
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(ServiceCalendar));
