import React from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import memoizeOne from 'memoize-one';

import { OrderType } from 'constants/serviceCalendar';
import LinkArrow, { direction, size } from 'components/LinkArrow/LinkArrow.jsx';

const getQuery = memoizeOne(search => queryString.parse(search));

const StyledLinkColumn = styled.div`
    font-size: ${props => props.theme.fontSize.xxs};
    font-weight: ${props => props.theme.fontWeight.bold};
`;

const LinkColumn = props => {
    const { data } = props;
    const { meta, orderType, serviceOrderNumber, id, functionalLocation } = data;
    const { pathname, search } = window.location;

    const orderId = orderType === OrderType.PLANNED ? id : serviceOrderNumber;
    const query = queryString.stringify({
        ...getQuery(search),
        orderId,
        functionalLocationId: functionalLocation,
        orderType,
        partnerNumber: meta.authorizedPartnerNumber,
    });

    const serviceOrderLink = !meta.noPermissions &&
        <LinkArrow
            to={ `${pathname}?${query}` }
            direction={ direction.RIGHT }
            size={ size.NORMAL }
            data-test-id="ServiceOrderLink"
        />;

    return (
        <StyledLinkColumn>
            { serviceOrderLink }
        </StyledLinkColumn>
    );
};

export default LinkColumn;
