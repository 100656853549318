import React, { PureComponent } from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

class QueryLink extends PureComponent {
    render() {
        const { location, ...props } = this.props;

        // Clean unused props from withRouter since they cause warnings
        const linkProps = Object.assign({}, props);
        delete linkProps.history;
        delete linkProps.match;
        delete linkProps.staticContext;

        return (
            <Link { ...linkProps } to={{
                ...props.to,
                search: queryString.stringify(
                    Object.assign({}, queryString.parse(location.search), props.to.query)
                )
            }} />
        );

    }
}

export default withRouter(QueryLink);
