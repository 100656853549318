import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const withProgress = WrappedComponent => {
    const ProgressComponent = ({ loading, full, initStep, ...other }) => {
        const [progress, setProgress] = useState(0);
        const intervalRef = useRef(null);
        const timeoutRef = useRef(null);

        useEffect(
            () => {
                if (loading && !full) {
                    /**
                     * From: https://medium.com/stormix/fake-progress-bars-and-maths-80a20dc2801d
                     */
                    let currentProgress = 0,
                        step = initStep; // the smaller this is the slower the progress bar

                    intervalRef.current = setInterval(() => {
                        currentProgress += step;
                        const calculatedProgress =
                            Math.round((Math.atan(currentProgress) / (Math.PI / 2)) * 100 * 1000) /
                            1000;

                        intervalRef.current && setProgress(calculatedProgress);

                        if (calculatedProgress >= 100) {
                            clearInterval(intervalRef.current);
                        } else if (calculatedProgress >= 70) {
                            step = 0.1;
                        }
                    }, 100);
                } else {
                    clearInterval(intervalRef.current);
                    timeoutRef.current = setTimeout(() => setProgress(0), 100);
                }

                return () => {
                    clearInterval(intervalRef.current);
                    clearTimeout(timeoutRef.current);
                };
            },
            [loading, full]
        );

        return <WrappedComponent progress={full ? 100 : progress} {...other} />;
    };

    ProgressComponent.defaultProps = {
        full: false,
        initStep: 0.15,
        loading: false
    };

    ProgressComponent.propTypes = {
        full: PropTypes.bool,
        /**
         * initStep affects the speed of progress
         * Hints for appropriate values:
         * fast: 0.3
         * default: 0.15
         * slow: 0.01
         * super slow: 0.001
         */
        initStep: PropTypes.number,
        loading: PropTypes.bool
    };

    return ProgressComponent;
};

export default withProgress;
