import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import _ from 'lodash';

import SortableTree, { changeNodeAtPath } from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import Section from 'components/Section/Section';
import ScrollToComponent from 'components/ScrollToComponent/ScrollToComponent';
import ButtonDropdown from 'components/Button/ButtonDropdown';
import { getFloorFeatures } from 'utils/Data/sensorHierarchy';

const treeGroups = ['Areas', 'Sensors'];

const StyledSection = styled.div `
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
    h3, h4 {
        min-width: 120px;
    }
`;

const TreeContainer = styled.section`
    display: flex;
    flex-flow: row nowrap;
`;

const Tree = styled.div`
    width: 100%;
    overflow: auto;
    ${props => props.theme.media.landscape`
        width: 50%;
        overflow: visible;
    `}
`;

const treeNodeHeight = 40;

const TreeMenuContainer = styled.div`
    width: ${treeNodeHeight}px;
`;

const TreeMenuWrapper = styled.div`
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    width: ${treeNodeHeight}px;
`;

const TreeMenuToggle = styled.a`
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: ${props => props.theme.colors.white};
    border-left: 1px solid ${props => props.theme.colors.lightGray};
    z-index: 2;

    &:hover {
        background: ${props => props.theme.colors.mystic};
    }

    &::after {
        content: '···';
        display: block;
        font-size: 1.35rem;
        letter-spacing: -0.05em;
        line-height: 1;
        vertical-align: middle;
        color: ${props => props.theme.colors.black};
    }
`;

const TreeMenuOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: transparent;
    pointer-events: ${props => props.active ? 'auto' : 'none'};
`;

const TreeMenu = styled.div`
    display: ${props => props.isOpen ? 'flex' : 'none'};
    flex-flow: column nowrap;
    position: absolute;
    left: 50%;
    bottom: 0;
    min-width: 10em;
    transform: translate(-50%, 100%);
    z-index: 5;

    /* Triangles */
    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        border-style: solid;
        border-width: 0 10px 10px;
        border-color: transparent;
    }

    &::before {
        top: -10px;
        border-bottom-color: ${props => props.theme.colors.lightGray};
        z-index: 6;
    }

    &::after {
        top: -9px;
        border-bottom-color: ${props => props.theme.colors.white};
        z-index: 7;
    }
`;

const TreeMenuBackground = styled.div`
    border-radius: 0.25rem;
    box-shadow: ${props => props.theme.shadows.default};
    border: 1px solid ${props => props.theme.colors.lightGray};
    background: ${props => props.theme.colors.white};
    overflow: hidden;
`;

const TreeMenuButton = styled.button`
    display: block;
    border: 0;
    padding: 0.5em;
    margin: 0;
    text-align: center;
    width: 100%;
    font-size: ${props => props.theme.fontSize.xs};
    color: ${props => props.isDeleteButton ? props.theme.colors.radicalRed : props.theme.colors.black};
    background: ${props => props.theme.colors.white};
    cursor: pointer;
    ${props => props.isDeleteButton && css`
        border-top: 1px solid ${props => props.theme.colors.lightGray};
    `}

    &:hover {
        background: ${props => props.theme.colors.mystic};
    }

    &:disabled {
        color: ${props => props.theme.colors.silver};
        cursor: not-allowed;

        &:hover {
            background: transparent;
        }
    }

    &:focus,
    &:active {
        box-shadow: none;
        outline: 0;
    }
`;

const TreeSaveButton = styled.button`
    margin: 0 1em;
    border: 0;
    padding: 0.5em 1em;
    font-size: ${props => props.theme.fontSize.xxs};
    border-radius: 0.25em;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.active ? props.theme.button.colors.submit : props.theme.colors.silver};
    cursor: pointer;
`;

class BuildingSensorTree extends PureComponent {
    state = {
        /** Data for sortable tree */
        buildingTree: [],
        /** Open tree menu */
        openMenu: null,
    };

    componentDidMount() {
        this.updateBuildingTree();
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.buildingHierarchy, this.props.buildingHierarchy)) {
            this.updateBuildingTree();
        }
    }

    updateBuildingTree = () => {
        const hierarchy = this.props.buildingHierarchy;

        if (hierarchy && hierarchy.length > 0) {
            const buildingTree = this.mapFeaturesToTree(hierarchy, treeGroups);
            this.setState({ buildingTree, openMenu: null });
        }
    };

    /**
     * Sensor hierarchy tree creation and handling
     */
    // Map features (areas, sensors) to tree
    mapFeaturesToTree = hierarchy => {
        const buildingLevel = _.cloneDeep(hierarchy);
        const data = buildingLevel[0];
        const buildingTree = [this.getTreeFromHierarchy(data)];
        return buildingTree;
    };

    // Get tree data from hierarchy
    getTreeFromHierarchy = hierarchy => {
        if (!hierarchy || hierarchy.length === 0) {
            return;
        }
        const { children, type, sensors, id, name, shortName } = hierarchy;
        const treeNode = this.createHierarchyNode({ type, shortName, id, name });

        if (sensors && sensors.length > 0) {
            treeNode.children = treeNode.children.concat(this.getTreeFromSensors(sensors, id));
        }
        if (children && children.length > 0) {
            children.forEach(child => {
                treeNode.children = treeNode.children.concat(this.getTreeFromHierarchy(child));
            });
        }
        return treeNode;
    };

    // Create hierarchy group node
    createHierarchyNode = data => {
        return {
            name: data.type === 'floor' ? data.shortName : data.name,
            placeholder: data.type,
            type: data.type,
            id: data.id,
            group: true,
            children: [],
            expanded: true
        };
    };

    createNodeFromSensor = (sensor, hierarchyId, hasLocation) => this.createTreeNode({
        name: sensor.name,
        type: sensor.type,
        id: sensor.id,
        sensorType: sensor.sensorType,
        meta: sensor.sensorMeta,
        hierarchyId,
        isSubSensor: !!sensor.parentId,
        isRemovable: !!sensor.parentId && hasLocation,
        sensor,
    });

    mapNodeProperties = (hierarchyId, sensor, canBeRemovedFromLocation) => {
        const { name, type, id, sensorType, sensorMeta, parentId } = sensor;
        return {
            name,
            type,
            id,
            sensorType,
            meta: sensorMeta,
            hierarchyId,
            isSubSensor: !!parentId,
            canBeRemovedFromLocation,
            sensor,
        };
    };

    // Recursively create sensor tree (sensor -> subsensor etc.) from a list of sensors
    getTreeFromSensors = (
        sensors,
        hierarchyId
    ) => {

        // Loop through all sensors that are tied to parent level
        const tree = sensors.reduce((accu, sensor) => {
            // Skip empty sensors
            if (sensor) {
                // Create sensor node
                const node = this.createTreeNode(
                    this.mapNodeProperties(hierarchyId, sensor, hierarchyId !== this.props.buildingId)
                );
                // If sensor has subsensors, go through them and push them to node.children as new nodes
                if (sensor.children && sensor.children.length > 0) {
                    _.forEach(sensor.children, subsensor => {
                        const childNode = this.createTreeNode(
                            this.mapNodeProperties(hierarchyId, subsensor, false)
                        );
                        node.children.push(childNode);
                    });
                }
                accu.push(node);
            }
            return accu;
        }, []);
        // Return sensor-subsensor node tree
        return tree;
    };

    // Create sensor node
    createTreeNode({
        name,
        type,
        id,
        hierarchyId,
        sensorType,
        isSubSensor,
        canBeRemovedFromLocation,
        meta,
        sensor,
    }) {
        const { t } = this.props;
        const subTitleType = _.isObject(sensorType) ? sensorType.name : type;
        const typeTitle = isSubSensor ? 'measuring point' : 'device';
        const loraId = !!meta && meta.length > 0 && _.find(meta, { 'metaKey': 'LoRaWAN ID' });
        const sigfoxId = !!meta && meta.length > 0 && _.find(meta, { 'metaKey': 'sigfox_id' });
        const niagaraId = !!meta && meta.length > 0 && _.find(meta, { 'metaKey': 'hs:id' });

        return {
            title: `${typeTitle}: ${name}`,
            subtitle: `type: ${subTitleType}`
                + `, id: ${id}`
                + (!!loraId ? `, lorawan id: ${loraId.value}` : '')
                + (!!sigfoxId ? `, sigfox id: ${sigfoxId.value}` : '')
                + (!!niagaraId ? `, niagara id: ${niagaraId.value}` : '')
                + (sensor.disabled ? ` (${t('disabled')})` : ''),
            sensorType,
            type: 'sensor',
            typeTitle,
            id,
            hierarchyId,
            children: [],
            expanded: true,
            isOpen: false,
            isSubSensor,
            canBeRemovedFromLocation,
            name,
            sensor,
        };
    }

    // Keep up tree state changes (open nodes etc)
    handleHierarchyTreeChange = buildingTree => this.setState({ buildingTree });

    // Creates floor object for Blueprint module
    getFloorMapAreasAndSensors = (floor, latestValuesBySensorId, t) => {
        if (!floor) {
            return {};
        }

        const floorData = getFloorFeatures(floor, latestValuesBySensorId, t);
        const {
            floorImage,
            floorImagePath,
            floorAreas,
            floorSensors,
            areaFeatures,
            sensorFeatures,
            floorIcons
        } = floorData;

        const floorObj = {
            id: floor.id,
            areaList: floorAreas,
            sensorList: floorSensors,
            number: floor.order,
            title: floor.shortName,
            image: floorImagePath,
            imageId: floorImage ? floorImage.id : null,
            imageWidth: floorImage ? floorImage.width : null,
            imageHeight: floorImage ? floorImage.height : null,
            areas: {
                type: 'FeatureCollection',
                features: areaFeatures
            },
            sensors: {
                type: 'FeatureCollection',
                features: sensorFeatures
            },
            floorIcons
        };
        return floorObj;
    }

    handleToggleTreeMenu = (node, path) => {
        this.closeOpenMenu();

        if (!node.isOpen) {
            this.setState(state => ({
                openMenu: { node, path },
                buildingTree: changeNodeAtPath({
                    treeData: state.buildingTree,
                    path,
                    getNodeKey: ({ treeIndex }) => treeIndex,
                    newNode: { ...node, isOpen: true }
                })
            }));
        }
    };

    closeOpenMenu = () => {
        this.setState(state => state.openMenu && {
            openMenu: null,
            buildingTree: changeNodeAtPath({
                treeData: state.buildingTree,
                path: state.openMenu.path,
                getNodeKey: ({ treeIndex }) => treeIndex,
                newNode: { ...state.openMenu.node, isOpen: false }
            })
        });
    };

    generateNodeProps = ({ node, path }) => {
        const {
            t, editSensor, editSensorMeta, toggleSensor, removeSensorLocation, onDelete, onTitleSave, theme,
        } = this.props;

        const isSensor = node.id && node.type === 'sensor';
        const canBeEdited = node.id && (node.type === 'sensor' || node.type === 'area');
        const canBeRemoved = node.id &&
            (node.type === 'sensor' ||
            node.type === 'area' ||
            node.type === 'group' ||
            node.type === 'group_energy');
        const hasButtons = isSensor || canBeEdited || canBeRemoved;

        return {
            buttons: hasButtons ? [
                <TreeMenuContainer>
                    { this.props.scrollToId === node.id && <ScrollToComponent/> }
                    <TreeMenuWrapper>
                        <TreeMenuToggle
                            isOpen={ node.isOpen }
                            onClick={ e => { e.preventDefault(); this.handleToggleTreeMenu(node, path); } }
                        />
                        { node.isOpen && <TreeMenu isOpen>
                            <TreeMenuBackground>
                                { canBeEdited && <TreeMenuButton onClick={() => editSensor(node)}>
                                    { t('Edit') }
                                </TreeMenuButton> }
                                { isSensor && <TreeMenuButton onClick={() => editSensorMeta(node)} >
                                    { t('Meta') }
                                </TreeMenuButton> }
                                { isSensor &&
                                    <TreeMenuButton onClick={ () => toggleSensor(node.sensor) }>
                                        { t(node.sensor.disabled ? 'Enable' : 'Disable') }
                                    </TreeMenuButton>
                                }
                                { node.canBeRemovedFromLocation && <TreeMenuButton
                                    onClick={() => removeSensorLocation(node) }
                                    isDeleteButton
                                >
                                    { t('Remove from location') }
                                </TreeMenuButton>}
                                { canBeRemoved && <TreeMenuButton
                                    onClick={() => onDelete(node) }
                                    isDeleteButton
                                >
                                    { t('Remove') }
                                </TreeMenuButton>}
                            </TreeMenuBackground>
                        </TreeMenu> }
                    </TreeMenuWrapper>
                </TreeMenuContainer>
            ] : [],
            title: node.group &&
            <div title={ `id: ${node.id}` }>
                { t(node.type) }: <input
                    value={ node.name || '' }
                    placeholder={ node.placeholder }
                    onChange={ event => {
                        const name = event.target.value;
                        this.setState(state => {
                            const getNodeKey = ({ treeIndex }) => treeIndex;
                            return {
                                buildingTree: changeNodeAtPath({
                                    treeData: state.buildingTree,
                                    path,
                                    getNodeKey,
                                    newNode: { ...node, name, saved: false },
                                })
                            };
                        });
                    }}
                />
                <TreeSaveButton active={ node.saved === false } onClick={() => onTitleSave(node)}>
                    { t('Save') }
                </TreeSaveButton>
            </div>,
            style: node.sensor && node.sensor.disabled && {
                color: theme.colors.silver,
            },
        };
    };

    render () {
        const {
            t,
            buildingId,
            addSensor,
            addGroup,
        } = this.props;

        const {
            buildingTree,
            openMenu,
        } = this.state;

        return (
            <Section>
                <TreeMenuOverlay active={ openMenu } onClick={ this.closeOpenMenu } />
                <StyledSection>
                    <h4>{t('Building Sensors')}</h4>
                    <ButtonDropdown
                        buttonLabel={ `${t('Functions')} +` }
                        items={ [
                            { label: t('Add device'), onClick: e => addSensor(e, buildingId) },
                            { label: t('Add group'), onClick: addGroup }
                        ] }
                    />
                </StyledSection>
                { buildingTree && buildingTree.length > 0 && <TreeContainer>
                    <Tree>
                        <SortableTree
                            canDrag={false}
                            treeData={buildingTree}
                            onChange={this.handleHierarchyTreeChange}
                            isVirtualized={false}
                            generateNodeProps={this.generateNodeProps}
                        />
                    </Tree>
                </TreeContainer> }
            </Section>
        );
    }
}

BuildingSensorTree.propTypes = {
    /** Translations function */
    t: PropTypes.func.isRequired,
    /** Building sensor hierarchy */
    buildingHierarchy: PropTypes.arrayOf(
        PropTypes.object
    ).isRequired,
    /** Building id */
    buildingId: PropTypes.number.isRequired,
    /** Functions: */
    addSensor: PropTypes.func.isRequired,
    addGroup: PropTypes.func.isRequired,
    editSensor: PropTypes.func.isRequired,
    editSensorMeta: PropTypes.func.isRequired,
    toggleSensor: PropTypes.func.isRequired,
    removeSensorLocation: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onTitleSave: PropTypes.func.isRequired,
    /** Sensor id where user want to scroll in tree  */
    scrollToId: PropTypes.number,
    theme: PropTypes.object.isRequired,
};

export default withTheme(BuildingSensorTree);
