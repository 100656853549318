import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';

import {
    Button,
    InputRow,
    InputBooleanCheckbox,
    InputForm,
    InputSearchDropdown,
    ErrorText,
    Infotip,
} from 'components/index.js';
import Loader from 'components/Loader/Loader';
import SectionHeader from 'components/Section/SectionHeader';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import Section from 'components/Section/Section';
import ScrollToComponent from 'components/ScrollToComponent/ScrollToComponent';
import {
    isAdminRole,
    isAdminOrCaverionUserRole,
    isCaverionAdminRole,
    isCaverionAdminOrUserRole
} from 'utils/Data/profileData';
import {
    loadPermissionsHierarchy,
    loadCustomers,
    searchPartnersByKeyword,
    searchPartnersByIds,
    searchFunctionalLocationsByPartners,
    resetSearch,
    changeTypes,
    addLoading,
    removeLoading,
    searchUsername,
    getPortfolioCounts,
    clearEditProfile,
    deleteProfile,
    showGlobalNotification,
    loadFunctionalLocations,
} from 'redux/modules/index';
import SnackBar from 'components/SnackBar/SnackBar';
import Permissions from './Permissions/Permissions';
import SearchResults from './SearchResults/SearchResults';
import Features from './Features/Features';
import Settings from './Settings/Settings';
import CustomViews from './CustomViews/CustomViews';
import { getPartnerNumbers, deriveEditedPermissions, getAllPermissionsFromModel } from 'utils/profile';
import { MODALTYPE } from 'components/Modal/ModalTypes';
import { showModal } from 'redux/modules/modal/modal';
import Api from './Api/Api';

const TOOLTIP_HIDE_DELAY = 5000;

const types = [
    { type: 'BE', text: 'Business Entities' },
    { type: 'BU', text: 'Buildings' },
    { type: 'UN', text: 'Tenants' },
    { type: 'IN', text: 'Industries' },
    { type: 'TO', text: 'Technical Objects' },
    { type: 'SYSTEM', text: 'Disciplines' }
];

const LOADING_PARTNERS = 'partners';
const LOADING_RESULTS = 'results';

const tabOptions = (t, showPermissions, showApi) => {
    const tabs = [
        { label: t('Profile & Settings'), value: 0 },
        { label: t('Features'), value: 1 },
        { label: t('Permissions'), value: 2 },
        { label: t('Custom Views'), value: 3 }
    ];
    !showPermissions && _.pullAt(tabs, 2);
    if (showApi) {
        tabs.push({ label: t('API'), value: 4 });
    }

    return tabs;
};

const Container = styled.div`
    width: 100%;
    max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});
    margin: 0 auto;
`;

const UserformSearchFilters = styled.div`
    background-color: ${props => props.theme.colors.alabaster};
    border-top: 1px solid ${props => props.theme.colors.alabaster};
    border-bottom: 1px solid ${props => props.theme.colors.lightGray};
    color: ${props => props.theme.colors.blue};
    padding: 0.9em 1.2em;
    font-size: 1em;

    :checked + label {
        top: 3px;
    }

    > span {
        margin-right: 10px;
    }
`;

const StyledAdminOrCaverionUser = styled.div`
    flex: 0 0 100%;
    max-width: 100%;
`;

const SectionTitle = styled.h2`
    padding: ${props => props.theme.spacing.md} 0;
    font-size: ${props => props.theme.font.size.lg};
`;

const StyledErrorText = styled.div`
    margin: ${props => props.theme.spacing.md} 0;
`;

const SnackBarButtonContainer = styled.div`
    display: flex;
`;

const SnackBarButtonSpacer = styled.div`
    width: ${props => props.theme.spacing.md};
`;

const SearchFilterInput = styled.span`
    display: inline-block;
    margin: ${props => props.theme.spacing.xs} 0;
`;

class UserForm extends Component {
    state = {
        tooltip: undefined,
        selectedTab: 0
    };

    componentDidMount() {
        if (this.props.profile.role === 'customer-admin') {
            this.props.searchPartnersByIds(this.props.profile.adminPartnerNumbers || []);
        }
    }

    componentDidUpdate(prevProps) {
        const { profile } = this.props;
        // Update pre-selected partner numbers for customer-admin.
        if (this.props.profile.role === 'customer-admin' &&
            prevProps.profile.adminPartnerNumbers !== this.props.profile.adminPartnerNumbers) {
            this.props.searchPartnersByIds(this.props.profile.adminPartnerNumbers || []);
        }

        if (this.props.customViews !== prevProps.customViews) {
            // Load FL:s in custom views
            const customViews = this.props.model && this.props.model.id
                && this.props.customViews[this.props.model.id] || [];
            const FLs = _.compact(_.uniq(_.flatten(_.map(customViews, 'functionalLocations'))));

            // Load FLs and customers for normal users (admins have already loaded customers)
            if (profile && !isCaverionAdminOrUserRole(profile.role)) {
                this.props.loadFunctionalLocations(FLs);
                const customers = _.uniq(_.flatten(_.map(customViews, 'customers')));
                this.props.loadCustomers(customers);
            }
            else {
                // Load FLs for admins
                this.props.loadPermissionsHierarchy(FLs);
            }
        }
    }

    componentWillUnmount() {
        this.props.clearEditProfile();
        this.props.resetSearch();
        clearTimeout(this.hideTooltipTimer);
    }

    addFunctionalLocation = functionalLocations => () => {
        const partner = this.props.partnerSearch.ids[0];
        const { model: { addedFunctionalLocations = {} } } = this.props;
        const flArray = Array.isArray(functionalLocations) ? functionalLocations : [functionalLocations];

        // Load information needed in order to show the FL(s) in permissions list
        this.props.loadPermissionsHierarchy(flArray);

        // Add functional location to partner permissions

        const addedForPartner = addedFunctionalLocations[partner] || [];
        this.props.setProperty('addedFunctionalLocations',
            _.assign(addedFunctionalLocations, { [partner]: addedForPartner.concat(flArray) }));

        return false;
    };

    deleteFunctionalLocation = (functionalLocation, partner) => () => {
        const {
            model: {
                addedFunctionalLocations = {},
                deletedFunctionalLocations = {} }
        } = this.props;

        const deleted = deletedFunctionalLocations[partner]
            && _.includes(deletedFunctionalLocations[partner], functionalLocation);
        const newlyAdded = addedFunctionalLocations[partner]
            && _.includes(addedFunctionalLocations[partner], functionalLocation);

        // Re-deleting item that is marked for deletion => don't delete
        if (deleted) {
            const editedDeletedFls = _.without(deletedFunctionalLocations[partner], functionalLocation);
            this.props.setProperty('deletedFunctionalLocations',
                _.assign(deletedFunctionalLocations, { [partner]: editedDeletedFls }));
        }
        // Deleting newly added => remove from added and profile permissions
        else if (newlyAdded) {
            const editedAddedFls = _.without(addedFunctionalLocations[partner], functionalLocation);
            this.props.setProperty('addedFunctionalLocations',
                _.assign(addedFunctionalLocations, { [partner]: editedAddedFls }));
        }
        else {
            const deletedForPartner = deletedFunctionalLocations[partner] || [];
            this.props.setProperty('deletedFunctionalLocations',
                _.assign(deletedFunctionalLocations, { [partner]: deletedForPartner.concat([functionalLocation]) }));
        }

        return false;
    };

    deletePartnerFunctionalLocations = partnerNumber => () => {
        const {
            model,
            model: {
                permissions = {},
                addedFunctionalLocations = {},
                deletedFunctionalLocations = {},
            },
            setProperty,
        } = this.props;

        const functionalLocations = getAllPermissionsFromModel(model)[partnerNumber] || [];
        const deleted = functionalLocations.every(fl => _.includes(deletedFunctionalLocations[partnerNumber], fl));

        if (deleted) {
            setProperty('deletedFunctionalLocations', {
                ...deletedFunctionalLocations,
                [partnerNumber]: [],
            });
        }
        else {
            setProperty('addedFunctionalLocations', _.omit(addedFunctionalLocations, [partnerNumber]));

            // Mark as deleted only if previously added
            if (permissions[partnerNumber]) {
                setProperty('deletedFunctionalLocations', {
                    ...deletedFunctionalLocations,
                    [partnerNumber]: functionalLocations,
                });
            }
        }
    };

    deletePartnerPermissions = partner => () => {
        const {
            setProperty,
            model: {
                partnerPermissions = [],
                addedPartnerPermissions = [],
                deletedPartnerPermissions = [],
            },
        } = this.props;

        const previouslyAdded = _.includes(partnerPermissions, partner);
        const deleted = _.includes(deletedPartnerPermissions, partner);
        if (deleted) {
            setProperty('deletedPartnerPermissions', _.without(deletedPartnerPermissions, partner));
        }
        else {
            setProperty('addedPartnerPermissions', _.without(addedPartnerPermissions, partner));
            if (previouslyAdded) {
                setProperty('deletedPartnerPermissions', [...deletedPartnerPermissions, partner]);
            }
        }
    };

    addPartnerPermission = partnerNumber => () => {
        const {
            setProperty,
            model: {
                addedPartnerPermissions = [],
                deletedPartnerPermissions = [],
            },
        } = this.props;

        setProperty('addedPartnerPermissions', [...addedPartnerPermissions, partnerNumber]);
        setProperty('deletedPartnerPermissions', _.without(deletedPartnerPermissions, partnerNumber));
    };

    submit = () => {
        const { model, submit } = this.props;
        const profile = {
            ...model,
            ...deriveEditedPermissions(model),
        };

        const partnerNumbers = getPartnerNumbers(profile);

        // If user role is set to customer-admin, populate administrated partner numbers.
        if (profile.role === 'customer-admin') {
            profile.adminPartnerNumbers = partnerNumbers;
        }

        // Prevent saving profile without partners.
        if (partnerNumbers.length === 0) {
            this.setState({ tooltip: 'Please add at least one permission for the user.' });
            clearInterval(this.hideTooltipTimer);
            this.hideTooltipTimer = setTimeout(() => this.setState({ tooltip: undefined }), TOOLTIP_HIDE_DELAY);
            return;
        }

        this.setState({ tooltip: undefined });
        submit(profile);
    };

    invokePartnerSearch(value) {

        // Don't invoke partner search if user is customer-admin since available customers
        // are pre-populated.
        if (this.props.profile.role === 'customer-admin') {
            return;
        }

        this.props.addLoading(LOADING_PARTNERS);
        this.props.searchPartnersByKeyword(value)
            .then(
                () => this.props.removeLoading(LOADING_PARTNERS),
                () => this.props.removeLoading(LOADING_PARTNERS));
    }

    loadFunctionalLocations(partner, types = this.props.types) {
        // Clear search if partners are not given.
        if (!partner) {
            this.props.resetSearch(this.props.profile.role === 'customer-admin');
        }
        else {
            this.props.addLoading(LOADING_RESULTS);
            this.props.searchFunctionalLocationsByPartners([partner], types)
                .then(() => { this.props.removeLoading(LOADING_RESULTS); },
                    () => this.props.removeLoading(LOADING_RESULTS));
        }
    }

    toggleType(type, value) {
        const types = Object.assign({}, this.props.types, { [type]: value });
        this.props.changeTypes(types);
        this.loadFunctionalLocations(this.props.partnerSearch.ids[0], types);
    }

    checkExistingUsername(model) {
        const username = _.get(model, 'username');
        if (!this.props.newProfile || !username) {
            return;
        }
        this.props.searchUsername(username);
    }

    renderTypeCheckbox(type, text) {
        return (
            <SearchFilterInput key={ `search-filter-${type}` }>
                <InputBooleanCheckbox
                    id={ type }
                    value={ this.props.types[type] }
                    onChange={ (property, value) => this.toggleType(type, value) }
                    label={ this.props.t(text) }
                />
            </SearchFilterInput>
        );
    }

    markDeletedPartners(partnerSearchOptions) {
        if (!partnerSearchOptions) {
            return undefined;
        }
        return partnerSearchOptions.map(option =>
            option.deleted ? { ...option, label: `**${this.props.t('Deleted')}** ${option.label}` } : option);
    }

    handleDeleteClick = event => {
        event.preventDefault();

        const { model, history, showModal, deleteProfile, showGlobalNotification, t, profile } = this.props;
        showModal(
            MODALTYPE.CONFIRMATION_DELETE_USER,
            null,
            () => deleteProfile(model.id).then(result => {
                if (result.error) {
                    showGlobalNotification(t('User deletion failed.'), 'error');
                }
                else if (profile.id === model.id) {
                    history.push('/Logout');
                }
                else {
                    history.push('/Admin');
                    showGlobalNotification(t('User has been deleted.'), 'success');
                }
            }),
            `${t('User')} ${t('{0} will be deleted', model.username)}`
        );
    };

    render() {
        const {
            t,
            model,
            searchItems,
            functionalLocations,
            profile,
            newProfile,
            loading,
            loadingPermissions,
            usernameExists,
            customers,
            portfolioCounts,
            customViews,
            functionalLocationsForUser
        } = this.props;

        const { tooltip, selectedTab } = this.state;

        const isAdmin = profile && isAdminRole(profile.role);
        const isCaverionAdmin = profile && isCaverionAdminRole(profile.role);
        const isCaverionAdminOrUser = profile && isCaverionAdminOrUserRole(profile.role);
        const isAdminOrCaverionUser = profile && isAdminOrCaverionUserRole(profile.role);
        const invalidUsername = newProfile && model && model.username && usernameExists[model.username] || false;
        const hasApi = model && model.features.api;

        const filters = <UserformSearchFilters>
            { types.map(t => this.renderTypeCheckbox(t.type, t.text)) }
        </UserformSearchFilters>;

        const error = this.props.error &&
            <StyledErrorText>
                <ScrollToComponent key={this.props.error} />
                <ErrorText>
                    { t(this.props.error) }
                </ErrorText>
            </StyledErrorText>;

        const noSearchResults = this.props.partnerSearch.ids && this.props.partnerSearch.ids.length > 0
            && !_.includes(loading, LOADING_RESULTS) && searchItems.length === 0;
        const showSnackbar = model &&
                             model.edited &&
                             !loadingPermissions &&
                             !invalidUsername;

        return (
            <Container>
                <SectionHeader t={ t }>
                    <SectionTabSelector
                        left
                        large
                        t={ t }
                        options={ tabOptions(t, isAdminOrCaverionUser, hasApi) }
                        model={{ selectedTab }}
                        property="selectedTab"
                        onTabChange={ (property, value) => this.setState({ selectedTab: value }) }
                    />
                </SectionHeader>
                { error }
                <InputForm
                    id={ this.props.id }
                    model={ model }
                    onPropertyChange={ this.props.setProperty }
                    onSubmit={ this.submit }
                >
                    { selectedTab === 0 && <Settings
                        t={ t }
                        model={ model }
                        isCaverionAdminOrUser={ isCaverionAdminOrUser }
                        isAdmin={ isAdmin }
                        isCaverionAdmin={ isCaverionAdmin }
                        onChange={ this.props.setProperty }
                        newProfile={ newProfile }
                        handleDeleteClick={ this.handleDeleteClick }
                        usernameExists={ usernameExists }
                    /> }
                    { selectedTab === 1 && <Features
                        t={ t }
                        model={ model }
                        isCaverionAdminOrUser={ isCaverionAdminOrUser }
                        onChange={ this.props.setProperty }
                    /> }
                    { isAdminOrCaverionUser && selectedTab === 2 && <StyledAdminOrCaverionUser>
                        <Section>
                            <SectionTitle id="partnerNumber">{ t('Add Permissions') }</SectionTitle>
                            <InputRow fullRow>
                                <InputSearchDropdown
                                    property="keyword"
                                    model={ this.props.partnerSearch }
                                    onSearch={ value => this.invokePartnerSearch(value) }
                                    onChange={ (_, value) => this.loadFunctionalLocations(value) }
                                    options={ this.markDeletedPartners(this.props.partnerSearch.options) }
                                    placeholder={ `${t('Select or search customers')}...` }
                                    t={ t }
                                    loading={ _.includes(loading, LOADING_PARTNERS) }
                                />
                            </InputRow>
                            <div>
                                { this.props.partnerSearch.ids && this.props.partnerSearch.ids.length > 0 && filters }
                                { _.includes(loading, LOADING_RESULTS) ?
                                    <InputRow fullRow><Loader /></InputRow> :
                                    <SearchResults
                                        t={t}
                                        model={model}
                                        items={searchItems}
                                        customer={this.props.partnerSearch.ids && this.props.partnerSearch.ids.length ?
                                            customers[this.props.partnerSearch.ids[0]] :
                                            undefined
                                        }
                                        portfolioCounts={portfolioCounts}
                                        addFunctionalLocation={this.addFunctionalLocation}
                                        deleteFunctionalLocation={this.deleteFunctionalLocation}
                                        addPartnerPermission={this.addPartnerPermission}
                                    />
                                }
                                { noSearchResults && <div className="center" style={{ marginTop: '2em' }}>
                                    { this.props.t('No results found') }
                                </div> }
                            </div>
                        </Section>
                        <Section>
                            <SectionTitle id="permissions" data-test-id="Permissions">
                                { t('Existing Permissions') }
                            </SectionTitle>
                            <InputRow fullRow>
                                { tooltip && <ScrollToComponent key={tooltip} /> }
                                { loadingPermissions && <div style={{ marginTop: '1em' }}>
                                    <Loader />
                                </div> }
                                { model && !loadingPermissions && <Permissions
                                    t={t}
                                    partnerPermissions={ model.partnerPermissions || EMPTY_ARRAY }
                                    addedFunctionalLocations={ model.addedFunctionalLocations || EMPTY_OBJECT }
                                    deletedFunctionalLocations={ model.deletedFunctionalLocations || EMPTY_OBJECT }
                                    addedPartnerPermissions={ model.addedPartnerPermissions || EMPTY_ARRAY }
                                    deletedPartnerPermissions={ model.deletedPartnerPermissions || EMPTY_ARRAY }
                                    permissions={ model.permissions || EMPTY_OBJECT }
                                    inactivePermissions={ model.inactivePermissions || EMPTY_OBJECT }
                                    functionalLocations={functionalLocations}
                                    customers={customers}
                                    portfolioCounts={portfolioCounts}
                                    deleteFunctionalLocation={this.deleteFunctionalLocation}
                                    deletePartnerFunctionalLocations={this.deletePartnerFunctionalLocations}
                                    deletePartnerPermissions={this.deletePartnerPermissions}
                                /> }
                            </InputRow>
                        </Section>
                    </StyledAdminOrCaverionUser>}
                    { selectedTab === 3 && <CustomViews
                        t={ t }
                        model={ model }
                        onChange={ this.props.setProperty }
                        customViews={ model && model.id && customViews[model.id] || [] }
                        customers={ this.props.customers }
                        functionalLocations={ Object.assign({}, functionalLocationsForUser, functionalLocations) }
                    /> }
                    { selectedTab === 4 &&
                        <Api
                            t={ t }
                            profileId={ this.props.profileId }
                            showModal={ this.props.showModal }
                            profile={ profile }
                        />
                    }
                    <SnackBar
                        visible={ showSnackbar }
                        variant="confirmation"
                        hideDelay={ 1000 }
                        primaryContent={ <div>
                            { !this.props.saving && !this.props.saved && t('Save your changes') }
                            { this.props.saving && `${t('Saving')}...` }
                            { this.props.saved && t('Profile successfully saved') }
                        </div>}
                        secondaryContent={
                            <SnackBarButtonContainer>
                                {tooltip &&
                                    <Infotip
                                        text={ t(tooltip) }
                                        alwaysVisible
                                        position="left"
                                        textAlign="left"
                                    >
                                        <SnackBarButtonSpacer />
                                    </Infotip>
                                }
                                <Button submit>
                                    { !this.props.saving && !this.props.saved && t('Save') }
                                    { this.props.saving && <Loader color='WHITE' size='SMALL' /> }
                                    { this.props.saved && t('Saved') }
                                </Button>
                            </SnackBarButtonContainer>
                        }
                    />
                </InputForm>
            </Container>
        );
    }
}

const COMPONENT = 'UserForm';
const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const mapStateToProps = (state, props) => ({
    usernameExists: state.profile.usernameExists,
    profile: state.profile.profile,
    customerHierarchy: state.customer.hierarchy,
    searchItems: state.customer.functionalLocationSearchResults,
    totalResults: state.customer.totalResults,
    functionalLocations: state.customer.functionalLocations,
    functionalLocationsForUser: state.functionalLocations.functionalLocations,
    partnerSearch: state.customer.partnerSearch,
    types: state.customer.typesToSearch,
    customers: state.customer.customers,
    error: state.profile.error,
    loading: state.common.loading[COMPONENT] || EMPTY_ARRAY,
    portfolioCounts: state.customer.portfolioCounts,
    customViews: state.customView.customViewsByUser,
});

const mapDispatchToProps = dispatch => ({
    searchPartnersByKeyword: value => dispatch(searchPartnersByKeyword(value)),
    searchPartnersByIds: ids => dispatch(searchPartnersByIds(ids)),
    searchFunctionalLocationsByPartners: (partners, types) =>
        Promise.all([
            dispatch(searchFunctionalLocationsByPartners(partners, types)),
            dispatch(loadCustomers(partners)),
            dispatch(getPortfolioCounts(partners))
        ]),
    loadPermissionsHierarchy: functionalLocations => dispatch(loadPermissionsHierarchy(functionalLocations)),
    loadFunctionalLocations: functionalLocations => dispatch(loadFunctionalLocations(functionalLocations)),
    loadCustomers: customers => dispatch(loadCustomers(customers)),
    searchUsername: username => dispatch(searchUsername(username)),
    resetSearch: leaveOptions => dispatch(resetSearch(leaveOptions)),
    changeTypes: types => dispatch(changeTypes(types)),
    addLoading: key => dispatch(addLoading(key, COMPONENT)),
    removeLoading: key => dispatch(removeLoading(key, COMPONENT)),
    clearEditProfile: () => dispatch(clearEditProfile()),
    showModal: (type, preConditions, onSubmitAction, passedProps) =>
        dispatch(showModal(type, preConditions, onSubmitAction, passedProps)),
    deleteProfile: profileId => dispatch(deleteProfile(profileId)),
    showGlobalNotification: (message, type) => dispatch(showGlobalNotification(message, type)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withRouter(UserForm));
