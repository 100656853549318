import { MasterData } from '@caverion/redux/api/actions';
import moment from 'moment';

import { createReducerFromMapping } from 'redux/utils';
import { indexByDate } from '../../utils/indexing';
import { SEARCH_SUCCESS } from './serviceOrders';

const initialState = {
    index: indexByDate.getInitialState(),
    loading: {},
};

export const LOAD = 'CUSTOMER_PLATFORM/PlannedMaintenance/LOAD';
export const LOAD_SUCCESS = 'CUSTOMER_PLATFORM/PlannedMaintenance/LOAD_SUCCESS';
export const LOAD_FAIL = 'CUSTOMER_PLATFORM/PlannedMaintenance/LOAD_FAIL';

export const loadPlannedMaintenance = (maintenanceId, partnerNumber, functionalLocation, key) => async dispatch => {
    const filter = JSON.stringify({
        where: {
            id: maintenanceId,
            path: {
                'any x': {
                    x: functionalLocation,
                },
            },
        },
    });

    dispatch({ type: LOAD, key });
    try {
        const result = await dispatch(MasterData.plannedMaintenance(filter, partnerNumber));
        return dispatch({
            type: LOAD_SUCCESS,
            key,
            result,
        });
    } catch (error) {
        return dispatch({
            type: LOAD_FAIL,
            key,
            error,
        });
    }
};

export const loadPlannedMaintenances = (
    partnerNumber, functionalLocation, equipmentNumber, startDate, endDate, key
) => async dispatch => {
    const filter = JSON.stringify({
        where: {
            plannedDate: {
                between: [startDate.toISOString(), endDate.toISOString()],
            },
            path: {
                'any x': {
                    x: functionalLocation,
                },
            },
            ...equipmentNumber && {
                equipmentNumber: {
                    'any x': {
                        x: equipmentNumber,
                    },
                },
            },
        }
    });

    dispatch({ type: LOAD, key });
    try {
        const result = await dispatch(MasterData.plannedMaintenance(filter, partnerNumber));
        return dispatch({
            type: LOAD_SUCCESS,
            key,
            result,
        });
    } catch (error) {
        return dispatch({
            type: LOAD_FAIL,
            key,
            error,
        });
    }
};

export const loadAllPlannedMaintenances = (startDate, endDate, partnerNumber, key) => async dispatch => {
    dispatch({ type: LOAD, key });
    try {
        const result = await dispatch(MasterData.plannedMaintenancesForUser(
            startDate.toISOString(),
            endDate.toISOString(),
            partnerNumber !== 'all' ? partnerNumber : undefined,
        ));

        return dispatch({
            type: LOAD_SUCCESS,
            key,
            result,
        });
    } catch (error) {
        return dispatch({
            type: LOAD_FAIL,
            key,
            error,
        });
    }
};

const getMaintenanceYear = order => moment(order.plannedDate || order.createdDate).year();

export default createReducerFromMapping({
    [LOAD]: (state, action) => ({
        ...state,
        loading: {
            ...state.loading,
            [action.key]: true,
        },
    }),
    [LOAD_SUCCESS]: (state, action) => ({
        ...state,
        index: indexByDate.index(state.index, action.result, 'id', getMaintenanceYear),
        loading: {
            ...state.loading,
            [action.key]: false,
        },
    }),
    [LOAD_FAIL]: (state, action) => ({
        ...state,
        loading: {
            ...state.loading,
            [action.key]: false,
        },
    }),

    [SEARCH_SUCCESS]: (state, action) => ({
        ...state,
        index: indexByDate.index(state.index, action.result, 'id', getMaintenanceYear),
    }),
}, initialState);
