import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ServiceOrderCard from 'components/ServiceOrderCard/ServiceOrderCard';
import Legend from 'components/Calendar/Legend/Legend';
import { serviceOperationSortOrder } from 'utils/Data/resolveServiceOrderStatus';
import { OrderStatus } from 'constants/serviceCalendar';

const getStatus = (operation, order) => {
    if (operation.completedDate || order && order.status === OrderStatus.COMPLETED) {
        return 'completed';
    }
    if (operation.startedDate) {
        return 'started';
    }
    if (operation.arrivedAtSiteDate) {
        return 'arrived';
    }
    return 'released';
};

const Content = styled.div`
    margin-top: ${props => props.theme.spacing.md};
`;

Content.displayName = 'Content';

const Header = styled.div`
    display: flex;
    flex-direction: column;

    ${props => props.theme.media.portrait`
        flex-direction: row;
        justify-content: space-between;
    `}
`;

const StyledLegend = styled(Legend)`
    margin: ${props => props.theme.spacing.sm} 0 0 0;
    border: none;
    background: none;
    padding: 0;

    ${props => props.theme.media.portrait`
        margin: 0;
    `}
`;


const renderContent = ({ t, operations, order }) => {
    if (!operations || operations.loading) {
        return (
            <ServiceOrderCard loading={ true } operation={ true } />
        );
    }

    if (!operations.items || !operations.items.length) {
        return t('No operations.');
    }

    return operations.items && operations.items.map(operation =>
        <ServiceOrderCard
            key={ operation.objectNumber }
            t={ t }
            title={ `${t('Operation')} #00${operation.serviceOrderOperationNumber}` }
            text={ operation.text.split(/\r?\n/)[0] }
            status={ getStatus(operation, order) }
            createdDate={ order && order.createdDate }
            operation={ operation }
        />
    );
};

renderContent.propTypes = {
    order: PropTypes.object
};

const ServiceOrderOperations = props => {
    const { t } = props;
    return (
        <React.Fragment>
            <Header>
                <h4>{ t('Operations') }</h4>
                <StyledLegend statuses={ serviceOperationSortOrder } t={ t } noCounts />
            </Header>
            <Content>
                { renderContent(props) }
            </Content>
        </React.Fragment>
    );
};

ServiceOrderOperations.propTypes = {
    t: PropTypes.func.isRequired,
    operations: PropTypes.shape({
        loading: PropTypes.bool,
        items: PropTypes.arrayOf(PropTypes.object),
        error: PropTypes.object,
    })
};

export default ServiceOrderOperations;
