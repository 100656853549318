import styled from 'styled-components';
import React from 'react';

const StyledValuesBoxComparisonValues = styled.div`
    display: flex;
    flex-flow: row nowrap;
    border-top: ${({ theme }) => `${theme.spacing.border} solid ${theme.colors.lightGray}`};
`;
StyledValuesBoxComparisonValues.displayName = 'StyledValuesBoxComparisonValues';

const ValuesBoxComparisonValues = props =>
    <StyledValuesBoxComparisonValues {...props} />;

export default ValuesBoxComparisonValues;
