import { createReducerFromMapping } from 'redux/utils/index.js';
import _ from 'lodash';
import { MasterData } from '@caverion/redux/api/actions';

const initialState = {
    equipments: {},
    subEquipments: {},
    totalEquipment: {},
    equipmentTexts: {}
};

const emptyArray = [];

export const SET_TOTAL_EQUIPMENT = 'CUSTOMER_PLATFORM/Equipment/SET_TOTAL_EQUIPMENT';

export const setTotalEquipment = (functionalLocation, totalEquipment) =>
    ({ type: SET_TOTAL_EQUIPMENT, functionalLocation, totalEquipment });

export const LOAD_ONE = 'CUSTOMER_PLATFORM/Equipment/LOAD_ONE';
export const LOAD_ONE_SUCCESS = 'CUSTOMER_PLATFORM/Equipment/LOAD_ONE_SUCCESS';
export const LOAD_ONE_FAIL = 'CUSTOMER_PLATFORM/Equipment/LOAD_ONE_FAIL';

export const loadEquipment = (functionalLocation, equipmentNumber, superordinate, flParent = false) => {
    const filter = {
        where: {
            path: {
                'any x': {
                    x: functionalLocation.functionalLocation,
                }
            },
            equipmentNumber: equipmentNumber,
            equipmentSuperordinate: superordinate || ''
        }
    };

    if (superordinate && flParent) {
        filter.where.functionalLocationParent = functionalLocation.functionalLocation;
    } else {
        filter.where.functionalLocation = functionalLocation.functionalLocation;
    }

    return async dispatch => {
        dispatch({ type: LOAD_ONE });
        try {
            const result = await dispatch(MasterData.equipment(JSON.stringify(filter)));

            return dispatch({
                type: LOAD_ONE_SUCCESS,
                page: undefined,
                functionalLocation: functionalLocation.functionalLocation,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_ONE_FAIL,
                functionalLocation: functionalLocation.functionalLocation,
                error
            });
        }
    };
};

export const loadSubEquipment = (functionalLocation, equipmentNumber, equipmentSuperordinate) => {
    return async dispatch => {
        dispatch({ type: LOAD_ONE });
        try {
            const results = await Promise.all([
                dispatch(loadEquipment(functionalLocation, equipmentNumber, equipmentSuperordinate)),
                dispatch(loadEquipment(functionalLocation, equipmentNumber, equipmentSuperordinate, true))]);

            return dispatch({
                type: LOAD_ONE_SUCCESS,
                functionalLocation: functionalLocation.functionalLocation,
                equipmentSuperordinate,
                result: _.flatten(results.map(result => _.flatten(result.result)))
            });
        } catch (error) {
            return dispatch({
                type: LOAD_ONE_FAIL,
                functionalLocation: functionalLocation.functionalLocation,
                equipmentSuperordinate,
                error
            });
        }
    };
};

export const LOAD = 'CUSTOMER_PLATFORM/Equipment/LOAD';
export const LOAD_SUCCESS = 'CUSTOMER_PLATFORM/Equipment/LOAD_SUCCESS';
export const LOAD_FAIL = 'CUSTOMER_PLATFORM/Equipment/LOAD_FAIL';

export const loadEquipments = (functionalLocationId, includeAllLevels) => {
    const filter = {
        where: {
            path: {
                'any x': {
                    x: functionalLocationId,
                }
            },
            ...!includeAllLevels && { functionalLocation: functionalLocationId },
            equipmentSuperordinate: ''
        }
    };

    return async dispatch => {
        dispatch({ type: LOAD });
        try {
            const result = await dispatch(MasterData.equipment(JSON.stringify(filter)));

            return dispatch({
                type: LOAD_SUCCESS,
                functionalLocation: functionalLocationId,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_FAIL,
                functionalLocation: functionalLocationId,
                error
            });
        }
    };
};

export const LOAD_TEXTS = 'CUSTOMER_PLATFORM/Equipment/LOAD_TEXTS';
export const LOAD_TEXTS_SUCCESS = 'CUSTOMER_PLATFORM/Equipment/LOAD_TEXTS_SUCCESS';
export const LOAD_TEXTS_FAIL = 'CUSTOMER_PLATFORM/Equipment/LOAD_TEXTS_FAIL';

export const loadEquipmentTexts = equipmentIds => {
    const filter = {
        where: {
            equipmentNumber: { inq: equipmentIds }
        }
    };

    return async dispatch => {
        dispatch({ type: LOAD_TEXTS });
        try {
            const result = await dispatch(MasterData.equipmentTexts(filter));

            return dispatch({
                type: LOAD_TEXTS_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_TEXTS_FAIL,
                error
            });
        }
    };
};

export const LOAD_SUBEQUIPMENT = 'CUSTOMER_PLATFORM/Equipment/LOAD_SUBEQUIPMENT';
export const LOAD_SUBEQUIPMENT_SUCCESS = 'CUSTOMER_PLATFORM/Equipment/LOAD_SUBEQUIPMENT_SUCCESS';
export const LOAD_SUBEQUIPMENT_FAIL = 'CUSTOMER_PLATFORM/Equipment/LOAD_SUBEQUIPMENT_FAIL';

export const LOAD_SUBEQUIPMENT_PART1 = 'CUSTOMER_PLATFORM/Equipment/LOAD_SUBEQUIPMENT_PART1';
export const LOAD_SUBEQUIPMENT_PART2 = 'CUSTOMER_PLATFORM/Equipment/LOAD_SUBEQUIPMENT_PART2';
export const LOAD_SUBEQUIPMENT_PART1_SUCCESS = 'CUSTOMER_PLATFORM/Equipment/LOAD_SUBEQUIPMENT_PART1_SUCCESS';
export const LOAD_SUBEQUIPMENT_PART2_SUCCESS = 'CUSTOMER_PLATFORM/Equipment/LOAD_SUBEQUIPMENT_PART2_SUCCESS';
export const LOAD_SUBEQUIPMENT_PART1_FAIL = 'CUSTOMER_PLATFORM/Equipment/LOAD_SUBEQUIPMENT_PART1_FAIL';
export const LOAD_SUBEQUIPMENT_PART2_FAIL = 'CUSTOMER_PLATFORM/Equipment/LOAD_SUBEQUIPMENT_PART2_FAIL';

export const loadSubEquipments = (functionalLocationId, equipmentSuperordinate) => {
    return async dispatch => {
        dispatch({ type: LOAD_SUBEQUIPMENT });
        try {
            const results = await Promise.all([
                dispatch(loadSubEquipmentsByFunctionalLocation(functionalLocationId, equipmentSuperordinate)),
                dispatch(loadSubEquipmentsByFunctionalLocationParent(functionalLocationId, equipmentSuperordinate))]);

            return dispatch({
                type: LOAD_SUBEQUIPMENT_SUCCESS,
                functionalLocation: functionalLocationId,
                equipmentSuperordinate,
                result: _.flatten(results.map(result => _.flatten(result.result)))
            });
        } catch (error) {
            return dispatch({
                type: LOAD_SUBEQUIPMENT_FAIL,
                functionalLocation: functionalLocationId,
                equipmentSuperordinate,
                error
            });
        }
    };
};

const loadSubEquipmentsByFunctionalLocation = (functionalLocationId, equipmentSuperordinate) => {
    const filter = {
        where: {
            functionalLocation: functionalLocationId,
            equipmentSuperordinate
        }
    };

    return async dispatch => {
        dispatch({ type: LOAD_SUBEQUIPMENT_PART1 });
        try {
            const result = await dispatch(MasterData.equipment(JSON.stringify(filter)));

            return dispatch({
                type: LOAD_SUBEQUIPMENT_PART1_SUCCESS,
                functionalLocation: functionalLocationId,
                equipmentSuperordinate,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_SUBEQUIPMENT_PART1_FAIL,
                functionalLocation: functionalLocationId,
                equipmentSuperordinate,
                error
            });
        }
    };
};

const loadSubEquipmentsByFunctionalLocationParent = (functionalLocationId, equipmentSuperordinate) => {
    const filter = {
        where: {
            path: {
                'any x': {
                    x: functionalLocationId,
                }
            },
            functionalLocationParent: functionalLocationId,
            equipmentSuperordinate
        }
    };

    return async dispatch => {
        dispatch({ type: LOAD_SUBEQUIPMENT_PART2 });
        try {
            const result = await dispatch(MasterData.equipment(JSON.stringify(filter)));

            return dispatch({
                type: LOAD_SUBEQUIPMENT_PART2_SUCCESS,
                functionalLocation: functionalLocationId,
                equipmentSuperordinate,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_SUBEQUIPMENT_PART2_FAIL,
                functionalLocation: functionalLocationId,
                equipmentSuperordinate,
                error
            });
        }
    };
};

export default createReducerFromMapping({
    [SET_TOTAL_EQUIPMENT]: (state, action) => ({
        ...state,
        totalEquipment: { ...state.totalEquipment, [action.functionalLocation]: action.totalEquipment }
    }),
    [LOAD_ONE]: (state, action) => ({
        ...state
    }),
    [LOAD_ONE_SUCCESS]: (state, action) => {
        return {
            ...state,
            equipments: _.merge({}, state.equipments, {
                [action.functionalLocation]:
                    _.uniqBy(
                        (state.equipments[action.functionalLocation] || emptyArray)
                            .concat(action.result.map(equipment => ({
                                ...equipment,
                                page: action.page
                            }))),
                        'equipmentNumber'
                    )
            })
        };
    },
    [LOAD_ONE_FAIL]: (state, action) => ({
        ...state,
        equipments: _.merge({}, state.equipments, {
            [action.functionalLocation]: action.error
        })
    }),
    [LOAD]: (state, action) => ({
        ...state
    }),
    [LOAD_SUCCESS]: (state, action) => {
        const equipments = state.equipments[action.functionalLocation];
        const existingEquipmentData = equipments && equipments.map(equipment =>
            ({ ...equipment,
                totalMatches: action.result.length > 0 ? action.result[0].totalMatches : 0
            }));

        return {
            ...state,
            equipments: _.merge({}, state.equipments, {
                [action.functionalLocation]:
                    _.uniqBy((existingEquipmentData || emptyArray).concat(action.result),
                        'equipmentNumber')
            })
        };
    },
    [LOAD_FAIL]: (state, action) => ({
        ...state,
        equipments: _.merge({}, state.equipments, {
            [action.functionalLocation]: action.error
        })
    }),

    [LOAD_SUBEQUIPMENT]: (state, action) => ({
        ...state
    }),
    [LOAD_SUBEQUIPMENT_PART1]: (state, action) => ({
        ...state
    }),
    [LOAD_SUBEQUIPMENT_PART2]: (state, action) => ({
        ...state
    }),
    [LOAD_SUBEQUIPMENT_PART1_SUCCESS]: (state, action) => ({
        ...state
    }),
    [LOAD_SUBEQUIPMENT_PART2_SUCCESS]: (state, action) => ({
        ...state
    }),
    [LOAD_SUBEQUIPMENT_SUCCESS]: (state, action) => ({
        ...state,
        subEquipments: _.merge({}, state.subEquipments, {
            [action.equipmentSuperordinate]:
                _.uniqBy(
                    (state.subEquipments[action.equipmentSuperordinate] || emptyArray)
                        .concat(action.result.map(equipment => ({ ...equipment }))),
                    'equipmentNumber'
                )
        })
    }),
    [LOAD_SUBEQUIPMENT_FAIL]: (state, action) => ({
        ...state,
        subEquipments: _.merge({}, state.subEquipments, {
            [action.functionalLocation]: action.error
        })
    }),
    [LOAD_SUBEQUIPMENT_PART1_FAIL]: (state, action) => ({
        ...state,
        subEquipments: _.merge({}, state.subEquipments, {
            [action.functionalLocation]: action.error
        })
    }),
    [LOAD_SUBEQUIPMENT_PART2_FAIL]: (state, action) => ({
        ...state,
        subEquipments: _.merge({}, state.subEquipments, {
            [action.functionalLocation]: action.error
        })
    }),

    [LOAD_TEXTS_SUCCESS]: (state, action) => ({
        ...state,
        equipmentTexts: { ...state.equipmentTexts, ...action.result }
    })
}, initialState);
