import React from 'react';
import SkeletonText from 'components/Skeletons/SkeletonText';

export const SortableFLTableSkeleton = () => {
    return <tbody>
        <tr>
            <td style={{ padding: '1em 0' }} colSpan="5">
                <SkeletonText style={{ height: '28px' }}/>
            </td>
        </tr>
    </tbody>;
};
