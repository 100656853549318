import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Name = styled.span`
    display: inline-block;
    width: 190px;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    font-weight: ${props => props.theme.font.weight.bold};
    line-height: ${props => props.theme.font.lineHeight.md};

    ${props => props.theme.media.portrait`
        width: auto;
    `}
`;
Name.displayName = 'Name';

const NameColumn = ({ data }) => {

    return (
        <Name>{ data }</Name>
    );
};
NameColumn.displayName = 'NameColumn';

NameColumn.propTypes = {
    data: PropTypes.string.isRequired
};

export default NameColumn;
