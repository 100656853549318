import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DialogModal from 'components/Dialog/DialogModal';
import InputForm from 'components/Form/InputForm';
import ApiKeyModalFooter from './ApiKeyModalFooter';
import ApiKeyModalContent from './ApiKeyModalContent';
import ApiModalHeading from './ApiModalHeading';

const ApiKeyModal = ({ t, toggle, profileId, createApiKey }) => {
    const close = React.useCallback(() => toggle(false), [toggle]);
    const [model, setModel] = React.useState({ expires: moment().add(1, 'year').toDate() });
    const [saving, setSaving] = React.useState(false);
    const [createdKey, setCreatedKey] = React.useState();

    const handlePropertyChange = (property, value) => setModel({ ...model, [property]: value });

    const handleSubmit = async event => {
        event.stopPropagation();

        const apiKey = {
            name: model.name,
            expires: model.expires ? model.expires.toISOString() : undefined,
        };

        setSaving(true);
        const created = await createApiKey(profileId, apiKey);
        setSaving(false);

        if (created.result) {
            setCreatedKey(created.result);
        }
    };

    return (
        <InputForm
            id="generate-api-key-form"
            model={ model }
            onSubmit={ handleSubmit }
        >
            <DialogModal
                t={ t }
                animate
                isActive
                onOverlayClick={ close }
                footer={
                    <ApiKeyModalFooter
                        t={ t }
                        close={ close }
                        disabled={ !model.name || !model.expires }
                        created={ !!createdKey }
                    />
                }
            >
                <ApiModalHeading>{ t('Generate a new API key') }</ApiModalHeading>
                <ApiKeyModalContent
                    t={ t }
                    createdKey={ createdKey }
                    saving={ saving }
                    model={ model }
                    handlePropertyChange={ handlePropertyChange }
                />
            </DialogModal>
        </InputForm>
    );
};

ApiKeyModal.propTypes = {
    t: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    createApiKey: PropTypes.func.isRequired,
    profileId: PropTypes.string.isRequired,
};

export default ApiKeyModal;
