import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NameColumn from './NameColumn';
import ValueColumn from 'components/Conditions/ConditionTable/ValueColumn';
import StatusColumn from 'components/Conditions/ConditionTable/StatusColumn';
import AddonColumn from 'components/Conditions/ConditionTable/AddonColumn';

const Row = styled.tr`
    border-bottom: ${props => props.theme.spacing.border} solid ${props => props.theme.colors.alabaster};
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.font.size.xs};
    color: ${props => props.theme.colors.black};
    overflow: hidden;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        cursor: pointer;
        background: ${props => props.theme.colors.alabaster};
    }
`;
Row.displayName = 'Row';

const Cell = styled.td`
    width: ${props => props.width};
    padding: ${props => props.theme.spacing.lg} ${props => props.theme.spacing.sm};
    vertical-align: middle;
    display: ${props => props.hideOnMobile && 'none'};

    ${props => props.theme.media.landscape`
        display: ${props => props.hideOnDesktop ? 'none' : 'table-cell'};
    `}

    &:last-child {
        padding-right: initial;
        position: relative;
    }
`;
Cell.displayName = 'Cell';


const SensorRow = props => {
    const { rowData, t } = props;
    return <Row
        onClick={ typeof rowData.onClick === 'function' ? rowData.onClick : undefined }
        data-test-id={ props['data-test-id'] }
    >
        <Cell hideOnMobile>
            <NameColumn { ...rowData.name } />
        </Cell>
        <Cell hideOnMobile>
            <StatusColumn data={ rowData.status } />
        </Cell>
        <Cell hideOnDesktop width="70%">
            <NameColumn { ...rowData.name } />
            <StatusColumn data={ rowData.status } />
        </Cell>
        <Cell>
            <ValueColumn data={ rowData.value } />
        </Cell>
        <Cell columnWidth="50px" onClick={ event => event.stopPropagation() }>
            <AddonColumn data={ rowData.addon } t={ t } />
        </Cell>
    </Row>;
};

SensorRow.displayName = 'ConditionRow';

SensorRow.propTypes = {
    rowData: PropTypes.shape({
        name: PropTypes.object.isRequired,
        value: PropTypes.object.isRequired,
        status: PropTypes.object.isRequired,
        onClick: PropTypes.func,
    }).isRequired,
    t: PropTypes.func.isRequired,
    'data-test-id': PropTypes.string.isRequired
};

export default SensorRow;
