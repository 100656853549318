import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import InputLabel from 'components/Form/InputLabel';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import RadioButton from 'components/Form/RadioButton';
import DateTools, { DATE_RANGES } from 'components/Form/DateTools';
import { keys, map } from 'lodash';
import InfoBox from 'components/InfoBox/InfoBox';

/**
 * Constants
 */
export const GRANULARITY = {
    hourly: 'hourly',
    daily: 'daily',
    raw: 'raw'
};

/**
 * Styled components
 */
const Group = styled.div`
    ${props => props.row && css`
        display: flex;
        flex-flow: row nowrap;
    `}

    &:not(:last-child) {
        margin-bottom: ${props => props.theme.spacing.md};
    }
`;
Group.displayName = 'Group';

const Container = styled.div`
    max-width: 900px;
`;

/**
 * ExportSensorDataForm component
 */
const ExportSensorDataForm = ({
    t,
    model,
    onPropertyChange,
    granularityOptions,
    categoryOptions,
    sensorTypeOptions,
    sensorOptions,
}) => <Container>
    <Group>
        <InputLabel text={ t('Groups') } />
        <InputSelectDropdown
            placeholder={ t('All groups & floors') }
            label={ t('Groups') }
            showAllText={ t('Show all') }
            t={ t }
            onChange={ (property, value) => onPropertyChange(property, value.map(entry => entry.value)) }
            options={ categoryOptions }
            property="categoriesSelected"
            model={ model }
            multi
        />
    </Group>
    <Group>
        <InputLabel text={ t('Sensor types') } />
        <InputSelectDropdown
            placeholder={ t('All sensor types') }
            label={ t('Sensor types') }
            showAllText={ t('Show all') }
            t={ t }
            onChange={ (property, value) => onPropertyChange(property, value.map(entry => entry.value)) }
            options={ sensorTypeOptions }
            property="sensorTypesSelected"
            model={ model }
            multi
        />
    </Group>
    <Group>
        <InputLabel text={ t('Sensors') } />
        <InputSelectDropdown
            placeholder={ t('All sensors') }
            label={ t('Sensors') }
            showAllText={ t('Show all') }
            t={ t }
            onChange={ (property, value) => onPropertyChange(property, value.map(entry => entry.value)) }
            options={ sensorOptions }
            property="particularSensorsSelected"
            model={ model }
            multi
        />
    </Group>
    <Group>
        <DateTools
            alignment="left"
            t={ t }
            model={ model }
            onChange={ onPropertyChange }
            defaultRange={ DATE_RANGES.DAYS_7 }
            placement="bottom"
        />
    </Group>
    <Group>
        <RadioButtonGroup
            value={ model && model.granularity }
            onChange={ onPropertyChange }
            name="granularity"
        >
            { granularityOptions &&
                map(keys(granularityOptions), key =>
                    <RadioButton
                        key={ `granularity_${key}` }
                        label={ t(`Export ${granularityOptions[key]}`) }
                        value={ granularityOptions[key] }
                    />)
            }
        </RadioButtonGroup>
    </Group>
    <InfoBox>
        { t('EXPORT_INFO') }
    </InfoBox>
</Container>;

ExportSensorDataForm.propTypes = {
    // Translations function
    t: PropTypes.func.isRequired,
    // Form model
    model: PropTypes.shape({
        categoriesSelected: PropTypes.arrayOf(PropTypes.number),
        sensorTypesSelected: PropTypes.arrayOf(PropTypes.number),
        particularSensorsSelected: PropTypes.arrayOf(PropTypes.number),
        startDatetime: PropTypes.object,
        endDatetime: PropTypes.object,
        granularity: PropTypes.string
    }).isRequired,
    // Form property onchange function
    onPropertyChange: PropTypes.func.isRequired,
    // Form options for granularity
    granularityOptions: PropTypes.object.isRequired,
    // Available sensor hierarchies
    categoryOptions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number
    })).isRequired,
    // Available sensor types
    sensorTypeOptions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number
    })).isRequired,
    // Available sensors
    sensorOptions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number
    })).isRequired,
};

export default ExportSensorDataForm;
