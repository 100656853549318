import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import BlueprintMap from './BlueprintMap';
import BlueprintLegend from 'components/Blueprint/BlueprintLegend';
import FilterPills from 'components/FilterPills/FilterPills';
import FilterPill from 'components/FilterPills/FilterPill';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import SkeletonPill from 'components/Skeletons/SkeletonPill';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

const FilterPillsContainer = styled.div`
    margin-top: ${props => props.theme.spacing.sm};
    border-bottom: ${props => `${props.theme.spacing.border} solid ${props.theme.colors.lightGray}`};
`;

const FloorPlan = styled.div`
    position: relative;
    padding-bottom: ${props => props.imageSize && props.imageSize.height / props.imageSize.width * 100 + '%'};
    overflow: hidden;
`;

const FloorPlanInner = styled.div`
    position: ${props => props.isExpanded ? 'fixed' : 'absolute'};
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.colors.white};
    z-index: ${props => props.isExpanded && props.theme.zIndex('blueprint')};
`;

const FloorSelector = styled.div`
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    padding: ${props => props.theme.spacing.md};
    flex-flow: row nowrap;

    ${props => props.theme.media.landscape`
        display: ${props => props.isExpanded ? 'flex' : 'none'};
    `}
`;

const SkeletonFilter = () => <SkeletonPill width="110px" margin="8px 0px 0px" />;

class Blueprint extends Component {
    state = {
        scrollTo: null,
        featureGroupsVisible: []
    };

    componentDidMount() {
        // Format featureGroups to be visible by default
        const { featureGroups } = this.props;
        if (featureGroups && featureGroups.length > 0) {
            this.setState({
                featureGroupsVisible: featureGroups.map(() => true)
            });
        }
    }

    componentDidUpdate(prevProps) {
        // If featureGroups have changed, show all by default
        const { featureGroups } = this.props;
        if (featureGroups
            && featureGroups.length > 0
            && !_.isEqual(featureGroups, prevProps.featureGroups)) {
            this.setState({
                featureGroupsVisible: featureGroups.map(() => true)
            });
        }
    }

    toggleFilter = layer => {
        this.setState(prevState => {
            const visibility = !prevState.featureGroupsVisible[layer];
            const visibleLayers = prevState.featureGroupsVisible.slice();
            visibleLayers[layer] = visibility;
            return { featureGroupsVisible: visibleLayers };
        });
    };

    render() {
        const {
            showFilters,
            loading,
            icons,
            isExpanded,
            location,
            content,
            selectedTab,
            ...other
        } = this.props;

        if (loading) {
            return (
                <Fragment>
                    { showFilters && <FilterPillsContainer>
                        <FilterPills>
                            <SkeletonFilter />
                            <SkeletonFilter />
                            <SkeletonFilter />
                        </FilterPills>
                    </FilterPillsContainer> }
                    <SkeletonChart height="880px" width="100%">{ this.props.t('Loading data') }...</SkeletonChart>
                </Fragment>
            );
        }

        return (
            <Fragment>
                {!this.props.editMode && showFilters && this.props.featureGroupNames &&
                    <FilterPillsContainer>
                        <FilterPills>
                            { this.props.featureGroupNames.map((group, idx) => {
                                return <FilterPill
                                    selected={ this.state.featureGroupsVisible[idx] }
                                    onClick={ () => { this.toggleFilter(idx); } }
                                    key={'filter' + idx}
                                >
                                    { group }
                                </FilterPill>;
                            })}
                        </FilterPills>
                    </FilterPillsContainer>}
                <FloorPlan imageSize={ this.props.imageSize }>
                    <FloorPlanInner isExpanded={ isExpanded }>
                        { this.props.featureGroups && this.props.featureGroups.length > 0 || this.props.editMode
                            ? <Fragment>
                                <BlueprintMap
                                    {...other}
                                    featureGroupsVisible={ this.state.featureGroupsVisible }
                                    isExpanded={ isExpanded }
                                />
                                { content && content.length > 0 &&
                                    <FloorSelector isExpanded={ isExpanded }>
                                        { content.map((child, index) => {
                                            if (child) {
                                                const layer = child.title ? child.title.toLowerCase() : '';
                                                const selected = selectedTab === layer || !selectedTab && index === 0;
                                                return this.props.renderTabLabel(
                                                    child, index, selected, location, this.props.t, true
                                                );
                                            }
                                            return null;
                                        }) }
                                    </FloorSelector>
                                }
                            </Fragment>


                            : <img src={ this.props.image } alt={ this.props.title } width="100%" />
                        }
                    </FloorPlanInner>
                </FloorPlan>
                <BlueprintLegend t={ this.props.t } icons={ icons } />
            </Fragment>
        );
    }
}

Blueprint.defaultValues = {
    featureGroups: [],
    featureGroupNames: [],
    editMode: false,
    isExpanded: false,
    title: '',
    showFilters: false,
    loading: true,
    icons: [],
    content: [],
    selectedTab: 0,
};

Blueprint.propTypes = {
    // Image url
    image: PropTypes.string.isRequired,
    // Image size as { height, width }
    imageSize: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    }).isRequired,
    // Renders tab label JSX
    renderTabLabel: PropTypes.func.isRequired,
    // Translation function
    t: PropTypes.func.isRequired,
    // react-router location object
    location: PropTypes.object.isRequired,
    // Feature groups (containing areas, sensors, etc)
    featureGroups: PropTypes.array,
    // Feature group names
    featureGroupNames: PropTypes.array,
    // Edit mode allows drawing features
    editMode: PropTypes.bool,
    // State of full window view
    isExpanded: PropTypes.bool,
    // Image title
    title: PropTypes.string,
    // Show filters
    showFilters: PropTypes.bool,
    // Loading state
    loading: PropTypes.bool,
    // Icons
    icons: PropTypes.array,
    // Tab content
    content: PropTypes.array,
    // Currently selected tab index
    selectedTab: PropTypes.string,
};

export default withRouter(Blueprint);
