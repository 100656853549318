import React, { Fragment } from 'react';

import Section from 'components/Section/Section';
import SkeletonText from 'components/Skeletons/SkeletonText';
import SkeletonCircle from 'components/Skeletons/SkeletonCircle';
import { BuildingAdminCircleTabsContainer, BuildingAdminCircleTabs } from './BuildingAdminFloors';

const LoadingBuildingSensors = props => {

    return (
        <Fragment>
            <BuildingAdminCircleTabsContainer>
                <BuildingAdminCircleTabs>
                    <SkeletonCircle width="50px" borderWidth="2px" margin="0 12px" />
                    <SkeletonCircle width="50px" borderWidth="2px" margin="0 12px" />
                    <SkeletonCircle width="50px" borderWidth="2px" margin="0 12px" />
                    <SkeletonCircle width="50px" borderWidth="2px" margin="0 12px" />
                    <SkeletonCircle width="50px" borderWidth="2px" margin="0 12px" />
                </BuildingAdminCircleTabs>
            </BuildingAdminCircleTabsContainer>
            <Section>
                <SkeletonText />
            </Section>
            <Section>
                <SkeletonText />
            </Section>
        </Fragment>
    );
};

export default LoadingBuildingSensors;
