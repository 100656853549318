import { rem, rgba } from 'polished';

/*
    Sizing naming:
      extra extra extra small:  xxxs
      extra extra small:        xxs
      extra small:              xs
      small:                    sm
      medium:                   md
      large:                    lg
      extra large:              xl
      extra extra large         xxl
*/

// Default rem size
export const rootSize = 16;

/**
 * Colors
 * Please do not add new colors without asking the UI designer.
 * Use transparentize from polished or alpha channel for additional lighter colors, if needed.
 */
export const colors = {
    black: '#222222',
    abbey: '#4E545C', // softened black for form inputs
    white: '#FFFFFF',
    mystic: '#E7ECF1',
    geyser: '#D3DEEA',
    rockBlue: '#A2B3C9',
    kashmirBlue: '#446794',
    blue: '#002F6C',
    midnight: '#001F45',
    alabaster: '#F7F7F7', // Chart backgrounds
    silver: '#C3C3C3',
    lightGray: '#E6E6E6',
    darkGray: '#888E97', // Chart text labels & chart lines
    radicalRed: '#FF4656',
    orange: '#FF8F1C',
    yellow: '#FFCC00',
    emerald: '#3CC87A',
    cerulean: '#00B5E3',
    amethyst: '#8951cd',
    serenade: '#fff3e9',
    peachOrange: '#fec999',
    transparent: 'transparent',
};

export const display = {
    inline: 'inline',
    block: 'block',
    inlineBlock: 'inline-block',
    none: 'none'
};

export const font = {
    // Legacy
    sanchez: 'Sanchez-Regular',
    sanchezItalic: 'Sanchez-RegularItalic',
    sanchezExtraLight: 'Sanchez-Regular',
    sanchezSemiBold: 'Sanchez-Regular',
    arial: 'Arial, "Helvetica Neue", Helvetica, sans-serif',

    // Font families
    family: {
        sanchez: 'Sanchez-Regular',
        sanchezItalic: 'Sanchez-RegularItalic',
        sanchezExtraLight: 'Sanchez-Regular',
        sanchezSemiBold: 'Sanchez-Regular',
        arial: 'Arial, "Helvetica Neue", Helvetica, sans-serif'
    },
    // Font weights
    weight: {
        normal: 400,
        bold: 700
    },
    // Font sizes
    size: {
        root: rootSize + 'px',
        xxxs: rem(0.625 * rootSize),
        xxs: rem(0.75 * rootSize),
        xs: rem(0.875 * rootSize),
        sm: rem(rootSize),
        md: rem(1.25 * rootSize),
        lg: rem(1.5 * rootSize),
        xl: rem(1.75 * rootSize),
        xxl: rem(2 * rootSize)
    },
    // Line height
    lineHeight: {
        sm: 1,
        md: 1.25,
        lg: 1.375
    }
};

export const size = {
    // Available spacings
    spacing: {
        xxxs: rem(0.125 * rootSize),
        xxs: rem(0.25 * rootSize),
        xs: rem(0.5 * rootSize),
        sm: rem(0.75 * rootSize),
        md: rem(1 * rootSize),
        lg: rem(1.5 * rootSize),
        xl: rem(2 * rootSize),
        xxl: rem(3 * rootSize)
    },
    border: {
        sm: '1px',
        md: '2px',
        lg: '3px'
    }
};

export const grid = {
    gutter: size.spacing.lg
};

export const shadows = {
    default: `0 2px 5px ${rgba(colors.black, 0.10)}`,
    kpiCard: `0 5px 5px 2px ${rgba(colors.black, 0.08)}`,
    opiCard: `0 5px 5px 2px ${rgba(colors.black, 0.08)}`,
    meterBox: `0 5px 5px 2px ${rgba(colors.black, 0.08)}`
};

export const time = {
    quick: '150ms',
    default: '250ms',
    long: '1000ms'
};

export const text = {
    p: rem(rootSize),
    h1: rem(2.5 * rootSize),
    h2: rem(2 * rootSize),
    h3: rem(1.5 * rootSize),
    huge: rem(5 * rootSize),
    small: rem(0.75 * rootSize)
};

export const breakpoints = {
    portrait: 600,
    landscape: 900,
    desktop: 1280,
    bigDesktop: 1600
};

/**
 * Stacking order for component z-indexes from lowest to highest.
 * Use with zIndex() function that is included on the theme (usage example: zIndex('menu')).
 * If needed, z-index can be bumped up manually in local context: zIndex('menu') + 1
 * but try to avoid this.
 * Z-indexes below base z-index (100) are also free to use for local stacking adjustments.
 * Please try to keep all local adjustments as minimal as possible and below 10.
 */
export const zIndexKeys = [
    'filter',
    'hero',
    'sideNavi',
    'circleTabs',
    'header',
    'menu',
    'sideFilter',
    'infoTip',
    'contextualHelp',
    'announcements',
    'feedback',
    'blueprint',
    'tos',
    'snackbar',
    'modal',
    'snackbarError'
];

const baseZIndex = 100;

export const zIndex = key => {
    const keyIndex = zIndexKeys.indexOf(key);
    if (keyIndex > -1) {
        return baseZIndex + (keyIndex + 1) * 10;
    }
    return baseZIndex;
};

export const defaultColor = '#1a9edd';
