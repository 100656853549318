import { createReducerFromMapping } from 'redux/utils/index.js';

import { loadEquipments, loadSubEquipments } from 'redux/modules/customer/equipments.js';

const initialState = {
    // Make the default state loading so that the first load respects loading order via promise resolve
    loading: true,
    loadingMoreData: false,
    error: false
};

export const EQUIPMENTS_MODULE_LOAD = 'CUSTOMER_PLATFORM/EquipmentsModule/LOAD';
export const EQUIPMENTS_MODULE_LOAD_SUCCESS = 'CUSTOMER_PLATFORM/EquipmentsModule/LOAD_SUCCESS';
export const EQUIPMENTS_MODULE_LOAD_FAIL = 'CUSTOMER_PLATFORM/EquipmentsModule/LOAD_FAIL';

export const loadEquipmentsModule = functionalLocation => {
    return async dispatch => {
        dispatch({ type: EQUIPMENTS_MODULE_LOAD });
        try {
            const result = await dispatch(loadEquipments(functionalLocation.functionalLocation));
            const equipment = result.result;

            await equipment.forEach(item => {
                dispatch(loadSubEquipments(functionalLocation.functionalLocation, item.equipmentNumber));
            });

            return dispatch({ type: EQUIPMENTS_MODULE_LOAD_SUCCESS });
        } catch (error) {
            return dispatch({
                type: EQUIPMENTS_MODULE_LOAD_FAIL,
                error
            });
        }
    };
};

export default createReducerFromMapping({
    [EQUIPMENTS_MODULE_LOAD]: state => ({
        ...state,
        loading: true,
        loadingMoreData: true
    }),
    [EQUIPMENTS_MODULE_LOAD_SUCCESS]: state => ({
        ...state,
        loading: false,
        loadingMoreData: false
    }),
    [EQUIPMENTS_MODULE_LOAD_FAIL]: (state, action) => ({
        ...state,
        loading: false,
        loadingMoreData: false,
        error: action.error
    })
}, initialState);
