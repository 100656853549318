import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { includes, map, fromPairs, filter } from 'lodash';
import { connect } from 'react-redux';

import DialogModal from 'components/Dialog/DialogModal';
import InfoBox from 'components/InfoBox/InfoBox';
import InputBooleanCheckbox from 'components/Form/InputBooleanCheckbox';
import DialogFooter from 'components/Dialog/DialogFooter';
import Button from 'components/Button/Button';

import { saveProfile } from 'redux/modules/index.js';
import { CONFIGURATION_PROPERTY, OrderType } from 'constants/serviceCalendar';
import { notificationTimeout } from 'containers/Application/Admin/Building/AdminEditBuilding';

const List = styled.ul`
    margin: ${props => props.theme.spacing.md} 0 ${props => props.theme.spacing.sm};
`;

const ListItem = styled.li`
    border-bottom: 1px solid ${props => props.theme.colors.mystic};
    padding: ${props => props.theme.spacing.sm};
`;


const CalendarConfiguration = props => {
    const { t, showConfiguration, setShowConfiguration, configurationOptions, profile, saveProfile,
        setNotification, configuration, orderType } = props;

    const [model, setModel] = React.useState(fromPairs(map(configurationOptions, option =>
        [option.field, {
            label: option.label || option.title,
            selected: includes(configuration, option.field),
            hide: !option.title && !option.label,
            disabled: option.field === 'title',
        }]
    )));
    const [loading, setLoading] = React.useState(false);

    if (!showConfiguration) {
        return null;
    }

    const onClose = () => setShowConfiguration(false);
    const onSubmit = () => {
        setLoading(true);
        const newConfiguration = map(
            filter(configurationOptions, option => model[option.field] && model[option.field].selected),
            'field'
        );
        saveProfile(profile.id, { ...profile, [CONFIGURATION_PROPERTY[orderType]]: newConfiguration })
            .then(result => {
                if (result.error) {
                    setNotification({ type: 'error', visible: true, message: t('Save Failed') });
                }
                else {
                    setNotification({ type: 'success', visible: true, message: t('Saved') });
                }
                setTimeout(() => setNotification({ type: '', visible: false, message: '' }), notificationTimeout);
                setLoading(false);
                onClose();
            });
    };

    const handleCheckbox = (property, value) => {
        setModel({ ...model, [property]: { ...model[property], selected: value } });
    };

    return (
        <DialogModal isActive onOverlayClick={ onClose } t={ t } footer={
            <DialogFooter>
                <Button cancel onClick={ onClose }>{ t('Cancel') }</Button>
                <Button submit onClick={ onSubmit } loading={ loading }>{ t('Save settings') }</Button>
            </DialogFooter>
        }>
            <section>
                <h3>{ t('Select columns') }</h3>

                <List>
                    {map(model, (config, field) => {
                        if (config.hide) {
                            return null;
                        }

                        return (
                            <ListItem key={ field }>
                                <InputBooleanCheckbox
                                    id={ field }
                                    property={ field }
                                    onChange={ handleCheckbox }
                                    label={ config.label }
                                    value={ config.selected }
                                    disabled={ config.disabled }
                                />
                            </ListItem>
                        );
                    })}
                </List>

                <InfoBox>
                    { t('High number of columns might cause complexity or overlapping in the user interface. '
                    + 'Recommended amount of columns is max 5.') }
                </InfoBox>
            </section>
        </DialogModal>
    );
};

CalendarConfiguration.propTypes = {
    t: PropTypes.func.isRequired,
    showConfiguration: PropTypes.bool.isRequired,
    setShowConfiguration: PropTypes.func.isRequired,
    configurationOptions: PropTypes.array.isRequired,
    configuration: PropTypes.array.isRequired,
    profile: PropTypes.object.isRequired,
    saveProfile: PropTypes.func.isRequired,
    setNotification: PropTypes.func.isRequired,
    orderType: PropTypes.oneOf([OrderType.ORDER, OrderType.PLANNED]).isRequired,
};

const mapStateToProps = state => ({
    profile: state.profile.profile,
});

const mapDispatchToProps = {
    saveProfile,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CalendarConfiguration);
