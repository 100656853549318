import React from 'react';
import PropTypes from 'prop-types';
import { transparentize } from 'polished';
import styled from 'styled-components';
import Select from 'components/Select/Select';
import Svg from 'components/Svg/Svg';

const SelectWrapper = styled.div`
    position: absolute;
    top: ${props => props.theme.spacing.sm};
    right: 0;

    &&& {
        .Select-menu-outer {
            margin-top: -5px;
            right: -${props => props.theme.spacing.md};
            left: auto;

            &::after, &::before {
                right: 1.3rem;
            }
        }
        .Select-arrow-zone {
            right: 0;
            top: -2px;
            padding-right: 0;
        }
    }
`;

const SelectArrowBall = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.theme.spacing.xl};
    height: ${props => props.theme.spacing.xl};
    border-radius: 50%;

    &:hover {
        background-color: ${props => transparentize(0.8, props.theme.colors.cerulean)}
    }
`;

const Arrow = <SelectArrowBall><Svg name="fa-ellipsis-h" /></SelectArrowBall>;


const ActionColumn = ({ t, rowData }) => {
    const hasActions = rowData.actions
        && typeof rowData.actions.onEdit === 'function'
        && typeof rowData.actions.onDelete === 'function';

    if (!hasActions) {
        return null;
    }

    const handleSelect = value => {
        if (value === 'EDIT') {
            rowData.actions.onEdit(rowData.documentId || rowData.id);
        } else {
            rowData.actions.onDelete(rowData.documentId || rowData.id, rowData.name);
        }
    };

    return (
        <SelectWrapper>
            <Select
                lightBackground
                t={ t }
                onChange={ handleSelect }
                options={ [
                    { label: t('Edit'), value: 'EDIT' },
                    { label: t('Delete'), value: 'DELETE' }
                ] }
                customArrow={ Arrow }
                placeholder=" "
            />
        </SelectWrapper>
    );
};

ActionColumn.propTypes = {
    t: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired
};

export default ActionColumn;
