import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DateTools, { DATE_RANGES } from 'components/Form/DateTools';

const Tools = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: ${props => props.theme.colors.alabaster};
    border-top: 1px solid ${props => props.theme.colors.mystic};
    padding: ${props => props.theme.sensorValues.padding.mobile};

    ${props => props.theme.media.portrait`
        align-items: center;
        padding: ${props => props.theme.spacing.sm} ${props => props.theme.sensorValues.padding.tablet} ${props => props.theme.spacing.md};
    `}

    ${props => props.theme.media.desktop`
        padding: ${props => props.theme.spacing.sm} ${props => props.theme.sensorValues.padding.desktop} ${props => props.theme.spacing.md};
    `}
`;
Tools.displayName = 'Tools';


const SensorTools = props => {
    const {
        parameterModel,
        onParameterChange,
        t,
        defaultRange,
        sensorGranularity,
        isUtilizationRate,
    } = props;

    const disabledButtons = () => {
        if (isUtilizationRate) {
            return [DATE_RANGES.HOURS_24];
        }
        switch (sensorGranularity) {
        case 'month':
            return [DATE_RANGES.HOURS_24, DATE_RANGES.DAYS_7, DATE_RANGES.DAYS_30];
        case 'day':
            return [DATE_RANGES.HOURS_24];
        default:
            return undefined;
        }
    };


    return (
        <Tools>
            <DateTools
                t={ t }
                model={ parameterModel }
                onChange={ onParameterChange }
                defaultRange={ defaultRange }
                showArrows
                disabledButtons={ disabledButtons() }
            >
            </DateTools>
        </Tools>
    );
};

export default SensorTools;

SensorTools.defaultProps = {
    defaultRange: DATE_RANGES.DAYS_7,
};

SensorTools.propTypes = {
    // required:
    parameterModel: PropTypes.object.isRequired,
    onParameterChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    // optional:
    defaultRange: PropTypes.string,
    sensorGranularity: PropTypes.string,
    isUtilizationRate: PropTypes.bool,
};
