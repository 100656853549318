import { createReducerFromMapping } from 'redux/utils/index.js';

import {
    loadBuildingConditions,
    loadSensorValues,
    loadCleaning,
    loadLatestSensorsValues
} from 'redux/modules/iot/values.js';
import { loadSensorAlarms } from 'redux/modules/iot/sensorAlarms';

import {
    loadSensorHierarchies,
    loadSensorDataTypes,
} from 'redux/modules/customer/sensorHierarchy.js';
import { loadFunctionalLocationNotices, loadFunctionalLocationSla } from 'redux/modules/iot/notice.js';
import { loadFunctionalLocationAlarms } from 'redux/modules/iot/alarm.js';
import { loadAnnouncementsByFL } from 'redux/modules/announcement/announcement';
import { loadBuildingMeta } from 'redux/modules/buildingConfig/buildingMeta';
import { loadPartnerMeta } from 'redux/modules/customer/partnerMeta';

import moment from 'moment-timezone';
import _ from 'lodash';
import { getBuildingSensorsFromHierarchy } from 'utils/Data/sensorHierarchy';

const initialState = {
    // Make the default state loading so that the first load respects loading order via promise resolve
    loading: true,
    error: false
};

export const BUILDING_CONTAINER_LOAD = 'CUSTOMER_PLATFORM/BuildingContainer/LOAD';
export const BUILDING_CONTAINER_LOAD_SUCCESS = 'CUSTOMER_PLATFORM/BuildingContainer/LOAD_SUCCESS';
export const BUILDING_CONTAINER_LOAD_FAIL = 'CUSTOMER_PLATFORM/BuildingContainer/LOAD_FAIL';

export const loadBuildingContainer = (partnerNumber, functionalLocation, startDate, endDate, features) => {
    const yearAgo = moment.utc().subtract(1, 'year').startOf('day');
    const nextYearEnd = moment.utc().add(1, 'year').endOf('year').endOf('day');

    return async dispatch => {
        dispatch({ type: BUILDING_CONTAINER_LOAD });
        try {
            dispatch(loadSensorHierarchies(functionalLocation.functionalLocation))
                // Only load the latest values for the sensors in the building container.
                .then(res => {
                    if (res.error) {
                        return console.error('Error with sensor hierarchies', res.error);
                    }

                    // Dispatch success here to start rendering
                    dispatch({ type: BUILDING_CONTAINER_LOAD_SUCCESS });
                    const sensors = getBuildingSensorsFromHierarchy(_.head(res.result));

                    const cleaningSensors = _.filter(sensors, sensor =>
                        sensor.sensorType && sensor.sensorType.name === 'cleaning') || [];

                    const utilizationTypes = [
                        'cooling utilization',
                        'heating utilization',
                        'ventilation utilization',
                        'outdoor temperature'
                    ];
                    const utilizationSensors = _.filter(sensors, sensor =>
                        sensor.sensorType && utilizationTypes.indexOf(sensor.sensorType.name) !== -1);

                    const conditionsSensors = _.filter(sensors, sensor =>
                        sensor.sensorType && sensor.sensorType.name === 's2');

                    if (cleaningSensors.length > 0 && features.cleaningTab) {
                        dispatch(loadCleaning(_.map(cleaningSensors, 'id'), startDate, endDate));
                    }
                    if (utilizationSensors.length > 0 && features.buildingAutomationTab) {
                        dispatch(loadBuildingConditions(
                            functionalLocation.functionalLocation,
                            utilizationSensors,
                            startDate,
                            endDate
                        ));
                    }
                    if (conditionsSensors.length > 0 && features.buildingAutomationTab) {
                        dispatch(loadSensorValues(_.map(conditionsSensors, 'id'), startDate, endDate, 'hourlyAverage'));
                    }

                    if (sensors.length > 0) {
                        const aggregationGroups = _.groupBy(
                            sensors, sensor => _.get(sensor, 'sensorType.latestValueAggregation.aggregation')
                        );

                        _.entries(aggregationGroups).forEach(([aggregation, aggregationSensors]) => {
                            if (aggregation !== 'undefined') {
                                const sensorIds = aggregationSensors.map(sensor => sensor.id);
                                dispatch(loadLatestSensorsValues(sensorIds, aggregation));
                            }
                        });
                    }

                    if (features.floorsTab) {
                        const presenceSensors = _.filter(
                            sensors,
                            sensor => sensor.sensorType && _.startsWith(sensor.sensorType.name, 'presence')
                        );
                        dispatch(
                            loadSensorValues(_.map(presenceSensors, 'id'), startDate, endDate, 'hourlyUtilizationRate')
                        );
                    }
                });

            if (features.controlRoomTab) {
                dispatch(loadFunctionalLocationNotices(functionalLocation, yearAgo, nextYearEnd));
                dispatch(loadFunctionalLocationSla(functionalLocation));
                dispatch(loadFunctionalLocationAlarms(functionalLocation, yearAgo));
            }

            dispatch(loadSensorDataTypes());
            features.announcementsTab && dispatch(loadAnnouncementsByFL(functionalLocation.functionalLocation));
            dispatch(loadBuildingMeta([functionalLocation.functionalLocation]));
            dispatch(loadPartnerMeta(partnerNumber));
            features.conditions && dispatch(loadSensorAlarms());
        } catch (error) {
            return dispatch({
                type: BUILDING_CONTAINER_LOAD_FAIL,
                error
            });
        }
    };
};

export default createReducerFromMapping({
    [BUILDING_CONTAINER_LOAD]: state => ({
        ...state,
        loading: true
    }),
    [BUILDING_CONTAINER_LOAD_SUCCESS]: state => ({
        ...state,
        loading: false
    }),
    [BUILDING_CONTAINER_LOAD_FAIL]: (state, action) => ({
        ...state,
        loading: false,
        error: action.error
    })
}, initialState);
