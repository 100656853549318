import React, { Component } from 'react';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { RichText } from 'prismic-reactjs';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';

import { getSectionTitleText, generateCopiedUrl, getSectionSubjectText, SLICE_TYPE } from './utils/UserManualUtils';
import Icon from 'components/Icon/Icon';
import { ICON_SIZES } from 'components/Icon/Icon';
import { icons } from 'components/Icon/IconNames';

const StyledUserManualContent = styled.div`
    align-items: baseline;
    margin: ${props => props.theme.spacing.md} 0;
    width: 100%;

    h2 {
        font-size: ${props => props.theme.font.size.xl};
    }

    img {
        border: solid ${props => props.theme.spacing.md} ${props => props.theme.colors.lightGray};
        max-width: 100%;
        max-height: 100%;
        margin: ${props => props.theme.spacing.md} 0;
    }
`;

const StyledUserManualContentContainer = styled.div`
    line-height: 1.5;
    margin-bottom: ${props => props.theme.spacing.xl};

    h3 {
        font-size: ${props => props.theme.font.size.md};
        line-height: ${props => props.theme.font.lineHeight.md};
    }

    ul {
        list-style: disc;
        margin-left: ${props => props.theme.spacing.xl};
    }

    ol {
        margin-left: ${props => props.theme.spacing.xl};
    }

    ul, ol {
        margin-top: 0.9rem;
        margin-bottom: 0.9rem;
    }
`;

StyledUserManualContentContainer.displayName = 'StyledUserManualContentContainer';

const StyledUserManualContentHeader = styled.div`
    display: flex;
    align-items: center;
    margin: ${props => props.theme.spacing.md} 0;
`;
StyledUserManualContentHeader.displayName = 'StyledUserManualContentHeader';

const StyledUserManualContentText = styled.div`
    h4, h5, h6 {
        font-size: 1.10rem;
        margin: ${props => props.theme.spacing.md} 0;
    }

    p {
        margin: 0.9rem 0;
    }
`;
StyledUserManualContentText.displayName = 'StyledUserManualContentText';

const StyledCopyLink = styled.div`
    width: auto;
    background-color: transparent;
    color: ${props => props.theme.colors.cerulean};
    border-radius: 25px;
    margin-left: ${props => props.theme.spacing.md};
    font-size: ${props => props.theme.font.size.xxxs};
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    padding: ${props => props.theme.spacing.xxs} ${props => props.theme.spacing.sm};
    text-transform: uppercase;
    font-family: ${props => props.theme.font.family.arial};
    font-weight: ${props => props.theme.font.weight.bold};

    &:hover {
        cursor: pointer;
        background-color: ${props => props.theme.colors.cerulean};
        color: ${props => props.theme.colors.white};
    }

    svg {
        margin-right: 4px;
    }
`;

StyledCopyLink.displayName = 'StyledCopyLink';

export class UserManualContent extends Component {
    state = { copiedUrl: '' };

    render() {
        const { data, t } = this.props;
        const { copiedUrl } = this.state;

        if (data && data.body) {
            return (
                <StyledUserManualContent>
                    {data.body.map((singleSectionContent, index) => {
                        switch (singleSectionContent.slice_type) {
                        case SLICE_TYPE.SECTION_SUBJECT: {
                            const subject = getSectionSubjectText(singleSectionContent);
                            return <StyledUserManualContentText id={subject} key={index}>
                                {RichText.render(singleSectionContent.primary.contentSubject)}
                            </StyledUserManualContentText>;
                        }
                        case SLICE_TYPE.CONTENT_SECTION: {
                            const title = getSectionTitleText(singleSectionContent);
                            return (
                                <StyledUserManualContentContainer id={title} key={index}>
                                    <StyledUserManualContentHeader>
                                        {RichText.render(singleSectionContent.primary.contentTitle)}
                                        <CopyToClipboard
                                            text={copiedUrl}
                                            onCopy={() =>
                                                this.setState({ copiedUrl: generateCopiedUrl(title) })
                                            }>
                                            <StyledCopyLink>
                                                <Icon name={icons.LINK_SOLID} size={ICON_SIZES.SMALL} />
                                                {t('copy link')}
                                            </StyledCopyLink>
                                        </CopyToClipboard>
                                    </StyledUserManualContentHeader>
                                    <StyledUserManualContentText>
                                        {RichText.render(singleSectionContent.primary.content)}
                                    </StyledUserManualContentText>
                                </StyledUserManualContentContainer>
                            );
                        }
                        default:
                            return null;
                        }
                    })}
                </StyledUserManualContent>
            );
        }
    }
}

UserManualContent.propTypes = {
    data: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

export default translations(UserManualContent);
