import styled, { css } from 'styled-components';
import { rgba } from 'polished';

const EventsRow = styled.tr`
    border-bottom: ${props => props.theme.spacing.border} solid ${props => props.theme.colors.mystic};
    font-family: ${props => props.theme.fontFamily.text};
    font-size: ${props => props.theme.fontSize.xs};

    ${props => props.highlight && css`
        background-color: ${rgba(props.theme.colors.cerulean, 0.05)};
    `};

    ${props => props.theme.media.landscape`
        ${props => (props.onClick || props.link) && css`
            cursor: pointer;

            &:hover {
                background-color: ${props => props.theme.colors.alabaster};
            }
        `};
    `}
`;

export default EventsRow;
