import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import TypeColumn from './TypeColumn';
import NameColumn from './NameColumn';
import StatusColumn from './StatusColumn';
import ValueColumn from './ValueColumn';
import AddonColumn from './AddonColumn';

const Row = styled.tr`
    border-bottom: ${props => props.theme.spacing.border} solid ${props => props.theme.colors.alabaster};
    overflow: hidden;

    &:last-child {
        border-bottom: none;
    }

    ${props => props.hasHover && `
        &:hover {
            cursor: pointer;
            background: ${props.theme.colors.alabaster};

            svg {
                cursor: pointer;
            }
        }

    `}
`;
Row.displayName = 'Row';

const Cell = styled.td`
    position: relative;
    width: ${props => props.columnWidth};
    padding: ${props => !props.noPaddingOnMobile && `${props.theme.spacing.md} 0`};
    display: ${props => props.hideOnMobile && 'none'};
    vertical-align: middle;
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.font.size.xs};
    color: ${props => props.theme.colors.black};

    &:first-child { padding-left: ${props => props.theme.spacing.sm}; }

    ${props => props.theme.media.landscape`
        display: ${props => props.hideOnDesktop ? 'none' : 'table-cell'};
        padding: ${props => props.theme.spacing.xs};

        &:first-child { padding-left: initial; }
        &:last-child { padding-right: initial; }
    `}
`;
Cell.displayName = 'Cell';

const StyledLink = styled(Link)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
`;
StyledLink.displayName = 'StyledLink';

const Linked = props => {
    return props.link
        ? <Fragment><StyledLink to={ props.link } />{ props.children }</Fragment>
        : props.children;
};


const ConditionRow = props => {
    const { rowData, t, depth, theme } = props;
    const hasOnClick = typeof rowData.onClick === 'function' && !rowData.link;
    const hasHover = typeof rowData.onClick === 'function' || rowData.link;
    const onClick = hasOnClick ? rowData.onClick : undefined;
    const addonOnClick = rowData.addon.alarm
        ? event => event.stopPropagation() : undefined;

    return [
        <Row onClick={ onClick } hasHover={ hasHover } data-test-id={ props['data-test-id'] } key={ rowData.key }>
            <Cell hideOnDesktop>
                <Linked link={ rowData.link }>
                    <NameColumn data={ rowData.name } onIconClick={ onClick } depth={ depth } theme={ theme }/>
                    <StatusColumn data={ rowData.status } depth={ depth } /><TypeColumn data={ rowData.type } />
                </Linked>
            </Cell>
            <Cell hideOnMobile>
                <Linked link={ rowData.link }>
                    <NameColumn data={ rowData.name } onIconClick={ onClick } depth={ depth } theme={ theme } />
                </Linked>
            </Cell>
            <Cell hideOnMobile>
                <Linked link={ rowData.link }>
                    <TypeColumn data={ rowData.type } />
                </Linked>
            </Cell>
            <Cell hideOnMobile>
                <Linked link={ rowData.link }>
                    <StatusColumn data={ rowData.status } />
                </Linked>
            </Cell>
            <Cell>
                <Linked link={ rowData.link }>
                    <ValueColumn data={ rowData.value } />
                </Linked>
            </Cell>
            <Cell columnWidth="50px" onClick={ addonOnClick }>
                <Linked link={ rowData.link }>
                    <AddonColumn data={ rowData.addon } onIconClick={ onClick } t={ t } />
                </Linked>
            </Cell>
        </Row>,
        ...(rowData.children || []).map(child =>
            <ConditionRow { ...props } key={ child.key } rowData={ child } depth={ depth + 1 } />
        ),
    ];
};

ConditionRow.displayName = 'ConditionRow';

ConditionRow.propTypes = {
    rowData: PropTypes.object.isRequired,
    depth: PropTypes.number,
};

ConditionRow.defaultProps = {
    depth: 0,
};

export default withTheme(ConditionRow);
