import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import styled from 'styled-components';
import { includes, omit } from 'lodash';
import queryString from 'query-string';

import InputText from 'components/Form/InputText';
import translations from 'decorators/Translations/translations';
import {
    searchServiceOrdersAndPlanned,
    loadFunctionalLocations,
    addLoading,
    removeLoading,
    setServiceOrderSearchText,
} from 'redux/modules';
import CalendarListView from 'components/Calendar/CalendarListView';
import ServiceOrderRow from 'components/Calendar/ServiceOrderRow';
import * as utils from '../../utils';
import { getFormattedSearchResults } from 'redux/modules/service/selectors/search';
import { getUniqueFunctionalLocations } from 'utils/Data/serviceOrders';
import Section from 'components/Section/Section';
import PlainSection from 'components/Section/PlainSection';
import Button, { BUTTON_MIN_WIDTH } from 'components/Button/Button';
import { findPartnerNumberForFL } from 'utils/Data/partners';

const COMPONENT = 'ServiceOrders/Search';

const getColumns = memoizeOne(t => [{
    title: t('Order'),
    field: 'title',
    sortable: true,
    width: '35%',
    mobileWidth: 'auto',
}, {
    title: t('Created'),
    field: 'createdDate',
    sortable: true,
    align: 'left',
    width: '140px',
    mobileWidth: '135px',
}, {
    title: t('Updated'),
    field: 'modifiedDate',
    sortable: true,
    width: '140px',
    align: 'left',
    hideOnMobile: true,
}, {
    title: t('Location'),
    field: 'location',
    sortable: true,
    hideOnMobile: true,
}, {
    title: '',
    field: 'link',
    sortable: false,
    width: '20px',
    hideOnMobile: true,
}]);

const StyledSection = styled(Section)`
    margin-top: 0;

    ${props => props.theme.media.portrait`
        margin-top: ${props => props.theme.spacing.lg};
    `}
`;

const StyledPlainSection = styled(PlainSection)`
    margin-bottom: 0;
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;

    ${props => props.theme.media.portrait`
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        & > div:first-child {
            width: ${props => `calc(100% - ${BUTTON_MIN_WIDTH}px - ${props.theme.spacing.lg})`};
        }
    `}
`;

const StyledButton = styled(Button)`
    margin-top: ${props => props.theme.spacing.md};

    ${props => props.theme.media.portrait`
        margin-top: 0;
    `}
`;

const HeadingContainer = styled.div`
    margin: ${props => props.theme.spacing.sm} 0;
    display: inline-flex;
    flex-direction: column;
`;

const HeadingDetails = styled.span`
    font-size: ${props => props.theme.font.size.xs};
    margin-top: ${props => props.theme.spacing.sm};
    padding: ${props => props.theme.spacing.md};
    border: ${props => props.theme.input.border.error};
    border-radius: 0.25em;
    background-color: ${props => props.theme.status.alarmLightColor};

    ${props => props.theme.media.portrait`
        margin-top: ${props => props.theme.spacing.md};
    `}
`;

export const Search = ({
    t,
    location,
    history,
    match,
    query,
    section,
    text,
    loading,
    total,
    results,
    profile,
    searchServiceOrdersAndPlanned,
    loadFunctionalLocations,
    functionalLocations,
    addLoading,
    removeLoading,
    setServiceOrderSearchText,
}) => {
    const { functionalLocationId } = match.params;

    const partnerNumber = !functionalLocationId || match.params.partnerNumber && match.params.partnerNumber !== 'all'
        ? match.params.partnerNumber : findPartnerNumberForFL(functionalLocations[functionalLocationId], profile);

    const search = async text => {
        try {
            const { result } = await searchServiceOrdersAndPlanned(
                section,
                text,
                partnerNumber && partnerNumber !== 'all' ? partnerNumber : undefined,
                functionalLocationId
            );
            const uniqueFLs = getUniqueFunctionalLocations(result.items);
            addLoading(section, COMPONENT);
            await loadFunctionalLocations(uniqueFLs);
            removeLoading(section, COMPONENT);
        }
        catch (error) {
            //
        }
    };

    React.useEffect(() => {
        if (query.search) {
            const nextQuery = queryString.stringify(omit(query, 'search'));
            setServiceOrderSearchText(section, query.search);
            history.replace(`${location.pathname}?${nextQuery}`);
            search(query.search);
        }
    }, []);

    const handleSubmit = async event => {
        event.preventDefault();

        if (query.page) {
            history.replace(`${location.pathname}?${queryString.stringify(omit(query, 'page'))}`);
        }

        search(text);
    };

    return (
        <React.Fragment>
            <StyledPlainSection>
                <StyledForm onSubmit={ handleSubmit }>
                    <InputText
                        placeholder={ t('Search by name or ID') }
                        icon="magnifier"
                        value={ text }
                        onChange={ (_, value) => setServiceOrderSearchText(section, value) }
                        inputProps={{ spellCheck: false }}
                    />
                    <StyledButton submit loading={ loading }>{ t('Search') }</StyledButton>
                </StyledForm>
            </StyledPlainSection>
            { results &&
                <StyledSection>
                    <HeadingContainer>
                        <h4>{ loading ? t('Searching...') : `${total} ${t('Results Found')}` }</h4>
                        { total > results.length &&
                            <HeadingDetails>
                                { t('Showing first {0} results. Please refine the search.', results.length) }
                            </HeadingDetails>
                        }
                    </HeadingContainer>
                    <CalendarListView
                        loading={ loading }
                        serviceOrders={ results }
                        columns={ getColumns(t) }
                        RowComponent={ rowProps => <ServiceOrderRow columns={ getColumns(t) } { ...rowProps } /> }
                        showServiceOrder={ utils.showServiceOrder(location, query, history) }
                        showNoOrders={ false }
                        profile={ profile }
                    />
                </StyledSection>
            }
        </React.Fragment>
    );
};

Search.propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    query: PropTypes.object.isRequired,
    section: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    total: PropTypes.number,
    results: PropTypes.arrayOf(PropTypes.object),
    profile: PropTypes.object.isRequired,
    searchServiceOrdersAndPlanned: PropTypes.func.isRequired,
    loadFunctionalLocations: PropTypes.func.isRequired,
    addLoading: PropTypes.func.isRequired,
    removeLoading: PropTypes.func.isRequired,
    setServiceOrderSearchText: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const section = utils.getSectionKey(ownProps.match);
    const loadingFLs = includes(state.common.loading[COMPONENT], section);
    const params = { loadingFLs, section };
    return {
        query: utils.parseQuery(ownProps.location.search),
        section,
        text: state.serviceOrders.search[section] ? state.serviceOrders.search[section].text : '',
        loading: state.serviceOrders.search[section] ? state.serviceOrders.search[section].loading : undefined,
        total: state.serviceOrders.search[section] ? state.serviceOrders.search[section].total : undefined,
        results: getFormattedSearchResults(state, params),
        profile: state.profile.profile,
        functionalLocations: state.functionalLocations.functionalLocations,
    };
};

const mapDispatchToProps = {
    searchServiceOrdersAndPlanned,
    loadFunctionalLocations,
    addLoading,
    removeLoading,
    setServiceOrderSearchText,
};

export default translations(connect(mapStateToProps, mapDispatchToProps)(Search));
