import React, { Component, Fragment } from 'react';
import { last, clone } from 'lodash';

import MaintenanceModule from 'containers/Application/Modules/MaintenanceModule/MaintenanceModule.jsx';
import FunctionalLocationsModule from 'components/Modules/FunctionalLocationsModule/FunctionalLocationsModule.jsx';
import EquipmentModule from 'containers/Application/Modules/EquipmentModule/EquipmentModule.jsx';
import ExternalDocumentModule from 'containers/Application/Modules/ExternalDocumentModule/ExternalDocumentModule.jsx';
import Hero from 'components/Hero/Hero.jsx';
import ServiceRequest from 'containers/Application/ServiceRequest/ServiceRequest';

import { Tabs, Tab } from 'components/index.js';
import Header from 'containers/Application/Header/Header.jsx';
import {
    disciplines,
    addServiceOrderLink,
    addTechnicalLink,
    addEquipmentLink,
    addExternalDocumentsLink,
    addNewServiceRequestLink
} from 'utils/Data/functionalLocations';
import { isServiceOrdersEnabled, isTechnicalTabEnabled, isEquipmentEnabled } from 'utils/Data/profileData';

class TechnicalSystem extends Component {

    render() {
        const {
            t, loadingParent, loadingEquipment,
            features,
            featureTeasers,
            functionalLocation,
            equipments,
            subEquipments,
            partnerNumber,
            parents,
            documentCount,
            loadingDocuments,
            equipmentCount,
            totalFunctionalLocations,
            loadingFunctionalLocations,
            buildingImage,
            loadingChildren,
            childFunctionalLocations,
            page,
            match: { url },
            profile,
        } = this.props;
        const discipline = last(functionalLocation.functionalLocation.split('-'));
        const type = disciplines.indexOf(discipline) !== -1 ? discipline : 'EXT';

        let links = [];
        links = addServiceOrderLink(links, url, features);
        links = addTechnicalLink(links, url, features, functionalLocation,
            totalFunctionalLocations, loadingFunctionalLocations, featureTeasers);
        links = addEquipmentLink(links, url, features, equipmentCount, loadingEquipment, featureTeasers);
        links = addExternalDocumentsLink(links, url, features, documentCount, loadingDocuments, featureTeasers);

        let linksMobile = clone(links);
        linksMobile = addNewServiceRequestLink(linksMobile, url, features);

        const heroContext = this.props.getHeroContext(parents, partnerNumber, features, profile.customViews);
        const context = Object.assign({}, heroContext, { type, loadingContext: loadingParent, functionalLocation });

        const tabs = [];

        if (features && features.documents) {
            tabs.push(
                <Tab
                    title="External Documents"
                    key="External Documents"
                    count={ documentCount }
                    loading={ loadingDocuments }
                    disabled={ !(documentCount > 0) }>
                    <ExternalDocumentModule functionalLocation={ functionalLocation } />
                </Tab>
            );
        }

        if (isEquipmentEnabled(features) && equipments) {
            tabs.push(
                <Tab title="Equipment" key="Equipment">
                    <EquipmentModule
                        loadingEquipment={ loadingEquipment }
                        equipments={ equipments[functionalLocation.functionalLocation] }
                        subEquipments={ subEquipments }
                    />
                </Tab>
            );
        }

        if (features && features.sr) {
            tabs.push(
                <Tab title="New Service Request" key="New Service Request">
                    <ServiceRequest />
                </Tab>
            );
        }

        if (isServiceOrdersEnabled(features)) {
            tabs.push(
                <Tab title="Service Orders" key="Service Orders">
                    <MaintenanceModule functionalLocation={ functionalLocation } partnerNumber={ partnerNumber } />
                </Tab>
            );
        }

        if (isTechnicalTabEnabled(features)) {
            tabs.push(
                <Tab title="Technical" key="Technical">
                    <FunctionalLocationsModule
                        t={ t }
                        loadingChildren={ loadingChildren }
                        childFunctionalLocations={ childFunctionalLocations }
                        partnerNumber={ partnerNumber }
                        page={ page }
                    />
                </Tab>
            );
        }

        return (
            <Fragment>
                <Header context={ context } t={ t } links={ links } linksMobile={ linksMobile } />
                <Hero
                    title={ functionalLocation.description }
                    functionalLocation={ functionalLocation }
                    type={ type }
                    loadingContext={ loadingParent }
                    t={ t }
                    showAddress
                    showNewServiceRequest={ features && features.sr }
                    partnerNumber={ partnerNumber }
                    heroImage={ buildingImage }
                />
                <Tabs t={ t }>
                    { tabs }
                </Tabs>
            </Fragment>
        );
    }
}

export default TechnicalSystem;
