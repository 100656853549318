import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import themes from 'styles/themes';

// margin-top is a hack to align the box alongside of the charts
const StyledValuesBox = styled.div`
    box-shadow: ${props => props.theme.shadows.default};
    border-radius: 0.25rem;
    overflow: hidden;
    margin-bottom: ${props => props.theme.spacing.sm};
    &:first-child {
        margin-top: 57px;
    }
`;
StyledValuesBox.displayName = 'StyledValuesBox';

const Wrapper = styled.div`
    border-radius: 0.25rem;
    border-color: ${props => props.theme.colors.lightGray};
    border-style: solid;
    border-width: ${({ theme }) => `0 ${theme.spacing.border} ${theme.spacing.border}`};
`;

const Title = styled.div`
    background-color: ${props => props.theme.colors.blue};
    font-size: ${props => props.theme.fontSize.xxxs};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.white};
    /* Make background go on top of box borders */
    margin: 0 -${props => props.theme.spacing.border};
    text-transform: uppercase;
    letter-spacing: 1.4px;
    text-align: center;
    line-height: 1rem;
    padding: 0.5rem;
`;
Title.displayName = 'Title';

const ValueWrapper = styled.div`
    padding: ${props => props.theme.spacing.md};
    text-align: center;
    line-height: 1;
`;
ValueWrapper.displayName = 'ValueWrapper';

const Value = styled.div`
    font-family: ${props => props.theme.font.sanchez};
    font-size: ${props => props.theme.fontSize.lg};
    color: ${props => props.valueIsNegative ? props.theme.colors.radicalRed : props.positiveColor};
`;
Value.displayName = 'Value';

const Label = styled.div`
    margin-top: ${props => props.theme.spacing.xxs};
    color: ${props => props.theme.colors.rockBlue};
    font-size: ${props => props.theme.fontSize.xxs};
    font-weight: ${props => props.theme.fontWeight.bold};
`;
Label.displayName = 'Label';

const ValuesBox = ({
    heading,
    value,
    valueIsNegative,
    label,
    children,
    positiveValueColor,
    ...others
}) => {
    return (
        <StyledValuesBox {...others}>
            <Wrapper>
                { heading && <Title>{ heading }</Title> }
                { (value || label) &&
                    <ValueWrapper>
                        { value && <Value valueIsNegative={ valueIsNegative } positiveColor={ positiveValueColor }>
                            { value }
                        </Value> }
                        { label && <Label>{ label }</Label> }
                    </ValueWrapper>
                }
                { children }
            </Wrapper>
        </StyledValuesBox>
    );
};

ValuesBox.defaultProps = {
    heading: '',
    value: '',
    label: '',
    valueIsNegative: false,
    positiveValueColor: themes.customerPlatform.colors.emerald
};

ValuesBox.propTypes = {
    /** Heading for the box */
    heading: PropTypes.string,
    /** Value with unit */
    value: PropTypes.string,
    /** Value label */
    label: PropTypes.string,
    /** Boolean to indicate value's negative sense in the context of UI */
    valueIsNegative: PropTypes.bool,
    /** Color to be used in positive context */
    positiveValueColor: PropTypes.string,
    children: PropTypes.any
};

export default ValuesBox;
