import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ContextualHelp from 'components/ContextualHelp/ContextualHelp';

const StyledSectionHeader = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${props => props.theme.spacing.md} 0;

    ${props => props.theme.media.portrait`
        flex-direction: row;
        justify-content: space-between;
    `}

    ${props => props.theme.media.landscape`
        border-bottom: ${props => props.noBorder ? 'none' : `${props.theme.spacing.border} solid ${props.theme.colors.lightGray}`};
        margin-bottom: ${props => !props.noBorder && props.theme.spacing.xl};
        flex-direction: row;
        justify-content: space-between;
    `}
`;
StyledSectionHeader.displayName = 'StyledSectionHeader';

const SectionTitle = styled.h2`
    font-size: ${props => props.theme.fontSize.title};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: ${props => props.theme.lineHeight.heading};
`;
SectionTitle.displayName = 'SectionTitle';

const StyledSectionDescription = styled.div`
    display: flex;
`;

const SectionDescriptionText = styled(SectionTitle)`
    font-size: ${props => props.theme.font.size.md};
    color: ${props => props.theme.colors.darkGray};
    font-family: ${props => props.theme.font.family.arial};
    font-weight: ${props => props.theme.font.weight.normal};
`;

SectionDescriptionText.displayName = 'SectionDescriptionText';

const SectionDescriptionValue = styled(SectionDescriptionText)`
    color: ${props => props.theme.colors.black};
    margin-left: ${props => props.theme.spacing.xs};
`;

SectionDescriptionValue.displayName = 'SectionDescriptionValue';


const SectionHeader = props => {
    const { t, title, children, ctxHelp, ctxHelpOverride, descriptionText, descriptionValue } = props;

    const sectionDescription = (descriptionText, descriptionValue) =>
        <StyledSectionDescription>
            {descriptionText && <SectionDescriptionText >{descriptionText}:</SectionDescriptionText>}
            {descriptionValue && <SectionDescriptionValue>{descriptionValue}</SectionDescriptionValue>}
        </StyledSectionDescription>;

    return (
        <StyledSectionHeader {...props}>
            { title && <SectionTitle data-test-id={ `SectionHeader ${title}` }>
                { title }
                { ctxHelp && <ContextualHelp
                    t={ t }
                    title={ title }
                    text={ ctxHelp }
                    override={ !!ctxHelpOverride }
                    position="right" />}
            </SectionTitle> }
            {(descriptionText || descriptionValue) && sectionDescription(descriptionText, descriptionValue)}
            { children }
        </StyledSectionHeader>
    );
};

SectionHeader.defaultProps = {
    title: '',
    descriptionText: undefined,
    descriptionValue: undefined,
    noBorder: false,
};

SectionHeader.propTypes = {
    t: PropTypes.func,
    title: PropTypes.string,
    ctxHelp: PropTypes.string,
    ctxHelpOverride: PropTypes.bool,
    children: PropTypes.node,
    descriptionText: PropTypes.string,
    descriptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    noBorder: PropTypes.bool,
};

export default SectionHeader;
