import React, { createRef, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Blueprint from 'components/Blueprint/Blueprint';
import { InputRow, InputLabel, InputText, InputSelectDropdown, InputSearch } from 'components/index.js';
import InputCircleSelect from 'components/Form/InputCircleSelect';
import Checkbox from 'components/Form/Checkbox';
import RadioButton from 'components/Form/RadioButton';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import DialogModal from 'components/Dialog/DialogModal';
import DialogFooter from 'components/Dialog/DialogFooter';
import Button from 'components/Button/Button';

import { SAVE_TYPES, FORM_TEXTS, SENSOR_APPEARANCES } from './utils';

const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
`;
Form.displayName = 'Form';

const Title = styled.h3``;
Title.displayName = 'Title';

const Subtitle = styled.h4`
    width: 100%;
    font-family: ${props => props.theme.font.family.arial};
    font-weight: ${props => props.theme.font.weight.bold};
    font-size: ${props => props.theme.font.size.sm};
    margin-top: ${props => props.theme.spacing.md};
    &:first-child {
        margin-top: ${props => props.theme.spacing.lg};
    }
`;
Subtitle.displayName = 'Subtitle';

const FullRow = styled.span`
    width: 100%;
    margin: ${props => props.theme.spacing.md} 0;
`;
FullRow.displayName = 'FullRow';

const CheckboxContainer = styled.div`
    display: inline-block;
    line-height: ${props => props.theme.spacing.xxl};
`;
CheckboxContainer.displayName = 'CheckboxContainer';

const StyledSection = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: ${props => props.withBlueprint && props.theme.spacing.md};
`;
StyledSection.displayName = 'StyledSection';

const InfoText = styled.p`
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.font.size.xxs};
    color: ${props => props.theme.colors.darkGray};
    margin: ${props => props.theme.spacing.md} 0;
`;
InfoText.displayName = 'InfoText';

const ShowAdvancedText = styled.a`
    display: block;
    width: 100%;
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.font.size.xs};
    font-weight: ${props => props.theme.font.weight.bold};
    color: ${props => props.theme.colors.cerulean};
    margin: ${props => props.theme.spacing.md} 0;
`;
ShowAdvancedText.displayName = 'ShowAdvancedText';

const AdvancedOptions = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;
AdvancedOptions.displayName = 'AdvancedOptions';

const NarrowInputRow = styled(InputRow)`
    width: 100%;

    ${props => props.theme.media.portrait`
        width: 50%;
        &:nth-child(odd) {
            padding-right: ${props => props.theme.spacing.md};
        }
    `}

    ${props => props.theme.media.landscape`
        flex: 1;
        padding-right: ${props => props.theme.spacing.md};
    `}
`;

const WideInputRow = styled(InputRow)`
    width: 100%;

    ${props => props.theme.media.portrait`
        width: 50%;
    `}

    ${props => props.theme.media.landscape`
        flex: 2;
    `}
`;

const SensorConfiguration = props => {
    const {
        appearance,
        t,
        isCreateMode,
        isSapEquipment,
        onCancel,
        onSubmit,
        onFormChange,
        model,
        loading,
        floorOptions,
        groupOptions,
        areaOptions,
        sensorTypeOptions,
        sensorPresenceTypeOptions,
        floorplanValueOptions,
        showPresenceType,
        showSigfoxId,
        subsensorOptions,
        granularityOptions,
        featureCollection,
        image,
        coordsEditMode,
        setCoordinates,
        hasFloors,
        searchingSigfoxId,
        sigfoxIdSearchResult,
        parentSaveType,
        sigfoxTypeOptions,
    } = props;

    const [advancedOpen, setAdvancedOpen] = useState(false);

    const formNode = createRef();
    const texts = FORM_TEXTS(appearance, t);

    const blueprintNode = useRef(null);
    const locationRowNode = useRef(null);
    const scrollToNode = node => {
        if (node && node.current && typeof node.current.scrollIntoView === 'function') {
            // Short timeout to allow component to render
            setTimeout(() => {
                node.current.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
    };
    const onEnableCoordsChange = e => {
        onFormChange(
            'saveType',
            e.target.checked ? SAVE_TYPES.TO_COORDS : SAVE_TYPES.TO_FLOOR
        );
        scrollToNode(blueprintNode);
    };
    const onLocationChange = (...props) => {
        onFormChange(...props);
        scrollToNode(locationRowNode);
    };

    const isAreaPresence = model.sensorTypeId === sensorPresenceTypeOptions.area;

    const AreaConfig = <React.Fragment>
        <InputLabel text={ t('Area')} id="areaDropdown" />
        <InputSelectDropdown
            model={ model }
            property="area"
            options={ areaOptions }
            t={ t }
            id="areaDropdown"
            onChange={ onFormChange }
            menuPortalTarget={ formNode }
        />
    </React.Fragment>;

    const isSigfoxIdOk = !!model.sigfoxId && !searchingSigfoxId && !sigfoxIdSearchResult;
    const isSubSensor = !!model.parentId;

    return (
        <DialogModal extraLarge animate isActive onOverlayClick={onCancel} t={ t } footer={
            <DialogFooter>
                <Button cancel onClick={onCancel}>{ t('Cancel') }</Button>
                <Button
                    submit
                    onClick={onSubmit}
                    type="submit"
                    data-test-id="CustomViewSubmitButton"
                    loading={loading}
                    disabled={ showSigfoxId && !isSigfoxIdOk }
                >
                    { t('Save changes') }
                </Button>
            </DialogFooter>
        }>
            <section>
                <Title>
                    { isCreateMode ? texts.addTitle : texts.editTitle }
                </Title>
                <Form ref={ formNode }>
                    <Subtitle>{ texts.informationSubtitle }</Subtitle>
                    { appearance === SENSOR_APPEARANCES.device &&
                        <FullRow>
                            <RadioButtonGroup
                                name="isSigfox"
                                value={ showSigfoxId }
                                onChange={ onFormChange }
                                row
                            >
                                <RadioButton
                                    label={ t('Sigfox') }
                                    value={ true }
                                    name="isSigfox"
                                />
                                <RadioButton
                                    label={ t('Other') }
                                    value={ false }
                                    name="isSigfox"
                                />
                            </RadioButtonGroup>
                        </FullRow>
                    }
                    <NarrowInputRow fullRow required>
                        <InputLabel
                            text={ showSigfoxId ? texts.useCase : texts.sensorTypeDropdown }
                            id="sensorTypeDropdown"
                        />
                        <InputSelectDropdown
                            model={ model }
                            property="sensorTypeId"
                            options={ showSigfoxId ? sigfoxTypeOptions : sensorTypeOptions }
                            t={ t }
                            clearable={ false }
                            id="sensorTypeDropdown"
                            onChange={ onFormChange }
                            menuPortalTarget={ formNode }
                        />
                    </NarrowInputRow>
                    { showPresenceType &&
                        <NarrowInputRow fullRow>
                            <InputLabel text={ t('Presence type') } />
                            <RadioButtonGroup
                                name="sensorTypeId"
                                value={ model.sensorTypeId }
                                onChange={ onFormChange }
                                row
                            >
                                <RadioButton
                                    label={ t('Seat') }
                                    value={ sensorPresenceTypeOptions.seat }
                                />
                                <RadioButton
                                    label={ t('Zone') }
                                    value={ sensorPresenceTypeOptions.zone }
                                />
                                <RadioButton
                                    label={ t('For custom shape area') }
                                    value={ sensorPresenceTypeOptions.area }
                                />
                            </RadioButtonGroup>
                        </NarrowInputRow>
                    }
                    { showSigfoxId &&
                        <NarrowInputRow key="sigfoxId" fullRow>
                            <InputLabel
                                text={ t('Sigfox id') }
                                id="sigfoxId"
                                errorText={ sigfoxIdSearchResult && `This ID already exists in ${sigfoxIdSearchResult}` }
                            />
                            <InputSearch
                                id="sigfoxId"
                                onChange={ value => onFormChange('sigfoxId', value) }
                                initialValue={ model.sigfoxId }
                                noMagnifier
                                hasError={ !!sigfoxIdSearchResult }
                                loading={ searchingSigfoxId }
                                ok={ isSigfoxIdOk }
                            />
                        </NarrowInputRow>
                    }
                    <NarrowInputRow required fullRow>
                        <InputLabel text={ texts.sensorName } id="sensorName" />
                        <InputText
                            model={ model }
                            property="name"
                            id="sensorName"
                            onChange={ onFormChange }
                        />
                    </NarrowInputRow>
                    { areaOptions && areaOptions.length > 0 && isAreaPresence &&
                        <InputRow required>
                            { AreaConfig }
                        </InputRow>
                    }
                    { groupOptions && groupOptions.length > 0 &&
                        <WideInputRow fullRow>
                            <InputLabel text={ t('Group')} id="groupDropdown" />
                            <InputSelectDropdown
                                model={ model }
                                property="groups"
                                options={ groupOptions }
                                t={ t }
                                id="groupDropdown"
                                onChange={ onFormChange }
                                multi
                                menuPortalTarget={ formNode }
                            />
                        </WideInputRow>
                    }
                    <ShowAdvancedText href="#" onClick={ () => setAdvancedOpen(!advancedOpen) }>
                        { advancedOpen ? `${t('Hide Advanced Options')} -` : `${t('Show Advanced Options')} +` }
                    </ShowAdvancedText>
                    { advancedOpen && <AdvancedOptions>
                        <InputRow>
                            <InputLabel text={ t('Granularity')} id="sensorGranularityDropdown" />
                            <InputSelectDropdown
                                model={ model }
                                property="granularity"
                                options={ granularityOptions }
                                t={ t }
                                clearable={ true }
                                id="sensorGranularityDropdown"
                                onChange={ onFormChange }
                                menuPortalTarget={ formNode }
                            />
                        </InputRow>
                        { areaOptions && areaOptions.length > 0 && !showPresenceType &&
                            <InputRow>
                                { AreaConfig }
                            </InputRow>
                        }
                        { subsensorOptions && subsensorOptions.length > 0 && <InputRow key="default">
                            <InputLabel text={ t('Default subsensor') } id="sensorDefaultDropdown" />
                            <InputSelectDropdown
                                model={ model }
                                property="default"
                                options={ subsensorOptions }
                                t={ t }
                                clearable={ true }
                                id="sensorDefaultDropdown"
                                onChange={ onFormChange }
                                menuPortalTarget={ formNode }
                            />
                        </InputRow> }
                        <InputRow key="equipmentNumber">
                            <InputLabel text={ t('Equipment number') } id="equipmentNumber" />
                            <InputText
                                model={ model }
                                property="equipmentNumber"
                                id="equipmentNumber"
                                onChange={ onFormChange }
                            />
                        </InputRow>
                        <InputRow key="functionalLocation">
                            <InputLabel text={ t('Functional location') } id="functionalLocation" />
                            <InputText
                                model={ model }
                                property="functionalLocation"
                                id="functionalLocation"
                                onChange={ onFormChange }
                                disabled={ isSapEquipment }
                            />
                        </InputRow>
                        <InputRow>
                            <InputLabel text={ t('Synthetic') } id="type" />
                            <CheckboxContainer>
                                <Checkbox
                                    id="type"
                                    label={ texts.type }
                                    checked={ model.type === 'synthetic' }
                                    onChange={ e => onFormChange('type', e.target.checked ? 'synthetic' : null) }
                                />
                            </CheckboxContainer>
                        </InputRow>
                    </AdvancedOptions> }
                    <div ref={ locationRowNode }></div>
                    <Subtitle>{ texts.saveType }</Subtitle>
                    <FullRow>
                        <RadioButtonGroup
                            name="saveType"
                            value={ parentSaveType || model.saveType }
                            onChange={ onLocationChange }
                            row
                        >
                            <RadioButton
                                label={ t('Building (top of hierarchy)') }
                                value={ SAVE_TYPES.TO_BUILDING }
                                name="saveType"
                                disabled={ isSubSensor || showPresenceType }
                            />
                            { hasFloors &&
                                <RadioButton
                                    label={ t('Floor level') }
                                    value={ model.saveType === SAVE_TYPES.TO_COORDS
                                        ? SAVE_TYPES.TO_COORDS
                                        : SAVE_TYPES.TO_FLOOR
                                    }
                                    name="saveType"
                                    disabled={ isSubSensor || showPresenceType }
                                />
                            }
                        </RadioButtonGroup>
                    </FullRow>
                    { floorOptions && floorOptions.length > 0 &&
                    <FullRow>
                        <InputLabel text={ t('Select Floor Level') } id="sensorFloor" />
                        <InputCircleSelect
                            model={ model }
                            property="floor"
                            options={ floorOptions }
                            t={ t }
                            id="sensorFloor"
                            onChange={ onFormChange }
                            disabled={ isSubSensor || showPresenceType }
                        />
                    </FullRow> }
                    { (model.saveType === SAVE_TYPES.TO_COORDS ||
                      model.saveType === SAVE_TYPES.TO_FLOOR ||
                      parentSaveType === SAVE_TYPES.TO_FLOOR) && !isAreaPresence &&
                        <CheckboxContainer>
                            <Checkbox
                                id="saveType_coords_checkbox"
                                checked={ model.saveType === SAVE_TYPES.TO_COORDS }
                                onChange={ onEnableCoordsChange }
                                label={ t('Add to Floorplan') }
                                labelSize="large"
                                disabled={ showPresenceType }
                            />
                        </CheckboxContainer>
                    }
                    <div ref={ blueprintNode }></div>
                    { model.saveType === SAVE_TYPES.TO_COORDS &&
                        <StyledSection withBlueprint>
                            { !isCreateMode && floorplanValueOptions && floorplanValueOptions.length > 0 &&
                                <InputRow>
                                    <InputLabel text={ t('Values displayed on floorplan')} id="floorplanValuesDropdown" />
                                    <InputSelectDropdown
                                        model={ model }
                                        property="coordsSubsensors"
                                        options={ floorplanValueOptions }
                                        t={ t }
                                        id="groupDropdown"
                                        onChange={ onFormChange }
                                        multi
                                        menuPortalTarget={ formNode }
                                    />
                                </InputRow>
                            }
                            <InfoText>{ texts.blueprintInfo }</InfoText>
                            { image && image.path
                                ? <Blueprint
                                    key={ image.path }
                                    t={ t }
                                    image={ image.path }
                                    imageSize={{ width: image.width, height: image.height }}
                                    editMode={ coordsEditMode }
                                    handleNewPoint={ setCoordinates }
                                    featureGroups={ featureCollection }
                                />
                                : <div>{ t('Image not found') }</div>
                            }
                        </StyledSection>
                    }
                </Form>
            </section>
        </DialogModal>
    );
};

SensorConfiguration.propTypes = {
    appearance: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    setCoordinates: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onFormChange: PropTypes.func.isRequired,
    isCreateMode: PropTypes.bool,
    isSapEquipment: PropTypes.bool,
    loading: PropTypes.bool,
    floorOptions: PropTypes.array,
    groupOptions: PropTypes.array,
    areaOptions: PropTypes.array,
    sensorTypeOptions: PropTypes.array.isRequired,
    sensorPresenceTypeOptions: PropTypes.object.isRequired,
    floorplanValueOptions: PropTypes.array,
    showPresenceType: PropTypes.bool,
    showSigfoxId: PropTypes.bool,
    subsensorOptions: PropTypes.array,
    granularityOptions: PropTypes.array.isRequired,
    featureCollection: PropTypes.array,
    model: PropTypes.object.isRequired,
    image: PropTypes.object,
    coordsEditMode: PropTypes.oneOf([true, 'point']),
    hasFloors: PropTypes.bool,
    parentSaveType: PropTypes.string,
    sigfoxTypeOptions: PropTypes.array.isRequired,
    searchingSigfoxId: PropTypes.bool,
    sigfoxIdSearchResult: PropTypes.string
};

SensorConfiguration.defaultProps = {
    coordsEditMode: 'point',
    image: null,
    featureCollection: [],
    floorOptions: [],
    areaOptions: [],
    subsensorOptions: [],
    groupOptions: [],
    showPresenceType: false,
    showSigfoxId: false,
    hasFloors: false
};

export default SensorConfiguration;

