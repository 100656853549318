import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Svg from 'components/Svg/Svg';

const StyledAnnouncementButton = styled.span`
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    padding: 5px;
    pointer-events: auto;
    cursor: pointer;

    &:hover {
        > * {
            transform: scale(1.15);
            transition: all ${props => props.theme.motion.quick}
                ${props => props.theme.motion.easing};
        }
    }

    ${props => props.theme.media.portrait`
        display: flex;
    `}
`;
StyledAnnouncementButton.displayName = 'StyledButton';

const Icon = styled(Svg)`
    fill: ${props => props.theme.colors.white};
    font-size: 25px;
`;
Icon.displayName = 'Icon';

const IconContainer = styled.div`
    position: relative;
    height: 25px;
`;

export const AnnouncementButton = ({ onClick, icon, children }) =>
    <StyledAnnouncementButton onClick={onClick} data-test-id='AnnouncementButton'>
        <IconContainer>
            <Icon name={icon} />
            {children}
        </IconContainer>
    </StyledAnnouncementButton>;

AnnouncementButton.displayName = 'AnnouncementButton';

AnnouncementButton.defaultProps = {
    children: {}
};

AnnouncementButton.propTypes = {
    children: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
};
