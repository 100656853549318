import React, { Fragment, useRef, useCallback } from 'react';
import { get, find, isNil, isObjectLike, isArray } from 'lodash';
import Select from 'react-select';
import styled, { keyframes } from 'styled-components';
import 'react-select/dist/react-select.css';

const spinnerSpin = keyframes`
    from    { transform:rotate(0deg);   }
    to      { transform:rotate(360deg); }
`;

const StyledSelect = styled(Select)`
    &&& {
        min-width: 5em;

        &.is-open > .Select-control .Select-arrow {
            border-bottom-color: ${props => props.theme.input.font.color.default};
        }
        .Select-menu-outer {
            z-index: 2;
            border-radius: 0;
            box-shadow: ${props => props.theme.input.shadow.active};
            border: ${props => props.theme.input.border.active};
            .Select-menu {
                .Select-option, .Select-noresults {
                    padding: ${props => props.theme.input.padding};
                    font-size: ${props => props.theme.input.font.mobileSize};
                    font-family: ${props => props.theme.input.font.family};
                    font-weight: ${props => props.theme.font.weight.normal};
                    line-height: ${props => props.theme.input.lineHeight.default};
                    color: ${props => props.theme.input.font.color.default};
                    font-style: ${props => props.disabled ? 'italic' : ''};
                    &:last-child {
                        border-radius: none;
                    }
                    &.is-selected {
                        font-weight: ${props => props.theme.font.weight.bold};
                        color: ${props => props.theme.input.font.color.default};
                    }

                    ${props => props.theme.media.landscape`
                        font-size: ${props => props.theme.input.font.size};
                    `}
                }
                .Select-noresults {
                    color: ${props => props.theme.input.font.color.placeholder};
                }
            }
        }
        &&& {
            .Select-input, .Select-placeholder, .Select-value-label {
                font-size: ${props => props.theme.input.font.mobileSize};
                font-family: ${props => props.theme.input.font.family};
                line-height: ${props => props.theme.input.lineHeight.default};

                ${props => props.theme.media.landscape`
                    font-size: ${props => props.theme.input.font.size};
                `}
            }
            .Select-value-label {
                color: ${props => props.theme.input.font.color.default};
            }
            .Select-placeholder {
                color: ${props => props.theme.input.font.color.placeholder};
                padding: ${props => props.theme.input.padding};
            }
            .Select-input {
                width: auto;
                height: ${props => props.multi ? '3rem' : 'auto'};
                padding: ${props => props.multi ? '1rem' : 0};
                & > input {
                    height: auto;
                    padding: 0;
                    line-height: ${props => props.theme.input.lineHeight.default};
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
            .Select-arrow-zone {
                padding-right: 0;
                text-align: right;
                height: 1rem;
            }
            .Select-arrow {
                border-top-color: ${props => props.theme.input.font.color.default};
            }
        }
        &.is-disabled > .Select-control{
            background-color: ${props => props.theme.input.background.disabled};
            cursor: not-allowed;
        }
        .Select-control {
            min-width: 100%;
            height: auto;
            background-color: ${props => props.theme.input.background.default};
            padding: ${props => props.theme.input.padding};
            ${props => props.multi && 'padding-top: 0; padding-bottom: 0; padding-left: 0;'}
            border: ${props => props.theme.input.border.static};
            border-radius: 0;
            box-shadow: ${props => props.theme.input.shadow.static};
            transition: ${props => props.theme.input.transition};
            &:hover, &:focus {
                box-shadow: ${props => props.theme.input.shadow.active};
                border: ${props => props.theme.input.border.active};
            }
            .Select-value {
                height: auto;
                padding: ${props => props.multi ? props.theme.spacing.xxs : props.theme.input.padding};
                margin: ${props => props.multi && '10px 0 0 5px'};
                line-height: ${props => props.theme.input.lineHeight.default};
            }
        }
        .Select-loading {
            border: 3px solid ${props => props.theme.colors.blue};
            border-right-color: transparent;
            animation: ${spinnerSpin} 0.5s linear infinite;
        }
        .Select-clear-zone .Select-clear {
            font-size: ${props => props.theme.font.size.sm};
            color: ${props => props.theme.colors.rockBlue};
        }
    }
`;
StyledSelect.displayName = 'StyledSelect';

export const InputSelectDropdown = props => {
    const ref = useRef();
    const handleMenuOpen = useCallback(() => ref.current.scrollIntoView(), []);

    const value = props.value || get(props.model, props.property);
    const selected = !isNil(value)
        ? isArray(value)
            ? value
            : find(props.options, ['value', isObjectLike(value) ? Object.keys(value)[0] : value]) || value
        : '';

    return (
        <Fragment>
            <div ref={ ref } />
            <StyledSelect
                defaultMenuIsOpen={true}
                name={ props.id }
                value={ selected }
                required={ props.required }
                disabled={ props.disabled }
                clearable={ props.clearable }
                filterOptions={ props.filterOptions }
                onInputChange={ value => props.onInput ? props.onInput(value) : undefined }
                onChange={ selectedOption => props.onChange(
                    props.property,
                    props.multi ? selectedOption : selectedOption ? selectedOption.value : ''
                ) }
                options={ props.options }
                placeholder={ props.placeholder || `${props.t('Select')}...` }
                noResultsText={ props.noResultsText || props.t('No results found') }
                clearValueText={ props.t('Clear value') }
                isLoading={ props.loading }
                onBlurResetsInput={ false }
                multi={ props.multi }
                menuPortalTarget={ props.menuPortalTarget }
                onOpen={ props.scrollToMenu ? handleMenuOpen : undefined }
            />
        </Fragment>
    );
};
InputSelectDropdown.displayName = 'InputSelectDropdown';

export default InputSelectDropdown;
