import moment from 'moment';
import fill from 'lodash/fill';
import pick from 'lodash/pick';
import entries from 'lodash/entries';
import fromPairs from 'lodash/fromPairs';
import toInteger from 'lodash/toInteger';
import isEqual from 'lodash/isEqual';
import countBy from 'lodash/countBy';
import sum from 'lodash/sum';
import sortBy from 'lodash/sortBy';
import isNil from 'lodash/isNil';
import constant from 'lodash/constant';
import times from 'lodash/times';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import trim from 'lodash/trim';
import lowerCase from 'lodash/lowerCase';
import round from 'lodash/round';
import memoizeOne from 'memoize-one';
import queryString from 'query-string';

import { FILTERABLE_FIELDS, DEFAULT_PRIORITY, GROUP_UNKNOWN } from 'constants/serviceCalendar';
import { serviceStatusSortOrder } from 'utils/Data/resolveServiceOrderStatus';

export const getLoadingKey = (...args) => args.join(':');

/**
 * Parse query (memoized)
 */
export const parseQuery = memoizeOne(search => {
    return queryString.parse(search);
});

/**
 * Get selected months from query.
 */
export const getSelectedMonths = memoizeOne(query => {
    const months = Array.isArray(query.months) ?
        query.months :
        query.months ? [query.months] : [];

    return sortBy(months.map(toInteger));
}, (a, b) => isEqual(a[0].months, b[0].months));

/**
 * Get active filter from query.
 */
export const getActiveFilter = memoizeOne(query => {
    return pick(query, FILTERABLE_FIELDS);
}, (a, b) => isEqual(pick(a[0], FILTERABLE_FIELDS), pick(b[0], FILTERABLE_FIELDS)));

/**
 * Get service order / planned maintenance counts for each month.
 */
export const getMonthlyCounts = orders => {
    const months = fill(Array(12), 0);
    orders.forEach(order => {
        const date = order.meta.filterable.date;
        if (!isNaN(date)) {
            months[date.getMonth()] += 1;
        }
    });
    return months;
};

/**
 * Get service order / planned maintenance counts by status.
 */
export const getStatusCounts = memoizeOne(serviceOrders => {
    if (serviceOrders) {
        const countsByStatus = countBy(serviceOrders, 'status');
        const counts = serviceStatusSortOrder.map(status => countsByStatus[status] || 0);
        const totalStatusCounts = sum(counts);
        const total = serviceOrders.length;
        return { counts, totalStatusCounts, total, countsByStatus };
    }
    return null;
});

const divide = (a, b) => b === 0 ? 0 : a / b;

const newPriorityStatistics = () => ({
    sampleSize: 0,
    monthlySampleSize: times(12, constant(0)),
    totalHours: 0,
    monthlyHours: times(12, constant(0)),

    slaSampleSize: 0,
    monthlySlaSampleSize: times(12, constant(0)),
    slaCount: 0,
    monthlySlaCounts: times(12, constant(0)),
});

const newStatistics = () => ({ 'all': newPriorityStatistics() });

// mutates stats
const addHourStatistics = (stats, priority, hours, month) => {
    stats[priority].sampleSize++;
    stats[priority].monthlySampleSize[month]++;
    stats[priority].totalHours += hours;
    stats[priority].monthlyHours[month] += hours;
};

// mutates stats
const addSlaStatistics = (stats, priority, isUnderSla, month) => {
    stats[priority].slaSampleSize++;
    stats[priority].monthlySlaSampleSize[month]++;
    isUnderSla && stats[priority].slaCount++;
    isUnderSla && stats[priority].monthlySlaCounts[month]++;
};

// mutates stats
// Calculates monthlyHourAverages and monthlySlaPercents for every priority
export const addAverages = stats => {
    forEach(stats, statsForPriority => {
        statsForPriority.monthlyHourAverages = map(
            statsForPriority.monthlyHours,
            (hours, index) => divide(hours, statsForPriority.monthlySampleSize[index])
        );
        statsForPriority.monthlySlaPercents = map(
            statsForPriority.monthlySlaCounts,
            (count, index) => divide(count, statsForPriority.monthlySlaSampleSize[index])
        );
    });
};

/**
 * Get reaction time, downtime, and lead time statistics by priority and 'all'.
 */
export const getResponseStatistics = memoizeOne((serviceOrders, responseSLAs) => {
    if (serviceOrders) {
        // gather hour and sla data by priority
        const statistics = serviceOrders.reduce((accu, order) => {
            const priority = order.priority || DEFAULT_PRIORITY;
            const reactionTimeSLA = responseSLAs && responseSLAs.reactionTime && responseSLAs.reactionTime[priority];
            const downtimeSLA = responseSLAs && responseSLAs.downtime && responseSLAs.downtime[priority];
            const leadTimeSLA = responseSLAs && responseSLAs.leadTime && responseSLAs.leadTime[priority];
            const month = moment(order.createdDate).month();

            // add new priority to statistics
            if (!accu.reactionTimeStatistics[priority]) {
                accu.reactionTimeStatistics[priority] = newPriorityStatistics();
                accu.downtimeStatistics[priority] = newPriorityStatistics();
                accu.leadTimeStatistics[priority] = newPriorityStatistics();
            }

            if (!isNil(order.reactionTime)) {
                addHourStatistics(accu.reactionTimeStatistics, priority, order.reactionTime, month);
                addHourStatistics(accu.reactionTimeStatistics, 'all', order.reactionTime, month);

                if (!isNil(reactionTimeSLA)) {
                    addSlaStatistics(accu.reactionTimeStatistics, priority,
                        order.reactionTime <= reactionTimeSLA, month);
                    addSlaStatistics(accu.reactionTimeStatistics, 'all', order.reactionTime <= reactionTimeSLA, month);
                }
            }

            if (!isNil(order.downtime)) {
                addHourStatistics(accu.downtimeStatistics, priority, order.downtime, month);
                addHourStatistics(accu.downtimeStatistics, 'all', order.downtime, month);

                if (!isNil(downtimeSLA)) {
                    addSlaStatistics(accu.downtimeStatistics, priority, order.downtime <= downtimeSLA, month);
                    addSlaStatistics(accu.downtimeStatistics, 'all', order.downtime <= downtimeSLA, month);
                }
            }

            if (!isNil(order.leadTime)) {
                addHourStatistics(accu.leadTimeStatistics, priority, order.leadTime, month);
                addHourStatistics(accu.leadTimeStatistics, 'all', order.leadTime, month);

                if (!isNil(leadTimeSLA)) {
                    addSlaStatistics(accu.leadTimeStatistics, priority, order.leadTime <= leadTimeSLA, month);
                    addSlaStatistics(accu.leadTimeStatistics, 'all', order.leadTime <= leadTimeSLA, month);
                }
            }

            return accu;
        }, {
            reactionTimeStatistics: newStatistics(),
            downtimeStatistics: newStatistics(),
            leadTimeStatistics: newStatistics(),
        });

        // add averages
        addAverages(statistics.reactionTimeStatistics);
        addAverages(statistics.downtimeStatistics);
        addAverages(statistics.leadTimeStatistics);

        return statistics;
    }
    return {};
});

/**
 * Get planned maintenance counts by synthetic status. Synthetic status is added in the selector, and replaces statuses
 * from SAP with more detailed statuses when possible.
 */
export const getSyntheticStatusCounts = memoizeOne(serviceOrders => countBy(serviceOrders, 'meta.syntheticStatus'));

/**
 * Get label for OPI card based on current date range (year / month(s) / day)
 */
export const getDateRangeLabel = (t, year, months, day) => {
    if (day) {
        return moment().year(year).month(months[0] - 1).date(day).format('MMMM Do, YYYY');
    }

    if (!months.length) {
        return year.toString();
    }

    if (months.length === 1) {
        return `${t(moment().month(months[0] - 1).format('MMMM'))}, ${year}`;
    }

    if (months.length <= 3) {
        const monthNames = moment.localeData().monthsShort();
        return months
            .map(month => t(monthNames[month - 1]))
            .concat([year])
            .join(', ');
    }

    return `${t('Selected months')}, ${year}`;
};

export const orderStatusLabels = {
    Y20: 'Repair',
    Y02: 'Planned maintenance',
    Y10: 'Modification work',
    Y30: 'Warranty case',
    Y01: 'Inspection',
    Y44: 'FM Work at tenants',
    Y07: 'Long term plan work',
    Y43: 'FM Additional work',
    tampuuriPlanned: 'Planned maintenance (Tampuuri)',
    tampuuriOrder: 'Repair (Tampuuri)',
};

export const optionLabelOverrides = {
    type: (value, { t }) => t(orderStatusLabels[value] || value),
    status: (value, { t }) => t(value),
    source: {
        sap: 'SAP',
        tampuuri: 'Tampuuri',
        planon: 'Planon',
    },
    location: (value, { functionalLocations }) => {
        const functionalLocation = functionalLocations[value];
        return functionalLocation ?
            [functionalLocation.name, functionalLocation.description].filter(part => part).join(', ') :
            value;
    },
    discipline: (value, { t }) => t(value),
};


/**
 * Get options for filter dropdowns.
 *
 * Should return all distinct values for filter fields. If location is selected, addresses should be filtered by that.
 */
export const getFilterOptions = memoizeOne((filteredOrders, allOrders, activeFilter, functionalLocations, t) => {
    const options = FILTERABLE_FIELDS.reduce((acc, filter) => ({
        ...acc,
        [filter]: new Set(),
    }), {});

    FILTERABLE_FIELDS.forEach(field => {
        if (field === 'address' && activeFilter.location) {
            filteredOrders.forEach(order => {
                const value = order.meta.filterable[field];
                if (value) {
                    options[field].add(value);
                }
            });
        } else {
            allOrders.forEach(order => {
                const value = order.meta.filterable[field];
                if (value && value !== GROUP_UNKNOWN) {
                    options[field].add(value);
                }
            });
        }
    });

    const params = {
        t,
        functionalLocations,
    };

    return fromPairs(
        entries(options).map(([filter, options]) => [
            filter,
            Array.from(options).map(value => {
                let text = value;
                if (optionLabelOverrides[filter]) {
                    if (typeof optionLabelOverrides[filter] === 'function') {
                        text = optionLabelOverrides[filter](value, params);
                    } else {
                        text = optionLabelOverrides[filter][value] || value;
                    }
                }

                return {
                    value,
                    text,
                };
            }),
        ])
    );
});

export const getSectionKey = memoizeOne(match => [
    match.params.partnerNumber,
    match.params.functionalLocationId
].join(':'));

export const showServiceOrder = memoizeOne((location, query, history) =>
    (orderId, orderType, functionalLocationId, partnerNumber) => {
        const { pathname } = location;
        const nextQuery = queryString.stringify({ ...query, orderId, orderType, functionalLocationId, partnerNumber });
        history.push(`${pathname}?${nextQuery}`);
    }
);

export const hideResponseTimes = partnerMeta => {
    const row = find(partnerMeta, { key: 'hide_response_times' }) || {};
    if (lowerCase(trim(row.value)) === 'true') {
        return true;
    }
    return false;
};

export const formatValue = value => {
    if (value === '-') {
      return value;
    }
    // If less than hour, show minutes
    else if (value < 1) {
      return `${round(value * 60)} min`;
    }
    // Show more precise value if less than 100 hours
    else if (value < 100) {
      return `${round(value, 1)} h`;
    }
    return `${round(value)} h`;
  };
