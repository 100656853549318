import moment from 'moment';
import { getSensorMetaValue, getMaximumSensorValue } from 'utils/Data/values';

export const ONE_HOUR = 1000 * 60 * 60;
export const ONE_DAY = 24 * ONE_HOUR;
export const ONE_MONTH = 30 * ONE_DAY;

export const getLabelFormatter = tickInterval => {
    let dateFormat;
    switch (tickInterval) {
        case ONE_MONTH:
            dateFormat = 'MMM';
            break;
        case ONE_DAY:
            dateFormat = 'D. MMM';
            break;
        default:
            dateFormat = 'D. MMM HH:mm';
    }

    return function() {
        return moment(this.value).format(dateFormat);
    };
};

export const getTooltipHeaderFormatter = aggregation => {
    let dateFormat;
    switch (aggregation) {
        case 'monthlySum':
            dateFormat = 'MMM';
            break;
        case 'hourlySum':
        case 'raw':
            dateFormat = 'D. MMM HH:mm';
            break;
        case 'energyRating':
        case 'dailySum':
        default:
            dateFormat = 'D. MMM';
    }

    return value => moment(value).format(dateFormat);
};

export const getTickInterval = timespan => {
    const duration = moment.duration(timespan);
    if (duration.asMonths() > 1) {
        return ONE_MONTH;
    } else if (duration.asDays() > 2) {
        return ONE_DAY;
    }
    return ONE_HOUR;
};

export const getPerformanceChartConfig = ({ sensorTypeName, sensorTypeUnit, sensorData, sensorMeta, timespan, maxX, getThreshold }) => {
    const isAreaCount = sensorTypeName === 'area_count';
    const isBooleanType = sensorTypeName === 'boolean';
    const config = {
        histories: sensorData,
        minXRange: timespan,
        unit: sensorTypeUnit,
        maxX,
        getThreshold,
        isBooleanType
    };
    if (isBooleanType || isAreaCount) {
        config.yMin = 0;
        config.precision = 0;
    }
    if (isBooleanType) {
        config.yMax = 1;
    }
    if (isAreaCount) {
        const capacity = getSensorMetaValue('capacity', sensorMeta);
        if (capacity) {
            config.unit = '%';
            config.yMax = Math.max(100, getMaximumSensorValue(sensorData) || 0);
        }
    }
    return config;
}
