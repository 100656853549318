import React from 'react';
import PropTypes from 'prop-types';
import EnergyOptimizationFilterInputs from './EnergyOptimizationFilterInputs';
import EnergyOptimizationFilterRow from './EnergyOptimizationFilterRow';
import SideFilter from 'components/SideFilter/SideFilter';


const EnergyOptimizationFilter = ({
    t,
    loading,
    filtersOpen,
    filter,
    selectedFilterValues,
    toggleFilterClick,
    handleFilterChange,
}) =>
    <React.Fragment>
        <EnergyOptimizationFilterRow
            t={t}
            toggleFilterClick={toggleFilterClick}
            loading={loading}
            handleFilterRemove={handleFilterChange}
            selectedFilterValues={selectedFilterValues}
        />

        <SideFilter
            t={t}
            useSideToggle={false}
            isOpen={filtersOpen}
            toggleFilterClick={toggleFilterClick}
            filtersOn={true}
        >
            { !loading && <EnergyOptimizationFilterInputs
                t={t}
                filter={filter}
                selectedFilterValues={selectedFilterValues}
                handleSelectDropdown={handleFilterChange}
            /> }
        </SideFilter>
    </React.Fragment>;

EnergyOptimizationFilter.propTypes = {
    t: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    filtersOpen: PropTypes.bool.isRequired,
    filter: PropTypes.array.isRequired,
    selectedFilterValues: PropTypes.object.isRequired,
    toggleFilterClick: PropTypes.func.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
};

export default React.memo(EnergyOptimizationFilter);
