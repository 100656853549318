import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import queryString from 'query-string';
import { ThemeProvider } from 'styled-components';
import { getWelcomeUrlForLanguage } from 'utils/Data/language';
import translations from 'decorators/Translations/translations';

import Loading from 'components/Loading/Loading.jsx';
import PageTemplate from './PageTemplate';
import themes from '../../../styles/themes';

import {
    Button,
    InputRow,
    InputLabel,
    InputText,
    InputPassword,
    InputForm,
    ErrorText
} from 'components/index.js';

import {
    loadWelcomeProfile,
    verifyProfile,
    loadUnauthenticatedTranslations
} from 'redux/modules/index.js';

const Description = styled.h3`
    color: ${props => props.theme.colors.rockBlue};
`;

const StyledInputRow = styled(InputRow)`
    width: 100% !important;
    margin: 1em 0 !important;

    label {
        text-align: left;
    }
`;

const WelcomeNewUser = props => {
    const {
        location: { search },
        loadWelcomeProfile,
        welcomeProfile,
        loadTranslations,
        loadingTranslations,
        verifyProfile,
        error,
        t
    } = props;

    const [model, setModel] = useState({});
    const query = queryString.parse(search);

    useEffect(() => {
        loadWelcomeProfile(query.code);
    }, []);

    useEffect(() => {
        if (welcomeProfile && welcomeProfile.verified) {
            window.location = `${getWelcomeUrlForLanguage(welcomeProfile.language)}?username=${welcomeProfile.username}`;
        }
        else if (welcomeProfile && welcomeProfile.username) {
            setModel({ ...model, username: welcomeProfile.username });
            loadTranslations(welcomeProfile.language);
        }
    }, [welcomeProfile]);

    const setProperty = (property, value) => setModel({ ...model, [property]: value });

    let body = null;

    if (welcomeProfile === null) {
        body =
            <div>
                <br />
                <h5>Sorry but we don't know you. Please contact your administrator for more information.</h5>
                <br />
            </div>
        ;
    }
    else if (welcomeProfile.id === undefined || loadingTranslations) {
        return <ThemeProvider theme={ themes.customerPlatform }><Loading showLogo={ false } /></ThemeProvider>;
    }
    else {
        body =
            <InputForm
                id="newUserForm"
                model={ model }
                onPropertyChange={ setProperty }
                onSubmit={() => verifyProfile(query.code, model.password, model.passwordAgain)}
            >
                <StyledInputRow>
                    <InputLabel id="username" text={ t('Username') } />
                    <InputText id="username" property="username" readonly />
                </StyledInputRow>
                <StyledInputRow required>
                    <InputLabel id="password" text={ t('Password') } />
                    <InputPassword id="password" property="password" placeholder={ t('Choose your password') } />
                </StyledInputRow>
                <StyledInputRow required>
                    <InputLabel id="passwordAgain" text={ t('Repeat password') } />
                    <InputPassword
                        id="passwordAgain"
                        property="passwordAgain"
                        placeholder={ t('Repeat your password') }
                    />
                </StyledInputRow>
                <StyledInputRow style={{ flexDirection: 'row-reverse' }}>
                    <Button submit>
                        { t('Let\'s start!') }
                    </Button>
                </StyledInputRow>
            </InputForm>
        ;
    }

    const errorComponent = error ? <ErrorText>{ t(error) }</ErrorText> : null;

    return (
        <PageTemplate
            title={ t('Welcome to Caverion SmartView!') }
            mainSiteLinkText={ t('Caverion.com main site') }
        >
            <Description>{ t('Before we begin, it is time to set your password.') }</Description>
            { body }
            { errorComponent }
        </PageTemplate>
    );
};

const mapStateToProps = state => ({
    profile: state.profile.profile,
    welcomeProfile: state.profile.welcomeProfile,
    error: state.profile.error,
    loadingTranslations: state.localization.loadingUnauthenticated
});

const mapDispatchToProps = dispatch => ({
    loadWelcomeProfile: verificationCode => dispatch(loadWelcomeProfile(verificationCode)),
    loadTranslations: language => dispatch(loadUnauthenticatedTranslations(language)),

    verifyProfile: (verificationCode, password, passwordAgain) =>
        dispatch(verifyProfile(verificationCode, password, passwordAgain))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(WelcomeNewUser));
