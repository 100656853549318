import React, { Fragment, PureComponent } from 'react';
import translations from 'decorators/Translations/translations';
import { connect } from 'react-redux';
import { loadFunctionalLocationsEnergyValues, loadSensorsValues } from 'redux/modules/iot/values';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import flatMapDeep from 'lodash/flatMapDeep';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import EnergyConsumption from 'components/Modules/EnergyModule/EnergyConsumption/EnergyConsumption';
import EnergyBreakdown from 'components/Modules/EnergyModule/EnergyBreakdown/EnergyBreakdown';
import { energyBreakdownTypes, isEnergyRatingSensor } from 'utils/Data/values';
import OPICard from 'components/OPICard/OPICard';
import OPICards from 'components/OPICard/OPICards';
import SectionHeader from 'components/Section/SectionHeader';
import Section from 'components/Section/Section';
import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import SkeletonText from 'components/Skeletons/SkeletonText';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import ScrollToComponent from 'components/ScrollToComponent/ScrollToComponent';
import {
  getEnergyValues,
  getOpiCards,
  getBreakdownTabs,
  getTotalRawValues,
  getEnergyRatingValues,
  getOutdoorsTemperatureData,
  getReferenceData,
} from './EnergyModuleUtils';
import { loadBuildingMeta } from 'redux/modules/buildingConfig/buildingMeta';
import { OUTDOOR_TYPE } from 'utils/Data/values';
import { metaReferenceKeys } from 'utils/Data/values';
import { loadEnergyRating } from 'redux/modules/iot/values';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';

const NoDataAvailable = styled.p`
  text-align: center;
  margin-top: ${props => props.theme.spacing.md};
`;

NoDataAvailable.displayName = 'NoDataAvailable';

const defaultValues = {
  series: [],
  yearTotals: {},
  years: [],
  categories: [],
  last365DaysConsumption: 0,
};

const energyRatingPrecision = 1;
const DEFAULT_OUTDOORS_TEMPERATURE_KEY = 'default_outdoor_temperature_id';

export class EnergyModule extends PureComponent {
  state = {
    energyRatingValues: {},
    valuesByType: {},
    totalValues: {
      ...defaultValues,
    },
    outdoorsTemperatureData: {},
    referenceData: {},
    tabSelected: 0,
    sensorId: null,
  };

  componentDidMount() {
    if (!this.props.functionalLocation || this.props.loadingParent) {
      return;
    }

    this.props.loadFunctionalLocationsEnergyValues(this.props.functionalLocation);
    this.props.loadEnergyRatingValuesByFL([this.props.functionalLocation.functionalLocation]); // energy rating for opi cards
    this.props.loadBuildingMeta(this.props.functionalLocation.functionalLocation);
    this.loadOutdoorsTemperatureValues();
  }

  // Listen to value updates
  componentDidUpdate(prevProps, prevState) {
    if (this.props.loadingParent) {
      return;
    }
    const {
      energyValues,
      energySensors,
      theme,
      buildingMeta: { meta },
      functionalLocation: { functionalLocation },
      t,
      buildingSensors,
      valuesBySensorId,
    } = this.props;
    const { referenceData } = this.state;

    // update energy after loading energy values (or when reference data should be included)
    if (energyValues !== prevProps.energyValues || referenceData !== prevState.referenceData) {
      this.updateEnergyValues(energyValues, energySensors, theme, meta, functionalLocation);
    }

    // update reference data and load outdoors temperature values
    if (meta !== prevProps.buildingMeta.meta) {
      const metaReferenceKeyValues = Object.keys(metaReferenceKeys).map(
        key => metaReferenceKeys[key]
      );
      const referenceMeta = meta[functionalLocation].filter(metaRow =>
        metaReferenceKeyValues.includes(metaRow.key)
      );
      this.updateReferenceData(referenceMeta, theme, t);
      this.loadOutdoorsTemperatureValues();
    }

    // update outdoors temperature after loaded values
    if (valuesBySensorId !== prevProps.valuesBySensorId) {
      this.updateOutdoorsTemperature(
        this.getOutdoorsTemperatureSensorValues(),
        this.props.theme,
        this.props.t
      );
    }

    if (!this.hasDefaultOutdoorsTemperatureSensor()) {
      // if buildingSensors change and we rely on condition sensor for outdoors temperature, load sensor values
      if (buildingSensors !== prevProps.buildingSensors) {
        this.loadOutdoorsTemperatureValues();
      }
    }
  }

  // Update state with ready values
  updateEnergyValues = (energyValues, energySensors, theme, meta, functionalLocation) => {
    const valuesByType = {};
    let energyRatingValues = {};
    let totalValues = { ...defaultValues };

    if (energyValues) {
      energyBreakdownTypes.forEach(type => {
        if (energyValues[type] && energyValues[type].length > 0) {
          valuesByType[type] = getEnergyValues(energyValues[type], theme, meta, energySensors);

          const referenceSeries = this.getReferenceForType(type);
          referenceSeries && valuesByType[type].series.push(referenceSeries);
        }
      });

      const totalRawValues = getTotalRawValues(energyValues);

      if (totalRawValues && totalRawValues.length > 0) {
        totalValues = getEnergyValues(totalRawValues, theme, meta, energySensors);

        const referenceSeries = this.getReferenceForType('energy');
        referenceSeries && totalValues.series.push(referenceSeries);
      }
      if (energyValues.energyRating && energyValues.energyRating.length > 0) {
        energyRatingValues = getEnergyRatingValues(energyValues.energyRating).find(
          item => item.functionalLocation === functionalLocation
        );
      }
    }

    if (valuesByType || totalValues) {
      this.setState({
        energyRatingValues,
        valuesByType,
        totalValues,
      });
    }
  };

  getReferenceForType = type => {
    const metaKey = metaReferenceKeys[type];
    return metaKey && !isEmpty(this.state.referenceData[metaKey])
      ? flatMapDeep(this.state.referenceData[metaKey])[0]
      : null;
  };

  updateOutdoorsTemperature = (outdoorsTemperatureValues, theme, t) => {
    if (outdoorsTemperatureValues && outdoorsTemperatureValues.length > 0) {
      this.setState({
        outdoorsTemperatureData: getOutdoorsTemperatureData(outdoorsTemperatureValues, theme, t),
      });
    }
  };

  updateReferenceData = (referenceMeta, theme, t) => {
    if (referenceMeta) {
      const referenceData = {};
      const name = t('Reference');

      referenceMeta.forEach(({ key, value }) => {
        let values;
        // Reference values come from building meta. An admin user has configured them as text so parsing it might cause an exception.
        try {
          values = JSON.parse(value);
        } catch (error) {
          values = [];
        }
        referenceData[key] = getReferenceData(values, theme, name);
      });
      this.setState({ referenceData });
    }
  };

  getLoadingSkeleton() {
    return (
      <Fragment>
        <OPICards>
          <OPICard loading />
          <OPICard loading />
        </OPICards>
        <Section>
          <SectionHeader>
            <SkeletonText header />
          </SectionHeader>
          <Columns>
            <Column
              columnWidth={{
                landscape: 9,
                default: 12,
              }}
            >
              <SkeletonChart height="200px" />
            </Column>
            <Column
              columnWidth={{
                landscape: 3,
                default: 12,
              }}
            >
              <SkeletonText height="200px" />
            </Column>
          </Columns>
        </Section>
      </Fragment>
    );
  }

  get noDataAvailableText() {
    return <NoDataAvailable>{this.props.t('No data available')}</NoDataAvailable>;
  }

  getEnergyRatingSensor = (energySensors, sensorTypes) => {
    const energyRatingType = sensorTypes.find(sensor => isEnergyRatingSensor(sensor.name));
    if (isEmpty(energyRatingType)) {
      return;
    }
    return energySensors.find(sensor => energyRatingType.id === sensor.sensorTypeId);
  };

  getOutdoorsTemperatureSensorValues = () => {
    const sensorId = this.getOutdoorsTemperatureSensorId();
    if (sensorId) {
      return this.props.valuesBySensorId[sensorId] || [];
    }
    return [];
  };

  hasDefaultOutdoorsTemperatureSensor = () => {
    return !!this.getDefaultOutdoorsTemperatureSensorId();
  };

  getDefaultOutdoorsTemperatureSensorId = () => {
    const {
      buildingMeta: { meta },
      functionalLocation: { functionalLocation },
    } = this.props;

    if (meta && meta[functionalLocation]) {
      const metaDataItem = meta[functionalLocation].find(
        item => item.key === DEFAULT_OUTDOORS_TEMPERATURE_KEY
      );
      return metaDataItem ? metaDataItem.value : null;
    }
    return null;
  };

  getOutdoorsTemperatureSensorId = () => {
    return (
      this.getDefaultOutdoorsTemperatureSensorId() || this.getOutdoorsTemperatureConditionSensorId()
    );
  };

  getOutdoorsTemperatureConditionSensorId = () => {
    const outdoorsTemperatureSensors = this.props.buildingSensors.filter(
      sensor => sensor.sensorType && sensor.sensorType.name === OUTDOOR_TYPE
    );
    return outdoorsTemperatureSensors.length > 0 && outdoorsTemperatureSensors[0].id;
  };

  loadOutdoorsTemperatureValues = () => {
    const sensorId = this.getOutdoorsTemperatureSensorId();
    if (sensorId) {
      this.props.loadSensorsValues(
        [sensorId],
        moment
          .utc()
          .subtract(2, 'years')
          .startOf('year'),
        moment.utc().endOf('day'),
        'dailyAverage'
      );
    }
  };

  scrollToElement = (scrollTo, tab) => {
    // Scroll to element
    this.setState({
      scrollTo,
    });
    setTimeout(() => this.setState({ scrollTo: null }), 500);
    // Change tab if needed
    if (scrollTo === 'breakdown' && !isNil(tab)) {
      this.handleTabChange(tab);
    }
  };

  handleTabChange = tab => this.setState({ tabSelected: tab });

  handleMeterClick = sensorId => {
    this.showSensor(sensorId);
  };

  showSensor = sensorId => this.setState({ sensorId: sensorId });

  hideSensor = () => this.setState({ sensorId: null });

  render() {
    const {
      t,
      loading,
      loadingParent,
      energySensors,
      functionalLocation,
      energyRatingValuesByFL,
      sensorTypes,
      sensorAlarmsById,
    } = this.props;
    const {
      totalValues,
      valuesByType,
      energyRatingValues,
      outdoorsTemperatureData,
      referenceData,
      scrollTo,
      tabSelected,
      sensorId,
    } = this.state;

    if (loading || loadingParent) {
      return this.getLoadingSkeleton();
    } else if (!loading && isEmpty(valuesByType)) {
      return this.noDataAvailableText;
    }

    const tabs = getBreakdownTabs(valuesByType, t);
    let functionalLocationEnergyRating = {};
    if (this.props.functionalLocation) {
      functionalLocationEnergyRating =
        energyRatingValuesByFL[this.props.functionalLocation.functionalLocation] || {};
    }
    const energyRatingSensor = this.getEnergyRatingSensor(energySensors, sensorTypes);
    const opiCards = getOpiCards(
      totalValues,
      valuesByType,
      functionalLocationEnergyRating,
      t,
      energyRatingSensor
    );

    return (
      <div data-test-id="EnergyModule">
        {opiCards && opiCards.length > 0 && (
          <OPICards>
            {opiCards.map((card, idx) => (
              <OPICard
                key={`card${idx}`}
                {...card}
                noCircle
                t={t}
                ctxHelp={`${CTXHELP_PREFIX} Energy ${card.ctxHelpType} OPI`}
                ctxHelpPosition={(idx + 1) % 3 === 0 || (idx + 1) % 4 === 0 ? 'left' : 'top'}
                onClick={
                  card.scrollTo
                    ? () => this.scrollToElement(card.scrollTo, card.changeTabTo)
                    : card.sensorId
                    ? () => this.handleMeterClick(card.sensorId)
                    : undefined
                }
                icon={card.sensorId && 'opi-expand'}
              />
            ))}
          </OPICards>
        )}
        {totalValues && (
          <Fragment>
            {scrollTo === 'energyConsumption' && <ScrollToComponent />}
            <EnergyConsumption
              {...totalValues}
              energyRating={
                energyRatingValues
                  ? {
                      latestValue: energyRatingValues.latestValue
                        ? round(energyRatingValues.latestValue, energyRatingPrecision)
                        : null,
                      monthAgoValue: energyRatingValues.monthAgoValue
                        ? round(energyRatingValues.monthAgoValue, energyRatingPrecision)
                        : null,
                      reference: null,
                    }
                  : {}
              }
              t={t}
              outdoorsTemperatureData={outdoorsTemperatureData}
              referenceData={referenceData}
              functionalLocation={functionalLocation}
            />
          </Fragment>
        )}
        {tabs && tabs.length > 0 && (
          <Fragment>
            {scrollTo === 'breakdown' && <ScrollToComponent />}
            <EnergyBreakdown
              tabs={tabs}
              t={t}
              functionalLocation={this.props.functionalLocation}
              energySensors={energySensors}
              valuesByType={valuesByType}
              outdoorsTemperatureData={outdoorsTemperatureData}
              referenceData={referenceData}
              tabSelected={tabSelected}
              handleTabChange={this.handleTabChange}
              handleMeterClick={this.handleMeterClick}
              hideSensor={this.hideSensor}
              sensorId={sensorId}
              sensorAlarmsById={sensorAlarmsById}
            />
          </Fragment>
        )}
      </div>
    );
  }
}
EnergyModule.displayName = 'EnergyModule';

EnergyModule.defaultProps = {
  energySensors: [],
  energyValues: {},
  energyChartValues: {},
  loading: false,
  loadingParent: false,
  energyRatingValuesByFL: {},
};

EnergyModule.propTypes = {
  energySensors: PropTypes.array.isRequired,
  functionalLocation: PropTypes.object,
  energyValues: PropTypes.object,
  loading: PropTypes.bool,
  loadingParent: PropTypes.bool,
  buildingMeta: PropTypes.shape({
    meta: PropTypes.object.isRequired,
  }).isRequired,
  loadBuildingMeta: PropTypes.func.isRequired,
  sensorTypes: PropTypes.array,
  buildingSensors: PropTypes.array.isRequired,
  loadEnergyRatingValuesByFL: PropTypes.func.isRequired,
  loadFunctionalLocationsEnergyValues: PropTypes.func.isRequired,
  energyRatingValuesByFL: PropTypes.object,
  sensorAlarmsById: PropTypes.object,
  valuesBySensorId: PropTypes.object.isRequired,
  loadSensorsValues: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  energySensors:
    props.functionalLocation &&
    state.values.energySensors[props.functionalLocation.functionalLocation],
  energyValues:
    props.functionalLocation &&
    state.values.energyValues[props.functionalLocation.functionalLocation],
  energyChartValues:
    props.functionalLocation &&
    state.values.energyChartValues.byFl[props.functionalLocation.functionalLocation],
  loading: state.values.loadingEnergyValues,
  buildingMeta: state.buildingMeta,
  energyRatingValuesByFL: state.values.energyRating.data,
  valuesBySensorId: state.values.valuesBySensorId,
  loadingSensorValues: state.values.loadingSensorValues,
});

const mapDispatchToProps = dispatch => ({
  loadFunctionalLocationsEnergyValues: functionalLocation => {
    dispatch(loadFunctionalLocationsEnergyValues(null, [functionalLocation]));
  },
  loadBuildingMeta: fl => dispatch(loadBuildingMeta([fl], false)),
  loadEnergyRatingValuesByFL: functionalLocations =>
    dispatch(loadEnergyRating(functionalLocations)),
  loadSensorsValues: (sensors, startTime, endTime, aggregation) => {
    dispatch(loadSensorsValues(sensors, startTime, endTime, aggregation));
  },
});

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withTheme(connector(translations(EnergyModule)));
