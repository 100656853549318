import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import PropTypes from 'prop-types';

import Map from 'ol/Map';
import View from 'ol/View';
import Projection from 'ol/proj/Projection';
import { getCenter } from 'ol/extent';
import { defaults as defaultInteractions, PinchZoom } from 'ol/interaction';
import Select from 'ol/interaction/Select';
import Snap from 'ol/interaction/Snap';
import Draw from 'ol/interaction/Draw';
import Zoom from 'ol/control/Zoom';
import ImageStatic from 'ol/source/ImageStatic';
import Image from 'ol/layer/Image';
import GeoJSON from 'ol/format/GeoJSON';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Group from 'ol/layer/Group';
import Text from 'ol/style/Text';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import Overlay from 'ol/Overlay';
import Control from 'ol/control/Control';

import { withTheme } from 'styled-components';
import _ from 'lodash';
import { mapIconToSvg } from './utils';

export const controlsCss = css`
    .ol-zoom-in,
    .ol-zoom-out {
        position: relative;
        display: inline-block;
        border: 2px solid ${props => props.theme.colors.darkGray};
        border-radius: 50%;
        padding: 0;
        height: calc(1em + 2px);
        width: calc(1em + 2px);
        background-color: ${props => props.theme.colors.white};
        color: transparent;
        box-sizing: content-box;
        font-size: 100%;
        cursor: pointer;
        margin: 0.5em;

        &::before {
            content: '';
            position: absolute;
            border-top: 2px solid ${props => props.theme.colors.darkGray};
            left: 0.25em;
            right: 0.25em;
            top: 0.5em;
        }

        &:hover {
            border-color: ${props => props.theme.colors.blue};

            &:before,
            &:after {
                border-color: ${props => props.theme.colors.blue};
            }
        }
    }

    .ol-zoom-in {
        &::after {
            content: '';
            position: absolute;
            border-left: 2px solid ${props => props.theme.colors.darkGray};
            top: 0.25em;
            bottom: 0.25em;
            left: 0.5em;
        }
    }

    .ol-expand-button {
        margin: 0.5em;

        button {
            position: relative;
            padding: 0;
            border: 0;
            margin: 0;
            background: transparent;
            cursor: pointer;
            width: 2em;
            height: 2em;

            &::before,
            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 48%;
                width: 100%;
                border: 1px solid ${props => props.theme.colors.darkGray};
            }

            &::before {
                transform: rotate(45deg);
            }

            &::after {
                transform: rotate(-45deg);
            }

            &:hover {
                &::before,
                &::after {
                    border-color: ${props => props.theme.colors.blue};
                }

                svg {
                    fill: ${props => props.theme.colors.blue};
                }
            }
        }
    }
`;

const BlueprintStyles = styled.div`
    ${props => props.isExpanded && css`
        .ol-overlaycontainer-stopevent {
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            flex-flow: row nowrap;
            padding: 0.75em 0.25em 0.5em;
            background: #fff;
            border-bottom-left-radius: 0.25em;
            box-shadow: 1px -1px 3px ${props => props.theme.colors.darkGray};
        }

        .ol-control {
            display: flex;
            flex-flow: row nowrap;
        }

        ${controlsCss}
    `}

    // infotip styles
    .ol-overlay-container {
        background-color: ${props => props.theme.colors.white};
        padding: ${props => props.theme.spacing.xxs};
        border: 1px solid ${props => props.theme.colors.lightGray};
        border-radius: 2px;
        color: ${props => props.theme.colors.black};
        font-family: ${props => props.theme.font.family.arial};
        font-size: ${props => props.theme.font.size.xxxs};
        font-weight: ${props => props.theme.font.weight.bold};
        line-height: ${props => props.theme.font.lineHeight.md};
        letter-spacing: 0.3px;
        text-align: center;
        cursor: pointer;

        .hideOnMobile {
            display: none;
            ${props => props.theme.media.landscape`
                display: block;
            `}
        }

        .capitalize {
            text-transform: capitalize;
        }

        ${props => props.theme.media.landscape`
            font-size: ${props => props.theme.font.size.xxs};
            padding: ${props => props.theme.spacing.xs};
            box-shadow: 0 2px 20px ${props => transparentize(0.75, props.theme.colors.black)};
            transition: transform ${props => props.theme.motion.easeIn} ${props => props.theme.motion.quick};

            &:hover {
                transform: translateY(-4px);
            }
        `}
    }
`;

const isTouchDevice = 'ontouchstart' in document.documentElement;

class BlueprintMap extends Component {
    state = {
        selectedShape: 'Polygon',
        width: null,
        height: null,
        mapInitialized: false,
        isExpanded: false,
    };

    // Array containing instances of ol.layer.Group
    featureGroups = [];

    /**
     * Lifecycle methods
     */
    componentDidMount() {
        const { image, imageSize } = this.props;

        // Get map container div
        this.DOMnode = ReactDOM.findDOMNode(this.containerDiv);

        if (!image) {
            return;
        } else if (!imageSize || !imageSize.width || !imageSize.height) {
            console.log('imageSize: { width, height } is not defined');
            return;
        }

        // Get map container div width
        const containerWidth = this.DOMnode.clientWidth;

        // Initialize map
        this.initMap(imageSize, containerWidth);

        // Set edit mode
        this.setEditMode(this.props.editMode);
    }

    componentDidUpdate(prevProps) {
        const { image, imageSize, featureGroups, featureGroupsVisible } = this.props;

        // Get map container div width
        const currentWidth = this.DOMnode.clientWidth;

        if (!image) {
            return;
        } else if (!this.state.mapInitialized && (!imageSize || !imageSize.width || !imageSize.height)) {
            console.log('imageSize: { width, height } is not defined');
            return;
        } else if (!this.state.mapInitialized) {
            // Initialize map
            this.initMap(imageSize, currentWidth);
        }

        // If updating changed image, update image layer
        if (image !== prevProps.image && imageSize && imageSize.width && imageSize.height) {
            const newSize = imageSize;
            const oldSize = prevProps.imageSize;

            if (newSize.width !== oldSize.width || newSize.height !== oldSize.height) {
                // this.updateProjection();
                this.updateDimensions();
            }

            this.setImageLayer(image, imageSize);
        }

        // If feature props length has changed, update features
        if (!_.isEqual(prevProps.featureGroups, featureGroups)) {
            this.updateMap(featureGroups);
        }

        // Update layer visibility
        if (featureGroups
            && featureGroups.length > 0
            && featureGroupsVisible
            && featureGroupsVisible.length > 0
            && !_.isEqual(prevProps.featureGroupsVisible, featureGroupsVisible)) {
            this.setVisibleLayers(featureGroupsVisible);
        }

        // If updating changed dimensions, recalculate map dimensions
        if (currentWidth !== this.state.width) {
            this.updateDimensions();
        }

        if (!_.isNil(this.props.isExpanded) && this.state.isExpanded !== this.props.isExpanded) {
            this.setState({
                isExpanded: this.props.isExpanded
            }, () => {
                if (this.props.isExpanded) {
                    this.updateDimensions();
                }
            });
            this.toggleControls();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.throttledUpdate);
        window.removeEventListener('keydown', this.escFunction, false);
    }

    /**
     * Map initialization and general administration
     */
    updateMap = featureGroups => {
        if (featureGroups && featureGroups.length > 0) {
            // First remove all feature layers and infotip overlays
            this.removeFeatureLayers();
            this.featureGroups = [];
            this.removeOverlays();

            // Add new feature layers and new infotip overlays
            featureGroups.forEach((featureGroup, idx) => {
                const features = featureGroup.map(geojson => this.convertGeoJSONToFeature(geojson));
                this.addFeatureGroup(features, idx);
                this.addOverlayGroup(features);
            });
        }
    };

    // Update map size to div and openlayers based on image size
    updateDimensions = () => {
        const { isExpanded } = this.state;
        const imageSize = this.props.imageSize;
        const width = this.DOMnode.clientWidth;

        const aspectRatio = this.getAspectRatio(imageSize);
        let height = this.getCanvasHeight(width, aspectRatio);

        if (isExpanded) {
            height = window.innerHeight;
        }

        // Update map div size and force OpenLayers updateSize()
        this.updateMapSize(width, height);
        // Recalculate resolution and update
        this.updateResolution(imageSize, width, height);
    };

    // Create map projection
    createProjection(extent) {
        return new Projection({
            code: 'static-image',
            units: 'pixels',
            extent: extent
        });
    }

    // Create extent based on image size
    createExtent(imageSize) {
        return [0, 0, imageSize.width, imageSize.height];
    }

    // Create view
    createView(currentExtent, projection, projectionToCanvasRatio) {
        const view = new View({
            projection: projection,
            center: getCenter(currentExtent),
            // Lock resolution to fill whole canvas
            resolution: projectionToCanvasRatio,
            maxZoom: 6
        });
        // Set min zoom to initial zoom level
        const zoom = view.getZoom();
        view.setMinZoom(zoom);
        return view;
    }

    createMap(view) {
        const { isZoomable } = this.props;
        return new Map({
            // Disable zooming and other map controls for now
            interactions: defaultInteractions({
                doubleClickZoom: isZoomable,
                dragAndDrop: false,
                dragPan: !isTouchDevice && isZoomable,
                keyboardPan: false,
                keyboardZoom: false,
                mouseWheelZoom: false,
                altShiftDragRotate: false,
                pinchRotate: false
            }).extend(isZoomable ? [
                new PinchZoom()
            ] : []),
            controls: [],
            view: view
        });
    }

    // Set image layer
    setImageLayer = (image, size) => {
        // If there already is imageLayer, remove it first
        if (!_.isEmpty(this.imageLayer)) {
            this.map.removeLayer(this.imageLayer);
        }
        // Blueprint image layer
        const imageLayer = new Image({
            source: new ImageStatic({
                url: image,
                projection: this.projection,
                imageExtent: this.extent,
                // Hack to make IE11 handle SVG image size properly
                imageLoadFunction: (staticImage, src) => {
                    const image = staticImage.getImage();
                    image.width = size.width;
                    image.height = size.height;
                    image.src = src;
                },
            }),
            name: 'Imagelayer'
        });

        this.map.addLayer(imageLayer);
        this.imageLayer = imageLayer;
    };

    bindViewActions = () => {
        this.bindCursorStyleOnHover();
        this.bindFeatureClick();
        // this.bindFeatureDoubleClick();
    };

    unbindViewActions = () => {
        this.unbindCursorStyleOnHover();
        this.unbindFeatureClick();
        // this.unbindFeatureDoubleClick();
    };

    cursorStyleChange = e => {
        const pixel = this.map.getEventPixel(e.originalEvent);
        const primaryFeature = this.getPrimaryFeature(pixel);
        // Check if there is a feature on the hovered pixel and that feature has sensorId defined
        if (primaryFeature && primaryFeature.get('sensorId')) {
            this.map.getTarget().style.cursor = 'pointer';
        } else {
            this.map.getTarget().style.cursor = '';
        }
    };

    bindCursorStyleOnHover = () => {
        // Change mouse cursor to pointer when hovering over marker
        this.map.on('pointermove', this.cursorStyleChange);
    };

    unbindCursorStyleOnHover = () => {
        // Remove change mouse cursor to pointer when hovering over marker
        this.map.un('pointermove', this.cursorStyleChange);
    };

    // Bind actions to feature click
    bindFeatureClick = () => {
        const self = this;
        // Toggle dialog on feature select
        this.map.on('click', e => {
            const primaryFeature = this.getPrimaryFeature(e.pixel);

            if (primaryFeature
                && self.props.onSensorClick instanceof Function
                && primaryFeature.get('sensorId')) {
                const sensorId = primaryFeature.get('sensorId');

                self.props.onSensorClick(
                    primaryFeature.get('title'),
                    primaryFeature.get('type'),
                    sensorId ? [sensorId] : []
                );
            }
            if (typeof this.props.onFeatureClick === 'function') {
                this.props.onFeatureClick(primaryFeature.getProperties(), primaryFeature.getCoordinates());
            }
        });
    };

    // Unbind
    unbindFeatureClick = () => {
        // Toggle dialog on feature select
        this.map.un('click', this.featureClick);
    };

    featureDoubleClick = e => {
        const primaryFeature = this.getPrimaryFeature(e.pixel);
        if (primaryFeature) {
            if (typeof this.props.onFeatureDoubleClick === 'function') {
                this.props.onFeatureDoubleClick(primaryFeature.getProperties());
            }
        }
    }

    // Bind actions to feature double click
    bindFeatureDoubleClick = () => {
        this.map.on('dblclick', this.featureDoubleClick);
    };

    // Unbind actions to feature double click
    unbindFeatureDoubleClick = () => {
        this.map.un('dblclick', this.featureDoubleClick);
    };

    // Get primary feature from given pixel
    getPrimaryFeature = pixel => {
        let primaryFeature;
        // If we clicked on sensor, we'll want to show that.
        // If there is no sensor where we clicked, let's show area if available.
        this.map.forEachFeatureAtPixel(pixel, (feature, layer) => {
            if (!primaryFeature || feature.getGeometry().getType() === 'Point') {
                primaryFeature = feature;
            }
        });
        return primaryFeature;
    };

    convertGeoJSONToFeature = geoJSON => {
        return new GeoJSON().readFeatures(geoJSON);
    }

    initMap = (imageSize, width) => {
        // Calculate projection to canvas ratio based on image size
        const projectionToCanvasRatio = this.getProjectionToCanvasRatio(imageSize.width, width);

        // Set extent
        this.extent = this.createExtent(imageSize);

        // Create projection
        this.projection = this.createProjection(this.extent);

        // Configure view
        this.view = this.createView(this.extent, this.projection, projectionToCanvasRatio);

        // Configure map
        this.map = this.createMap(this.view);

        if (this.props.getControlsRef) {
            this.externalControl = new Zoom({
                target: this.props.getControlsRef()
            });
            this.map.addControl(this.externalControl);
        }

        // Set image layer
        this.setImageLayer(this.props.image, this.props.imageSize);

        // Add feature layers
        if (this.props.featureGroups && this.props.featureGroups.length > 0) {
            this.props.featureGroups.forEach(featureGroup => {
                const features = featureGroup.map(geojson => this.convertGeoJSONToFeature(geojson));
                this.addFeatureGroup(features);
                this.addOverlayGroup(features);
            });
        }

        // Add edit layer
        this.addEditLayer();

        // Set layer visibility
        if (this.props.featureGroups
            && this.props.featureGroups.length > 0
            && this.props.featureGroupsVisible
            && this.props.featureGroupsVisible.length > 0) {
            this.setVisibleLayers(this.props.featureGroupsVisible);
        }

        // Bind map to div
        this.map.setTarget(this.targetDiv);

        // Force dimension update
        this.updateDimensions();

        // Add listener to enable map resizing
        window.addEventListener('resize', this.throttledUpdate);
        window.addEventListener('keydown', this.handleEsc);

        // Change viewport inline styles
        this.map.getViewport().style.overflow = 'visible';

        // Set map as initialized
        this.setState({
            mapInitialized: true
        });
    };

    handleEsc = e => {
        const { toggleExpand } = this.props;
        const { isExpanded } = this.state;
        if (e.keyCode === 27 && isExpanded && typeof toggleExpand === 'function') {
            toggleExpand();
        }
    };

    throttledUpdate = () => {
        _.throttle(this.updateDimensions, 1000)();
    };

    onFeatureAdd = e => {
        if (typeof this.props.handleNewArea === 'function') {
            this.props.handleNewArea(e.feature.getGeometry().getCoordinates());
        }
        if (typeof this.props.handleNewPoint === 'function') {
            this.props.handleNewPoint(e.feature.getGeometry().getCoordinates());
        }

        this.disableEditMode();

        if (!this.props.editMode) {
            this.bindViewActions();
        } else {
            this.map.removeLayer(this.editLayer);
            this.addEditLayer();
            this.enableEditMode();
        }
    }

    addEditLayer = () => {
        this.editSource = new VectorSource();
        this.editSource.on('addfeature', this.onFeatureAdd);
        this.editLayer = this.createFeatureLayer(this.editSource);
        this.map.addLayer(this.editLayer);
    };

    setEditMode = editMode => {
        if (editMode === 'point') {
            this.unbindViewActions();
            this.disableEditMode();
            this.createFeature('Point');
        } else if (editMode === 'area') {
            this.unbindViewActions();
            this.disableEditMode();
            this.createFeature('Polygon');
        } else if (editMode === true) {
            this.unbindViewActions();
            this.enableEditMode();
        } else {
            this.disableEditMode();
            this.bindViewActions();
        }
    };

    enableEditMode = () => {
        const self = this;

        // Set interaction to select a feature
        const select = new Select({
            wrapX: false,
            filter: (feature, layer) => feature.getProperties().editable || layer === this.editLayer
        });
        // Set listener so that a select event will cause hovering blue ball and snapping interaction
        select.once('select', () => {
            self.map.addInteraction(this.draw);
            self.map.addInteraction(this.snap);
            // prevent select to reselect the point after it's been moved to the clicked location
            self.map.removeInteraction(this.select);
        });

        const draw = new Draw({
            type: typeof this.props.handleNewArea === 'function' ? 'Polygon' : 'Point',
            source: this.editSource
        });

        const snap = new Snap({ source: this.editSource });

        this.select = select;
        this.draw = draw;
        this.snap = snap;

        this.map.addInteraction(this.select);
    }

    disableEditMode = () => {
        this.map.removeInteraction(this.draw);
        this.map.removeInteraction(this.snap);
        this.map.removeInteraction(this.select);
    }

    // Create ability to edit and add areas and sensors
    createFeature = type => {
        this.draw = new Draw({
            type: type,
            source: this.editSource
        });

        this.map.addInteraction(this.draw);
        this.snap = new Snap({ source: this.editSource });
        this.map.addInteraction(this.snap);
    };

    // line-height: 15px, padding: 8px + 8px, triangle: ~10px, icon: ~10px
    calcInfotipHeight = rows => rows * 15 + 36;

    addOverlayGroup = featureArray => {
        featureArray.forEach(
            features => features.forEach(feature => {
                const { infotip, title, type, sensorId, geometry } = feature.values_;

                if (infotip) {
                    const overlay = this.createOverlay(geometry.flatCoordinates);
                    overlay.element.innerHTML = infotip.content;
                    overlay.element.onclick = event => {
                        event.preventDefault();
                        this.props.onSensorClick(title, type, [sensorId]);
                    };
                    this.map.addOverlay(overlay);
                }
            })
        );
    };

    createOverlay = (coords, height) => {
        return new Overlay({
            insertFirst: true,
            position: coords,
            positioning: 'center-center', // https://openlayers.org/en/latest/apidoc/module-ol_OverlayPositioning.html
            stopEvent: false,
            offset: height && [0, -height],
        });
    };

    // Create layer group and add it to the map
    addFeatureGroup = featureArray => {
        // Create layers from GeoJSON
        const newFeatureLayers = featureArray.map(features => {
            const self = this;
            const featureSource = this.createFeatureSource(features);
            featureSource.on('changefeature', function(e) {
                if (typeof self.props.handleNewArea === 'function') {
                    self.props.handleNewArea(e.feature.getGeometry().getCoordinates());
                }
                if (typeof self.props.handleNewPoint === 'function') {
                    self.props.handleNewPoint(e.feature.getGeometry().getCoordinates());
                }
            });
            return this.createFeatureLayer(featureSource);
        });
        const group = this.createLayerGroup(newFeatureLayers);
        this.featureGroups.push(group);
        // Add layer group to map
        this.map.addLayer(group);
    };

    createLayerGroup = layers => {
        // Create layer group from layers
        return new Group({
            layers: layers,
            name: 'FeatureGroup'
        });
    };

    createFeatureSource = features => {
        return new VectorSource({
            features: features
        });
    }

    // Create feature layer
    createFeatureLayer = featureSource => {
        const getTextStyle = feature => {
            if (feature.get('infotip') || window.innerWidth < 900) {
                return undefined;
            }
            return new Text({
                textAlign: 'center',
                textBaseline: 'middle',
                text: feature.get('title'),
                font: `normal 1.125em ${this.props.theme.fontFamily.text}`,
                fill: new Fill({ color: 'rgb(0, 0, 0)' }),
                stroke: new Stroke({ color: 'rgba(255,255,255,0.75)', width: 2 }),
                offsetY: feature.getGeometry().getType() === 'Polygon' ? 0 : 25,
                overflow: true
            });
        };
        const getPointStyle = feature => {
            if (feature.get('infotip')) {
                return undefined;
            }
            const icon = mapIconToSvg[feature.get('icon')]
                || mapIconToSvg[feature.get('type')]
                || mapIconToSvg.default;
            return new Icon({
                src: 'data:image/svg+xml;charset=utf-8,'
                    + encodeURIComponent(icon.data),
                imgSize: icon.size,
                zIndex: 10
            });
        };

        // Layer area and sensor style
        const getLayerStyle = feature => new Style({
            // Area
            fill: new Fill({
                color: feature.get('fillColor')
            }),
            stroke: new Stroke({
                color: feature.get('strokeColor'),
                width: 2
            }),
            // Point
            image: getPointStyle(feature),
            // Text
            text: getTextStyle(feature)
        });

        // Return openlayers vector layer
        return new VectorLayer({
            name: 'Features',
            style: getLayerStyle,
            source: featureSource
        });
    };

    // Get image aspect ratio
    getAspectRatio(imageSize) {
        return imageSize.width / imageSize.height;
    }

    // Calculate canvas height based on image aspect ratio and container width
    getCanvasHeight(width, ratio) {
        return Math.ceil(width / ratio);
    }

    // Calculate image to canvas size ratio
    getProjectionToCanvasRatio(projectionWidth, canvasWidth) {
        return projectionWidth / canvasWidth;
    }

    getSelectedFeature = () => {
        const writer = new GeoJSON();
        return writer.writeFeatures(this.select.getFeatures().getArray());
    };

    hideFeatures = () => {
        this.map.getLayers().forEach(layer => {
            if (layer.get('name') !== undefined && layer.get('name') === 'FeatureGroup') {
                layer.setVisible(false);
            }
        });
    };

    // Remove all feature layers
    removeFeatureLayers = () => {
        // Get layers we want to remove
        const layersToRemove = [];
        this.map.getLayers().forEach(layer => {
            if (layer.get('name') !== undefined && layer.get('name') === 'FeatureGroup') {
                layersToRemove.push(layer);
            }
        });

        // Remove layers
        const removeLength = layersToRemove.length;
        for (let i = 0; i < removeLength; i++) {
            this.map.removeLayer(layersToRemove[i]);
        }
    }

    // Remove all overlays
    removeOverlays = () => {
        _.forEach(
            _.slice(this.map.getOverlays().array_),
            overlay => this.map.removeOverlay(overlay)
        );
    };

    // Set layer visibility
    setLayerVisibility = (layer, visibility) => {
        if (this.featureGroups[layer]) {
            this.featureGroups[layer].setVisible(visibility);
        }
    }

    setVisibleLayers = layerVisibility => {
        layerVisibility.forEach((enabled, idx) => {
            this.setLayerVisibility(idx, enabled);
        });
    }

    showFeatures = () => {
        this.map.getLayers().forEach(layer => {
            if (layer.get('name') !== undefined && layer.get('name') === 'FeatureGroup') {
                layer.setVisible(true);
            }
        });
    }

    // Update container div height and width and for map updateSize()
    updateMapSize(width, height) {
        this.setState({ height, width }, () => this.map.updateSize());
    }

    // Update map resolution based on floor image width and container width
    updateResolution(imageSize, containerWidth, containerHeight) {
        const { isExpanded } = this.props;
        let ratio;
        // In expanded mode, fit whole image to screen
        if (isExpanded && imageSize.width / imageSize.height < containerWidth / containerHeight) {
            const paddedHeight = imageSize.height + 40;
            ratio = this.getProjectionToCanvasRatio(paddedHeight, containerHeight);
        } else {
            let paddedWidth = imageSize.width;
            // Add padding to image if we are on expanded mode
            if (this.props.isExpanded) {
                paddedWidth = paddedWidth + 40;
            }
            ratio = this.getProjectionToCanvasRatio(paddedWidth, containerWidth);
        }
        this.view.setResolution(ratio);
        const zoom = this.view.getZoom();
        this.view.setMinZoom(zoom);
    }

    toggleControls = () => {
        if (this.state.isExpanded === false) {
            this.enableControls();
        } else {
            this.disableControls();
        }
    };

    enableControls = () => {
        if (!this.internalZoom) {
            const button = document.createElement('button');
            const element = document.createElement('div');
            element.className = 'ol-expand-button ol-unselectable ol-control';
            element.appendChild(button);

            if (typeof this.props.toggleExpand === 'function') {
                button.addEventListener('click', this.props.toggleExpand);
            }

            this.internalZoom = new Zoom();
            this.internalExpand = new Control({
                element
            });
            this.map.addControl(this.internalZoom);
            this.map.addControl(this.internalExpand);
        }
    };

    disableControls = () => {
        if (this.internalZoom) {
            this.map.removeControl(this.internalZoom);
            this.map.removeControl(this.internalExpand);
            this.internalZoom = null;
            this.internalExpand = null;
        }
    };

    render() {
        return (
            <BlueprintStyles isExpanded={this.state.isExpanded}>
                <div ref={ node => {this.containerDiv = node;} }>
                    <div
                        ref={ node => {this.targetDiv = node;} }
                        style={{ width: this.state.width, height: this.state.height }}
                    />
                </div>
            </BlueprintStyles>
        );
    }
}

BlueprintMap.defaultProps = {
    toggleExpand: undefined,
    getControlsRef: undefined,
    onSensorClick: undefined,
    onFetureClick: undefined,
    isExpanded: false,
    editMode: false,
    featureGroups: [],
    featureGroupsVisible: [],
    isZoomable: false,
};

BlueprintMap.propTypes = {
    theme: PropTypes.object.isRequired,
    // Image url
    image: PropTypes.string.isRequired,
    // Image size as { height, width }
    imageSize: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    }).isRequired,
    // Function to toggle map container to expand it to full window (expanding enables controls overlay)
    toggleExpand: PropTypes.func,
    // Get ref to controls DOM element (for controls that are outside of the map)
    getControlsRef: PropTypes.func,
    // Callback for sensor click
    onSensorClick: PropTypes.func,
    // Callback for feature click
    onFeatureClick: PropTypes.func,
    // Callback for feature double click
    onFeatureDoubleClick: PropTypes.func,
    // Callback to handle newly added area coordinates
    handleNewArea: PropTypes.func,
    // Callback to handle newly added single point coordinates
    handleNewPoint: PropTypes.func,
    // Edit mode allows drawing features
    editMode: PropTypes.bool,
    // State of full window view
    isExpanded: PropTypes.bool,
    // Feature groups (containing areas, sensors, etc)
    featureGroups: PropTypes.array,
    // Currently visible feature groups
    featureGroupsVisible: PropTypes.array,
    // Is zoomable
    isZoomable: PropTypes.bool,
};

export default withTheme(BlueprintMap);
