import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';
import { customViewShape } from 'components/CustomView/utils';

const StyledFreelayout = styled.div`
    overflow: hidden;
`;

const StyledIframe = styled.iframe`
    margin-top: ${props => `-${props.offsetTop}px`};
`;

const EmbeddedPageView = ({
    customView,
    noDataRender
}) => {
    if (!customView || isEmpty(customView.content)) {
        return noDataRender;
    }

    const offsetTop = +customView.offsetTop || 0;
    const height = +customView.height + offsetTop;

    return (
        <StyledFreelayout>
            <StyledIframe
                src={ customView.content }
                frameBorder="0"
                height={ height }
                width="100%"
                offsetTop={ offsetTop }
            />
        </StyledFreelayout>
    );
};

EmbeddedPageView.defaultProps = {
    customView: {},
};

EmbeddedPageView.propTypes = {
    customView: PropTypes.oneOfType([customViewShape()]),
    noDataRender: PropTypes.node
};

export default EmbeddedPageView;
