import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';

const Responsive = WrapperComponent => class extends React.Component {
    constructor(props) {
        super(props);
        this.componentNode = React.createRef();
        this.domNode = null;
    }

    state = {
        width: null,
        height: null
    };

    componentDidMount() {
        this.domNode = ReactDOM.findDOMNode(this.componentNode.current);
        this.updateDimensions();
        window.addEventListener('resize', this.throttledUpdate);
    }

    componentDidUpdate() {
        this.updateDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.throttledUpdate);
    }

    throttledUpdate = () => {
        _.throttle(this.updateDimensions, 600)();
    };

    updateDimensions = () => {
        if (!this.domNode) {
            return;
        }
        const node = ReactDOM.findDOMNode(this.domNode);
        if (!node) {
            return;
        }
        const height = node.clientHeight;
        const width = node.clientWidth;

        if (height !== this.state.height || width !== this.state.width) {
            this.setState({
                height,
                width
            });
        }
    }

    render() {
        return <WrapperComponent
            {...this.props}
            ref={ this.componentNode }
            dimensions={{ ...this.state }}
        />;
    }
};

export default Responsive;
