import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LeftArrow = styled.span`
    border: 1px solid white;
    border-radius: 50%;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-left: ${props => props.theme.spacing.md};
    &::after {
        content: '↑';
    }
    transform: ${props => props.isOpen ? 'rotate(180deg)' : 'rotate(0)'};
    transition: transform 0.8s;
`;
LeftArrow.displayName = 'LeftArrow';

const FilterToggleButton = styled.button`
    position: absolute;
    height: 50px;
    width: 150px;
    top: 50%;
    left: -100px;
    transform: rotate(-90deg);
    margin: auto;
    background: ${props => props.filtersOn ? props.theme.colors.emerald : props.theme.colors.cerulean};
    color: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.font.family.sanchez};
    font-size: ${props => props.theme.font.size.sm};
    white-space: nowrap;
    border-color: transparent;

    &:focus {
        outline: none;
    }
    &:hover {
        cursor: pointer;
    }

    visibility: ${props => !props.isOpen ? 'initial' : 'hidden'};
    ${props => props.theme.media.portrait`
        visibility: initial;
    `};
`;
FilterToggleButton.displayName = 'FilterToggleButton';

export const SideToggle = ({ t, isOpen, filtersOn, toggleFilterClick }) =>
    <FilterToggleButton isOpen={ isOpen } filtersOn={ filtersOn } onClick={ toggleFilterClick }>
        { filtersOn ? t('Filter ON') : t('Add Filters') }
        <LeftArrow isOpen={ isOpen } />
    </FilterToggleButton>;

SideToggle.propTypes = {
    t: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    filtersOn: PropTypes.bool.isRequired,
    toggleFilterClick: PropTypes.func.isRequired,
};

export default SideToggle;
