import React, { Fragment, PureComponent } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import QueryLink from '../QueryLink/QueryLink.jsx';
import SkeletonCircle from 'components/Skeletons/SkeletonCircle';
import Infotip, { InfotipContent, InfotipContentWrapper } from 'components/Infotip/Infotip';
import { toggleScroll } from 'utils/Modal/toggleScroll';

const performanceColors = props => ({
    ok: props.theme.status.okColor,
    warning: props.theme.status.warningColor,
    neutral: props.theme.colors.rockBlue
});

const selectedPerformanceColors = props => ({
    ok: props.theme.status.okColor,
    warning: props.theme.status.warningColor,
    neutral: props.theme.colors.blue
});

export const FloorTabsContainer = styled.div`
    max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});

    ${props => props.theme.media.landscape`
        margin: -2em auto 0;
    `};
`;

// Scroll overflow handling
export const ScrollContainer = styled.div`
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;

    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const FloorTabsListContainer = styled.div`
    height: ${props => props.theme.navigation.height};
`;

export const FloorTabsList = styled.div`
    position: relative;
    background-color: ${props => props.theme.colors.white};
    box-shadow: ${props => `0 2px 5px ${rgba(props.theme.colors.black, 0.10)}`};
    white-space: nowrap;
    text-align: center;
    font-family: ${props => props.theme.font.sanchez};

    ${props => props.fixedMobile && css`
        top: 0;
        left: 0;
        right: 0;
        position: fixed;
        z-index: ${props => props.theme.zIndex('circleTabs')};
    `};

    ${props => props.theme.media.portrait`
        position: initial;
        ${props => props.fixedTablet && css`
            position: fixed;
            top: 80px;
        `}
    `}

    ${props => props.theme.media.landscape`
        position: initial;
        ${props => props.fixed && css`
            position: fixed;
            top: ${props => props.theme.navigation.height};
            left: ${props => props.naviExtended ? props.theme.navigation.sideTabletWidth : props.theme.navigation.sideSmallWidth};
            z-index: ${props => props.theme.zIndex('circleTabs')};
        `}
    `}
    ${props => props.theme.media.desktop`
        position: initial;
        ${props => props.fixed && css`
            position: fixed;
            left: ${props => props.naviExtended ? props.theme.navigation.sideWidth : props.theme.navigation.sideSmallWidth};
        `}
    `}
`;

const FloorTab = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    margin: 0 0.25em;
    min-width: ${props => props.small ? '2em' : '2.5em'};
    height: ${props => props.small ? '2em' : '2.5em'};
    font-size: ${props => props.small && '1em'};
    border: 1px solid ${props =>
        props.selected ? selectedPerformanceColors(props)[props.performance] :
        performanceColors(props)[props.performance]};
    border-radius: 2.5em;
    background-color: ${props =>
        props.selected ? selectedPerformanceColors(props)[props.performance] :
        props.theme.colors.white};
    box-shadow: ${props => !props.selected && `0 0 0 2px ${rgba(performanceColors(props)[props.performance], 0.15)}`};

    a {
        padding: 0 0.5em;
        color: ${props => props.selected ? props.theme.colors.white : performanceColors(props)[props.performance]};
    }
`;

const FloorTabItem = styled.div`
    display: ${props => props.selected ? 'block' : 'none'};
    margin-top: 0.75em;
`;

const TabLabel = styled.div`
    display: inline-block;
`;

const StyledInfotip = styled(Infotip)`
    && {
        display: block;
        position: static;
    }

    && ${InfotipContentWrapper} {
        position: static;
        transform: none;
    }

    && ${InfotipContent} {
        position: fixed;
        z-index: ${props => props.theme.zIndex('infoTip')};
        top: ${props => props.offsetTop + 'px'};
        left: ${props => props.offsetLeft + 'px'};
        transform: translateX(-50%);
    }
`;

class FloorTabs extends PureComponent {
    state = {
        toolTipStyles: [],
        isExpanded: false
    };
    labelRefs = [];

    handleInfoTipMouseOver = index => {
        const bounds = this.getLabelPosition(index);
        if (bounds) {
            this.setInfoTipPosition(bounds, index);
        }
    };

    /**
     * Gets given index label bounding client rect
     */
    getLabelPosition = index => {
        if (this.labelRefs && this.labelRefs.length > 0) {
            const element = ReactDOM.findDOMNode(this.labelRefs[index]);
            if (element) {
                return element.getBoundingClientRect();
            }
        }
        return null;
    };

    /**
     * Sets infotip positioning styles to state
     */
    setInfoTipPosition = (bounds, index) => {
        this.setState(prevState => {
            const styles = prevState.toolTipStyles.slice(0);
            styles[index] = {
                left: bounds.left + bounds.width / 2,
                top: bounds.top - 66
            };
            return {
                toolTipStyles: styles
            };
        });
    };

    toggleExpand = () => {
        this.setState(prevState => {
            const { isExpanded } = prevState;
            toggleScroll(!isExpanded);
            return {
                isExpanded: !isExpanded
            };
        });
    };

    renderTabLabel(label, index, selected, location, t, small) {
        const layer = label.title ? label.title.toLowerCase() : '';

        return <FloorTab selected={ selected } key={ index } performance={ label.performance } small={ small }>
            <QueryLink
                role="button"
                to={{
                    pathname: location && location.pathname,
                    query: { layer, page: '' }
                }}
            >
                { t(label.title) }
            </QueryLink>
        </FloorTab>;
    }

    renderLabels = (props, state, selectedTab) => {
        const { scroll: { scrollTop }, naviExtended } = props;

        const scrollOffset = 150 + 40; // Hero + margin
        const scrollOffsetMobile = 64 + 80; // Navi + tab navi
        const scrollOffsetTablet = 150 + 64 + 24; // Hero + navi + tablet margin
        const fixed = scrollOffset < scrollTop;
        const fixedMobile = scrollOffsetMobile < scrollTop;
        const fixedTablet = scrollOffsetTablet < scrollTop;

        const self = this;

        if ( props.loading ) {
            return (
                <FloorTabsListContainer>
                    <FloorTabsList>
                        <ScrollContainer>
                            <SkeletonCircle width="40px" borderWidth="2px" />
                            <SkeletonCircle width="40px" borderWidth="2px" />
                            <SkeletonCircle width="40px" borderWidth="2px" />
                            <SkeletonCircle width="40px" borderWidth="2px" />
                            <SkeletonCircle width="40px" borderWidth="2px" />
                        </ScrollContainer>
                    </FloorTabsList>
                </FloorTabsListContainer>
            );
        }
        return <FloorTabsListContainer>
            <FloorTabsList
                fixed={ fixed }
                fixedMobile={ fixedMobile }
                fixedTablet={ fixedTablet }
                naviExtended={ naviExtended }
            >
                <ScrollContainer>
                    { props.content.map((child, index) => {
                        if (child) {
                            const layer = child.title ? child.title.toLowerCase() : '';
                            const selected = selectedTab === layer || !selectedTab && index === 0;
                            const tooltipContent = child.tooltipContent;
                            if (tooltipContent && !selected && fixed === false) {
                                return (
                                    <TabLabel
                                        ref={ el => { self.labelRefs[index] = el; } }
                                        onMouseOver={ () => this.handleInfoTipMouseOver(index) }
                                        key={ index }
                                    >
                                        <StyledInfotip
                                            offsetLeft={ this.state.toolTipStyles[index]
                                                && this.state.toolTipStyles[index].left }
                                            offsetTop={ this.state.toolTipStyles[index]
                                                && this.state.toolTipStyles[index].top }
                                            index={ index }
                                            text={ tooltipContent }
                                        >
                                            { this.renderTabLabel(child, index, selected, props.location, props.t) }
                                        </StyledInfotip>
                                    </TabLabel>
                                );
                            }

                            return (
                                <TabLabel key={ index }>
                                    { this.renderTabLabel(child, index, selected, props.location, props.t) }
                                </TabLabel>
                            );
                        }
                        return null;
                    }) }
                </ScrollContainer>
            </FloorTabsList>
        </FloorTabsListContainer>;
    };

    renderContent(props, selectedTab) {
        return (
            <Fragment>
                { props.content.map((child, index) => {
                    if (child) {
                        const layer = child.title ? child.title.toLowerCase() : '';
                        const selected = selectedTab === layer || !selectedTab && index === 0;

                        if (selected) {
                            const newChild = React.cloneElement(
                                child.content,
                                {
                                    isSelectedStickyTab: selected,
                                    selectedTab,
                                    renderTabLabel: this.renderTabLabel,
                                    toggleExpand: this.toggleExpand,
                                    isExpanded: this.state.isExpanded,
                                    content: props.content
                                }
                            );
                            return (
                                <FloorTabItem selected={ selected } key={ index }>
                                    { newChild }
                                </FloorTabItem>
                            );
                        }
                        return null;
                    }
                    return child;
                }) }
            </Fragment>
        );
    }

    render() {
        const props = this.props;

        const query = queryString.parse(props.location.search);
        const selectedTab = query.layer;
        const noNavi = query.noNavi === 'true';

        if (!props.content) {
            return null;
        }

        return (
            <FloorTabsContainer>
                { !noNavi && this.renderLabels(props, this.state, selectedTab) }
                { this.renderContent(props, selectedTab) }
            </FloorTabsContainer>
        );
    }
}

FloorTabs.propTypes = {
    t: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    content: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        performance: PropTypes.oneOf(['ok', 'warning', 'neutral']),
        content: PropTypes.element,
        tooltipContent: PropTypes.element
    })),
    scroll: PropTypes.shape({
        scrollTop: PropTypes.number.isRequired
    }).isRequired,
    naviExtended: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    scroll: state.common.scroll,
    naviExtended: state.navigation.sideNaviExtended
});

export default withRouter(connect(mapStateToProps)(FloorTabs));
