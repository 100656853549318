import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { filter, includes } from 'lodash';

import Section from 'components/Section/Section';
import { STATISTICS_TYPES } from 'constants/serviceCalendar';
import ServiceOrderStatistics from '../../containers/Application/ServiceOrderStatistics/ServiceOrderStatistics';
import CalendarBodyHead from './CalendarBodyHead';
import CalendarListView from './CalendarListView';
import { OrderType } from 'constants/serviceCalendar';
import translations from 'decorators/Translations/translations';
import ServiceOrderRow from './ServiceOrderRow';
import CalendarView from './CalendarView';
import { ViewMode } from 'constants/serviceCalendar';
import CalendarConfiguration from './CalendarConfiguration/CalendarConfiguration';
import SnackBar from 'components/SnackBar/SnackBar';


const StyledSection = styled(Section)`
    padding: 0;
    padding-bottom: ${props => props.theme.spacing.lg};

    ${props => props.theme.media.portrait`
        padding: ${props => `${props.theme.section.verticalSpacing} ${!props.noPadding ? props.theme.grid.gutter : 0}`};
        padding-bottom: ${props => props.theme.spacing.lg};
    `}
`;

const MainContent = styled.div`
    padding: 0 ${props => props.theme.spacing.md};

    ${props => props.theme.media.portrait`
        padding: 0;
    `}
`;

const getColumns = memoizeOne((t, orderType) => {
    switch (orderType) {
    case OrderType.ORDER:
        return [{
            title: t('Status'),
            label: t('Status Name'),
            field: 'status',
            sortable: true,
            hideOnMobile: true,
            width: '50px',
        }, {
            title: t('Order'),
            label: t('Service Order Name'),
            field: 'title',
            sortable: true,
            width: '35%',
            mobileWidth: 'auto',
        }, {
            title: t('Created'),
            field: 'createdDate',
            sortable: true,
            align: 'left',
            width: '140px',
            mobileWidth: '135px',
        }, {
            title: t('Updated'),
            field: 'modifiedDate',
            sortable: true,
            width: '140px',
            align: 'left',
            hideOnMobile: true,
        }, {
            title: t('Type'),
            label: t('Activity Type'),
            field: 'type',
            sortable: true,
            width: 'auto',
            align: 'left',
            hideOnMobile: true,
        }, {
            title: t('Reaction Time'),
            field: 'reactionTime',
            sortable: true,
            align: 'left',
            width: 'auto',
            hideOnMobile: true,
        }, {
            title: t('Lead Time'),
            field: 'leadTime',
            sortable: true,
            align: 'left',
            width: 'auto',
            hideOnMobile: true,
        }, {
            title: t('Downtime'),
            field: 'downtime',
            sortable: true,
            align: 'left',
            width: 'auto',
            hideOnMobile: true,
        }, {
            title: t('Priority'),
            field: 'priority',
            sortable: true,
            align: 'center',
            width: 'auto',
            hideOnMobile: true,
        }, {
            title: t('Discipline'),
            field: 'discipline',
            sortable: true,
            hideOnMobile: true,
        }, {
            title: t('Location'),
            field: 'location',
            sortable: true,
            hideOnMobile: true,
        }, {
            title: '',
            field: 'link',
            sortable: false,
            width: '20px',
            hideOnMobile: true,
        }];
    case OrderType.PLANNED:
        return [{
            title: t('Status'),
            label: t('Status Name'),
            field: 'status',
            sortable: true,
            hideOnMobile: true,
            width: '50px',
        }, {
            title: t('Order'),
            label: t('Service Order Name'),
            field: 'title',
            sortable: true,
            width: '35%',
            mobileWidth: 'auto',
        }, {
            title: t('Updated'),
            field: 'modifiedDate',
            sortable: true,
            align: 'left',
            width: '140px',
            mobileWidth: '135px',
        }, {
            title: t('Planned Date'),
            field: 'plannedDate',
            sortable: true,
            width: '140px',
            align: 'left',
            hideOnMobile: true,
        }, {
            title: t('Type'),
            label: t('Activity Type'),
            field: 'type',
            sortable: true,
            width: 'auto',
            align: 'left',
            hideOnMobile: true,
        }, {
            title: t('Discipline'),
            field: 'discipline',
            sortable: true,
            hideOnMobile: true,
        }, {
            title: t('Location'),
            field: 'location',
            sortable: true,
            hideOnMobile: true,
        }, {
            title: '',
            field: 'link',
            sortable: false,
            width: '20px',
            hideOnMobile: true,
        }];
    default:
        return [];
    }
});

const getConfiguredColumns = memoizeOne((columns, configuration) =>
    filter(columns, column => includes(configuration, column.field))
);

const setYear = memoizeOne((location, query, history) => year => {
    const { pathname } = location;
    const nextQuery = queryString.stringify({ ...query, year });
    history.push(`${pathname}?${nextQuery}`);
});

const CalendarBody = ({
    activeFilter,
    functionalLocations,
    equipmentTexts,
    history,
    loading,
    loadingFLs,
    location,
    monthlyCounts,
    orders,
    orderType,
    partnerNumber,
    t,
    year,
    query,
    setStatisticsView,
    showServiceOrder,
    match: { params: { functionalLocationId } },
    listConfiguration,
}) => {

    const [showConfiguration, setShowConfiguration] = React.useState(false);
    const [notification, setNotification] = React.useState({ type: '', visible: false, message: '' });

    const columns = getConfiguredColumns(getColumns(t, orderType), listConfiguration);

    const statistics = React.useMemo(() => ({
        show: () => setStatisticsView({
            visible: true,
            orderType,
            statisticsType: STATISTICS_TYPES[orderType][0],
        }),
        hide: () => setStatisticsView({ visible: false }),
        setType: statisticsType => setStatisticsView({ statisticsType }),
    }), [setStatisticsView, orderType]);

    return (
        <React.Fragment>
            <StyledSection>
                <CalendarBodyHead
                    t={ t }
                    showStatistics={ statistics.show }
                    setShowConfiguration={ setShowConfiguration }
                />
                <MainContent>
                    { query.mode === ViewMode.CALENDAR ?
                        <CalendarView
                            loading={ loading }
                            location={ location }
                            monthlyCounts={ monthlyCounts }
                            orders={ orders.filtered }
                            partnerNumber={ partnerNumber }
                            year={ year }
                            query={ query }
                        />
                        :
                        <CalendarListView
                            columns={ columns }
                            RowComponent={ rowProps => <ServiceOrderRow columns={ columns } { ...rowProps } /> }
                            loading={ loading }
                            serviceOrders={ orders.selected }
                            showServiceOrder={ showServiceOrder }
                        />
                    }
                </MainContent>
            </StyledSection>
            <ServiceOrderStatistics
                serviceOrders={ orders.selected }
                year={ year }
                loading={ loading }
                loadingFLs={ loadingFLs }
                functionalLocations={ functionalLocations }
                equipmentTexts={ equipmentTexts }
                setYear={ setYear(location, query, history) }
                setStatisticsType={ statistics.setType }
                hide={ statistics.hide }
                activeFilter={ activeFilter }
                partnerNumber={ partnerNumber }
                hideBuildingStats={ !!functionalLocationId }
            />
            <CalendarConfiguration
                t={ t }
                showConfiguration={ showConfiguration }
                setShowConfiguration={ setShowConfiguration }
                configurationOptions={ getColumns(t, orderType) }
                configuration={ listConfiguration }
                setNotification={ setNotification }
                orderType={ orderType }
            />
            <SnackBar variant={ notification.type } visible={ notification.visible }>
                { notification.message }
            </SnackBar>
        </React.Fragment>
    );
};

CalendarBody.propTypes = {
    activeFilter: PropTypes.object.isRequired,
    functionalLocations: PropTypes.objectOf(PropTypes.object).isRequired,
    equipmentTexts: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingFLs: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    monthlyCounts: PropTypes.arrayOf(PropTypes.number).isRequired,
    orders: PropTypes.shape({
        filtered: PropTypes.arrayOf(PropTypes.object).isRequired,
        selected: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
    orderType: PropTypes.oneOf([OrderType.ORDER, OrderType.PLANNED]).isRequired,
    partnerNumber: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    year: PropTypes.number.isRequired,
    query: PropTypes.object.isRequired,
    setStatisticsView: PropTypes.func.isRequired,
    showServiceOrder: PropTypes.func.isRequired,
    listConfiguration: PropTypes.arrayOf(PropTypes.string),
};

export default withRouter(translations(React.memo(CalendarBody)));
