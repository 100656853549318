import moment from 'moment-timezone';
import _ from 'lodash';

const getAreaForSensorId = (floor, sensorId) => {
    const sensor = _.find(floor.sensorList, { id: parseInt(sensorId, 10) });
    if (!sensor) {
        return null;
    }
    const floorAreas = _.find(floor.children, { type: 'area ' });
    const area = _.find(floorAreas, area => area.id === parseInt(sensor.sensorHierarchyId, 10));
    return area ? area.name : null;
};

export const mapCleaningData = (t, value, floor) => ({
    area: {
        value: getAreaForSensorId(value.sensorId) ||
        floor && `${t('Floor')} ${floor.shortName || floor.name}` || '',
        id: parseInt(value.sensorId, 10)
    },
    date: { date: value.timestamp, value: moment.utc(value.timestamp).local().valueOf() },
    value: value.value
});

export const getLatestCleaningData = cleaningData => {
    const latestCleaning = _.first(_.orderBy(cleaningData, 'date.date', 'desc'));
    let latestCleaningTime;
    let isCleanedToday = false;
    if (latestCleaning) {
        const latestCleaningMoment = moment.utc(latestCleaning.date.date).local();
        const dayAgo = moment().subtract(1, 'days');
        isCleanedToday = latestCleaningMoment.isAfter(dayAgo);
        latestCleaningTime = isCleanedToday ? _.capitalize(latestCleaningMoment.fromNow())
            : latestCleaningMoment.format('D.M.YYYY - HH:mm');
    }

    return { latestCleaning, latestCleaningTime, isCleanedToday };
};

// Use UTC noon to minimise the possibility of timezone-related issues when comparing dates.
// Must be a function to use an explicit locale instead of the default (english).
export const cleaningDates = () =>
    _.map(Array(7), (v, i) => moment.utc().subtract(i, 'days').startOf('day').add(12, 'hours')).reverse();
