import { createReducerFromMapping } from 'redux/utils/index.js';
import _, { keyBy } from 'lodash';
import { MasterData } from '@caverion/redux/api/actions';

import { filterPathByPermissions, hasPartnerPermission } from 'utils/profile';
import { LOAD_PERMISSIONS_SUCCESS } from '../profile/profile';

const initialState = {
    loading: [],
    functionalLocations: {}
};

export const resolveFunctionalLocationType = functionalLocation => functionalLocation ? functionalLocation.type : '';

export const LOAD = 'CUSTOMER_PLATFORM/FunctionalLocations/LOAD';
export const LOAD_SUCCESS = 'CUSTOMER_PLATFORM/FunctionalLocations/LOAD_SUCCESS';
export const LOAD_FAIL = 'CUSTOMER_PLATFORM/FunctionalLocations/LOAD_FAIL';

export const loadFunctionalLocations = functionalLocations => async dispatch => {
    const key = JSON.stringify([LOAD, functionalLocations]);
    if (!functionalLocations.length) {
        return dispatch({
            type: LOAD_SUCCESS,
            key,
            result: [],
        });
    }

    dispatch({ type: LOAD, key });
    try {
        const result = await dispatch(MasterData.hierarchiesByFunctionalLocations(functionalLocations));
        return dispatch({
            type: LOAD_SUCCESS,
            key,
            result
        });
    } catch (error) {
        return dispatch({
            type: LOAD_FAIL,
            key,
            error
        });
    }
};

// TODO: Could this be removed now that loadFunctionalLocations uses hierarchiesByFunctionalLocations?
export const loadFunctionalLocationsByPath = (partnerNumber, path) => async (dispatch, getState) => {
    const key = JSON.stringify([LOAD, path]);
    const profile = getState().profile.profile;
    let params = {};

    // Construct query params based on user permissions
    if (hasPartnerPermission(profile, partnerNumber)) {
        params = {
            filter: {
                where: {
                    partnerNumber,
                },
            },
            path,
        };
    } else {
        const authorizedPath = filterPathByPermissions(path, partnerNumber, profile);
        if (!authorizedPath.length) {
            return dispatch({
                type: LOAD_SUCCESS,
                key,
                result: [],
            });
        }

        params = {
            filter: {
                where: {
                    functionalLocation: {
                        inq: authorizedPath,
                    },
                },
            },
        };
    }

    dispatch({ type: LOAD, key });
    try {
        // API has special handling for hierarchies endpoint if 'path' parameter is provided to allow fetching
        // hierarchies with partner permissions properly. Using this special parameter if partnerNumber is provided.
        const result = await dispatch(MasterData.hierarchies(params.filter, params.path));
        return dispatch({
            type: LOAD_SUCCESS,
            key,
            result,
        });
    } catch (error) {
        return dispatch({
            type: LOAD_FAIL,
            key,
            error,
        });
    }
};

const loadFunctionalLocationSuccessReducer = (state, action) => ({
    ...state,
    loading: state.loading.filter(x => x !== action.key),
    functionalLocations: {
        ...state.functionalLocations,
        ...keyBy(action.result, 'functionalLocation'),
    },
});

const loadPermissionsSuccessReducer = (state, action) => ({
    ...state,
    functionalLocations: {
        ...state.functionalLocations,
        ...keyBy(action.result, 'functionalLocation'),
    },
});

export default createReducerFromMapping({
    [LOAD]: (state, action) => ({
        ...state,
        loading: state.loading.concat([action.key])
    }),
    [LOAD_SUCCESS]: loadFunctionalLocationSuccessReducer,
    [LOAD_FAIL]: (state, action) => ({
        ...state,
        loading: state.loading.filter(x => x !== action.key),
        functionalLocations: _.assign({}, state.functionalLocations,
            ...action.result.map(x => ({ [x.functionalLocation]: x }))
        )
    }),
    [LOAD_PERMISSIONS_SUCCESS]: loadPermissionsSuccessReducer,
}, initialState);
