import styled from 'styled-components';

export const PermissionListItemIcon = styled.div`
    width: calc(40px + 2 * ${props => props.theme.spacing.sm});
    min-width: 70px;
    display: flex;
    justify-content: center;
    font-size: ${props => props.theme.font.size.sm};
    div {
        margin-top: 0;
    }
`;
