import React, { Fragment, memo } from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import memoizeOne from 'memoize-one';
import SortableTable from 'components/SortableTable/SortableTable';
import SortableFLTableRow from './SortableFLTableRow';
import { SortableFLTableSkeleton } from './SortableFLTableSkeleton';

const NoResult = styled.p`
    font-family: ${props => props.theme.fontFamily.text};
    font-size: ${props => props.theme.fontSize.xs};
    color: ${props => props.theme.colors.darkGray};
    margin: ${props => props.theme.spacing.md};
`;

const getColumns = memoizeOne((t, hideAddress, hasEquipment, isEquipment, showPerformance) => {
    const columns = [{
        title: '',
        field: 'image',
        width: '48px',
        hideOnMobile: true
    },
    {
        title: t('Name'),
        field: 'description',
        sortable: true
    }];

    if (!hideAddress) {
        columns.push({
            title: t('Address'),
            field: 'address',
            sortable: true,
            hideOnMobile: true
        });
    }

    if (hasEquipment) {
        columns.push({
            title: isEquipment ? t('Subequipment') : t('Equipment'),
            field: 'equipmentCount',
            sortable: true,
            hideOnMobile: true
        });
    }

    if (showPerformance) {
        columns.push({
            title: t('Performance'),
            field: 'performance',
            sortable: true,
            hideOnMobile: true
        });
    }

    columns.push({
        title: '',
        field: 'icon'
    });

    return columns;
});

export const SortableFLTable = ({
    loading,
    t,
    sortedFunctionalLocations,
    hasEquipment,
    isEquipment,
    hideAddress,
    removeHead,
    orderBy = 'description',
    order = 'asc',
    showPerformance
}) => {
    const columns = getColumns(t, hideAddress, hasEquipment, isEquipment, showPerformance);
    return (
        <Fragment>
            <SortableTable
                scrollTop
                showControls
                stripes={false}
                RowComponent={SortableFLTableRow}
                SkeletonBodyComponent={ SortableFLTableSkeleton }
                loading={loading}
                columns={columns}
                data={sortedFunctionalLocations}
                orderBy={ orderBy }
                order={ order }
                t={t}
                initialRows={20}
                paginate
                removeHead={ removeHead }
                data-test-id="FLTable"
            />
            { !loading && sortedFunctionalLocations.length === 0 && <NoResult>{t('No results found')}</NoResult> }
        </Fragment>
    );
};

export default memo(SortableFLTable, (prevProps, nextProps) =>
    isEqual(prevProps.sortedFunctionalLocations, nextProps.sortedFunctionalLocations) &&
    isEqual(prevProps.loading, nextProps.loading) &&
    isEqual(prevProps.page, nextProps.page)
);


