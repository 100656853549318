import React from 'react';
import PropTypes from 'prop-types';
import StatusInfos from 'components/StatusInfo/StatusInfos';
import StatusInfo from 'components/StatusInfo/StatusInfo';
import { connect } from 'react-redux';
import { loadKPIContainer } from 'redux/modules';
import moment from 'moment-timezone';
import { includes, throttle } from 'lodash';
import { isValidPartner } from 'utils/Data/partners';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { percentage } from 'utils/math';
import { createSelector, createStructuredSelector } from 'reselect';
import { sumBy, get, keyBy, keys, uniq, replace, capitalize, values, mean } from 'lodash';
import ConsumptionKPI from './ConsumptionKPI';

const formatEnergyValue = value => typeof value === 'number' ? (value / 1000).toFixed(1) : value;
const formatWaterValue = value => typeof value === 'number' ? value.toFixed(1) : value;

const KPIModule = ({ dispatch, t, theme, profileKpis, partnerNumber, history, profile, sideNaviExtended, kpis }) => {
    const getCardCount = () => {
        const width = window.innerWidth;
        if (width >= theme.breakpoints.bigDesktop) {
            return 6;
        }
        if (width >= 1400 || width >= theme.breakpoints.desktop && !sideNaviExtended) {
            return 5;
        }
        if (width >= 1200 || width >= theme.breakpoints.landscape && !sideNaviExtended) {
            return 4;
        }

        return sideNaviExtended ? 3 : 4;
    };
    const [cardCount, setCardCount] = React.useState(getCardCount());
    const throttledSetCardCount = throttle(() => setCardCount(getCardCount()), 100);

    React.useEffect(() => {
        window.addEventListener('resize', throttledSetCardCount);

        return () => {
            window.removeEventListener('resize', throttledSetCardCount);
        };
    }, []);

    React.useEffect(() => {
        if (partnerNumber) {
            dispatch(loadKPIContainer(
                partnerNumber, profile.serviceOrderStartDate, profileKpis, profile.buildingPerformanceSource
            ));
        }
    }, [partnerNumber]);

    const partnerPart = !isValidPartner(partnerNumber) ? '' : `/${partnerNumber}`;
    const serviceCalendarLink = `${partnerPart}/ServiceCalendar/ServiceOrders?year=${moment().format('YYYY')}`;
    const plannedMaintenanceLink = `${partnerPart}/ServiceCalendar/PlannedMaintenance?year=${moment().format('YYYY')}`;
    const alarmsLink = `${partnerPart}/RemoteCenter/Alarms`;

    const commonProps = {
        t,
        sideNaviExtended,
        valuePosition: 'outside'
    };

    const consumptionProps = {
        ...commonProps,
        history,
        partnerNumber,
        isConsumption: true
    };

    const cards = [];
    includes(profileKpis, 'serviceOrders') && cards.push({
        ...commonProps,
        loading: kpis.serviceOrders.loading,
        heading: t('Service Orders'),
        value: kpis.serviceOrders.value,
        unit: '%',
        spaceBeforeUnit: false,
        color: kpis.serviceOrders.value >= 90 && theme.colors.emerald,
        valueNote: `${t('Completed')} (${moment().format('YYYY')})`,
        onClick: () => history.push(serviceCalendarLink),
        ctxHelp: `${CTXHELP_PREFIX} KPI Service Orders`
    });
    includes(profileKpis, 'plannedMaintenance') && cards.push({
        ...commonProps,
        loading: kpis.plannedMaintenance.loading,
        heading: t('Planned Maintenance'),
        value: kpis.plannedMaintenance.value,
        unit: '%',
        spaceBeforeUnit: false,
        valueNote: `${t('Completed')} (${moment().format('YYYY')})`,
        onClick: () => history.push(plannedMaintenanceLink),
        ctxHelp: `${CTXHELP_PREFIX} KPI Planned Maintenance`,
        color: theme.status.neutralColor
    });
    includes(profileKpis, 'alarms') && cards.push({
        ...commonProps,
        loading: kpis.alarms.loading,
        heading: t('Alarms'),
        value: kpis.alarms.value,
        unit: '%',
        spaceBeforeUnit: false,
        valueNote: `${t('Handled')} (${t('Last 365 Days')})`,
        ctxHelp: `${CTXHELP_PREFIX} KPI Alarms`,
        onClick: () => history.push(alarmsLink),
    });
    includes(profileKpis, 'alarmHandlingTime') && cards.push({
        ...commonProps,
        loading: kpis.alarmHandlingTime.loading,
        heading: t('Alarm Handling Time'),
        value: kpis.alarmHandlingTime.mean,
        positiveValue: kpis.alarmHandlingTime.positiveValue,
        unit: t('min'),
        valueNote: `${t('Average')} (${t('Last 365 days')})`,
        ctxHelp: `${CTXHELP_PREFIX} KPI Alarm Handling Time`,
        onClick: () => history.push(alarmsLink),
        isPerformance: true,
    });
    includes(profileKpis, 'alarmTimeToAction') && cards.push({
        ...commonProps,
        loading: kpis.alarmTimeToAction.loading,
        heading: t('Alarm Time to Action'),
        value: kpis.alarmTimeToAction.mean,
        positiveValue: kpis.alarmTimeToAction.positiveValue,
        unit: t('min'),
        valueNote: `${t('Average')} (${t('Last 365 days')})`,
        ctxHelp: `${CTXHELP_PREFIX} KPI Alarm Time to Action`,
        onClick: () => history.push(alarmsLink),
        isPerformance: true
    });
    includes(profileKpis, 'inspections') && cards.push({
        ...commonProps,
        loading: kpis.inspection.loading,
        heading: t('Inspections'),
        value: kpis.inspection.value,
        valueNote: `${t('Completed / SLA')} (${moment().format('YYYY')})`,
        bars: kpis.inspection.slas,
        ctxHelp: `${CTXHELP_PREFIX} KPI Inspections`,
        onClick: () => history.push(`${partnerPart}/RemoteCenter/Inspections`),
    });
    includes(profileKpis, 'observations') && cards.push({
        ...commonProps,
        loading: kpis.observation.loading,
        heading: t('Observations'),
        value: kpis.observation.value,
        unit: '%',
        spaceBeforeUnit: false,
        valueNote: `${t('Completed')} (${t('Last 365 Days')})`,
        ctxHelp: `${CTXHELP_PREFIX} KPI Observations`,
        onClick: () => history.push(`${partnerPart}/RemoteCenter/Observations`),
    });
    includes(profileKpis, 'energy') && cards.push({
        ...commonProps,
        'data-test-id': 'KpiPartnerEnergy',
        loading: kpis.energyRating.loading,
        precision: 1,
        heading: t('Energy Rating'),
        value: kpis.energyRating.latest,
        valueNote: capitalize(moment().subtract(1, 'month').format('MMMM')),
        unit: 'kWh/m²',
        positiveValue: kpis.energyRating.positiveValue,
        positiveValueDirection: 'down',
        onClick: () => history.push(`/${partnerNumber}/EnergyOptimization`),
        ctxHelp: `${CTXHELP_PREFIX} KPI Energy Rating`
    });
    includes(profileKpis, 'energyConsumption') && cards.push({
        ...consumptionProps,
        kpi: kpis.energyConsumption,
        heading: 'Energy consumption',
        unit: 'MWh',
        formatValue: formatEnergyValue,
        ctxHelp: `${CTXHELP_PREFIX} KPI Energy Consumption`
    });
    includes(profileKpis, 'electricity') && cards.push({
        ...consumptionProps,
        kpi: kpis.electricity,
        heading: 'Electricity',
        unit: 'MWh',
        formatValue: formatEnergyValue,
        ctxHelp: `${CTXHELP_PREFIX} KPI Electricity`
    });
    includes(profileKpis, 'districtHeating') && cards.push({
        ...consumptionProps,
        kpi: kpis.districtHeating,
        heading: 'District heating',
        unit: 'MWh',
        formatValue: formatEnergyValue,
        ctxHelp: `${CTXHELP_PREFIX} KPI District Heating`
    });
    includes(profileKpis, 'water') && cards.push({
        ...consumptionProps,
        kpi: kpis.water,
        heading: 'Water',
        unit: 'm³',
        formatValue: formatWaterValue,
        ctxHelp: `${CTXHELP_PREFIX} KPI Water`
    });
    includes(profileKpis, 'indoorAirQuality') && cards.push({
        ...commonProps,
        loading: kpis.airQuality.loading,
        heading: t('Indoor Air Quality'),
        value: kpis.airQuality.value,
        unit: '%',
        spaceBeforeUnit: false,
        valueNote: t('7 Days Average'),
        ctxHelp: `${CTXHELP_PREFIX} KPI Indoor Air Quality`,
        isAirQuality: true
    });

    return (
        <StatusInfos>
            { cards.map((card, index) => {
                const ctxHelpPosition = cardCount && (index + 1) % cardCount === 0 && 'left';
                const props = {
                    key: card.heading,
                    ctxHelpPosition: ctxHelpPosition || undefined
                };

                if (card.isConsumption) {
                    return <ConsumptionKPI { ...card } { ...props } />;
                }
                return <StatusInfo { ...card } { ...props } />;
            }) }
        </StatusInfos>
    );
};

KPIModule.propTypes = {
    t: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
    profileKpis: PropTypes.array.isRequired,
    partnerNumber: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    kpis: PropTypes.objectOf(PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        chart: PropTypes.shape({
            type: PropTypes.oneOf(['line', 'column']),
            data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        }),
    })).isRequired,
    sideNaviExtended: PropTypes.bool.isRequired,
};

const getEnergyRatingKPI = createSelector(
    state => state.values.energyRating,
    energyRating => {
        const { latest, previous } = energyRating.data.averages;
        return {
            loading: energyRating.loading,
            latest,
            previous,
            positiveValue: latest && previous && latest < previous || false
        };
    }
);

const getServiceOrderKPI = createSelector(
    state => state.serviceOrders.kpiCounts,
    counts => ({
        loading: counts.loading,
        value: percentage(counts.serviceOrders.completed, counts.serviceOrders.total),
    })
);

const getPlannedMaintenanceKPI = createSelector(
    state => state.serviceOrders.kpiCounts,
    counts => ({
        loading: counts.loading,
        value: percentage(counts.plannedMaintenances.completed, counts.plannedMaintenances.total),
    })
);

const getAlarmKPI = createSelector(
    (state, props) => state.alarm.byPartner[props.partnerNumber],
    state => state.alarm.byPartner.loading,
    (alarms, loading) => {
        const total = alarms ? alarms.total : null;
        const handled = alarms ? alarms.handled : null;
        return {
            loading,
            value: alarms ? percentage(handled, total) : undefined,
        };
    }
);

const getConsumptionKPI = kpi => {
    const breakdown = get(kpi, 'data.breakdown', []);
    return {
        loading: get(kpi, 'loading', false),
        value: breakdown.length ? sumBy(breakdown, 'value') : null,
        current: breakdown.length ? get(kpi, 'data.current') : null,
        previous: breakdown.length ? get(kpi, 'data.previous') : null,
        chart: {
            type: 'column',
            data: breakdown && {
                categories: breakdown.map(value => moment.utc(value.timestamp)),
                values: breakdown.map(value => value.value),
            },
        },
        breakdown,
    };
};

const getElectricityKPI = createSelector(state => state.values.consumptionKPIs.electricity_main, getConsumptionKPI);

const getWaterKPI = createSelector(state => state.values.consumptionKPIs.water_consumption, getConsumptionKPI);

const getDistrictHeatingKPI = createSelector(state => state.values.consumptionKPIs.district_heating, getConsumptionKPI);

const getEnergyConsumptionKPI = createSelector(
    getElectricityKPI,
    getDistrictHeatingKPI,
    (electricity, heating) => {
        if (electricity.loading || heating.loading) {
            return {
                loading: true,
                chart: {
                    type: 'column',
                    data: {},
                },
            };
        }

        const electricityValues = keyBy(electricity.breakdown, 'timestamp');
        const heatingValues = keyBy(heating.breakdown, 'timestamp');
        const values = uniq([...keys(electricityValues), ...keys(heatingValues)]).map(timestamp =>
            get(electricityValues[timestamp], 'value', 0) + get(heatingValues[timestamp], 'value', 0)
        );

        const noValue = electricity.value === null && heating.value === null;
        return {
            loading: false,
            value: noValue ? null : electricity.value + heating.value,
            chart: {
                type: 'column',
                data: electricity.breakdown && {
                    categories: electricity.breakdown.map(value => moment.utc(value.timestamp)),
                    values,
                },
            },
        };
    }
);

const alarmCombiner = (kpi, loading, limit) => {
    const mean = kpi ? kpi.mean : null;
    return {
        loading,
        mean,
        positiveValue: mean < limit
    };
};

const getAlarmHandlingTimeKPI = createSelector(
    (state, props) => state.alarm.handlingTimeKPI[props.partnerNumber],
    state => state.alarm.handlingTimeKPI.loading,
    () => 60,
    alarmCombiner
);

const getAlarmTimeToActionKPI = createSelector(
    (state, props) => state.alarm.timeToActionKPI[props.partnerNumber],
    state => state.alarm.timeToActionKPI.loading,
    () => 180,
    alarmCombiner
);

const getObservationKPI = createSelector(
    (state, props) => state.notice.observationKPI[props.partnerNumber],
    state => state.notice.observationKPI.loading,
    (observationKPI, loading) => {
        const total = observationKPI ? observationKPI.total : null;
        const completed = observationKPI ? observationKPI.completed : null;
        return {
            loading,
            value: observationKPI ? percentage(completed, total) : undefined,
            total,
            completed,
        };
    }
);
const getInspectionKPI = createSelector(
    (state, props) => state.notice.inspectionKPI[props.partnerNumber],
    state => state.notice.inspectionKPI.loading,
    (inspectionKPI, loading) => {
        const slas = inspectionKPI && Object.keys(inspectionKPI.sla).reduce((slaList, key) => {
            const sla = inspectionKPI.sla[key];
            if (key === 'adhocSlaTarget') {
                return slaList;
            }
            const data = {
                count: sla.count || 0,
                reference: sla.sla || 0,
                title: `${capitalize(replace(key, 'SlaTarget', ''))} Monitoring`
            };
            slaList.push(data);
            return slaList;
        }, []) || [];

        const slaCount = inspectionKPI ? sumBy(slas, 'reference') : null;
        const total = inspectionKPI ? inspectionKPI.counts.total : null;
        return {
            loading,
            value: inspectionKPI ? `${total} / ${slaCount}` : '',
            slas
        };
    }
);

const getAirQualityKPI = createSelector(
    state => state.values.airQualityKPI,
    kpi => {
        const flValues = kpi.data.moving7dAverage && values(kpi.data.moving7dAverage);
        const moving7dAverage = flValues && flValues.length ? mean(flValues) : null;
        return {
            loading: kpi.loading,
            value: moving7dAverage,
        };
    }
);

const getKPIs = createStructuredSelector({
    energyRating: getEnergyRatingKPI,
    serviceOrders: getServiceOrderKPI,
    plannedMaintenance: getPlannedMaintenanceKPI,
    alarms: getAlarmKPI,
    electricity: getElectricityKPI,
    water: getWaterKPI,
    districtHeating: getDistrictHeatingKPI,
    energyConsumption: getEnergyConsumptionKPI,
    observation: getObservationKPI,
    alarmHandlingTime: getAlarmHandlingTimeKPI,
    alarmTimeToAction: getAlarmTimeToActionKPI,
    inspection: getInspectionKPI,
    airQuality: getAirQualityKPI,
});

const mapStateToProps = (state, props) => ({
    profile: state.profile.profile,
    profileKpis: state.profile.profile.kpis || [],
    kpis: getKPIs(state, props),
    sideNaviExtended: state.navigation.sideNaviExtended,
});

export default connect(mapStateToProps)(KPIModule);
