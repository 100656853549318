import { createSelector } from 'reselect';

import { getFunctionalLocations, getLoadingFLs, getProfile } from './common';
import { formatOrderForCalendar } from 'utils/Data/serviceOrders';

/**
 * Get search results for active section.
 * Expects the following parameters: section
 */
export const getSearchResults = (state, { section }) => state.serviceOrders.search[section] ?
    state.serviceOrders.search[section].items :
    undefined;

/**
 * Get search results for the active section, merging orders with functional locations.
 * Expects the following parameters: section, loadingFLs
 */
export const getFormattedSearchResults = createSelector(
    [getSearchResults, getFunctionalLocations, getLoadingFLs, getProfile],
    (results, functionalLocations, loadingFLs, profile) => {
        if (!results) {
            return undefined;
        }

        return results.map(result => formatOrderForCalendar(result, functionalLocations, profile, false, loadingFLs));
    }
);
