import { createReducerFromMapping } from 'redux/utils/index.js';
import { IoT } from '@caverion/redux/api/actions';

const initialState = {
    loading: false
};

export const EXPORT_SENSOR_DATA = 'CUSTOMER_PLATFORM/Export/EXPORT_SENSOR_DATA';
export const EXPORT_SENSOR_DATA_SUCCESS = 'CUSTOMER_PLATFORM/Export/EXPORT_SENSOR_DATA_SUCCESS';
export const EXPORT_SENSOR_DATA_FAIL = 'CUSTOMER_PLATFORM/Export/EXPORT_SENSOR_DATA_FAIL';

export const exportSensorData = data => async dispatch => {
    dispatch({ type: EXPORT_SENSOR_DATA });
    try {
        await dispatch(IoT.exportValues(data));
        return dispatch({ type: EXPORT_SENSOR_DATA_SUCCESS });
    } catch (error) {
        return dispatch({
            type: EXPORT_SENSOR_DATA_FAIL,
            error
        });
    }
};

export default createReducerFromMapping({
    [EXPORT_SENSOR_DATA]: (state, action) => ({
        ...state,
        loading: true
    }),
    [EXPORT_SENSOR_DATA_SUCCESS]: (state, action) => ({
        ...state,
        loading: false
    }),
    [EXPORT_SENSOR_DATA_FAIL]: (state, action) => ({
        ...state,
        loading: false
    }),
}, initialState);
