import _ from 'lodash';
import Cookies from 'utils/Cookies/Cookies.js';

export const getWelcomeUrl = () => {
    const cookie = Cookies.getItem('language');
    if (!cookie) {
        return `${window.location.origin}/welcome.html`;
    }

    const languages = _.uniq(cookie.split(',').map(lang => lang.split(/[_-]/)[0]));
    const languageParts = languages.map(lang => {
        if (lang === 'nn' || lang === 'nb') {
            return '_no';
        }
        if (lang === 'sv') {
            return '_sv';
        }
        if (lang === 'fi') {
            return '_fi';
        }
        if (lang === 'da') {
            return '_da';
        }
        if (lang === 'de') {
            return '_de';
        }
        if (lang === 'ru') {
            return '_ru';
        }
        return '';
    });

    return `${window.location.origin}/welcome${languageParts[0]}.html`;
};

export const getWelcomeUrlForLanguage = lang => {
    let languagePart = '';

    if (lang === 'nn' || lang === 'nb') {
        languagePart = '_no';
    }
    if (lang === 'sv') {
        languagePart = '_sv';
    }
    if (lang === 'fi') {
        languagePart = '_fi';
    }
    if (lang === 'da') {
        languagePart = '_da';
    }
    if (lang === 'de') {
        languagePart = '_de';
    }
    if (lang === 'ru') {
        languagePart = '_ru';
    }

    return `${window.location.origin}/welcome${languagePart}.html`;
};

// Convert mistakenly used se to sv
export const fixLanguage = language => language === 'se' ? 'sv' : language;
