import React from 'react';
import styled, { keyframes } from 'styled-components';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import { isNil } from 'lodash';
import { colors } from '../../styles/definitions.js';
import Responsive from '../Responsive/Responsive';
import SkeletonCircle from 'components/Skeletons/SkeletonCircle';

highchartsMore(Highcharts);
solidGauge(Highcharts);

const ANIMATION_INCREMENT = 2;
const ANIMATION_DURATION = 280 * 3;
const ANIMATION_EASING_FUNCTION = 'easeInOutCubic';

Math.easeInOutCubic = t => {
    return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
};

const labelAnimation = keyframes`
    to {
        opacity: 1;
    }
`;

const StyledGaugeChart = styled.div`
    position: relative;
    width: ${props => props.size === typeof stringValue ? props.size : props.size + 'px'};
    height: ${props => props.size === typeof stringValue ? props.size : props.size + 'px'};
`;

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin-top: 0.125em;
`;

const LabelValue = styled.div`
    display: block;
    transition: color 0.5s ease-out;
    font-family: 'Sanchez-Regular', serif;
    opacity: 0;
    animation-name: ${labelAnimation};
    animation-duration: 0.28s;
    animation-fill-mode: forwards;
    animation-delay: 0.28s;
    color: ${props => props.color};
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.theme.fontWeight.normal};
`;

const CustomLabel = styled.div`
    text-align: center;
`;

const AxisLabels = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    position: absolute;
    left: 5%;
    bottom: 5%;
    right: 5%;
    color: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.fontFamily.heading};
`;

const AxisMin = styled.div`
    font-size: ${props => props.theme.fontSize.note};
`;

const AxisMax = styled.div`
    font-size: ${props => props.theme.fontSize.note};
`;

const AxisTitle = styled.div`

`;

export const GaugeChartLabelNote = Responsive(styled.div`
    margin-bottom: -${props => props.theme.spacing.sm};
    font-size: ${props => props.theme.fontSize.xxxs};
    font-family: ${props => props.theme.fontFamily.heading};
    color: ${props => props.theme.gaugeChart.noteColor};
    line-height: 1.25;
`);

class GaugeChart extends React.PureComponent {
    state = {
        value: undefined,
        color: 'rgba(0, 0, 0, 0)'
    };

    componentDidMount() {
        if (!isNil(this.props.value) && !this.props.loading) {
            this.animateValue(this.props.value, this.state.value);
            this.updateColor();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== null && this.props.value !== prevProps.value && !prevProps.loading) {
            this.updateChartValue(this.props.value);
            this.updateColor();
        }
    }

    updateChartValue = value => {
        const animation = {
            duration: ANIMATION_DURATION,
            easing: ANIMATION_EASING_FUNCTION
        };
        const chart = this.node;
        const point = chart.series[0].points[0];
        point.update(value, true, animation);
        this.animateValue(value, this.state.value);
    };

    updateColor = () => {
        const chart = this.node;
        const point = chart.series[0].points[0];

        if (point) {
            const color = point.color;
            this.setState({
                color: color
            });
        }
    };

    getStops = () => {
        if (this.props.min < 0 && this.props.max > 0) {
            return [
                [0.4999, colors.emerald], // green
                [0.5, colors.radicalRed] // red
            ];
        }
        return [
            [0.79, colors.radicalRed],
            [0.80, colors.orange],
            [0.94, colors.orange],
            [0.95, colors.emerald],
            [1, colors.emerald]
        ];
    };

    animateValue = (newValue, oldValue) => {
        if (this.props.animateValue) {
            if (oldValue !== newValue && oldValue !== null && newValue !== null) {
                let value = oldValue;
                const cycle = ANIMATION_DURATION / (Math.abs(newValue - oldValue) / ANIMATION_INCREMENT);
                const interval = setInterval(() => {
                    if (newValue > oldValue && value >= newValue || oldValue > newValue && value <= newValue) {
                        clearInterval(interval);
                        this.setState({
                            value: newValue
                        });
                    } else if (value) {
                        this.setState({
                            value: value
                        });
                    }
                    if (oldValue < newValue) {
                        value = value + ANIMATION_INCREMENT;
                    } else {
                        value = value - ANIMATION_INCREMENT;
                    }
                }, cycle);
            }
        } else if (newValue !== null) {
            this.setState({
                value: newValue
            });
        }
    };

    getConfig = () => {
        const min = this.props.min || 0;
        const max = this.props.max || 100;
        const innerRadius = 100 - this.props.lineWidth + '%';
        const outerRadius = '100%';
        const backgroundPadding = this.props.backgroundPadding != null
            ? this.props.backgroundPadding
            : this.props.size / 27;

        let startAngle = 0;
        let endAngle = 359;

        if (min < 0 && max > 0) {
            const absMin = Math.abs(min);
            const maxValue = Math.max(absMin, max);
            startAngle = Math.floor(-(absMin / maxValue * 140));
            endAngle = Math.ceil(max / maxValue * 140);
        } else if (this.props.rounded) {
            endAngle = 348;
        }

        const config = {
            chart: {
                type: 'solidgauge',
                height: '100%',
                backgroundColor: 'transparent',
                spacing: [0, 0, 0, 0],
                margin: [0, 0, 0, 0]
            },
            title: {
                text: null
            },
            pane: {
                startAngle: startAngle,
                endAngle: endAngle,
                background: {
                    backgroundColor: this.props.background || '#fff',
                    borderColor: this.props.background || '#fff',
                    innerRadius: innerRadius,
                    outerRadius: outerRadius,
                    borderWidth: backgroundPadding,
                    shape: 'arc'
                }
            },
            // the value axis
            yAxis: {
                stops: this.props.stops || this.getStops(),
                min: min, // add padding to gauge start
                max: max,
                lineWidth: 0,
                minorTickInterval: null,
                visible: false,
                tickAmount: 0,
                tickPositioner: () => {
                    return [this.min, this.max];
                }
            },
            plotOptions: {
                solidgauge: {
                    size: '100%',
                    pointStart: 0,
                    dataLabels: false,
                    stickyTracking: false,
                    rounded: this.props.rounded,
                    animation: {
                        duration: ANIMATION_DURATION,
                        easing: ANIMATION_EASING_FUNCTION
                    }
                }
            },
            tooltip: {
                enabled: false
            },
            series: [{
                name: 'Value',
                radius: outerRadius,
                innerRadius: innerRadius,
                threshold: 0,
                data: [this.props.value],
            }],
            credits: {
                enabled: false
            }
        };

        return config;
    };

    getFormattedValue() {
        const { hidePlus, unit } = this.props;
        const { value } = this.state;
        if (isNil(value)) {
            return 'N/A';
        }
        const prefix = value > 0 && !hidePlus ? '+' : '';
        return `${prefix}${value || 0}${unit}`;
    }
    render() {
        const {
            showValue,
            size,
            children,
            valueNote,
            valueColor,
            min,
            max,
            axisTitle,
            className,
            loading
        } = this.props;
        const config = this.getConfig();

        if (loading) {
            const border = 4 * this.props.lineWidth;
            return (
                <StyledGaugeChart size={size}>
                    <SkeletonCircle
                        width={`calc(${size === typeof stringValue ? size : size + 'px'} - calc(2 * ${border}px))`}
                        borderWidth={`${border}px`}
                        margin="1.5em"
                        backgroundColor={this.props.background ? this.props.background : '#ccc'} />
                </StyledGaugeChart>
            );
        }

        return (
            <StyledGaugeChart size={size} className={className}>
                <HighchartsReact
                    highcharts={ Highcharts }
                    options={ config }
                    callback={ node => { this.node = node; }}
                />
                { React.Children.count(children) > 0
                    ? <LabelWrapper>
                        <CustomLabel>{children}</CustomLabel>
                    </LabelWrapper>
                    : showValue &&
                    <LabelWrapper>
                        <LabelValue
                            color={valueColor || this.state.color}
                            fontSize="0.9em"
                        >
                            {this.getFormattedValue()}
                        </LabelValue>
                        { valueNote &&
                            <GaugeChartLabelNote>
                                {valueNote}
                            </GaugeChartLabelNote>
                        }
                    </LabelWrapper>
                }
                { min < 0 && max > 0 &&
                    <AxisLabels>
                        <AxisMin>{min}</AxisMin>
                        <AxisTitle>{axisTitle}</AxisTitle>
                        <AxisMax>+{max}</AxisMax>
                    </AxisLabels>
                }
            </StyledGaugeChart>
        );
    }
}

GaugeChart.defaultProps = {
    value: undefined,
    size: 400,
    showValue: true,
    hidePlus: false,
    animateValue: false,
    unit: '%',
    lineWidth: 6
};

export default GaugeChart;
