import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const Bar = styled.div`
    height: ${props => props.height}px;
    border-radius: ${props => !props.noBorderRadius && '8px'};
    overflow: hidden;
`;

export const ProgressBarBackground = styled(Bar)`
    background-color: ${props => props.background || props.theme.colors.mystic};
`;
ProgressBarBackground.displayName = 'ProgressBarBackground';

export const ProgressBarValue = styled(Bar)`
    background-color: ${props => props.color};
    width: 100%;
    transform: translateX(${props => props.value - 100}%);
    transition: ${props => props.theme.motion.quick} box-shadow ${props => props.theme.motion.easing},
        ${props => props.theme.motion.quick} transform linear;
    border: ${props => props.border && `1px dashed ${props.theme.colors.rockBlue}`};

    ${props => props.hasHover && css`
        ${ProgressBarBackground}:hover &, &:hover {
            box-shadow: 0 0 1rem ${props.color};
        }
    `}
`;
ProgressBarValue.displayName = 'ProgressBarValue';

const ProgressBar = props =>
    <ProgressBarBackground { ...props } >
        <ProgressBarValue { ...props } />
    </ProgressBarBackground>;

export default ProgressBar;

ProgressBar.defaultProps = {
    hasHover: false,
    height: 10,
    noBorderRadius: false,
};

ProgressBar.propTypes = {
    color: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    hasHover: PropTypes.bool,
    height: PropTypes.number,
    background: PropTypes.string,
    noBorderRadius: PropTypes.bool,
};
