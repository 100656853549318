import React from 'react';
import { connect } from 'react-redux';
import SortableTable from 'components/SortableTable/SortableTable';
import InspectionsRow, { PlannedInspectionsRow } from './InspectionsRow';
import TableCell from 'components/BuildingEvents/EventsRow/EventsCell';
import SkeletonText from 'components/Skeletons/SkeletonText';
import { showModal } from 'redux/modules/modal/modal';
import { MODALTYPE } from 'components/Modal/ModalTypes';

const InspectionsTable = props => {
    const { t, data, loading, showModal, planned } = props;

    const SkeletonBodyComponent = () =>
        <tbody data-test-id="SkeletonInspectionsTable">
            <tr>
                <TableCell grow hideOnMobile>
                    <SkeletonText width="40%" style={{ marginLeft: '0' }} />
                    <SkeletonText width="40%" style={{ marginLeft: '0' }} />
                </TableCell>
                <TableCell hideOnMobile>
                    <SkeletonText />
                </TableCell>
                { !planned && <TableCell hideOnMobile>
                    <SkeletonText />
                </TableCell> }
                <TableCell grow hideOnDesktop>
                    <SkeletonText />
                    <SkeletonText />
                </TableCell>
            </tr>
        </tbody>
    ;

    const openModal = data => showModal(MODALTYPE.INSPECTIONS_CONTENT, null, null, { data, t });

    const columns = [{
        title: t('Inspection Type'),
        field: 'location',
        sortable: true
    }];
    !planned && columns.push({
        title: t('Status'),
        field: 'status',
        sortable: true,
        width: '180px',
        align: 'left'
    });
    columns.push({
        title: t('Date'),
        field: 'date',
        sortable: true,
        width: '150px',
        align: 'left'
    });

    return (
        <SortableTable
            columns={ columns }
            data={ data }
            RowComponent={ planned ? PlannedInspectionsRow : InspectionsRow }
            SkeletonBodyComponent={ SkeletonBodyComponent }
            headerBackgroundColor="transparent"
            orderBy="date"
            order="desc"
            t={ t }
            showControls
            loading={ loading }
            onClick={ openModal }
            removeHeadOnMobile
        />
    );
};

const mapDispatchToProps = dispatch => ({
    showModal: (modalType, preConditions, onSubmitAction, passedProps) =>
        dispatch(showModal(modalType, preConditions, onSubmitAction, passedProps))
});

const connector = connect(null, mapDispatchToProps);

export default connector(InspectionsTable);
