import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { includes } from 'lodash';
import PropTypes from 'prop-types';

import Cookies from 'utils/Cookies/Cookies.js';
import { getPartnerNumbers } from 'utils/profile';

class PartnerRedirect extends Component {
    static propTypes = {
        profile: PropTypes.object.isRequired,
    }

    render() {
        const { profile } = this.props;

        let partnerNumber = Cookies.getItem('selectedPartner');

        if (partnerNumber !== 'all' && !includes(getPartnerNumbers(profile), partnerNumber)) {
            Cookies.removeItem('selectedPartner');
            partnerNumber = undefined;
        }

        if (!partnerNumber) {
            partnerNumber = profile.partnerNumbers && profile.partnerNumbers[0];
        }

        // User has permission to only one FL
        if (partnerNumber && profile.partnerNumbers.length === 1 && profile.permissions
            && Object.keys(profile.permissions).length === 1 && profile.permissions[partnerNumber].length === 1) {
            return <Redirect to={ `/${partnerNumber}/FunctionalLocation/${profile.permissions[partnerNumber][0]}` } />;
        }

        if (partnerNumber) {
            return (
                <Redirect to={ `/${partnerNumber}/Overview` } />
            );
        }
        return (
            <Redirect to="/all/Overview" />
        );
    }
}

const mapStateToProps = state => ({
    profile: state.profile.profile
});

const connector = connect(mapStateToProps);

export default connector(withRouter(PartnerRedirect));
