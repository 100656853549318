import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { map, find, flatMap, findKey, includes, without, isArray } from 'lodash';
import { rgba } from 'polished';
import Svg from 'components/Svg/Svg';

const FilterItem = styled.div`
    display: inline-flex;
    align-items: center;
    border: 1px solid ${props => props.theme.colors.cerulean};
    background-color: ${props => rgba(props.theme.colors.cerulean, 0.1)};
    height: 20px;
    box-sizing: content-box;
    margin-right: ${props => props.theme.spacing.xs};
`;
FilterItem.displayName = 'FilterItem';

const Cross = styled(Svg)`
    fill: ${props => props.theme.colors.cerulean};
    border-right: 1px solid ${props => props.theme.colors.cerulean};
    font-size: 20px;
    padding: 0.3rem;
    box-sizing: border-box;
    cursor: pointer;
`;
Cross.displayName = 'Cross';

const Label = styled.span`
    padding: ${props => props.theme.spacing.xs};
    font-size: ${props => props.theme.font.size.xxs};
    color: ${props => props.theme.colors.cerulean};
    font-family: ${props => props.theme.font.family.arial};
    font-weight: ${props => props.theme.font.weight.bold};
`;
Label.displayName = 'Label';

const CalendarFilterRow = ({ t, activeFilter, setFilter, loading, filterOptions }) => {

    const handleFilterClick = value => {
        const field = findKey(
            activeFilter,
            filterValue => filterValue === value || includes(filterValue, value)
        );
        setFilter(field, without(activeFilter[field], value));
    };

    return (
        <Fragment>
            {flatMap(activeFilter,
                (values, field) => map(
                    isArray(values) ? values : [values],
                    value => {
                        const option = find(filterOptions[field], { value }) || {};
                        if (!option.text) {
                            return null;
                        }
                        return (
                            <FilterItem key={ value }>
                                <Cross onClick={ () => handleFilterClick(value) } name="remove" />
                                <Label>{ loading ? '...' : t(option.text) }</Label>
                            </FilterItem>
                        );
                    }
                )
            )}
        </Fragment>
    );
};

CalendarFilterRow.propTypes = {
    t: PropTypes.func.isRequired,
    activeFilter: PropTypes.object,
    setFilter: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

export default React.memo(CalendarFilterRow);
