import React, { PureComponent } from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import ZoomableChart from 'components/Charts/ZoomableChart';
import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import ValuesBox from 'components/ValuesBox/ValuesBox';
import ValuesBoxComparisonValues from 'components/ValuesBox/ValuesBoxComparisonValues';
import ValuesBoxComparisonValue from 'components/ValuesBox/ValuesBoxComparisonValue';
import {
    getTotalRawValues,
    getZoomedEnergyValues,
    ZOOM_TYPE,
    loadDailyChartValues,
    loadHourlyChartValues,
} from 'containers/Application/Modules/EnergyModule/EnergyModuleUtils';
import { metaReferenceKeys } from 'utils/Data/values';
import { loadEnergyChartValues } from 'redux/modules/iot/values';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';

const EMPTY_ARRAY = [];

export class EnergyConsumption extends PureComponent {
    state = {
        zoom: {
            type: ZOOM_TYPE.year,
            selected: null,
        },
        loading: false,
        series: [],
        categories: [],
        unit: undefined,
        xTitle: '',
        temperatureSeries: [],
    };

    static metaKey = metaReferenceKeys.energy;

    componentDidMount() {
        this.initializeChart();
    }

    componentDidUpdate(prevProps, prevState) {
        const { zoom, loading } = this.state;
        const { energyChartValues, loadingEnergyChartValues, outdoorsTemperatureData } = this.props;
        /*
         * Update chart data if
         *  1) zoom has changed and no loading were required
         *  2) loadingEnergyChartValues (from redux) stopped and this component had the loading on
         *  3) Outdoors temp values have changed
         */
        const stoppedLoadingChartValues = prevProps.loadingEnergyChartValues && !loadingEnergyChartValues;
        if (!_.isEqual(zoom, prevState.zoom) && !loading || loading && stoppedLoadingChartValues
            || !_.isEqual(outdoorsTemperatureData, prevProps.outdoorsTemperatureData)) {
            if (zoom.type === ZOOM_TYPE.year) {
                this.initializeChart();
            } else {
                this.updateChart(
                    zoom.type === ZOOM_TYPE.date
                        ? energyChartValues.hourlySum
                        : energyChartValues.dailySum
                );
                this.removeLoading();
            }
        }
    }

    initializeChart = () => {
        const { series, categories, unit } = this.props;
        // Initialize state with props
        this.setState({
            series,
            temperatureSeries: this.getTemperatureSeries(),
            categories,
            unit,
            xTitle: '',
        });
    };

    updateChart = energyValues => {
        const { zoom: { type, selected }, unit } = this.state;
        const { theme } = this.props;

        if (!_.isEmpty(energyValues)) {
            const { series, categories, xTitle } = getZoomedEnergyValues(
                getTotalRawValues(energyValues),
                theme,
                type,
                selected,
                unit
            );
            const referenceSeries = this.getReferenceSeries();
            const temperatureSeries = this.getTemperatureSeries();
            referenceSeries && series.push(referenceSeries);

            this.setState({
                temperatureSeries,
                series,
                categories,
                unit,
                xTitle,
            });
        } else {
            this.handleZoom(null, ZOOM_TYPE.year);
        }
    }

    getTemperatureSeries = () => this.props.outdoorsTemperatureData[this.state.zoom.type]
        && this.props.outdoorsTemperatureData[this.state.zoom.type][this.state.zoom.selected || 0]
        || EMPTY_ARRAY;

    getReferenceSeries = () => this.props.referenceData[EnergyConsumption.metaKey]
        && this.props.referenceData[EnergyConsumption.metaKey][this.state.zoom.type]
        && this.props.referenceData[EnergyConsumption.metaKey][this.state.zoom.type][this.state.zoom.selected || 0]
        || null;

    handleZoom = (selected, type) => {
        const { loadEnergyChartValues, partnerNumber, energyChartValues } = this.props;
        let loading = true;

        if (type === ZOOM_TYPE.month && _.isEmpty(energyChartValues)) {
            loadDailyChartValues(loadEnergyChartValues, partnerNumber);
        } else if (type === ZOOM_TYPE.date) {
            loadHourlyChartValues(selected, loadEnergyChartValues, partnerNumber);
        } else {
            loading = false;
        }

        this.setState(oldState => ({
            zoom: {
                ...oldState.zoom,
                type,
                selected
            },
            loading
        }));
    }

    removeLoading = () => this.setState({ loading: false });

    render() {
        const {
            t,
            unit,
            years,
            yearTotals,
            energyRating,
            last365DaysConsumption,
            theme,
            maxZoomLevel,
        } = this.props;
        const {
            zoom,
            categories,
            xTitle,
            series,
            temperatureSeries,
            loading,
        } = this.state;

        const sortedYears = _.chain(years).sortBy().reverse().value();

        return (
            <Section>
                <SectionHeader
                    title={ t('Energy consumption') }
                    t={ t }
                    ctxHelp={ `${CTXHELP_PREFIX} Energy consumption` }
                />
                <Columns>
                    <Column columnWidth={{
                        landscape: 9,
                        default: 12
                    }}>
                        <ZoomableChart
                            categories={ categories }
                            series={ series }
                            t={ t }
                            onZoom={ this.handleZoom }
                            zoom={ zoom }
                            xTitle={ xTitle }
                            loading={ loading }
                            temperatureSeries={ temperatureSeries }
                            maxZoomLevel={ maxZoomLevel }
                        />
                    </Column>
                    <Column columnWidth={{
                        landscape: 3,
                        default: 12
                    }}>
                        { !!energyRating && !!energyRating.latestValue && <ValuesBox
                            heading={ t('Energy rating') }
                            value={ `${energyRating.latestValue} kWh/m²` }
                            label={ t('Yearly consumption') }
                            positiveValueColor={theme.charts.colorsEnergy[0]}
                        >
                            <ValuesBoxComparisonValues>
                                { energyRating.monthAgoValue && <ValuesBoxComparisonValue
                                    value={ `${energyRating.monthAgoValue} kWh/m²` }
                                    label={ t('Month ago') }
                                /> }
                                { energyRating.referenceValue && <ValuesBoxComparisonValue
                                    value={ `${energyRating.referenceValue} kWh/m²` }
                                    label={ t('Reference') }
                                /> }
                            </ValuesBoxComparisonValues>
                        </ValuesBox> }
                        <ValuesBox
                            heading={ t('Total energy consumption') }
                            value={ `${last365DaysConsumption} ${unit}` }
                            label={ t('Last 365 days') }
                            positiveValueColor={theme.charts.colorsEnergy[0]}
                        >
                            <ValuesBoxComparisonValues>
                                { !!sortedYears && !!sortedYears[0] && !!yearTotals[sortedYears[0]] &&
                                    <ValuesBoxComparisonValue
                                        value={ `${yearTotals[sortedYears[0]]} ${unit}` }
                                        label={ sortedYears[0] }
                                    />
                                }
                                { !!sortedYears && !!sortedYears[1] && !!yearTotals[sortedYears[1]] &&
                                    <ValuesBoxComparisonValue
                                        value={ `${yearTotals[sortedYears[1]]} ${unit}` }
                                        label={ sortedYears[1] }
                                    />
                                }
                            </ValuesBoxComparisonValues>
                        </ValuesBox>
                    </Column>
                </Columns>
            </Section>
        );
    }
}

EnergyConsumption.defaultProps = {
    energyRating: {},
    xTitle: '',
    outdoorsTemperatureData: {},
    referenceData: {},
    functionalLocation: null,
    partnerNumber: null,
    energyChartValues: {},
    maxZoomLevel: undefined,
};

EnergyConsumption.propTypes = {
    t: PropTypes.func.isRequired,
    unit: PropTypes.string.isRequired,
    series: PropTypes.array.isRequired,
    years: PropTypes.array.isRequired,
    yearTotals: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    last365DaysConsumption: PropTypes.number.isRequired,
    loadingEnergyChartValues: PropTypes.bool.isRequired,
    loadEnergyChartValues: PropTypes.func.isRequired,
    functionalLocation: PropTypes.object,
    partnerNumber: PropTypes.string,
    outdoorsTemperatureData: PropTypes.object,
    referenceData: PropTypes.object,
    energyRating: PropTypes.shape({
        latestValue: PropTypes.number,
        monthAgoValue: PropTypes.number,
        referenceValue: PropTypes.number,
        unit: PropTypes.string
    }),
    xTitle: PropTypes.string,
    loading: PropTypes.bool,
    energyChartValues: PropTypes.object,
    maxZoomLevel: PropTypes.number,
};

const mapStateToProps = (state, props) => ({
    energyChartValues: props.functionalLocation
        ? state.values.energyChartValues.byFl[props.functionalLocation.functionalLocation]
        : state.values.energyChartValues.byPartner[props.partnerNumber],
    loadingEnergyChartValues: state.values.loadingEnergyChartValues,
});

const mapDispatchToProps = dispatch => ({
    loadEnergyChartValues: (partnerNumber, betweens, aggregation) => {
        dispatch(loadEnergyChartValues(partnerNumber, betweens, aggregation));
    },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withTheme(connector(EnergyConsumption));
