import React from 'react';
import Blueprint from 'components/Blueprint/Blueprint';

class Floor extends React.Component {
    render() {
        const {
            t,
            floorData,
            scrollToNode
        } = this.props;

        const image = floorData.image;
        const imageSize = {
            width: floorData.imageWidth,
            height: floorData.imageHeight
        };

        return (
            <Blueprint
                ref={ node => { this.mapDiv = node; } }
                t={ t }
                image={ image }
                imageSize={ imageSize }
                featureGroups={ [[floorData.areas], [floorData.sensors]] }
                onSensorClick={ (title, type, sensorId) => Array.isArray(sensorId)
                    ? scrollToNode(sensorId[0])
                    : scrollToNode(sensorId)
                }
                icons={ floorData.floorIcons }
            />
        );
    }
}

export default Floor;
