import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Section from 'components/Section/Section';
import { connect } from 'react-redux';
import ApiKeyTable from './ApiKeyTable';
import Button from 'components/Button/Button';
import ApiKeyModal from './ApiKeyModal';
import Loader from 'components/Loader/Loader';
import QuotaModal from './QuotaModal';
import * as actions from 'redux/modules';
import ReactMarkdown from 'react-markdown';

const Title = styled.h2`
    padding: ${props => props.theme.spacing.md} 0;
    font-size: ${props => props.theme.font.size.lg};
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    ${props => props.theme.media.portrait`
        flex-direction: row;
    `}
`;

const TitleText = styled.div`
    margin-bottom: ${props => props.theme.spacing.xs};
    ${props => props.theme.media.portrait`
        margin: 0;
    `}
`;

export const Info = styled.p`
    margin: 0.5em 0 0.8em;
    line-height: ${props => props.theme.lineHeight.text};
    font-weight: ${props => props.theme.font.weight[props.bold ? 'bold' : 'normal']};
`;

const renderLink = ({ href, children }) => (
    <a href={href} target="blank">
        {children}
    </a>
);

const Api = ({
    t,
    profileId,
    showModal,
    apiKeys,
    apiQuota,
    apiQuotaDefault,
    deleteApiKey,
    profile,
    createApiKey,
    setApiQuota,
    loadApiKeys,
    loadApiQuota
}) => {
    React.useEffect(
        () => {
            loadApiKeys(profileId);
            loadApiQuota(profileId);
            loadApiQuota('default');
        },
        [profileId]
    );

    const [generateDialog, setGenerateDialog] = React.useState(false);
    const [quotaDialog, setQuotaDialog] = React.useState(false);

    const handleGenerateApiKeyClick = () => setGenerateDialog(true);
    const handleSetQuotaClick = () => setQuotaDialog(true);

    const quota = get(apiQuota, 'data.perHour', get(apiQuotaDefault, 'data.perHour'));
    const admin = profile.role === 'caverion-admin';
    return (
        <React.Fragment>
            <Section>
                <Title>
                    <TitleText>{t('API Keys')}</TitleText>
                    <Button autoWidth onClick={handleGenerateApiKeyClick}>
                        {t('Generate new key')}
                    </Button>
                </Title>
                <Info>{t('API_KEY_INFO')}</Info>
                <Info>
                    <ReactMarkdown
                        source={t(
                            'See [API documentation]({0}) for details on how to use the API.',
                            process.env.REACT_APP_API_DOCS
                        )}
                        renderers={{ link: renderLink }}
                    />
                </Info>
                <ApiKeyTable
                    t={t}
                    profileId={profileId}
                    apiKeys={apiKeys}
                    showModal={showModal}
                    deleteApiKey={deleteApiKey}
                />
                {generateDialog && (
                    <ApiKeyModal
                        t={t}
                        toggle={setGenerateDialog}
                        createApiKey={createApiKey}
                        profileId={profileId}
                    />
                )}
            </Section>
            <Section>
                <Title>
                    <TitleText>{t('Quota')}</TitleText>
                    {admin && (
                        <Button type="button" autoWidth onClick={handleSetQuotaClick}>
                            {t('Set quota')}
                        </Button>
                    )}
                </Title>
                <Info>{t('QUOTA_INFO')}</Info>
                {!apiQuota || apiQuota.loading || !apiQuotaDefault || apiQuotaDefault.loading ? (
                    <Loader />
                ) : (
                    <React.Fragment>
                        <Info bold>{t('The current quota is {0} requests per hour.', quota)}</Info>
                        {!admin && <Info>{t('QUOTA_INCREASE')}</Info>}
                    </React.Fragment>
                )}
                {quotaDialog && (
                    <QuotaModal
                        t={t}
                        toggle={setQuotaDialog}
                        initialQuota={apiQuota}
                        defaultQuota={apiQuotaDefault}
                        setApiQuota={setApiQuota}
                        profileId={profileId}
                        loadApiQuota={loadApiQuota}
                    />
                )}
            </Section>
        </React.Fragment>
    );
};

Api.propTypes = {
    t: PropTypes.func.isRequired,
    profileId: PropTypes.string.isRequired,
    showModal: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,

    // Redux
    apiKeys: PropTypes.object,
    apiQuota: PropTypes.shape({
        loading: PropTypes.bool,
        error: PropTypes.string,
        data: PropTypes.object
    }),
    apiQuotaDefault: PropTypes.shape({
        loading: PropTypes.bool,
        error: PropTypes.string,
        data: PropTypes.object
    }),
    loadApiKeys: PropTypes.func.isRequired,
    loadApiQuota: PropTypes.func.isRequired,
    deleteApiKey: PropTypes.func.isRequired,
    createApiKey: PropTypes.func.isRequired,
    setApiQuota: PropTypes.func.isRequired
};

const mapState = (state, { profileId }) => ({
    apiKeys: state.profile.apiKeys[profileId],
    apiQuota: state.profile.apiQuotas[profileId],
    apiQuotaDefault: state.profile.apiQuotas.default
});

const mapDispatch = {
    loadApiKeys: actions.loadApiKeys,
    loadApiQuota: actions.loadApiQuota,
    deleteApiKey: actions.deleteApiKey,
    createApiKey: actions.createApiKey,
    setApiQuota: actions.setApiQuota
};

export default connect(
    mapState,
    mapDispatch
)(Api);
