import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ContextualHelp from 'components/ContextualHelp/ContextualHelp';

const Container = styled.div`
    display: flex;
`;

export const Label = styled.label`
    line-height: 2.5em;
    color: ${props => props.theme.colors.darkGray};
    font-size: ${props => props.theme.font.size.xxxs};
    display: ${props => props.hideOnMobile ? 'none' : 'inline-block'};
    font-weight: ${props => props.theme.font.weight.bold};
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-left: 1px;

    ${props => props.theme.media.portrait`
        display: inline-block;
    `}
`;
Label.displayName = 'Label';

const ExtraText = styled.span`
    font-weight: ${props => props.theme.font.weight.normal};
    padding-left: ${props => props.theme.spacing.xxs};
`;

const ErrorText = styled.span`
    position: relative;
    float: right;
    padding-left: ${props => props.theme.spacing.xxs};
    color: ${props => props.theme.colors.radicalRed};
    text-transform: none;
`;

export const InputLabel = props => {
    return <Container>
        <Label htmlFor={ props.id } className={ props.className } hideOnMobile={ props.hideOnMobile }>
            { props.text }
            <ExtraText>{ props.extraText }</ExtraText>
            <ErrorText>{ props.errorText }</ErrorText>
        </Label>
        { !!props.ctxHelp && !!props.t && <ContextualHelp
            t={ props.t }
            title={ props.text }
            text={ props.ctxHelp }
            position="top"
            standalone
        /> }
    </Container>;
};

InputLabel.displayName = 'InputLabel';

InputLabel.propTypes = {
    text: PropTypes.string.isRequired,
    id: PropTypes.string,
    className: PropTypes.string,
    ctxHelp: PropTypes.string,
    t: PropTypes.func,
    hideOnMobile: PropTypes.bool,
    extraText: PropTypes.string,
    errorText: PropTypes.string,
};

InputLabel.defaultProps = {
    id: undefined,
    className: ''
};

export default InputLabel;
