import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { find, omitBy, clone, map, isArray, flatten, isEmpty } from 'lodash';
import Helmet from 'react-helmet';

import { loadEquipmentContainer } from 'redux/modules/containers/equipment.js';

import translations from 'decorators/Translations/translations';
import ExternalDocumentModule from 'containers/Application/Modules/ExternalDocumentModule/ExternalDocumentModule.jsx';
import MaintenanceModule from 'containers/Application/Modules/MaintenanceModule/MaintenanceModule.jsx';

import EquipmentModule from 'containers/Application/Modules/EquipmentModule/EquipmentModule.jsx';
import Hero from 'components/Hero/Hero.jsx';
import Header from 'containers/Application/Header/Header.jsx';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage.jsx';

import Tabs from 'components/Tabs/Tabs.jsx';
import Tab from 'components/Tabs/Tab.jsx';
import StandardPage from 'components/StandardPage/StandardPage.jsx';
import { icons } from 'components/Icon/IconNames';

import {
    getParentFunctionLocations,
    getHeroContext,
    getBuildingImage,
    addServiceOrderLink,
    addConditionsLink,
    addEquipmentLink,
    addExternalDocumentsLink,
    addNewServiceRequestLink
} from 'utils/Data/functionalLocations';
import { isServiceOrdersEnabled, isEquipmentEnabled } from 'utils/Data/profileData';

import PerformanceData from './Conditions/PerformanceData';

class Equipment extends Component {

    componentDidUpdate(prevProps) {
        if (
            prevProps.match.params.functionalLocationId !== this.props.match.params.functionalLocationId ||
            prevProps.match.params.equipmentNumber !== this.props.match.params.equipmentNumber ||
            prevProps.match.params.superordinate !== this.props.match.params.superordinate
        ) {
            this.loadData();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        const {
            features,
            match: { params: { functionalLocationId, equipmentNumber, superordinate, partnerNumber } },
            loadEquipmentContainer
        } = this.props;
        loadEquipmentContainer(functionalLocationId, equipmentNumber, features, superordinate, partnerNumber);
    }

    render() {
        const {
            t,
            features,
            functionalLocations,
            equipments,
            subEquipments,
            location,
            loading,
            equipmentDocuments,
            match: { params: { functionalLocationId, equipmentNumber, partnerNumber, superordinate } },
            loadingParents,
            functionalLocationImages,
            match: { url },
        } = this.props;

        if (!isEquipmentEnabled(features)) {
            return null;
        }

        const equipment = find(equipments[functionalLocationId], x => x.equipmentNumber === equipmentNumber);
        if (!equipment && !loading) {
            return <ErrorPage type="equipment" />;
        }

        // Show documents loading indicator if equipmentDocuments is null.
        let loadingDocuments = false;
        let documentCount = 0;
        if (equipmentDocuments && isArray(equipmentDocuments[equipmentNumber])) {
            const files = flatten(equipmentDocuments[equipmentNumber]
                .filter(d => d.files.length > 0).map(d => d.files));
            documentCount = files.length;
        }
        else {
            loadingDocuments = true;
        }

        // Show subequipment loading indicator if subEquipments is null.
        let loadingSubEquipment = false;
        let subEquipmentCount = 0;
        if (subEquipments) {
            if (subEquipments[equipmentNumber]) {
                subEquipmentCount = subEquipments[equipmentNumber].length;
            }
            else {
                loadingSubEquipment = true;
            }
        }

        const functionalLocation = functionalLocations[functionalLocationId];
        const path = functionalLocation && functionalLocation.path;
        const parents = getParentFunctionLocations(functionalLocations, path, functionalLocationId).reverse();
        parents.push(functionalLocation);

        if (superordinate) {
            const parentEquipment = find(equipments[functionalLocationId], x => x.equipmentNumber === superordinate);
            parents.push(parentEquipment);
        }

        const partnerPart = !partnerNumber ? '' : `/${partnerNumber}`;
        const from = queryString.parse(location.search).from;
        const fromTab = queryString.parse(location.search).fromTab;
        const backLink = { pathname: `${partnerPart}/FunctionalLocation/${from}`, tab: `${fromTab || 'conditions'}` };
        const parentImages = omitBy(map(parents, parent =>
            getBuildingImage(functionalLocationImages, parent)), isEmpty);

        const tabs = [];

        if (features && features.documents) {
            tabs.push(
                <Tab title="External Documents" key="External Documents">
                    { loading ?
                        <ExternalDocumentModule /> :
                        <ExternalDocumentModule functionalLocation={ functionalLocation } equipment={ equipment } /> }
                </Tab>
            );
        }

        if (features && features.conditions) {
            tabs.push(
                <Tab title="Conditions" key="Conditions">
                    <PerformanceData t={ t } equipment={ equipment } functionalLocationId={ functionalLocationId } />
                </Tab>
            );
        }

        if (isServiceOrdersEnabled(features)) {
            tabs.push(
                <Tab title="Service Orders" key="Service Orders">
                    <MaintenanceModule functionalLocation={ functionalLocation } equipment={ equipment } />
                </Tab>
            );
        }

        tabs.push(
            <Tab title="Subequipment" key="Subequipment">
                <EquipmentModule
                    title={ t('Subequipment') }
                    equipments={ subEquipments[equipmentNumber] }
                    subEquipments={ subEquipments }
                    loadingEquipment={ loadingSubEquipment }
                />
            </Tab>
        );

        let context = { loadingContext: true, loading: true };
        let links = [];
        from && links.push({
            title: 'Back to Building Conditions',
            icon: icons.ARROW_LEFT,
            to: backLink,
            smallIcon: true
        });

        const pathname = url.replace('/ServiceCalendar', '');
        links = addServiceOrderLink(links, `${pathname}/ServiceCalendar`, features);
        links = addConditionsLink(links, pathname, features);
        links = addExternalDocumentsLink(links, pathname, features, documentCount, loadingDocuments);
        links = addEquipmentLink(links, pathname, features, subEquipmentCount, loadingSubEquipment, [], true);

        let linksMobile = clone(links);
        linksMobile = addNewServiceRequestLink(linksMobile, partnerNumber, functionalLocation, features);

        if (equipment) {
            const heroContext = getHeroContext(parents, partnerNumber, features);
            context = Object.assign({}, heroContext,
                { type: 'EQ', loadingContext: loadingParents, functionalLocation, equipment, text: equipment.text });
        }

        return (
            <StandardPage disableScrollToTop withTabs>
                <Helmet title={ equipment && equipment.text } />
                <Header context={ context } t={ t } links={ links } linksMobile={ linksMobile } />
                <Hero
                    equipment={ equipment }
                    type="EQ"
                    title={ equipment && equipment.text }
                    t={ t }
                    loadingContext={ loadingParents }
                    partnerNumber={ partnerNumber }
                    heroImage={ parentImages && parentImages[0] }
                />

                <Tabs t={ t }>
                    { tabs }
                </Tabs>

            </StandardPage>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.equipmentContainer.loading,
    loadingParents: state.equipmentContainer.loadingParents,
    features: state.profile.profile.syntheticFeatures,
    functionalLocations: state.functionalLocations.functionalLocations,
    equipments: state.equipments.equipments,
    subEquipments: state.equipments.subEquipments,
    equipmentDocuments: state.document.filteredEquipment,
    functionalLocationImages: state.partnerImage.byFL
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    loadEquipmentContainer: (functionalLocation, equipmentNumber, features, superordinate) =>
        dispatch(loadEquipmentContainer(
            functionalLocation,
            equipmentNumber,
            features,
            superordinate,
            ownProps.match.params.partnerNumber
        ))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(translations(Equipment)));
