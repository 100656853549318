import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const FadeTransition = props =>
    <TransitionGroup>
        <CSSTransition
            {...props}
            classNames="fade"
            timeout={ 280 }
            appear
        />
    </TransitionGroup>;

export default FadeTransition;
