// https://stackoverflow.com/questions/25973920/how-to-display-highchart-series-line-marker-symbol-from-tooltip-formatter
export const getSymbolChar = symbol => {
    switch ( symbol ) {
    case 'circle':
        return '&#9679';
    case 'diamond':
        return '&#9670';
    case 'square':
        return '&#9632';
    case 'triangle':
        return '&#9650';
    case 'triangle-down':
        return '&#9660';
    default:
        return '&#9679'; // circle
    }
};
