import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withTheme } from 'styled-components';

const SimpleBarChart = ({
    categories, values, theme, formatCategory, formatValue, height, innerRef, placeholder, columnWidth, ...other
}) => {
    const options = React.useMemo(() => {
        const data = placeholder ? categories.map(_ => 1) : values;
        return {
            chart: {
                type: 'column',
                spacing: 0,
                marginTop: -5,
                height,
                width: null,
            },
            series: [{
                data,
            }],
            xAxis: {
                categories: formatCategory ? categories.map(formatCategory) : categories,
                visible: false,
            },
            yAxis: {
                visible: false,
            },
            title: false,
            legend: {
                enabled: false,
            },
            colors: [placeholder ? theme.colors.mystic : theme.colors.midnight],
            plotOptions: {
                column: {
                    borderRadiusTopLeft: columnWidth / 2,
                    borderRadiusTopRight: columnWidth / 2,
                    pointWidth: columnWidth,
                    states: {
                        hover: {
                            color: theme.colors.rockBlue,
                        },
                    },
                    enableMouseTracking: !placeholder,
                },
            },
            tooltip: {
                backgroundColor: theme.colors.midnight,
                style: {
                    color: theme.colors.white,
                },
                headerFormat: '{point.key}: ',
                pointFormatter: function() {
                    return formatValue ? formatValue(this.y) : this.y;
                },
                borderWidth: 0,
                borderRadius: 5,
                shadow: false,
                enabled: !placeholder,
                outside: true,
            },
            credits: {
                enabled: false,
            },
        };
    }, [categories, values, formatCategory, theme, placeholder, columnWidth, formatValue, height]);

    return (
        <HighchartsReact
            highcharts={ Highcharts }
            options={ options }
            containerProps={ other }
        />
    );
};

SimpleBarChart.propTypes = {
    categories: PropTypes.array.isRequired,
    values: PropTypes.arrayOf(PropTypes.number),
    formatCategory: PropTypes.func,
    formatValue: PropTypes.func,
    height: PropTypes.number,
    placeholder: PropTypes.bool,
    columnWidth: PropTypes.number,
};

SimpleBarChart.defaultProps = {
    height: 400,
    columnWidth: 6,
};

export default withTheme(SimpleBarChart);
