import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import moment from 'moment-timezone';

import BarChart from 'components/Charts/BarChart';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import { Footer, ZoomLink } from 'components/Charts/ZoomableChart';
import IoTChart from 'components/Charts/IoTChart';

const Year = styled.span`
    color: ${props => props.theme.colors.darkGray};
    font-family: ${props => props.theme.font.family.sanchez};
    font-size: ${props => props.theme.font.size.sm};
    font-weight: ${props => props.theme.font.weight.bold};
    padding: ${props => props.theme.spacing.md} 0;
`;

const StyledSectionTabSelector = styled(SectionTabSelector)`
    padding-top: 0;
    margin-bottom: ${props => props.theme.spacing.md};
`;

const ChartSection = props => {
    const {
        theme,
        t,
        title,
        series,
        year,
        changeYear,
        loading,
        tabOptions,
        selectedTab,
        onTabChange,
        lineChart
    } = props;

    const colors = series.length === 1 ? [theme.colors.midnight] :
        [theme.colors.orange, theme.colors.cerulean, theme.colors.midnight, theme.colors.amethyst];

    return <Section>
        { title && <SectionHeader noBorder title={ title } t={ t } /> }
        { tabOptions && <SectionHeader t={ t }>
            <StyledSectionTabSelector
                left
                large
                t={ t }
                options={ tabOptions }
                model={{ selectedTab }}
                property="selectedTab"
                onTabChange={ onTabChange }
            />
        </SectionHeader> }
        { !lineChart && <BarChart
            t={ t }
            type="column"
            categories={ moment.monthsShort() }
            hideDecimals
            valueDecimals={ 0 }
            noRounding
            showTooltipTotal={ series.length > 1 }
            colors={ colors }
            pointWidth={ series.length === 1 ? 20 : 10 }
            plotBorderWidth="0"
            legendAlign="left"
            hideLegend={ series.length === 1 }
            loading={ loading }
            series={ series }
            labelStyle={{
                color: theme.colors.darkGray,
                fontWeight: theme.font.weight.bold,
                fontFamily: theme.font.family.arial,
                letterSpacing: '1px',
                textTransform: 'uppercase'
            }}
        /> }
        { lineChart && <IoTChart
            t={ t }
            type="spline"
            histories={ series }
            loading={ loading }
            alarms={ [] }
            unit={ t('min') }
            yAxisTitle={ t('Minutes') }
            yAxisLabelFormat={ `{value:.0f}` }
            yAxisVerticalTitle
            tooltipDateFormatter={ timestamp => `${moment(timestamp).format(`MMM YYYY`)}`}
            xAxisLabelFormat={ `{value:%b}` }
        /> }
        { !loading && <Footer>
            <ZoomLink left onClick={() => changeYear(year - 1)}>
                { `← ${ t('Previous year') }` }
            </ZoomLink>
            <Year>{ year }</Year>
            <ZoomLink onClick={() => changeYear(year + 1)}>
                { `${ t('Next year') } →` }
            </ZoomLink>
        </Footer> }
    </Section>;
};

ChartSection.propTypes = {
    t: PropTypes.func.isRequired,
    title: PropTypes.string,
    year: PropTypes.number.isRequired,
    changeYear: PropTypes.func.isRequired,
    series: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    tabOptions: PropTypes.array,
    selectedTab: PropTypes.string,
    onTabChange: PropTypes.func,
    lineChart: PropTypes.bool
};

export default withTheme(ChartSection);
