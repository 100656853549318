import { createReducerFromMapping } from 'redux/utils/index.js';
import _ from 'lodash';
import { MasterData } from '@caverion/redux/api/actions';

const initialState = {
    functionalLocationSearchResults: [],
    totalResults: 0,
    customers: {},
    functionalLocations: {},
    hierarchy: {},
    partnerSearch: {},
    typesToSearch: { BU: true, UN: true },
    error: null,
    portfolioCounts: {},
};

export const NUMBER_OF_SEARCH_RESULTS = 20;

export const SEARCH = 'CUSTOMER_PLATFORM/Customer/SEARCH';
export const SEARCH_SUCCESS = 'CUSTOMER_PLATFORM/Customer/SEARCH_SUCCESS';
export const SEARCH_FAIL = 'CUSTOMER_PLATFORM/Customer/SEARCH_FAIL';
export const RESET_SEARCH = 'CUSTOMER_PLATFORM/Customer/RESET_SEARCH';

export const searchFunctionalLocationsByPartners = (partners, typesToSearch) => {
    const filter = {
        where: {
            partnerNumberWithParents: {
                'any x': {

                    // Select only given partners. This will select only direct partners
                    // so inherited partners are not included.
                    x: { inq: partners }
                }
            },
            type: { inq: _.keys(_.pickBy(typesToSearch)) },
        },

        // Order by functional location to put same locations close together. However, this is
        // greedy and does not work in all cases.
        order: 'functionalLocation'
    };

    return async dispatch => {
        dispatch({ type: SEARCH, partners });
        try {
            const result = await dispatch(MasterData.adminFindHierarchies(filter));

            return dispatch({
                type: SEARCH_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: SEARCH_FAIL,
                error
            });
        }
    };
};

export const resetSearch = leaveOptions => {
    return {
        type: RESET_SEARCH,
        leaveOptions
    };
};

export const CHANGE_TYPES = 'CUSTOMER_PLATFORM/Customer/CHANGE_TYPES';
export const changeTypes = typesToSearch => {
    return {
        type: CHANGE_TYPES,
        typesToSearch
    };
};

export const PARTNER_SEARCH = 'CUSTOMER_PLATFORM/Customer/PARTNER_SEARCH';
export const PARTNER_SEARCH_SUCCESS = 'CUSTOMER_PLATFORM/Customer/PARTNER_SEARCH_SUCCESS';
export const PARTNER_SEARCH_FAIL = 'CUSTOMER_PLATFORM/Customer/PARTNER_SEARCH_FAIL';

export const searchPartnersByKeyword = keyword => {
    const filter = {
        fields: { name: true, partnerNumber: true, deleted: true },
        where: {
            '*': keyword
        }
    };

    return async dispatch => {
        dispatch({ type: PARTNER_SEARCH });
        try {
            const result = await dispatch(MasterData.customers(filter));

            return dispatch({
                type: PARTNER_SEARCH_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: PARTNER_SEARCH_FAIL,
                error
            });
        }
    };
};

export const PARTNER_SEARCH_BY_IDS = 'CUSTOMER_PLATFORM/Customer/PARTNER_SEARCH_BY_IDS_SET';
export const PARTNER_SEARCH_BY_IDS_SUCCESS = 'CUSTOMER_PLATFORM/Customer/PARTNER_SEARCH_BY_IDS_SUCCESS';
export const PARTNER_SEARCH_BY_IDS_FAIL = 'CUSTOMER_PLATFORM/Customer/PARTNER_SEARCH_BY_IDS_FAIL';

export const searchPartnersByIds = ids => {
    const filter = {
        fields: { name: true, partnerNumber: true },
        where: {
            partnerNumber: {
                inq: ids
            }
        }
    };

    return async dispatch => {
        dispatch({ type: PARTNER_SEARCH_BY_IDS });
        try {
            const result = await dispatch(MasterData.customers(filter));

            return dispatch({
                type: PARTNER_SEARCH_BY_IDS_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: PARTNER_SEARCH_BY_IDS_FAIL,
                error
            });
        }
    };
};

export const SEARCH_ALL = 'CUSTOMER_PLATFORM/Customer/SEARCH_ALL';
export const SEARCH_ALL_SUCCESS = 'CUSTOMER_PLATFORM/Customer/SEARCH_ALL_SUCCESS';
export const SEARCH_ALL_FAIL = 'CUSTOMER_PLATFORM/Customer/SEARCH_ALL_FAIL';

export const searchAllFunctionalLocationsByPartners = (partners, typesToSearch) => {
    const filter = {
        where: {
            partnerNumberWithParents: {
                'any x': {

                    // Select given partners. This will select also inherited partners.
                    x: { inq: partners }
                }
            },
            type: { inq: _.keys(_.pickBy(typesToSearch)) }
        },
    };

    return async dispatch => {
        dispatch({ type: SEARCH_ALL });
        try {
            const result = await dispatch(MasterData.adminFindHierarchies(filter));

            return dispatch({
                type: SEARCH_ALL_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: SEARCH_ALL_FAIL,
                error
            });
        }
    };
};

export const GET_PORTFOLIO_COUNTS = 'CUSTOMER_PLAYFORM/Customer/GET_PORTFOLIO_COUNTS';
export const GET_PORTFOLIO_COUNTS_SUCCESS = 'CUSTOMER_PLAYFORM/Customer/GET_PORTFOLIO_COUNTS_SUCCESS';
export const GET_PORTFOLIO_COUNTS_FAIL = 'CUSTOMER_PLAYFORM/Customer/GET_PORTFOLIO_COUNTS_FAIL';

export const getPortfolioCounts = partnerNumbers => async dispatch => {
    if (!partnerNumbers.length) {
        return;
    }

    dispatch({ type: GET_PORTFOLIO_COUNTS });
    try {
        const result = await dispatch(MasterData.hierarchiesPortfolioCounts(partnerNumbers));
        return dispatch({
            type: GET_PORTFOLIO_COUNTS_SUCCESS,
            result,
        });
    } catch (error) {
        return dispatch({
            type: GET_PORTFOLIO_COUNTS_FAIL,
            error,
        });
    }
};

export const LOAD_CUSTOMERS = 'CUSTOMER_PLATFORM/Customer/LOAD_CUSTOMERS';
export const LOAD_CUSTOMERS_SUCCESS = 'CUSTOMER_PLATFORM/Customer/LOAD_CUSTOMERS_SUCCESS';
export const LOAD_CUSTOMERS_FAIL = 'CUSTOMER_PLATFORM/Customer/LOAD_CUSTOMERS_FAIL';

export const loadCustomers = ids => {
    return async dispatch => {
        dispatch({ type: LOAD_CUSTOMERS });
        try {
            const result = await dispatch(MasterData.customersByIds(ids));

            return dispatch({
                type: LOAD_CUSTOMERS_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_CUSTOMERS_FAIL,
                error
            });
        }
    };
};

export const LOAD_PERMISSIONS_HIERARCHY = 'CUSTOMER_PLATFORM/Customer/LOAD_PERMISSIONS_HIERARCHY';
export const LOAD_PERMISSIONS_HIERARCHY_SUCCESS = 'CUSTOMER_PLATFORM/Customer/LOAD_PERMISSIONS_HIERARCHY_SUCCESS';
export const LOAD_PERMISSIONS_HIERARCHY_FAIL = 'CUSTOMER_PLATFORM/Customer/LOAD_PERMISSIONS_HIERARCHY_FAIL';

export const loadPermissionsHierarchy = functionalLocations => {
    const filter = {
        where: {
            functionalLocation: {
                inq: functionalLocations
            }
        }
    };

    return async dispatch => {
        dispatch({ type: LOAD_PERMISSIONS_HIERARCHY });
        try {
            const result = await dispatch(MasterData.adminFindHierarchies(filter));

            return dispatch({
                type: LOAD_PERMISSIONS_HIERARCHY_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_PERMISSIONS_HIERARCHY_FAIL,
                error
            });
        }
    };
};

export default createReducerFromMapping({
    [SEARCH]: (state, action) => ({
        ...state,

        // Update partner search to include selected partner IDs. This is done
        // to support pagination so that we can re-invoke the search request when
        // a page changes.
        partnerSearch: {
            ...state.partnerSearch,
            ids: action.partners
        }
    }),
    [SEARCH_SUCCESS]: (state, action) => ({
        ...state,
        functionalLocationSearchResults: action.result.map(functionalLocation => ({
            ...functionalLocation,
        })),
        totalResults: action.result.length > 0 ? action.result[0].totalMatches : 0
    }),
    [SEARCH_FAIL]: (state, action) => ({
        ...state,
        functionalLocationSearchResults: [],
        error: action.error
    }),

    [RESET_SEARCH]: (state, action) => ({
        ...state,
        functionalLocationSearchResults: [],

        // Reset partners also, but leave options if requested.
        partnerSearch: action.leaveOptions
            ? { options: state.partnerSearch.options }
            : {}
    }),

    [CHANGE_TYPES]: (state, action) => ({
        ...state,
        typesToSearch: action.typesToSearch
    }),

    [PARTNER_SEARCH]: (state, action) => ({
        ...state,

        // Reset old functionalLocation results
        functionalLocationSearchResults: [],

        // Update keyword but don't touch options.
        partnerSearch: {
            ...state.partnerSearch,
            keyword: action.keyword
        }
    }),
    [PARTNER_SEARCH_SUCCESS]: (state, action) => ({
        ...state,

        // Put new options once we get the results.
        partnerSearch: {
            ...state.partnerSearch,
            options: action.result
                .map(partner => ({
                    value: partner.partnerNumber,
                    label: `${partner.name} (${partner.partnerNumber})`,
                    deleted: partner.deleted
                }))
        }
    }),
    [PARTNER_SEARCH_FAIL]: (state, action) => ({
        ...state,

        // Clear options on error.
        partnerSearch: {
            ...state.partnerSearch,
            options: []
        }
    }),

    [PARTNER_SEARCH_BY_IDS]: (state, action) => ({
        ...state,

        // Reset old functionalLocation results
        functionalLocationSearchResults: []
    }),
    [PARTNER_SEARCH_BY_IDS_SUCCESS]: (state, action) => ({
        ...state,

        // Put new options once we get the results.
        partnerSearch: {
            ...state.partnerSearch,
            options: action.result
                .map(x => ({ value: x.partnerNumber, label: `${x.name} (${x.partnerNumber})` }))
        }
    }),
    [PARTNER_SEARCH_BY_IDS_FAIL]: (state, action) => ({
        ...state,

        // Clear options on error.
        partnerSearch: {
            ...state.partnerSearch,
            options: []
        }
    }),

    [SEARCH_ALL]: (state, action) => ({
        ...state
    }),
    [SEARCH_ALL_SUCCESS]: (state, action) => ({
        ...state
    }),
    [SEARCH_ALL_FAIL]: (state, action) => ({
        ...state
    }),

    [GET_PORTFOLIO_COUNTS_SUCCESS]: (state, action) => ({
        ...state,
        portfolioCounts: {
            ...state.portfolioCounts,
            ...action.result,
        },
    }),

    [LOAD_CUSTOMERS]: (state, action) => ({
        ...state
    }),
    [LOAD_CUSTOMERS_SUCCESS]: (state, action) => ({
        ...state,
        customers: action.result.length > 0
            ? _.assign(_.clone(state.customers), ...action.result.map(x => ({ [x.partnerNumber]: x })))
            : state.customers
    }),
    [LOAD_CUSTOMERS_FAIL]: (state, action) => ({
        ...state,
        customers: action.error
    }),

    [LOAD_PERMISSIONS_HIERARCHY]: (state, action) => ({
        ...state
    }),
    [LOAD_PERMISSIONS_HIERARCHY_SUCCESS]: (state, action) => ({
        ...state,
        functionalLocations: action.result.length > 0
            ? _.assign(_.clone(state.functionalLocations), ...action.result.map(x => ({ [x.functionalLocation]: x })))
            : state.functionalLocations
    }),
    [LOAD_PERMISSIONS_HIERARCHY_FAIL]: (state, action) => ({
        ...state
    })
}, initialState);
