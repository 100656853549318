import React from 'react';
import translations from 'decorators/Translations/translations';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { head } from 'lodash';

import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import SkeletonText from 'components/Skeletons/SkeletonText';
// import SingleValuesForm from 'components/ValueImporter/SingleValuesForm';
import { getBuildingSensorsFromHierarchy } from 'utils/Data/sensorHierarchy';
import { importSensorValues } from 'redux/modules/index';
import CsvFileImport from './CsvFileImport';


const BuildingValueImporter = props => {
    const {
        t,
        loading,
        sensors,
        sensorTypes,
        importSensorValues,
        importingSensorValues,
        showNotification
    } = props;

    const handleSubmit = React.useCallback(async values => {
        await importSensorValues(values);
        showNotification({ type: 'success', message: t('Import succeeded') });
    }, [importSensorValues, showNotification, t]);

    return (
        <React.Fragment>
            <Section>
                <SectionHeader title={t('Import sensor values')} t={ t } />
                { loading
                    ? <SkeletonText />
                    : <CsvFileImport
                        sensors={ sensors }
                        sensorTypes={ sensorTypes }
                        onSubmitValues={ handleSubmit }
                        importingSensorValues={ importingSensorValues }
                    />
                }
            </Section>
            {/* Hide this section for now
            <Section>
                <SectionHeader title={t('Input single values')} t={ t } />
                { loading
                    ? <SkeletonText />
                    : <SingleValuesForm
                        t={ t }
                        sensors={ sensors }
                        sensorTypes={ sensorTypes }
                        onSubmitValues={ handleSubmit }
                    />
                }
            </Section>
            */}
        </React.Fragment>
    );
};

BuildingValueImporter.defaultProps = {
    loading: false,
    importingSensorValues: false
};

BuildingValueImporter.propTypes = {
    t: PropTypes.func.isRequired,
    sensors: PropTypes.arrayOf(PropTypes.object).isRequired,
    sensorTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    importSensorValues: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    importingSensorValues: PropTypes.bool
};

const EMPTY_ARRAY = [];

const hierarchySelector = (state, props) =>
    state.sensorHierarchy.buildingHierarchy[props.functionalLocation] || EMPTY_ARRAY;

const sensorSelector = createSelector(
    hierarchySelector,
    hierarchy => getBuildingSensorsFromHierarchy(head(hierarchy))
);

const mapStateToProps = (state, props) => ({
    sensors: sensorSelector(state, props),
    sensorTypes: state.sensorHierarchy.sensorDataTypes,
    importingSensorValues: state.values.importingSensorValues
});

const mapDispatchToProps = {
    importSensorValues
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(BuildingValueImporter));
