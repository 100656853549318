import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import styled, { withTheme } from 'styled-components';
import { isEqual } from 'lodash';
import { transparentize } from 'polished';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import ModalTypes, { SUBMIT_BUTTON_TYPE } from './ModalTypes';
import Button from 'components/Button/Button';
import ErrorText from 'components/ErrorText/ErrorText';
import { closeModal } from 'redux/modules/modal/modal';
import Loader from 'components/Loader/Loader.jsx';
import { showErrorMessage } from 'redux/modules/modal/modal';
import Svg from 'components/Svg/Svg';
import { toggleScroll } from 'utils/Modal/toggleScroll';

const MODAL_PADDING = 40;
const MOBILE_MODAL_PADDING = 25;


const ModalAdapter = ({ className, modalClassName, ...props }) =>
    <Modal
        className={modalClassName}
        portalClassName={className}
        {...props} >
    </Modal>;

const StyledModal = styled(ModalAdapter).attrs(() => ({
    overlayClassName: {
        base: 'Overlay',
        afterOpen: 'Overlay--after-open',
        beforeClose: 'Overlay--before-close'
    },
    modalClassName: {
        base: 'Modal',
        afterOpen: 'Modal--after-open',
        beforeClose: 'Modal--before-close'
    }
}))`
    .Modal {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: center;
        padding: ${MOBILE_MODAL_PADDING}px;
        background-color: ${props => props.theme.colors.white};
        text-align: center;

        ${props => props.theme.media.landscape`
            top: 50%;
            right: initial;
            bottom: initial;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: ${MODAL_PADDING}px;
            width: ${props => props.width}px;
            border-radius: 4px;
            box-shadow: 0 0 24px 0 rgba(51, 51, 51, 0.1);
        `}

        &--after-open {
            overflow-y: auto;
        }
    }

    .Overlay {
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${props => transparentize(0.3, props.theme.colors.black)};
        z-index: ${props => props.theme.zIndex('modal')};
        transition: opacity 400ms ease-in-out;

        &--after-open {
            opacity: 1;
        }
        &--before-close {
            opacity: 0;
        }
    }
`;

StyledModal.displayName = 'StyledModal';

const ModalHeader = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.theme.fontSize.xl};
  color: ${props => props.theme.colors.darkGray};
  font-family: ${props => props.theme.font.sanchez};
  line-height: ${props => props.theme.font.lineHeight.md};
  margin: ${props => props.theme.spacing.md} 0;
  width: inherit;
`;
ModalHeader.displayName = 'ModalHeader';

const ModalContent = styled.div`
    height: calc(100% - ${MOBILE_MODAL_PADDING - 5}px - 72px);
    overflow-y: auto;

    ${props => props.theme.media.landscape`
        max-height: 50vh;
        width: 100%;
    `}
`;
ModalContent.displayName = 'ModalContent';

const ButtonGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: ${MOBILE_MODAL_PADDING - 5}px;

    button {
        :not(:first-child) {
            margin-top: ${props => props.theme.spacing.xs};
        }
    }

    ${props => props.theme.media.landscape`
        margin-top: ${MODAL_PADDING - 10}px;
    `}
`;
ButtonGroup.displayName = 'ButtonGroup';

const CloseCross = styled(Svg)`
    display: none;
    position: absolute;
    font-size: ${props => props.theme.font.size.lg};
    top: ${props => props.theme.spacing.sm};
    right: ${props => props.theme.spacing.sm};
    fill: ${props => props.theme.colors.darkGray};

    &:hover {
        cursor: pointer;
    }

    ${props => props.theme.media.landscape`
        display: block;
    `}
`;

CloseCross.displayName = 'CloseCross';

const StyledErrorParagraph = styled.div`
  font-family: ${props => props.theme.font.arial};
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.bold};
`;

export class CModal extends Component {
    state = { loading: false };

    onSubmit = () => {
        this.setState({ loading: true }, () => {
            this.props.modal.onSubmitAction()
                .then(() => this.props.closeModal())
                .catch(error => this.props.showErrorMessage(error.message))
                .finally(() => this.setState({ loading: false }));
        });
    };

    checkPreConditions = () =>
        this.props.modal.preConditions().catch(error => this.props.showErrorMessage(error.message));

    getSubmitButtonColorByType = (type, theme) =>
        type === SUBMIT_BUTTON_TYPE.WARNING ? theme.colors.radicalRed : theme.colors.emerald;

    renderButtonGroupByType = ({ buttons }, { errorMessage }, theme, t) =>
        <ButtonGroup>
            {buttons.submit &&
                <Button
                    submit
                    data-test-id="ModalDeleteButton"
                    color={this.getSubmitButtonColorByType(buttons.submit.type, theme)}
                    disabled={!!errorMessage}
                    onClick={() => this.onSubmit()}
                >
                    {this.state.loading ? <Loader color='WHITE' size='SMALL' /> : t(buttons.submit.text)}
                </Button>}
            {buttons.cancel &&
                <Button
                    cancel
                    data-test-id="ModalCloseButton"
                    onClick={() => this.props.closeModal()}
                    textColor={ theme.colors.black }
                >
                    {t(buttons.cancel.text)}
                </Button>}
        </ButtonGroup>;

    componentDidUpdate(prevProps) {
        if (this.props.modal
            && this.props.modal.preConditions
            && !isEqual(prevProps.modal.preConditions, this.props.modal.preConditions)) {
            this.checkPreConditions();
        }
    }

    render() {
        const { t, modal, theme, closeModal, announcementMenuOpen } = this.props;

        const modalConfiguration = ModalTypes[modal.modalType];
        const header = modalConfiguration && modalConfiguration.getHeader(t);

        if (!modal.show) {
            !announcementMenuOpen && toggleScroll(false);
            return null;
        }

        toggleScroll(true);

        return (
            <StyledModal
                isOpen
                className='Modal'
                overlayClassName='Overlay'
                closeTimeoutMS={600}
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={true}
                width={ modalConfiguration.width }
                {...this.props} >
                <CloseCross name="remove" onClick={closeModal} />
                <ModalContent>
                    {modalConfiguration.getIcon()}
                    { !!header && <ModalHeader>{ header }</ModalHeader> }
                    {modalConfiguration.getContent(modal.passedProps)}
                    {modal.errorMessage &&
                        <ErrorText style={{ margin: '12px' }}>
                            <StyledErrorParagraph>{t(modal.errorMessage)}</StyledErrorParagraph>
                        </ErrorText>
                    }
                </ModalContent>
                {this.renderButtonGroupByType(modalConfiguration, modal, theme, t)}
            </StyledModal>
        );
    }
}

CModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    showErrorMessage: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    modal: PropTypes.shape({
        show: PropTypes.bool.isRequired,
        modalType: PropTypes.string.isRequired,
        errorMessage: PropTypes.string,
        preConditions: PropTypes.func,
        onSubmitAction: PropTypes.func,
        passedProps: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string])
    })
};

CModal.defaultProps = {
    modal: PropTypes.shape({
        show: false,
        modalType: null,
        preConditions: null,
        onSubmitAction: null,
        passedProps: {}
    })
};

const mapStateToProps = state => ({
    modal: state.modal,
    announcementMenuOpen: state.announcement.menuOpen,
});

const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch(closeModal()),
    showErrorMessage: message => dispatch(showErrorMessage(message))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const CModalWithTheme = withTheme(CModal);

export default connector(translations(withTheme(CModal)));
