import React, { PureComponent } from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import styled from 'styled-components';

const TabsContent = styled.div`
    ${props => props.theme.media.landscape`
        padding-top: 2em;
    `}
`;

class Tabs extends PureComponent {
    render() {
        const { location, children, useKeysToSelectTab } = this.props;
        const selectedTab = queryString.parse(location.search).tab;

        return (
            <TabsContent>
                { React.Children.map(children, (child, idx) => {
                    if (child) {
                        const tab = useKeysToSelectTab && child.key ? child.key.toLowerCase() : child.props.title.toLowerCase();
                        const selected = selectedTab === tab || !selectedTab && idx === 0;
                        if (selected) {
                            return child;
                        }
                        return null;
                    }
                    return child;
                }) }
            </TabsContent>
        );
    }
}

export default withRouter(Tabs);
