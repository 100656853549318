import React, { Component, Fragment } from 'react';
import translations from 'decorators/Translations/translations';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { uniq, isEmpty, isBoolean, isEqual, keys, omit,
    reject, isNil, isArray, transform, cloneDeep, pickBy, includes, every, isPlainObject } from 'lodash';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import Hero from 'components/Hero/Hero';
import StandardPage from 'components/StandardPage/StandardPage';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage';
import Header from 'containers/Application/Header/Header';

import { loadPermissions } from 'redux/modules/profile/profile';

import Benchmarking from 'components/Modules/EnergyModule/Benchmarking/Benchmarking';
import {
    defaultValues
} from '../Modules/EnergyModule/EnergyModuleUtils';
import { loadBuildingMeta } from 'redux/modules/buildingConfig/buildingMeta';
import {
    generateFilterInputs,
    getFLsByFilterMatchedBuildingMeta,
    extendEnergyRatingObjectsForBenchMarking,
    hasPartnerChanged,
    filterFLsBySelectedPartner,
    hasFunctionalLocationsChanged,
    hasFilters,
} from './EnergyOptimizationUtil';
import { EnergyOptimizationOpiCards } from './EnergyOptimizationOpiCards';
import { EnergyOptimizationSkeleton } from './EnergyOptimizationSkeleton';
import { isValidPartner } from 'utils/Data/partners';
import { getPartnerNumbers } from 'utils/profile';
import EnergyOptimizationFilter from './EnergyOptimizationFilter/EnergyOptimizationFilter';
import { loadEnergyRating } from 'redux/modules/iot/values';

const StyledMessage = styled.p`
    width: 100%;
    text-align: center;
    margin-top: ${props => props.theme.spacing.xl};
`;


export class EnergyOptimization extends Component {

    constructor(props) {
        super(props);
        this.state = {
            energyRatingValues: [],
            valuesByType: {},
            totalValues: {
                ...defaultValues
            },
            selectedFilterValues: {},
            toggleFilter: false,
            filtersOn: false,
            filterableFLs: [],
            filterMatchDataFound: true,
        };
    }

    handleFilterChange = (property, value, originalValue) => {
        const oldSelectedValues = this.state.selectedFilterValues;
        let newSelectedValues;

        // remove from filter if value is nil
        if (isNil(value) || isEmpty(value)) {
            // support multi-value filters
            if (isArray(oldSelectedValues[property])
                && isPlainObject(oldSelectedValues[property][0])
                && oldSelectedValues[property].length > 1) {
                newSelectedValues = {
                    ...this.state.selectedFilterValues,
                    [property]: reject(oldSelectedValues[property], { value: originalValue })
                };
            }
            else {
                newSelectedValues = omit(oldSelectedValues, property);
            }
        }
        else {
            newSelectedValues = { ...oldSelectedValues, [property]: value };
        }

        this.setState({ selectedFilterValues: newSelectedValues }, this.onFilterSubmit);
    };

    onClickFilter = () => this.setState({ toggleFilter: !this.state.toggleFilter });

    resetFilter = () => this.setState({ selectedFilterValues: {}, filterableFLs: [] });

    onFilterSubmit = () => {
        const {
            buildingMeta: { meta },
            energyRatingValuesByFL
        } = this.props;
        const { selectedFilterValues } = this.state;

        const filteredFLs = uniq(getFLsByFilterMatchedBuildingMeta(meta, selectedFilterValues));
        let energyRatingValues;

        // if selected filter is empty, show all data
        if (isEmpty(selectedFilterValues) || every(selectedFilterValues, isEmpty)) {
            energyRatingValues = this.transformEnergyRatingValues(energyRatingValuesByFL);
        }
        // if filtered FLs are empty, return and show "no data"
        else if (isEmpty(filteredFLs)) {
            return this.setState({
                filterMatchDataFound: false,
                filterableFLs: [],
                energyRatingValues: []
            });
        }
        // show data for filtered FLs
        else {
            energyRatingValues = this.transformEnergyRatingValues(
                this.filterEnergyRatingValues(energyRatingValuesByFL, filteredFLs));
        }

        this.setState({
            energyRatingValues,
            filterMatchDataFound: true,
            filterableFLs: filteredFLs
        });
    };

    filterEnergyRatingValues = (values, flFilter) => {
        return pickBy(values, (value, key) => includes(flFilter, key));
    };

    transformEnergyRatingValues = energyRatingValues => {
        return transform(energyRatingValues, (result, value, key) => {
            if (key !== 'averages' && value && value.latest) {
                result.push({
                    ...value,
                    functionalLocation: key,
                    unit: 'kWh/m²'
                });
            }
        }, []);
    };

    setEnergyValuesToState = values => {
        const energyRatingValues = this.transformEnergyRatingValues(values);
        this.setState({ energyRatingValues });
    };

    resetStateEnergyValues = () => {
        this.setState({ energyRatingValues: [] });
    };

    get opiCards() {
        const { t, energyRatingValuesByFL, buildingMeta } = this.props;
        const { totalValues, valuesByType, filterableFLs } = this.state;
        const filter = generateFilterInputs(buildingMeta, t);
        return <EnergyOptimizationOpiCards
            t={t}
            totalValues={totalValues}
            energyRatingValuesByFL={energyRatingValuesByFL}
            filterableFLs={filterableFLs}
            valuesByType={valuesByType}
            hasFilters={ hasFilters(filter) }
        />;
    }

    get benchmark() {
        const { energyRatingValues } = this.state;
        const {
            t,
            functionalLocations,
            features: { energyTab },
            match: { params: { partnerNumber } }
        } = this.props;

        if (!isEmpty(energyRatingValues)) {
            const benchmarkValues = cloneDeep(energyRatingValues);
            extendEnergyRatingObjectsForBenchMarking(benchmarkValues, functionalLocations, t);
            return <Benchmarking
                performers={benchmarkValues}
                t={t}
                energyTabEnabled={isBoolean(energyTab) ? energyTab : false}
                partnerNumber={partnerNumber} />;
        }
    }


    get customerName() {
        const { customers, match: { params: { partnerNumber } } } = this.props;
        return customers[partnerNumber] && customers[partnerNumber].name;
    }

    get energyFilter() {
        const { t, loading, buildingMeta, match: { params: { partnerNumber } } } = this.props;
        const { toggleFilter, selectedFilterValues, filterMatchDataFound, energyRatingValues } = this.state;
        const noData = !loading
            && isEmpty(selectedFilterValues)
            && (!filterMatchDataFound || isEmpty(energyRatingValues));
        const filter = generateFilterInputs(buildingMeta, t);

        if (!isValidPartner(partnerNumber) || noData || !hasFilters(filter) || loading) {
            return null;
        }

        return (
            <EnergyOptimizationFilter
                t={t}
                filtersOpen={toggleFilter}
                filter={filter}
                toggleFilterClick={this.onClickFilter}
                selectedFilterValues={selectedFilterValues}
                loading={loading}
                handleFilterChange={ this.handleFilterChange }
            />
        );
    }

    get energyContent() {
        return <section>
            {this.benchmark}
        </section>;
    }

    get skeleton() {
        return <EnergyOptimizationSkeleton />;
    }

    get content() {
        return (
            <Fragment>
                {this.opiCards}
                {this.energyContent}
            </Fragment>
        );
    }

    get dataNotFound() {
        const { t } = this.props;
        return <StyledMessage>{t('No data available')}</StyledMessage>;
    }

    get renderCase() {
        const { loading } = this.props;
        const { energyRatingValues, filterMatchDataFound } = this.state;
        const noData = isEmpty(energyRatingValues);
        if (loading) {
            return this.skeleton;
        }
        if (!filterMatchDataFound || noData) {
            return this.dataNotFound;
        }
        return this.content;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
    }

    componentDidUpdate(prevProps) {
        const {
            match: { params: { partnerNumber } },
            energyRatingValuesByFL,
            functionalLocations,
            loadPermissions,
            loadBuildingMeta,
            loadEnergyRatingValuesByFL,
        } = this.props;
        if (hasFunctionalLocationsChanged(functionalLocations, prevProps)) {
            loadEnergyRatingValuesByFL(keys(functionalLocations));
            loadBuildingMeta(keys(functionalLocations));
        }
        if (hasPartnerChanged(prevProps, partnerNumber)) {
            loadPermissions();
            this.resetStateEnergyValues();
            this.resetFilter();
        }
        if (!isEqual(energyRatingValuesByFL, prevProps.energyRatingValuesByFL)) {
            this.setEnergyValuesToState(energyRatingValuesByFL);
        }
    }

    componentDidMount() {
        const {
            energyRatingValuesByFL,
            loadEnergyRatingValuesByFL,
            loadBuildingMeta,
            functionalLocations,
            loadPermissions
        } = this.props;
        if (isEmpty(functionalLocations)) {
            loadPermissions();
        }
        else {
            loadEnergyRatingValuesByFL(keys(functionalLocations));
            loadBuildingMeta(keys(functionalLocations));
        }
        if (!isEmpty(energyRatingValuesByFL)) {
            this.setEnergyValuesToState(energyRatingValuesByFL);
        }
    }

    render() {
        const { t, match: { params: { partnerNumber } }, profile } = this.props;

        if (!partnerNumber || partnerNumber === 'all') {
            return <ErrorPage type="selectPartner" />;
        }
        if (!includes(getPartnerNumbers(profile), partnerNumber)) {
            return <ErrorPage type="partner" />;
        }

        return (
            <StandardPage withTabs>
                <Helmet title={t('Energy Optimization')} />
                <Header t={ t } selected="energy optimization" showPartnerSelect />
                <Hero
                    title={ t('Energy Optimization') }
                    subTitle={ this.customerName }
                    t={ t }
                    type="ENERGY"
                />
                <div>
                    {this.energyFilter}
                    {this.renderCase}
                </div>
            </StandardPage>
        );
    }
}

EnergyOptimization.defaultProps = {
    energyValuesByPartner: {},
    energyValues: {},
    energyRatingValuesByFL: {
        averages: {
            previous: null,
            latest: null
        }
    }
};

EnergyOptimization.propTypes = {
    t: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            partnerNumber: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    buildingMeta: PropTypes.shape({
        meta: PropTypes.object.isRequired
    }).isRequired,
    functionalLocations: PropTypes.object.isRequired,
    customers: PropTypes.object.isRequired,
    loadBuildingMeta: PropTypes.func.isRequired,
    loadPermissions: PropTypes.func.isRequired,
    energyValuesByPartner: PropTypes.object,
    energyValues: PropTypes.object,
    features: PropTypes.object.isRequired,
    energyRatingValuesByFL: PropTypes.object,
    loadEnergyRatingValuesByFL: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => ({
    energyValuesByPartner: state.values.energyValuesByPartner,
    energyValues: state.values.energyValues,
    loading: state.values.energyRating.loading || state.profile.loading,
    buildingMeta: state.buildingMeta,
    functionalLocations: filterFLsBySelectedPartner(
        state.functionalLocations.functionalLocations, props.match.params.partnerNumber),
    customers: state.customer.customers,
    features: state.profile.profile.features,
    energyRatingValuesByFL: state.values.energyRating.data,
    profile: state.profile.profile,
});

const mapDispatchToProps = dispatch => ({
    loadBuildingMeta: functionalLocations => dispatch(loadBuildingMeta(functionalLocations)),
    loadPermissions: () => dispatch(loadPermissions()),
    loadEnergyRatingValuesByFL: functionalLocations => dispatch(loadEnergyRating(functionalLocations))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withTheme(connector(translations(EnergyOptimization)));
