import { upperFirst } from 'lodash';

const SENSOR_TYPE_NAME_MAP = {
    'indoor temperature': 'temperature',
    'outdoor temperature': 'temperature',
    carbondioxide: 'CO₂',
    'organic_gas': 'TVOC',
    'particle/PM10': 'PM10',
    'particle/PM2.5': 'PM2.5',
};

export const humanizeSensorTypeName = (t, sensorTypeName) => {
    if (!sensorTypeName) { return; }

    const type = SENSOR_TYPE_NAME_MAP[sensorTypeName] || sensorTypeName;
    const translated = t(type);
    if (translated !== type) {
        return translated;
    }

    return t(upperFirst(type).replace('_', ' '));
};
