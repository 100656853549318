import { createReducerFromMapping } from 'redux/utils/index.js';
import { IoT } from '@caverion/redux/api/actions';
import { keyBy, reject } from 'lodash';

const initialState = {
    loading: false,
    sensorAlarms: {},
    sensorAlarmsById: {},
    error: ''
};

export const LOAD_SENSOR_ALARMS = 'CUSTOMER_PLATFORM/SensorAlarms/LOAD_SENSOR_ALARMS';
export const LOAD_SENSOR_ALARMS_SUCCESS = 'CUSTOMER_PLATFORM/SensorAlarms/LOAD_SENSOR_ALARMS_SUCCESS';
export const LOAD_SENSOR_ALARMS_FAIL = 'CUSTOMER_PLATFORM/SensorAlarms/LOAD_SENSOR_ALARMS_FAIL';

export const loadSensorAlarms = () => {
    return async dispatch => {
        dispatch({ type: LOAD_SENSOR_ALARMS });
        try {
            const result = await dispatch(IoT.sensorAlarmConfig());
            return dispatch({
                type: LOAD_SENSOR_ALARMS_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_SENSOR_ALARMS_FAIL,
                error
            });
        }
    };
};

export const UPDATE_SENSOR_ALARMS = 'CUSTOMER_PLATFORM/SensorAlarms/UPDATE_SENSOR_ALARMS';
export const UPDATE_SENSOR_ALARMS_SUCCESS = 'CUSTOMER_PLATFORM/SensorAlarms/UPDATE_SENSOR_ALARMS_SUCCESS';
export const UPDATE_SENSOR_ALARMS_FAIL = 'CUSTOMER_PLATFORM/SensorAlarms/UPDATE_SENSOR_ALARMS_FAIL';

export const updateSensorAlarms = sensorAlarms => {
    return async dispatch => {
        dispatch({ type: UPDATE_SENSOR_ALARMS });
        try {
            const result = await dispatch(IoT.upsertSensorAlarmConfig(sensorAlarms));
            return dispatch({
                type: UPDATE_SENSOR_ALARMS_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: UPDATE_SENSOR_ALARMS_FAIL,
                error
            });
        }
    };
};

export const deleteSensorAlarm = sensorId => {
    return async (dispatch, getState) => {
        const sensorAlarms = getState().sensorAlarms.sensorAlarms;
        const sensors = reject(sensorAlarms.sensors, { id: sensorId });
        return dispatch(updateSensorAlarms({ sensors }));
    };
};

export default createReducerFromMapping({
    [LOAD_SENSOR_ALARMS]: state => ({ ...state, loading: true }),
    [LOAD_SENSOR_ALARMS_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [LOAD_SENSOR_ALARMS_SUCCESS]: (state, action) => ({
        ...state,
        sensorAlarms: action.result,
        sensorAlarmsById: !action.result || !action.result.sensors
            ? {}
            : keyBy(action.result.sensors, 'id'),
        loading: false
    }),
    [UPDATE_SENSOR_ALARMS]: state => ({ ...state, loading: true }),
    [UPDATE_SENSOR_ALARMS_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [UPDATE_SENSOR_ALARMS_SUCCESS]: (state, action) => ({
        ...state,
        sensorAlarms: action.result,
        sensorAlarmsById: !action.result || !action.result.sensors
            ? {}
            : keyBy(action.result.sensors, 'id'),
        loading: false
    }),
}, initialState);
