import styled, { css } from 'styled-components';

const TableCell = styled.td`
    padding: ${props => props.padding || `${props.theme.spacing.md} ${props.theme.spacing.xs}`};

    ${props => props.removeSidePadding && css`
        &:first-child {
            padding-left: 0;
        }
    `}

    ${props => props.align && css`
        text-align: ${props.align};
    `}

    ${props => props.cellWidthMobile || props.cellWidth && css`
        width: ${props.cellWidthMobile || props.cellWidth};
        min-width: ${props.cellWidthMobile || props.cellWidth};
    `}

    ${props => props.theme.media.portrait`
        ${props => props.cellWidth && css`
            width: ${props.cellWidth};
            min-width: ${props.cellWidth};
        `}
    `}

    ${props => props.theme.media.landscape`
        padding: ${props => props.padding || props.theme.spacing.md};
    `}
`;

export default TableCell;
