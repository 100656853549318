const rejectionTracking = require('promise/lib/rejection-tracking');
const objectAssign = require('object-assign');

if (typeof Promise === 'undefined') {
    // Rejection tracking prevents a common issue where React gets into an
    // inconsistent state due to an error, but it gets swallowed by a Promise,
    // and the user has no idea what causes React's erratic future behavior.
    rejectionTracking.enable();
    window.Promise = require('promise/lib/es6-extensions.js');
}

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = objectAssign;

// IE11 doesn't support Event constructor
function Event(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    const evt = document.createEvent('Event');
    evt.initEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
}

if (typeof window.Event !== 'function') {
    Event.prototype = window.Event.prototype;
    window.Event = Event;
}
