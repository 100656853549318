import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Transition } from 'react-transition-group';
import { transparentize } from 'polished';
import translations from 'decorators/Translations/translations';
import FeedbackPanel from './FeedbackPanel';
import FeedbackButton from './FeedbackButton';
import { toggleFeedbackPanel, sendFeedback } from 'redux/modules/feedback/feedback';
import SnackBar from 'components/SnackBar/SnackBar';
import { notificationTimeout } from 'containers/Application/Admin/Building/AdminEditBuilding';


const FeedbackContainer = styled.div`
    position: relative;
    height: 100%;
    width: 0px;
    display: flex;

    ${props => props.theme.media.portrait`
        border: solid ${props => rgba(props.theme.colors.white, 0.20)};
        border-width: 0 0 0 1px;
        width: 60px;
        justify-content: center;
    `}
`;

const Overlay = styled.div`
    pointer-events: auto;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${props => props.active ? 1 : 0}
    transition: opacity 0.3 ease-in-out;
    z-index: ${props => props.theme.zIndex('feedback') - 1};
    background-color: ${props => transparentize(0.6, props.theme.colors.black)};
`;
Overlay.displayName = 'Overlay';


export const Feedback = props => {
    const {
        t,
        feedbackMenuOpen,
        toggleFeedbackPanel,
        sendFeedback,
        sending,
        sent,
        error
    } = props;
    const noNotification = { type: '', visible: false, message: '' };
    const [notification, setNotification] = useState(noNotification);
    const onSubmit = async feedback => {
        await sendFeedback({
            ...feedback,
            sentFromUrl: window && window.location.href
        });
        setNotification({ type: 'success', visible: true, message: t('Thank you for your feedback!') });
        setTimeout(() => setNotification(noNotification), notificationTimeout);
    };

    return (
        <FeedbackContainer data-test-id="Feedback">
            <Transition
                in={ feedbackMenuOpen }
                timeout={{
                    enter: 0,
                    exit: 300,
                }}
                unmountOnExit
                mountOnEnter
            >
                { transitionState => {
                    const active = transitionState === 'entered';
                    return (
                        <Fragment>
                            <Overlay key="feedbackOverlay" active={ active } onClick={ toggleFeedbackPanel } />
                            <FeedbackPanel
                                key="feedbackPanel"
                                t={ t }
                                onClose={ toggleFeedbackPanel }
                                onSubmit={ onSubmit }
                                active={ active }
                                error={ error }
                                sending={ sending }
                                sent={ sent }
                            />
                        </Fragment>
                    );
                }}
            </Transition>
            <FeedbackButton onClick={ toggleFeedbackPanel } />
            <SnackBar variant={ notification.type } visible={ notification.visible }>
                { notification.message }
            </SnackBar>
        </FeedbackContainer>
    );
};

Feedback.displayName = 'Feedback';

Feedback.propTypes = {
    t: PropTypes.func.isRequired,
    feedbackMenuOpen: PropTypes.bool.isRequired,
    toggleFeedbackPanel: PropTypes.func.isRequired,
    sendFeedback: PropTypes.func.isRequired,
    sending: PropTypes.bool,
    sent: PropTypes.bool,
    error: PropTypes.string,
};

const mapStateToProps = state => ({
    feedbackMenuOpen: state.feedback.menuOpen,
    sending: state.feedback.sending,
    sent: state.feedback.sent,
    error: state.feedback.error,
});

const mapDispatchToProps = {
    toggleFeedbackPanel,
    sendFeedback
};

export default connect(mapStateToProps, mapDispatchToProps)(translations(Feedback));
