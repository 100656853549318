import styled from 'styled-components';

export const PermissionListItemGroup = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: ${props => props.spread ? 'space-between' : 'start' }
    margin-right: ${props => props.theme.spacing.xs};

    ${props => props.spread && `
        & > * {
            flex: 1;
        }`
    }
`;
