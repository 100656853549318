import React, { Component } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';

import { StandardPage } from 'components/index.js';
import Giosg from 'components/Giosg/Giosg.jsx';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';

import themes from 'styles/themes';

const HeaderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${props => props.theme.navigation.height};
    z-index: ${props => props.theme.zIndex('header')};

    ${props => props.theme.media.landscape`
        position: fixed;
    `};
`;

const Header = styled.header`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${props => props.theme.navigation.height};
    width: 100%;
    padding: 0 ${props => props.theme.spacing.md};
    background-color: ${props => props.theme.colors.midnight};
    z-index: ${props => props.theme.zIndex('header')};
`;

const Lists = styled.div`
    display: flex;
    flex-direction: column;

    ${props => props.theme.media.landscape`
        flex-direction: row;
    `}
`;

const ListWrapper = styled.div`
    flex: 1;
    margin-bottom: ${props => props.theme.spacing.sm};
`;

const ListHeader = styled.h3`
    margin-bottom: ${props => props.theme.spacing.sm};
`;

const Important = styled.h4`
    margin: ${props => props.theme.spacing.xl} 0;
    text-align: center;
`;

const Logo = styled.img`
    height: 36px;
`;

const Link = styled.a`
    display: block;
    margin: 10px 0;
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fontSize.xs};
`;

const List = styled.ul`
    list-style: disc;
    padding-left: ${props => props.theme.spacing.md};
`;

const ListItem = styled.li`
    padding: ${props => props.theme.spacing.xs} 0;
`;

const textOverride = 'Keskustelu ei ole juuri nyt käytettävissä.\n\nVikailmoitukset:\n' +
    'puh. 0800 157 777\nkiinteistohelp@caverion.com';

class Helpdesk extends Component {

    render() {
        return (
            <ThemeProvider theme={ themes.customerPlatform }>
                <StandardPage noMargin>
                    <Helmet titleTemplate="Helpdesk" />
                    <HeaderContainer>
                        <Header>
                            <Logo src="/caverion_logo_white.svg" alt="logo"/>
                            <Link href="http://www.caverion.com">Caverion.com main site &rarr;</Link>
                        </Header>
                    </HeaderContainer>
                    <Section>
                        <SectionHeader title={ 'Caverion Kiinteistö-HelpDesk – Ongelmia kiinteistössä?' } />
                        <Lists>
                            <ListWrapper>
                                <ListHeader>Yhteystiedot HelpDeskiin</ListHeader>
                                <List>
                                    { /* eslint-disable max-len */ }
                                    <ListItem>
                                        <a href="tel:0800 157 777">0800 157 777</a> (24/7)
                                    </ListItem>
                                    <ListItem>Chat (sivun oikea alareuna)</ListItem>
                                    <ListItem>
                                        <a href="mailto:kiinteistohelp@caverion.com">
                                            kiinteistohelp@caverion.com
                                        </a>
                                    </ListItem>
                                    <ListItem>
                                        <a
                                            href="https://www.caverion.fi/yhteystiedot/kiinteisto-helpdesk-24h/palveluilmoitus"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            https://www.caverion.fi/yhteystiedot/kiinteisto-helpdesk-24h/palveluilmoitus
                                        </a>
                                    </ListItem>
                                    { /* eslint-enable max-len */ }
                                </List>
                            </ListWrapper>
                            <ListWrapper>
                                <ListHeader>Antaessasi vikailmoitusta Caverion HelpDeskiin</ListHeader>
                                <List>
                                    { /* eslint-disable max-len */ }
                                    <ListItem>Kerro tarkat yhteystietosi (nimi, puhelinnumero, sähköposti)</ListItem>
                                    <ListItem>Kerro mistä soitat; osoite, kaupunki</ListItem>
                                    <ListItem>Kerro tilaajan yritys, mikäli tilaajataho on eri kuin soittaja</ListItem>
                                    <ListItem>Kerro kerros ja huonenumero / tila sekä viallisen laitteen tiedot</ListItem>
                                    <ListItem>Kerro viankuvaus; esimerkiksi wc-tukos, lampun vaihto, muu kiinteistöön puute tai vika</ListItem>
                                    <ListItem>Kerro kohteessa olevan yhteyshenkilön tiedot, mikäli yhteyshenkilö on eri kuin soittaja</ListItem>
                                    <ListItem>Sähköpostiviestit käsitellään saapumisjärjestyksessä arkisin klo 7-21 ja lauantaisin, sunnuntaisin sekä arkipyhinä 8-20</ListItem>
                                    { /* eslint-enable max-len */ }
                                </List>
                            </ListWrapper>
                        </Lists>
                        { /* eslint-disable max-len */ }
                        <Important>Kiireellisistä asioista on aina soitettava (esimerkiksi vesivuoto, sähkökatkos)</Important>
                        { /* eslint-enable max-len */ }
                    </Section>
                    <Giosg giosgId={ 4687 } textOverride={ textOverride } />
                </StandardPage>
            </ThemeProvider>
        );
    }
}

export default Helpdesk;
