import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Svg from 'components/Svg/Svg';

const StyledDescriptionColumn = styled.div``;

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const Title = styled.h3`
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.fontSize.xs};
    font-weight: ${props => props.theme.fontWeight.bold};
`;

const Description = styled.p`
    position: relative;
    font-size: ${props => props.theme.fontSize.xs};
    line-height: ${props => props.theme.lineHeight.text};
    color: ${props => props.noValue ? props.theme.colors.blue : props.theme.colors.darkGray};
    overflow: hidden;
    transition: max-height 280ms linear;
    white-space: pre-line;
    max-height: 0;

    ${props => props.visible && css`
        max-height: 20em;
    `}
`;

const Toggle = styled(Svg)`
    margin: 0 ${props => props.theme.spacing.xs}
    fill: ${props => props.theme.colors.darkGray};
`;

class ToggleDescriptionColumn extends PureComponent {

    static propTypes = {
        data: PropTypes.shape({
            value: PropTypes.string,
            description: PropTypes.string
        }).isRequired
    };

    state = {
        visible: false
    };

    render() {
        const { value, description } = this.props.data;
        const { visible } = this.state;
        const toggleIcon = visible ? 'ion-arrow-up' : 'ion-arrow-down';

        return (
            <StyledDescriptionColumn>
                <TitleContainer onClick={ () => this.setState(prevState => ({ visible: !prevState.visible })) }>
                    { value && <Title>{ value }</Title> }
                    <Toggle name={ toggleIcon }/>
                </TitleContainer>
                { description &&
                    <Description visible={ visible } noValue={ !value }>
                        { description }
                    </Description>
                }
            </StyledDescriptionColumn>
        );
    }
}

export default ToggleDescriptionColumn;
