import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';

import translations from 'decorators/Translations/translations';
import DocumentTable from 'components/Documents/DocumentTable/DocumentTable';
import SkeletonPill from 'components/Skeletons/SkeletonPill';
import Section from 'components/Section/Section';

import {
    downloadDocument,
    filterDocumentsByKeyword,
    resetFilterDocumentsByKeyword
} from 'redux/modules/index.js';

import { InputSearch } from 'components/index.js';

const Heading = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: ${props => props.theme.spacing.md} 0;
`;

const Title = styled.h3`
    padding-right: ${props => props.theme.spacing.xl};
`;

class ExternalDocumentModule extends Component {
    componentDidMount() {
        const { resetFilterDocumentsByKeyword } = this.props;
        resetFilterDocumentsByKeyword();
    }

    componentDidUpdate(prevProps) {
        const { equipment, functionalLocation, resetFilterDocumentsByKeyword } = this.props;

        if (!_.isEqual(equipment, prevProps.equipment)) {
            resetFilterDocumentsByKeyword();
        }
        if (!_.isEqual(functionalLocation, prevProps.functionalLocation)) {
            resetFilterDocumentsByKeyword();
        }
    }

    download = file => {
        return this.props.downloadDocument(
            this.props.functionalLocation.functionalLocation,
            file.documentId,
            file.id,
            `${file.name}.${file.extension}`
        );
    };

    renderDocuments(documents) {
        const showSkeletons = documents === null;

        return (
            <Fragment>
                <Heading>
                    <Title>{ this.props.t('Documents') }</Title>
                    { showSkeletons ? <SkeletonPill width="300px" /> :
                    <InputSearch
                        onChange={ value => this.props.filterDocumentsByKeyword(value) }
                        onClear={ () => this.props.resetFilterDocumentsByKeyword() }
                        placeholder={ this.props.t('Search from documents') }
                        initialValue={ this.props.documentFilterKeyword }
                        width="300px"
                    />
                    }
                </Heading>

                <DocumentTable
                    loading={ showSkeletons }
                    t={ this.props.t }
                    documents={ documents }
                    download={ this.download }
                />
            </Fragment>
        );
    }

    renderFunctionalLocation() {
        if (!this.props.functionalLocation || this.props.equipment) {
            return null;
        }

        const documents = _.get(this.props.functionalLocationDocuments,
            [this.props.functionalLocation.functionalLocation]);

        return this.renderDocuments(documents);
    }

    renderServiceOrder() {
        if (!this.props.serviceOrder) {
            return null;
        }

        const documents = _.get(this.props.serviceOrderDocuments, [this.props.serviceOrder.serviceOrderNumber]);
        return this.renderDocuments(documents);
    }

    renderEquipment() {
        if (!this.props.equipment) {
            return null;
        }

        const documents = _.get(this.props.equipmentDocuments, [this.props.equipment.equipmentNumber]);
        return this.renderDocuments(documents);
    }

    render() {
        const { profile: { profile: { features } }, serviceOrder, equipment, functionalLocation } = this.props;
        if (!features || !features.documents) {
            return null;
        }

        if (!serviceOrder && !equipment && !functionalLocation) {
            return (
                <Section>
                    { this.renderDocuments(null) }
                </Section>
            );
        }

        return (
            <Section>
                { this.renderFunctionalLocation() }
                { this.renderServiceOrder() }
                { this.renderEquipment() }
            </Section>
        );
    }
}

const mapStateToProps = state => ({
    profile: state.profile,
    functionalLocationDocuments: state.document.filteredFunctionalLocations,
    serviceOrderDocuments: state.document.filteredServiceOrders,
    equipmentDocuments: state.document.filteredEquipment,
    documentFilterKeyword: state.document.filterByKeyword
});

const mapDispatchToProps = dispatch => ({
    downloadDocument: (functionalLocation, documentId, fileId, filename) =>
        dispatch(downloadDocument(functionalLocation, documentId, fileId, filename)),

    filterDocumentsByKeyword: value => dispatch(filterDocumentsByKeyword(value)),

    resetFilterDocumentsByKeyword: () => dispatch(resetFilterDocumentsByKeyword())
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(ExternalDocumentModule));
