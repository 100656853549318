import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import Select from 'react-select';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';

import {
    getSectionTitleText,
    scrollTo,
    getSectionOptions,
    getSectionSubjectText,
    SLICE_TYPE
} from './utils/UserManualUtils';
import { BackLink } from './UserManualBackLink';
import SkeletonText from 'components/Skeletons/SkeletonText';

const StyledUserManualSectionListDesktop = styled.div`
    display: none;
    background-color: ${props => props.theme.colors.midnight};
    ${props => props.theme.media.landscape`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: fixed;
        top: 0;
        padding-top: 48px;
        bottom: 0;
        width: 25%;
        max-width: 300px;
        padding-bottom: ${props => props.theme.spacing.md};
        transition: padding 0.1s ease-in;
    `}
    h3 {
        font-size: ${props => props.theme.font.size.sm};
        color: ${props => props.theme.colors.lightGray};
        line-height: 1.5;
    }
`;

StyledUserManualSectionListDesktop.displayName = 'StyledUserManualSectionListDesktop';

const StyledUserManualSectionListMobile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    height: auto;
    width: 100%;
    top: 0;
    padding-top: ${props => props.top ? 0 : props.theme.navigation.height};
    transition: padding 0.1s ease-in;
    background-color: ${props => props.theme.colors.midnight};
    h3 {
        font-size: ${props => props.theme.font.size.sm};
        color: ${props => props.theme.colors.lightGray};
        line-height: 1.5;
    }
    ${props => props.theme.media.landscape`
        display: none;
    `}
`;

StyledUserManualSectionListMobile.displayName = 'StyledUserManualSectionListMobile';

const Separator = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${props => props.theme.colors.rockBlue};
`;

Separator.displayName = 'Separator';

const UserManualSectionHeader = styled.p`
    width: 100%;
    font-size: ${props => props.theme.font.size.xxs};
    font-family: ${props => props.theme.font.family.arial};
    font-weight: ${props => props.theme.font.weight.bold};
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    padding: ${props => props.theme.spacing.md};
    ${props => props.theme.media.landscape`
        padding: ${props => props.theme.spacing.xl};
        text-align: left;
    `}
    color: ${props => props.theme.colors.white};
`;

UserManualSectionHeader.displayName = 'UserManualSectionHeader';

const UserManualSectionTitles = styled.a`
    display: flex;
    padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.xl};
    h2, h3 {
        color: ${props => props.theme.colors.rockBlue};
        font-size: ${props => props.theme.font.size.sm};
        &:hover {
            cursor: pointer;
        }
    }
    h3 {
        margin-left: ${props => props.theme.spacing.md};
    }
`;

UserManualSectionTitles.displayName = 'UserManualSectionTitles';

const StyledSelect = styled(Select)`
    width: 300px;
    margin-bottom: ${props => props.theme.spacing.xl};

    .Select-menu-outer {
        .Select-option {
            padding-left: ${props => props.theme.spacing.lg};
        }
        .Select-option.is-disabled {
            padding-left: ${props => props.theme.spacing.sm};
            color: ${props => props.theme.colors.darkGray};
            font-style: italic;
        }
    }
`;

StyledSelect.displayName = 'StyledSelect';

const UserManualSectionContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;
UserManualSectionContainer.displayName = 'UserManualSectionContainer';

export class UserManualSectionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSection: this.getQueryString()
        };
    }

    getSectionContentDesktop = sectionContent =>
        <UserManualSectionContainer>
            {sectionContent.map((singleSectionContent, index) => {
                switch (singleSectionContent.slice_type) {
                case SLICE_TYPE.SECTION_SUBJECT: {
                    const subject = getSectionSubjectText(singleSectionContent);
                    return (
                        <UserManualSectionTitles
                            onClick={() => this.setSelectedSection(subject)}
                            key={index}>
                            {RichText.render(singleSectionContent.primary.contentSubject)}
                        </UserManualSectionTitles>
                    );
                }
                case SLICE_TYPE.CONTENT_SECTION: {
                    const title = getSectionTitleText(singleSectionContent);
                    return (
                        <UserManualSectionTitles
                            onClick={() => this.setSelectedSection(title)}
                            key={index}>
                            {RichText.render(singleSectionContent.primary.contentTitle)}
                        </UserManualSectionTitles>
                    );
                }
                default:
                    return null;
                }
            })}
        </UserManualSectionContainer>;

    getSkeleton = () =>
        <UserManualSectionContainer>
            <SkeletonText style={{ width: '80%', marginBottom: '1em' }}/>
            <SkeletonText style={{ width: '80%', marginBottom: '1em' }}/>
            <SkeletonText style={{ width: '80%', marginBottom: '1em' }}/>
            <SkeletonText style={{ width: '80%', marginBottom: '1em' }}/>
        </UserManualSectionContainer>;

    setSelectedSection = selectedSection =>
        this.setState({ selectedSection }, () => scrollTo(selectedSection));

    getQueryString = () => {
        const values = queryString.parse(this.props.history.location.search);
        if (values && values.title) {
            return values.title;
        }
        return '';
    };

    render() {
        const { t, history, data, scroll: { scrollTop, scrollingUp }, loading } = this.props;
        const top = scrollingUp ? scrollTop > 64 + 20 : scrollTop > 20;

        return (
            <Fragment>
                <StyledUserManualSectionListDesktop>
                    <BackLink history={history} text={t('Go Back')} />
                    <Separator />
                    <UserManualSectionHeader>{t('table of contents')}</UserManualSectionHeader>
                    { loading && this.getSkeleton() }
                    { !loading && data && data.body && this.getSectionContentDesktop(data.body) }
                </StyledUserManualSectionListDesktop>
                <StyledUserManualSectionListMobile top={ top }>
                    <UserManualSectionHeader>{t('table of contents')}</UserManualSectionHeader>
                    <StyledSelect
                        name='select-dropdown'
                        value={this.state.selectedSection}
                        onChange={e => this.setSelectedSection(e)}
                        options={ data && data.body && getSectionOptions(data) }
                        clearable={false}
                        searchable={false}
                        simpleValue
                        disabled={ loading }
                    />
                </StyledUserManualSectionListMobile>
            </Fragment>
        );
    }
}

UserManualSectionList.propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    scroll: PropTypes.shape({
        scrollTop: PropTypes.number.isRequired,
        scrollingUp: PropTypes.bool.isRequired
    }).isRequired,
    loading: PropTypes.bool
};

export default translations(UserManualSectionList);
