import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Infotip from '../Infotip/Infotip';
import { CircleButton } from 'components/CircleButton/CircleButton';
import translations from 'decorators/Translations/translations';

const Indicator = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid ${props => props.theme.colors.midnight};
    background: ${props => props.closed ? props.theme.colors.darkGray : props.theme.colors.emerald};
    box-shadow: 0 0 0 2px ${props => props.closed ? props.theme.colors.darkGray : props.theme.colors.emerald};
    position: absolute;
    top: 25%;
    left: 55%;
`;

const Text = styled.p`
    white-space: pre-line;
    padding: 0.5em;
    width: 200px;
    text-align: left;
    font-size: ${props => props.theme.font.size.xs};
`;

class Giosg extends Component {
    componentDidMount() {
        const { profileGiosgId, giosgId } = this.props;

        // Skip if no Giosg id or if it's not valid
        if (!giosgId && (!profileGiosgId || isNaN(profileGiosgId))) {
            return;
        }

        const addScript = document.createElement('script');
        const scriptText = document.createTextNode(`(function(w, t, f) {
          var s='script',o='_giosg',h='https://service.giosg.com',e,n;e=t.createElement(s);e.async=1;e.src=h+'/live/';
          w[o]=w[o]||function(){(w[o]._e=w[o]._e||[]).push(arguments)};w[o]._c=f;w[o]._h=h;n=t.getElementsByTagName(s)[0];n.parentNode.insertBefore(e,n);
        })(window,document,${giosgId || profileGiosgId});`);

        addScript.appendChild(scriptText);
        document.body.appendChild(addScript);
    }

    render() {
        const { t, textOverride } = this.props;
        const text = <Text>{ textOverride || t('Chat is closed') }</Text>;

        return <div>
            <div className="giosg-button--online" style={{ display: 'none' }}>
                <CircleButton icon="fa-comment" offsetRight>
                    <Indicator />
                </CircleButton>
            </div>
            <div className="giosg-button--offline" style={{ display: 'none' }}>
                <CircleButton icon="fa-comment" offsetRight>
                    <Infotip text={ text } absolute giosg/>
                    <Indicator closed />
                </CircleButton>
            </div>
        </div>;
    }
}

const mapStateToProps = state => ({
    profileGiosgId: state.profile.profile.giosgId
});

const connector = connect(mapStateToProps);
export default connector(translations(Giosg));
