import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import Pager from 'components/Pager/Pager';
import Button from 'components/Button/Button';

const Container = styled.div`
    position: relative;
`;

const PaginationContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: ${props => props.theme.spacing.md} 0;

    & > button:focus {
        cursor: progress;
    }

    ${props => props.theme.media.landscape`
        flex-direction: row;
        margin: ${props => props.theme.spacing.lg} 0;
    `}
`;

const CountContainer = styled.div`
    position: absolute;
    top: ${props => props.theme.spacing.lg};
    transform: translateY(-50%);
    text-align: center;
    width: 100%;

    ${props => props.theme.media.landscape`
        top: 50%;
        left: ${props => props.theme.spacing.md};
        width: auto;
    `}
`;

const PagerContainer = styled.div`
    order: -1;

    ${props => props.theme.media.landscape`
        order: 0;
    `}
`;

const Count = styled.div`
    font-size: ${props => props.theme.fontSize.xs};
    color: ${props => props.theme.colors.rockBlue};
    margin: ${props => props.mobileMargin && `${props.theme.spacing.md} 0 ${props.theme.spacing.lg}`};

    ${props => props.theme.media.landscape`
        margin: 0;
    `}
`;

const Number = styled.span`
    color: ${props => props.theme.colors.midnight};
    font-weight: ${props => props.theme.fontWeight.bold};
`;

const StyledFilterDropdown = styled(FilterDropdown)`
    font-size: ${props => props.theme.fontSize.xs};
    font-weight: ${props => props.theme.fontWeight.bold};
    margin-bottom: ${props => props.theme.spacing.md};
    padding-top: ${props => props.theme.spacing.xxl};

    ${props => props.theme.media.landscape`
        justify-content: center;
        margin-bottom: 0;
        padding-top: 0;
        margin-top: ${props => props.theme.spacing.md};
    `}
`;

const getMinMaxRows = (page, visibleRows, total) => {
    let maxRow = page * visibleRows;
    const minRow = maxRow - visibleRows + 1;
    maxRow = maxRow > total ? total : maxRow;
    return {
        minRow,
        maxRow
    };
};

const SortableTableControls = ({
    totalRows,
    rowsPerFold,
    initialRows,
    visibleRows,
    setVisibleRows,
    loading,
    t,
    paginate,
    onPageChange,
    showAll,
    toggleShowAll,
    page
}) => {

    if (paginate) {
        const { minRow, maxRow } = getMinMaxRows(page, visibleRows || totalRows, totalRows);
        const currentRows = showAll ? totalRows : `${minRow}-${maxRow}`;

        return (
            <PaginationContainer>
                <Count mobileMargin>
                    { `${t('Showing')}: ` }<Number>{ currentRows }</Number> / <Number>{ totalRows }</Number>
                </Count>
                { !showAll && <PagerContainer>
                    <Pager
                        totalResults={totalRows}
                        limit={visibleRows || totalRows}
                        center
                        onChange={ onPageChange }
                    />
                </PagerContainer> }
                <Button clear onClick={ toggleShowAll }>{ showAll ? t('Show less') : t('Show all') }</Button>
            </PaginationContainer>
        );
    }

    const options = [{ text: initialRows.toString(), value: initialRows }];
    for (let i = rowsPerFold; i < totalRows; i = i + rowsPerFold ) {
        if (i > initialRows) {
            options.push({ text: i.toString(), value: i });
        }
    }
    return <Container>
        <CountContainer>
            <Count>
                { `${t('Showing')}: ` }<Number>{`1-${visibleRows || totalRows}`}</Number> / <Number>{ totalRows }</Number>
            </Count>
        </CountContainer>
        <StyledFilterDropdown
            options={ options }
            selectedFilter={ visibleRows }
            handleChange={ setVisibleRows }
            loading={ loading }
            showAllText={ t('All') }
            noFilterLast
            label={ t('Show') }
            labelPosition='left'
        />
    </Container>;
};

SortableTableControls.propTypes = {
    t: PropTypes.func.isRequired,
    totalRows: PropTypes.number.isRequired,
    rowsPerFold: PropTypes.number.isRequired,
    initialRows: PropTypes.number.isRequired,
    visibleRows: PropTypes.number,
    setVisibleRows: PropTypes.func,
    loading: PropTypes.bool,
    paginate: PropTypes.bool,
    onPageChange: PropTypes.func.isRequired,
    showAll: PropTypes.bool.isRequired,
    toggleShowAll: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired
};

export default SortableTableControls;
