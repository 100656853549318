import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Icon } from 'components/Icon/Icon';
import { Unread } from 'components/Announcement/BellButton';

export const Link = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    font-size: ${props => props.theme.fontSize.sm};
    color: ${props => props.theme.colors.blue};
    padding: 0 ${props => props.theme.spacing.xl};
    font-family: ${props => props.theme.font.family.sanchez};

    left: 0;
    transition-property: transform, opacity, left;
    transition-duration: 0.4s;
    transition-delay: 0.15s, 0.15s, 0s;
    transition-timing-function: ease;
    cursor: pointer;

    &:hover {
        left: 6px;
    }

    svg {
        margin-right: ${props => props.theme.spacing.xs};
    }

    ${props => props.theme.media.portrait`
        display: none;
    `}
`;

Link.displayName = 'Link';

const AnnouncementUnreadContainer = styled.div`
    position: absolute;
    top: 1em;
    left: 3.5em;
`;

export const AnnouncementLink = ({ onClick, t, unread }) =>
    <Link data-test-id="Announcements" onClick={ onClick } >
        <Icon name="bell" fill='currentColor' />
        <AnnouncementUnreadContainer>
            <Unread hidden={ unread < 1 }>{ unread }</Unread>
        </AnnouncementUnreadContainer>
        { t('Announcements') }
    </Link>;

AnnouncementLink.propTypes = {
    t: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    unread: PropTypes.number.isRequired
};
