import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';

import StandardPage from 'components/StandardPage/StandardPage';
import Header from 'containers/Application/Header/Header';
import ErrorPageContent from 'components/ErrorPage/ErrorPageContent';
import Navigation from 'containers/Application/Navigation/Navigation';

export const ErrorPage = ({ t, type, embed }) => {
    let icon, title, link, buttonText;
    const overviewLink = '/all/Overview';
    const overViewText = t('Go to frontpage');
    let instructions = t('Please try to refresh the page or contact local support for further assistance.');

    switch (type) {
    case 'functionalLocation':
        title = t(`Functional Location not found or you don't have the right permissions`);
        icon = 'empty-box';
        link = overviewLink;
        buttonText = overViewText;
        break;
    case 'serviceOrder':
        title = t(`Service order not found or you don't have the right permissions`);
        icon = 'service-orders';
        link = overviewLink;
        buttonText = overViewText;
        break;
    case 'equipment':
        title = t(`Equipment not found or you don't have the right permissions`);
        icon = 'Equipment';
        link = overviewLink;
        buttonText = overViewText;
        break;
    case 'partner':
        title = t(`Customer portfolio not found or you don't have the right permissions`);
        icon = 'empty-box';
        link = overviewLink;
        buttonText = overViewText;
        break;
    case 'selectPartner':
        title = t('No Customer selected');
        icon = 'empty-box';
        link = overviewLink;
        buttonText = overViewText;
        instructions = t('Please select a Customer portfolio from the selector on top navigation');
        break;
    case 'customView':
        title = t(`Custom view not found or you don't have the right permissions`);
        icon = 'dead-page';
        link = overviewLink;
        buttonText = overViewText;
        break;
    case 'authentication':
    case 'session':
    default:
        title = t('Something went wrong');
        icon = 'dead-page';
        link = '/';
        buttonText = t('Go to frontpage');
        break;
    }

    const Container = embed ? React.Fragment : StandardPage;
    const containerProps = embed ? undefined : { noMargin: true };
    return (
        <Container { ...containerProps }>
            <Helmet title={ t('Error') } />
            <Header useALinkTag showPartnerSelect t={ t } />
            <Navigation />
            <ErrorPageContent
                icon={ icon }
                title={ title }
                instructions={ instructions }
                buttonText={ buttonText }
                buttonLink={ link }
            />
        </Container>
    );
};

ErrorPage.defaultProps = {
    type: null,
};

ErrorPage.propTypes = {
    t: PropTypes.func.isRequired,
    type: PropTypes.string,
    embed: PropTypes.bool,
};

export default translations(ErrorPage);
