import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { round, isEqual, without } from 'lodash';
import { getStatusValue, legendColors } from 'components/Calendar/Legend/Legend';
import StatusMeter from 'components/StatusMeter/StatusMeter';
import { serviceStatusSortOrder } from 'utils/Data/resolveServiceOrderStatus.js';
import { OrderStatus } from 'constants/serviceCalendar';

const MeterBlock = styled.div`
    display: flex;
    margin-bottom: ${props => props.theme.spacing.md};

    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none;

    section {
        max-width: initial;
        &:not(:last-child) {
            border-right: 1px solid ${props => props.theme.colors.lightGray};
        }
    }

    ${props => props.theme.media.landscape`
        flex-wrap: wrap;
        section {
            min-width: 130px;
            max-width: 130px;
        }
    `}

    ${props => props.theme.media.desktop`
        section {
            min-width: 152px;
            max-width: 152px;
        }
    `}
`;


const CalendarStatusMeters = ({ t, loading, statusCounts, theme, showPlanned }) => {
    // Show "Planned" only in Planned Maintenance -view
    const statuses = showPlanned ? serviceStatusSortOrder : without(serviceStatusSortOrder, OrderStatus.PLANNED);

    return (
        <MeterBlock>
            { statuses.map(status => {
                const count = getStatusValue(serviceStatusSortOrder, statusCounts, status);
                const value = round(count / statusCounts.totalStatusCounts * 100);

                return <StatusMeter
                    key={ status }
                    value={ value }
                    title={ t(status) }
                    subtitle={ `${count} ${t('Total')}` }
                    color={ legendColors({ theme })[status.replace(' ', '_').toLowerCase()] }
                    loading={ loading }
                />;
            }) }
        </MeterBlock>
    );
};

CalendarStatusMeters.propTypes = {
    t: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    statusCounts: PropTypes.object.isRequired,
    showPlanned: PropTypes.bool,
    theme: PropTypes.object
};

CalendarStatusMeters.defaultProps = {
    loading: false,
    showPlanned: false
};

export default React.memo(withTheme(CalendarStatusMeters), isEqual);
