import { createReducerFromMapping } from 'redux/utils/index.js';
import { MasterData } from '@caverion/redux/api/actions';
import _ from 'lodash';

const initialState = {
    loading: [],
    serviceRequests: {},
    count: {},
    new: {},
    error: ''
};

export const SET_NEW_PROPERTY = 'CUSTOMER_PLATFORM/ServiceRequests/SET_NEW_PROPERTY';

export const setNewProperty = (property, value, override) => {
    return {
        type: SET_NEW_PROPERTY,
        property,
        value,
        override
    };
};

export const CREATE = 'CUSTOMER_PLATFORM/ServiceRequests/CREATE';
export const CREATE_SUCCESS = 'CUSTOMER_PLATFORM/ServiceRequests/CREATE_SUCCESS';
export const CREATE_FAIL = 'CUSTOMER_PLATFORM/ServiceRequests/CREATE_FAIL';

export const create = serviceRequest => {
    return async dispatch => {
        dispatch({ type: CREATE });
        try {
            const result = await dispatch(MasterData.createServiceRequest(JSON.stringify(serviceRequest)));
            return dispatch({
                key: JSON.stringify([CREATE, serviceRequest]),
                type: CREATE_SUCCESS,
                precondition: (store, action) => store.serviceRequests.loading.indexOf(action.key) < 0,
                result
            });
        } catch (error) {
            return dispatch({
                key: JSON.stringify([CREATE, serviceRequest]),
                type: CREATE_FAIL,
                error: 'Creating a new service request failed!'
            });
        }
    };
};

export default createReducerFromMapping({
    [CREATE]: (state, action) => ({
        ...state,
        loading: state.loading.concat([action.key])
    }),
    [CREATE_SUCCESS]: (state, action) => ({
        ...state,
        loading: state.loading.filter(x => x !== action.key),
        new: {}
    }),
    [CREATE_FAIL]: (state, action) => ({
        ...state,
        loading: state.loading.filter(x => x !== action.key),
        error: action.error
    }),

    [SET_NEW_PROPERTY]: (state, action) => ({
        ...state,
        new: action.override
            // Set whole tree undefined if a value is overriden and then set the value.
            ? _.setWith(
                _.setWith(
                    _.clone(state.new),
                    _.first(action.property),
                    undefined,
                    Object
                ),
                action.property,
                action.value,
                Object
            )

            // Just incrementally set the value.
            : _.setWith(_.clone(state.new), action.property, action.value, Object)
    })
}, initialState);
