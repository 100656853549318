import { createReducerFromMapping } from 'redux/utils/index.js';
import _ from 'lodash';
import { IoT } from '@caverion/redux/api/actions';

const initialState = {};

export const LOAD = 'CUSTOMER_PLATFORM/IoT_History/LOAD';
export const LOAD_SUCCESS = 'CUSTOMER_PLATFORM/IoT_History/LOAD_SUCCESS';
export const LOAD_FAIL = 'CUSTOMER_PLATFORM/IoT_History/LOAD_FAIL';

export const loadValues = (functionalLocation, sensorName, start, end, aggregation, equipment) => {
    const filter = {
        where: {
            functionalLocation: functionalLocation.functionalLocation,
            sensorName,
            aggregation,
            timestamp: {
                between: [
                    start.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                    end.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                ]
            }
        },

        // Highcharts requires that data is sorted by depended variable in ascending order.
        order: 'timestamp ASC'
    };

    if (equipment !== undefined) {
        filter.where.equipmentNumber = equipment.equipmentNumber;
    }

    return async dispatch => {
        dispatch({ type: LOAD });
        try {
            const result = await dispatch(IoT.histories(JSON.stringify(filter)));

            return dispatch({
                type: LOAD_SUCCESS,
                functionalLocation: functionalLocation.functionalLocation,
                sensorName,
                aggregation,
                start, end,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_FAIL,
                functionalLocation: functionalLocation.functionalLocation,
                sensorName,
                aggregation,
                start, end,
                error
            });
        }
    };
};

export const LOAD_LAST = 'CUSTOMER_PLATFORM/IoT_History/LOAD_LAST';
export const LOAD_LAST_SUCCESS = 'CUSTOMER_PLATFORM/IoT_History/LOAD_SUCCESS_LAST';
export const LOAD_LAST_FAIL = 'CUSTOMER_PLATFORM/IoT_History/LOAD_FAIL_LAST';

export const loadLast = (functionalLocation, sensorName, aggregation, equipment) => {
    const filter = {
        where: {
            functionalLocation: functionalLocation.functionalLocation,
            sensorName,
            aggregation,
        },

        // Load only the latest (last) value
        order: 'timestamp DESC',
        limit: '1'
    };

    if (equipment !== undefined) {
        filter.where.equipmentNumber = equipment.equipmentNumber;
    }

    return async dispatch => {
        dispatch({ type: LOAD_LAST });
        try {
            const result = await dispatch(IoT.histories(JSON.stringify(filter)));

            return dispatch({
                type: LOAD_LAST_SUCCESS,
                functionalLocation: functionalLocation.functionalLocation,
                sensorName,
                aggregation,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_LAST_FAIL,
                functionalLocation: functionalLocation.functionalLocation,
                sensorName,
                aggregation,
                error
            });
        }
    };
};

export default createReducerFromMapping({
    [LOAD]: (state, action) => ({
        ...state
    }),

    [LOAD_SUCCESS]: (state, action) => ({
        ...state,
        [action.aggregation]: _.setWith(_.clone(state[action.aggregation] || {}),
            [
                action.start.format('YYYY-MM-DD'),
                action.end.format('YYYY-MM-DD'),
                action.functionalLocation,
                action.sensorName
            ], {
                last: action.result[action.result.length - 1],
                all: action.result
            }, Object)
    }),

    [LOAD_FAIL]: (state, action) => ({
        ...state,
        [action.aggregation]: _.setWith(_.clone(state[action.aggregation] || {}),
            [
                action.start.format('YYYY-MM-DD'),
                action.end.format('YYYY-MM-DD'),
                action.functionalLocation,
                action.sensorName
            ], action.error, Object)
    }),

    [LOAD_LAST]: (state, action) => ({
        ...state
    }),

    [LOAD_LAST_SUCCESS]: (state, action) => ({
        ...state,
        [action.aggregation]: _.setWith(_.clone(state[action.aggregation] || {}),
            [
                'last',
                action.functionalLocation,
                action.sensorName
            ], action.result[0], Object)
    }),

    [LOAD_LAST_FAIL]: (state, action) => ({
        ...state,
        [action.aggregation]: _.setWith(_.clone(state[action.aggregation] || {}),
            [
                'last',
                action.functionalLocation,
                action.sensorName
            ], action.error, Object)
    }),
}, initialState);
