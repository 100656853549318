import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { rgba } from 'polished';

const Checkbox = styled.input`
    position: absolute;
    opacity: 0;

    &:checked + label:before {
        background-color: ${props => props.disabled ? props.theme.colors.lightGray : props.theme.colors.cerulean};
        border: ${props => props.disabled ? '1px solid ' + props.theme.colors.rockBlue : 'none'};
        box-shadow: none;
     }

    &:checked + label:after {
        content: '';
        position: absolute;
        display: block;
        left: 8px;
        top: 2px;
        width: 5px;
        height: 8px;
        border: solid ${props => props.disabled ? props.theme.colors.rockBlue : props.theme.colors.white};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
`;
Checkbox.displayName = 'Checkbox';

const CheckboxLabel = styled.label`
    cursor: ${props => !props.disabled && 'pointer'};
    position: relative;
    line-height: 1;
    opacity: ${props => props.disabled && 0.6};
    white-space: normal;
    word-break: break-all;
    color: ${props => props.theme.input.font.color.default};
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.font.size.xs};
    font-weight: ${props => props.useBold && props.theme.font.weight.bold};
    display: flex;
    width: 100%;

    &:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        min-width: 20px;
        max-width: 20px;
        height: 20px;
        background: white;
        border: 1px solid ${props => props.theme.colors.rockBlue};
        box-shadow: ${props => `inset 0 0 3px 3px ${rgba(props.theme.colors.black, 0.10)}`};
        border-radius: 2px;
        margin-top: -3px;
    }
`;
CheckboxLabel.displayName = 'CheckboxLabel';

export const InputCheckbox = props => {
    const value = props.value || _.get(props.model, props.property) || props.falseValue;
    const type = props.type || 'checkbox';
    return (
        <span className="admin-checkbox-container">
            <Checkbox className="admin-checkbox"
                disabled={ props.disabled }
                type={ type } id={ props.id }
                checked={ value !== props.falseValue }
                onChange={ () => props.onChange(props.property,
                    value === props.falseValue ? props.trueValue : props.falseValue, props.override)
                }
                data-test-id={ props['data-test-id'] }
            />
            <CheckboxLabel
                htmlFor={ props.id }
                className={ props.className }
                disabled={ props.disabled }
                useBold={ props.useBold && value !== props.falseValue }
                onClick={ e => e.stopPropagation() }
            >
                { props.label }
            </CheckboxLabel>
        </span>
    );
};

export default InputCheckbox;
