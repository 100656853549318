import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { transparentize } from 'polished';

import { InfotipContent, getInfotipPositioning } from 'components/Infotip/Infotip';
import { DialogOverlay } from 'components/Dialog/Dialog';
import Svg from 'components/Svg/Svg';

const Container = styled.div`
    position: relative;
    display: none;
    left: ${props => !props.standalone && '0.4em'};

    ${props => props.theme.media.landscape`
        display: inline-flex;
    `}
`;
Container.displayName = 'Container';

export const StyledOverlay = styled(DialogOverlay)`
    background-color: ${props => transparentize(0.6, props.theme.colors.black)};
    z-index: ${props => props.theme.zIndex('contextualHelp') - 1};
`;
StyledOverlay.displayName = 'StyledOverlay';

const ClickableWrapper = styled.div`
    cursor: pointer;
    padding: 0.2rem;
    display: flex;
`;
ClickableWrapper.displayName = 'ClickableWrapper';

const HelpIcon = styled(Svg)`
    fill: ${props => props.theme.colors.darkGray};
    font-size: ${props => props.theme.font.size.sm};
    cursor: pointer;
`;
HelpIcon.displayName = 'HelpIcon';

// defines position
const InfoWrapper = styled.div`
    position: absolute;
    z-index: ${props => props.theme.zIndex('contextualHelp')};

    @media (max-width: ${props => props.theme.breakpoints.landscape / 16}em) {
        position: fixed;
        left: 5px;
        top: 10%;
    }

    ${props => props.theme.media.landscape`
        ${props => getInfotipPositioning(props.position)}
    `}

    visibility: ${props => props.show ? 'visible' : 'hidden'};
    transition: visibility 0s 0.15s;
    transition-delay: ${props => props.show && '0.3s'};
`;
InfoWrapper.displayName = 'InfoWrapper';

// defines white area
const InfoContent = styled(InfotipContent)`
    cursor: default;
    font-size: 1rem;
    padding: 1em;
    &::before { display: none; } // hide triangle

    @media (max-width: ${props => props.theme.breakpoints.landscape / 16}em) {
        width: 90vw;
    }

    ${props => props.theme.media.portrait`
        min-width: 400px;
        max-width: 90vw;
    `}

    ${props => props.theme.media.landscape`
        max-width: 690px;
        &::before { display: block; } // show triangle
    `}
`;
InfoContent.displayName = 'InfoContent';

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25em;
    width: 100%;
    margin-left: 1px;
`;
Header.displayName = 'Header';

const Title = styled.h4`
    color: ${props => props.theme.colors.cerulean};
    text-transform: uppercase;
    font-size: ${props => props.theme.font.size.xxxs};
    font-family: ${props => props.theme.font.family.arial};
    font-weight: ${props => props.theme.font.weight.bold};
    letter-spacing: 1px;
`;
Title.displayName = 'Title';

const CloseIcon = styled(Svg)`
    fill: ${props => props.theme.colors.darkGray};
    font-size: ${props => props.theme.font.size.xxxs};
    cursor: pointer;
`;
CloseIcon.displayName = 'CloseIcon';

const Content = styled.p`
    font-size: ${props => props.theme.font.size.xxs};
    line-height: ${props => props.theme.font.lineHeight.lg};
    font-weight: ${props => props.theme.font.weight.normal};
    font-family: ${props => props.theme.font.family.arial};
    text-align: left;
    white-space: pre-wrap;
    color: ${props => props.theme.colors.black};
    a {
        word-break: break-word;
    }
`;
Content.displayName = 'Content';

export const CTXHELP_PREFIX = 'ctxhelp';

export class ContextualHelp extends PureComponent {
    state = {
        showHelp: false
    };

    toggleShowHelp = e => {
        if (!this.props.override) {
            e.stopPropagation();
            this.setState(oldState => ({ showHelp: !oldState.showHelp }));
        }
    };

    render() {
        const { t, text, title, position, override, standalone, link } = this.props;

        // Don't render if we don't have proper content for the user's language
        if (t(text) === text) {
            return null;
        }

        return (
            <Container standalone={ standalone }>
                <StyledOverlay onClick={ this.toggleShowHelp } active={ this.state.showHelp } />

                <ClickableWrapper onClick={ this.toggleShowHelp } standalone={ standalone }>
                    <HelpIcon name="help" />
                </ClickableWrapper>

                { (this.state.showHelp || override) &&
                    <InfoWrapper show={ this.state.showHelp || override } position={ position }>
                        <InfoContent
                            show={ this.state.showHelp || override }
                            position={ position }
                            isComponent={ true }>
                            <Header>
                                <Title>{ title }</Title>
                                <CloseIcon name="remove" onClick={ this.toggleShowHelp } />
                            </Header>
                            <Content>
                                { t(text) }
                                { link && <a href={ link } target="_blank" rel="noopener noreferrer"> { link }</a> }
                            </Content>
                        </InfoContent>
                    </InfoWrapper>
                }
            </Container>
        );
    }
}

ContextualHelp.propTypes = {
    t: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string,
    position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    override: PropTypes.bool,
    standalone: PropTypes.bool,
    link: PropTypes.string
};

ContextualHelp.defaultProps = {
    title: null,
    position: 'top',
    override: false,
    standalone: false,
    link: null
};

export default withTheme(ContextualHelp);
