import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const List = styled.ul`
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: 0;
`;

export default class FunctionalLocationList extends Component {
    render() {
        const { children } = this.props;
        if (children) {
            return (
                <List>
                    { children }
                </List>
            );
        }

        return null;
    }
}

FunctionalLocationList.propTypes = {
    children: PropTypes.node
};
