import React from 'react';
import styled from 'styled-components';

const StyledPermissionListSubheading = styled.div`
    font-family: ${props => props.theme.font.family.arial};
    font-weight: ${props => props.theme.font.weight.normal};
    font-size: ${props => props.theme.font.size.xs};
    cursor: default;

    ${props => props.theme.media.portrait`
        font-weight: ${props => props.theme.font.weight.bold};
    `}
`;

export const PermissionListSubheading = props =>
    <StyledPermissionListSubheading
        onClick={e => e.stopPropagation()}
        {...props}
    />;
