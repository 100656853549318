import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { Icon, ICON_SIZES } from 'components/Icon/Icon';

export const StyledLabel = styled.label`
    cursor: ${props => !props.disabled && 'pointer'};
    opacity: ${props => props.checked ? 1 : 0.8};
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.abbey};
    border: 1px solid;
    border-color: ${props => props.checked ? props.theme.colors.silver : 'transparent'};
    border-radius: 1px;
    font-size: ${props => props.theme.font.size.xs};
    line-height: ${props => props.theme.font.lineHeight.md};
    white-space: nowrap;
    margin-top: 0;
    padding: ${props => props.theme.spacing.xxs} ${props => props.theme.spacing.xs};

    &:hover {
        border-color: ${props => props.theme.colors.silver};
        color: ${props => props.theme.colors.black};
        opacity: 1;
    }
    svg {
        fill: ${props => props.disabled && props.theme.colors.lightGray || !props.color};
        margin: 0;
    }
`;
StyledLabel.displayName = 'StyledLabel';

const StyledLabelText = styled.p`
    padding-left: ${props => props.theme.spacing.xs};
    padding-right: ${props => props.theme.spacing.lg};

`;

StyledLabelText.displayName = 'StyledLabelText';

const HiddenInput = styled.input`
    display: none;
`;
HiddenInput.displayName = 'HiddenInput';

const FeedbackRadioButton = props =>
    <Fragment>
        <HiddenInput
            id={`${props.name}_${props.value}`}
            type="radio"
            name={props.name}
            value={props.value}
            checked={props.checked}
            onChange={e => props.onChange(props.name, props.value)}
            disabled={ props.disabled }
        />
        <StyledLabel
            htmlFor={`${props.name}_${props.value}`}
            disabled={ props.disabled }
            checked={props.checked}
        >
            <Icon
                name={props.icon}
                size={ICON_SIZES.MEDIUM}
                fill={props.color}
            />
            <StyledLabelText>{props.label}</StyledLabelText>
        </StyledLabel>
    </Fragment>;

FeedbackRadioButton.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    icon: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool
};

FeedbackRadioButton.defaultProps = {
    disabled: false
};

export default withTheme(FeedbackRadioButton);
