import React from 'react';
import styled from 'styled-components';
import Svg from 'components/Svg/Svg';

const StyledDescriptionColumn = styled.div`
    max-width: 800px;
    display: flex;
    align-items: center;

    ${props => props.theme.media.landscape`
        padding-left: ${props => props.theme.spacing.md};
    `}
`;

const Title = styled.h3`
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.fontSize.xs};
    font-weight: ${props => props.theme.fontWeight.bold};
`;

const Description = styled.div`
    display: inline;
    margin-right: ${props => props.theme.spacing.xxs};
    color: ${props => props.theme.colors.darkGray};
    font-size: ${props => props.theme.fontSize.xs};
    line-height: ${props => props.theme.lineHeight.text};
`;

const Icon = styled(Svg)`
    font-size: ${props => props.theme.fontSize.huge};
    fill: ${props => props.theme.colors.blue};
    min-width: ${props => props.theme.fontSize.huge};
    margin-right: ${props => props.theme.spacing.md};
`;

const DESC_MAX_LENGTH = 300;

const DescriptionColumn = props => {
    const { value, description } = props.data;
    const icon = props.icon && props.icon.icon;

    return (
        <StyledDescriptionColumn>
            { icon && <Icon name={ icon } /> }
            <div>
                { value && <Title>{ value }</Title> }
                { description && <Description>
                    { `${description.substring(0, DESC_MAX_LENGTH)}${description.length > DESC_MAX_LENGTH ? '...' : ''}` }
                </Description> }
            </div>
        </StyledDescriptionColumn>
    );
};

export default DescriptionColumn;
