import { findLast } from 'lodash';
import { getThumbnailImageUrl, variants } from 'utils/Data/images.js';

export const getBuildingImage = (functionalLocation, functionalLocationImages) => {
    let buildingImagePath = '';
    if (functionalLocationImages && functionalLocationImages[functionalLocation]) {
        const images = functionalLocationImages[functionalLocation];
        const buildingImage = findLast(images, image => image.type === 'building');
        if (buildingImage && buildingImage.path) {
            if (buildingImage.variants &&
                buildingImage.variants.length > 0 &&
                buildingImage.variants.indexOf(variants.THUMBNAIL) !== -1
            ) {
                buildingImagePath = getThumbnailImageUrl(buildingImage.path);
            }
            else {
                buildingImagePath = buildingImage.path;
            }
        }
    }
    return buildingImagePath;
};
