import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import memoizeOne from 'memoize-one';
import { connect } from 'react-redux';
import { isEmpty, some } from 'lodash';

import Loader from 'components/Loader/Loader';
import DialogModal from 'components/Dialog/DialogModal';
import DialogBody from 'components/Dialog/DialogBody';
import StatisticsHeader from './StatisticsHeader/StatisticsHeader';
import {
    STATISTICS_ORDER_REACTION_TIME_HOURS,
    STATISTICS_ORDER_DOWNTIME_HOURS,
    STATISTICS_ORDER_LEAD_TIME_HOURS,
    STATISTICS_ORDER_REACTION_TIME_PERCENTS,
    STATISTICS_ORDER_DOWNTIME_PERCENTS,
    STATISTICS_ORDER_LEAD_TIME_PERCENTS,
    STATISTICS_ORDER_PERFORMANCE_RATIO,
    STATISTICS_ORDER_VOLUMES_BY_DISCIPLINE,
    STATISTICS_PLANNED_PERFORMANCE_RATIO,
    STATISTICS_PLANNED_VOLUMES_BY_BUILDING,
    STATISTICS_PLANNED_VOLUMES_BY_PROVIDER,
    STATISTICS_PLANNED_VOLUMES_BY_DISCIPLINE,
    STATISTICS_ORDER_VOLUMES_BY_BUILDING,
    STATISTICS_ORDER_VOLUMES_BY_EQUIPMENT
} from 'constants/serviceCalendar';
import StatisticsFooter from './StatisticsFooter/StatisticsFooter';
import { groupServiceOrdersByType, CHART_HEIGHT } from './utils';
import { StatisticsOptions } from './types';
import {
    OrderBreakdown,
    OrderBreakdownPercent,
    OrderPerformanceRatio,
    OrderVolumes,
    PlannedPerformanceRatio,
    PlannedVolumes,
} from './charts';
import { loadPartnerMeta } from 'redux/modules';
import translations from 'decorators/Translations/translations';
import { getResponseSLAs } from 'utils/Data/serviceOrders';
import { hideResponseTimes } from 'containers/Application/ServiceCalendar/utils';

const componentMapping = {
    [STATISTICS_ORDER_REACTION_TIME_HOURS]: OrderBreakdown,
    [STATISTICS_ORDER_DOWNTIME_HOURS]: OrderBreakdown,
    [STATISTICS_ORDER_LEAD_TIME_HOURS]: OrderBreakdown,
    [STATISTICS_ORDER_REACTION_TIME_PERCENTS]: OrderBreakdownPercent,
    [STATISTICS_ORDER_DOWNTIME_PERCENTS]: OrderBreakdownPercent,
    [STATISTICS_ORDER_LEAD_TIME_PERCENTS]: OrderBreakdownPercent,
    [STATISTICS_ORDER_PERFORMANCE_RATIO]: OrderPerformanceRatio,
    [STATISTICS_ORDER_VOLUMES_BY_DISCIPLINE]: OrderVolumes,
    [STATISTICS_ORDER_VOLUMES_BY_BUILDING]: OrderVolumes,
    [STATISTICS_ORDER_VOLUMES_BY_EQUIPMENT]: OrderVolumes,
    [STATISTICS_PLANNED_PERFORMANCE_RATIO]: PlannedPerformanceRatio,
    [STATISTICS_PLANNED_VOLUMES_BY_BUILDING]: PlannedVolumes,
    [STATISTICS_PLANNED_VOLUMES_BY_PROVIDER]: PlannedVolumes,
    [STATISTICS_PLANNED_VOLUMES_BY_DISCIPLINE]: PlannedVolumes,
};

const groupServiceOrdersMemoized = memoizeOne(groupServiceOrdersByType);

const Content = styled.div`
    ${props => props.theme.media.landscape`
        width: 90vw;
        max-width: ${props => props.theme.grid.maxWidth};
    `}
`;

const LoadingPlaceholder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${CHART_HEIGHT}px;
`;

const StyledDialogBody = styled(DialogBody)`
    ${props => props.theme.media.landscape`
        width: auto;
    `}
`;

const ServiceOrderStatistics = ({
    functionalLocations,
    equipmentTexts,
    hide,
    loading,
    options,
    serviceOrders,
    setStatisticsType,
    setYear,
    t,
    year,
    partnerNumber,
    partnerMeta,
    loadPartnerMeta,
    loadingPartnerMeta,
    hideBuildingStats
}) => {
    useEffect(() => {
        if (options.visible) {
            loadPartnerMeta(partnerNumber);
        }
    }, [options.visible, partnerNumber, loadPartnerMeta]);

    if (!options.visible) {
        return null;
    }

    const groupedOrders = groupServiceOrdersMemoized(serviceOrders);
    const StatisticsBody = componentMapping[options.statisticsType];
    const breakdownSLAs = getResponseSLAs(partnerMeta, partnerNumber) || {};

    const hideEquipmentStats = !some(serviceOrders, so =>
        !isEmpty(so.equipmentNumber) && so.maintenanceActivityType !== 'Y02');
    const hideBreakdownStats = hideResponseTimes((partnerMeta[partnerNumber] || {}).meta);
    const hideBreakdownPercentStats = isEmpty(breakdownSLAs.reactionTime) && isEmpty(breakdownSLAs.downtime)
        && isEmpty(breakdownSLAs.leadTime);

    return (
        <DialogModal isActive={ options.visible } onOverlayClick={ hide } t={ t } bodyComponent={ StyledDialogBody }>
            <Content>
                <StatisticsHeader
                    t={ t }
                    options={ options }
                    setStatisticsType={ setStatisticsType }
                    hideBuildingStats={ hideBuildingStats }
                    hideEquipmentStats={ hideEquipmentStats }
                    hideBreakdownStats={ hideBreakdownStats }
                    hideBreakdownPercentStats={ hideBreakdownPercentStats }
                />
                {loading || loadingPartnerMeta ?
                    <LoadingPlaceholder>
                        <Loader size="MEDIUM" />
                    </LoadingPlaceholder> :
                    <StatisticsBody
                        t={ t }
                        serviceOrders={ groupedOrders }
                        statisticsType={ options.statisticsType }
                        functionalLocations={ functionalLocations }
                        equipmentTexts={ equipmentTexts }
                        year={ year }
                        breakdownSLAs={ breakdownSLAs }
                        partnerMeta={ partnerMeta[partnerNumber] }
                        yearControls={
                            <StatisticsFooter
                                t={ t }
                                year={ year }
                                setYear={ setYear }
                            />
                        }
                    />
                }
            </Content>
        </DialogModal>
    );
};

ServiceOrderStatistics.propTypes = {
    functionalLocations: PropTypes.objectOf(PropTypes.object).isRequired,
    equipmentTexts: PropTypes.object.isRequired,
    hide: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingFLs: PropTypes.bool.isRequired,
    options: StatisticsOptions.isRequired,
    serviceOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
    setStatisticsType: PropTypes.func.isRequired,
    setYear: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    year: PropTypes.number.isRequired,
    partnerNumber: PropTypes.string.isRequired,
    partnerMeta: PropTypes.object,
    loadPartnerMeta: PropTypes.func.isRequired,
    loadingPartnerMeta: PropTypes.bool,
    hideBuildingStats: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => ({
    options: state.common.statisticsView,
    partnerMeta: state.partnerMeta.meta,
    loadingPartnerMeta: ownProps.partnerNumber in state.partnerMeta.meta
        ? state.partnerMeta.meta[ownProps.partnerNumber] && state.partnerMeta.meta[ownProps.partnerNumber].loading
        : undefined,
});

const mapDispatchToProps = {
    loadPartnerMeta,
};

export default connect(mapStateToProps, mapDispatchToProps)(translations(ServiceOrderStatistics));
