import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import { rgba, transparentize } from 'polished';
import queryString from 'query-string';
import Infotip from 'components/Infotip/Infotip';
import Svg from 'components/Svg/Svg';
import { flIcons } from 'utils/Data/functionalLocations';
import QueryLink from 'components/QueryLink/QueryLink';
import SimpleDropdown from 'components/SimpleDropdown/SimpleDropdown';
import Loader from 'components/Loader/Loader';
import { InputSearch } from 'components';
import { getPartnerNumbers } from 'utils/profile';
import { getEnabledTabs, getEnabledCustomViews } from 'utils/Data/features';

import { loadCustomers, setActivePartner } from 'redux/modules';
import { loadEquipments, loadSubEquipments } from 'redux/modules/customer/equipments';
import { isBusinessUnit } from 'utils/Data/functionalLocations';
import { loadPermissions } from 'redux/modules/profile/profile';
import { isValidPartner } from 'utils/Data/partners';

const Loading = styled.h4`
    color: ${props => props.theme.colors.lightGray};
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
`;

const LinkContainer = styled.div`
    font-size: ${props => props.theme.fontSize.xs};
    padding: ${props => props.theme.spacing.md} 0 ${props => props.theme.spacing.md}
        ${props => props.theme.spacing.md};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: ${props => (props.withName ? 'auto' : '50px')};
    cursor: pointer;

    &:last-child {
        cursor: ${props => (props.withLink ? 'pointer' : 'default')};
    }

    ${props => props.theme.media.portrait`
        border-left: 1px solid ${props => rgba(props.theme.colors.white, 0.2)};
        padding: ${props => props.theme.spacing.md};
        width: ${props => (props.withName ? 'auto' : '68px')};
    `}
`;

const ContextIcon = styled(Svg)`
    fill: ${props => rgba(props.theme.colors.white, 0.7)};
    font-size: 28px;
    min-width: 28px;
    margin: 0 2px;

    ${props => props.theme.media.portrait`
        fill: ${props => props.theme.colors.white};
    `}
`;

const Links = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    z-index: 10;
    pointer-events: auto;

    > *:last-child {
        height: 100%;
        overflow: hidden;
    }

    > *:last-child ${LinkContainer} {
        position: relative;
        height: 100%;

        ${props => props.theme.media.portrait`
            border-right: 1px solid ${props => rgba(props.theme.colors.white, 0.2)};
            background-color: ${props => rgba(props.theme.colors.white, 0.15)};
        `}
    }

    > *:last-child ${ContextIcon} {
        fill: ${props => props.theme.colors.white};
    }
`;

const SelectorText = styled.div`
    padding-left: ${props => props.theme.spacing.sm};
    font-size: ${props => props.theme.fontSize.xxxs};
    font-weight: ${props => props.theme.fontWeight.bold};
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${props => props.theme.colors.white};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
`;

SelectorText.displayName = 'SelectorText';

const Selector = styled.div`
    position: absolute;
    top: 64px;
`;
Selector.displayName = 'Selector';

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.colors.geyser};
`;

const SearchInput = styled(InputSearch)`
    font-size: ${props => props.theme.fontSize.xs};
    width: 100%;
    height: 100%;

    input,
    input:hover,
    input:focus {
        box-shadow: none;
        border: none;
    }
`;

const SelectorLink = styled(QueryLink)`
    font-size: ${props => props.theme.fontSize.xs};
    color: ${props => props.theme.colors.midnight};
    padding: ${props => props.theme.spacing.md};
    cursor: pointer;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        background-color: ${props => transparentize(0.9, props.theme.colors.cerulean)};
    }
`;

const DropdownContainer = styled.div`
    height: 18em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.rockBlue};
`;

const EmptyIcon = styled(Svg)`
    font-size: 3rem;
    margin-bottom: ${props => props.theme.spacing.md};
    fill: ${props => props.theme.colors.rockBlue};
`;

const ArrowIcon = styled(Svg)`
    font-size: ${props => props.theme.fontSize.xs};
    margin-left: ${props => props.theme.spacing.xs};
    fill: ${props => props.theme.colors.white};
`;

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
`;

const renderIcon = type => {
    const icon = flIcons[type];

    if (!icon) {
        return null;
    }

    return <ContextIcon name={icon} />;
};

const renderLink = (loadingContext, context, contextLink, type, index) => {
    if (contextLink) {
        return (
            <Infotip text={context || ''} position="bottom" type="white" key={`${type}-${index}`}>
                <LinkContainer>
                    <StyledLink to={contextLink}>{type && renderIcon(type)}</StyledLink>
                </LinkContainer>
            </Infotip>
        );
    }
    return (
        <LinkContainer key={`${type}-${index}`}>
            <Loading>{loadingContext ? '...' : context || ''}</Loading>
        </LinkContainer>
    );
};

export const Context = props => {
    const {
        profile,
        topLevelPermissions,
        customers,
        t,
        location,
        location: { pathname },
        match: {
            params: { partnerNumber }
        },
        contextLinks,
        showPartnerSelect,
        loadingContext,
        type,
        text,
        setActivePartner,
        functionalLocation,
        equipment,
        features,
        functionalLocations,
        equipments,
        subEquipments,
        loadPermissions,
        loadEquipment,
        loadSubEquipment,
        loadCustomers
    } = props;

    const [selectorOpen, setSelectorOpen] = useState(false);
    const [loadingSiblings, setLoadingSiblings] = useState(true);
    const [searchResults, setSearchResults] = useState(null);

    const isMounted = useRef(true);
    const node = useRef(null);
    const selector = useRef(null);
    const parent =
        functionalLocation &&
        _.last(_.without(functionalLocation.path, functionalLocation.functionalLocation));

    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false);

        if (showPartnerSelect) {
            loadPartners();
        }

        // Set selected partner based on url parameter to keep it in sync when using direct urls
        isValidPartner(partnerNumber) && setActivePartner(partnerNumber);

        return () => {
            document.removeEventListener('mousedown', handleClick, false);
            isMounted.current = false;
        };
    }, []);

    useEffect(
        () => {
            if (!showPartnerSelect) {
                load();
            }
        },
        [functionalLocations[parent]]
    );

    useEffect(
        () => {
            if (selectorOpen && selector.current) {
                selector.current.focus();
            }
        },
        [selectorOpen, loadingContext, loadingSiblings]
    );

    const loadPartners = async () => {
        const partners = getPartnerNumbers(profile);
        if (partners.length > 0) {
            setLoadingSiblings(true);
            await loadCustomers(partners);

            // don't change state if unmount has happened
            if (isMounted.current) {
                setLoadingSiblings(false);
            }
        }
    };

    const load = async () => {
        // Load siblings for FL/Equipment
        if (functionalLocation) {
            setLoadingSiblings(true);
            const parentFl = functionalLocations[parent];

            if (!equipment) {
                await loadPermissions(parentFl);
            } else if (equipment.equipmentSuperordinate) {
                await loadSubEquipment(functionalLocation, equipment.equipmentSuperordinate);
            } else {
                await loadEquipment(functionalLocation);
            }

            // don't change state if unmount has happened
            if (isMounted.current) {
                setLoadingSiblings(false);
            }
        }
    };

    const handleClick = e => {
        if (node.current.contains(e.target)) {
            return;
        }
        setSelectorOpen(false);
    };

    const renderSelectorText = (text, showSelector) => {
        const arrowIconName = selectorOpen ? 'fa-caret-up' : 'fa-caret-down';

        return (
            <Fragment>
                <SelectorText>{text}</SelectorText>
                {showSelector && <ArrowIcon name={arrowIconName} />}
            </Fragment>
        );
    };

    const renderSelector = (text, options, onClick) => {
        if (!selectorOpen) {
            return null;
        }
        const optionsToShow = searchResults !== null ? searchResults : options;

        return (
            <Selector data-test-id="Selector">
                <SimpleDropdown left width="20em">
                    {!loadingSiblings && !_.isEmpty(options) && (
                        <div>
                            {options.length > 7 && (
                                <SearchContainer>
                                    <SearchInput
                                        onChange={value => {
                                            const searchResults = _.filter(
                                                options,
                                                option =>
                                                    option.search &&
                                                    option.search.indexOf(value.toLowerCase()) !==
                                                        -1
                                            );
                                            setSearchResults(searchResults);
                                        }}
                                        placeholder={t('Filter by Name or Address')}
                                        onClear={() => setSearchResults(null)}
                                        inputRef={selector}
                                    />
                                </SearchContainer>
                            )}
                            {optionsToShow.map((option, index) => (
                                <SelectorLink
                                    to={option.to}
                                    key={index}
                                    data-test-id="SelectorLink"
                                    onClick={onClick ? () => onClick(option.value) : undefined}>
                                    {option.label}
                                </SelectorLink>
                            ))}
                        </div>
                    )}
                    {loadingSiblings && (
                        <DropdownContainer>
                            <Loader color="GRAY" size="MEDIUM" />
                        </DropdownContainer>
                    )}
                    {!loadingSiblings && _.isEmpty(options) && (
                        <DropdownContainer>
                            <EmptyIcon name="empty-box" />
                            <p>{t('No siblings available')}</p>
                        </DropdownContainer>
                    )}
                </SimpleDropdown>
            </Selector>
        );
    };

    const renderContext = contextLinks => {
        if (showPartnerSelect) {
            const options = getPartnerOptions();
            const selected = _.find(options, { value: partnerNumber }) || { label: t('Select') };
            const onClick = value => {
                setActivePartner(value);
                setSelectorOpen(false);
                setSearchResults(null);
            };
            const selectorClick = () => setSelectorOpen(!selectorOpen);

            return (
                <Links>
                    <div>
                        <LinkContainer
                            withLink
                            withName
                            onClick={selectorClick}
                            data-test-id="PartnerSelector">
                            {renderIcon('BE')}
                            {renderSelectorText(selected.label, true)}
                        </LinkContainer>
                        {renderSelector(selected.label, options, onClick)}
                    </div>
                </Links>
            );
        }

        if (contextLinks && contextLinks.length > 0) {
            const firstLevel = contextLinks.length === 1;
            const showSelector = isValidPartner(partnerNumber) && !!functionalLocation;
            const siblings =
                showSelector &&
                selectorOpen &&
                (equipment ? getEquipmentOptions() : getFLOptions(firstLevel));

            const currentType = type || (functionalLocation && functionalLocation.type);
            const selectorText = text || (functionalLocation && functionalLocation.description);
            const selectorClick = showSelector ? () => setSelectorOpen(!selectorOpen) : undefined;
            const tail = currentType && (
                <div>
                    <LinkContainer
                        key={currentType}
                        withName
                        withLink={showSelector}
                        onClick={selectorClick}>
                        {renderIcon(currentType)}
                        {selectorText && renderSelectorText(selectorText, showSelector)}
                    </LinkContainer>
                    {selectorText && renderSelector(selectorText, siblings)}
                </div>
            );

            if (loadingContext) {
                return (
                    <Links>
                        {renderLink(
                            loadingContext,
                            contextLinks[0].context,
                            contextLinks[0].contextLink,
                            contextLinks[0].type,
                            0
                        )}
                        <LinkContainer key="loading">
                            <Loading>...</Loading>
                        </LinkContainer>
                        {tail}
                    </Links>
                );
            }
            return (
                <Links>
                    {contextLinks.map((link, index) =>
                        renderLink(loadingContext, link.context, link.contextLink, link.type, index)
                    )}
                    {tail}
                </Links>
            );
        }

        return null;
    };

    const getPartnerOptions = () => {
        const partners = getPartnerNumbers(profile);
        const pathArray = pathname.split('/').splice(2);
        let path = '';
        // Custom view pages are partner specific and we don't want to include them when changin context
        if (!_.includes(pathArray, 'Report')) {
            path = pathArray.join('/');
        }

        // Map all partner numbers to customers and remove non-mapped partners.
        const filteredCustomers = partners
            .map(partner => customers[partner])
            .filter(partner => !!partner);
        const options = _.sortBy(
            filteredCustomers.map(customer => ({
                to: { pathname: `/${customer.partnerNumber}/${path}` },
                label: customer.name,
                value: customer.partnerNumber,
                search: `${customer.name.toLowerCase()} ${customer.address.toLowerCase()}`
            })),
            'label'
        );
        options.push({ to: { pathname: `/all/${path}` }, label: t('Show all'), value: 'all' });
        return options;
    };

    const getFLOptions = firstLevel => {
        let options;
        let siblings;

        // If this is the first level, there is no parent. Use BUs for partner permissions and profile FLs otherwise.
        if (firstLevel) {
            const partnerPermissions = profile.partnerPermissions || [];
            siblings = _.values(functionalLocations)
                .filter(functionalLocation => {
                    // Show building level FLs if user has partner permissions.
                    if (
                        functionalLocation.partnerNumberWithParents.some(partner =>
                            _.includes(partnerPermissions, partner)
                        )
                    ) {
                        return functionalLocation.type === 'BU';
                    }

                    // Otherwise show FLs the user has direct permissions to
                    return _.includes(topLevelPermissions, functionalLocation.functionalLocation);
                })
                // Filter out functional locations that are not related to selected partner (if selected)
                .filter(
                    x =>
                        !isValidPartner(partnerNumber) ||
                        _.includes(
                            functionalLocations[x.functionalLocation].partnerNumberWithParents,
                            partnerNumber
                        )
                );
        } else {
            const pathWithoutFL = _.without(
                functionalLocation.path,
                functionalLocation.functionalLocation
            );
            const parent = _.last(pathWithoutFL);
            siblings = _.without(
                Object.keys(functionalLocations),
                functionalLocation.functionalLocation
            )
                .map(fl => functionalLocations[fl])
                .filter(
                    fl =>
                        fl.functionalLocation !== parent &&
                        _.includes(fl.partnerNumberWithParents, partnerNumber) &&
                        ((isBusinessUnit(functionalLocation) && isBusinessUnit(fl)) ||
                            _.includes(_.takeRight(fl.path, 2), parent))
                );
        }

        if (siblings && siblings.length > 0) {
            options = siblings.map(sibling => {
                let tab = queryString.parse(location.search).tab;
                const enabledTabs = getEnabledTabs(
                    features,
                    sibling.type,
                    getEnabledCustomViews(profile.customViews, {
                        functionalLocation: sibling.functionalLocation
                    })
                );
                // Check if wanted tab is enabled for user and if not, just select first enabled tab
                tab = _.includes(enabledTabs, tab) ? tab : _.head(enabledTabs);

                const id = sibling.functionalLocation;
                const pathname = !_.isNil(partnerNumber)
                    ? `/${partnerNumber}/FunctionalLocation/${id}`
                    : `/FunctionalLocation/${id}`;
                const search = `${sibling.description ? sibling.description.toLowerCase() : ''} ${
                    sibling.address ? sibling.address.toLowerCase() : ''
                }`;
                return {
                    label: sibling.description,
                    to: { pathname, query: { tab } },
                    search
                };
            });
        }

        return _.sortBy(options, 'label');
    };

    const getEquipmentOptions = () => {
        let options;
        const flEquipmentList = equipments[functionalLocation.functionalLocation];
        const subEquipmentList = subEquipments[equipment.equipmentSuperordinate];
        const equipmentList = equipment.equipmentSuperordinate ? subEquipmentList : flEquipmentList;
        const siblings =
            equipmentList &&
            equipmentList.filter(
                flEquipment =>
                    flEquipment.equipmentNumber !== equipment.equipmentNumber &&
                    flEquipment.functionalLocation === functionalLocation.functionalLocation
            );

        if (siblings && siblings.length > 0) {
            options = siblings.map(sibling => {
                const tab = queryString.parse(location.search).tab;
                const partnerPart = !partnerNumber ? '' : `/${partnerNumber}`;
                const parentPart = equipment.equipmentSuperordinate
                    ? `/${equipment.equipmentSuperordinate}`
                    : '';
                const fl = functionalLocation.functionalLocation;
                const id = sibling.equipmentNumber;
                const pathname = `${partnerPart}/Equipment/${fl}${parentPart}/${id}`;
                return {
                    label: sibling.text,
                    to: { pathname, query: { tab } },
                    search: sibling.text ? sibling.text.toLowerCase() : ''
                };
            });
        }
        return _.sortBy(options, 'label');
    };

    const links = showPartnerSelect
        ? contextLinks
        : [
              {
                  context: t('Overview'),
                  contextLink: '/',
                  type: 'OVERVIEW'
              }
          ].concat(contextLinks);

    return <span ref={node}>{renderContext(links)}</span>;
};

Context.displayName = 'Context';

Context.propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            partnerNumber: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired
    }).isRequired,
    text: PropTypes.string,
    type: PropTypes.string,
    contextLinks: PropTypes.array,
    loadingContext: PropTypes.bool,
    functionalLocation: PropTypes.object,
    equipment: PropTypes.object,
    showPartnerSelect: PropTypes.bool
};

Context.defaultProps = {
    contextLinks: [],
    loadingContext: false,
    showPartnerSelect: false
};

const mapStateToProps = state => ({
    profile: state.profile.profile,
    topLevelPermissions: state.profile.topLevelPermissions,
    customers: state.customer.customers,
    functionalLocations: state.functionalLocations.functionalLocations,
    equipments: state.equipments.equipments,
    subEquipments: state.equipments.subEquipments,
    features: state.profile.profile.syntheticFeatures
});
const mapDispatchToProps = dispatch => ({
    loadCustomers: ids => dispatch(loadCustomers(ids)),
    setActivePartner: partnerNumber => dispatch(setActivePartner(partnerNumber)),
    loadPermissions: fl => dispatch(loadPermissions(fl)),
    loadEquipment: fl => dispatch(loadEquipments(fl.functionalLocation, 0, null)),
    loadSubEquipment: (fl, parent) => dispatch(loadSubEquipments(fl.functionalLocation, parent))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Context);
