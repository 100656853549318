import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import queryString from 'query-string';

import CalendarStatus from './CalendarStatus/CalendarStatus';
import CalendarDay from './CalendarDay/CalendarDay';
import { OrderStatus, ViewMode } from 'constants/serviceCalendar';

const CalendarWeekNumber = styled.div`
    padding: 0.375em 0;
    width: 2em;
    font-size: ${props => props.theme.font.size.xxs};
    text-align: left;
    color: ${props => props.theme.colors.rockBlue};
    font-family: ${props => props.theme.fontFamily.heading};
`;

const FormattedDay = styled.div`
    width: 2em;
    z-index: 1;
    text-align: center;
    color: ${props => props.isOpen ? props.theme.colors.white : props.theme.colors.black};
    font-weight: ${props => (props.isOpen || props.hasServiceOrders) && props.theme.font.weight.bold};
    opacity: ${props => !(props.isOpen || props.hasServiceOrders) && 0.7};
`;

const CalendarWeek = ({ week, pathname, statusesByDay, query }) => {
    return (
        <tr>
            <td style={{ verticalAlign: 'middle' }}>
                <CalendarWeekNumber>{ week.number }</CalendarWeekNumber>
            </td>
            { week.days.map((day, index) => {
                if (!day.hidden) {
                    const dayOfMonth = day.format('D');
                    const month = day.month();
                    const statuses = statusesByDay[month][dayOfMonth];
                    const isOpen = statuses && statuses.has(OrderStatus.OPEN);
                    const hasOrders = !!statuses;
                    const dayLinkQuery = queryString.stringify({
                        ...query,
                        months: month + 1,
                        day: dayOfMonth,
                        mode: ViewMode.LIST,
                    });

                    return (
                        <CalendarDay
                            key={ index }
                            date={ day }
                            showLink={ hasOrders }
                            link={ `${pathname}?${dayLinkQuery}` }
                        >
                            <CalendarStatus
                                statuses={ statuses }
                                isOpen={ isOpen }
                            />
                            <FormattedDay
                                hasServiceOrders={ hasOrders }
                                isOpen={ isOpen }
                            >
                                { dayOfMonth }
                            </FormattedDay>
                        </CalendarDay>
                    );
                }

                return (
                    <td key={ index }>
                        <div>
                            &nbsp;
                        </div>
                    </td>
                );
            }) }
        </tr>
    );
};

CalendarWeek.propTypes = {
    statusesByDay: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.instanceOf(Set))),
    query: PropTypes.object.isRequired,
    pathname: PropTypes.string.isRequired,
};

export default CalendarWeek;
