import React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { placeholder } from 'polished';
import Svg from 'components/Svg/Svg';

import 'react-datepicker/dist/react-datepicker.css';

const DatePickerContainer = styled.label`
    position: relative;

    .react-datepicker-wrapper, .react-datepicker__input-container, input {
        width: 100%;
    }

    .react-datepicker-popper {
        z-index: 3;
    }

    input {
        height: 50px;
        background-color: ${props => props.theme.input.background.default};
        padding: ${props => props.theme.input.padding};
        padding-left: ${props => props.showIcon && '3.375rem'};
        border: ${props => props.theme.input.border.static};
        box-shadow: ${props => !props.simple && props.theme.input.shadow.static};
        color: ${props => props.theme.input.font.color.default};
        font-size: ${props => props.theme.input.font.mobileSize};
        font-family: ${props => props.theme.input.font.family};
        line-height: ${props => props.theme.input.lineHeight.default};
        transition: ${props => props.theme.input.transition};
        &:hover, &:focus {
            border: ${props => props.theme.input.border.active};
            box-shadow: ${props => props.theme.input.shadow.active};
        }
        &:disabled {
            background-color: ${props => props.theme.input.background.disabled};
        }
        ${props => placeholder({ 'color': props.theme.input.font.color.placeholder }) }

        ${props => props.theme.media.portrait`
            border-left-color: ${props => props.endDate && 'transparent'};
            box-shadow: ${props => props.startDate || props.endDate || props.simple ? 'none' : props.theme.input.shadow.static};
        `}

        ${props => props.theme.media.landscape`
            height: auto;
            font-size: ${props => props.theme.input.font.size};
        `}
    }

    .react-datepicker__close-icon::after {
        background-color: ${props => props.theme.colors.rockBlue};
        font-size: ${props => props.theme.input.font.mobileSize};
        width: 1rem;
        height: 1rem;
        padding: 0;
        right: 0.875rem;

        ${props => props.theme.media.landscape`
            font-size: ${props => props.theme.input.font.size};
        `}
    }
`;
DatePickerContainer.displayName = 'DatePickerContainer';

const Icon = styled(Svg)`
    position: absolute;
    left: 0.875rem;
    top: 0.8rem;
    fill: ${props => props.theme.colors.rockBlue};
    font-size: ${props => props.theme.font.size.lg};
    cursor: pointer;
`;
Icon.displayName = 'Icon';

export const InputDate = props => {
    // Use value if it is explicitly provided in the props. Otherwise, get the property from the model.
    const value = props.value || _.get(props.model, props.property);
    const selected = !value || moment.isMoment(value) ? value : moment.utc(value).local();

    let datePicker;
    const handleClickOutside = () => {
        datePicker.cancelFocusInput();
        datePicker.setOpen(false);
    };

    return (
        <DatePickerContainer
            data-test-id={ props['data-test-id'] }
            showIcon={ props.showIcon }
            startDate={ props.selectsStart }
            endDate={ props.selectsEnd }
            simple={ props.simple }
        >
            <DatePicker
                id={ props.id || props.property }
                utcOffset={ 0 }
                selected={ selected }
                onChange={ newDate => props.onChange(props.property, newDate) }
                isClearable={ !!props.clearable }
                required={ props.required }

                ref={ node => { datePicker = node; } } // for problems with overflow and opening/closing:
                popperModifiers={{ // https://reactdatepicker.com/#example-27
                    preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                        boundariesElement: 'viewport'
                    }
                }}
                popperPlacement={ props.placement } // https://popper.js.org/popper-documentation.html#Popper.placements
                onClickOutside={ handleClickOutside }

                // for date ranges:
                selectsEnd={ props.selectsEnd }
                selectsStart={ props.selectsStart }
                startDate={ _.get(props.model, props.startDateProperty) }
                endDate={ _.get(props.model, props.endDateProperty) }

                fixedHeight={ props.fixedHeight }
                minDate={ props.minDate }
                maxDate={ props.maxDate }

                // shows time select in date picker:
                showTimeSelect={ props.showTimeSelect }
                timeFormat="HH:mm"
                dateFormat={ props.showTimeSelect ? 'DD/MM/YYYY, HH:mm' : undefined }
            />
            { props.showIcon && <Icon name={props.iconName ? props.iconName : 'calendar-date'} /> }
        </DatePickerContainer>
    );
};
InputDate.displayName = 'InputDate';

export default InputDate;
