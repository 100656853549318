import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'lodash';
import DialogModal from 'components/Dialog/DialogModal';
import InputForm from 'components/Form/InputForm';
import { Info } from './Api';
import InputRow from 'components/Form/InputRow';
import InputLabel from 'components/Form/InputLabel';
import InputText from 'components/Form/InputText';
import QuotaModelFooter from './QuotaModelFooter';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import ApiModalHeading from './ApiModalHeading';

const QuotaRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const QuotaInputContainer = styled.div`
    flex: 1;
    margin-right: ${props => props.theme.spacing.md};
`;

const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 171px;
`;

const QuotaModal = ({ t, toggle, defaultQuota, initialQuota, setApiQuota, profileId }) => {
    const close = React.useCallback(() => toggle(false), [toggle]);
    const [model, setModel] = React.useState({});
    const [saving, setSaving] = React.useState(false);
    React.useEffect(() => setModel({ perHour: get(initialQuota, 'data.perHour') }), [initialQuota]);

    const handlePropertyChange = ([property], value) => setModel({ ...model, [property]: value });

    const handleSubmit = async event => {
        event.stopPropagation();

        setSaving(true);
        const result = await setApiQuota(profileId, model.perHour);
        setSaving(false);

        if (!result.error) {
            close();
        }
    };

    const platformDefault = get(defaultQuota, 'data.perHour');
    const modified = get(initialQuota, 'data.perHour') !== Number.parseInt(model.perHour, 10);
    return (
        <InputForm model={ model } onPropertyChange={ handlePropertyChange } onSubmit={ handleSubmit }>
            <DialogModal
                t={ t }
                animate
                isActive
                onOverlayClick={ close }
                footer={ <QuotaModelFooter t={ t } close={ close } disabled={ !modified || saving } /> }
            >
                <ApiModalHeading>{ t('Set quota') }</ApiModalHeading>
                { saving ?
                    <LoaderContainer>
                        <Loader />
                    </LoaderContainer> :
                    <React.Fragment>
                        <Info>
                            { t('QUOTA_MODAL_INFO', platformDefault) }
                        </Info>
                        <InputRow fullRow>
                            <InputLabel text={ t('Quota') } />
                            <QuotaRow>
                                <QuotaInputContainer>
                                    <InputText type="number" property="perHour" />
                                </QuotaInputContainer>
                                <Button
                                    type="reset"
                                    onClick={ () => setModel({ perHour: null }) }
                                    autoWidth
                                    disabled={ !model.perHour }
                                >
                                    { t('Reset') }
                                </Button>
                            </QuotaRow>
                        </InputRow>
                    </React.Fragment>
                }
            </DialogModal>
        </InputForm>
    );
};

QuotaModal.propTypes = {
    t: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    defaultQuota: PropTypes.object,
    initialQuota: PropTypes.object,
    setApiQuota: PropTypes.func.isRequired,
    profileId: PropTypes.string.isRequired,
};

export default QuotaModal;
