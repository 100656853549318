const hashCode = stringToHash => {
    let hash = 0;
    if (stringToHash.length === 0) {
        return hash;
    }
    for (let i = 0; i < stringToHash.length; i++) {
        const char = stringToHash.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    hash = (hash >>> 0).toString(16);
    return hash;
};

export default hashCode;
