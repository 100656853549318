import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { transparentize } from 'polished';
import styled from 'styled-components';
import { Icon } from 'components/Icon/Icon';
import { ICON_TYPES, ICON_SIZES } from 'components/Icon/Icon';
import { icons } from 'components/Icon/IconNames';
import Select from 'components/Select/Select';

const arrowIcon = theme => ({
    name: icons.LINK_ARROW,
    iconType: ICON_TYPES.TRANSPARENT,
    fill: theme.colors.darkGray,
    size: ICON_SIZES.EXTRA_SMALL,
});

const alarmIcon = (theme, active, alert) => ({
    name: icons.BELL,
    iconType: ICON_TYPES.TRANSPARENT,
    fill:
        alert ? theme.colors.radicalRed :
        active ? theme.colors.cerulean :
        theme.colors.rockBlue,
    size: ICON_SIZES.SMALL_MINUS,
});

const IconContainer = styled.div`
    display: ${props => props.hideOnMobile && 'none'};
    font-size: ${props => props.theme.font.size.md};
    width: ${props => props.theme.spacing.xl};
    height: ${props => props.theme.spacing.xl};
    min-width: ${props => props.theme.spacing.xl};
    margin-left: auto;
    margin-right: ${props => props.theme.spacing.xxs};
    display: flex;
    align-items: center;
    justify-content: flex-end;

    div {
        width: ${props => props.theme.spacing.xl};
        height: ${props => props.theme.spacing.xl};
        background: ${props => props.backgroundColor};
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg {
        margin: 0;
        display: block;
    }

    &:hover {
        div {
            cursor: pointer;
        }
        svg {
            fill: ${props => props.theme.colors.black};
        }
    }

    ${props => props.theme.media.landscape`
        display: block;
    `}
`;
IconContainer.displayName = 'IconContainer';

const EmptyIcon = styled.div`
    width: ${props => props.theme.spacing.xl};
    height: ${props => props.theme.spacing.xl};
    min-width: ${props => props.theme.spacing.xl};
`;
EmptyIcon.displayName = 'EmptyIcon';

const SelectWrapper = styled.div`
    position: absolute;
    top: ${props => props.theme.spacing.md};
    right: 0;

    ${props => props.theme.media.landscape`
        top: ${props => props.theme.spacing.xs};
    `}

    ${props => props.theme.media.desktop`
        top: ${props => props.theme.spacing.md};
    `}

    &&& {
        .Select-menu-outer {
            margin-top: 6px;
            right: -12px;
            left: auto;
        }
        .Select-arrow-zone {
            right: 0;
            top: -2px;
            padding-right: 0;
        }
    }
`;
SelectWrapper.displayName = 'SelectWrapper';


const AddonColumn = ({ data, onIconClick, theme, t }) => {
    if (data.showArrow) {
        return (
            <IconContainer>
                <Icon
                    { ...arrowIcon(theme) }
                    onClick={ onIconClick }
                />
            </IconContainer>
        );
    }

    if (data.alarm) {
        const active = data.alarm.active;
        const alert = data.alarm.alert;
        const icon = alarmIcon(theme, active, alert);
        const iconElement =
            <IconContainer
                backgroundColor={ (active || alert) && transparentize(0.8, icon.fill) }
                onClick={ !data.alarm.active ? data.alarm.onClick : undefined }
            >
                <Icon { ...icon } />
            </IconContainer>;

        if (!data.alarm.active) {
            return iconElement;
        }

        const handleSelect = value => {
            if (value === 'EDIT') {
                data.alarm.onClick();
            } else {
                data.alarm.onDelete();
            }
        };

        return (
            <SelectWrapper>
                <Select
                    lightBackground
                    t={ t }
                    onChange={ value => handleSelect(value) }
                    options={ [{ label: t('Edit'), value: 'EDIT' }, { label: t('Remove Alarm'), value: 'DELETE' }] }
                    customArrow={ iconElement }
                    placeholder=" "
                />
            </SelectWrapper>
        );
    }

    return <EmptyIcon />;
};
AddonColumn.displayName = 'AddonColumn';

AddonColumn.defaultProps = {
    onIconClick: undefined,
};

AddonColumn.propTypes = {
    data: PropTypes.object.isRequired,
    onIconClick: PropTypes.func,
};

export default withTheme(AddonColumn);
