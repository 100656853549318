import React from 'react';
import { isEmpty, map, find } from 'lodash';
import InputLabel from 'components/Form/InputLabel';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import PropTypes from 'prop-types';

const AggregationSelector = props => {
    const {
        sensorId,
        aggregation,
        setAggregation,
        t,
        sensors,
        sensorTypes,
        placeholder
    } = props;

    const handleAggregationChange = (property, value) => setAggregation(value);

    const sensor = find(sensors, { id: sensorId });
    const sensorType = sensor && find(sensorTypes, { id: sensor.sensorTypeId });

    const aggregations = sensorType && !isEmpty(sensorType.aggregations) && sensorType.aggregations || [];
    const aggregationOptions = map(aggregations, aggr => ({ label: aggr.aggregation, value: aggr.aggregation }));
    aggregationOptions.push({ label: 'raw', value: 'raw' });

    return (
        <React.Fragment>
            <InputLabel text={ t('Aggregation') } />
            <InputSelectDropdown
                t={ t }
                model={{ aggregation }}
                options={ aggregationOptions }
                onChange={ handleAggregationChange }
                property="aggregation"
                placeholder={placeholder}
            />
        </React.Fragment>
    );
};

AggregationSelector.propTypes = {
    sensorId: PropTypes.number,
    aggregation: PropTypes.string,
    t: PropTypes.func.isRequired,
    sensors: PropTypes.arrayOf(PropTypes.object).isRequired,
    sensorTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    setAggregation: PropTypes.func.isRequired,
    placeholder: PropTypes.string
};

export default React.memo(AggregationSelector);
