import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { reduce } from 'lodash';
import Loader from 'components/Loader/Loader';
import charWidth from 'utils/String/charWidth.js';

export const BUTTON_MIN_WIDTH = 200;

const StyledButton = styled.button`
    background-color: ${props => props.color
        || props.submit && props.theme.button.colors.submit
        || props.cancel && props.theme.button.colors.cancel
        || props.clear && props.theme.button.colors.clear
        || props.remove && props.theme.button.colors.remove
        || props.theme.button.colors.default};

    color: ${props => props.textColor
        || props.submit && props.theme.button.textColors.submit
        || props.cancel && props.theme.button.textColors.cancel
        || props.clear && props.disabled && props.theme.button.textColors.clearDisabled
        || props.clear && props.theme.button.textColors.clear
        || props.remove && props.theme.button.textColors.remove
        || props.theme.button.textColors.default};

    border: 1px solid transparent;
    border-color: ${props => props.borderColor
        || props.submit && props.theme.button.borderColors.submit
        || props.cancel && props.theme.button.borderColors.cancel
        || props.clear && props.disabled && props.theme.button.borderColors.clearDisabled
        || props.clear && props.theme.button.borderColors.clear
        || props.remove && props.theme.button.borderColors.remove
        || props.theme.button.borderColors.default};

    border-radius: 2em;
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.font.size.xxxs};
    font-weight: ${props => props.theme.font.weight.bold};
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0 ${props => !props.cancel && '3em'};
    width: ${props => !props.cancel && props.autoWidth ? 'auto' : props.width}px;
    height: 3.2em;
    appearance: none;
    opacity: ${props => props.disabled ? 0.6 : 1};
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

    &:focus {
        outline: none;
    }

    ${props => props.theme.media.portrait`
        min-width: ${props => !props.cancel && !props.autoWidth && BUTTON_MIN_WIDTH}px;
    `}
`;
StyledButton.displayName = 'StyledButton';


const Button = ({ children, type, loading, submit, clear, ...others }) => {

    // Calculate the approximate width of button text so that loader wouldn't resize the button
    const width = typeof children === 'string' ? reduce(children.split(''), charWidth, 0) + 95
        : undefined;

    return (
        <StyledButton
            type={ submit ? 'submit' : type }
            submit={ submit }
            clear={ clear }
            width={ width }
            {...others}
        >
            { loading ? <Loader color={clear ? 'GRAY' : 'WHITE'} size='SMALL' /> : children }
        </StyledButton>
    );
};

Button.defaultProps = {
    disabled: false,
    loading: false,
    type: 'button',
    children: '...',
    submit: false,
    cancel: false,
    clear: false,
    remove: false,
    autoWidth: false
};

Button.propTypes = {
    color: PropTypes.string,
    textColor: PropTypes.string,
    borderColor: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    value: PropTypes.any,
    'data-test-id': PropTypes.string,
    submit: PropTypes.bool,
    cancel: PropTypes.bool,
    clear: PropTypes.bool,
    remove: PropTypes.bool,
    autoWidth: PropTypes.bool,
    children: PropTypes.any
};

export default Button;
