import React from 'react';
import PropTypes from 'prop-types';

import Dialog from './Dialog.jsx';
import DialogFrame from './DialogFrame.jsx';
import DialogBody from './DialogBody.jsx';

const DialogModal = ({ children, footer, t, small, large, extraLarge, bodyComponent: BodyComponent, ...otherProps }
) => {
    return (
        <Dialog { ...otherProps }>
            <DialogFrame footer={ footer } t={ t } onClose={ otherProps.onOverlayClick }>
                <BodyComponent small={ small } large={ large } extraLarge={ extraLarge }>
                    { children }
                </BodyComponent>
            </DialogFrame>
        </Dialog>
    );
};

DialogModal.propTypes = {
    bodyComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.elementType]),
};

DialogModal.defaultProps = {
    bodyComponent: DialogBody,
};

export default DialogModal;
