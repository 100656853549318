import { createReducerFromMapping } from 'redux/utils/index.js';
import { MasterData } from '@caverion/redux/api/actions';
import moment from 'moment';
import _ from 'lodash';
import { isAdminRole } from 'utils/Data/profileData';

const initialState = {
    loading: false,
    loadingFLImage: false,
    updating: false,
    updated: false,
    byPartner: {},
    byFL: {}
};

export const UPSERT = 'CUSTOMER_PLATFORM/PartnerImage/UPSERT';
export const UPSERT_SUCCESS = 'CUSTOMER_PLATFORM/PartnerImage/UPSERT_SUCCESS';
export const UPSERT_FAIL = 'CUSTOMER_PLATFORM/PartnerImage/UPSERT_FAIL';

export const upsertImage = image => {
    return async dispatch => {
        dispatch({ type: UPSERT });
        try {
            const result = await dispatch(MasterData.upsertImage(image));
            return dispatch({
                type: UPSERT_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: UPSERT_FAIL,
                error
            });
        }
    };
};


export const DELETE = 'CUSTMOER_PLATFORM/PartnerImage/DELETE';
export const DELETE_SUCCESS = 'CUSTMOER_PLATFORM/PartnerImage/DELETE_SUCCESS';
export const DELETE_FAIL = 'CUSTMOER_PLATFORM/PartnerImage/DELETE_FAIL';

export const deleteImage = id => {
    return async dispatch => {
        dispatch({ type: DELETE });
        try {
            const result = await dispatch(MasterData.deleteImage(id));
            return dispatch({
                type: DELETE_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: DELETE_FAIL,
                error
            });
        }
    };
};


export const LOAD_FOR_PARTNER = 'CUSTOMER_PLATFORM/PartnerImage/LOAD_FOR_PARTNER';
export const LOAD_FOR_PARTNER_SUCCESS = 'CUSTOMER_PLATFORM/PartnerImage/LOAD_FOR_PARTNER_SUCCESS';
export const LOAD_FOR_PARTNER_FAIL = 'CUSTOMER_PLATFORM/PartnerImage/LOAD_FOR_PARTNER_FAIL';

export const loadPartnerImages = partnerNumber => async (dispatch, getState) => {
    // Invalidate cache for admin users
    // TODO: Proper cache invalidation process
    const state = getState();
    const profile = state.profile && state.profile.profile;
    const timestamp = profile && isAdminRole(profile.role) ? Date.now() : undefined;

    dispatch({ type: LOAD_FOR_PARTNER });
    try {
        const result = await dispatch(MasterData.imagesByPartnerNumber(partnerNumber, timestamp));
        return dispatch({
            type: LOAD_FOR_PARTNER_SUCCESS,
            key: 'partner_images_' + partnerNumber,
            partnerNumber,
            result
        });
    } catch (error) {
        return dispatch({
            type: LOAD_FOR_PARTNER_FAIL,
            key: 'partner_images_' + partnerNumber,
            error
        });
    }
};


export const LOAD_FOR_FL = 'CUSTOMER_PLATFORM/PartnerImage/LOAD_FOR_FL';
export const LOAD_FOR_FL_SUCCESS = 'CUSTOMER_PLATFORM/PartnerImage/LOAD_FOR_FL_SUCCESS';
export const LOAD_FOR_FL_FAIL = 'CUSTOMER_PLATFORM/PartnerImage/LOAD_FOR_FL_FAIL';

export const loadFunctionalLocationImages = functionalLocation => {
    const filter = {
        where: {
            functionalLocation: functionalLocation
        }
    };
    return async (dispatch, getState) => {
        // Invalidate cache for admin users
        // TODO: Proper cache invalidation
        const state = getState();
        const profile = state.profile && state.profile.profile;
        if (profile && isAdminRole(profile.role)) {
            filter.timestamp = moment.utc().toISOString();
        }
        dispatch({ type: LOAD_FOR_FL });
        try {
            const result = await dispatch(MasterData.images(filter));
            return dispatch({
                type: LOAD_FOR_FL_SUCCESS,
                key: 'fl_image_' + functionalLocation,
                functionalLocation,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_FOR_FL_FAIL,
                key: 'fl_image_' + functionalLocation,
                error
            });
        }
    };
};

export const LOAD_FOR_FLS = 'CUSTOMER_PLATFORM/PartnerImage/LOAD_FOR_FL';
export const LOAD_FOR_FLS_SUCCESS = 'CUSTOMER_PLATFORM/PartnerImage/LOAD_FOR_FL_SUCCESS';
export const LOAD_FOR_FLS_FAIL = 'CUSTOMER_PLATFORM/PartnerImage/LOAD_FOR_FL_FAIL';

export const loadFunctionalLocationsImages = functionalLocations => {
    const filter = {
        where: {
            functionalLocation: { inq: functionalLocations },
            type: 'building'
        }
    };
    return async dispatch => {
        dispatch({ type: LOAD_FOR_FLS });
        try {
            const result = await dispatch(MasterData.images(filter));
            return dispatch({
                type: LOAD_FOR_FLS_SUCCESS,
                key: 'fl_image_' + JSON.stringify(functionalLocations),
                functionalLocations,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_FOR_FLS_FAIL,
                key: 'fl_image_' + JSON.stringify(functionalLocations),
                error
            });
        }
    };
};


export default createReducerFromMapping({
    [UPSERT]: state => ({
        ...state,
        updating: true
    }),
    [UPSERT_SUCCESS]: state => ({
        ...state,
        updating: false,
        updated: true
    }),
    [UPSERT_FAIL]: (state, action) => ({
        ...state,
        updating: false,
        updated: false,
        error: action.error,
    }),
    [DELETE]: state => ({
        ...state,
        deleting: true
    }),
    [DELETE_SUCCESS]: state => ({
        ...state,
        deleting: false,
        deleted: true
    }),
    [DELETE_FAIL]: (state, action) => ({
        ...state,
        deleting: false,
        deleted: false,
        error: action.error,
    }),

    [LOAD_FOR_PARTNER]: state => ({
        ...state,
        loading: true
    }),
    [LOAD_FOR_PARTNER_SUCCESS]: (state, action) => {
        // If result is an empty array, let's clear that partner state
        let result = {};
        if (action.result && action.result.length === 0) {
            result = {
                [action.partnerNumber]: []
            };
        } else {
            result = _.groupBy(action.result, 'partnerNumber');
        }

        return {
            ...state,
            loading: false,
            byPartner: _.assign({}, state.byPartner, result)
        };
    },
    [LOAD_FOR_PARTNER_FAIL]: state => ({
        ...state,
        loading: false
    }),
    [LOAD_FOR_FL]: state => ({
        ...state,
        loadingFLImage: true
    }),
    [LOAD_FOR_FL_SUCCESS]: (state, action) => {
        let result = {};
        if (action.result && action.result.length === 0) {
            result = {
                [action.functionalLocation]: []
            };
        } else {
            result = _.groupBy(action.result, 'functionalLocation');
        }

        return {
            ...state,
            loadingFLImage: false,
            byFL: _.assign({}, state.byFL, result),
        };
    },
    [LOAD_FOR_FL_FAIL]: state => ({
        ...state,
        loadingFLImage: false,
    }),
    [LOAD_FOR_FLS]: state => ({
        ...state,
        loadingFLImage: true
    }),
    [LOAD_FOR_FLS_SUCCESS]: (state, action) => {
        let result = {};
        if (action.result && action.result.length === 0) {
            if (_.isArray(action.functionalLocation)) {
                action.functionalLocation.forEach(functionalLocation => {
                    result[functionalLocation] = [];
                });
            } else {
                result[action.functionalLocation] = [];
            }
        } else {
            result = _.groupBy(action.result, 'functionalLocation');
        }

        return {
            ...state,
            loadingFLImage: false,
            byFL: _.assign({}, state.byFL, result),
        };
    },
    [LOAD_FOR_FLS_FAIL]: state => ({
        ...state,
        loadingFLImage: false,
    }),
}, initialState);
