import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    sessionUnset
} from 'redux/modules/index.js';

import { getWelcomeUrl } from 'utils/Data/language';

import Loading from 'components/Loading/Loading.jsx';

class LogoutSession extends Component {

    componentDidMount() {
        this.props.sessionUnset(getWelcomeUrl());
    }

    render() {
        return <Loading showLogo={ false } />;
    }
}

function mapStateToProps(state) {
    return {
        session: state.session
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sessionUnset: redirectUrl =>
            dispatch(sessionUnset(redirectUrl))
    };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withRouter(LogoutSession));
