import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import translations from 'decorators/Translations/translations';
import PropTypes from 'prop-types';
import { reject, get } from 'lodash';
import Button from 'components/Button/Button';
import DialogModal from 'components/Dialog/DialogModal';
import DialogFooter from 'components/Dialog/DialogFooter';
import SensorAlarmForm from 'components/SensorAlarmForm/SensorAlarmForm';
import { updateSensorAlarms } from 'redux/modules/iot/sensorAlarms';
import { getPerformanceLimit } from 'utils/Data/performance';

const Heading = styled.h3`
    margin-bottom: ${props => props.theme.spacing.md};
`;

export const SensorAlarm = props => {
    const {
        t,
        sensorAlarmsById,
        sensorAlarms,
        submitting,
        onClose,
        onError,
        onSuccess,
        updateSensorAlarms,
        buildingMeta,
        sensor
    } = props;

    const sensorId = sensor.id;
    const sensorName = sensor.name;
    const sensorType = sensor.sensorType;
    const isPresence = sensorType && sensorType.graphType === 'presence';
    const aggregation = get(sensor, 'sensorType.latestValueAggregation.aggregation') || 'raw';

    const getDefaultData = () => {
        if (isPresence) {
            return { id: sensorId, weekdays: true, aggregation };
        }

        const getLimits = getPerformanceLimit(sensor, sensor.parent, buildingMeta);
        const limits = getLimits && getLimits(new Date());
        if (limits) {
            const [min, max] = limits;
            return { id: sensorId, weekdays: true, min, max, aggregation };
        }

        return { id: sensorId, weekdays: true, aggregation };
    };

    const [sensorConfig, updateConfig] = useState(sensorAlarmsById[sensorId] || getDefaultData());

    const handleFormChange = (property, value) => updateConfig({ ...sensorConfig, [property]: value });

    const handleSubmit = event => {
        event.preventDefault();
        let sensors = sensorAlarms && sensorAlarms.sensors || [];
        sensors = reject(sensors, { id: sensorConfig.id });
        sensors.push(sensorConfig);

        updateSensorAlarms({ sensors }).then(response => {
            if (response.error) {
                return onError(t('Setting sensor alarm failed!'));
            }
            return onSuccess(t('Sensor alarm set successfully'));
        });
    };

    return <form>
        <DialogModal
            t={ t }
            isActive
            animate
            onOverlayClick={ onClose }
            footer={
                <DialogFooter>
                    <Button cancel onClick={ onClose }>{ t('Cancel') }</Button>
                    <Button
                        submit
                        onClick={ handleSubmit }
                        type="submit"
                        loading={ submitting }
                        disabled={ isNaN(sensorConfig.min) && isNaN(sensorConfig.max) && !sensorConfig.occupiedAlarm }
                    >
                        { t('Set Alarm') }
                    </Button>
                </DialogFooter>
            }>
            <Heading>{ t('Set Alarm') }</Heading>
            <SensorAlarmForm
                sensor={ sensorConfig }
                onPropertyChange={ handleFormChange }
                sensorName={ sensorName }
                t={ t }
                isPresence={ isPresence }
            />
        </DialogModal>
    </form>;
};

SensorAlarm.propTypes = {
    onClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    sensorAlarms: PropTypes.object.isRequired,
    sensorAlarmsById: PropTypes.object.isRequired,
    sensor: PropTypes.object.isRequired,
    parent: PropTypes.object,
    updateSensorAlarms: PropTypes.func.isRequired,
    buildingMeta: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => ({
    submitting: state.sensorAlarms.loading,
    sensorAlarms: state.sensorAlarms.sensorAlarms,
    sensorAlarmsById: state.sensorAlarms.sensorAlarmsById
});

const mapDispatchToProps = dispatch => ({
    updateSensorAlarms: data => dispatch(updateSensorAlarms(data))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(SensorAlarm));
