import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isEmpty, endsWith, times, throttle } from 'lodash';
import { connect } from 'react-redux';
import { Document, Page } from 'react-pdf';
import ReactDOM from 'react-dom';

import { customViewShape } from 'components/CustomView/utils';
import { getCustomViewFileUrl } from 'redux/modules/document/file';
import Loader from 'components/Loader/Loader';

const Container = styled.div`
    cursor: ${props => props.progress && 'progress'};
    ${props => props.theme.media.portrait`
        margin-top: ${props => props.theme.spacing.md};
    `}

    ${props => props.theme.media.landscape`
        margin-top: 0;
    `}
`;

const StyledImage = styled.img`
    max-width: 100%;
`;

const PageContainer = styled.div`
    margin-bottom: ${props => props.theme.spacing.md};
`;

const ZoomContainer = styled.div`
    display: none;
    position: ${props => props.fixed ? 'fixed' : 'absolute'};
    top: ${props => props.fixed ? '64px' : 'auto'};
    right: 40px;
    z-index: 2;
    margin: ${props => props.theme.spacing.xs};
    background-color: ${props => props.theme.colors.lightGray};
    border-radius: 2px;

    ${props => props.theme.media.landscape`
        display: block;
    `}
`;

const ZoomElement = styled.span`
    font-size: ${props => props.theme.font.size.xxl};
    padding: ${props => props.theme.spacing.sm};
    cursor: ${props => props.progress ? 'progress' : 'pointer'};
    color: ${props => props.theme.colors.black};

    &:hover {
        color: ${props => props.theme.colors.darkGray};
    }

    &:active {
        cursor: progress;
    }
`;

const LoaderContainer = styled.div`
    text-align: center;
`;

const getLoader = () => <LoaderContainer><Loader color='GRAY' size='LARGE' /></LoaderContainer>;

const ZOOM_STEP = 0.1;
const ZOOM_DIRECTION = {
    UP: 'up',
    DOWN: 'down'
};

const EmbeddedDocumentView = props => {
    const { customView, noDataRender, getFileUrl, t, url, scroll: { scrollTop }, hasHero } = props;

    const [loading, setLoading] = useState(true);
    const [numPages, setNumPages] = useState(0);
    const [containerWidth, setContainerWidth] = useState(undefined);
    const [scale, setScale] = useState(1);
    const [zooming, setZooming] = useState(false);

    const isPDF = customView && endsWith(customView.content, 'pdf');
    const isFixed = !hasHero || scrollTop > 150 + 64;
    let containerRef = null;


    const setRef = el => {
        if (el) {
            containerRef = el;
        }
    };

    const onDocumentLoadSuccess = document => {
        const { numPages } = document;
        setNumPages(numPages);
    };

    useEffect(() => {
        const loadData = async () => {
            await getFileUrl(customView.id);
            setLoading(false);
        };
        const calculateContainerWidth = () => {
            if (containerRef) {
                const element = ReactDOM.findDOMNode(containerRef);
                if (element) {
                    setContainerWidth(scale * element.getBoundingClientRect().width);
                }
            }
        };
        const handleResize = throttle(() => {
            isPDF && calculateContainerWidth();
        }, 500);

        loadData();
        isPDF && calculateContainerWidth();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const scaleDocument = direction => {
        setZooming(true);
        const newScale = direction === ZOOM_DIRECTION.UP ? scale + ZOOM_STEP : scale - ZOOM_STEP;
        setScale(newScale);
    };

    if (!customView || isEmpty(customView.content)) {
        return noDataRender;
    }

    if (!loading && !url) {
        return noDataRender;
    }

    return (
        <Container ref={ setRef } hasHero={ hasHero } progress={ zooming }>
            { loading && getLoader() }
            { !isPDF && !loading && <StyledImage src={ url } /> }
            { isPDF && !loading && <Fragment>
                <ZoomContainer fixed={ isFixed }>
                    <ZoomElement progress={ zooming } onClick={ () => scaleDocument(ZOOM_DIRECTION.DOWN) }>
                        -
                    </ZoomElement>
                    <ZoomElement progress={ zooming } onClick={ () => scaleDocument(ZOOM_DIRECTION.UP) }>
                        +
                    </ZoomElement>
                </ZoomContainer>
                <Document
                    file={ url }
                    onLoadSuccess={ onDocumentLoadSuccess }
                    loading={ getLoader() }
                    error={ t('Failed to load PDF file.') }
                    width="100%"
                >
                    { times(numPages).map((el, index) =>
                        <PageContainer key={ index }>
                            <Page
                                pageIndex={ index }
                                loading=""
                                width={ scale * containerWidth }
                                onRenderSuccess={() => index === numPages - 1 && setZooming(false)}
                            />
                        </PageContainer>
                    ) }
                </Document>
            </Fragment> }
        </Container>
    );
};

EmbeddedDocumentView.defaultProps = {
    customView: {},
    hasHero: false
};

EmbeddedDocumentView.propTypes = {
    customView: PropTypes.oneOfType([customViewShape()]),
    noDataRender: PropTypes.node,
    t: PropTypes.func.isRequired,
    scroll: PropTypes.shape({
        scrollTop: PropTypes.number.isRequired
    }).isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    url: ownProps.customView && state.file.customViewUrls[ownProps.customView.id],
    scroll: state.common.scroll,
});

const mapDispatchToProps = dispatch => ({
    getFileUrl: customViewId => dispatch(getCustomViewFileUrl(customViewId))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(EmbeddedDocumentView);
