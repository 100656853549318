import memoizeOne from 'memoize-one';
import moment from 'moment';
import { sortBy, meanBy, keyBy, pick, values, capitalize, forOwn, groupBy, isEmpty, map, flatMap, filter, compact,
    some } from 'lodash';

import { CELSIUS, HEATING_TYPE, COOLING_TYPE, OUTDOOR_TYPE } from 'utils/Data/values';
import { getPresenceData } from 'components/Modules/FloorplanModule/FloorOPICards/FloorOPIUtils';

export const getAirQualityValues = memoizeOne((floors, sensorValues, latestValues, t) => {
    const airQualitySensors = getAirQualitySensors(floors);
    if (!isEmpty(airQualitySensors)) {
        const airQualitySensorIds = airQualitySensors.map(sensor => sensor.id);
        return {
            value: getAirQualityOPIValue(airQualitySensorIds, latestValues),
            sensorGroups: getFloorSensorGroups(floors, airQualitySensors, t),
            sensorsIds: airQualitySensorIds
        };
    }
});

export const getAirQualitySensors = floors =>
    flatMap(floors, 'sensors')
        .filter(sensor => sensor && sensor.sensorType && sensor.sensorType.name === 'technical_performance');

export const getBuildingConditionsValues = memoizeOne(buildingConditions => {
    let result = {
        cooling: [],
        heating: [],
        outdoorTemperature: [],
    };

    if (!isEmpty(buildingConditions)) {
        const coolingUtilizationValues = [];
        forOwn(groupBy(buildingConditions[COOLING_TYPE], value => value.timestamp), (value, key) => {
            coolingUtilizationValues.push({
                timestamp: moment.utc(key).valueOf(),
                avg: meanBy(value, a => a.avg),
                sensorName: value[0].sensorName
            });
        });
        coolingUtilizationValues.sort((a, b) => a.timestamp - b.timestamp);

        const heatingUtilizationValues = [];
        forOwn(groupBy(buildingConditions[HEATING_TYPE], value => value.timestamp), (value, key) => {
            heatingUtilizationValues.push({
                timestamp: moment.utc(key).valueOf(),
                avg: meanBy(value, a => a.avg),
                sensorName: value[0].sensorName
            });
        });
        heatingUtilizationValues.sort((a, b) => a.timestamp - b.timestamp);

        // Use the values from the first temperature sensor only.
        let outdoorsTemperatureValues =
            buildingConditions[OUTDOOR_TYPE] && buildingConditions[OUTDOOR_TYPE].length > 0 ?
                buildingConditions[OUTDOOR_TYPE].filter((value, i, a) => value.sensorId === a[0].sensorId) :
                [];
        outdoorsTemperatureValues = outdoorsTemperatureValues.map(row =>
            ({ ...row, timestamp: moment.utc(row.timestamp).valueOf() }));
        outdoorsTemperatureValues.sort((a, b) => a.timestamp - b.timestamp);

        result = Object.assign({}, result, {
            cooling: coolingUtilizationValues,
            heating: heatingUtilizationValues,
            outdoorTemperature: outdoorsTemperatureValues,
        });
    }
    return result;
});

const EMPTY_OBJECT = {};

const getFloorSensorGroups = (floors, opiSensors, t) =>
    compact(map(floors, floor => {
        const opiSensorsInFloor = filter(opiSensors, opiSensor =>
            some(floor.sensors, floorSensor =>
                floorSensor.id === opiSensor.id || floorSensor.id === opiSensor.parentId)
        );

        if (isEmpty(opiSensorsInFloor)) {
            return null;
        }

        return {
            name: `${t('Floor')} ${t(floor.shortName || floor.name || 'Unnamed')}`,
            isGroup: true,
            sensors: opiSensorsInFloor,
            sensorType: opiSensors[0].sensorType,
            sensorTypeId: opiSensors[0].sensorTypeId,
        };
    }));

export const getUtilizationRateValues = memoizeOne((floors, sensorValues, buildingMeta, t) => {
    if (isEmpty(floors) || isEmpty(sensorValues) || isEmpty(buildingMeta)
        || !some(sensorValues, { aggregation: 'hourlyUtilizationRate' })) {
        return EMPTY_OBJECT;
    }

    const floorSensors = flatMap(floors, 'sensors');

    const presenceAreaData = getPresenceData(floorSensors, sensorValues, 'presence_area', buildingMeta);
    const presenceArea = {
        value: presenceAreaData.value,
        sensorGroups: getFloorSensorGroups(floors, presenceAreaData.sensors, t),
        sensorsIds: presenceAreaData.sensorIds,
    };

    const presenceSeatData = getPresenceData(floorSensors, sensorValues, 'presence_seat', buildingMeta);
    const presenceSeat = {
        value: presenceSeatData.value,
        sensorGroups: getFloorSensorGroups(floors, presenceSeatData.sensors, t),
        sensorsIds: presenceSeatData.sensorIds,
    };

    const presenceZoneData = getPresenceData(floorSensors, sensorValues, 'presence_zone', buildingMeta);
    const presenceZone = {
        value: presenceZoneData.value,
        sensorGroups: getFloorSensorGroups(floors, presenceZoneData.sensors, t),
        sensorsIds: presenceZoneData.sensorIds,
    };

    return {
        presenceArea,
        presenceZone,
        presenceSeat,
    };
});

export const getOPICards = memoizeOne((
    airQuality, outsideTemperature, cooling, heating, presenceArea, presenceZone, presenceSeat, toggleModal, t) => {

    const opiCards = [];

    if (typeof airQuality !== 'undefined') {
        opiCards.push({
            title: 'Indoor Air Quality',
            subtitle: '7 Days Average',
            value: airQuality.value,
            s2Link: true,
            isAirQuality: true,
            onClick: () => toggleModal({
                title: t('Indoor Air Quality'),
                sensorGroups: airQuality.sensorGroups,
                sensorsIds: airQuality.sensorsIds,
                isAirQuality: true,
                showCombinationGroup: true
            }),
            icon: 'opi-expand',
        });
    }

    if (outsideTemperature) {
        opiCards.push({
            title: 'Outdoors temperature',
            subtitle: outsideTemperature && capitalize(moment.utc(outsideTemperature.timestamp).local().fromNow()),
            valueInside: outsideTemperature ? `${outsideTemperature.avg}${CELSIUS}` : null,
            scrollTo: 'BS',
            noCircle: true,
            icon: 'opi-arrow',
        });
    }

    if (cooling) {
        opiCards.push({
            title: 'Cooling utilization',
            subtitle: cooling && capitalize(moment.utc(cooling.timestamp).local().fromNow()),
            value: cooling ? cooling.avg : null,
            scrollTo: 'BS',
            neutral: true,
            icon: 'opi-arrow',
        });
    }

    if (heating) {
        opiCards.push({
            title: 'Heating utilization',
            subtitle: heating && capitalize(moment.utc(heating.timestamp).local().fromNow()),
            value: heating ? heating.avg : null,
            scrollTo: 'BS',
            neutral: true,
            icon: 'opi-arrow',
        });
    }

    if (presenceArea && !isEmpty(presenceArea.sensorsIds)) {
        opiCards.push({
            title: 'Meeting Room Utilization',
            subtitle: 'Last 7 days',
            value: presenceArea.value,
            neutral: true,
            onClick: () => toggleModal({
                title: t('Meeting Room Utilization'),
                sensorGroups: presenceArea.sensorGroups,
                sensorsIds: presenceArea.sensorsIds,
                isUtilizationRate: true,
                showCombinationGroup: true
            }),
            icon: 'opi-expand',
        });
    }

    if (presenceZone && !isEmpty(presenceZone.sensorsIds)) {
        opiCards.push({
            title: 'Open Office Utilization',
            subtitle: 'Last 7 days',
            value: presenceZone.value,
            neutral: true,
            onClick: () => toggleModal({
                title: t('Open Office Utilization'),
                sensorGroups: presenceZone.sensorGroups,
                sensorsIds: presenceZone.sensorsIds,
                isUtilizationRate: true,
                showCombinationGroup: true
            }),
            icon: 'opi-expand',
        });
    }

    if (presenceSeat && !isEmpty(presenceSeat.sensorsIds)) {
        opiCards.push({
            title: 'Seat Utilization',
            subtitle: 'Last 7 days',
            value: presenceSeat.value,
            neutral: true,
            onClick: () => toggleModal({
                title: t('Seat Utilization'),
                sensorGroups: presenceSeat.sensorGroups,
                sensorsIds: presenceSeat.sensorsIds,
                isUtilizationRate: true,
                showCombinationGroup: true
            }),
            icon: 'opi-expand',
        });
    }

    return opiCards;
});

export const getAirQualityAverages = memoizeOne((values, sensorIds, aggregation, startDate, endDate) => {
    const start = startDate.toISOString();
    const end = endDate.toISOString();
    const sensorIdMap = keyBy(sensorIds);
    const airQualityValues = values.filter(value =>
        value.aggregation === aggregation &&
        value.sensorId in sensorIdMap &&
        moment(value.timestamp).isBetween(start, end, undefined, '[]')
    );

    let startOf;
    switch (aggregation) {
    case 'monthlyAverage':
        startOf = 'month';
        break;
    case 'weeklyAverage':
        startOf = 'isoWeek';
        break;
    case 'dailyAverage':
        startOf = 'day';
        break;
    default:
        startOf = 'hour';
        break;
    }

    const buckets = new Map();
    airQualityValues.forEach(value => {
        const key = moment.utc(value.timestamp).startOf(startOf).valueOf();
        const bucket = buckets.get(key);
        if (bucket) {
            bucket.push(value);
        }
        else {
            buckets.set(key, [value]);
        }
    });

    return sortBy(
        Array.from(buckets, ([timestamp, bucket]) => ({
            timestamp,
            value: meanBy(bucket, 'value'),
        })),
        'timestamp'
    );
});

export const getAirQualityOPIValue = memoizeOne((sensorIds, latestValues) => {
    const airQualityValues = values(pick(latestValues, sensorIds));
    return airQualityValues.length ? meanBy(airQualityValues, 'value') : undefined;
});
