import React from 'react';
import styled, { withTheme } from 'styled-components';
import { rgba } from 'polished';

const CheckboxContainer = styled.span`
    color: ${props => props.theme.colors.abbey};
`;
CheckboxContainer.displayName = 'CheckboxContainer';

const CheckboxInput = styled.input`
    position: absolute;
    opacity: 0;

    &:checked + label::after {
        content: '';
        position: absolute;
        display: block;
        left: 10px;
        top: 0;
        width: 6px;
        height: 12px;
        border: solid ${props => props.disabled ? props.theme.colors.rockBlue : props.theme.colors.white};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

    &:checked + label::before {
        background: ${props => props.disabled ? props.theme.colors.lightGray : props.theme.colors.cerulean};
        border: ${props => props.disabled ? '1px solid ' + props.theme.colors.rockBlue : 'none'};
        box-shadow: none;
    }

`;
CheckboxInput.displayName = 'CheckboxInput';

const CheckboxLabel = styled.label`
    cursor: ${props => !props.disabled && 'pointer'};
    position: relative;
    font-size: ${props => props.labelSize === 'large' ? props.theme.font.size.sm : props.theme.font.size.xs};
    font-weight: ${props => props.labelSize === 'large' ? props.theme.fontWeight.bold : props.theme.fontWeight.normal};
    opacity: ${props => props.disabled && 0.6};

    &::before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        width: 24px;
        height: 24px;
        background: ${props => props.theme.colors.white};
        border: 1px solid ${props => props.theme.colors.darkGray};
        border-radius: 6px;
        /* Set margin as ems if label font-size changes (formula: -3 / 14 * 1em) */
        margin-top: -0.2143em;
        box-shadow: ${props => `inset 0 3px 3px 0 ${rgba(props.theme.colors.black, 0.10)}`};
    }
`;
CheckboxLabel.displayName = 'CheckboxLabel';

const Checkbox = props => {
    return (
        <CheckboxContainer>
            <CheckboxInput
                type="checkbox"
                id={ props.id }
                data-test-id={ props.id }
                checked={ props.checked }
                onChange={ props.onChange }
                disabled={ props.disabled }
            />
            <CheckboxLabel labelSize={ props.labelSize } htmlFor={ props.id } disabled={ props.disabled }>
                { props.label }
            </CheckboxLabel>
        </CheckboxContainer>
    );
};

export default withTheme(Checkbox);
