import { createReducerFromMapping } from 'redux/utils/index.js';
import _ from 'lodash';
import moment from 'moment';
import { IoT, MasterData } from '@caverion/redux/api/actions';
import {
    isEnergySensor,
    isElectricitySensor,
    isDistrictHeatingSensor,
    isWaterConsumptionSensor,
    isEnergyRatingSensor
} from 'utils/Data/values.js';
import { getWhereFromPermissions } from 'redux/utils/partnerFilter';

const initialState = {
    conditions: {},
    cleaningValues: [],
    sensorsByEquipmentNumber: {},
    sensorValues: [],
    latestValuesBySensorId: {},
    valuesBySensorId: {},
    energyValues: {},
    energyValuesByPartner: {},
    energyValuesBySensor: {},
    energySensors: {},
    energyChartValues: { byFl: {}, byPartner: {} },
    loadingSensorValues: false,
    loadingLatestSensorValues: false,
    loadingConditions: false,
    loadingEnergyValues: false,
    loadingEnergyChartValues: false,
    energyRating: {
        loading: false,
        data: {
            averages: {},
        },
    },
    loadingCleaning: false,
    consumptionKPIs: {},
    airQualityKPI: {
        loading: false,
        data: {},
    },
};

const fields = ['sensorId', 'aggregation', 'timestamp', 'value'];

export const LOAD_CLEANING = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CLEANING';
export const LOAD_CLEANING_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CLEANING_SUCCESS';
export const LOAD_CLEANING_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CLEANING_FAIL';

export const loadCleaning = (sensorIds, startTime, endTime) => {
    const filter = {
        where: {
            sensorId: { inq: sensorIds },
            timestamp: { between: [startTime, endTime] },
            aggregation: 'raw'
        },
        fields,
    };

    return async dispatch => {
        dispatch({ type: LOAD_CLEANING });
        try {
            const result = await dispatch(IoT.findWithPost(filter));

            return dispatch({
                type: LOAD_CLEANING_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_CLEANING_FAIL,
                error
            });
        }
    };
};

export const LOAD_CONDITIONS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CONDITIONS';
export const LOAD_CONDITIONS_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CONDITIONS_SUCCESS';
export const LOAD_CONDITIONS_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CONDITIONS_FAIL';

export const loadBuildingConditions = (functionalLocation, sensors, startTime, endTime) => {
    const filter = {
        where: {
            aggregation: 'hourlyAverage', // TODO: aggregation needs to be dynamic from the sensor.analytics , if has hourlyAverage analytics, use that, otherwise pick all values.
            timestamp: { between: [startTime, endTime] },
        },
        order: 'timestamp ASC',
        fields,
    };

    return async dispatch => {
        dispatch({ type: LOAD_CONDITIONS });

        if (!sensors || sensors.length === 0) {
            return dispatch({
                type: LOAD_CONDITIONS_FAIL,
            });
        }

        const sensorIds = _.map(sensors, 'id');
        filter.where.sensorId = { inq: sensorIds };

        try {
            const result = await dispatch(IoT.findWithPost(filter));

            return dispatch({
                type: LOAD_CONDITIONS_SUCCESS,
                result,
                sensors,
                functionalLocation
            });
        } catch (error) {
            return dispatch({
                type: LOAD_CONDITIONS_FAIL,
                error
            });
        }
    };
};

export const LOAD_SENSOR_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_SENSOR_VALUES';
export const LOAD_SENSOR_VALUES_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_SENSOR_VALUES_SUCCESS';
export const LOAD_SENSOR_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_SENSOR_VALUES_FAIL';

export const loadSensorValues = (sensorIds, startTime, endTime, aggregation) => {
    const filter = {
        where: {
            sensorId: { inq: sensorIds },
            timestamp: { between: [startTime, endTime] },
        },
        order: 'timestamp ASC',
        fields,
    };
    if (aggregation) {
        filter.where.aggregation = aggregation;
    }

    return async dispatch => {
        dispatch({ type: LOAD_SENSOR_VALUES });
        try {
            const result = await dispatch(IoT.findWithPost(filter));

            return dispatch({
                type: LOAD_SENSOR_VALUES_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_SENSOR_VALUES_FAIL,
                error,
                sensorIds
            });
        }
    };
};

export const LOAD_EQUIPMENT_SENSORS_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_EQUIPMENT_SENSORS_VALUES';
export const LOAD_EQUIPMENT_SENSORS_VALUES_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_EQUIPMENT_SENSORS_VALUES_SUCCESS'; // eslint-disable-line max-len
export const LOAD_EQUIPMENT_SENSORS_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_EQUIPMENT_SENSORS_VALUES_FAIL';

export const loadEquipmentSensorsValues = (equipmentNumber, types, startTime, endTime, aggregation) => {
    const sensorFilter = {
        where: {
            equipmentNumber
        },
        fields: ['id'],
        include: {
            relation: 'children',
            scope: {
                fields: ['id']
            }
        }
    };

    const filter = {
        where: {
            type: { inq: types },
            timestamp: { between: [startTime, endTime] },
        },
        order: 'timestamp ASC',
        fields,
    };
    if (aggregation) {
        filter.where.aggregation = aggregation;
    }

    return async dispatch => {
        dispatch({ type: LOAD_EQUIPMENT_SENSORS_VALUES });
        try {
            const equipmentSensors = await dispatch(MasterData.sensors(JSON.stringify(sensorFilter)));
            filter.where.sensorId = { inq: _.flatMap(equipmentSensors[0].children, 'id') };
            const result = await dispatch(IoT.findWithPost(filter));

            return dispatch({
                type: LOAD_EQUIPMENT_SENSORS_VALUES_SUCCESS,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_EQUIPMENT_SENSORS_VALUES_FAIL,
                error
            });
        }
    };
};

export const LOAD_SENSORS_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_SENSORS_VALUES';
export const LOAD_SENSORS_VALUES_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_SENSORS_VALUES_SUCCESS';
export const LOAD_SENSORS_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_SENSORS_VALUES_FAIL';

export const loadSensorsValues = (sensorIds, startTime, endTime, aggregation) => {
    const filter = {
        where: {
            timestamp: { between: [startTime, endTime] },
            sensorId: { inq: sensorIds }
        },
        order: 'timestamp ASC',
        fields,
    };
    if (aggregation !== undefined) {
        filter.where.aggregation = aggregation;
    }

    return async dispatch => {
        dispatch({ type: LOAD_SENSORS_VALUES });
        try {
            const result = await dispatch(IoT.findWithPost(filter));

            return dispatch({
                type: LOAD_SENSORS_VALUES_SUCCESS,
                result,
                sensorIds
            });
        } catch (error) {
            return dispatch({
                type: LOAD_SENSORS_VALUES_FAIL,
                error,
                sensorIds
            });
        }
    };
};

export const LOAD_LATEST_SENSORS_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_LATEST_SENSORS_VALUES';
export const LOAD_LATEST_SENSORS_VALUES_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_LATEST_SENSORS_VALUES_SUCCESS';
export const LOAD_LATEST_SENSORS_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_LATEST_SENSORS_VALUES_FAIL';

export const loadLatestSensorsValues = (sensorIds, aggregation = 'raw') => async dispatch => {
    dispatch({ type: LOAD_LATEST_SENSORS_VALUES });
    if (_.isEmpty(sensorIds)) {
        return dispatch({
            type: LOAD_LATEST_SENSORS_VALUES_FAIL
        });
    }
    try {
        const result = await dispatch(IoT.sensorsLatestValues(sensorIds, aggregation));

        return dispatch({
            type: LOAD_LATEST_SENSORS_VALUES_SUCCESS,
            result
        });
    } catch (error) {
        return dispatch({
            type: LOAD_LATEST_SENSORS_VALUES_FAIL,
            error
        });
    }
};

export const LOAD_LATEST_EQUIPMENT_SENSORS_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_LATEST_EQUIPMENT_SENSORS_VALUES';
export const LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_SUCCESS'; // eslint-disable-line max-len
export const LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_FAIL'; // eslint-disable-line max-len

export const loadLatestEquipmentSensorsValues = (functionalLocation, equipmentNumber) => {
    const sensorFilter = {
        where: {
            functionalLocation,
            equipmentNumber
        },
        include: [{
            children: [{
                relation: 'sensorMeta',
            }, {
                relation: 'sensorType',
                scope: {
                    include: [{
                        relation: 'aggregations',
                        scope: {
                            where: {
                                active: true
                            },
                            fields: ['aggregation', 'frequency'],
                        },
                    }],
                },
            }]
        }],
    };

    return async dispatch => {
        dispatch({ type: LOAD_LATEST_EQUIPMENT_SENSORS_VALUES });
        try {
            const equipmentSensors = await dispatch(MasterData.sensors(sensorFilter));
            const sensorIds = _.flatMap(equipmentSensors[0].children, 'id');
            const result = await dispatch(IoT.sensorsLatestValues(sensorIds));

            return dispatch({
                type: LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_SUCCESS,
                equipmentNumber,
                equipmentSensors: equipmentSensors[0].children,
                result
            });
        } catch (error) {
            return dispatch({
                type: LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_FAIL,
                error
            });
        }
    };
};

export const LOAD_FL_ENERGY_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_FL_ENERGY_VALUES';
export const LOAD_FL_ENERGY_VALUES_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_FL_ENERGY_VALUES_SUCCESS';
export const LOAD_FL_ENERGY_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_FL_ENERGY_VALUES_FAIL';

export function loadFunctionalLocationsEnergyValues(
    partnerNumber, functionalLocations, startTime, endTime) {
    const start = startTime || moment.utc().subtract(2, 'years').startOf('year');
    const end = endTime || moment.utc().endOf('day');

    return async (dispatch, getState) => {
        const { profile } = getState().profile;

        // take partnerNumbers as a priority, if not found take functionalLocations
        let locationFilter;
        if (partnerNumber && partnerNumber.length) {
            locationFilter = getWhereFromPermissions(profile, partnerNumber, {
                buildFunctionalLocationFilter: functionalLocations => ({
                    functionalLocation: {
                        inq: functionalLocations,
                    },
                }),
                buildPartnerNumberFilter: partnerNumber => ({
                    partnerNumber,
                }),
            });
        } else if (functionalLocations && functionalLocations.length > 0) {
            locationFilter = {
                functionalLocation: {
                    inq: functionalLocations.map(fl => fl.functionalLocation),
                },
            };
        }

        dispatch({ type: LOAD_FL_ENERGY_VALUES });
        try {
            if (!locationFilter) {
                return dispatch({
                    type: LOAD_FL_ENERGY_VALUES_FAIL
                });
            }
            let sensorValues = [];
            // Fetch sensor data types
            const sensorDataTypes = await dispatch(MasterData.sensorTypes());
            // Get energy sensor types from fetched data
            const energySensorTypes = _.filter(sensorDataTypes, sensorType => {
                return isEnergySensor(sensorType.name);
            });
            if (!energySensorTypes || energySensorTypes.length === 0) {
                return dispatch({
                    type: LOAD_FL_ENERGY_VALUES_FAIL
                });
            }
            // Fetch energy sensors from targeted FLs sensorHierarchy
            // Energy sensors should always be in group_energy type of group in hierarchy
            const sensorsFilter = {
                where: {
                    type: 'building',
                    ...locationFilter,
                },
                timestamp: moment.utc().startOf('hour').toISOString(),
                include: {
                    relation: 'children',
                    scope: {
                        where: {
                            type: 'group_energy',
                        },
                        include: {
                            relation: 'sensors',
                            scope: {
                                where: {
                                    sensorTypeId: {
                                        inq: energySensorTypes.map(sensor => sensor.id )
                                    }
                                }
                            }
                        }
                    },
                },
            };
            const buildings = await dispatch(MasterData.sensorHierarchies(sensorsFilter));
            const energySensorToBuildingMap = {};
            let energySensors = [];
            // Fetch values for those sensors
            if (buildings && buildings.length > 0) {
                buildings.forEach(building => {
                    if (building && building.children && building.children.length > 0) {
                        building.children.forEach(group => {
                            if (group && group.sensors && group.sensors.length > 0) {
                                const buildingEnergySensors = _.filter(
                                    group.sensors, sensor => sensor.sensorTypeId !== null
                                );
                                energySensors = energySensors.concat(buildingEnergySensors);

                                // Energy sensor can be mapped to a different FL, so we'll make a mapping
                                // from sensors to building to be able to tie values to building level FL
                                buildingEnergySensors.forEach(sensor => {
                                    if (sensor && sensor.id) {
                                        energySensorToBuildingMap[sensor.id] = building.functionalLocation;
                                    }
                                });
                            }
                        });
                    }
                });
                if (energySensors.length > 0) {
                    const valuesFilter = {
                        where: {},
                        order: 'timestamp ASC',
                        fields,
                    };

                    /**
                     * Get aggregations based on the sensor's granularity value
                     * Types:
                     * - energy rating sensors
                     * - all energy consumption sensors
                     * - energy consumption sensors that have only monthly data
                     */
                    const sensorsByType = {
                        energyRating: [],
                        energyConsumption: [],
                        energyConsumptionWithMonthlyData: []
                    };
                    const energyRatingSensorTypes = _.filter(sensorDataTypes, sensorType => {
                        return isEnergyRatingSensor(sensorType.name);
                    });
                    energySensors.forEach(sensor => {
                        if (_.find(energyRatingSensorTypes, { 'id': sensor.sensorTypeId })) {
                            // Energy rating is handled differently, so we gather them here
                            sensorsByType.energyRating.push(sensor.id);
                        } else {
                            // Get all monthly granularity consumption sensors
                            if (sensor.granularity === 'month') {
                                sensorsByType.energyConsumptionWithMonthlyData.push(sensor.id);
                            }
                            // Add all consumption sensors to energy consumption type
                            sensorsByType.energyConsumption.push(sensor.id);
                        }
                    });
                    valuesFilter.where.or = [];
                    if (!_.isEmpty(sensorsByType.energyRating)) {
                        valuesFilter.where.or.push({
                            sensorId: { inq: sensorsByType.energyRating },
                            timestamp: {
                                between: [
                                    start.toISOString(),
                                    end.toISOString()
                                ]
                            },
                            aggregation: 'energyRating',
                            value: { neq: null }
                        });
                    }

                    const startOfPreviousMonth = moment.utc()
                        .subtract(1, 'months')
                        .startOf('month');
                    const endOfMonthBeforePreviousMonth = moment.utc()
                        .subtract(2, 'months')
                        .endOf('month');
                    const startOfPreviousMonthYearAgo = moment.utc()
                        .subtract(1, 'years')
                        .subtract(1, 'months')
                        .startOf('month');
                    const endOfCurrentMonthYearAgo = moment.utc()
                        .subtract(1, 'years')
                        .endOf('month');
                    const startofNextMonthYearAgo = moment.utc()
                        .subtract(1, 'years')
                        .add(1, 'months')
                        .startOf('month');
                    const endOfMonthBeforePreviousMonthYearAgo = moment.utc()
                        .subtract(1, 'years')
                        .subtract(2, 'months')
                        .endOf('month');

                    /**
                     * If there are no sensors that have only monthly values, we can use daily values
                     * to calculate for example last 365 days and compare values from month ago
                     */
                    if (!_.isEmpty(sensorsByType.energyConsumption)
                        && _.isEmpty(sensorsByType.energyConsumptionWithMonthlyData)
                        && startOfPreviousMonthYearAgo.isBefore(end)
                        && endOfMonthBeforePreviousMonthYearAgo.isAfter(start)
                    ) {
                        /**
                         * Get aggregations as:
                         * Daily:
                         * - current month
                         * - month before current month
                         * - current month one year ago
                         * - month before current month one year ago
                         * Monthly:
                         * - all the other months
                         * This allows us to sum values from last 365 days and 30 days ago 365 days
                         * in common case where start and end times are in the middle of the month.
                         */

                        // Get daily values
                        valuesFilter.where.or.push({
                            sensorId: { inq: sensorsByType.energyConsumption },
                            timestamp: {
                                between: [
                                    startOfPreviousMonth.toISOString(),
                                    end.toISOString()
                                ]
                            },
                            aggregation: 'dailySum',
                            value: { neq: null }
                        });
                        valuesFilter.where.or.push({
                            sensorId: { inq: sensorsByType.energyConsumption },
                            timestamp: {
                                between: [
                                    startOfPreviousMonthYearAgo.toISOString(),
                                    endOfCurrentMonthYearAgo.toISOString()
                                ]
                            },
                            aggregation: 'dailySum',
                            value: { neq: null }
                        });
                        // Get rest of the values as monthlySum aggregation
                        valuesFilter.where.or.push({
                            sensorId: { inq: sensorsByType.energyConsumption },
                            timestamp: {
                                between: [
                                    start.toISOString(),
                                    endOfMonthBeforePreviousMonthYearAgo.toISOString()
                                ]
                            },
                            aggregation: 'monthlySum',
                            value: { neq: null }
                        });
                        valuesFilter.where.or.push({
                            sensorId: { inq: sensorsByType.energyConsumption },
                            timestamp: {
                                between: [
                                    startofNextMonthYearAgo.toISOString(),
                                    endOfMonthBeforePreviousMonth.toISOString()
                                ]
                            },
                            aggregation: 'monthlySum',
                            value: { neq: null }
                        });
                    } else {
                        // If there is at least one monthly granularity sensor,
                        // or if timeframe is non-regular, we use monthly values
                        valuesFilter.where.or.push({
                            sensorId: { inq: sensorsByType.energyConsumption },
                            timestamp: {
                                between: [
                                    start.toISOString(),
                                    end.toISOString()
                                ]
                            },
                            aggregation: 'monthlySum',
                            value: { neq: null }
                        });
                    }
                    if (valuesFilter.where.or && valuesFilter.where.or.length > 0) {
                        sensorValues = await dispatch(IoT.findWithPost(valuesFilter));
                    }
                }
            }

            return dispatch({
                type: LOAD_FL_ENERGY_VALUES_SUCCESS,
                result: sensorValues,
                energySensors,
                energySensorToBuildingMap,
                partnerNumber,
                sensorDataTypes
            });
        } catch (error) {
            return dispatch({
                type: LOAD_FL_ENERGY_VALUES_FAIL,
                error
            });
        }
    };
}

export const LOAD_ENERGY_RATING = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_ENERGY_RATING';
export const LOAD_ENERGY_RATING_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_ENERGY_RATING_SUCCESS';
export const LOAD_ENERGY_RATING_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_ENERGY_RATING_FAIL';

export const loadEnergyRating = functionalLocations => {
    if (!functionalLocations) {
        return dispatch => dispatch({
            type: LOAD_ENERGY_RATING_FAIL,
            error: "Parameter 'functionalLocations' is required.",
        });
    }

    return async dispatch => {
        dispatch({ type: LOAD_ENERGY_RATING });
        try {
            const result = await dispatch(IoT.energyRating({
                where: {
                    functionalLocation: {
                        inq: functionalLocations,
                    },
                }
            }));

            return dispatch({
                type: LOAD_ENERGY_RATING_SUCCESS,
                result,
            });
        } catch (error) {
            return dispatch({
                type: LOAD_ENERGY_RATING_FAIL,
                error,
            });
        }
    };
};

export const loadEnergyRatingKPI = partnerNumber => async dispatch => {
    dispatch({ type: LOAD_ENERGY_RATING });
    try {
        const result = await dispatch(IoT.energyRatingKPI(partnerNumber));
        return dispatch({
            type: LOAD_ENERGY_RATING_SUCCESS,
            result,
        });
    } catch (error) {
        return dispatch({
            type: LOAD_ENERGY_RATING_FAIL,
            error,
        });
    }
};

export const LOAD_CONSUMPTION_KPI = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CONSUMPTION_KPI';
export const LOAD_CONSUMPTION_KPI_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CONSUMPTION_KPI_SUCCESS';
export const LOAD_CONSUMPTION_KPI_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CONSUMPTION_KPI_FAIL';

export const loadConsumptionKPI = (partnerNumber, kpi) => async dispatch => {
    dispatch({ type: LOAD_CONSUMPTION_KPI, kpi });
    try {
        const result = await dispatch(IoT.consumptionKPI(partnerNumber, kpi));
        return dispatch({
            type: LOAD_CONSUMPTION_KPI_SUCCESS,
            kpi,
            result,
        });
    } catch (error) {
        return dispatch({
            type: LOAD_CONSUMPTION_KPI_FAIL,
            kpi,
            error,
        });
    }
};

export const LOAD_AIR_QUALITY_KPI = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_AIR_QUALITY_KPI';
export const LOAD_AIR_QUALITY_KPI_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_AIR_QUALITY_KPI_SUCCESS';
export const LOAD_AIR_QUALITY_KPI_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_AIR_QUALITY_KPI_FAIL';

export const loadAirQualityKPI = partnerNumber => async dispatch => {
    dispatch({ type: LOAD_AIR_QUALITY_KPI });
    try {
        const result = await dispatch(IoT.airQualityKPI(partnerNumber));
        return dispatch({
            type: LOAD_AIR_QUALITY_KPI_SUCCESS,
            result,
        });
    } catch (error) {
        return dispatch({
            type: LOAD_AIR_QUALITY_KPI_FAIL,
            error,
        });
    }
};

export const LOAD_ENERGY_CHART_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_ENERGY_CHART_VALUES';
export const LOAD_ENERGY_CHART_VALUES_SUCCESS =
    'CUSTOMER_PLATFORM/IoT_Values/LOAD_ENERGY_CHART_VALUES_SUCCESS';
export const LOAD_ENERGY_CHART_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_ENERGY_CHART_VALUES_FAIL';

export function loadEnergyChartValues(partnerNumber, betweens, aggregation) {
    return async (dispatch, getState) => {
        dispatch({ type: LOAD_ENERGY_CHART_VALUES });

        if (!aggregation || !betweens || !Array.isArray(betweens)) {
            return dispatch({
                type: LOAD_ENERGY_CHART_VALUES_FAIL,
                error: 'Parameters aggregation or betweens are missing.'
            });
        }

        try {
            const state = getState();
            const energySensors = _.flatten(_.values(state.values.energySensors));
            const sensorTypes = await dispatch(MasterData.sensorTypes());

            const valuesFilter = {
                where: {},
                order: 'timestamp ASC',
                fields
            };

            if (!energySensors || energySensors.length === 0 || !sensorTypes || sensorTypes.length === 0) {
                return dispatch({
                    type: LOAD_ENERGY_CHART_VALUES_FAIL,
                    error: 'No energySensors or sensorTypes.'
                });
            }

            const energyRatingSensorTypes = _.filter(
                sensorTypes,
                sensorType => isEnergyRatingSensor(sensorType.name)
            );
            // sort sensors to energyRating and energyConsumption arrays
            const sensorsByType = energySensors.reduce((accu, sensor) => {
                if (_.find(energyRatingSensorTypes, { 'id': sensor.sensorTypeId })) {
                    accu.energyRating.push(sensor.id);
                } else {
                    accu.energyConsumption.push(sensor.id);
                }
                return accu;
            }, { energyRating: [], energyConsumption: [] });

            valuesFilter.where.or = [];

            if (sensorsByType.energyRating.length !== 0) {
                // Add energyRating sensors for each between-condition
                _.forEach(betweens, between => {
                    valuesFilter.where.or.push({
                        sensorId: { inq: sensorsByType.energyRating },
                        timestamp: { between },
                        aggregation: 'energyRating',
                        value: { neq: null }
                    });
                });
            }
            if (sensorsByType.energyConsumption.length !== 0) {
                // Add other sensors for each between-condition
                _.forEach(betweens, between => {
                    valuesFilter.where.or.push({
                        sensorId: { inq: sensorsByType.energyConsumption },
                        timestamp: { between },
                        aggregation,
                        value: { neq: null }
                    });
                });
            }

            let sensorValues = [];
            if (valuesFilter.where.or && valuesFilter.where.or.length > 0) {
                sensorValues = await dispatch(IoT.findWithPost(valuesFilter));
            }

            return dispatch({
                type: LOAD_ENERGY_CHART_VALUES_SUCCESS,
                result: sensorValues,
                sensorTypes,
                energySensors,
                partnerNumber,
                aggregation,
            });
        } catch (error) {
            return dispatch({
                type: LOAD_ENERGY_CHART_VALUES_FAIL,
                error
            });
        }
    };
}


export const IMPORT_SENSOR_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/IMPORT_SENSOR_VALUES';
export const IMPORT_SENSOR_VALUES_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/IMPORT_SENSOR_VALUES_SUCCESS';
export const IMPORT_SENSOR_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/IMPORT_SENSOR_VALUES_FAIL';

export const importSensorValues = sensorValues => async dispatch => {
    dispatch({ type: IMPORT_SENSOR_VALUES });
    try {
        const result = await dispatch(IoT.importValues(sensorValues));
        dispatch({
            type: IMPORT_SENSOR_VALUES_SUCCESS,
            result,
        });
        return result;
    } catch (error) {
        dispatch({
            type: IMPORT_SENSOR_VALUES_FAIL,
            error,
        });
        throw(error);
    }
};


export default createReducerFromMapping({
    [LOAD_CLEANING]: state => ({
        ...state,
        loadingCleaning: true
    }),
    [LOAD_CLEANING_SUCCESS]: (state, action) => ({
        ...state,
        cleaningValues: _.compact([].concat(state.cleaningValues, action.result)),
        loadingCleaning: false
    }),

    [LOAD_CLEANING_FAIL]: state => ({
        ...state,
        loadingCleaning: false
    }),
    [LOAD_CONDITIONS]: (state, action) => ({
        ...state,
        loadingConditions: true
    }),
    [LOAD_CONDITIONS_SUCCESS]: (state, action) => ({
        ...state,
        conditions: {
            ...state.conditions,
            [action.functionalLocation]: mapConditionsData(action.result, action.sensors)
        },
        loadingConditions: false
    }),
    [LOAD_CONDITIONS_FAIL]: (state, action) => ({
        ...state,
        loadingConditions: false
    }),
    [LOAD_SENSOR_VALUES]: (state, action) => ({
        ...state
    }),
    [LOAD_SENSOR_VALUES_SUCCESS]: (state, action) => ({
        ...state,
        sensorValues: [].concat(state.sensorValues, action.result)
    }),
    [LOAD_SENSOR_VALUES_FAIL]: (state, action) => {
        const sensorIds = _.isArray(action.sensorIds)
            ? action.sensorIds.reduce((accu, id) => { accu[id] = []; return accu; }, {})
            : {};

        return {
            ...state,
            valuesBySensorId: {
                ...state.valuesBySensorId,
                ...sensorIds,
            },
            error: action.error,
        };
    },
    [LOAD_SENSORS_VALUES]: (state, action) => ({
        ...state,
        loadingSensorValues: true
    }),
    [LOAD_SENSORS_VALUES_SUCCESS]: (state, action) => {
        let newValues = action.sensorIds.reduce((accu, id) => { accu[id] = []; return accu; }, {});

        if (action.result && action.result.length > 0) {
            const result = _.forOwn(
                _.groupBy(action.result, value => value.sensorId),
                sensorValues => sensorValues.sort(
                    (a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
                )
            );
            newValues = { ...newValues, ...result };
        }

        return {
            ...state,
            sensorValues: [].concat(state.sensorValues, action.result),
            valuesBySensorId: Object.assign({}, state.valuesBySensorId, newValues),
            loadingSensorValues: false
        };
    },
    [LOAD_SENSORS_VALUES_FAIL]: (state, action) => ({
        ...state,
        loadingSensorValues: false
    }),
    [LOAD_EQUIPMENT_SENSORS_VALUES]: (state, action) => ({
        ...state
    }),
    [LOAD_EQUIPMENT_SENSORS_VALUES_SUCCESS]: (state, action) => ({
        ...state,
        sensorValues: [].concat(state.sensorValues, action.result),
        valuesBySensorId: _.groupBy(action.result, value => value.sensorId)
    }),
    [LOAD_EQUIPMENT_SENSORS_VALUES_FAIL]: (state, action) => ({
        ...state
    }),
    [LOAD_LATEST_SENSORS_VALUES]: (state, action) => ({
        ...state,
        loadingLatestSensorValues: true
    }),
    [LOAD_LATEST_SENSORS_VALUES_SUCCESS]: (state, action) => {
        const newValues = _.keyBy(action.result, value => value.sensorId);
        return {
            ...state,
            sensorValues: [].concat(state.sensorValues, action.result),
            latestValuesBySensorId: { ...state.latestValuesBySensorId, ...newValues },
            loadingLatestSensorValues: false
        };
    },
    [LOAD_LATEST_SENSORS_VALUES_FAIL]: (state, action) => ({
        ...state,
        loadingLatestSensorValues: false
    }),
    [LOAD_LATEST_EQUIPMENT_SENSORS_VALUES]: (state, action) => ({
        ...state
    }),
    [LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_SUCCESS]: (state, action) => ({
        ...state,
        sensorValues: [].concat(state.sensorValues, action.result),
        sensorsByEquipmentNumber: {
            ...state.sensorsByEquipmentNumber,
            [action.equipmentNumber]: action.equipmentSensors
        },
        latestValuesBySensorId: _.keyBy(action.result, value => value.sensorId)
    }),
    [LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_FAIL]: (state, action) => ({
        ...state
    }),
    [LOAD_FL_ENERGY_VALUES]: (state, action) => ({
        ...state,
        loadingEnergyValues: true
    }),
    [LOAD_FL_ENERGY_VALUES_SUCCESS]: (state, action) => {
        if (!action.result || action.result.length === 0) {
            return {
                ...state,
                loadingEnergyValues: false
            };
        }
        const newValues = {};
        const partnerValues = {};
        const newValuesBySensor = {};
        const newEnergySensors = {};

        const getSensorTypeFromSensor = (sensors, sensorTypes, sensorId) => {
            if (sensors && sensors.length > 0 && sensorTypes && sensorTypes.length > 0) {
                const sensor = _.find(sensors, { id: sensorId });
                if (sensor) {
                    const sensorType = _.find(sensorTypes, { id: sensor.sensorTypeId });
                    if (sensorType) {
                        return sensorType.name;
                    }
                }
            }
            return null;
        };
        const getValueObject = (data, sensorType, fl) => ({
            aggregation: data.aggregation,
            timestamp: data.timestamp,
            functionalLocation: fl,
            sensorId: data.sensorId,
            type: sensorType,
            value: data.value
        });
        action.result.forEach(data => {
            const dataSensorId = +data.sensorId;
            const dataSensorType = getSensorTypeFromSensor(action.energySensors, action.sensorDataTypes, dataSensorId);
            let fl;
            // If energy sensor is found from mapping (it should always be), get building FL id from there
            if (action.energySensorToBuildingMap && action.energySensorToBuildingMap[data.sensorId]) {
                fl = action.energySensorToBuildingMap[data.sensorId];
            } else {
                fl = data.functionalLocation;
            }
            const valueObject = getValueObject(data, dataSensorType, fl);

            if (fl) {
                newValues[fl] = newValues[fl] || {};
                // All sensors should be energy sensors but we might want to change this later
                if (isEnergySensor(dataSensorType)) {
                    newValues[fl].allTypes = newValues[fl].allTypes || [];
                    newValues[fl].allTypes.push(valueObject);
                    partnerValues.allTypes = partnerValues.allTypes || [];
                    partnerValues.allTypes.push(valueObject);
                }
                if (isElectricitySensor(dataSensorType)) {
                    newValues[fl].electricity = newValues[fl].electricity || [];
                    newValues[fl].electricity.push(valueObject);
                    partnerValues.electricity = partnerValues.electricity || [];
                    partnerValues.electricity.push(valueObject);
                }
                if (isDistrictHeatingSensor(dataSensorType)) {
                    newValues[fl].districtHeating = newValues[fl].districtHeating || [];
                    newValues[fl].districtHeating.push(valueObject);
                    partnerValues.districtHeating = partnerValues.districtHeating || [];
                    partnerValues.districtHeating.push(valueObject);
                }
                if (isWaterConsumptionSensor(dataSensorType)) {
                    newValues[fl].waterConsumption = newValues[fl].waterConsumption || [];
                    newValues[fl].waterConsumption.push(valueObject);
                    partnerValues.waterConsumption = partnerValues.waterConsumption || [];
                    partnerValues.waterConsumption.push(valueObject);
                }
                if (isEnergyRatingSensor(dataSensorType)) {
                    newValues[fl].energyRating = newValues[fl].energyRating || [];
                    newValues[fl].energyRating.push(valueObject);
                    partnerValues.energyRating = partnerValues.energyRating || [];
                    partnerValues.energyRating.push(valueObject);
                }
            }
            newValuesBySensor[data.sensorId] = newValuesBySensor[data.sensorId] || [];
            newValuesBySensor[data.sensorId].push(valueObject);
        });
        let energyValuesByPartner = {};
        if (action.partnerNumber) {
            energyValuesByPartner = _.cloneDeep(state.energyValuesByPartner);
            energyValuesByPartner[action.partnerNumber] = partnerValues;
        }
        if (action.energySensors && action.energySensors.length > 0) {
            action.energySensors.forEach(sensor => {
                let fl;
                if (action.energySensorToBuildingMap && action.energySensorToBuildingMap[sensor.id]) {
                    fl = action.energySensorToBuildingMap[sensor.id];
                } else {
                    fl = sensor.functionalLocation;
                }
                newEnergySensors[fl] = newEnergySensors[fl] || [];
                newEnergySensors[fl].push(sensor);
            });
        }

        return {
            ...state,
            energyValues: {
                ...state.energyValues,
                ...newValues
            },
            energyValuesBySensor: {
                ...state.energyValuesBySensor,
                ...newValuesBySensor
            },
            energySensors: {
                ...state.energySensors,
                ...newEnergySensors
            },
            energyValuesByPartner,
            loadingEnergyValues: false
        };
    },
    [LOAD_FL_ENERGY_VALUES_FAIL]: (state, action) => ({
        ...state,
        loadingEnergyValues: false
    }),
    [LOAD_ENERGY_RATING]: (state, action) => ({
        ...state,
        energyRating: {
            ...initialState.energyRating,
            loading: true,
        },
    }),
    [LOAD_ENERGY_RATING_SUCCESS]: (state, action) => ({
        ...state,
        energyRating: {
            loading: false,
            data: action.result,
        },
    }),
    [LOAD_ENERGY_RATING_FAIL]: (state, action) => ({
        ...state,
        energyRating: {
            ...state.energyRating,
            loading: false,
            error: action.error,
        },
    }),
    [LOAD_CONSUMPTION_KPI]: (state, action) => ({
        ...state,
        consumptionKPIs: {
            ...state.consumptionKPIs,
            [action.kpi]: {
                loading: true,
                data: {},
            },
        }
    }),
    [LOAD_CONSUMPTION_KPI_SUCCESS]: (state, action) => ({
        ...state,
        consumptionKPIs: {
            ...state.consumptionKPIs,
            [action.kpi]: {
                loading: false,
                data: action.result,
            },
        }
    }),
    [LOAD_CONSUMPTION_KPI_FAIL]: (state, action) => ({
        ...state,
        consumptionKPIs: {
            ...state.consumptionKPIs,
            [action.kpi]: {
                loading: false,
                error: action.error,
            },
        }
    }),
    [LOAD_AIR_QUALITY_KPI]: state => ({
        ...state,
        airQualityKPI: {
            ...state.airQualityKPI,
            loading: true,
        },
    }),
    [LOAD_AIR_QUALITY_KPI_SUCCESS]: (state, action) => ({
        ...state,
        airQualityKPI: {
            ...state.airQualityKPI,
            loading: false,
            data: action.result,
        },
    }),
    [LOAD_AIR_QUALITY_KPI_FAIL]: (state, action) => ({
        ...state,
        airQualityKPI: {
            ...state.airQualityKPI,
            loading: false,
            error: action.error,
            data: {},
        },
    }),
    [LOAD_ENERGY_CHART_VALUES]: (state, action) => ({
        ...state,
        loadingEnergyChartValues: true,
    }),
    [LOAD_ENERGY_CHART_VALUES_SUCCESS]: (state, action) => {
        if (!action.result || action.result.length === 0) {
            return {
                ...state,
                loadingEnergyChartValues: false,
            };
        }
        const initValues =
                { 'all': [], 'electricity': [], 'districtHeating': [], 'waterConsumption': [], 'energyRating': [] };

        // function to add a value to collection[aggregation][category of categories]
        const addValue = (valueObject, collection, categories) => {
            collection[action.aggregation] = collection[action.aggregation] || _.cloneDeep(initValues);
            _.forEach(
                categories,
                category => collection[action.aggregation][category].push(valueObject)
            );
        };

        // overwrite old values
        const flValues = {};
        const partnerValues = {};

        // keep the dailySums if aggregation is hourlySum
        if (action.aggregation === 'hourlySum') {
            _.forEach(
                _.keys(state.energyChartValues.byFl),
                fl => {
                    flValues[fl] = { dailySum: _.cloneDeep(state.energyChartValues.byFl[fl].dailySum) };
                }
            );
            _.forEach(
                _.keys(state.energyChartValues.byPartner),
                partner => {
                    partnerValues[partner] =
                        { dailySum: _.cloneDeep(state.energyChartValues.byPartner[partner].dailySum) };
                }
            );
        }

        // add functional locations and types to energy sensors
        const sensors = _.map(
            action.energySensors,
            sensor => ({
                ...sensor,
                functionalLocation: _.find(
                    _.keys(state.energySensors), // state.energySensors are grouped by fl
                    sensorFl => _.find(state.energySensors[sensorFl], { id: sensor.id })
                ),
                type: _.find(action.sensorTypes, { id: sensor.sensorTypeId })
            })
        );

        action.result.forEach(data => {
            const sensor = _.find(sensors, { id: +data.sensorId });
            const functionalLocation = sensor.functionalLocation || data.functionalLocation;
            const partnerNumber = action.partnerNumber;
            const type = sensor.type ? sensor.type.name : data.type;
            const valueObject = { ...data, functionalLocation, type };
            const categories = [];
            // categories for valueObject
            if (isEnergySensor(type)) {
                categories.push('all');
            }
            if (isElectricitySensor(type)) {
                categories.push('electricity');
            }
            if (isDistrictHeatingSensor(type)) {
                categories.push('districtHeating');
            }
            if (isWaterConsumptionSensor(type)) {
                categories.push('waterConsumption');
            }
            if (isEnergyRatingSensor(type)) {
                categories.push('energyRating');
            }
            // add valueObject to flValues and/or partnerValues
            if (functionalLocation) {
                flValues[functionalLocation] = flValues[functionalLocation] || {};
                addValue(valueObject, flValues[functionalLocation], categories);
            }
            if (partnerNumber) {
                partnerValues[partnerNumber] = partnerValues[partnerNumber] || {};
                addValue(valueObject, partnerValues[partnerNumber], categories);
            }
        });

        return {
            ...state,
            energyChartValues: {
                byFl: flValues,
                byPartner: partnerValues,
            },
            loadingEnergyChartValues: false,
        };
    },
    [LOAD_ENERGY_CHART_VALUES_FAIL]: (state, action) => ({
        ...state,
        loadingEnergyChartValues: false,
    }),

    [IMPORT_SENSOR_VALUES]: state => ({
        ...state,
        importingSensorValues: true,
    }),
    [IMPORT_SENSOR_VALUES_SUCCESS]: (state, action) => ({
        ...state,
        importingSensorValues: false,
    }),
    [IMPORT_SENSOR_VALUES_FAIL]: (state, action) => ({
        ...state,
        importingSensorValues: false,
    }),
}, initialState);

const mapConditionsData = (conditions, sensors) => {
    const mapData = (accu, value) => {
        const sensor = _.find(sensors, { id: parseInt(value.sensorId, 10) });
        if (sensor && sensor.sensorType) {
            accu.push({
                avg: Math.round(value.value),
                sensorName: sensor.sensorType.name,
                unit: value.unit,
                timestamp: value.timestamp,
                sensorId: value.sensorId,
            });
        }
        return accu;
    };
    return _.groupBy(_.orderBy(conditions.reduce(mapData, []), 'timestamp', 'asc'), row => row.sensorName);
};
