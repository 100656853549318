import React from 'react';
import PropTypes from 'prop-types';
import SortableTable from 'components/SortableTable/SortableTable';
import TableCell from 'components/BuildingEvents/EventsRow/EventsCell';
import CustomViewRow from './CustomViewRow';
import SkeletonText from 'components/Skeletons/SkeletonText';

const SkeletonBodyComponent = () =>
    <tbody>
        <tr>
            <TableCell>
                <SkeletonText />
            </TableCell>
            <TableCell>
                <SkeletonText />
            </TableCell>
            <TableCell>
                <SkeletonText />
            </TableCell>
            <TableCell>
                <SkeletonText />
            </TableCell>
        </tr>
    </tbody>
;

const CustomViewTable = props => {
    const { data, t, loading } = props;

    return (
        <SortableTable
            columns={[
                {
                    title: t('Name'),
                    field: 'name',
                    sortable: true,
                    width: '35%',
                },
                {
                    title: t('Type'),
                    field: 'type',
                    sortable: true,
                    align: 'left',
                    width: '20%',
                },
                {
                    title: t('Functional location'),
                    field: 'functionalLocation',
                    sortable: false,
                    align: 'left',
                    width: '25%',
                    hideOnMobile: true
                },
                {
                    title: t('Users'),
                    field: 'users',
                    sortable: false,
                    align: 'left',
                    width: '15%',
                    hideOnMobile: true
                },
                {
                    field: 'manage',
                    sortable: false,
                    align: 'left',
                    width: '5%',
                }
            ]}
            data={ data }
            RowComponent={ CustomViewRow }
            SkeletonBodyComponent={ SkeletonBodyComponent }
            stripes={ false }
            t={ t }
            loading={ loading }
            orderBy="name"
            cellPadding="12px"
        />
    );
};

CustomViewTable.displayName = 'CustomViewTable';

CustomViewTable.defaultProps = {
    loading: false,
};

CustomViewTable.propTypes = {
    data: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

export default CustomViewTable;
