import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import StandardPage from 'components/StandardPage/StandardPage';
import { scrollTo, CUSTOM_TYPE } from './utils/UserManualUtils';
import OnImagesLoaded from 'components/OnImagesLoaded/OnImagesLoaded';
import UserManualContent from './UserManualContent';
import UserManualSectionList from './UserManualSectionList';
import { UserManualHeader } from './UserManualHeader';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage';
import SkeletonText from 'components/Skeletons/SkeletonText';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import { loadData, loadAdminUserManualData } from '../../redux/modules/userManual/userManual';
import FadeTransition from 'components/FadeTransition/FadeTransition';

const PageContainer = styled.div`
    position: initial;
`;

const StyledUserManualContainer = styled.div`
    display: flex;
`;

const SectionListFill = styled.div`
    width: 25%;
    max-width: 300px;
    display: none;

    ${props => props.theme.media.landscape`
        display: block;
    `};
`;

const UserManualContentContainer = styled.div`
    padding: 9rem 3rem 3rem 3rem;
    height: 100%;
    background-color: ${props => props.theme.colors.white};
    max-width: 1100px;
    margin: 0 auto;
    flex: 1;

    ${props => props.theme.media.landscape`
        padding: ${props => props.theme.spacing.xxl};
    `};
`;

UserManualContentContainer.displayName = 'UserManualContentContainer';

export class UserManualContainer extends PureComponent {
    state = {
        initialLoading: true,
    };

    componentDidMount() {
        const { type, loadData, loadAdminUserManualData } = this.props;
        type === CUSTOM_TYPE.GENERAL_USER_MANUAL && loadData();
        type === CUSTOM_TYPE.ADMIN_USER_MANUAL && loadAdminUserManualData();
    }

    componentDidUpdate(prevProps) {
        const prevUserManual = prevProps.userManual;
        const userManual = this.props.userManual;

        if (this.state.initialLoading && prevUserManual.loading && !userManual.loading) {
            this.setState({ initialLoading: false });
        }
    }

    scrollToTitle = () => {
        const values = queryString.parse(this.props.history.location.search);
        if (values && values.title) {
            scrollTo(values.title);
        }
    };

    getUserManualData = (type, userManual) => {
        let data;
        if (type === CUSTOM_TYPE.GENERAL_USER_MANUAL) {
            data = !!userManual && !!userManual.general && userManual.general;
        }
        else if (type === CUSTOM_TYPE.ADMIN_USER_MANUAL) {
            data = !!userManual && !!userManual.admin && userManual.admin;
        }
        return data || {};
    };

    getSkeleton = () => {
        return <Fragment>
            <SkeletonText header />
            <SkeletonText style={{ margin: '2em 0', width: '20%' }} />
            <SkeletonChart height="200px" />
        </Fragment>;
    };

    render() {
        const {
            type,
            userManual,
            title,
            history,
            scroll
        } = this.props;
        const { initialLoading } = this.state;

        const data = this.getUserManualData(type, userManual);
        const dataSuccessfullyLoaded = !isEmpty(data);
        const error = !!userManual.error;
        const loading = userManual.loading || initialLoading;

        if (!loading && (error || !dataSuccessfullyLoaded)) {
            return <ErrorPage />;
        }

        return (
            <FadeTransition>
                <StandardPage noMargin>
                    <Helmet title={title} />
                    <PageContainer>
                        <UserManualSectionList
                            history={ history }
                            data={ data }
                            scroll={ scroll }
                            loading={ loading }
                        />
                        <StyledUserManualContainer>
                            <SectionListFill />
                            <UserManualContentContainer>
                                { loading && this.getSkeleton() }
                                { dataSuccessfullyLoaded && !loading && <Fragment>
                                    <UserManualHeader data={data} />
                                    <OnImagesLoaded
                                        onLoaded={() => this.scrollToTitle()}
                                        timeout={10000}>
                                        <UserManualContent data={data} />
                                    </OnImagesLoaded>
                                </Fragment> }
                            </UserManualContentContainer>
                        </StyledUserManualContainer>
                    </PageContainer>
                </StandardPage>
            </FadeTransition>
        );
    }
}

UserManualContainer.defaultProps = {
    userManual: PropTypes.shape({
        general: null,
        admin: null,
        error: null
    })
};

UserManualContainer.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf([CUSTOM_TYPE.ADMIN_USER_MANUAL, CUSTOM_TYPE.GENERAL_USER_MANUAL]),
    userManual: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        general: PropTypes.object,
        admin: PropTypes.object,
        error: PropTypes.string
    }),
    theme: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    loadData: PropTypes.func.isRequired,
    loadAdminUserManualData: PropTypes.func.isRequired,
    scroll: PropTypes.shape({
        scrollTop: PropTypes.number.isRequired,
        scrollingUp: PropTypes.bool.isRequired
    }).isRequired,
    profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    userManual: state.userManual,
    scroll: state.common.scroll,
    profile: state.profile.profile
});

const mapDispatchToProps = dispatch => ({
    loadData: () => dispatch(loadData()),
    loadAdminUserManualData: () => dispatch(loadAdminUserManualData())
});

const connector = connect(
    mapStateToProps,
    mapDispatchToProps
);

export default connector(withTheme(UserManualContainer));


