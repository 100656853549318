import React from 'react';
import styled from 'styled-components';
import 'react-select/dist/react-select.css';


const StyledSelect = styled.select`
    width: 100%;
    min-height: 350px;
    background-color: ${props => props.theme.input.background.default};
    padding: ${props => props.theme.input.padding};
    border: ${props => props.invalid ? props.theme.input.border.error : props.theme.input.border.static};
    box-shadow: ${props => props.simple ? 'none' : props.invalid ? props.theme.input.shadow.errorStatic : props.theme.input.shadow.static};
    color: ${props => props.theme.input.font.color.default};
    font-size: ${props => props.theme.input.font.mobileSize};
    font-family: ${props => props.theme.input.font.family};
    line-height: ${props => props.theme.input.lineHeight.default};
    transition: ${props => props.theme.input.transition};

    &:hover, &:focus {
        box-shadow: ${props => props.simple ? 'none' : props.invalid ? props.theme.input.shadow.errorActive : props.theme.input.shadow.active};
        border: ${props => props.invalid ? props.theme.input.border.error : props.theme.input.border.active};
    }

    &:read-only, &:read-only:hover, &:read-only:focus, &:disabled {
        opacity: 0.6;
        box-shadow: ${props => props.invalid ? props.theme.input.shadow.errorStatic : 'none'};
    }

    &:disabled {
        color: ${props => props.theme.input.font.color.disabled};
        background-color: ${props => props.theme.input.background.disabled};
    }

    ${props => props.theme.media.landscape`
        font-size: ${props => props.theme.input.font.size};
    `}
`;
StyledSelect.displayName = 'StyledSelect';

const getMultiSelectValues = event => {
    const options = event.target.options;
    const values = [];
    for (let i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
            values.push(parseInt(options[i].value, 10));
        }
    }
    return values;
};


export const InputMultiSelect = props => {

    return (
        <StyledSelect
            defaultMenuIsOpen={true}
            name={ props.id }
            disabled={ props.disabled }
            clearable={ props.clearable }
            onChange={ event => props.onChange(props.property, getMultiSelectValues(event)) }
            onBlurResetsInput={ false }
            multiple
        >
            { props.children }
        </StyledSelect>
    );
};
InputMultiSelect.displayName = 'InputMultiSelect';

export default InputMultiSelect;
