import React from 'react';
import PropTypes from 'prop-types';

import translations from 'decorators/Translations/translations';
import UserManualContainer from './UserManualContainer';
import { CUSTOM_TYPE } from './utils/UserManualUtils';

export const UserManual = ({ history, t }) =>
    <UserManualContainer
        type={CUSTOM_TYPE.GENERAL_USER_MANUAL}
        title={t('User Manual')}
        history={history} />;

UserManual.propTypes = {
    history: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

export default translations(UserManual);
