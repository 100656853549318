import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import SectionTabs from 'components/SectionTabs/SectionTabs';
import SectionTab from 'components/SectionTabs/SectionTab';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';

const MobileSelectContainer = styled.div`
    width: 100%;
    padding: 0 ${props => !props.noMobilePadding && props.theme.spacing.xs};

    ${props => props.theme.media.portrait`
        ${props => !props.left && `
            width: auto;
            min-width: 14em;
        `}
        padding: 0;
    `}

    ${props => props.theme.media.landscape`
        display: none;
    `}
`;
MobileSelectContainer.displayName = 'MobileSelectContainer';

const SectionTabSelector = ({ t, left, large, options, onTabChange, model, property, noMobilePadding }) => {
    if (!options) { return null; }

    const selected = get(model, property);

    return (
        <Fragment>
            <SectionTabs left={ left } hideOnMobile>
                {options.map(option =>
                    <SectionTab
                        key={ option.value }
                        selected={ option.value === selected }
                        onClick={ () => onTabChange(property, option.value) }
                        large={ large }
                    >
                        { option.label }
                    </SectionTab>
                )}
            </SectionTabs>
            <MobileSelectContainer left={ left } noMobilePadding={ noMobilePadding }>
                <InputSelectDropdown
                    model={ model }
                    property={ property }
                    options={ options }
                    t={ t }
                    clearable={ false }
                    onChange={ onTabChange }
                />
            </MobileSelectContainer>
        </Fragment>
    );
};

SectionTabSelector.defaultProps = {
    left: false,
    large: false,
    options: null,
    noMobilePadding: false,
};

SectionTabSelector.propTypes = {
    t: PropTypes.func.isRequired,
    onTabChange: PropTypes.func.isRequired,
    model: PropTypes.object.isRequired,
    property: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.any,
    })),
    left: PropTypes.bool,
    large: PropTypes.bool,
    noMobilePadding: PropTypes.bool,
};

export default SectionTabSelector;
