import memoizeOne from 'memoize-one';

export const SAVE_TYPES = {
    TO_COORDS: 'By coordinates',
    TO_FLOOR: 'Floor level',
    TO_BUILDING: 'Building'
};

export const BUILDING_SAVE_TYPES = { TO_BUILDING: 'Building' };

export const SUBSENSOR_SAVE_TYPES = { TO_PARENT_SENSOR: 'Uncategorized' };

export const GRANULARITIES = {
    HOUR: {
        value: 'hour',
        label: 'Hour'
    },
    DAY: {
        value: 'day',
        label: 'Day'
    },
    MONTH: {
        value: 'month',
        label: 'Month'
    }
};

export const SENSOR_APPEARANCES = {
    device: 'device',
    measuringPoint: 'measuring point',
};

export const FORM_TEXTS = memoizeOne((appearance, t) => ({
    informationSubtitle: t(`${appearance} information`),
    sensorTypeDropdown: t(`${appearance} type`),
    sensorName: t(`${appearance} name`),
    type: t(`${appearance} is synthetic`),
    saveType: t(`${appearance} location`),
    blueprintInfo: t(`You may edit the placement by re-clicking the ${appearance} and choosing another place.`),
    addTitle: t(`Add ${appearance}`),
    editTitle: t(`Edit ${appearance}`),
    useCase: t(`Use case`),
}));

export const PRESENCE_TYPES = {
    seat: 'presence_seat',
    zone: 'presence_zone',
    area: 'presence_area',
};
