import React from 'react';
import SortableTable from 'components/SortableTable/SortableTable';
import TableCell from 'components/SortableTable/SortableTableCell';
import TableRow from 'components/SortableTable/SortableTableRow';
import SkeletonText from 'components/Skeletons/SkeletonText';
import SensorRow from './SensorRow';


const SkeletonBodyComponent = () =>
    <tbody>
        <TableRow>
            <TableCell>
                <SkeletonText />
            </TableCell>
            <TableCell>
                <SkeletonText />
            </TableCell>
            <TableCell>
                <SkeletonText />
            </TableCell>
        </TableRow>
    </tbody>
;

const SensorTable = ({ t, data, loading, isPerformance }) =>
    <SortableTable
        columns={[
            {
                title: t('Sensor'),
                field: 'name',
                sortable: true
            },
            {
                title: t('Data status'),
                field: 'status',
                sortable: true,
                width: '150px'
            },
            {
                title: isPerformance ? t('Performance') : t('Value'),
                field: 'value',
                sortable: isPerformance,
                width: '150px'
            },
            {
                title: '',
                field: 'addon',
                sortable: false,
                width: '50px'
            }
        ]}
        data={ data }
        RowComponent={ SensorRow }
        SkeletonBodyComponent={ SkeletonBodyComponent }
        orderBy={ isPerformance ? 'value' : 'name' }
        order="asc"
        t={ t }
        loading={ loading }
        stripes={ false }
        cellPadding="12px"
        removeHeadOnMobile
    />;

export default SensorTable;
