import React from 'react';
import styled from 'styled-components';
import { isArray, includes } from 'lodash';

export const legendColors = props => ({
    open: props.theme.serviceOrder.openColor,
    released: props.theme.serviceOrder.releasedColor,
    'in_progress': props.theme.serviceOrder.inProgressColor,
    started: props.theme.serviceOrder.startedColor,
    completed: props.theme.serviceOrder.completedColor,
    'partly_completed': props.theme.serviceOrder.partlyCompletedColor,
    arrived: props.theme.serviceOrder.arrivedColor,
    postponed: props.theme.serviceOrder.postponedColor,
    planned: props.theme.serviceOrder.plannedColor
});

const Container = styled.ul`
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    border: ${props => props.theme.input.border.static};
    border-left: 0;
    border-right: 0;
    padding: ${props => props.theme.spacing.md};
    background: ${props => props.theme.colors.alabaster};

    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none;

    ${props => props.theme.media.portrait`
        flex-wrap: wrap;
        border: none;
        padding: 0;
        background: none;
        flex-wrap: wrap;
        overflow-x: initial;
        justify-content: center;
    `}
`;

const LegendItem = styled.li`
    display: inline-block;
    font-size: ${props => props.theme.font.size.xxs};
    line-height: 2em;
    margin-left: ${props => props.theme.spacing.md};
    color: ${props => props.disabled ? props.theme.colors.darkGray : props.theme.colors.black};
    font-weight: ${props => props.theme.font.weight.normal};
    font-family: ${props => props.theme.font.family.arial};
    padding: 0 ${props => props.theme.spacing.xs};
    border-bottom: 3px solid ${props => legendColors(props)[props.status]};

    &:first-child {
        margin-left: 0;
    }
`;

const Name = styled.span`
    white-space: nowrap;
`;

const Count = styled.span`
    margin-right: ${props => props.theme.spacing.xxs};
    font-weight: ${props => props.theme.font.weight.bold};
`;

export const getStatusValue = (statuses, counts, status) => {
    if (counts) {
        return counts.counts[statuses.indexOf(status)];
    }
    return 0;
};

const Legend = props => {
    return (
        <Container className={`legend ${props.className || ''}`}>
            { props.statuses.map(status => {
                const disabled = props.highlight && status !== props.highlight && (
                    !isArray(props.highlight) || !includes(props.highlight, status)
                );
                const slugifiedStatus = status.replace(' ', '_').toLowerCase();
                return (
                    <LegendItem
                        key={ 'legend-service-order' + slugifiedStatus }
                        status={ slugifiedStatus }
                        disabled={ disabled }
                    >
                        { /* Do not render status counts if noCounts is true. */ }
                        { !props.noCounts && <Count>
                            { getStatusValue(props.statuses, props.statusCounts, status) }
                        </Count> }
                        <Name>{ props.t(status) }</Name>
                    </LegendItem>
                );
            }) }
        </Container>
    );
};

export default Legend;
