import React from 'react';
import Helmet from 'react-helmet';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';
import translations from 'decorators/Translations/translations';
import withErrors from 'decorators/Errors/withErrors';
import Dialog from 'components/Dialog/Dialog';
import Section from 'components/Section/Section';
import StandardPage from 'components/StandardPage/StandardPage.jsx';
import Button from 'components/Button/Button';
import {
    loadTagGroups,
    upsertTagDetails,
    upsertTagGroup,
    deleteTag,
    deleteTagGroup
} from 'redux/modules/tag/tag';
import TagForm from 'components/Tags/TagForm';
import TagGroupForm from 'components/Tags/TagGroupForm';
import Header from 'containers/Application/Header/Header';
import { icons } from 'components/Icon/IconNames';
import SnackBar from 'components/SnackBar/SnackBar';
import FadeTransition from 'components/FadeTransition/FadeTransition';

import { Icon, IconContainer } from 'components/Icon/Icon';

import Pill from 'components/Pill/Pill';
import { ICON_TYPES, ICON_SIZES } from 'components/Icon/Icon';
import {
    InputSelectDropdown,
    InputLabel,
    InputRow,
} from 'components/index.js';

import {
    loadTranslations,
    addLoading,
    removeLoading,
} from 'redux/modules/index.js';

const Row = styled.div `
    margin-top: 1em;
    margin-bottom: 1em;
`;
const AddNew = styled(Button) `
    min-width: 200px;
    height: 38px;
    font-size: ${props => props.theme.font.size.xs};
    font-family: ${props => props.theme.font.family.sanchez};
`;
const TagContainer = styled.div `
    margin-top: 1em;
    margin-bottom: 1em;
    border: thin solid gray;
    padding: 0.5em;
`;
AddNew.displayName = 'AddNew';

const StyledErrorMessage = styled.h4`
    display: flex;
    justify-content: center;
    color: red;
    margin-top: 24px;
`;

const TagEditButtons = styled.div `
    display: inline-block;
    & > ${IconContainer} {
        width: 2em;
        height: 2em;
    }
`;
const TagGroupEditButtons = styled.div `
    display: inline-block;
    margin-left: 1em;
    & > div {
        margin-right: 0.5em;
    }
`;

const FlexRow = styled.div `
    display: flex;
    align-items: center;
`;
const TagRow = styled.div `
    display: flex;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    align-items: center;
    text-align: left;

    justify-content: space-between;
    border-bottom: thin solid gray;
    width: 75%;
    @media (max-width: 48em) {
        width: 100%;
    }
    & > div:first-child {
        width: 20%;
    }
    & > div:nth-child(2) {
        width: 50%;
    }
`;

const StyledPill = styled.div `

`;


const ErrorMessage = ({ t }) => <StyledErrorMessage>{t('Save Failed')}</StyledErrorMessage>;

class AdminTags extends React.Component {
    state = {
        saving: false,
        saved: false,
        selectedGroup: null,
        model: {},
        error: null,
        loading: false,
        dropdownOpen: false,
        tagValues: {}
    };

    componentDidMount() {
        // Load tag groups
        this.props.loadTagGroups({});
    }
    select = selectedOption => {
        const groupId = parseInt(selectedOption, 10);
        this.setState({ selectedGroup: this.getGroup(groupId), selectedGroupId: groupId });
    }
    getGroup = groupId => _.find(this.props.tag.groups, { id: groupId });

    getSelectedGroup = () => {
        if (!this.props.tag.groups || !this.state.selectedGroup || !this.state.selectedGroup.id) {
            return null;
        }
        return _.find(this.props.tag.groups, { id: this.state.selectedGroup.id });
    }

    openAddNewTag = () => {
        this.setState({
            formType: 'TagForm',
            dialogOpen: true,
            model: {
                name: '',
                description: '',
                type: 'boolean',
            },
            addNew: true
        });
    };

    openAddNewTagGroup = () => {
        this.setState({
            formType: 'TagGroupForm',
            dialogOpen: true,
            model: {
                title: '',
                description: '',
                division: '',
            },
            addNew: true
        });
    }

    handleFormChange = (property, value) => {
        this.setState({
            model: {
                ...this.state.model,
                [property]: value
            },
        });
    }
    handleSubmit = async () => {
        const model = this.state.model;
        if (this.state.formType === 'TagForm') {
            const data = {
                key: model.key,
                tagGroupId: this.state.selectedGroup.id,
                type: model.type,
                defaultValue: model.defaultValue
            };
            if (model.id) {
                data.id = model.id;
            }
            await this.props.upsertTagDetails(data);
            await this.props.loadTagGroups({});
            this.setState({ dialogOpen: false, model: {} });
        }
        else if (this.state.formType === 'TagGroupForm') {
            const data = {
                name: model.name,
                description: model.description,
                division: model.division
            };
            if (model.id) {
                data.id = model.id;
            }
            this.props.upsertTagGroup(data)
                .then( () => {
                    this.props.loadTagGroups({});
                    this.setState({ dialogOpen: false, model: {} });
                });
        }
    }
    closeForm = () => {
        this.setState({ dialogOpen: false });
    }
    toggleDropdown = id => {
        this.setState( state => ({ dropdownOpen: state.dropdownOpen === id ? null : id }));
    }
    deleteTag = (tagId, groupId) => {
        this.props.deleteTag(tagId, groupId);
    }
    editTag = tag => {
        this.setState({
            formType: 'TagForm',
            dialogOpen: true,
            model: {
                key: tag.key,
                defaultValue: tag.defaultValue,
                description: tag.description,
                type: tag.type,
                id: tag.id,
            }
        });
    }

    editTagGroup = tagGroup => {
        this.setState({
            formType: 'TagGroupForm',
            dialogOpen: true,
            model: {
                description: tagGroup.description,
                name: tagGroup.name,
                division: tagGroup.division,
                id: tagGroup.id,
            }
        });
    }
    deleteTagGroup = id => {
        this.props.deleteTagGroup(id);
    }
    renderTags = selectedGroup => {
        const theme = this.props.theme;
        if (!selectedGroup.tagDetails) {
            return;
        }
        return _.map(
            _.orderBy(selectedGroup.tagDetails, 'type'),
            tag => {
                return (
                    <TagRow key={tag.name}>
                        <div>
                            <StyledPill>
                                <Pill>
                                    {tag.key}
                                </Pill>
                            </StyledPill>
                        </div>
                        <div style={{ color: 'gray' }}>
                            { tag.type === 'boolean' ? <span>Boolean</span> : tag.defaultValue}
                        </div>

                        <TagEditButtons>
                            <Icon
                                name={icons.PEN}
                                iconType={ICON_TYPES.TRANSPARENT}
                                size={ICON_SIZES.MEDIUM_MINUS}
                                fill={ theme.colors.midnight }
                                onClick={ () => this.editTag(tag) } />
                            <Icon
                                name={icons.DEL}
                                iconType={ICON_TYPES.TRANSPARENT}
                                fill={ theme.colors.radicalRed }
                                size={ICON_SIZES.MEDIUM_MINUS}
                                onClick={ () => this.deleteTag(tag.id, selectedGroup.id) } />
                        </TagEditButtons>
                    </TagRow>
                );
            }
        );
    }

    render() {
        const { theme, t, tag: { groups } } = this.props;
        const selectedGroup = this.getSelectedGroup();
        const links = [
            { title: 'Back to admin homepage', icon: icons.ARROW_LEFT, to: { pathname: '/Admin' }, smallIcon: true },
            { title: 'Tagging tool', icon: icons.ADMIN_TAGS }
        ];
        return (
            <FadeTransition>
                <StandardPage>
                    <Helmet title={ t('Tagging') }/>
                    <Header t={ t } links={ links } selected="tagging tool" />

                    {
                        this.state.dialogOpen &&
                        <Dialog animate isActive onOverlayClick={this.closeForm} t={ t }>
                            { this.state.formType === 'TagForm' ?
                                <TagForm
                                    t={t}
                                    tag={this.state.model}
                                    addNew={this.state.addNew}
                                    onChange={this.handleFormChange}
                                    onSubmit={this.handleSubmit}
                                    onClose={this.closeForm}
                                    errorMessage={this.state.error && <ErrorMessage t={t} />}
                                    loading={this.state.loading}
                                /> :
                                <TagGroupForm
                                    t={t}
                                    tagGroup={this.state.model}
                                    addNew={this.state.addNew}
                                    onChange={this.handleFormChange}
                                    onSubmit={this.handleSubmit}
                                    onClose={this.closeForm}
                                    errorMessage={this.state.error && <ErrorMessage t={t} />}
                                    loading={this.state.loading}
                                />
                            }
                        </Dialog>
                    }
                    <Section style={{ paddingTop: '2em' }}>

                        <h2>{ t('Tagging tool') }</h2>

                        <FlexRow>
                            <InputRow>
                                <InputLabel text={ t('Group')} id="groupSelectorDropdown" />
                                <InputSelectDropdown
                                    property="selectedGroup"
                                    label="Group"
                                    model={ { selectedGroup: this.state.selectedGroupId } }
                                    options={ _.map(groups, group => ({ label: group.name, value: group.id })) }
                                    t={ t }
                                    clearable={ false }
                                    onChange={ (property, value) => this.select(value) }
                                />
                            </InputRow>
                            <InputRow>
                                <Button style={{ marginTop: '2em' }} onClick={ this.openAddNewTagGroup }>
                                    { t('Add new group')}
                                </Button>
                            </InputRow>
                        </FlexRow>

                        {
                            selectedGroup &&
                            <TagContainer>
                                <h3 style={{ display: 'inline-block' }}>{ selectedGroup.name }</h3>
                                <TagGroupEditButtons>
                                    <Icon
                                        name={icons.PEN}
                                        iconType={ICON_TYPES.DEFAULT}
                                        size={ICON_SIZES.SMALL}
                                        onClick={ () => this.editTagGroup(selectedGroup) } />
                                    <Icon
                                        name={icons.DEL}
                                        iconType={ICON_TYPES.TRASH_CAN}
                                        size={ICON_SIZES.SMALL}
                                        onClick={ () => this.deleteTagGroup(selectedGroup.id) } />
                                </TagGroupEditButtons>
                                { this.renderTags(selectedGroup) }
                                <Row>
                                    <Button onClick={ this.openAddNewTag }>{ t('New tag') }</Button>
                                </Row>
                            </TagContainer>
                        }
                        <SnackBar
                            variant="error"
                            visible={ this.props.tag.error || this.props.tag.groupError }
                            secondaryContent={
                                <Button
                                    color={theme.button.colors.clear}
                                    textColor={theme.colors.midnight}
                                    onClick={this.handleDismissError}
                                >
                                    OK
                                </Button>
                            }
                        >
                            { _.get(this.props.tag, 'error.message') }
                            { _.get(this.props.tag, 'groupError.message') }
                        </SnackBar>
                    </Section>
                </StandardPage>
            </FadeTransition>
        );
    }
}

const COMPONENT = 'AdminTags';

const mapStateToProps = state => ({
    loading: state.common.loading[COMPONENT] || [],
    tag: state.tag
});

const mapDispatchToProps = dispatch => {
    return {
        loadTranslations: language => dispatch(loadTranslations(language)),
        addLoading: key => dispatch(addLoading(key, COMPONENT)),
        removeLoading: key => dispatch(removeLoading(key, COMPONENT)),
        loadTagGroups: () => dispatch(loadTagGroups()),
        upsertTagDetails: props => dispatch(upsertTagDetails(props)),
        upsertTagGroup: props => dispatch(upsertTagGroup(props)),
        deleteTag: (tagId, groupId) => dispatch(deleteTag(tagId, groupId)),
        deleteTagGroup: id => dispatch(deleteTagGroup(id))
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withTheme(withErrors(translations(AdminTags))));
