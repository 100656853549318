import React from 'react';
import styled from 'styled-components';
import { placeholder } from 'polished';
import Svg from 'components/Svg/Svg';
import Loader from 'components/Loader/Loader';


const MagnifierIcon = styled(({ show, ...props }) => <Svg { ...props } />)`
    position: absolute;
    right: 0.875rem;
    top: 0.8rem;
    font-size: ${props => props.theme.font.size.lg};
    fill: ${props => props.theme.colors.rockBlue};
`;
MagnifierIcon.displayName = 'MagnifierIcon';

const LoaderWrapper = styled.div`
    position: absolute;
    right: 0.875rem;
    top: 1rem;
`;
LoaderWrapper.displayName = 'LoaderWrapper';

const Input = styled.input`
    appearance: none;
    height: 50px;
    width: 100%;
    background-color: ${props => props.theme.input.background.default};
    padding: ${props => props.theme.input.padding};
    border: ${props => props.error && props.theme.input.border.error
        || props.ok && props.theme.input.border.ok
        || props.theme.input.border.static};
    box-shadow: ${props => props.error
            ? props.theme.input.shadow.errorStatic
            : props.theme.input.shadow.searchStatic};
    color: ${props => props.theme.input.font.color.default};
    font-size: ${props => props.theme.input.font.mobileSize};
    font-family: ${props => props.theme.input.font.family};
    line-height: ${props => props.theme.input.lineHeight.default};
    transition: ${props => props.theme.input.transition};

    &:hover, &:focus {
        box-shadow: ${props => props.error
                ? props.theme.input.shadow.errorActive
                : props.theme.input.shadow.searchActive};
        border: ${props => props.error && props.theme.input.border.error
            || props.ok && props.theme.input.border.ok
            || props.theme.input.border.active};
    }
    &:disabled {
        background-color: ${props => props.theme.input.background.disabled};
    }
    ${props => placeholder({ 'color': props.theme.input.font.color.placeholder }) }

    &::-webkit-search-cancel-button {
        display: ${props => props.isLoading && 'none'};
    }

    ${props => props.theme.media.landscape`
        font-size: ${props => props.theme.input.font.size};
    `}
`;
Input.displayName = 'Input';

const Container = styled.div`
    position: relative;
    width: ${props => props.width};
`;
Container.displayName = 'Container';

class InputSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ticker: null,
            value: props.initialValue || ''
        };
    }

    handleChange(value) {
        if (value.length === 0) {
            this.props.onClear && this.props.onClear();
        }

        this.setState(prevState => {
            if (prevState.ticker !== null) {
                clearTimeout(prevState.ticker);
            }

            const ticker =
                typeof value === 'string' && value.length >= 3 ?
                    setTimeout(() => this.props.onChange(value), 500) : null;

            return {
                ticker: ticker,
                value: value
            };
        });
    }

    render() {
        const { width, className, id, placeholder, noMagnifier, loading, hasError, ok, inputRef } = this.props;
        const value = this.state.value;

        return (
            <Container width={ width } className={ className }>
                <Input
                    type="search"
                    id={ id }
                    value={ value }
                    onChange={ e => this.handleChange(e.target.value) }
                    onKeyPress={ e => { if (e.key === 'Enter') { e.preventDefault(); } } }
                    placeholder={ placeholder }
                    error={ hasError }
                    isLoading={ loading }
                    ok={ ok }
                    ref={ inputRef }
                />
                { loading && <LoaderWrapper><Loader color="GRAY" size="SMALL" /></LoaderWrapper> }
                { value.length === 0 && !noMagnifier && !loading && <MagnifierIcon name="magnifier" /> }
            </Container>
        );
    }
}

export default InputSearch;
