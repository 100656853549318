import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import memoizeOne from 'memoize-one';
import { isEmpty, keys, fromPairs, intersection, filter as ldFilter } from 'lodash';

import { GroupedServiceOrders, StatisticsType } from '../../types';
import { OrderType, STATISTICS_ORDER_VOLUMES_BY_EQUIPMENT } from 'constants/serviceCalendar';
import StatisticsBarChart from 'components/Charts/StatisticsBarChart';
import * as utils from '../../utils';
import * as data from './data';
import StatisticsChartContainer from '../../StatisticsChartContainer/StatisticsChartContainer';

const aggregateOrders = memoizeOne(data.aggregateOrders);
const filterAggregates = memoizeOne(data.filterAggregates);
const getChartData = memoizeOne(data.getChartData);

const FilterInput = styled.input`
    width: 250px;
    padding: ${props => props.theme.spacing.md} ${props => props.theme.spacing.xs};
    box-shadow: none;
    border-bottom: thin solid ${props => props.theme.colors.rockBlue};
`;

const OrderVolumes = props => {
    const { t, theme, serviceOrders, functionalLocations, equipmentTexts, statisticsType, yearControls } = props;
    const [filter, setFilter] = useState('');

    const items = statisticsType === STATISTICS_ORDER_VOLUMES_BY_EQUIPMENT ? equipmentTexts : functionalLocations;
    const orders = serviceOrders[OrderType.ORDER];
    const aggregates = aggregateOrders(orders, statisticsType, functionalLocations);
    const filtered = filterAggregates(aggregates, filter, statisticsType, items, t);
    const { categories, series } = getChartData(t, filtered, statisticsType, items);
    const max = series.reduce((acc, { data }) => acc + (data[0] || 0), 0);
    let total = filtered
        .map(([, aggregate]) => utils.getAggregateTotal(aggregate, utils.orderStatuses))
        .reduce((sum, count) => sum + count, 0);

    if (statisticsType === STATISTICS_ORDER_VOLUMES_BY_EQUIPMENT) {
        const filteredEquipment = keys(fromPairs(filtered));
        total = ldFilter(orders, order =>
            !isEmpty(intersection(filteredEquipment, order.equipmentNumber))).length;
    }

    const empty = !aggregates.length;
    return (
        <React.Fragment>
            { !empty &&
                <FilterInput
                    type="text"
                    placeholder={ t('Filter') }
                    value={ filter }
                    onChange={ e => setFilter(e.target.value) }
                />
            }
            <StatisticsChartContainer
                t={ t }
                empty={ empty }
                count={ total }
                countLabel={ t('Service Orders') }
                controls={ yearControls }
            >
                <StatisticsBarChart
                    key={ statisticsType }
                    t={ t }
                    type="bar"
                    height={ utils.CHART_HEIGHT }
                    hideLegend={ false }
                    categories={ categories }
                    noZoom
                    stacked
                    plotBorderWidth="0"
                    legendAlign="left"
                    labelStyle={ utils.getLabelStyle(theme) }
                    series={ series }
                    colors={ utils.getOrderColors(theme) }
                    yTitle={ t('Service Orders') }
                    hideDecimals
                    backgroundColor="transparent"
                    xAxis={ utils.getXAxisOptions(categories.length) }
                    yMax={ max * 1.05 }
                />
            </StatisticsChartContainer>
        </React.Fragment>
    );
};

OrderVolumes.propTypes = {
    t: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
    serviceOrders: GroupedServiceOrders.isRequired,
    functionalLocations: PropTypes.objectOf(PropTypes.object).isRequired,
    equipmentTexts: PropTypes.object.isRequired,
    statisticsType: StatisticsType,
    yearControls: PropTypes.node.isRequired,
};

export default withTheme(OrderVolumes);
