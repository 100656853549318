import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { includes } from 'lodash';

import Svg from 'components/Svg/Svg';

const Options = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
Options.displayName = 'Options';

const Option = styled.div`
    display: flex;
    font-size: ${props => props.theme.font.size.xxs};
    color: ${props => props.selected ? props.theme.colors.white : props.theme.colors.midnight};
    background-color: ${props => props.selected && props.theme.colors.cerulean};
    border: 1px solid ${props => props.selected ? 'transparent' : props.theme.colors.midnight};
    border-radius: 2px;
    margin: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.xs} 0 0;
    padding: ${props => props.theme.spacing.xxs} ${props => props.theme.spacing.xs};
    cursor: pointer;

    ${props => props.theme.media.portrait`
        font-size: ${props => props.theme.font.size.xs};
        padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.sm};
    `}
`;
Option.displayName = 'Option';

const Icon = styled(({ selected, ...props }) => <Svg {...props} />)`
    font-size: ${props => props.theme.font.size.xxs};
    margin-left: ${props => props.theme.spacing.xs};
    fill: ${props => props.selected ? props.theme.colors.white : props.theme.colors.midnight};
`;
Icon.displayName = 'Icon';

export const MultiCheck = props => {
    const { options, value, onChange } = props;

    return (
        <Options>
            { options.map(option => {
                const selected = includes(value, option.value);
                return <Option
                    key={ option.label }
                    selected={ selected }
                    onClick={() => onChange(option.value, !selected) }
                >
                    { option.label }<Icon name={ selected ? 'check' : 'plus' } selected={ selected } />
                </Option>;
            })}
        </Options>
    );
};

MultiCheck.displayName = 'MultiCheck';

MultiCheck.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.any })).isRequired,
    value: PropTypes.array,
    onChange: PropTypes.func.isRequired,
};

MultiCheck.defaultProps = {
    value: [],
};

export default MultiCheck;
