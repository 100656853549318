import React from 'react';
import SortableTable from 'components/SortableTable/SortableTable';
import TableCell from 'components/BuildingEvents/EventsRow/EventsCell';
import AlarmsRow from './AlarmsRow';
import SkeletonText from 'components/Skeletons/SkeletonText';
import SkeletonSquare from 'components/Skeletons/SkeletonSquare';

const SkeletonBodyComponent = () =>
    <tbody data-test-id="SkeletonAlarmsTable">
        <tr>
            <TableCell grow hideOnMobile>
                <SkeletonText width="20%" style={{ marginLeft: '1em' }} />
                <SkeletonText width="20%" style={{ marginLeft: '1em' }} />
            </TableCell>
            <TableCell hideOnMobile>
                <SkeletonText width="98px" />
            </TableCell>
            <TableCell noPadding alignTop hideOnMobile>
                <SkeletonSquare size="1em" style={{ margin: '1.7em 1.2em 0 1em' }} />
            </TableCell>
            <TableCell grow hideOnDesktop>
                <SkeletonText />
            </TableCell>
            <TableCell hideOnDesktop>
                <SkeletonText />
            </TableCell>
        </tr>
    </tbody>
;

const AlarmsTable = props => {
    const { data, t, loading } = props;

    return (
        <SortableTable
            columns={[
                {
                    title: t('Action'),
                    field: 'alarm',
                    sortable: true,
                    hideOnMobile: true
                },
                {
                    title: t('Latest Action Date'),
                    field: 'date',
                    sortable: true,
                    width: '150px',
                    align: 'left',
                    hideOnMobile: true
                },
                {
                    title: t('Latest Action Date'),
                    field: 'date',
                    sortable: true,
                    hideOnDesktop: true
                },
                {
                    field: 'info',
                    hideOnMobile: true
                }
            ]}
            data={ data }
            RowComponent={ AlarmsRow }
            SkeletonBodyComponent={ SkeletonBodyComponent }
            headerBackgroundColor="transparent"
            t={ t }
            showControls
            loading={ loading }
            orderBy="date"
            order="desc"
        />
    );
};

export default AlarmsTable;
