import React from 'react';
import styled from 'styled-components';
import SkeletonAnimation from './SkeletonAnimation';

const Skeleton = styled.div`
    position: relative;
    overflow: hidden;
    float: left;
    border-radius: 4px;
    width: ${props => props.size ? props.size : '40px'};
    height: ${props => props.size ? props.size : '40px'};
    background-color: ${props => props.theme.skeleton.backgroundColor};
`;

const SkeletonSquare = props =>
    <Skeleton {...props}>
        <SkeletonAnimation />
    </Skeleton>;

export default SkeletonSquare;
