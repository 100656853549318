import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'lodash';

export const Items = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    overflow-x: auto;

`;
Items.displayName = 'Items';

const areSame = (item1, item2) => {
    if (!item1.id || !item2.id) {
        return item1.name === item2.name;
    }
    return item1.id === item2.id;
};

const PerformanceItems = ({ model, property, onClick, children, disabled }) => {
    const selected = get(model, property);

    const newChildren = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                selected: !disabled && areSame(child.props.value, selected),
                property,
                onClick: disabled ? undefined : onClick
            });
        }
    });

    return <Items>{ newChildren }</Items>;
};

PerformanceItems.propTypes = {
    onClick: PropTypes.func.isRequired,
    model: PropTypes.object.isRequired,
    property: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
};

export default PerformanceItems;
