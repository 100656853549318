import React, { Fragment } from 'react';

import MaintenanceModule from 'containers/Application/Modules/MaintenanceModule/MaintenanceModule';
import BuildingAutomationModule from 'components/Modules/BuildingAutomationModule/BuildingAutomationModule';
import FunctionalLocationsModule from 'components/Modules/FunctionalLocationsModule/FunctionalLocationsModule';
import DocumentModule from 'containers/Application/Modules/DocumentModule/DocumentModule';
import ExternalDocumentModule from 'containers/Application/Modules/ExternalDocumentModule/ExternalDocumentModule';
import EquipmentModule from 'containers/Application/Modules/EquipmentModule/EquipmentModule';
import AnnouncementsModule from 'components/Modules/AnnouncementsModule/AnnouncementsModule';
import ControlRoomModule from 'components/Modules/ControlRoomModule/ControlRoomModule';
import ConditionListModule from 'components/Modules/ConditionListModule/ConditionListModule';
import FloorplanModule from '../Modules/FloorplanModule/FloorplanModule';
import EnergyModule from 'containers/Application/Modules/EnergyModule/EnergyModule';
import CleaningModule from 'components/Modules/CleaningModule/CleaningModule';
import Hero from 'components/Hero/Hero';
import Header from 'containers/Application/Header/Header';

import { Tabs, Tab } from 'components';

import {
    isServiceOrdersEnabled,
    isTechnicalTabEnabled,
    isEquipmentEnabled,
    isConditionsEnabled
} from 'utils/Data/profileData';

const type = 'UNKNOWN';
const links = [];
const context = { loadingContext: true, loading: true };
const emptyArray = [];
const emptyObject = {};

const LoadingFunctionalLocation = props => {
    const {
        features,
        t,
        partnerNumber
    } = props;

    const tabs = [];

    if (isServiceOrdersEnabled(features)) {
        tabs.push(
            <Tab title="Service Orders" key="Service Orders">
                <MaintenanceModule />
            </Tab>
        );
    }

    if (isConditionsEnabled(features)) {
        tabs.push(
            <Tab title="Conditions" key="Conditions">
                <ConditionListModule
                    t={ t }
                    sensorCategories={ emptyArray }
                    valuesBySensorId={ emptyObject }
                    functionalLocation={ emptyObject }
                    latestValuesBySensorId={ emptyObject }
                />
            </Tab>
        );
    }

    if (features && features.energyTab) {
        tabs.push(
            <Tab title="Energy" key="Energy">
                <EnergyModule
                    buildingSensors={ emptyArray }
                    buildingConditions={ emptyArray }
                    loadingParent
                />
            </Tab>
        );
    }

    if (features && features.controlRoomTab) {
        tabs.push(
            <Tab title="Control Room" key="Control Room">
                <ControlRoomModule
                    t={ t }
                    observations={ emptyArray }
                    inspections={ emptyArray }
                    alarms={ emptyArray }
                    sla={ emptyObject }
                    loading
                />
            </Tab>
        );
    }

    if (features && features.buildingAutomationTab) {
        tabs.push(
            <Tab title="Building" key="Building">
                <BuildingAutomationModule
                    t={ t }
                    sensorValues={ emptyArray }
                    buildingConditions={ emptyArray }
                    buildingSensors={ emptyArray }
                    loading
                />
            </Tab>
        );
    }

    if (features && features.floorsTab) {
        tabs.push(
            <Tab title="Floors" key="Floors">
                <FloorplanModule
                    t={ t }
                    floors={ emptyArray }
                    sensors={ emptyArray }
                    sensorValues={ emptyArray }
                    loading
                    partnerNumber={ partnerNumber }
                    features={ features }
                />
            </Tab>
        );
    }

    if (isTechnicalTabEnabled(features)) {
        tabs.push(
            <Tab title="Systems" key="Systems">
                <FunctionalLocationsModule
                    t={ t }
                    loadingChildren
                    partnerNumber={ partnerNumber }
                    childFunctionalLocations={ emptyArray }
                    title={ t('Systems') }
                />
            </Tab>
        );
    }

    if (isTechnicalTabEnabled(features)) {
        tabs.push(
            <Tab title="Technical" key="Technical">
                <FunctionalLocationsModule
                    t={ t }
                    loadingChildren
                    partnerNumber={ partnerNumber }
                    childFunctionalLocations={ emptyArray }
                    title={ t('Technical') }
                />
            </Tab>
        );
    }

    if (isEquipmentEnabled(features)) {
        tabs.push(
            <Tab title="Equipment" key="Equipment">
                <EquipmentModule equipments={ emptyArray } loadingEquipment />
            </Tab>
        );
    }

    if (features && features.files) {
        tabs.push(
            <Tab title="Documents" key="Documents">
                <DocumentModule />
            </Tab>
        );
    }

    if (features && features.documents) {
        tabs.push(
            <Tab title="External Documents" key="External Documents">
                <ExternalDocumentModule />
            </Tab>
        );
    }

    if (features && features.announcementsTab) {
        tabs.push(
            <Tab title="News" key="News">
                <AnnouncementsModule t={ t } announcements={ emptyArray } profile={ emptyObject } />
            </Tab>
        );
    }

    if (features && features.cleaningTab) {
        tabs.push(
            <Tab title="Cleaning" key="Cleaning">
                <CleaningModule
                    t={ t }
                    cleaningValues={ emptyArray }
                    floors={ emptyArray }
                />
            </Tab>
        );
    }

    return (
        <Fragment>
            <Header context={ context } t={ t } links={ links } />
            <Hero type={ type } loadingContext t={ t } partnerNumber={ partnerNumber }/>
            <Tabs t={ t }>
                { tabs }
            </Tabs>
        </Fragment>
    );
};

export default LoadingFunctionalLocation;
