import {
    colors as colorOptions,
    font,
    size,
    time,
    breakpoints,
    shadows,
    grid as gridOptions,
    zIndex,
    rootSize
} from './../definitions';
import { css } from 'styled-components';
import { lighten, darken, rem, rgba } from 'polished';

/**
 * BASICS
 */

const colors = {
    ...colorOptions,
};

/**
 * Font size
 */
const fontSize = {
    ...font.size,
    // Note
    note: font.size.xs,
    // Default text size
    text: font.size.sm,
    // Page title size
    title: font.size.xl,
    // Heading size
    heading: font.size.lg,
    // Sub heading size
    subHeading: font.size.md,
    // Huge text size
    huge: font.size.xxl
};

/**
 * Font family
 */
const fontFamily = {
    // Default heading font
    heading: font.family.sanchez,
    // Heading font for smaller font sizes
    subHeading: font.family.arial,
    // Default text font
    text: font.family.arial
};

/**
 * Font weight
 */
const fontWeight = {
    // Heading font weight
    heading: font.weight.normal,
    // Body text font weight
    normal: font.weight.normal,
    text: font.weight.normal,
    // Bold body text font weight
    bold: font.weight.bold
};

/**
 * Line height
 */
const lineHeight = {
    // Default line height
    text: font.lineHeight.md,
    // Heading line height
    heading: font.lineHeight.lg,
    // Remove leading
    reset: font.lineHeight.sm
};

/**
 * Text color
 */
const textColor = {
    // Default text color
    text: colors.black,
    // Heading text color
    heading: colors.blue,
    // Form inputs text color
    input: colors.abbey,
};

/**
 * Spacing
 */
const spacing = {
    // Default border width
    border: size.border.sm,
    // Available spacings
    xxs: size.spacing.xxs,
    xs: size.spacing.xs,
    sm: size.spacing.sm,
    md: size.spacing.md,
    lg: size.spacing.lg,
    xl: size.spacing.xl,
    xxl: size.spacing.xxl
};

/**
 * Grid
 */
const grid = {
    gutter: gridOptions.gutter,
    maxWidth: '92rem',
};

const getColumnWidth = (count, maxColumns = 12) => {
    return `calc(${count / maxColumns * 100 + '%'} - ${grid.gutter})`;
};

/**
 * Transitions
 */
const motion = {
    quick: time.quick,
    default: time.default,
    long: time.long,
    easing: 'ease-in-out',
    easeIn: 'ease-in',
    easeOut: 'ease-out'
};

/**
 * Media queries
 * https://github.com/styled-components/styled-components/blob/master/docs/tips-and-tricks.md
 */

export const media = Object.keys(breakpoints).reduce((accumulator, label) => {
    // use em in breakpoints to work properly cross-browser and support users
    // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
    const emSize = breakpoints[label] / 16;
    accumulator[label] = (...args) => css`
        @media (min-width: ${emSize}em) {
            ${css(...args)}
        }
    `;
    return accumulator;
}, {});

/**
 * COMPONENTS
 */

/**
 * Menu
 */
const menu = {
    width: '20em',
    spacingTop: size.spacing.xxl,
    itemSpacing: size.spacing.md,
    shadow: `0 0 40px 5px ${rgba(0, 0, 0, 0.15)}`
};

/**
 * Cards
 */

const cards = {
    backgroundColor: colorOptions.white,
    backgroundColorHover: darken(0.08, colorOptions.blue),
    padding: spacing.lg
};

/**
 * Charts
 */

const charts = {
    colors: [colors.emerald, colors.rockBlue, colors.cerulean],
    colorsEnergy: [colors.cerulean, colors.blue, colors.rockBlue],
    colorsTemperature: [lighten(0.1, colors.radicalRed), colors.orange],
};

/**
 * Gauge chart
 */

const gaugeChart = {
    noteColor: colors.rockBlue
};

/**
 * Section
 */

const section = {
    spacing: size.spacing.xl,
    verticalSpacing: size.spacing.md
};

/**
 * Sensor values modal
 */
const sensorValues = {
    padding: {
        mobile: size.spacing.md,
        tablet: size.spacing.xl,
        desktop: size.spacing.xl
    }
};

/**
 * Statuses
 */
const status = {
    neutralColor: colors.midnight,
    neutralOkColor: colors.geyser,
    okColor: colors.emerald,
    warningColor: colors.orange,
    alarmColor: colors.radicalRed,
    neutralLightColor: lighten(0.1, colors.geyser),
    warningLightColor: lighten(0.3, colors.orange),
    alarmLightColor: lighten(0.3, colors.radicalRed)
};

const serviceOrder = {
    openColor: colors.cerulean,
    releasedColor: colors.cerulean,
    completedColor: colors.emerald,
    arrivedColor: colors.yellow,
    startedColor: colors.orange,
    partlyCompletedColor: colors.yellow,
    inProgressColor: colors.amethyst,
    postponedColor: colors.rockBlue,
    plannedColor: colors.midnight,
    lateColor: colors.orange,
    overdueColor: colors.radicalRed,
};

/**
 * Hero
 */

const hero = {
    height: {
        default: '150px',
        mobile: '122px',
        admin: '22rem',
        buildingAdmin: '18rem', // admin height minus navigation height
        customerAdmin: '18rem', // admin height minus navigation height
        overview: '480px',
    }
};

/**
 * Button
 */

const button = {
    colors: {
        submit: colors.emerald,
        cancel: colors.transparent,
        clear: colors.white,
        remove: colors.radicalRed,
        default: colors.cerulean,
    },
    textColors: {
        submit: colors.white,
        cancel: colors.darkGray,
        clear: colors.midnight,
        clearDisabled: colors.rockBlue,
        remove: colors.white,
        default: colors.white,
    },
    borderColors: {
        submit: colors.transparent,
        cancel: colors.transparent,
        clear: colors.midnight,
        clearDisabled: colors.rockBlue,
        remove: colors.transparent,
        default: colors.transparent,
    }
};

/**
 * Blueprint
 */

const blueprint = {
    area: {
        colors: {
            default: colors.cerulean,
            available: colors.emerald,
            occupied: colors.radicalRed,
        },
        colorOptions: [colors.cerulean, colors.emerald, colors.blue],
    }
};

/**
 * Input
 */

const input = {
    border: {
        static: `1px solid ${colors.lightGray}`,
        active: `1px solid ${colors.rockBlue}`,
        error: `1px solid ${colors.radicalRed}`,
        ok: `1px solid ${colors.emerald}`,
    },
    shadow: {
        static: `inset 0 2px 3px 0 ${rgba(0, 0, 0, 0.1)}`,
        active: `0 2px 20px 0 ${rgba(0, 0, 0, 0.05)}`,
        errorStatic: `inset 0 2px 3px 0 ${rgba(colors.radicalRed, 0.1)}`,
        errorActive: `0 2px 20px 0 ${rgba(colors.radicalRed, 0.05)}`,
        searchStatic: 'none',
        searchActive: `0 2px 20px 0 ${rgba(0, 0, 0, 0.05)}`,
    },
    font: {
        color: {
            default: colors.abbey,
            placeholder: colors.rockBlue,
            disabled: colors.rockBlue,
        },
        size: font.size.xs,
        mobileSize: font.size.sm,
        family: font.family.arial,
    },
    padding: `${size.spacing.md} ${rem(0.875 * rootSize)}`,
    lineHeight: {
        default: font.lineHeight.sm,
        textarea: font.lineHeight.lg,
    },
    background: {
        default: colors.white,
        disabled: colors.alabaster,
    },
    transition: `border, box-shadow ${motion.default} ${motion.easing}`,
};

const navigation = {
    height: '4rem',
    sideWidth: '310px',
    sideTabletWidth: '255px',
    sideSmallWidth: '82px',
    transition: '0.2s cubic-bezier(0.645, 0.045, 0.355, 1)',
    spacing: '40px',
    tabletSpacing: '24px'
};

const skeleton = {
    backgroundColor: colors.lightGray,
    effectColor: colors.alabaster,
};


const theme = {
    fontSize,
    fontFamily,
    fontWeight,
    lineHeight,
    textColor,
    spacing,
    grid,
    getColumnWidth,
    menu,
    motion,
    media,
    shadows,
    status,
    serviceOrder,
    gaugeChart,
    section,
    cards,
    charts,
    sensorValues,
    hero,
    button,
    blueprint,
    zIndex,
    input,
    navigation,
    skeleton,
};

export default theme;
