import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import memoizeOne from 'memoize-one';
import { includes } from 'lodash';

import CalendarFilters from 'components/Calendar/CalendarFilters/CalendarFilters';
import translations from 'decorators/Translations/translations';
import CalendarMonthSelector from 'components/Calendar/CalendarMonthSelector';
import { getServiceOrderListVariants } from 'redux/modules/service/selectors/serviceOrders';
import {
    loadAllServiceOrders,
    loadFunctionalLocations,
    loadServiceOrders,
    addLoading,
    removeLoading,
    setStatisticsView,
} from 'redux/modules';
import { loadEquipmentTexts } from 'redux/modules/customer/equipments.js';
import * as utils from '../../utils';
import { getUniqueFunctionalLocations, getUniqueEquipment } from 'utils/Data/serviceOrders';
import { OrderType, ViewMode, CONFIGURATION_PROPERTY, DEFAULT_COLUMNS } from 'constants/serviceCalendar';
import CalendarBody from 'components/Calendar/CalendarBody';
import PlainSection from 'components/Section/PlainSection';
import CalendarMeters from 'components/Calendar/CalendarMeters/CalendarMeters';
import { findPartnerNumberForFL } from 'utils/Data/partners';

const getMonthlyCounts = memoizeOne(utils.getMonthlyCounts);

const COMPONENT = 'ServiceOrders';

export const ServiceOrders = ({
    activeFilter,
    addLoading,
    day,
    functionalLocations,
    equipmentTexts,
    history,
    loadAllServiceOrders,
    loadFunctionalLocations,
    loadEquipmentTexts,
    loading,
    loadingFLs,
    loadingKey,
    loadServiceOrders,
    location,
    match,
    months,
    query,
    removeLoading,
    serviceOrders,
    t,
    year,
    setStatisticsView,
    listConfiguration,
    profile,
}) => {
    const { params: { functionalLocationId, equipmentNumber } } = match;
    const partnerNumber = !functionalLocationId || match.params.partnerNumber && match.params.partnerNumber !== 'all'
        ? match.params.partnerNumber : findPartnerNumberForFL(functionalLocations[functionalLocationId], profile);


    useEffect(() => {
        const start = moment(year, 'YYYY').startOf('year');
        const end = moment(start).endOf('year');

        (functionalLocationId ?
            loadServiceOrders(partnerNumber, functionalLocationId, equipmentNumber, start, end, loadingKey) :
            loadAllServiceOrders(start, end, partnerNumber, loadingKey)
        ).then(({ result }) => {
            const uniqueFLs = getUniqueFunctionalLocations(result);
            const uniqueEquipment = getUniqueEquipment(result);
            addLoading(loadingKey, COMPONENT);
            Promise.all([
                loadFunctionalLocations(uniqueFLs),
                loadEquipmentTexts(uniqueEquipment)
            ])
                .then(() => removeLoading(loadingKey, COMPONENT))
                .catch(() => removeLoading(loadingKey, COMPONENT));
        });
    }, [partnerNumber, year]);

    // Added a check here, because after page refresh sometimes partnerNumber is resolved to null
    if (!partnerNumber) {
        return null;
    }
    const monthlyCounts = getMonthlyCounts(serviceOrders.filtered);
    const statusCounts = utils.getStatusCounts(serviceOrders.selected);
    const options = utils.getFilterOptions(
        serviceOrders.selected, serviceOrders.all, activeFilter, functionalLocations, t
    );

    return (
        <React.Fragment>
            <PlainSection>
                <CalendarFilters
                    t={ t }
                    options={ options }
                    activeFilter={ activeFilter }
                    history={ history }
                    location={ location }
                    loading={ loading }
                    loadingFLs={ loadingFLs }
                />
            </PlainSection>
            <PlainSection>
                <CalendarMonthSelector
                    year={ year }
                    months={ months }
                    day={ day }
                    counts={ monthlyCounts }
                    loading={ loading }
                    disableMonths={ query.mode === ViewMode.CALENDAR }
                />
            </PlainSection>
            <PlainSection>
                <CalendarMeters
                    t={ t }
                    loading={ loading }
                    statusCounts={ statusCounts }
                />
            </PlainSection>
            <CalendarBody
                activeFilter={ activeFilter }
                functionalLocations={ functionalLocations }
                equipmentTexts={ equipmentTexts }
                loading={ loading }
                loadingFLs={ loadingFLs }
                monthlyCounts={ monthlyCounts }
                orders={ serviceOrders }
                orderType={ OrderType.ORDER }
                partnerNumber={ partnerNumber }
                year={ year }
                query={ query }
                setStatisticsView={ setStatisticsView }
                showServiceOrder={ utils.showServiceOrder(location, query, history) }
                listConfiguration={ listConfiguration }
            />
        </React.Fragment>
    );
};

ServiceOrders.propTypes = {
    year: PropTypes.number.isRequired,
    months: PropTypes.arrayOf(PropTypes.number).isRequired,
    day: PropTypes.number,
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            partnerNumber: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
        search: PropTypes.string.isRequired,
    }).isRequired,
    loadServiceOrders: PropTypes.func.isRequired,
    loadAllServiceOrders: PropTypes.func.isRequired,
    serviceOrders: PropTypes.shape({
        all: PropTypes.arrayOf(PropTypes.object).isRequired,
        filtered: PropTypes.arrayOf(PropTypes.object).isRequired,
        selected: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    loadingFLs: PropTypes.bool.isRequired,
    activeFilter: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    functionalLocations: PropTypes.objectOf(PropTypes.object).isRequired,
    equipmentTexts: PropTypes.object.isRequired,
    query: PropTypes.object.isRequired,
    loadingKey: PropTypes.string.isRequired,
    setStatisticsView: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const { location, match } = ownProps;
    const query = utils.parseQuery(location.search);
    const year = Number.parseInt(query.year, 10);
    const months = utils.getSelectedMonths(query);
    const day = query.day ? Number.parseInt(query.day, 10) : undefined;
    const activeFilter = utils.getActiveFilter(query);
    const { partnerNumber, functionalLocationId, equipmentNumber } = match.params;
    const loadingKey = utils.getLoadingKey(year, partnerNumber, functionalLocationId, equipmentNumber);
    const loading = !!state.serviceOrders.loading[loadingKey];
    const loadingFLs = loading || includes(state.common.loading[COMPONENT], loadingKey);
    const listConfiguration = state.profile.profile[CONFIGURATION_PROPERTY[OrderType.ORDER]]
        || DEFAULT_COLUMNS[OrderType.ORDER];
    return {
        year,
        months,
        day,
        serviceOrders: getServiceOrderListVariants(state, { year, months, day, match, activeFilter, loadingFLs }),
        loading,
        loadingFLs,
        activeFilter,
        functionalLocations: state.functionalLocations.functionalLocations,
        equipmentTexts: state.equipments.equipmentTexts,
        query,
        loadingKey,
        listConfiguration,
        profile: state.profile.profile,
    };
};

const mapDispatchToProps = {
    loadServiceOrders,
    loadAllServiceOrders,
    loadFunctionalLocations,
    loadEquipmentTexts,
    addLoading,
    removeLoading,
    setStatisticsView,
};

export default connect(mapStateToProps, mapDispatchToProps)(translations(ServiceOrders));
