import React from 'react';
import 'powerbi-client';
import * as models from 'powerbi-models';
import styled from 'styled-components';

const StyledPowerBIEmbed = styled.div`
    height: 800px;
`;

class PowerBIEmbed extends React.Component {
    constructor(props) {
        super(props);
        this.StyledPowerBIEmbed = React.createRef();
    }

    componentDidMount() {
        if (this.props.embedUrl) {
            this.embed();
        }
    }

    componentWillUnmount() {
        window.powerbi.reset(this.StyledPowerBIEmbed.current);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.embedUrl !== this.props.embedUrl) {
            this.embed();
        }
    }

    embed() {
        const {
            id,
            pageName,
            filters,
            pageView,
            datasetId,
            permissions,
            viewMode,
            action,
            dashboardId,
            height,
            width,
            embedUrl,
            uniqueId,
            type,
            accessToken,
            tokenType,
            groupId,
            background,
            bookmarksPaneEnabled,
            commands,
            customLayout,
            extensions,
            filterPaneEnabled,
            hideErrors,
            layoutType,
            navContentPaneEnabled,
            useCustomSaveAsDialog,
            visualSettings,
        } = this.props;

        const config = {
            id,
            pageName,
            filters,
            pageView,
            datasetId,
            permissions,
            viewMode,
            action,
            dashboardId,
            height,
            width,
            settings: {
                background,
                bookmarksPaneEnabled,
                commands,
                customLayout,
                extensions,
                filterPaneEnabled,
                hideErrors,
                layoutType,
                navContentPaneEnabled,
                useCustomSaveAsDialog,
                visualSettings,
            },
            embedUrl,
            uniqueId,
            type,
            accessToken,
            tokenType,
            groupId,
        };

        if (models.validateReportLoad(config) !== undefined) {
            return;
        }

        window.powerbi.embed(this.StyledPowerBIEmbed.current, config);
    }

    render() {
        return (
            <StyledPowerBIEmbed ref={this.StyledPowerBIEmbed}/>
        );
    }
}

export default PowerBIEmbed;
