import React, { PureComponent } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withTheme } from 'styled-components';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import Loader from 'components/Loader/Loader.jsx';

class SimpleLineChart extends PureComponent {
    getConfig = () => {
        const { series, theme, lineWidth, lineShadow } = this.props;
        const chartWidth = this.getWidth();
        const chartHeight = this.getHeight();
        const color = this.props.color || theme.colors.rockBlue;

        return {
            chart: {
                type: 'spline',
                width: chartWidth,
                height: chartHeight,
                margin: [0, 0, 5, 0],
                spacing: [0, 0, 0, 0]
            },
            colors: [color],
            yAxis: {
                visible: false,
                startOnTick: false,
                endOnTick: false
            },
            xAxis: {
                visible: false
            },
            tooltip: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            title: {
                text: ''
            },
            plotOptions: {
                series: {
                    lineWidth,
                    states: {
                        hover: {
                            enabled: false
                        }
                    },
                    marker: {
                        enabled: false,
                    },
                    shadow: lineShadow,
                    clip: false
                }
            },
            series
        };
    };

    hasData = () => {
        const { series } = this.props;
        if (!series) { return false; }
        const dataLength = _.sum(_.flatten(series.map(series => { return _.map(series.data, d => { return d.y; }); })));
        return dataLength !== 0;
    };

    getWidth = () => this.props.width ?
        this.props.width > window.innerWidth ? window.innerWidth : this.props.width : null;
    getHeight = () => this.props.height;

    getSkeletonText = () =>
        this.props.loading ? <Loader color='BLUE' size='LARGE' /> :
        !this.hasData() ? this.props.t('No data available') : undefined;

    render() {
        const skeletonText = this.getSkeletonText();
        const chartWidth = this.getWidth();
        const chartHeight = this.getHeight();

        if (skeletonText !== undefined) {
            return (
                <SkeletonChart width={ chartWidth && `${chartWidth}px` } height={ chartHeight && `${chartHeight}px` }>
                    { skeletonText }
                </SkeletonChart>
            );
        }

        return <HighchartsReact
            highcharts={Highcharts}
            options={this.getConfig()}
        />;
    }
}

SimpleLineChart.propTypes = {
    t: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
    series: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    loading: PropTypes.bool,
    lineWidth: PropTypes.number,
    lineShadow: PropTypes.oneOfType([
        PropTypes.shape({
            color: PropTypes.string,
            offsetX: PropTypes.number,
            offsetY: PropTypes.number,
            opacity: PropTypes.number,
            width: PropTypes.number
        }),
        PropTypes.bool
    ]),
};

SimpleLineChart.defaultProps = {
    loading: false,
    lineWidth: 3,
    lineShadow: false
};

export default withTheme(SimpleLineChart);
