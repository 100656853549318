import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { rgba } from 'polished';

import Svg from '../Svg/Svg';
import HeroImage from './HeroImage';
import { flIcons } from 'utils/Data/functionalLocations';
import { icons } from 'components/Icon/IconNames';
import { canEditBuilding, canEditPartner } from 'utils/Data/profileData';
import Loader from 'components/Loader/Loader';
import SimpleDropdown from 'components/SimpleDropdown/SimpleDropdown';

const HeroContent = styled.div`
    position: relative;
    display: none;
    height: ${props => props.theme.hero.height.default};
    width: 100%;
    background-color: ${props => props.theme.colors.midnight};
    justify-content: space-between;
    z-index: ${props => props.theme.zIndex('hero')};
    max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});
    margin: 0 auto;

    ${props => props.theme.media.portrait`
        display: flex;
    `}
`;

const Title = styled.h1`
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.font.size.lg};
    overflow: hidden;
    max-height: 47px;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${props => props.theme.media.desktop`
        font-size: ${props.theme.text.h1};
    `}
`;

Title.displayName = 'Title';

const Background = styled.div`
    width: calc(25% - ${props => props.theme.spacing.md});
    flex-shrink: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => rgba(props.theme.colors.white, 0.12)};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(75% - 2*2em - 95px);
    flex-grow: 1;
    margin: 0 2em;
`;

const Address = styled.div`
    font-family: ${props => props.theme.font.family.arial};
    font-size: ${props => props.theme.fontSize.xs};
    color: ${props => props.theme.colors.rockBlue};
    line-height: ${props => props.theme.font.lineHeight.md};
`;

const Ctas = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    border-left: 1px solid ${props => rgba(props.theme.colors.white, 0.20)};
    padding: 0 2em;
`;

const CtaButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fontSize.md};
    border-radius: 50%;
    width: 30px;
    min-width: 30px;
    height: 30px;
    cursor: pointer;
    transition: all .2s ease-in-out;
    padding-top: 3px;
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.midnight};

    &:hover {
        transform: scale(1.125);
    }
`;

const CtaSelector = styled.div`
    position: absolute;
    top: calc(50% + 18px);
    right: 0;
`;

const Cta = styled(Link)`
    font-size: ${props => props.theme.fontSize.xs};
    color: ${props => props.theme.colors.black};
    padding: ${props => props.theme.spacing.md};
    cursor: pointer;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Icon = styled(Svg)`
    fill: ${props => props.theme.colors.white};
    font-size: 3rem;
`;

const Meta = styled.div`
    padding-top: ${props => props.theme.spacing.md};
    display: flex;
    flex-wrap: wrap;
`;

const MetaItem = styled.span`
    white-space: nowrap;
`;

const MetaKey = styled.span`
    color: ${props => props.theme.colors.rockBlue};
    font-size: ${props => props.theme.font.size.xxxs};
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
`;

const MetaValue = styled.span`
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.font.size.xxs};
    letter-spacing: 0.3px;
    margin-right: ${props => props.theme.spacing.md};
`;

const getBaseLinkForBuildingEdit = functionalLocation =>
    `/Admin/Buildings/Edit/${functionalLocation.functionalLocation}`;

const getBaseLinkForFLTab = (partnerNumber, functionalLocation) =>
    `/${partnerNumber}/FunctionalLocation/${functionalLocation}`;

const renderIcon = type => {
    const icon = flIcons[type] || icons[type];

    if (!icon) {
        return null;
    }

    return <Icon name={ icon } />;
};

const Hero = props => {
    const {
        t,
        partnerNumber,
        functionalLocation,
        title,
        loadingContext,
        customImageElement,
        type,
        showAddress,
        showNewServiceRequest,
        profile,
        isBuilding,
        isOverview,
        subTitle,
        meta,
        heroImage,
        showContactCaverion,
    } = props;

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const showPartnerEdit = isOverview && profile && canEditPartner(profile.role) && partnerNumber !== 'all';
    const showBuildingEdit = isBuilding && profile && canEditBuilding(profile.role);
    const showCTAs = showPartnerEdit || showBuildingEdit || showNewServiceRequest || false;
    const hasCTAs = !!showCTAs;

    const renderPartnerCTAs = () => {
        const link = `/Admin/Partners/Edit/${partnerNumber}`;
        return <Fragment>
            <Cta to={ `${link}?tab=configuration` } data-test-id="LinkToEditConfiguration">
                { t('Configuration') }
            </Cta>
            <Cta to={ `${link}?tab=customer announcements` } data-test-id="LinkToEditAnnouncements">
                { t('Announcements') }
            </Cta>
        </Fragment>;
    };

    const renderBuildingCTAs = () => {
        const baseLink = getBaseLinkForBuildingEdit(functionalLocation);
        return <Fragment>
            <Cta to={ `${baseLink}?tab=configuration` } data-test-id="LinkToEditConfiguration">
                { t('Configuration') }
            </Cta>
            <Cta to={ `${baseLink}?tab=floors and sensors` } data-test-id="LinkToEditSensors">
                { t('Floors & Sensors') }
            </Cta>
            <Cta to={ `${baseLink}?tab=import values` } data-test-id="LinkToImportValues">
                { t('Import values') }
            </Cta>
            <Cta to={ `${baseLink}?tab=news` } data-test-id="LinkToEditAnnouncements">
                { t('News') }
            </Cta>
        </Fragment>;
    };

    if (loadingContext) {
        return (
            <HeroContent>
                <Background><Loader color='GRAY' size='MEDIUM' /></Background>
            </HeroContent>
        );
    }

    const addressText = subTitle || (functionalLocation && functionalLocation.address ?
        functionalLocation.address + (functionalLocation.city && ', ' + functionalLocation.city) :
        functionalLocation ? functionalLocation.city : '') || '';

    let newServiceRequestTo, contactCaverionTo;

    if (functionalLocation) {
        newServiceRequestTo = `${getBaseLinkForFLTab(partnerNumber, functionalLocation.functionalLocation)}/ServiceRequest?tab=new service request`;
        contactCaverionTo = `${getBaseLinkForFLTab(partnerNumber, functionalLocation.functionalLocation)}/ContactCaverion?tab=contact caverion`;
    } else {
        newServiceRequestTo = `/${partnerNumber}/ServiceRequest`;
        contactCaverionTo = `/${partnerNumber}/ContactCaverion`;
    }

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    return (
        <HeroContent data-test-id="FunctionalLocationHero">
            { heroImage && <HeroImage image={ heroImage } />}
            { !heroImage && <Background>
                { type && renderIcon(type) }
                { customImageElement }
            </Background> }
            <Content className="hero-contents">
                <Title data-test-id="hero-title" title={ title }>{ title }</Title>
                { (showAddress || subTitle) && <Address>{ addressText }</Address> }
                { meta && meta.length > 0 && <Meta> { meta.map(item =>
                    <MetaItem key={ item.key }>
                        <MetaKey>{item.key}</MetaKey> <MetaValue>{item.value}</MetaValue>
                    </MetaItem>
                )}</Meta> }
            </Content>
            { hasCTAs && <Ctas>
                <CtaButton onClick={ toggleDropdown } data-test-id="HeroActionButton">&#8943;</CtaButton>
                { dropdownOpen && <CtaSelector>
                    <SimpleDropdown>
                        { showBuildingEdit && renderBuildingCTAs() }
                        { showPartnerEdit && renderPartnerCTAs() }
                        { showNewServiceRequest &&
                            <Cta to={ newServiceRequestTo } onClick={ toggleDropdown }>
                                { t('New Service Request') }
                            </Cta>
                        }
                        { showContactCaverion &&
                            <Cta to={ contactCaverionTo } onClick={ toggleDropdown }>
                                { t('Contact Caverion') }
                            </Cta>
                        }
                    </SimpleDropdown>
                </CtaSelector> }
            </Ctas> }
        </HeroContent>
    );
};

export default React.memo(Hero);
