import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { transparentize } from 'polished';
import Svg from 'components/Svg/Svg';
import Loader from 'components/Loader/Loader';

/**
 * Logo fade in
 * Filter is not supported in IE but as it is slow browser anyway
 * it really doesn't matter. Animation should be nice with it anyway.
 */
const fadeIn = keyframes`
    0% {
        transform: scale(0.65);
        filter: blur(30px);
        opacity: 0;
    }
    20% {
        transform: scale(1);
        opacity: 1;
        filter: blur(0);
    }
    85% {
        transform: scale(1);
        opacity: 1;
        filter: blur(0);
    }
    100% {
        transform: scale(1);
        opacity: 0;
        filter: blur(20px);
    }
`;

// If there is logo we create some delay with keyframes
const lettersWithLogo = keyframes`
    60% {
        opacity: 0;
        transform: scale(0.25);
        filter: blur(2px);
    }
    80% {
        opacity: 1;
        transform: scale(1);
        filter: blur(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
        filter: blur(0);
    }
`;

const letters = keyframes`
    0% {
        opacity: 0;
        transform: scale(0.25);
        filter: blur(2px);
    }
    50% {
        opacity: 1;
        transform: scale(1);
        filter: blur(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
        filter: blur(0);
    }
`;

const Container = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.blue};
`;

/**
 * We enhance animations with hardware acceleration enforced with translateZ
 */
const Wrapper = styled.div`
    max-width: 500px;
    width: 65%;
    transform: translateZ(0);
    text-align: center;
`;

const Logo = styled.div`
    width: 100%;
    transform: scale(0.65);
    filter: blur(30px);
    opacity: 0;
    animation: ${fadeIn} 7s cubic-bezier(0,.99,0,.99) 0.5s infinite;
    will-change: transform, opacity, filter;
`;

const Spinner = styled.div`
    margin-bottom: ${props => props.theme.spacing.md};
`;

const Text = styled.div`
    color: ${props => transparentize(0.15, props.theme.colors.blue)};
    margin: 0 8%;
    font-size: 14px;
    text-transform: uppercase;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    span {
        opacity: 0;
        animation:
            ${props => props.showLogo ? lettersWithLogo : letters}
            ${props => props.showLogo ? '3.5s' : '1.75s'}
            ease-in-out
            infinite
            alternate;
    }
    ${props => {
    /**
     * Set delay for each letter
     */
    let styles = '';
    for (let i = 0; i < 20; i++) {
        styles += `
            span:nth-child(${i}) {
                animation-delay: ${i * 0.175 + 0.5}s;
            }
        `;
    }
    return css`${styles}`;
}
    }
`;

const Loading = ({
    loadingText = 'Loading',
    showLogo = true
}) => {
    // Wrap characters with span to be able to target them with css
    const getLoadingText = text => {
        if (typeof text === 'string') {
            const characters = text.split('');
            return characters.map((character, idx) =>
                <span key={ character + idx }>{ character }</span>);
        }
        return null;
    };

    return (
        <Container data-test-id="loading">
            <Wrapper>
                { showLogo
                    ? <Logo>
                        <Svg name="caverion_logo" width="100%" height="100%" fill="currentColor" />
                    </Logo>
                    : <Spinner>
                        <Loader size="LARGE"/>
                    </Spinner>
                }
                <Text showLogo={ showLogo }>
                    { getLoadingText(loadingText) }
                </Text>
            </Wrapper>
        </Container>
    );
};

export default Loading;
