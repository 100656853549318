import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import FLAnnouncements from 'components/Announcement/FLAnnouncements';
import FLAnnouncement from 'components/Announcement/FLAnnouncement';

import { showModal } from 'redux/modules/modal/modal';
import { MODALTYPE } from 'components/Modal/ModalTypes';
import { sortAnnouncements } from 'components/Announcement/utils';
import { canEditBuilding } from 'utils/Data/profileData';

const CreateNewLink = styled(Link)`
    font-size: ${props => props.theme.fontSize.xxxs};
    color: ${props => props.theme.colors.darkGray};
    font-weight: ${props => props.theme.fontWeight.bold};
    font-family: ${props => props.theme.font.family.arial};
    letter-spacing: 1px;
    text-transform: uppercase;
`;

const AnnouncementsModule = props => {
    const {
        t,
        announcements,
        showModal,
        profile,
        functionalLocationId
    } = props;

    const toggleAnnouncementModal = announcement => {
        showModal(MODALTYPE.ANNOUNCEMENT_MODAL, null, null, { announcement: announcement });
    };

    const sortedAnnouncements = sortAnnouncements(announcements)
        .map(announcement =>
            <FLAnnouncement
                t={ t }
                announcement={ announcement }
                key={ announcement.id }
                onClick={ () => toggleAnnouncementModal(announcement) } /> );

    return (
        <Section>
            <SectionHeader noBorder title={ t('News') } t={ t }>
                { canEditBuilding(profile.role) &&
                    <CreateNewLink to={ `/Admin/Buildings/Edit/${functionalLocationId}?tab=news` }>
                        { t('Create Announcement') }&nbsp;+
                    </CreateNewLink>
                }
            </SectionHeader>
            <FLAnnouncements>
                { sortedAnnouncements }
            </FLAnnouncements>
        </Section>
    );
};

const mapDispatchToProps = dispatch => ({
    showModal: (modalType, preConditions, onSubmitAction, passedProps) =>
        dispatch(showModal(modalType, preConditions, onSubmitAction, passedProps))
});

const connector = connect(null, mapDispatchToProps);
export default connector(AnnouncementsModule);
