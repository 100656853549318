import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Svg from 'components/Svg/Svg';
import { calculateStartEnd, calculateStartEndForMobile } from './utils';

import styled from 'styled-components';

const StyledPager = styled.nav`
    display: flex;
    justify-content: ${props => props.center && 'center'};
    font-family: ${props => props.theme.fontFamily.heading};
    color: ${props => props.theme.colors.rockBlue};
`;

const PagerButton = styled.button`
    padding-top: 2px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.selected ? props.theme.colors.midnight : 'transparent'};
    color: ${props => props.selected ? props.theme.colors.white : props.theme.colors.midnight};
    border-radius: 50%;
    width: 2em;
    min-width: 2em;
    height: 2em;
    cursor: ${props => props.selected && 'default'};
    border: none;
    font-size: ${props => props.theme.font.size.sm};

    &:hover {
        background-color: ${props => props.theme.colors.midnight};
        color: ${props => props.theme.colors.white};
        cursor: pointer;
    }
`;

PagerButton.displayName = 'PagerButton';

const ArrowButton = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2em;
    min-width: 2em;
    height: 2em;
    border: 1px solid ${props => props.enabled ? props.theme.colors.midnight : props.theme.colors.rockBlue};
    border-radius: 50%;
    margin: 0 0.5em;
    cursor: ${props => props.enabled ? 'pointer' : 'default'};
`;

ArrowButton.displayName = 'ArrowButton';

const ArrowIcon = styled(({ enabled, ...rest }) => <Svg {...rest} />)`
    fill: ${props => props.enabled ? props.theme.colors.midnight : props.theme.colors.rockBlue};
`;

const Dots = styled.span`
    padding-top: 10px;
    width: 2em;
    text-align: center;
`;

Dots.displayName = 'Dots';

const renderArrowIcon = (enabled, right) => {
    const text = right ? 'Next page' : 'Previous page';
    const icon = right ? 'fa-long-arrow-right' : 'fa-long-arrow-left';

    return <ArrowIcon enabled={ enabled } name={ icon } alt={ text } />;
};

const renderArrowLink = (page, enabled, right, onChange) =>
    <ArrowButton type="button" enabled={enabled} onClick={() => onChange(page)}>
        { renderArrowIcon(enabled, right)}
    </ArrowButton>;

const renderPageLink = (currentPage, page, onChange) => {
    if (page === currentPage) {
        return <PagerButton key={ `pager-page-${page}` } selected>{ page }</PagerButton>;
    }

    return (
        <PagerButton
            type="button"
            key={ `pager-page-${page}` }
            onClick={() => onChange(page)}
        >
            { page }
        </PagerButton>
    );
};

const ControlledPager = ({ page, limit, totalResults, center, onChange }) => {
    const group = [];
    const mobile = window.innerWidth < 900;
    const maxPages = mobile ? 5 : 7;

    for (let i = 1; i <= Math.ceil(totalResults / limit); i++) {
        group.push(i);
    }

    const largeResultSet = group.length > maxPages;
    const lastPage = _.last(group);
    const { start, end } = mobile ? calculateStartEndForMobile(page, lastPage) :
        calculateStartEnd(page, lastPage);
    const pages = largeResultSet ? group.slice(start, end) : group.slice(1, -1);

    return (
        <StyledPager center={center}>
            { renderArrowLink(page - 1, page > 1, false, onChange) }
            { renderPageLink(page, 1, onChange) }
            { largeResultSet && pages[0] !== 2 && <Dots>...</Dots> }
            { pages.map(item => renderPageLink(page, item, onChange)) }
            { largeResultSet && pages[pages.length - 1] !== lastPage - 1 && <Dots>...</Dots> }
            { group.length !== 1 && renderPageLink(page, lastPage, onChange) }
            { renderArrowLink(page + 1, page !== _.last(group), true, onChange) }
        </StyledPager>
    );
};

ControlledPager.propTypes = {
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    totalResults: PropTypes.number.isRequired,
    center: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default ControlledPager;
